import CryptoJS from 'crypto-js';
import { v4 } from 'uuid';

import { canvasToBlob, changeMainImage, getZoom, zoomAndPan } from './canvas';
import { getUploadedPhotoError } from '../../validations/photos';
import { fileToArrayBuffer } from '../../files';
import { getSize } from '../../iterable/getSize';

import { EXT, INITIAL_STATE, MIME, MIME_TO_EXT, NAMES, TOOLS } from '../../../constants/photoEditor';
import { ERRORS } from '../../../constants/validations';

export function setValueIn({ path, state, value }) {
  if (typeof path === 'string') {
    if (value === undefined) {
      delete state[path];
    } else {
      state[path] = value;
    }
  } else if (Array.isArray(path)) {
    const size = getSize(path);

    if (size === 1) {
      if (value === undefined) {
        delete state[path[0]];
      } else {
        state[path[0]] = value;
      }
    } else if (size > 1) {
      setValueIn({ path: path.slice(1), state: state[path[0]], value });
    }
  }
}

export function toolControlsObject({ object, tool }) {
  switch (tool) {
    case TOOLS.BANNERS: {
      return (
        object.name === NAMES.BANNER ||
        object.name === NAMES.SHAPE
      );
    }

    case TOOLS.SCENES: {
      return object.name === NAMES.SCENERY;
    }

    case TOOLS.TEXT: {
      return object.name === NAMES.TEXT;
    }

    default: {
      return false;
    }
  }
}

export function cleanUpForTool({ current, next, state }) {
  switch (current) {
    case TOOLS.BANNERS:
    case TOOLS.SCENES:
    case TOOLS.TEXT: {
      const { canvas, objects: { byId, controls, ids, selected }} = state;

      if (selected && !toolControlsObject({ object: byId[selected], tool: next })) {
        state.objects = { byId, controls, ids };

        if (canvas) {
          canvas.discardActiveObject();
          canvas.requestRenderAll();
        }
      }

      break;
    }

    case TOOLS.CLEANUP: {
      const { canvas, cleanup: { showChanges }} = state;

      if (!showChanges) {
        state.cleanup.showChanges = true;

        if (canvas) {
          changeMainImage({ canvas, image: canvas.image.element });
        }
      }

      if (canvas) {
        canvas.isDrawingMode = false;
      }

      break;
    }

    case TOOLS.CROP: {
      const { canvas, crop, zoom: { initialSteps }} = state;

      if (!canvas) break;

      crop.area.off().sendToBack().set('selectable', false).set('visible', false);
      canvas.cropping = false;
      canvas.discardActiveObject();
      canvas.clipPath.item(0).set('opacity', 0);
      const target = crop.area;
      state.zoom = getZoom({ canvas, target, zoom: { initialSteps }});
      zoomAndPan({ canvas, target, zoom: state.zoom.current });
      break;
    }

    case TOOLS.ISOLATE: {
      const { canvas } = state;

      if (canvas) {
        canvas.isDrawingMode = false;
      }

      break;
    }

    default: {
      break;
    }
  }
}

export function setUpForTool({ current, state }) {
  switch (current) {
    case TOOLS.CLEANUP: {
      const { canvas } = state;

      if (canvas) {
        canvas.isDrawingMode = true;
      }

      break;
    }

    default: {
      break;
    }
  }
}

export function extractState({ state }) {
  const { canvas, cleanup, crop, history, isolate, objects, original, refine, zoom } = state;
  return { canvas, cleanup, crop, history, isolate, objects, original, refine, zoom };
}

export function initializeOrReviveState({ context, photoId }) {
  const {
    state: {
      banners,
      channel,
      cleanup,
      db,
      isolate,
      palettes,
      scenery,
      shopId,
      states,
      tools,
    },
  } = context;

  if (photoId in states) {
    const state = states[photoId];

    return {
      banners,
      channel,
      canvas: state.canvas,
      cleanup: {
        ...state.cleanup,
        brushSize: cleanup.brushSize,
      },
      crop: state.crop,
      db,
      history: state.history,
      isolate: {
        ...state.isolate,
        editBackground: {
          ...state.isolate.editBackground,
          brushSize: isolate.editBackground.brushSize,
        },
      },
      loading: true,
      objects: state.objects,
      original: state.original,
      palettes,
      refine: state.refine,
      scenery,
      shopId,
      states,
      tools,
      zoom: state.zoom,
    };
  }

  return {
    banners,
    channel,
    cleanup: {
      ...INITIAL_STATE.cleanup,
      brushSize: cleanup.brushSize,
    },
    db,
    isolate: {
      ...INITIAL_STATE.isolate,
      editBackground: {
        ...INITIAL_STATE.isolate.editBackground,
        brushSize: isolate.editBackground.brushSize,
      },
    },
    loading: true,
    palettes,
    scenery,
    shopId,
    states,
    tools,
  };
}

export function renderStateOnCanvas({ canvas, crop }) {
  return new Promise(function executor(resolve) {
    function onClone(clone) {
      function removeRedundant(object) {
        switch (object.name) {
          case NAMES.CROP_AREA: {
            clone.remove(object);
            break;
          }

          default: {
            break;
          }
        }
      }

      clone.renderOnAddRemove = false;
      delete clone.clipPath;
      clone.forEachObject(removeRedundant);

      const { dimensions, height, left, scaleX, scaleY, top, width } = crop;

      if (dimensions) {
        clone.set('height', dimensions.height);
        clone.set('width', dimensions.width);
        const zoom = 1 / Math.min(scaleX, scaleY);
        clone.setZoom(zoom);
        clone.viewportTransform[4] = -left * zoom;
        clone.viewportTransform[5] = -top * zoom;
      } else {
        clone.set('height', height);
        clone.set('width', width);
        clone.viewportTransform[4] = -left;
        clone.viewportTransform[5] = -top;
      }

      clone.calcViewportBoundaries(clone.viewportTransform);
      clone.renderAll();
      resolve(clone.toCanvasElement());
    }

    canvas.clone(onClone, ['name']);
  });
}

export function getHashFromBuffer(buffer) {
  return CryptoJS.SHA1(CryptoJS.lib.WordArray.create(buffer)).toString();
}

export function getPhotoFromState(state) {
  return new Promise(async function executor(resolve, reject) {
    try {
      const canvas = await renderStateOnCanvas(state);
      const blob = await canvasToBlob({ canvas });
      const data = await fileToArrayBuffer(blob);
      resolve({
        format: EXT.PNG,
        data: data,
        hash: getHashFromBuffer(data),
        mime: MIME.PNG,
        url: URL.createObjectURL(blob),
      });
    } catch (error) {
      reject(error);
    }
  });
}

export function shapeFileForEditor({ channel, file }) {
  return new Promise(async function executor(resolve, reject) {
    try {
      await getUploadedPhotoError({ channel, file });
      const { name, type } = file;
      const format = MIME_TO_EXT[type];

      if (!format) {
        reject(ERRORS.PHOTOS.UNSUPPORTED_FORMAT[channel]);
        return;
      }

      const data = await fileToArrayBuffer(file);

      resolve({
        blob: file,
        data,
        format,
        hash: getHashFromBuffer(data),
        id: v4(),
        mime: type,
        name,
        url: URL.createObjectURL(file),
      });
    } catch (error) {
      reject(error);
    }
  });
}
