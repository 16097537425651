// TODO: delete during FEATURE_FLAG_BULK_PHOTO_EDITOR removal
import FileType from 'file-type/browser';
import { encode, decode } from 'base64-arraybuffer';
import { List, Map, Set } from 'immutable';
import { isBase64 } from 'is-base64';

import { getImageTypeByUrl, getMimeTypeFromBase64 } from '../photos';
import { get } from '../iterable/get';
import api from '../api/request';

import { OPERATIONS as PHOTO_EDITOR_OPERATIONS } from '../../constants/photoEditor';
import { OPERATIONS as PRODUCT_OPERATIONS } from '../../constants/bulkEdit';
import { ENDPOINT, HEADERS } from '../../constants/api';
import { IMAGE_TYPE } from '../../constants/photos';

const { CONTENT_TYPE, EXPIRES } = HEADERS;

function isArrayBuffer(value) {
  return (
    typeof ArrayBuffer === 'function' && (
      value instanceof ArrayBuffer ||
      toString.call(value) === '[object ArrayBuffer]'
    )
  );
}

async function downloadImage({ mime = 'image/jpeg', url, data }, convertToBase64) {
  async function downloadPromise(resolve, reject) {
    function onImageDownloaded(blob) {
      const reader = new FileReader();

      async function onReaderLoad() {
        let mimeType = mime;

        if (convertToBase64) {
          mimeType = getMimeTypeFromBase64(reader.result);
        } else {
          const fileFromBuffer = await FileType.fromBuffer(reader.result);
          mimeType = fileFromBuffer?.mime || mime;
        }

        resolve({ data: reader.result, mime: mimeType });
      }

      reader.addEventListener('load', onReaderLoad);
      if (convertToBase64) {
        reader.readAsDataURL(blob);
      } else {
        reader.readAsArrayBuffer(blob);
      }
    }

    if (data) {
      if (isArrayBuffer(data)) {
        const fileFromBuffer = await FileType.fromBuffer(data);
        const mimeType = fileFromBuffer?.mime || mime;

        if (convertToBase64) {
          resolve({
            data: `data:${mimeType};base64,${encode(data)}`,
            mime: mimeType,
          });
        } else {
          resolve({ data: data, mime: mimeType });
        }
      } else if (isBase64(data)) {
        if (convertToBase64) {
          resolve({ data: data, mime: getMimeTypeFromBase64(data) || mime });
        } else {
          const buffer = decode(data);
          const fileFromBuffer = await FileType.fromBuffer(data);
          const mimeType = fileFromBuffer?.mime || mime;
          resolve({ data: buffer, mime: mimeType });
        }
      } else {
        resolve({ data, mime });
      }
    } else {
      const imageType = getImageTypeByUrl(url);

      if (imageType === null) {
        reject('Unknown image type');
        return;
      }

      const credentials = imageType === IMAGE_TYPE.ETSY;
      const params = credentials ? { url } : undefined;

      api
        .get({ credentials, params, url: credentials ? '/getImage64' : url })
        .then(onImageDownloaded, reject);
    }
  }

  return new Promise(downloadPromise);
}

function getSignedUploadUrl(photo) {
  const [hash, mime] = get('hash', 'mime')(photo);
  return api.get({
    url: `/images/uploadURL`,
    params: {
      hash,
      mime: mime || 'image/jpeg',
    },
  });
}

async function uploadImageToAmazon(photo) {
  // get signed upload image url
  let { uploadUrl: url } = await getSignedUploadUrl(photo);
  const [fullSizeUrl, hash] = get('fullsize_url', 'hash')(photo);
  let mime = get('mime')(photo);

  if (url) {
    let payload = get('data')(photo);

    if (!payload) {
      const downloaded = await downloadImage({ url: fullSizeUrl, mime });
      payload = downloaded.data;
      mime = downloaded.mime;
      // request new upload url because the previous one expires in 10 seconds
      const signedUrl = await getSignedUploadUrl({ hash, mime });
      url = signedUrl.uploadUrl;
    }

    // upload image to signed url
    await api.put({ url, payload, headers: { [CONTENT_TYPE]: mime, [EXPIRES]: 0 }});
  } else if (url === null) {
    url = `${ENDPOINT.AWS_S3_IMAGES}/${hash}`;
  }

  return { hash, mime, url };
}

export async function makeOperations({
  channel,
  index,
  operations,
  photos,
  productIds,
  toImmutable,
  withProducts,
}) {
  async function getValue(operation) {
    const result = {
      type: operation.get('type'),
    };

    const properties = {
      size: 'full',
    };

    if (operation.hasIn(['properties', 'color'])) {
      properties.bg_color = operation.getIn(['properties', 'color']);
    } else if (operation.hasIn(['properties', 'image'])) {
      if (operation.getIn(['properties', 'image', 'needUpload'])) {
        const image = operation.getIn(['properties', 'image']);
        const { url } = await uploadImageToAmazon(image);

        if (!url) {
          throw new Error(`Upload URL is ${url}, hash: ${image.get('hash')} mime: ${image.get('mime')}`);
        }

        properties.bg_image_url = url;
      } else {
        properties.bg_image_url = operation.getIn(['properties', 'image', 'fullsizeUrl']);
      }
    }

    result.properties = toImmutable ? Map(properties) : properties;
    return toImmutable ? Map(result) : result;
  }

  async function processOperation(operation) {
    function mapIndexesForImageId(imageIndex) {
      const processedId = photos.getIn(['ids', imageIndex]);
      return photos.getIn(['byId', processedId, 'originalId']);
    }

    function mapIndexesForProductId(imageIndex) {
      return productIds.get(imageIndex);
    }

    const result = { type: PRODUCT_OPERATIONS[channel].PHOTOS.EDIT };
    const indexes = operation.get('indexes').toList();
    const imageIds = indexes.map(mapIndexesForImageId);
    result.imageIds = toImmutable ? imageIds : imageIds.toArray();

    if (withProducts) {
      result.products = toImmutable
        ? indexes.map(mapIndexesForProductId)
        : indexes.map(mapIndexesForProductId).toArray();

      result.order = index + 1;
    }

    const value = await getValue(operation);
    result.value = toImmutable ? List([value]) : [value];

    return toImmutable ? Map(result) : result;
  }

  function mapOperations(operation) {
    async function callback(resolve) {
      try {
        const payload = await processOperation(operation);
        resolve(payload);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        resolve();
      }
    }

    switch (operation.get('type')) {
      case PHOTO_EDITOR_OPERATIONS.REMOVE_BACKGROUND: {
        return new Promise(callback);
      }

      default: {
        return Promise.resolve();
      }
    }
  }

  return await Promise.all(operations.map(mapOperations, []));
}

export function getPhotosToLoad(result, imageId, imageIndex) {
  function updateIndexes(indexes = Set()) {
    return indexes.add(imageIndex + result.offset);
  }

  if (!result.photos.hasIn(['byId', imageId])) {
    if (!result.toLoad.has(imageId)) {
      result.toLoad = result.toLoad.add(imageId);
    }
  } else if (
    result.photos.hasIn(['byId', imageId, 'originalId']) &&
    !result.photos.getIn(['byId', imageId, 'loaded'])
  ) {
    const processedId = imageId;
    const originalId = result.photos.getIn(['byId', processedId, 'originalId']);

    if (!result.toRemoveBG.has(originalId)) {
      result.toRemoveBG = result.toRemoveBG.set(originalId,
        Map({ indexes: Set([imageIndex + result.offset]), originalId, processedId })
      );
    } else {
      result.toRemoveBG = result.toRemoveBG.updateIn([originalId, 'indexes'], updateIndexes);
    }

    if (!result.toLoad.has(originalId)) {
      result.toLoad = result.toLoad.add(originalId);
    }
  }

  return result;
}
