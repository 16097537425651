import { ETSY, SHOPIFY } from './channels';

export const CHANNEL_DATA_KEYS = {
  [ETSY]: [
    'SCALES_LOOKUP',
    'STANDARD_PROPERTIES',
    'SUGGESTED_OPTIONS_LOOKUP',
    'TAXONOMY',
    'TAXONOMY_SPECIFIC_PROPERTIES',
  ],
  [SHOPIFY]: [
    'COUNTRIES_LIST',
    'COUNTRIES_MAP',
  ],
};
