/* eslint-disable no-console */
import { Map, Set } from 'immutable';

import ACTIONS from '../constants/actions';

import Reducers, { Reducer } from '../classes/Reducer';

const photoEditor = 'photoEditor';

function* listingSelected(reduction, id) {
  const state = reduction.get(photoEditor);
  const listing = state.getIn(['listings', 'byId', id]);

  function updatePhotos(photos) {
    return photos
      .set('changed', Set())
      .set('history', Set())
      .set('selected', photos.get('selected', photos.get('ids').last()));
  }

  return reduction.set(photoEditor,
    state
      .set('listing', listing.delete('photos'))
      .set('photos', listing.get('photos').update(updatePhotos))
  );
}

function* resetState(state) {
  return state.set(photoEditor, Map());
}

function* setValue(reduction, { path, value }) {
  if (!path) return reduction;

  const fullPath = Array.isArray(path)
    ? [photoEditor, ...path]
    : [photoEditor, path];

  return value === undefined
    ? reduction.deleteIn(fullPath)
    : reduction.setIn(fullPath, value);
}

const PhotoEditorReducer = new Reducer('Photo_Editor')
  .add(ACTIONS.PHOTO_EDITOR.LISTING_SELECTED, listingSelected)
  .add(ACTIONS.PHOTO_EDITOR.RESET_STATE, resetState)
  .add(ACTIONS.PHOTO_EDITOR.SET_VALUE, setValue);

Reducers.add(PhotoEditorReducer);
