import request from './request';
import * as account from './account';
import * as images from './images';
import * as products from './products';
import * as user from './user';
import affiliates from './affiliates';
import ai from './ai';
import billing from './billing';
import canva from './canva';
import csv from './csv';
import listings from './listings';
import profiles from './profiles';
import scheduledUpdates from './scheduledUpdates';
import shops from './shops';
import studio from './studio';
import waitlist from './waitlist';

export default {
  ...request,
  account,
  affiliates,
  ai,
  billing,
  canva,
  csv,
  images,
  listings,
  products,
  profiles,
  scheduledUpdates,
  shops,
  studio,
  user,
  waitlist,
};
