import React from 'react';
import { Link } from 'react-router-dom';

export const NOTIFICATION = {
  SUCCESS: 1,
  ERROR: 2,
  PROFILE_COPIED: 3,
  SAVED_AS_PROFILE: 4,
  LISTINGS_COPIED: 5,
  PHOTOS_MOVED: 6,
  LINK_COPIED: 7,
};

export const ANIMATION_DURATION = 300; // ms
export const HIDE_TIMEOUT = 5000; // ms

export const TYPE = {
  PROCESS: 1,
  SUCCESS: 2,
  FAIL: 3,
};

export const MESSAGE = {
  PREPARE: {
    COPY_LISTINGS: 'Preparing to copy',
    DELETE: 'Preparing to delete',
    IMPORT: 'Preparing to import',
    PROCESS_PHOTOS: 'Images processing',
    PUBLISH_LISTINGS: 'Preparing to publish',
    REFRESH_SHOP: 'Preparing to refresh',
    SAVE: 'Preparing to save',
    SYNC: 'Preparing to sync',
  },
  PROCESS: {
    COPY_LISTINGS: 'Copying listings',
    DELETE_PHOTOS: 'Deleting photos',
    PUBLISH_LISTINGS: 'Publishing',
    REFRESH_SHOP: 'Refreshing',
    SAVE: 'Saving',
    SYNC: 'Syncing',
  },
  PROCESSED: {
    COMPLETE: 'complete',
    COPIED: 'copied',
    IMPORTED: 'imported',
    PUBLISHED: 'published',
    SAVED: 'saved',
    SYNCED: 'synced',
  },
  SUCCESS: {
    COPY_LINK: 'Link copied',
    COPY_LISTINGS: 'Listings copied',
    COPY_LISTINGS_TO_SHOP: (props) => <>Listings copied to <Link {...props} /></>,
    COPY_PROFILE: 'Profile copied',
    COPY_PROFILE_TO_SHOP: (props) => <>Profile copied to <Link {...props} /></>,
    CREATE_FOLDER: 'Folder created',
    CREATE_PROFILE: 'Profile created',
    CREATE_LISTING: 'Listings created',
    DELETE_FOLDER: 'Folder deleted',
    DELETE_LISTINGS: 'Listings deleted',
    DELETE_PHOTOS: 'Photos deleted',
    DELETE_PROFILE: 'Profile deleted',
    DELETE_UPDATES: 'Updates deleted',
    DISCONNECT_LISTINGS: 'Listings disconnected',
    EXPORT_CSV: 'The export is processing. You’ll receive a CSV file via email once it is complete',
    IMPORT_CSV: 'CSV import complete and saved in Imported',
    LISTINGS_IMPORTED: 'Listings imported',
    LISTINGS_SAVED: 'Listings saved',
    MOVE_PHOTOS: (props) => <>Photos moved to <Link {...props} /></>,
    PUBLISH_LISTINGS: 'Publish complete',
    RENAME_FOLDER: 'Folder renamed',
    SAVE_LISTINGS: 'Listings saved',
    REFRESH_SHOP: 'Shop refreshed',
    SAVE: 'Save complete',
    SAVE_PHOTOS: 'Photos saved',
    SAVE_PROFILE: 'Profile saved',
    SAVE_AS_PROFILE: (props) => <><Link {...props} /> created and saved in Profiles</>,
    SCHEDULE_UPDATES: 'Updates scheduled',
    SYNC: 'Sync complete',
    UNSUBSCRIBE: 'Subscription cancelled',
    UPLOAD_FILES: 'Files uploaded',
    UPDATE_ACCOUNT: 'Account updated',
    UPDATE: 'Update complete',
    WAIT_LIST: 'Waitlist saved',
  },
  FAIL: {
    APPLY_PROFILE: 'Failed to apply profile',
    CHANGE_THEME: 'Failed to change theme',
    COPY_LINK: 'Failed to copy link',
    COPY_PROFILE: 'Failed to copy profile',
    CONNECT_LISTINGS: 'Failed to connect listings',
    COPY_LISTINGS: 'Failed to copy listings',
    CREATE_FOLDER: 'Failed to create folder',
    CREATE_LISTINGS: 'Failed to create listings',
    CREATE_PAGE: 'Failed to create page',
    CREATE_PROFILE: 'Failed to create profile',
    CSV_FILE_IMPORT: 'Failed to import CSV file',
    CSV_FILE_UNSUPPORTED: 'CSV file is corrupted or unsupported',
    DELETE_FOLDER: 'Failed to delete folder',
    DELETE_LISTINGS: 'Failed to delete listings',
    DELETE_PHOTOS: 'Failed to delete photos',
    DELETE_PROFILE: 'Failed to delete profile',
    DELETE_UPDATES: 'Failed to delete updates',
    DISCONNECT_LISTINGS: 'Failed to disconnect listings',
    DOWNLOAD_PHOTOS: 'Failed to download photos',
    ERROR: 'Technical error',
    EXPORT_CSV: 'Failed to export CSV',
    FETCH: 'Failed to fetch',
    FILE_TYPE_NOT_SUPPORTED: 'File type is not supported',
    GENERATE: 'Failed to generate',
    IMPORT_DESIGNS: 'Failed to import designs',
    IMPORT_CSV: 'Failed to import CSV',
    LOAD_DATA: 'Failed to load data',
    LOAD_FILE: 'Failed to load file',
    LOAD_FILES: 'Failed to load files',
    LOAD_IMAGE: 'Failed to load image',
    LOAD_IMAGES: 'Failed to load images',
    LOAD_INVOICES: 'Failed to load invoices',
    LOAD_LISTINGS: 'Failed to load listings',
    LOAD_PHOTO: 'Failed to load photo',
    LOAD_PROFILE: 'Failed to load profile',
    LOAD_PROFILES: 'Failed to load profiles',
    LOAD_UPDATES: 'Failed to load updates',
    MOVE_PHOTOS: 'Failed to move photos',
    NETWORK_ERROR: 'Network error, please try again later',
    NO_PHOTOS: 'No photos',
    PUBLISH_LISTINGS: 'Failed to publish listings',
    PROCESS_IMAGES: 'Failed to process images',
    RENAME_FOLDER: 'Failed to rename folder',
    REFRESH: 'Failed to refresh shop',
    SAVE: 'Failed to save updates',
    SCHEDULE_UPDATES: 'Failed to schedule updates',
    SYNC: 'Failed to sync updates',
    TOO_MANY_REQUESTS: 'Too many requests',
    UNAUTHORIZED: 'Unauthorized',
    UNKNOWN: 'Unknown error',
    UNKNOWN_IMAGE_TYPE: 'Unknown image type',
    UNSUBSCRIBE: 'Failed to cancel subscription',
    UPDATE_ACCOUNT: 'Failed to update account',
    UPLOAD_FILE: 'Failed to upload file',
    UPLOAD_FILES: 'Failed to upload files',
    UPLOAD_PHOTO: 'Failed to upload photo',
    UPLOAD_PHOTOS: 'Failed to upload photos',
    UPLOAD_PROFILE: 'Failed to upload profile',
    UPLOAD_VIDEO: 'Failed to upload video',
    UPLOAD_THUMBNAIL: 'Failed to upload thumbnail',
    WAIT_LIST: 'Failed to save waitlist',
  },
};
