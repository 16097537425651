import request from './request';

import { ENDPOINT, HEADERS } from '../../constants/api';

export async function getAll({ db, shopId }) {
  return request.get({
    url: `/shops/${shopId}/images`,
    params: { db },
  });
}

export async function getAllInFolder({ db, folderId, shopId }) {
  return request.get({
    url: `/shops/${shopId}/images/folders/${folderId}`,
    params: { db },
  });
}

export async function getById({ db, ids, shopId }) {
  return request.post({
    url: `/shops/${shopId}/images/details`,
    params: { db },
    payload: { ids },
  });
}

export async function getByIndex({ db, index, productIds, shopId }) {
  return request.post({
    url: `/shops/${shopId}/images/index/${index + 1}`,
    params: { db },
    payload: { productIds },
  });
}

export async function removeBG() {
  return new Promise(function executor(resolve, reject) {
    reject('https://vela-app.atlassian.net/browse/DEV-1846');
  });
}

export function uploadToS3({ data: payload, hash, mime }) {
  return new Promise(function executor(resolve, reject) {
    function onSuccess() {
      resolve({ url: `${ENDPOINT.AWS_S3_IMAGES}/${hash}` });
    }

    function onUploadURL({ uploadUrl: url }) {
      if (!url) {
        onSuccess();
      } else {
        const headers = { [HEADERS.CONTENT_TYPE]: mime, [HEADERS.EXPIRES]: 0 };
        request.put({ headers, payload, url }).then(onSuccess, reject);
      }
    }

    request.get({ params: { hash, mime }, url: '/images/uploadURL' }).then(onUploadURL, reject);
  });
}
