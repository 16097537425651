export const AMAZON = 'amazon';
export const EBAY = 'ebay';
export const ETSY = 'etsy';
export const FAIRE = 'faire';
export const SHOPIFY = 'shopify';
export const SQUARESPACE = 'squarespace';
export const WIX = 'wix';
export const WOOCOMMERCE = 'woocommerce';

export const CHANNEL_BY_ID = {
  1: ETSY,
  2: SHOPIFY,
  3: EBAY,
};

export const CHANNEL_ID = {
  [EBAY]: 3,
  [ETSY]: 1,
  [SHOPIFY]: 2,
};

export const CHANNEL_NAME = {
  [AMAZON]: 'Amazon',
  [EBAY]: 'eBay',
  [ETSY]: 'Etsy',
  [FAIRE]: 'Faire',
  [SHOPIFY]: 'Shopify',
  [SQUARESPACE]: 'Squarespace',
  [WIX]: 'WIX',
  [WOOCOMMERCE]: 'WooCommerce',
};
