/* eslint-disable no-console */
import { sideEffect } from 'redux-side-effects';
import { List, Map, Set } from 'immutable';

import { getPhotosToLoad, makeOperations } from '../utils/photoEditor/shapeForAPI';
import { processedToOriginal } from '../utils/photoEditor/shapePhotosById';
import { shapePhotoForApp } from '../utils/photoEditor/shapeForApp';
import processOperations from '../utils/photoEditor/processOperations';
import { removeBG } from '../utils/photoEditor/removeBG';
import { isTruthy } from '../utils/bool';
import { getSize } from '../utils/iterable/getSize';
import api from '../utils/api';

import { MESSAGE, NOTIFICATION } from '../constants/notifications';
import { BULK_PHOTOS_LIMIT } from '../constants/photoEditor';
import { OPERATIONS } from '../constants/bulkEdit';
import { PHOTOS } from '../constants/attributes';
import Reducers, { Reducer } from '../classes/Reducer';
import initialState from '../initialState';
import ACTIONS from '../constants/actions';
import Actions from '../actions';

const bulkPhotoEditor = 'bulkPhotoEditor';

function* addBackground(reduction, { color, image }) {
  let properties = Map();

  if (color) {
    properties = properties.set('color', color);
  } else if (image) {
    properties = properties.set('image', image);
  }

  const operations = processOperations.addBG({
    indexes: reduction.getIn([bulkPhotoEditor, 'photos', 'selected']),
    properties,
    operations: reduction.getIn([bulkPhotoEditor, 'operations']),
  });

  return reduction.setIn([bulkPhotoEditor, 'operations'], operations);
}

function* close(reduction) {
  return reduction.set(bulkPhotoEditor, initialState.get(bulkPhotoEditor));
}

function* getPhotos(reduction, { offset }) {
  if (!reduction.get(bulkPhotoEditor).size) return reduction;

  yield sideEffect((dispatch) => {
    dispatch(Actions.BulkPhotoEditor.setLoading(true));

    const limit = offset + BULK_PHOTOS_LIMIT;
    const db = reduction.getIn([bulkPhotoEditor, 'shopDb']);
    const shopId = reduction.getIn([bulkPhotoEditor, 'shopId']);
    const photos = reduction.getIn([bulkPhotoEditor, 'photos']);
    const { toLoad, toRemoveBG } = photos
      .get('ids')
      .slice(offset, limit)
      .reduce(getPhotosToLoad, { offset, photos, toLoad: Set(), toRemoveBG: Map() });

    api
      .images
      .getById({ db, ids: toLoad.toArray(), shopId })
      .then(
        (data) => dispatch(Actions.BulkPhotoEditor.getPhotosSucceeded({ data, toRemoveBG })),
        (error) => dispatch(Actions.BulkPhotoEditor.getPhotosFailed({ error, offset }))
      );
  });

  return reduction.setIn([bulkPhotoEditor, 'photos', 'offset'], offset);
}

function* getPhotosFailed(reduction, { error, offset }) {
  if (
    !reduction.get(bulkPhotoEditor).size ||
    reduction.getIn([bulkPhotoEditor, 'photos', 'offset']) !== offset
  ) {
    return reduction;
  }

  console.error(error);

  yield sideEffect((dispatch) => {
    dispatch(Actions.BulkPhotoEditor.setLoading());

    if (!reduction.hasIn([bulkPhotoEditor, 'photos', 'byId'])) {
      dispatch(Actions.BulkPhotoEditor.close());
    }

    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_IMAGES,
      })
    );
  });

  return reduction;
}

function* getPhotosSucceeded(reduction, { data, toRemoveBG: originalsToRemoveBG }) {
  if (!reduction.get(bulkPhotoEditor).size) return reduction;

  const { byId, toRemoveBG } = data.images.reduce(
    shapePhotoForApp,
    {
      byId: reduction.getIn([bulkPhotoEditor, 'photos', 'byId'], Map()),
      originalsToRemoveBG,
      toRemoveBG: Map(),
    }
  );

  yield sideEffect((dispatch) => {
    function onFail(params) {
      dispatch(Actions.BulkPhotoEditor.removeBackgroundFailed(params));
    }

    function onSuccess(params) {
      dispatch(Actions.BulkPhotoEditor.removeBackgroundSucceeded(params));
    }

    function onEnd() {
      dispatch(Actions.BulkPhotoEditor.setLoading());
    }

    if (getSize(toRemoveBG)) {
      removeBG({ images: toRemoveBG, onFail, onSuccess }).then(onEnd);
    } else {
      onEnd();
    }
  });

  return reduction.setIn([bulkPhotoEditor, 'photos', 'byId'], byId);
}

function* removeBackground(reduction) {
  const { operations, photos, toRemoveBG } = processOperations.removeBG({
    indexes: reduction.getIn([bulkPhotoEditor, 'photos', 'selected']),
    photos: reduction.getIn([bulkPhotoEditor, 'photos']),
    operations: reduction.getIn([bulkPhotoEditor, 'operations']),
  });

  yield sideEffect(async (dispatch) => {
    dispatch(Actions.BulkPhotoEditor.setLoading(true));

    function onFail(params) {
      dispatch(Actions.BulkPhotoEditor.removeBackgroundFailed(params));
    }

    function onSuccess(params) {
      dispatch(Actions.BulkPhotoEditor.removeBackgroundSucceeded(params));
    }

    function onEnd() {
      dispatch(Actions.BulkPhotoEditor.setLoading());
    }

    removeBG({ images: toRemoveBG, onFail, onSuccess }).then(onEnd);
  });

  return reduction
    .setIn([bulkPhotoEditor, 'operations'], operations)
    .setIn([bulkPhotoEditor, 'photos'], photos);
}

function* removeBackgroundFailed(reduction, { error, image, originalUrl }) {
  if (
    !reduction.get(bulkPhotoEditor).size ||
    !reduction.hasIn([bulkPhotoEditor, 'photos', 'byId', image.get('processedId')])
  ) {
    return reduction;
  }

  if (typeof error === 'string' && /Failed to fetch/.test(error)) {
    console.error(error);
  } else {
    console.error(`Failed to remove background for URL: `, originalUrl, '\n', error);
  }

  const { operations, photos } = processOperations.restoreOriginal({
    indexes: image.get('indexes'),
    photos: reduction.getIn([bulkPhotoEditor, 'photos']),
    operations: reduction.getIn([bulkPhotoEditor, 'operations']),
  });

  return reduction
    .setIn([bulkPhotoEditor, 'operations'], operations)
    .setIn([bulkPhotoEditor, 'photos'], photos);
}

function* removeBackgroundSucceeded(reduction, { image, processedUrl }) {
  if (
    !reduction.get(bulkPhotoEditor).size ||
    !reduction.hasIn([bulkPhotoEditor, 'photos', 'byId', image.get('processedId')])
  ) {
    return reduction;
  }

  function updateImage(value) {
    return value.set('loaded', true).set('fullsize_url', processedUrl);
  }

  return reduction.updateIn([bulkPhotoEditor, 'photos', 'byId', image.get('processedId')], updateImage);
}

function* restoreOriginals(reduction) {
  const { operations, photos } = processOperations.restoreOriginal({
    indexes: reduction.getIn([bulkPhotoEditor, 'photos', 'selected']),
    photos: reduction.getIn([bulkPhotoEditor, 'photos']),
    operations: reduction.getIn([bulkPhotoEditor, 'operations']),
  });

  return reduction
    .setIn([bulkPhotoEditor, 'operations'], operations)
    .setIn([bulkPhotoEditor, 'photos'], photos);
}

function* save(reduction) {
  let state = reduction;
  const bulkEdit = state.getIn([bulkPhotoEditor, 'bulkEdit']);
  const channel = state.getIn([bulkPhotoEditor, 'channel']);
  const index = state.getIn([bulkPhotoEditor, 'index']);
  const photos = state.getIn([bulkPhotoEditor, 'photos']);
  const shopDb = state.getIn([bulkPhotoEditor, 'shopDb']);
  const shopId = state.getIn([bulkPhotoEditor, 'shopId']);

  yield sideEffect(async (dispatch) => {
    let operations = await makeOperations({
      channel,
      index,
      operations: state.getIn([bulkPhotoEditor, 'operations']),
      photos,
      productIds: state.getIn([bulkPhotoEditor, 'productIds']),
      toImmutable: bulkEdit,
      withProducts: bulkEdit,
    });

    operations = operations.filter(isTruthy);

    if (operations.length) {
      if (bulkEdit) {
        if (reduction.get('bulkEdit').size) {
          const previewPhotos = reduction.getIn(['bulkEdit', 'preview', PHOTOS, PHOTOS], List());
          const previewOperations = reduction.getIn(['bulkEdit', 'preview', PHOTOS, 'operations'], List());
          dispatch(
            Actions.BulkEdit.addPreview(
              Map({
                [PHOTOS]: Map({
                  operations: previewOperations.concat(List(operations)),
                  operation: OPERATIONS[channel].PHOTOS.EDIT,
                  [PHOTOS]: previewPhotos.set(index, true),
                }),
              })
            )
          );
        }

        dispatch(Actions.BulkPhotoEditor.close());
      } else {
        api
          .products
          .bulkEditProducts({ operations, shopId, shopDb })
          .then(
            () => dispatch(Actions.BulkPhotoEditor.close()),
            (error) => dispatch(Actions.BulkPhotoEditor.saveFailed({ error, photos }))
          );
      }
    } else {
      dispatch(Actions.BulkPhotoEditor.saveFailed({ photos }));
    }
  });

  if (bulkEdit) {
    state = state.setIn(['bulkEdit', 'bulkPhotoEditor'],
      Map({
        [PHOTOS]: photos.get('byId').reduce(
          processedToOriginal,
          state.getIn(['bulkEdit', 'bulkPhotoEditor', PHOTOS], Map())
        ),
      })
    );
  }

  return state
    .deleteIn([bulkPhotoEditor, 'photos'])
    .setIn([bulkPhotoEditor, 'preventClose'], true);
}

function* saveFailed(reduction, { error, photos }) {
  yield sideEffect((dispatch) => {
    if (error) console.error(error);

    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.SAVE,
      })
    );
  });

  return reduction.get(bulkPhotoEditor).size
    ? reduction
      .setIn([bulkPhotoEditor, 'photos'], photos)
      .deleteIn([bulkPhotoEditor, 'preventClose'])
    : reduction;
}

function* selectAll(reduction) {
  return reduction.setIn([bulkPhotoEditor, 'photos', 'selected'],
    Set(reduction.getIn([bulkPhotoEditor, 'photos', 'ids']).keySeq())
  );
}

function* startEditing(reduction, { byId, imageIds, index, productIds }) {
  let state = reduction;
  let shopDb = state.getIn([bulkPhotoEditor, 'shopDb']);
  let shopId = state.getIn([bulkPhotoEditor, 'shopId']);
  let channel = state.getIn([bulkPhotoEditor, 'channel']);
  let photos = Map({
    ids: List(imageIds),
    selected: Set([...Array(getSize(imageIds)).keys()]),
  });

  if (!shopId) {
    shopId = state.getIn(['shops', 'current']);
    state = state.setIn([bulkPhotoEditor, 'shopId'], shopId);
  }

  if (!channel) {
    channel = state.getIn(['shops', 'byId', shopId, 'channel']);
    state = state.setIn([bulkPhotoEditor, 'channel'], channel);
  }

  if (!shopDb) {
    shopDb = state.getIn(['shops', 'byId', shopId, 'db']);
    state = state.setIn([bulkPhotoEditor, 'shopDb'], shopDb);
  }

  if (Array.isArray(productIds)) {
    state = state
      .setIn([bulkPhotoEditor, 'bulkEdit'], true)
      .setIn([bulkPhotoEditor, 'index'], index)
      .setIn([bulkPhotoEditor, 'productIds'], List(productIds));
  }

  if (Map.isMap(byId)) {
    photos = photos.set('byId', byId);
  }

  yield sideEffect((dispatch) => {
    dispatch(Actions.BulkPhotoEditor.getPhotos({ offset: 0 }));
  });

  return state
    .setIn([bulkPhotoEditor, 'operations'], List())
    .setIn([bulkPhotoEditor, 'photos'], photos);
}

function* setLoading(reduction, value) {
  return value
    ? reduction.setIn([bulkPhotoEditor, 'loading'], true)
    : reduction.deleteIn([bulkPhotoEditor, 'loading']);
}

function* togglePhoto(reduction, index) {
  function updateSelected(selected) {
    return selected.has(index)
      ? selected.delete(index)
      : selected.add(index);
  }

  return reduction.updateIn([bulkPhotoEditor, 'photos', 'selected'], updateSelected);
}

Reducers.add(
  new Reducer('Bulk_Photo_Editor')
    .add(ACTIONS.BULK_PHOTO_EDITOR.ADD_BACKGROUND, addBackground)
    .add(ACTIONS.BULK_PHOTO_EDITOR.CLOSE, close)
    .add(ACTIONS.BULK_PHOTO_EDITOR.GET_PHOTOS, getPhotos)
    .add(ACTIONS.BULK_PHOTO_EDITOR.GET_PHOTOS_FAILED, getPhotosFailed)
    .add(ACTIONS.BULK_PHOTO_EDITOR.GET_PHOTOS_SUCCEEDED, getPhotosSucceeded)
    .add(ACTIONS.BULK_PHOTO_EDITOR.REMOVE_BACKGROUND, removeBackground)
    .add(ACTIONS.BULK_PHOTO_EDITOR.REMOVE_BACKGROUND_FAILED, removeBackgroundFailed)
    .add(ACTIONS.BULK_PHOTO_EDITOR.REMOVE_BACKGROUND_SUCCEEDED, removeBackgroundSucceeded)
    .add(ACTIONS.BULK_PHOTO_EDITOR.RESTORE_ORIGINALS, restoreOriginals)
    .add(ACTIONS.BULK_PHOTO_EDITOR.SAVE, save)
    .add(ACTIONS.BULK_PHOTO_EDITOR.SAVE_FAILED, saveFailed)
    .add(ACTIONS.BULK_PHOTO_EDITOR.SELECT_ALL, selectAll)
    .add(ACTIONS.BULK_PHOTO_EDITOR.START_EDITING, startEditing)
    .add(ACTIONS.BULK_PHOTO_EDITOR.SET_LOADING, setLoading)
    .add(ACTIONS.BULK_PHOTO_EDITOR.TOGGLE_PHOTO, togglePhoto)
);
