import { getDescriptionError } from '../../validations/description';
import { clearErrors } from '../../errors';
import { escapeRegex } from '../../string';

import { FIND, OPERATIONS, REPLACE } from '../../../constants/bulkEdit';
import { DESCRIPTION } from '../../../constants/attributes';
import { DEFAULTS } from '../../../constants';
import { VALUE } from '../../../constants/product';

export default function description({ actions, operation, product }) {
  const channel = product.get('channel');

  function validateAndApply(value) {
    return {
      actions,
      product: getDescriptionError({ channel, value })
        ? product
        : clearErrors({
          errors: [DESCRIPTION],
          product: product.setIn([DESCRIPTION, VALUE], value),
        }),
    };
  }

  switch (operation.get('type')) {
    case OPERATIONS[channel].DESCRIPTION.SET: {
      const value = operation.get(VALUE);
      return validateAndApply(value);
    }

    case OPERATIONS[channel].DESCRIPTION.ADD_AFTER: {
      const value = `${product.getIn([DESCRIPTION, VALUE])}${operation.get(VALUE)}`;
      return validateAndApply(value);
    }

    case OPERATIONS[channel].DESCRIPTION.ADD_BEFORE: {
      const value = `${operation.get(VALUE)}${product.getIn([DESCRIPTION, VALUE])}`;
      return validateAndApply(value);
    }

    case OPERATIONS[channel].DESCRIPTION.DELETE: {
      const pattern = new RegExp(escapeRegex(operation.get(VALUE)), 'ig');
      const value = product.getIn([DESCRIPTION, VALUE]).replace(pattern, DEFAULTS.EMPTY_STRING);
      return validateAndApply(value);
    }

    case OPERATIONS[channel].DESCRIPTION.FIND_AND_REPLACE: {
      const pattern = new RegExp(escapeRegex(operation.getIn([VALUE, FIND])), 'ig');
      const value = product
        .getIn([DESCRIPTION, VALUE])
        .replace(pattern, operation.getIn([VALUE, REPLACE]));

      return validateAndApply(value);
    }

    default: {
      return { actions, product };
    }
  }
}
