import { Map } from 'immutable';

import { getAttribute, getUiState } from '../taxonomy';
import { getOversetAttributes } from '../product/taxonomy';
import { getCategoryError } from './details';

import { CATEGORY, TAXONOMY_ATTRIBUTES } from '../../constants/attributes';
import { TAXONOMY_LIMITS } from '../../constants/validations';
import { SECTIONS } from '../../constants/product';

export const errors = {
  invalidNumber(displayName) {
    return `${displayName} must be a valid number (e.g., 1, 1.5, 1 1/2)`;
  },
  noScale(displayName) {
    return `${displayName} must have a scale`;
  },
  overLimit(displayName) {
    return `${displayName} selected over limit`;
  },
  required(displayName) {
    return `${displayName} is required`;
  },
};

export function validateTaxonomyAttributes({ channel, product }) {
  const category = product.getIn([SECTIONS.DETAILS, CATEGORY, 'value']);

  if (getCategoryError({ channel, value: category })) return Map();

  const { availableAttributes: attributes = [] } = getUiState({ taxonomyId: category }, false);
  const taxonomyAttributes = product.getIn([SECTIONS.DETAILS, TAXONOMY_ATTRIBUTES], Map());
  const overset = getOversetAttributes(product);

  if (!attributes.length) return Map();

  function validateAttribute(result, attribute) {
    const { id: property, isMultivalued, required, variations } = attribute;
    const scale = taxonomyAttributes.getIn([property, 'scaleId']);
    const { displayName, availableOptions, availableScales, selectedOptions } = getAttribute({ category, property, scale });
    const size = (
      taxonomyAttributes.getIn([property, 'values'])?.size || 0
      + selectedOptions?.length || 0
    );

    if (!size && required && (!variations || !overset.has(property))) {
      return result.setIn([TAXONOMY_ATTRIBUTES, property], errors.required(displayName));
    }

    if (!isMultivalued && size > 1) {
      return result.setIn([TAXONOMY_ATTRIBUTES, property], errors.overLimit(displayName));
    }

    if (TAXONOMY_LIMITS.hasOwnProperty(property)) {
      const limit = TAXONOMY_LIMITS[property];

      if (size > limit) {
        return result.setIn([TAXONOMY_ATTRIBUTES, property], errors.overLimit(displayName));
      }
    }

    if (availableScales.length) {
      if (
        !taxonomyAttributes.getIn([property, 'scaleId']) &&
        taxonomyAttributes.getIn([property, 'values', 0])
      ) {
        return result.setIn([TAXONOMY_ATTRIBUTES, property], errors.noScale(displayName));
      }

      if (
        !availableOptions.length &&
        taxonomyAttributes.getIn([property, 'values', 0]) &&
        !/^(\d+(\.\d+)?|\.\d+|(\d+\s)?\d+\/\d+)$/.test(taxonomyAttributes.getIn([property, 'values', 0]))
      ) {
        return result.setIn([TAXONOMY_ATTRIBUTES, property], errors.invalidNumber(displayName));
      }
    }

    return result;
  }

  return attributes.reduce(validateAttribute, Map());
}
