import { STATUS } from '../../constants/listings';
import { NEW } from '../../constants/product';

export function shouldAddPlaceholder({ product, status }) {
  return (
    product.get('productId') === NEW ||
    (product.get('status') === STATUS.COPIED && status !== STATUS.COPIED) ||
    (product.get('status') === STATUS.UNPUBLISHED && status !== STATUS.UNPUBLISHED)
  );
}
