export const PLAN = {
  TRIAL: 'trial',
  ERROR: 'error',
};

export const CHANGE_TYPE = {
  UPGRADE: 'Upgrade',
  DOWNGRADE: 'Downgrade',
  CANCEL: 'Cancel',
};

export const INTERVALS = {
  monthly: 'month',
  annualy: 'year',
};

export const CARD_BRANDS = {
  amex: 'AmEx',
  diners: 'Diners Club',
  discover: 'Discover',
  jcb: 'JCB',
  mastercard: 'Mastercard',
  unionpay: 'UnionPay',
  visa: 'Visa',
};

export const FEATURES = {
  BULK_PHOTO_EDITOR: 'bulk-photo-editor',
  CANVA: 'canva',
  CSV_EXPORT: 'csv-export',
  CSV_IMPORT: 'csv-import',
  CSV_OVERRIDE: 'csv-override',
  EBAY_INTEGRATION: 'ebay',
  FILES_TO_S3: 'files-s3',
  SALES_PROFILES: 'sales-profiles',
  SEO_URL_HANDLE: 'seo-attributes-url-handle',
  SHOPIFY_INVENTORY_POLICY: 'shopify-inventory-policy',
  UI_UPDATES_AUGUST: 'ui-updates-august',
};

export const ACTION = {
  UPGRADED: 'successfully upgraded',
  DELETED: 'deleted',
  UNSUBSCRIBED: 'unsubscribed',
};

export const TIER = {
  T_0: 'tier_0_250',
  T_251: 'tier_251_500',
  T_501: 'tier_501_1000',
  T_1001: 'tier_1001_2500',
  T_2501: 'tier_2501_5000',
  T_5001: 'tier_5001_7500',
  T_7501: 'tier_7501_10000',
  T_10001: 'tier_10001_20000',
  T_20001: 'tier_20001_30000',
  T_30001: 'tier_30001_40000',
  T_40001: 'tier_40001_50000',
  T_50001: 'tier_50001_60000',
  T_60001: 'tier_60001_70000',
  T_70001: 'tier_70001_plus',
};

export const TIER_LIMITS = {
  [TIER.T_0]: [0, 250],
  [TIER.T_251]: [251, 500],
  [TIER.T_501]: [501, 1000],
  [TIER.T_1001]: [1001, 2500],
  [TIER.T_2501]: [2501, 5000],
  [TIER.T_5001]: [5001, 7500],
  [TIER.T_7501]: [7501, 10000],
  [TIER.T_10001]: [10001, 20000],
  [TIER.T_20001]: [20001, 30000],
  [TIER.T_30001]: [30001, 40000],
  [TIER.T_40001]: [40001, 50000],
  [TIER.T_50001]: [50001, 60000],
  [TIER.T_60001]: [60001, 70000],
  [TIER.T_70001]: [70001],
};

export const LAST_TIER_LIMIT = TIER_LIMITS[TIER.T_70001][0];

export const TIER_PRICE = {
  [TIER.T_0]: 10,
  [TIER.T_251]: 15,
  [TIER.T_501]: 20,
  [TIER.T_1001]: 25,
  [TIER.T_2501]: 30,
  [TIER.T_5001]: 35,
  [TIER.T_7501]: 40,
  [TIER.T_10001]: 50,
  [TIER.T_20001]: 60,
  [TIER.T_30001]: 70,
  [TIER.T_40001]: 80,
  [TIER.T_50001]: 90,
  [TIER.T_60001]: 100,
  [TIER.T_70001]: 110,
};

export const TIERS = [
  TIER.T_0,
  TIER.T_251,
  TIER.T_501,
  TIER.T_1001,
  TIER.T_2501,
  TIER.T_5001,
  TIER.T_7501,
  TIER.T_10001,
];
