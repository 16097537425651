export function findSuitableOffering({ variationId, optionId, offerings }) {
  function findOffering(offering) {
    function findOption(option) {
      return option.get('variationId') === variationId && option.get('optionId') === optionId;
    }

    return offering.get('options').find(findOption);
  }

  return offerings.find(findOffering);
}
