/* eslint-disable no-console */
import { sideEffect } from 'redux-side-effects';
import { isImmutable, List, Map, Set } from 'immutable';

import { shapeFoldersForApp, shapeImagesForApp } from '../utils/studio/library/shapeForApp';
import { shapeFileForEditor } from '../utils/studio/editor/state';
import { createController } from '../utils/reducer';
import { downloadFile } from '../utils/files';
import { increase } from '../utils/math';
import { getSize } from '../utils/iterable/getSize';
import api from '../utils/api';

import { MESSAGE, NOTIFICATION } from '../constants/notifications';
import { IMAGES_PER_PAGE } from '../constants/mediaLibrary';
import { DEFAULTS } from '../constants';
import ACTIONS from '../constants/actions';

import Reducers, { Reducer } from '../classes/Reducer';
import Actions from '../actions';

const mediaLibrary = 'mediaLibrary';
const controller = createController();

function getShopParams(state) {
  const channel = state.getIn([mediaLibrary, 'channel']);
  const db = state.getIn([mediaLibrary, 'db']);
  const shopId = state.getIn([mediaLibrary, 'shopId']);
  return { channel, db, shopId };
}

function* bootstrap(reduction, { channel, db, shopId }) {
  yield sideEffect((dispatch) => {
    dispatch(Actions.MediaLibrary.getFolders());
    dispatch(Actions.MediaLibrary.getImages());
  });

  let state = Map({ channel, db, shopId });

  if (reduction.hasIn([mediaLibrary, 'uploading'])) {
    state = state.set('uploading', reduction.getIn([mediaLibrary, 'uploading']));
  }

  return reduction.set(mediaLibrary, state);
}

function* cleanUp(reduction) {
  let state = Map();

  if (reduction.hasIn([mediaLibrary, 'uploading'])) {
    state = state.set('uploading', reduction.getIn([mediaLibrary, 'uploading']));
  }

  return reduction.set(mediaLibrary, state);
}

function* createFolder(reduction, { callback, name }) {
  const { db, shopId } = getShopParams(reduction);

  yield sideEffect((dispatch) => {
    api.studio.createFolder({ db, name, shopId })
      .then(
        ({ uuid }) => dispatch(Actions.MediaLibrary.createFolderSucceeded({ callback, name, uuid })),
        (error) => dispatch(Actions.MediaLibrary.createFolderFailed(error)),
      );
  });

  return reduction;
}

function* createFolderFailed(reduction, error) {
  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.CREATE_FOLDER,
      })
    );
  });

  return reduction;
}

function* createFolderSucceeded(reduction, { callback, name, uuid }) {
  yield sideEffect((dispatch) => {
    if (typeof callback === 'function') {
      callback(uuid);
    } else {
      dispatch(
        Actions.Notifications.add({
          type: NOTIFICATION.SUCCESS,
          message: MESSAGE.SUCCESS.CREATE_FOLDER,
        })
      );
    }

    dispatch(Actions.Data.setData({ path: 'lastInteraction' }));
    dispatch(Actions.MediaLibrary.getFolders());
    dispatch(Actions.MediaLibrary.getImages({ folder: uuid }));
  });

  function updateById(byId) {
    return byId.set(uuid, Map({ count: 0, name, uuid }));
  }

  function updateIds(ids) {
    return ids.push(uuid);
  }

  return reduction
    .updateIn([mediaLibrary, 'folders', 'byId'], updateById)
    .updateIn([mediaLibrary, 'folders', 'ids'], updateIds);
}

function* deleteFolder(reduction, folder) {
  const { db, shopId } = getShopParams(reduction);

  yield sideEffect((dispatch) => {
    api.studio.deleteFolder({ db, folder, shopId })
      .then(
        () => dispatch(Actions.MediaLibrary.deleteFolderSucceeded(folder)),
        (error) => dispatch(Actions.MediaLibrary.deleteFolderFailed(error)),
      );
  });

  return reduction;
}

function* deleteFolderFailed(reduction, error) {
  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.DELETE_FOLDER,
      })
    );
  });

  return reduction;
}

function* deleteFolderSucceeded(reduction, uuid) {
  yield sideEffect((dispatch) => {
    dispatch(Actions.Data.setData({ path: 'lastInteraction' }));
    dispatch(Actions.MediaLibrary.getFolders());
    dispatch(Actions.MediaLibrary.getImages({ folder: null }));
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.SUCCESS,
        message: MESSAGE.SUCCESS.DELETE_FOLDER,
      })
    );
  });

  function updateIds(ids) {
    return ids.filter(function filterIds(item) {
      return item !== uuid;
    });
  }

  return reduction
    .deleteIn([mediaLibrary, 'folders', 'byId', uuid])
    .updateIn([mediaLibrary, 'folders', 'ids'], updateIds);
}

function* deleteImages(reduction, { uuids } ) {
  const { db, shopId } = getShopParams(reduction);

  yield sideEffect((dispatch) => {
    dispatch(
      Actions.MediaLibrary.toggleImageState({
        images: uuids,
        state: 'processing',
        value: true,
      })
    );

    api.studio.deleteImages({ db, shopId, uuids })
      .then(
        () => dispatch(Actions.MediaLibrary.deleteImagesSucceeded(shopId)),
        (error) => dispatch(Actions.MediaLibrary.deleteImagesFailed({ error, shopId })),
      );
  });

  return reduction;
}

function* deleteImagesFailed(reduction, { error, shopId }) {
  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        message: MESSAGE.FAIL.DELETE_PHOTOS,
        type: NOTIFICATION.ERROR,
      })
    );

    if (shopId === getShopParams(reduction).shopId) {
      dispatch(Actions.Data.setData({ path: 'lastInteraction' }));
      dispatch(Actions.MediaLibrary.getFolders());
      dispatch(Actions.MediaLibrary.getImages({ clear: true }));
    }
  });

  return reduction;
}

function* deleteImagesSucceeded(reduction, shopId) {
  yield sideEffect((dispatch) => {
    dispatch(
      Actions.Notifications.add({
        message: MESSAGE.SUCCESS.DELETE_PHOTOS,
        type: NOTIFICATION.SUCCESS,
      })
    );

    if (shopId === getShopParams(reduction).shopId) {
      dispatch(Actions.Data.setData({ path: 'lastInteraction' }));
      dispatch(Actions.MediaLibrary.getFolders());
      dispatch(Actions.MediaLibrary.getImages({ clear: true }));
    }
  });

  return reduction;
}

function* downloadImages(reduction, { name, uuids }) {
  const { db, shopId } = getShopParams(reduction);

  yield sideEffect((dispatch) => {
    dispatch(
      Actions.MediaLibrary.toggleImageState({
        images: uuids,
        state: 'processing',
        value: true,
      })
    );

    api.studio.downloadImages({ db, shopId, uuids })
      .then((blob) => new Promise(function executor(resolve, reject) {
        const reader = new FileReader();

        reader.addEventListener('loadend', function onReaderLoad() {
          try {
            downloadFile({ data: reader.result, name });
            resolve();
          } catch (error) {
            reject(error);
          }
        });

        reader.readAsDataURL(blob);
      }))
      .then(
        () => dispatch(Actions.MediaLibrary.downloadImagesSucceeded({ shopId, uuids })),
        (error) => dispatch(Actions.MediaLibrary.downloadImagesFailed({ error, shopId, uuids })),
      );
  });

  return reduction;
}

function* downloadImagesFailed(reduction, { error, shopId, uuids }) {
  yield sideEffect((dispatch) => {
    console.error(error);

    dispatch(
      Actions.Notifications.add({
        message: MESSAGE.FAIL.DOWNLOAD_PHOTOS,
        type: NOTIFICATION.ERROR,
      })
    );

    if (shopId === getShopParams(reduction).shopId) {
      dispatch(
        Actions.MediaLibrary.toggleImageState({
          images: uuids,
          state: 'processing',
          value: false,
        })
      );
    }
  });

  return reduction;
}

function* downloadImagesSucceeded(reduction, { shopId, uuids }) {
  if (shopId === getShopParams(reduction).shopId) {
    yield sideEffect((dispatch) => {
      dispatch(
        Actions.MediaLibrary.toggleImageState({
          images: uuids,
          state: 'processing',
          value: false,
        })
      );
    });
  }

  return reduction;
}

function* getFolders(reduction) {
  const { db, shopId } = getShopParams(reduction);

  yield sideEffect((dispatch) => {
    api.studio.getFolders({ db, shopId })
      .then(
        (response) => dispatch(Actions.MediaLibrary.getFoldersSucceeded(response)),
        (error) => dispatch(Actions.MediaLibrary.getFoldersFailed(error)),
      );
  });

  return reduction;
}

function* getFoldersFailed(reduction, error) {
  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_DATA,
      })
    );
  });

  return reduction
    .setIn([mediaLibrary, 'folders'], Map({ byId: Map(), ids: List() }))
    .setIn([mediaLibrary, 'loaded'], reduction.hasIn([mediaLibrary, 'images']));
}

function* getFoldersSucceeded(reduction, response) {
  let state = reduction.get(mediaLibrary);
  const allPhotos = Map({ count: parseInt(response.image_count, 10) || 0, name: 'All photos' });
  const folders = response.folders.reduce(
    shapeFoldersForApp,
    state
      .get('folders', Map({ selected: null }))
      .set('byId', Map().set(null, allPhotos))
      .set('ids', List([null]))
  );

  state = state
    .set('folders', folders)
    .set('loaded', state.has('images'));

  if (state.get('loaded') && !state.getIn(['request', 'folder'])) {
    state = state.setIn(['request', 'folder'], folders.getIn(['byId', folders.get('selected')]));
  }

  return reduction.set(mediaLibrary, state);
}

function* getImages(reduction, payload = {}) {
  const { signal } = controller.start();
  const { db, shopId } = getShopParams(reduction);
  let state = reduction.get(mediaLibrary).setIn(['request', 'processing'], true);
  const { folder = state.getIn(['request', 'folder', 'uuid'], null) } = payload;
  let {
    clear = false,
    page = state.getIn(['request', 'page'], 0),
    query = state.getIn(['request', 'query'], DEFAULTS.EMPTY_STRING),
  } = payload;

  if (state.has('folders') && folder !== state.getIn(['folders', 'selected'])) {
    state = state.setIn(['folders', 'selected'], folder);
  }

  if (folder !== state.getIn(['request', 'folder', 'uuid'], null)) {
    clear = true;
    query = DEFAULTS.EMPTY_STRING;
  } else if (query !== state.getIn(['request', 'query'], DEFAULTS.EMPTY_STRING)) {
    clear = true;
  }

  if (clear) {
    state = state.setIn(['images', 'loading'], true);
    page = 0;
  }

  const request = Map({
    folder: state.getIn(['folders', 'byId', folder]),
    page,
    query,
  });

  yield sideEffect((dispatch) => {
    api.studio.getImages({ db, folder, page, shopId, signal, query })
      .then(
        (response) => dispatch(
          Actions.MediaLibrary.getImagesSucceeded({ clear, request, response })
        ),
        (error) => dispatch(Actions.MediaLibrary.getImagesFailed({ error, request, signal })),
      );
  });

  return reduction.set(mediaLibrary, state);
}

function* getImagesFailed(reduction, { error, request, signal }) {
  if (signal.aborted) return reduction;

  controller.stop();

  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_DATA,
      })
    );
  });

  const state = reduction
    .get(mediaLibrary)
    .set('images', Map({ byId: Map(), ids: List(), total: 0 }))
    .set('request', request);

  return reduction.set(mediaLibrary, state.set('loaded', state.has('folders')));
}

function* getImagesSucceeded(reduction, { clear, request, response }) {
  controller.stop();

  let state = reduction.get(mediaLibrary);
  let images = state
    .get('images', Map({ byId: Map(), ids: List() }))
    .delete('loading')
    .delete('processing')
    .set('total', parseInt(response.count, 10));

  if (clear) {
    images = images
      .delete('selected')
      .set('byId', Map())
      .set('ids', List());
  }

  state = state
    .set('images', response.images.reduce(shapeImagesForApp, images))
    .set('loaded', state.has('folders'))
    .set('request', request);

  if ((request.get('page') + 1) * IMAGES_PER_PAGE < images.get('total')) {
    state = state.setIn(['request', 'hasMore'], true);
  }

  if (state.get('loaded') && !request.get('folder') && getSize(state.getIn(['folders', 'byId']))) {
    state = state.setIn(['request', 'folder'],
      state.getIn(['folders', 'byId', state.getIn(['folders', 'selected'], null)])
    );
  }

  return reduction.set(mediaLibrary, state);
}

function* moveImages(reduction, { source, target, uuids }) {
  if (source === target || !Array.isArray(uuids) || !getSize(uuids)) return reduction;

  const { db, shopId } = getShopParams(reduction);

  yield sideEffect((dispatch) => {
    api.studio.moveImages({ db, shopId, source, target, uuids })
      .then(
        () => dispatch(Actions.MediaLibrary.moveImagesSucceeded({ target, uuids })),
        (error) => dispatch(Actions.MediaLibrary.moveImagesFailed(error)),
      );
  });

  return reduction;
}

function* moveImagesFailed(reduction, error) {
  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.MOVE_PHOTOS,
      })
    );
  });

  return reduction;
}

function* moveImagesSucceeded(reduction, { target, uuids }) {
  const { shopId } = getShopParams(reduction);

  yield sideEffect((dispatch) => {
    dispatch(Actions.Data.setData({ path: 'lastInteraction' }));
    dispatch(Actions.MediaLibrary.getFolders());

    if (reduction.getIn([mediaLibrary, 'folders', 'selected']) || !target) {
      dispatch(Actions.MediaLibrary.getImages({ clear: true }));
    }

    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.PHOTOS_MOVED,
        folder: Map({
          title: reduction.getIn([mediaLibrary, 'folders', 'byId', target, 'name']),
          uuid: target,
        }),
        shopId,
      })
    );
  });

  function reduceSelectedImages(result, uuid) {
    return result.remove(uuid);
  }

  const path = [mediaLibrary, 'images', 'selected'];
  const selectedImages = uuids.reduce(reduceSelectedImages, reduction.getIn(path, Set()));

  return reduction.setIn(path, selectedImages);
}

function* renameFolder(reduction, { folder, name }) {
  const { db, shopId } = getShopParams(reduction);

  yield sideEffect((dispatch) => {
    api.studio.renameFolder({ db, folder, name, shopId })
      .then(
        () => dispatch(Actions.MediaLibrary.renameFolderSucceeded({ name, uuid: folder })),
        (error) => dispatch(Actions.MediaLibrary.renameFolderFailed(error)),
      );
  });

  return reduction;
}

function* renameFolderFailed(reduction, error) {
  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.RENAME_FOLDER,
      })
    );
  });

  return reduction;
}

function* renameFolderSucceeded(reduction, { name, uuid }) {
  yield sideEffect((dispatch) => {
    dispatch(Actions.Data.setData({ path: 'lastInteraction' }));
    dispatch(Actions.MediaLibrary.getFolders());
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.SUCCESS,
        message: MESSAGE.SUCCESS.RENAME_FOLDER,
      })
    );
  });

  function updateFolder(folder) {
    return folder.set('name', name);
  }

  return reduction.updateIn([mediaLibrary, 'folders', 'byId', uuid], updateFolder);
}

function* setValue(reduction, { path, value }) {
  if (!path) return reduction;

  const fullPath = Array.isArray(path)
    ? [mediaLibrary, ...path]
    : [mediaLibrary, path];

  return value === undefined
    ? reduction.deleteIn(fullPath)
    : reduction.setIn(fullPath, value);
}

function* toggleImageState(reduction, { images, state: path, value }) {
  function reduceImageIds(result, uuid) {
    switch (value) {
      case true: {
        return result.add(uuid);
      }

      case false: {
        return result.remove(uuid);
      }

      default: {
        return result.has(uuid)
          ? result.remove(uuid)
          : result.add(uuid);
      }
    }
  }

  const fullPath = [mediaLibrary, 'images', path];
  const state = typeof images === 'string'
    ? reduceImageIds(reduction.getIn(fullPath, Set()), images)
    : Array.isArray(images) || isImmutable(images)
      ? images.reduce(reduceImageIds, reduction.getIn(fullPath, Set()))
      : null;

  return getSize(state)
    ? reduction.setIn(fullPath, state)
    : reduction.deleteIn(fullPath);
}

function* uploadFiles(reduction, { files }) {
  const current = getShopParams(reduction);
  let state = reduction.get(mediaLibrary);

  if (!getSize(files)) {
    const folder = state.getIn(['uploading', 'folder']);
    const count = state.getIn(['uploading', 'count']);
    const shopId = state.getIn(['uploading', 'shopId']);
    const total = state.getIn(['uploading', 'total']);

    if (total) {
      yield sideEffect((dispatch) => {
        if (!count) {
          dispatch(
            Actions.Notifications.add({
              message: MESSAGE.FAIL.UPLOAD_FILES,
              type: NOTIFICATION.ERROR,
            })
          );

          return;
        }

        dispatch(
          Actions.Notifications.add({
            message: count === total
              ? MESSAGE.SUCCESS.UPLOAD_FILES
              : `${count} of ${total} files uploaded`,
            type: NOTIFICATION.SUCCESS,
          })
        );

        if (shopId === current.shopId) {
          dispatch(Actions.MediaLibrary.getFolders());

          if (
            !state.getIn(['request', 'processing']) &&
            folder === state.getIn(['request', 'folder', 'uuid'])
          ) {
            dispatch(Actions.Data.setData({ path: 'lastInteraction' }));
            dispatch(Actions.MediaLibrary.getImages({ clear: true }));
          }
        }
      });
    }

    return reduction.set(mediaLibrary, state.delete('uploading'));
  }

  if (!state.get('uploading')) {
    const { channel, db, shopId } = current;
    const folder = state.getIn(['request', 'folder', 'uuid']);
    const uploading = Map({
      channel,
      count: 0,
      db,
      folder,
      images: Map({ byId: Map(), ids: List() }),
      shopId,
      total: getSize(files),
    });

    state = state.setIn(['uploading'], uploading);
  }

  yield sideEffect((dispatch) => {
    const file = files.first();
    const channel = state.getIn(['uploading', 'channel']);
    shapeFileForEditor({ channel, file })
      .then(
        (photo) => dispatch(Actions.MediaLibrary.uploadFilesSucceeded({ files, photo })),
        (error) => dispatch(Actions.MediaLibrary.uploadFilesFailed({ error, files })),
      );
  });

  return reduction.set(mediaLibrary, state);
}

function* uploadFilesFailed(reduction, { error, files, photo }) {
  let state = reduction.get(mediaLibrary);

  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(Actions.MediaLibrary.uploadFiles({ files: files.shift() }));
  });

  function updateIds(ids) {
    return ids.filter(function filterIds(id) {
      return id !== photo.id;
    });
  }

  if (photo) {
    state = state
      .deleteIn(['uploading', 'images', 'byId', photo.id])
      .updateIn(['uploading', 'images', 'ids'], updateIds);
  }

  return reduction.set(mediaLibrary, state);
}

function* uploadFilesSucceeded(reduction, { files, photo, uploaded }) {
  const current = getShopParams(reduction);
  let state = reduction.get(mediaLibrary);

  if (uploaded) {
    const folder = state.getIn(['uploading', 'folder']);
    const shopId = state.getIn(['uploading', 'shopId']);

    if (shopId === current.shopId) {
      if (folder) {
        state = state
          .updateIn(['folders', 'byId', null, 'count'], increase)
          .updateIn(['folders', 'byId', folder, 'count'], increase);
      } else {
        state = state.updateIn(['folders', 'byId', folder, 'count'], increase);
      }

      if (
        !state.getIn(['request', 'processing']) &&
        folder === state.getIn(['request', 'folder', 'uuid'])
      ) {
        const image = state
          .getIn(['uploading', 'images', 'byId', photo.id])
          .set('url', uploaded.url)
          .set('uuid', uploaded.uuid);

        state = state
          .updateIn(['images', 'total'], increase)
          .setIn(['images', 'byId', uploaded.uuid], image)
          .updateIn(['images', 'ids'], function updateIds(ids) {
            return ids.unshift(uploaded.uuid);
          });
      }
    }

    state = state
      .updateIn(['uploading', 'count'], increase)
      .deleteIn(['uploading', 'images', 'byId', photo.id])
      .updateIn(['uploading', 'images', 'ids'], function updateIds(ids) {
        return ids.filter(function filterIds(id) {
          return id !== photo.id;
        });
      });

    yield sideEffect((dispatch) => {
      dispatch(Actions.MediaLibrary.uploadFiles({ files: files.shift() }));
    });
  } else {
    const { data, format, id, mime, name, url: preview } = photo;
    state = state
      .updateIn(['uploading', 'images', 'byId'], function updateById(byId = Map()) {
        return byId.set(id,
          Map({
            altText: DEFAULTS.EMPTY_STRING,
            fileName: name,
            format,
            mime,
            url: preview,
            uuid: id,
          })
        );
      })
      .updateIn(['uploading', 'images', 'ids'], function update(ids = List()) {
        return ids.push(id);
      });

    yield sideEffect((dispatch) => {
      const folder = state.getIn(['uploading', 'folder']);
      const db = state.getIn(['uploading', 'db']);
      const shopId = state.getIn(['uploading', 'shopId']);

      api.studio
        .uploadToS3({ data, db, mime, shopId })
        .then(
          ({ url }) => {
            api.studio
              .savePhoto({ db, folder, format, name, shopId, url })
              .then(
                ({ fileUuid }) => dispatch(
                  Actions.MediaLibrary.uploadFilesSucceeded({
                    files,
                    photo,
                    uploaded: {
                      url,
                      uuid: fileUuid,
                    },
                  })
                ),
                (error) => dispatch(Actions.MediaLibrary.uploadFilesFailed({ error, files, photo })),
              );
          },
          (error) => dispatch(Actions.MediaLibrary.uploadFilesFailed({ error, files, photo })),
        );
    });
  }

  return reduction.set(mediaLibrary, state);
}

Reducers.add(
  new Reducer('Media_Library')
    .add(ACTIONS.MEDIA_LIBRARY.BOOTSTRAP, bootstrap)
    .add(ACTIONS.MEDIA_LIBRARY.CLEAN_UP, cleanUp)
    .add(ACTIONS.MEDIA_LIBRARY.CREATE_FOLDER, createFolder)
    .add(ACTIONS.MEDIA_LIBRARY.CREATE_FOLDER_FAILED, createFolderFailed)
    .add(ACTIONS.MEDIA_LIBRARY.CREATE_FOLDER_SUCCEEDED, createFolderSucceeded)
    .add(ACTIONS.MEDIA_LIBRARY.DELETE_FOLDER, deleteFolder)
    .add(ACTIONS.MEDIA_LIBRARY.DELETE_FOLDER_FAILED, deleteFolderFailed)
    .add(ACTIONS.MEDIA_LIBRARY.DELETE_FOLDER_SUCCEEDED, deleteFolderSucceeded)
    .add(ACTIONS.MEDIA_LIBRARY.DELETE_IMAGES, deleteImages)
    .add(ACTIONS.MEDIA_LIBRARY.DELETE_IMAGES_FAILED, deleteImagesFailed)
    .add(ACTIONS.MEDIA_LIBRARY.DELETE_IMAGES_SUCCEEDED, deleteImagesSucceeded)
    .add(ACTIONS.MEDIA_LIBRARY.DOWNLOAD_IMAGES, downloadImages)
    .add(ACTIONS.MEDIA_LIBRARY.DOWNLOAD_IMAGES_FAILED, downloadImagesFailed)
    .add(ACTIONS.MEDIA_LIBRARY.DOWNLOAD_IMAGES_SUCCEEDED, downloadImagesSucceeded)
    .add(ACTIONS.MEDIA_LIBRARY.GET_FOLDERS, getFolders)
    .add(ACTIONS.MEDIA_LIBRARY.GET_FOLDERS_FAILED, getFoldersFailed)
    .add(ACTIONS.MEDIA_LIBRARY.GET_FOLDERS_SUCCEEDED, getFoldersSucceeded)
    .add(ACTIONS.MEDIA_LIBRARY.GET_IMAGES, getImages)
    .add(ACTIONS.MEDIA_LIBRARY.GET_IMAGES_FAILED, getImagesFailed)
    .add(ACTIONS.MEDIA_LIBRARY.GET_IMAGES_SUCCEEDED, getImagesSucceeded)
    .add(ACTIONS.MEDIA_LIBRARY.MOVE_IMAGES, moveImages)
    .add(ACTIONS.MEDIA_LIBRARY.MOVE_IMAGES_SUCCEEDED, moveImagesSucceeded)
    .add(ACTIONS.MEDIA_LIBRARY.MOVE_IMAGES_FAILED, moveImagesFailed)
    .add(ACTIONS.MEDIA_LIBRARY.RENAME_FOLDER, renameFolder)
    .add(ACTIONS.MEDIA_LIBRARY.RENAME_FOLDER_FAILED, renameFolderFailed)
    .add(ACTIONS.MEDIA_LIBRARY.RENAME_FOLDER_SUCCEEDED, renameFolderSucceeded)
    .add(ACTIONS.MEDIA_LIBRARY.SET_VALUE, setValue)
    .add(ACTIONS.MEDIA_LIBRARY.TOGGLE_IMAGE_STATE, toggleImageState)
    .add(ACTIONS.MEDIA_LIBRARY.UPLOAD_FILES, uploadFiles)
    .add(ACTIONS.MEDIA_LIBRARY.UPLOAD_FILES_FAILED, uploadFilesFailed)
    .add(ACTIONS.MEDIA_LIBRARY.UPLOAD_FILES_SUCCEEDED, uploadFilesSucceeded)
);

