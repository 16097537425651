import { Set } from 'immutable';

import { getSize } from '../iterable/getSize';

import { MODALS } from '../../constants/modal';
import { NEW } from '../../constants/product';

export function isEditing({ profiles }) {
  return (
    !!getSize(profiles.get('editing', Set()).delete(NEW)) && (
      !profiles.get('modal') ||
      profiles.getIn(['modal', 'type']) !== MODALS.PROFILES.CANCEL
    )
  );
}
