import { isImmutable, List, Map, OrderedMap, Set } from 'immutable';

import { SEPARATOR } from './constants';

function skipValidation() {
  return null;
}
skipValidation.isRequired = skipValidation;

function makeImmutablePropMatcher(typeName, validator) {
  if (process.env.NODE_ENV === 'production') return skipValidation;

  function getPropType(instance) {
    if (Array.isArray(instance)) return 'array';

    if (isImmutable(instance)) {
      return 'Immutable.' + instance.toSource().split(SEPARATOR.SPACE)[0];
    }

    return typeof instance;
  }

  function validate(isRequired, props, propName, componentName, location, propFullName) {
    const value = props[propName];
    const name = propFullName || propName;

    if (value === null || value === undefined) {
      if (isRequired) {
        return new Error(
          `The ${location} \`${name}\` is marked as required in \`${componentName}\` but its value is \`${String(value)}\`.`
        );
      }
    } else if (!validator(value)) {
      return new Error(
        `Invalid ${location} \`${name}\` of type \`${getPropType(value)}\` supplied to \`${componentName}\`, expected \`Immutable.${typeName}\`.`
      );
    }

    return null;
  }

  const matcher = validate.bind(null, false);
  matcher.isRequired = validate.bind(null, true);

  return matcher;
}

export const Immutable = {
  List: makeImmutablePropMatcher('List', List.isList),
  Map: makeImmutablePropMatcher('Map', Map.isMap),
  OrderedMap: makeImmutablePropMatcher('OrderedMap', OrderedMap.isOrderedMap),
  Set: makeImmutablePropMatcher('Set', Set.isSet),
};
