import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Router as RouterContext } from 'react-router-dom';

import LastLocationContext from './contexts/lastLocation';

export default function Router({ children, history, ...props }) {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(
    function effect() {
      history.listen(setState);
    },
    [history],
  );

  return (
    <RouterContext
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      <LastLocationContext>
        {children}
      </LastLocationContext>
    </RouterContext>
  );
}

Router.propTypes = {
  children: PropTypes.node,
  history: PropTypes.object,
};
