import { AMAZON, CHANNEL_NAME, EBAY, ETSY, FAIRE, SHOPIFY, SQUARESPACE, WIX, WOOCOMMERCE } from './channels';
import { TEMPLATE, THEME } from '../templates/constants';

export const MEDIA = {
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  PINTEREST: 'Pinterest',
  X: 'X',
};

export const INITIAL_STATE = {
  background: undefined,
  channels: [
    CHANNEL_NAME[AMAZON],
    CHANNEL_NAME[EBAY],
    CHANNEL_NAME[ETSY],
    CHANNEL_NAME[FAIRE],
    CHANNEL_NAME[SHOPIFY],
    CHANNEL_NAME[SQUARESPACE],
    CHANNEL_NAME[WIX],
    CHANNEL_NAME[WOOCOMMERCE],
  ],
  colors: ['#F7EF99', '#F1BB87', '#F78E69', '#553A41', '#777DA7'],
  template: TEMPLATE.BASIC,
  theme: THEME.LIGHT,
  themes: [THEME.LIGHT, THEME.DARK],
};

export const MAX_TITLE_LENGTH = 155;

export const MAX_DESCRIPTION_LENGTH = {
  [TEMPLATE.BASIC]: 256,
  [TEMPLATE.CODY]: 256,
  [TEMPLATE.CONTRAST]: 256,
  [TEMPLATE.DILATED]: 256,
  [TEMPLATE.MARBLE]: 256,
  [TEMPLATE.ROUNDED]: 512,
  [TEMPLATE.VERSO]: 256,
};
