import { isImmutable } from 'immutable';

import { isObject } from '../isObject';

export function getSize(instance) {
  if (isImmutable(instance)) {
    return instance.size;
  } else if (Array.isArray(instance) || instance instanceof NodeList || typeof instance === 'string') {
    return instance.length;
  } else if (isObject(instance)) {
    return Object.keys(instance).length;
  } else {
    return 0;
  }
}
