import { useState } from 'react';

import { increase } from '../utils/math';

export function useForceUpdate() {
  const [updates, setUpdates] = useState(0);
  return {
    updates,
    forceUpdate() {
      setUpdates(increase);
    },
  };
}
