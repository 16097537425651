import { DESCRIPTION, TAGS, TITLE } from './attributes';

export const OPERATION = {
  ADD_MAXIMUM: 'add_maximum',
  ENLARGE: 'make_longer',
  FIX: 'fix_spelling_grammar',
  IMPROVE: 'improve_writing',
  REPLACE: 'replace_all',
  SHORTEN: 'make_shorter',
  TONE: 'change_tone',
  EVENTS: 'promote_event',
};

export const OPERATION_LABEL = {
  [OPERATION.ADD_MAXIMUM]: 'Add maximum',
  [OPERATION.ENLARGE]: 'Make longer',
  [OPERATION.FIX]: 'Fix spelling & grammar',
  [OPERATION.IMPROVE]: 'Improve writing',
  [OPERATION.REPLACE]: 'Replace all',
  [OPERATION.SHORTEN]: 'Make shorter',
  [OPERATION.TONE]: 'Change tone',
  [OPERATION.EVENTS]: 'Promote event',
};

export const ATTRIBUTE_OPERATIONS = {
  [DESCRIPTION]: [
    OPERATION.IMPROVE,
    OPERATION.SHORTEN,
    OPERATION.ENLARGE,
    OPERATION.FIX,
    null,
    OPERATION.TONE,
    OPERATION.EVENTS,
  ],
  [TITLE]: [
    OPERATION.IMPROVE,
    OPERATION.SHORTEN,
    OPERATION.ENLARGE,
    OPERATION.FIX,
  ],
  [TAGS]: [
    OPERATION.REPLACE,
    OPERATION.ADD_MAXIMUM,
  ],
};

export const EVENT = {
  ANNIVERSARY: 'an anniversary',
  BABY_SHOWER: 'a baby shower',
  BACHELOR_PARTY: 'a bachelor party',
  BACHELORETTE_PARTY: 'a bachelorette party',
  BACK_TO_SCHOOL: 'back to school',
  BIRTHDAY: 'a birthday',
  BLACK_FRIDAY: 'Black Friday',
  BRIDAL_SHOWER: 'a bridal shower',
  CHRISTMAS: 'Christmas',
  CYBER_MONDAY: 'Cyber Monday',
  EASTER: 'Easter',
  FATHERS_DAY: 'Father\'s Day',
  GRADUATION: 'a graduation',
  HALLOWEEN: 'Halloween',
  HANUKKAH: 'Hanukkah',
  LGBTQ_PRIDE: 'LGBTQ pride',
  MOTHERS_DAY: 'Mother\'s Day',
  NEW_YEAR: 'New Year\'s',
  THANKSGIVING: 'Thanksgiving',
  VALENTINES_DAY: 'Valentine\'s Day',
  WEDDING: 'a wedding',
};

export const EVENT_OPTIONS = [
  { label: 'Anniversary', value: EVENT.ANNIVERSARY },
  { label: 'Baby shower', value: EVENT.BABY_SHOWER },
  { label: 'Bachelor party', value: EVENT.BACHELOR_PARTY },
  { label: 'Bachelorette party', value: EVENT.BACHELORETTE_PARTY },
  { label: 'Back to school', value: EVENT.BACK_TO_SCHOOL },
  { label: 'Birthday', value: EVENT.BIRTHDAY },
  { label: 'Black Friday', value: EVENT.BLACK_FRIDAY },
  { label: 'Bridal shower', value: EVENT.BRIDAL_SHOWER },
  { label: 'Christmas', value: EVENT.CHRISTMAS },
  { label: 'Cyber Monday', value: EVENT.CYBER_MONDAY },
  { label: 'Easter', value: EVENT.EASTER },
  { label: 'Father\'s Day', value: EVENT.FATHERS_DAY },
  { label: 'Graduation', value: EVENT.GRADUATION },
  { label: 'Halloween', value: EVENT.HALLOWEEN },
  { label: 'Hanukkah', value: EVENT.HANUKKAH },
  { label: 'LGBTQ pride', value: EVENT.LGBTQ_PRIDE },
  { label: 'Mother\'s Day', value: EVENT.MOTHERS_DAY },
  { label: 'New Year\'s', value: EVENT.NEW_YEAR },
  { label: 'Thanksgiving', value: EVENT.THANKSGIVING },
  { label: 'Valentine\'s Day', value: EVENT.VALENTINES_DAY },
  { label: 'Wedding', value: EVENT.WEDDING },
];

export const TONE = {
  CASUAL: 'casual',
  EXCITED: 'excited',
  FRIENDLY: 'friendly',
  HUMOROUS: 'humorous',
  INFORMATIVE: 'informative',
  INSPIRATIONAL: 'inspirational',
  LUXURIOUS: 'luxurious',
  PERSUASIVE: 'persuasive',
  PROFESSIONAL: 'professional',
};

export const TONE_OPTIONS = [
  { label: 'Casual', value: TONE.CASUAL },
  { label: 'Excited', value: TONE.EXCITED },
  { label: 'Friendly', value: TONE.FRIENDLY },
  { label: 'Humorous', value: TONE.HUMOROUS },
  { label: 'Informative', value: TONE.INFORMATIVE },
  { label: 'Inspirational', value: TONE.INSPIRATIONAL },
  { label: 'Luxurious', value: TONE.LUXURIOUS },
  { label: 'Persuasive', value: TONE.PERSUASIVE },
  { label: 'Professional', value: TONE.PROFESSIONAL },
];
