import { List } from 'immutable';

export function moveListItem(input, from, to) {
  function validIndexes(length, ...rest) {
    function reduceIndexes(result, index) {
      return result && Number.isFinite(index) && index >= 0 && index < length;
    }

    return rest.reduce(reduceIndexes, true);
  }

  if (Array.isArray(input)) {
    if (from === to || !validIndexes(input.length, from, to)) return input;

    const result = [...input];
    const [item] = result.splice(from, 1);
    result.splice(to, 0, item);
    return result;
  } else if (List.isList(input)) {
    if (from === to || !validIndexes(input.size, from, to)) return input;

    return input.splice(from, 1).splice(to, 0, input.get(from));
  } else {
    return input;
  }
}
