
import { List, Map } from 'immutable';

import { sortCaseIgnore, sortCaseIgnoreWithNone } from './array';
import { isValidNumber } from './number';
import { isTruthy } from './bool';
import { shapeId } from './listings/listings';
import { getSize } from './iterable/getSize';

import { CHANNEL_BY_ID, ETSY, SHOPIFY } from '../constants/channels';
import { CHANNEL_DATA_KEYS } from '../constants/data';
import { EMPTY_PROFILES } from '../constants/product';
import { DEFAULTS, NONE } from '../constants';
import { PROFILE } from '../constants/profiles';

import DATA from '../data';

const { EMPTY_LIST, EMPTY_MAP, EMPTY_STRING } = DEFAULTS;

export function isChannelDataLoaded(channel) {
  function reduceKeys(loaded, key) {
    return loaded && DATA.hasOwnProperty(key);
  }

  return (
    !CHANNEL_DATA_KEYS.hasOwnProperty(channel) ||
    CHANNEL_DATA_KEYS[channel].reduce(reduceKeys, true)
  );
}

export function addChannelData({ data, key }) {
  DATA[key] = data;
}

export const shapeDataForApp = {
  productionPartners(productionPartners = []) {
    function reduceProductionPartners(result, productionPartner) {
      const { partner_name: name, production_partner_id: productionPartnerId } = productionPartner;
      const id = parseInt(productionPartnerId, 10);

      return {
        byId: result.byId.set(id, name),
        options: result.options.push(id),
      };
    }

    const { byId, options } = productionPartners.reduce(
      reduceProductionPartners,
      { byId: Map({ [EMPTY_STRING]: NONE }), options: List([EMPTY_STRING]) },
    );

    function sortProductionPartners(x, y) {
      return sortCaseIgnoreWithNone(byId.get(x), byId.get(y));
    }

    return Map({ byId, options: options.sort(sortProductionPartners) });
  },
  profiles({ profiles: source, type, userShops }) {
    function getProfileData(profile) {
      function mapTemplates(result, template) {
        const { channel_id: channelId, id: productId, shopId, userDb: db } = template;
        return isValidNumber(productId) && userShops.getIn(['byId', shopId, 'db']) === db
          ? result.push(
            Map({
              channel: CHANNEL_BY_ID[channelId],
              db,
              productId: shapeId(productId),
              shopId,
            })
          )
          : result;
      }

      switch (type) {
        case PROFILE.LISTINGS: {
          const { id, name, templates } = profile;

          if (!name || !isValidNumber(id) || !Array.isArray(templates) || !getSize(templates)) {
            return { id: shapeId(id), name, invalid: true };
          }

          return {
            id: shapeId(id),
            invalid: DEFAULTS.FALSE,
            name,
            templates: templates.reduce(mapTemplates, EMPTY_LIST),
          };
        }

        default: {
          const { id, invalid, name } = profile;
          return { id: shapeId(id), name, invalid };
        }
      }
    }

    function reduceProfiles(result, profile) {
      const { id, name, invalid, ...rest } = getProfileData(profile);

      if (invalid !== DEFAULTS.FALSE) return result;

      function updateById(byId = EMPTY_MAP) {
        return byId.set(id, Map({ name, ...rest }));
      }

      function updateOptions(options = EMPTY_LIST) {
        return id === DEFAULTS.MINUS_TWO
          ? options
          : options.push(id);
      }

      return result
        .update('byId', updateById)
        .update('options', updateOptions);
    }

    const profiles = source.reduce(reduceProfiles, EMPTY_PROFILES);

    function sortProfiles(x, y) {
      return sortCaseIgnoreWithNone(profiles.getIn(['byId', x, 'name']), profiles.getIn(['byId', y, 'name']));
    }

    return profiles.set('options', profiles.get('options').sort(sortProfiles));
  },
  returnPolicies(returnPolicies = []) {
    function reduceReturnPolicies(result, returnPolicy) {
      const {
        accepts_exchanges: acceptsExchanges,
        accepts_returns: acceptsReturns,
        return_deadline: returnDeadline,
        return_policy_id: returnPolicyId,
      } = returnPolicy;

      return {
        byId: result.byId.set(returnPolicyId, Map({ returnPolicyId, acceptsReturns, acceptsExchanges, returnDeadline })),
        options: result.options.push(returnPolicyId),
      };
    }

    const { byId, options } = returnPolicies.reduce(
      reduceReturnPolicies,
      {
        byId: Map({
          [DEFAULTS.ONE]: Map({ returnPolicyId: DEFAULTS.ONE, acceptsExchanges: false, acceptsReturns: false }),
        }),
        options: List([DEFAULTS.ONE]),
      }
    );

    function sortReturnPolicies(x, y) {
      if (!byId.hasIn([y, 'returnDeadline'])) return 1;

      if (byId.getIn([y, 'returnDeadline']) === null) return -1;

      return byId.getIn([x, 'returnDeadline']) - byId.getIn([y, 'returnDeadline']);
    }

    return Map({ byId, options: options.sort(sortReturnPolicies) });
  },
  shippingProfiles(shippingTemplates = []) {
    function reduceShippingProfiles(result, profile) {
      const { profile_type: profileType, shipping_template_id: profileId, title: name } = profile;
      const id = shapeId(profileId);

      return {
        byId: result.byId.set(id, Map({ profileType: shapeId(profileType), name })),
        options: result.options.push(id),
      };
    }

    const { byId, options } = shippingTemplates.reduce(reduceShippingProfiles, { byId: EMPTY_MAP, options: EMPTY_LIST });

    function sortShippingProfiles(x, y) {
      return sortCaseIgnore(byId.getIn([x, 'name']), byId.getIn([y, 'name']));
    }

    return Map({ byId, options: options.sort(sortShippingProfiles) });
  },
  shopData({ channel, shopData = {}}) {
    function getByIdAndOptions(source) {
      return function reduce(result, id) {
        function updateOptions(options) {
          return options.push(id);
        }

        function updateById(byId) {
          return byId.set(id, source[id]);
        }

        return result
          .update('options', updateOptions)
          .update('byId', updateById);
      };
    }

    function sortSections(sectionsById) {
      return function sort(x, y) {
        return sortCaseIgnoreWithNone(sectionsById.get(x), sectionsById.get(y));
      };
    }

    function reduceCollections(result, { label, type, value }) {
      function updateOptions(options) {
        return type === 'smart' ? options : options.push(value);
      }

      function updateById(byId) {
        return byId.set(value, Map({ label, type }));
      }

      return result
        .update('options', updateOptions)
        .update('byId', updateById);
    }

    function sortCollections(collectionsById) {
      return function sort(x, y) {
        return sortCaseIgnoreWithNone(
          collectionsById.getIn([x, 'label']),
          collectionsById.getIn([y, 'label'])
        );
      };
    }

    switch (channel) {
      case ETSY: {
        const result = {};
        const { sectionsMap: { shopId, ids: sectionIds = [], ...sections } = {}} = shopData;

        result.sections = sectionIds.reduce(
          getByIdAndOptions(sections),
          Map({ byId: Map({ none: NONE }), options: List(['none']) })
        );

        result.sections = result.sections.set('options',
          result.sections.get('options').sort(sortSections(result.sections.get('byId')))
        );

        return result;
      }

      case SHOPIFY: {
        const { collections = [], productTypes = [], vendors = [] } = shopData;

        const result = {
          productTypes: List(productTypes).filter(isTruthy).sort(sortCaseIgnore).unshift(NONE),
          vendors: List(vendors).filter(isTruthy).sort(sortCaseIgnore),
        };

        result.collections = collections.reduce(
          reduceCollections,
          Map({ byId: Map({ [EMPTY_STRING]: Map({ label: NONE }) }), options: List([EMPTY_STRING]) })
        );

        result.collections = result.collections.set('options',
          result.collections.get('options').sort(sortCollections(result.collections.get('byId')))
        );

        return result;
      }

      default: {
        return {};
      }
    }
  },
};
