import { Map } from 'immutable';

import { startsWithAlphanumeric, usedMoreThanAllowed } from './utils';
import { isValidNumber } from '../number';

import { CHAR_COUNT_MAX, ENABLED, INSTRUCTIONS } from '../../constants/attributes';
import {
  ERRORS,
  PERSONALIZATION_CHARACTERS_COUNT_MIN,
  PERSONALIZATION_CHARACTERS_COUNT_MAX,
  PERSONALIZATION_INSTRUCTION_LENGTH_MAX,
} from '../../constants/validations';

export function getInstructionsError({ allowStartWithAnyCharacter, channel, value }) {
  if (!value.length) {
    return false;
  } else if (value.length > PERSONALIZATION_INSTRUCTION_LENGTH_MAX[channel]) {
    return ERRORS.PERSONALIZATION.LENGTH_EXCEEDED[channel];
  } else if (!allowStartWithAnyCharacter && !startsWithAlphanumeric(value)) {
    return ERRORS.PERSONALIZATION.ALPHANUMERIC_BEGIN;
  } else if (usedMoreThanAllowed({ patterns: ['\\b\\p{Lu}{3,}\\b'], number: 1, value })) {
    return ERRORS.PERSONALIZATION.CAPITALIZED_WORDS_NUMBER_EXCEEDED;
  } else {
    return false;
  }
}

export function getCharactersCountError({ channel, value }) {
  return (
    (
      !isValidNumber(value) ||
      parseInt(value, 10) !== parseFloat(value) ||
      value < PERSONALIZATION_CHARACTERS_COUNT_MIN[channel] ||
      value > PERSONALIZATION_CHARACTERS_COUNT_MAX[channel]
    ) && ERRORS.PERSONALIZATION.LIMIT_OUT_OF_RANGE[channel]
  );
}

export function validatePersonalization({ channel, personalization }) {
  let errors = Map();

  if (personalization.get(ENABLED)) {
    const instructionsError = getInstructionsError({
      channel,
      value: personalization.getIn([INSTRUCTIONS, 'value']),
    });

    if (instructionsError) {
      errors = errors.set(INSTRUCTIONS, instructionsError);
    }

    const charactersCountError = getCharactersCountError({
      channel,
      value: personalization.getIn([CHAR_COUNT_MAX, 'value']),
    });

    if (charactersCountError) {
      errors = errors.set(CHAR_COUNT_MAX, charactersCountError);
    }
  }

  return errors;
}
