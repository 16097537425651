/* eslint-disable no-console */
import { forEach } from './iterable/forEach';
import { reduce } from './iterable/reduce';

export function getFromLocalStorage(values) {
  function callback(result, notSetValue, key) {
    try {
      result[key] = JSON.parse(window.localStorage.getItem(key));
    } catch (error) {
      result[key] = notSetValue;
    }

    return result;
  }

  return reduce(values, {}, callback);
}

export function saveToLocalStorage(values) {
  function callback(value, key) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  try {
    forEach(values, callback);
  } catch (error) {
    console.error(error);
  }
}
