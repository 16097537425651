import { useEffect, useRef } from 'react';

export function useClickOutside({ enabled, handler, target }) {
  const paused = useRef(false);

  useEffect(
    function effect() {
      const multiple = Array.isArray(target);

      function targetMatch(element) {
        let current = element;

        while (current.parentElement) {
          const match = multiple
            ? target.includes(current)
            : current === target;

          if (match) return true;

          current = current.parentElement;
        }

        return multiple
          ? target.includes(current)
          : current === target;
      }

      function onClick(event) {
        if (!enabled || paused.current || typeof handler !== 'function') {
          paused.current = false;
          return;
        }

        if (!targetMatch(event.target)) {
          handler(event);
        }
      }

      function onMouseDown(event) {
        paused.current = false;

        if (!enabled || typeof handler !== 'function') return;

        if (targetMatch(event.target)) {
          paused.current = true;
        }
      }

      function cleanup() {
        document.removeEventListener('mousedown', onMouseDown, true);
        document.removeEventListener('click', onClick, true);
      }

      if (!enabled || !target || (multiple && !target.length)) return cleanup;

      document.addEventListener('mousedown', onMouseDown, true);
      document.addEventListener('click', onClick, true);

      return cleanup;
    },
    [enabled, handler, target]
  );
}
