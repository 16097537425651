import { List, Map } from 'immutable';
import XRegExp from 'xregexp';
import Big from 'big.js';

import { isUploadFilesSupported } from '../product/checkAttribute';
import { toLowerCase } from '../string';
import { getSize } from '../iterable/getSize';
import { get } from '../iterable/get';

import { ERRORS, MAX_FILE_NAME_LENGTH, MAX_FILE_SIZE } from '../../constants/validations';
import { OPERATIONS, SECTIONS, VALUE } from '../../constants/product';
import { DIGITAL, FILES } from '../../constants/attributes';
import { DEFAULTS } from '../../constants';
import { ETSY } from '../../constants/channels';

const { EMPTY_LIST, EMPTY_STRING } = DEFAULTS;

function getFileSizeError({ channel, value }) {
  return (
    channel in MAX_FILE_SIZE &&
    new Big(MAX_FILE_SIZE[channel]).lt(value) &&
    ERRORS.FILES.TOO_BIG[channel]
  );
}

export function validateNames({ channel, file, files = [], fileNames = EMPTY_LIST, operationType, startIndex }) {
  function reduceNames(result, fileName) {
    return result || (
      typeof fileName === 'string' &&
      toLowerCase(fileName) === toLowerCase(get('name')(file)) &&
      `File with name "${get('name')(file)}" already exist`
    );
  }

  switch (operationType) {
    case OPERATIONS[channel].FILES.ADD: {
      return fileNames
        .concat(List(files.map(get('name'))))
        .reduce(reduceNames, false);
    }

    case OPERATIONS[channel].FILES.REPLACE: {
      return fileNames
        .splice(startIndex, getSize(files) + 1, ...files.map(get('name')))
        .reduce(reduceNames, false);
    }

    default: {
      return false;
    }
  }
}

function getFileNameError({ channel, value }) {
  const name = (value.match(/.*(?=\.[a-zA-Z0-9]*$)/) || [EMPTY_STRING])[0];

  if (channel in MAX_FILE_NAME_LENGTH && name.length > MAX_FILE_NAME_LENGTH[channel]) {
    return ERRORS.FILES.NAME_LENGTH_EXCEEDED[channel];
  }

  switch (channel) {
    case ETSY: {
      return (
        XRegExp('[^\\p{L}\\p{Nd}-_\.]').test(name) &&
        ERRORS.FILES.NAME_FORBIDDEN_CHARACTERS[channel]
      );
    }

    default: {
      return false;
    }
  }
}

export function getFileError({ channel, file }) {
  const { name, size } = file;

  return (
    getFileSizeError({ channel, value: size }) ||
    getFileNameError({ channel, value: name })
  );
}

export function validateFiles({ channel, product }) {
  let errors = Map();

  switch (channel) {
    case ETSY: {
      if (!product.getIn([SECTIONS.DETAILS, DIGITAL, VALUE])) break;

      if (isUploadFilesSupported({ channel, product })) {
        if (!getSize(product.getIn([FILES, VALUE]))) {
          errors = errors.set(FILES, ERRORS.FILES.REQUIRED);
        }
      } else if (getSize(product.getIn([FILES, VALUE]))) {
        errors = errors.set(FILES, ERRORS.DETAILS.DIGITAL_MADE_TO_ORDER);
      }

      break;
    }

    default: {
      break;
    }
  }

  return errors;
}
