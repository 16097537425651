import { List, Map, Set } from 'immutable';

import { PROFILE, SALE_PROFILE_FILTER } from '../../constants/profiles';

export function getFilters({ type }) {
  switch (type) {
    case PROFILE.SALES: {
      return Map({
        Status: Map({
          byId: Map({
            [SALE_PROFILE_FILTER.PENDING]: Map({ count: 0, name: 'Pending' }),
            [SALE_PROFILE_FILTER.ACTIVE]: Map({ count: 0, name: 'Active' }),
            [SALE_PROFILE_FILTER.COMPLETED]: Map({ count: 0, name: 'Complete' }),
          }),
          options: List([
            SALE_PROFILE_FILTER.PENDING,
            SALE_PROFILE_FILTER.ACTIVE,
            SALE_PROFILE_FILTER.COMPLETED,
          ]),
          selected: Set(),
        }),
      });
    }

    default: {
      return undefined;
    }
  }
}
