import { List, Map, Set } from 'immutable';

import { shapeOfferingForApp, shapeVariationForApp, sortEtsyVariations } from '../profiles/shapeForApp';
import { convertBodyHTMLToMetaDescription } from '../../utils/product/convert';
import { isValidNumber, toFixed, toString } from '../number';
import { getInventoryFromVariations } from '../inventory';
import { getIndividualVariations } from '../variations/getIndividualVariations';
import { photosFromImagesById } from '../photos';
import { videosFromProduct } from '../videos';
import { setProductErrors } from './errors';
import { tagsFromArray } from '../tags';
import { shapeId } from '../listings/listings';
import { getSize } from '../iterable/getSize';
import { get } from '../iterable/get';

import { ABOUT_LIST, OVERSET, SECTIONS, VALUE } from '../../constants/product';
import { INDIVIDUAL, INDIVIDUAL_FLAGS } from '../../constants/profiles';
import { SEO_URL_HANDLE_ENABLED } from '../../constants/featureFlags';
import { DEFAULTS, SEPARATOR } from '../../constants';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { STATUS } from '../../constants/listings';
import {
  CATEGORY,
  CHAR_COUNT_MAX,
  COLLECTIONS,
  DESCRIPTION,
  DIGITAL,
  ENABLED,
  FILES,
  HEIGHT,
  INSTRUCTIONS,
  IS_SUPPLY,
  LENGTH,
  MATERIALS,
  META_DESCRIPTION,
  PAGE_TITLE,
  PERSONALIZATION,
  PRICE,
  PRODUCTION_PARTNERS,
  PRODUCT_TYPE,
  PROFILE_ID,
  PROFILE_TYPE,
  QUANTITY,
  REQUIRED,
  RETURN_POLICY,
  SECTION,
  SEO,
  SHIPPING,
  SHOULD_AUTO_RENEW,
  SKU,
  TAXONOMY_ATTRIBUTES,
  URL_HANDLE,
  VARIATIONS,
  VENDOR,
  WEIGHT,
  WHEN_MADE,
  WHO_MADE,
  WIDTH,
} from '../../constants/attributes';

const { EMPTY_STRING, FALSE, NULL, ZERO } = DEFAULTS;
const { DETAILS, INVENTORY } = SECTIONS;

export function shapePersonalizationForApp(data) {
  const [
    personalizationEnabled,
    personalizationRequired,
    personalizationInstructions,
    personalizationCharCountMax,
  ] = get(
    'is_personalizable',
    'personalization_is_required',
    'personalization_instructions',
    'personalization_char_count_max',
  )(data);

  const enabled = typeof personalizationEnabled === 'boolean' ? personalizationEnabled : FALSE;
  const required = typeof personalizationRequired === 'boolean' ? personalizationRequired : FALSE;
  const instructions = personalizationInstructions || EMPTY_STRING;
  const charCountMax = isValidNumber(personalizationCharCountMax) ? personalizationCharCountMax : NULL;
  return Map({
    [ENABLED]: enabled,
    [CHAR_COUNT_MAX]: Map({ [VALUE]: charCountMax }),
    [INSTRUCTIONS]: Map({ [VALUE]: instructions }),
    [REQUIRED]: required,
  });
}

function shapeTaxonomyAttributes(attributes) {
  function parse(string) {
    return parseInt(string, 10) || -1;
  }

  function reduceAttributes(result, attribute) {
    const propertyId = parseInt(attribute.propertyId, 10);

    return isNaN(propertyId)
      ? result
      : result.set(propertyId, Map({
        scaleId: parseInt(attribute.scaleId, 10) || null,
        values: List(attribute.values || []),
        valueIds: List((attribute.valueIds || []).map(parse)),
      }));
  }

  return attributes.reduce(reduceAttributes, Map());
}

export function getShopIdMatchError({ productId, requestedShopId, shopId }) {
  return `Shop ID "${shopId}" of requested product "${productId}" does not match requested shop ID "${requestedShopId}"`;
}

export function shapeProductForApp({ channel, data, productId: id, shopId: requestedShopId }) {
  const { imagesById = {}, products = [], productsById = {}, videos = {}} = data;
  const product = productsById[id] || productsById[products[0]];

  const {
    tags,
    title,
    id: productId,
    shop_id: shopId,
    link_id: linkId,
    photos: imageIds,
    is_copied: copied,
  } = product;

  if (shapeId(shopId) !== requestedShopId) {
    throw new Error(getShopIdMatchError({ productId, requestedShopId, shopId }));
  }

  let result = Map({
    channel,
    linkId: shapeId(linkId),
    photos: Map({ [VALUE]: photosFromImagesById({ imagesById, imageIds }) }),
    videos: Map({ [VALUE]: videosFromProduct({ videos, productId }) }),
    productId,
    shopId: shapeId(shopId),
    tags: Map({ [VALUE]: tagsFromArray(tags) }),
    title: Map({ [VALUE]: title || EMPTY_STRING }),
  });

  function getAboutValue({ attribute, value }) {
    return ABOUT_LIST[channel][attribute]?.includes(value)
      ? value
      : NULL;
  }

  function shapeFilesForApp(files = []) {
    function mapFiles({ create_timestamp: created, filename, filesize, file_url: url, name, size }) {
      return Map({
        created: isValidNumber(created) ? created * 1000 : undefined,
        file_url: url,
        name: filename || name,
        size: filesize || size,
      });
    }

    return List(files.map(mapFiles));
  }

  // DEV-1481 corrupted data fix
  function reduceEtsyOfferings(reduction, offering) {
    if (!getSize(get('variationOptions')(offering))) return reduction;

    const key = get('variationOptions')(offering).map(get('optionId')).join(SEPARATOR.HYPHEN);

    if (reduction.options.has(key)) return reduction;

    reduction.options = reduction.options.add(key);
    reduction.offerings = reduction.offerings.push(
      shapeOfferingForApp({
        channel,
        forProduct: true,
        variations: reduction.variations,
      })(offering)
    );

    return reduction;
  }

  switch (channel) {
    case ETSY: {
      const {
        files,
        price,
        quantity,
        materials,
        description,
        state: status,
        attributes = [],
        who_made: whoMade,
        is_supply: isSupply,
        when_made: whenMade,
        section_id: section,
        item_width: width,
        item_weight: weight,
        item_height: height,
        item_length: length,
        is_digital: digital,
        productOfferings = [],
        taxonomy_id: taxonomyId,
        listing_id: channelProductId,
        return_policy_id: returnPolicyId,
        variations: productVariations = {},
        should_auto_renew: shouldAutoRenew,
        shipping_template_id: shippingTemplateId,
        shipping_template_type: shippingTemplateType,
        production_partner_ids: productionPartnerIds,
        merged_products_shop_ids: linkedProductsShopIds,
      } = product;

      const { sku = NULL } = productOfferings[0] || {};
      const category = shapeId(taxonomyId) || ZERO;

      const details = Map({
        [CATEGORY]: Map({ [VALUE]: category }),
        [DIGITAL]: Map({ [VALUE]: !!digital }),
        [IS_SUPPLY]: Map({ [VALUE]: typeof isSupply === 'boolean' ? isSupply : NULL }),
        [MATERIALS]: Map({ [VALUE]: tagsFromArray(materials) }),
        [PRODUCTION_PARTNERS]: Map({ [VALUE]: List(productionPartnerIds) }),
        [SECTION]: Map({ [VALUE]: section }),
        [SHOULD_AUTO_RENEW]: Map({ [VALUE]: !!shouldAutoRenew }),
        [WHEN_MADE]: Map({ [VALUE]: getAboutValue({ attribute: WHEN_MADE, [VALUE]: whenMade }) }),
        [WHO_MADE]: Map({ [VALUE]: getAboutValue({ attribute: WHO_MADE, [VALUE]: whoMade }) }),
        [TAXONOMY_ATTRIBUTES]: shapeTaxonomyAttributes(attributes),
      });

      let inventory = Map({
        [SKU]: Map({ [VALUE]: sku || EMPTY_STRING }),
        [PRICE]: Map({ [VALUE]: toFixed(price, 2) || EMPTY_STRING }),
        [QUANTITY]: Map({ [VALUE]: toString(quantity) || EMPTY_STRING }),
      });

      if (digital) {
        result = result.setIn([FILES, VALUE], shapeFilesForApp(files));
        result = result.set(VARIATIONS,
          Map({ category, errors: Map(), offerings: List(), variations: List() })
        );
      } else {
        result = result.set(SHIPPING,
          Map({
            [HEIGHT]: Map({ [VALUE]: isValidNumber(height) ? toString(height) : height || EMPTY_STRING }),
            [LENGTH]: Map({ [VALUE]: isValidNumber(length) ? toString(length) : length || EMPTY_STRING }),
            [PROFILE_ID]: Map({ [VALUE]: isValidNumber(shippingTemplateId) ? toString(shippingTemplateId) : DEFAULTS.NULL }),
            [PROFILE_TYPE]: Map({ [VALUE]: isValidNumber(shippingTemplateType) ? toString(shippingTemplateType) : DEFAULTS.NULL }),
            [RETURN_POLICY]: Map({ [VALUE]: shapeId(returnPolicyId) || DEFAULTS.ONE }),
            [WEIGHT]: Map({ [VALUE]: isValidNumber(weight) ? toString(weight) : weight || EMPTY_STRING }),
            [WIDTH]: Map({ [VALUE]: isValidNumber(width) ? toString(width) : width || EMPTY_STRING }),
          })
        );

        if (Object.keys(productVariations)?.length) {
          const profile = { category, errors: Map() };

          profile.variations = List(
            Object.values(productVariations)
              .sort(sortEtsyVariations)
              .map(shapeVariationForApp({ channel, category, imagesById }))
          );

          const reduced = productOfferings.reduce(
            reduceEtsyOfferings,
            {
              offerings: List(),
              options: Set(),
              variations: profile.variations,
            }
          );

          profile.offerings = reduced.offerings;

          const numberOfVariations = getSize(profile.variations);
          const individual = getIndividualVariations({
            properties: INDIVIDUAL_FLAGS[channel],
            variations: profile.variations,
          });

          inventory = inventory
            .setIn([PRICE, 'overset'], !!getSize(individual[INDIVIDUAL.PRICE]))
            .setIn([QUANTITY, 'overset'], !!getSize(individual[INDIVIDUAL.QUANTITY]))
            .setIn([SKU, 'overset'], !!getSize(individual[INDIVIDUAL.SKU]));

          profile[INDIVIDUAL.PRICE] = getSize(individual[INDIVIDUAL.PRICE]) === numberOfVariations;
          profile[INDIVIDUAL.QUANTITY] = getSize(individual[INDIVIDUAL.QUANTITY]) === numberOfVariations;
          profile[INDIVIDUAL.SKU] = getSize(individual[INDIVIDUAL.SKU]) === numberOfVariations;
          profile.hasBothIndividual = (
            profile[INDIVIDUAL.PRICE] ||
            profile[INDIVIDUAL.QUANTITY] ||
            profile[INDIVIDUAL.SKU]
          );

          result = result.set(VARIATIONS, Map(profile));
        } else {
          result = result.set(VARIATIONS,
            Map({
              category,
              errors: Map(),
              offerings: List(),
              variations: List(),
            })
          );
        }
      }

      result = result
        .set(DETAILS, details)
        .set(INVENTORY, inventory)
        .set(PERSONALIZATION, shapePersonalizationForApp(product))
        .setIn([VARIATIONS, 'channel'], channel)
        .set('status', copied ? STATUS.COPIED : status)
        .set('channelProductId', shapeId(channelProductId))
        .set('linkedProductsShopIds', linkedProductsShopIds.map(shapeId))
        .setIn([DESCRIPTION, VALUE], description || EMPTY_STRING);
      break;
    }

    case SHOPIFY: {
      const {
        status,
        vendor,
        variations,
        body_html: description,
        product_type: productType,
        collection_ids: collections,
        product_id: channelProductId,
        merged_products_shop_ids: linkedProductsShopIds,
      } = product;

      const details = Map({
        [VENDOR]: Map({ [VALUE]: vendor }),
        [PRODUCT_TYPE]: Map({ [VALUE]: productType }),
        [COLLECTIONS]: Map({ [VALUE]: List(collections) }),
      });

      const {
        seo_title: pageTitle,
        seo_description: metaDescription,
        seo_url: urlHandle,
      } = product;

      let seo = Map({
        [PAGE_TITLE]: Map({ [VALUE]: pageTitle || EMPTY_STRING }),
        [META_DESCRIPTION]: Map({ [VALUE]: metaDescription || EMPTY_STRING }),
      });

      if (!pageTitle || pageTitle === title) {
        seo = seo.set(PAGE_TITLE, Map({ [VALUE]: title, [OVERSET]: true }));
      }

      if (!metaDescription) {
        const plainDescription = convertBodyHTMLToMetaDescription(description);
        seo = seo.set(META_DESCRIPTION, Map({ [VALUE]: plainDescription, [OVERSET]: true }));
      } else {
        const plainDescription = convertBodyHTMLToMetaDescription(description);

        if (metaDescription === plainDescription) {
          seo = seo.setIn([META_DESCRIPTION, OVERSET], true);
        }
      }

      if (SEO_URL_HANDLE_ENABLED) {
        seo = seo.set(URL_HANDLE, Map({ [VALUE]: urlHandle || EMPTY_STRING }));
      }

      result = getInventoryFromVariations({ channel, imagesById, product: result, variations })
        .set(DETAILS, details)
        .set(SEO, seo)
        .set('channelProductId', channelProductId)
        .set('status', copied ? STATUS.COPIED : status)
        .set('linkedProductsShopIds', linkedProductsShopIds.map(shapeId))
        .setIn([DESCRIPTION, VALUE], description || EMPTY_STRING);

      break;
    }

    default: {
      break;
    }
  }

  return setProductErrors({ product: result });
}

export function sortShops({ first, prioritized = Set(), sorted = List() }) {
  return function sort(x, y) {
    return x === first
      ? -1
      : y === first
        ? 1
        : prioritized.has(x) === prioritized.has(y)
          ? Math.sign(sorted.indexOf(x) - sorted.indexOf(y))
          : prioritized.has(y) - prioritized.has(x);
  };
}

export function reduceShopIdsToChannels(shopsById) {
  return function reduce(result, shopId) {
    function updateChannel(channelShopIds = List()) {
      return channelShopIds.push(shopId);
    }

    return result.update(shopsById.getIn([shopId, 'channel']), updateChannel);
  };
}
