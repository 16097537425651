export const CONTACT_EMAIL = 'contact@getvela.com';

export const SUPPORT_EMAIL = 'support@getvela.com';

export const MINIMUM_PASSWORD_LENGTH = 6;

export const RESET_PASSWORD_STATUS = {
  QUERY_REQUESTED: 'query_requested',
  QUERY_ACCEPTED: 'query_accepted',
  QUERY_REJECTED: 'query_rejected',
  COMMIT_REQUESTED: 'commit_requested',
  COMMIT_ACCEPTED: 'commit_accepted',
  COMMIT_REJECTED: 'commit_rejected',
};
