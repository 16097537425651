import { List, Map } from 'immutable';

import { addThousandsSeparator, isValidNumber } from '../number';
import { formatDate } from '../time';
import { getSize } from '../iterable/getSize';

import { DEFAULTS, SEPARATOR, VELA } from '../../constants';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { LISTING } from '../../constants/listings';

const { EMPTY_LIST, EMPTY_MAP, EMPTY_STRING } = DEFAULTS;

export function shapeBool(value) {
  return !!value;
}

export function shapeCollections({ collections, shopData }) {
  function getCollection(collection) {
    return shopData.getIn(['collections', 'byId', collection, 'label'], collection);
  }

  return getSize(collections)
    ? collections.map(getCollection).join(SEPARATOR.COMMA_SPACE)
    : EMPTY_STRING;
}

export function shapeDate(date) {
  return date && formatDate({
    date,
    options: { day: 'numeric', month: 'numeric', year: '2-digit' },
  });
}

export function shapeId(value) {
  return isValidNumber(value)
    ? String(value)
    : value;
}

export function shapeLinkedShops({ shopIds, userShops }) {
  function shopExists(shopId) {
    return userShops.hasIn(['byId', shopId]);
  }

  function getShopChannel(shopId) {
    return userShops.getIn(['byId', shopId, 'channel']);
  }

  const linkedProductsShopIds = shopIds.map(shapeId).filter(shopExists);
  const linkedProductsShopChannels = linkedProductsShopIds.map(getShopChannel);

  return {
    linkedProductsShopIds,
    linkedProductsShopChannels,
  };
}

export function shapeNumber(value) {
  return parseInt(value, 10) || 0;
}

export function shapePrice(value) {
  return isValidNumber(value)
    ? addThousandsSeparator(value, 2, true)
    : DEFAULTS.ZERO_CENT;
}

export function shapePriceVariant(value) {
  const variant = shapeNumber(value);
  return variant > 1
    ? variant
    : 0;
}

export function shapeQuantity(value) {
  return addThousandsSeparator(value);
}

export function shapeSection({ section, shopData }) {
  return shopData.getIn(['sections', 'byId', section]);
}

export function shapeConnectedProduct({ response }) {
  function reduceProducts(result, product) {
    const { id, title } = product;

    return result.push(
      Map({
        productId: shapeId(id),
        title,
        thumbnailUrl: product.image,
      })
    );
  }
  return response.connectedProducts[0].products.reduce(reduceProducts, EMPTY_LIST);
}

export function shapeListingsForApp({ channel, response, shopData, userShops }) {
  function shapeListing(listing) {
    switch (channel) {
      case ETSY: {
        const channelProductId = shapeId(listing[LISTING[ETSY].CHANNEL_PRODUCT_ID]);
        const expiresAt = shapeDate(listing[LISTING[ETSY].EXPIRES_AT]);
        const isComplete = shapeBool(listing[LISTING[ETSY].IS_COMPLETE]);
        const linkId = shapeId(listing[LISTING[ETSY].LINK_ID]);
        const photo = listing[LISTING[ETSY].PHOTO];
        const price = shapePrice(listing[LISTING[ETSY].PRICE]);
        const priceVariant = shapePriceVariant(listing[LISTING[ETSY].PRICE_VARIANT]);
        const productId = shapeId(listing[LISTING[ETSY].PRODUCT_ID]);
        const quantity = shapeQuantity(listing[LISTING[ETSY].QUANTITY]);
        const section = shapeSection({ section: listing[LISTING[ETSY].SECTION_ID], shopData });
        const title = listing[LISTING[ETSY].TITLE];
        const updatedAt = shapeDate(listing[LISTING[ETSY].UPDATED_AT]);
        const {
          linkedProductsShopIds,
          linkedProductsShopChannels,
        } = shapeLinkedShops({
          shopIds: List(listing[LISTING[ETSY].LINKED_PRODUCTS_SHOP_IDS]),
          userShops,
        });

        return Map({
          [LISTING[VELA].CHANNEL_PRODUCT_ID]: channelProductId,
          [LISTING[VELA].EXPIRES_AT]: expiresAt,
          [LISTING[VELA].IS_COMPLETE]: isComplete,
          [LISTING[VELA].LINK_ID]: linkId,
          [LISTING[VELA].PHOTO]: photo,
          [LISTING[VELA].PRICE]: price,
          [LISTING[VELA].PRICE_VARIANT]: priceVariant,
          [LISTING[VELA].PRODUCT_ID]: productId,
          [LISTING[VELA].QUANTITY]: quantity,
          [LISTING[VELA].SECTION]: section,
          [LISTING[VELA].TITLE]: title,
          [LISTING[VELA].UPDATED_AT]: updatedAt,
          [LISTING[VELA].LINKED_PRODUCTS_SHOP_IDS]: linkedProductsShopIds,
          [LISTING[VELA].LINKED_PRODUCTS_SHOP_CHANNELS]: linkedProductsShopChannels,
        });
      }

      case SHOPIFY: {
        const channelProductId = listing[LISTING[SHOPIFY].CHANNEL_PRODUCT_ID];
        const collections = shapeCollections({
          collections: listing[LISTING[SHOPIFY].COLLECTION_IDS],
          shopData,
        });

        const isComplete = shapeBool(listing[LISTING[SHOPIFY].IS_COMPLETE]);
        const linkId = shapeId(listing[LISTING[SHOPIFY].LINK_ID]);
        const photo = listing[LISTING[SHOPIFY].PHOTO];
        const price = shapePrice(listing[LISTING[SHOPIFY].PRICE]);
        const priceVariant = shapePriceVariant(listing[LISTING[SHOPIFY].PRICE_VARIANT]);
        const productId = shapeId(listing[LISTING[SHOPIFY].PRODUCT_ID]);
        const publishedAt = shapeDate(listing[LISTING[SHOPIFY].PUBLISHED_AT]);
        const quantity = shapeQuantity(listing[LISTING[SHOPIFY].QUANTITY]);
        const title = listing[LISTING[SHOPIFY].TITLE];
        const updatedAt = shapeDate(listing[LISTING[SHOPIFY].UPDATED_AT]);
        const {
          linkedProductsShopIds,
          linkedProductsShopChannels,
        } = shapeLinkedShops({
          shopIds: List(listing[LISTING[SHOPIFY].LINKED_PRODUCTS_SHOP_IDS]),
          userShops,
        });

        return Map({
          [LISTING[VELA].CHANNEL_PRODUCT_ID]: channelProductId,
          [LISTING[VELA].COLLECTIONS]: collections,
          [LISTING[VELA].IS_COMPLETE]: isComplete,
          [LISTING[VELA].LINK_ID]: linkId,
          [LISTING[VELA].PHOTO]: photo,
          [LISTING[VELA].PRICE]: price,
          [LISTING[VELA].PRICE_VARIANT]: priceVariant,
          [LISTING[VELA].PRODUCT_ID]: productId,
          [LISTING[VELA].PUBLISHED_AT]: publishedAt,
          [LISTING[VELA].QUANTITY]: quantity,
          [LISTING[VELA].TITLE]: title,
          [LISTING[VELA].UPDATED_AT]: updatedAt,
          [LISTING[VELA].LINKED_PRODUCTS_SHOP_IDS]: linkedProductsShopIds,
          [LISTING[VELA].LINKED_PRODUCTS_SHOP_CHANNELS]: linkedProductsShopChannels,
        });
      }

      default: {
        return undefined;
      }
    }
  }

  function reduceListings(result, listing) {
    const productId = shapeId(listing[LISTING[channel].PRODUCT_ID]);
    const product = shapeListing(listing);

    if (!product) return result;

    function updateById(byId) {
      return byId.set(productId, product);
    }

    function updateIds(ids) {
      return ids.push(productId);
    }

    result.byId = result.byId.update(updateById);
    result.ids = result.ids.update(updateIds);

    return result;
  }

  return response.listings.reduce(reduceListings, { byId: EMPTY_MAP, ids: EMPTY_LIST });
}
