/* eslint-disable no-console */
import { sideEffect } from 'redux-side-effects';
import { List, Map } from 'immutable';
import { v4 } from 'uuid';

import { createController } from '../utils/reducer';
import { keysToCamelCase } from '../utils/string';
import { formatAmount } from '../utils/affiliates';
import { isTruthy } from '../utils/bool';
import { getSize } from '../utils/iterable/getSize';
import api from '../utils/api';

import { MESSAGE, NOTIFICATION } from '../constants/notifications';
import { APPLY_STATUS } from '../constants/affiliates';
import { DEFAULTS } from '../constants';
import { MODALS } from '../constants/modal';
import ACTIONS from '../constants/actions';

import Reducers, { Reducer } from '../classes/Reducer';
import Actions from '../actions';

const creditsController = createController();
const historyController = createController();
const linkController = createController();
const payoutController = createController();

const EMPTY_STATE = Map({
  link: DEFAULTS.EMPTY_STRING,
  history: DEFAULTS.EMPTY_LIST,
  payout: Map({
    availablePayoutAmount: 0,
    milestones: DEFAULTS.EMPTY_LIST,
    totalPayoutAmount: 0,
  }),
});

function abort(controller) {
  if (controller.isActive()) {
    controller.stop();
  }
}

function abortAll() {
  [
    creditsController,
    historyController,
    linkController,
    payoutController,
  ].forEach(abort);
}

function* bootstrap(reduction) {
  abortAll();

  switch (reduction.getIn(['user', 'affiliateStatus'])) {
    case APPLY_STATUS.ACTIVE:
    case APPLY_STATUS.SUBMITTED: {
      yield sideEffect((dispatch) => {
        dispatch(Actions.Affiliate.getHistory());
        dispatch(Actions.Affiliate.getLink());
        dispatch(Actions.Affiliate.getPayout());
      });

      return reduction;
    }

    case APPLY_STATUS.REJECTED: {
      return reduction.set('affiliate', EMPTY_STATE);
    }

    default: {
      return reduction.set('affiliate', EMPTY_STATE.set('modal', Map({ type: MODALS.AFFILIATE.SURVEY.APPLY })));
    }
  }
}

function* cleanUp(reduction) {
  abortAll();
  return reduction.set('affiliate', Map());
}

function* getHistory(reduction) {
  yield sideEffect((dispatch) => {
    const { signal } = historyController.start();

    function onSuccess(response) {
      historyController.stop();
      dispatch(Actions.Affiliate.getHistorySucceeded(response));
    }

    function onFail(error) {
      if (signal.aborted) return;

      historyController.stop();
      dispatch(Actions.Affiliate.getHistoryFailed(error));
    }

    api.affiliates
      .getHistory()
      .then(onSuccess, onFail);
  });

  return reduction;
}

function* getHistoryFailed(reduction, error) {
  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_DATA,
      })
    );
  });

  return reduction;
}

function* getHistorySucceeded(reduction, response) {
  function reduceData(history, item) {
    const { amount, ...rest } = item;
    const key = v4();

    return history.push(
      Map({
        ...keysToCamelCase(rest),
        amount: amount && formatAmount(amount),
        key,
      })
    );
  }

  return reduction.setIn(['affiliate', 'history'], response.data.reduce(reduceData, List()));
}

function* getLink(reduction) {
  yield sideEffect((dispatch) => {
    const { signal } = linkController.start();

    function onSuccess(response) {
      linkController.stop();
      dispatch(Actions.Affiliate.getLinkSucceeded(response));
    }

    function onFail(error) {
      if (signal.aborted) return;

      linkController.stop();
      dispatch(Actions.Affiliate.getLinkFailed(error));
    }

    api.affiliates
      .getLink()
      .then(onSuccess, onFail);
  });

  return reduction;
}

function* getLinkFailed(reduction, error) {
  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_DATA,
      })
    );
  });

  return reduction;
}

function* getLinkSucceeded(reduction, response) {
  return reduction.setIn(['affiliate', 'link'], response.referral_link);
}

function* getPayout(reduction) {
  yield sideEffect((dispatch) => {
    const { signal } = payoutController.start();

    function onSuccess(response) {
      payoutController.stop();
      dispatch(Actions.Affiliate.getPayoutSucceeded(response));
    }

    function onFail(error) {
      if (signal.aborted) return;

      payoutController.stop();
      dispatch(Actions.Affiliate.getPayoutFailed(error));
    }

    api.affiliates
      .getPayout()
      .then(onSuccess, onFail);
  });

  return reduction;
}

function* getPayoutFailed(reduction, error) {
  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_DATA,
      })
    );
  });

  return reduction;
}

function* getPayoutSucceeded(reduction, response) {
  const {
    available_payout_amount: availablePayoutAmount,
    milestones,
    total_payout_amount: totalPayoutAmount,
    ...rest
  } = response;

  const payout = Map({
    ...keysToCamelCase(rest),
    availablePayoutAmount: formatAmount(availablePayoutAmount),
    milestones: List(milestones.map(Map)),
    totalPayoutAmount: formatAmount(totalPayoutAmount),
  });

  return reduction.setIn(['affiliate', 'payout'], payout);
}

function* requestPayout(reduction, payload) {
  yield sideEffect((dispatch) => {
    function onSuccess() {
      dispatch(Actions.Affiliate.requestPayoutSucceeded());
      dispatch(Actions.Affiliate.getPayout());
    }

    function onError(error) {
      console.error(error);
      dispatch(Actions.Affiliate.requestPayoutFailed());
    }

    api.affiliates.requestPayout(payload).then(onSuccess, onError);
  });

  return reduction
    .deleteIn(['affiliate', 'modal', 'error'])
    .setIn(['affiliate', 'modal', 'processing'], true);
}

function* requestPayoutFailed(reduction) {
  return reduction.getIn(['affiliate', 'modal', 'type']) === MODALS.AFFILIATE.PAYOUT.REQUEST
    ? reduction
      .deleteIn(['affiliate', 'modal', 'processing'])
      .setIn(['affiliate', 'modal', 'error'], MESSAGE.FAIL.NETWORK_ERROR)
    : reduction;
}

function* requestPayoutSucceeded(reduction) {
  if (getSize(reduction.get('affiliate'))) {
    yield sideEffect((dispatch) => {
      dispatch(Actions.Affiliate.getPayout());
    });
  }

  return reduction.getIn(['affiliate', 'modal', 'type']) === MODALS.AFFILIATE.PAYOUT.REQUEST
    ? reduction.setIn(['affiliate', 'modal'], Map({ type: MODALS.AFFILIATE.PAYOUT.SUCCESS }))
    : reduction;
}

function* sendApply(reduction, { companies, groups }) {
  yield sideEffect((dispatch) => {
    function onSuccess() {
      dispatch(Actions.User.getUserData());
      dispatch(Actions.Affiliate.sendApplySucceeded());
    }

    function onError(error) {
      console.error(error);
      dispatch(Actions.Affiliate.sendApplyFailed());
    }

    const data = reduction.getIn(['affiliate', 'modal', 'data']);

    const payload = {
      channels: data.get('channels').toObject(),
      companies: [companies ? data.get('companies') : DEFAULTS.EMPTY_STRING],
      groups: groups ? data.get('groups').filter(isTruthy).toArray() : [],
    };

    api.affiliates.sendApply(payload).then(onSuccess, onError);
  });

  return reduction
    .deleteIn(['affiliate', 'modal', 'error'])
    .setIn(['affiliate', 'modal', 'processing'], true);
}

function* sendApplyFailed(reduction) {
  return getSize(reduction.get('affiliate'))
    ? reduction
      .deleteIn(['affiliate', 'modal', 'processing'])
      .setIn(['affiliate', 'modal', 'error'], MESSAGE.FAIL.NETWORK_ERROR)
    : reduction;
}

function* sendApplySucceeded(reduction) {
  if (!getSize(reduction.get('affiliate'))) return reduction;

  yield sideEffect((dispatch) => {
    dispatch(Actions.Affiliate.getHistory());
    dispatch(Actions.Affiliate.getLink());
    dispatch(Actions.Affiliate.getPayout());
  });

  return reduction.setIn(['affiliate', 'modal'], Map({ type: MODALS.AFFILIATE.SURVEY.SUCCESS }));
}

function* setData(reduction, { path, value }) {
  const fullPath = Array.isArray(path)
    ? ['affiliate', ...path]
    : ['affiliate', path];

  if (value !== undefined) return reduction.setIn(fullPath, value);

  let state = reduction.deleteIn(fullPath);

  fullPath.pop();

  while (getSize(fullPath) > 1) {
    if (!getSize(state.getIn(fullPath))) {
      state = state.deleteIn(fullPath);
    }

    fullPath.pop();
  }

  return state;
}

Reducers.add(
  new Reducer('Affiliate')
    .add(ACTIONS.AFFILIATE.BOOTSTRAP, bootstrap)
    .add(ACTIONS.AFFILIATE.CLEAN_UP, cleanUp)
    .add(ACTIONS.AFFILIATE.GET_HISTORY, getHistory)
    .add(ACTIONS.AFFILIATE.GET_HISTORY_FAILED, getHistoryFailed)
    .add(ACTIONS.AFFILIATE.GET_HISTORY_SUCCEEDED, getHistorySucceeded)
    .add(ACTIONS.AFFILIATE.GET_LINK, getLink)
    .add(ACTIONS.AFFILIATE.GET_LINK_FAILED, getLinkFailed)
    .add(ACTIONS.AFFILIATE.GET_LINK_SUCCEEDED, getLinkSucceeded)
    .add(ACTIONS.AFFILIATE.GET_PAYOUT, getPayout)
    .add(ACTIONS.AFFILIATE.GET_PAYOUT_FAILED, getPayoutFailed)
    .add(ACTIONS.AFFILIATE.GET_PAYOUT_SUCCEEDED, getPayoutSucceeded)
    .add(ACTIONS.AFFILIATE.REQUEST_PAYOUT, requestPayout)
    .add(ACTIONS.AFFILIATE.REQUEST_PAYOUT_FAILED, requestPayoutFailed)
    .add(ACTIONS.AFFILIATE.REQUEST_PAYOUT_SUCCEEDED, requestPayoutSucceeded)
    .add(ACTIONS.AFFILIATE.SEND_APPLY, sendApply)
    .add(ACTIONS.AFFILIATE.SEND_APPLY_FAILED, sendApplyFailed)
    .add(ACTIONS.AFFILIATE.SEND_APPLY_SUCCEEDED, sendApplySucceeded)
    .add(ACTIONS.AFFILIATE.SET_DATA, setData)
);
