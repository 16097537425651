import { List, Map } from 'immutable';

import { shapeTaxonomyAttributesForAPI } from './shapeTaxonomyAttributes';
import { shapeVariationsProfileForAPI } from '../profiles/shapeForAPI';
import { getInventoryOperationValue } from './getOperationValue';
import { getIndividualVariations } from '../variations/getIndividualVariations';
import { getSize } from '../iterable/getSize';
import { keepOld } from '../iterable/keepOld';
import { get } from '../iterable/get';

import { OPERATIONS, SECTIONS, VALUE } from '../../constants/product';
import { SEO_URL_HANDLE_ENABLED } from '../../constants/featureFlags';
import { DEFAULTS, SEPARATOR } from '../../constants';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { INDIVIDUAL } from '../../constants/profiles';
import {
  CATEGORY,
  CHAR_COUNT_MAX,
  COLLECTIONS,
  DESCRIPTION,
  DIGITAL,
  ENABLED,
  FILES,
  HEIGHT,
  INSTRUCTIONS,
  IS_SUPPLY,
  LENGTH,
  MATERIALS,
  META_DESCRIPTION,
  NEW_TAGS,
  PAGE_TITLE,
  PERSONALIZATION,
  PHOTOS,
  PRICE,
  PRODUCTION_PARTNERS,
  PRODUCT_TYPE,
  PROFILE_ID,
  QUANTITY,
  REQUIRED,
  RETURN_POLICY,
  SECTION,
  SEO,
  SHIPPING,
  SHOULD_AUTO_RENEW,
  SKU,
  TAGS,
  TAXONOMY_ATTRIBUTES,
  TITLE,
  URL_HANDLE,
  VARIATIONS,
  VENDOR,
  VIDEOS,
  WEIGHT,
  WHEN_MADE,
  WHO_MADE,
  WIDTH,
} from '../../constants/attributes';

const { EMPTY_LIST, EMPTY_MAP, EMPTY_ORDERED_MAP, EMPTY_STRING } = DEFAULTS;
const { DETAILS, INVENTORY } = SECTIONS;

const OPERATION_TYPES = {
  [ETSY]: [
    OPERATIONS[ETSY].PHOTOS.CHANGE_TO,
    OPERATIONS[ETSY].VIDEOS.ADD,
    OPERATIONS[ETSY].TITLE,
    OPERATIONS[ETSY].DESCRIPTION,
    OPERATIONS[ETSY].TAGS.CHANGE_TO,
    OPERATIONS[ETSY].WHO_MADE,
    OPERATIONS[ETSY].IS_SUPPLY,
    OPERATIONS[ETSY].WHEN_MADE,
    OPERATIONS[ETSY].CATEGORY,
    OPERATIONS[ETSY].TAXONOMY_ATTRIBUTES,
    OPERATIONS[ETSY].SHOULD_AUTO_RENEW,
    OPERATIONS[ETSY].DIGITAL,
    OPERATIONS[ETSY].FILES.ADD,
    OPERATIONS[ETSY].PRODUCTION_PARTNERS,
    OPERATIONS[ETSY].SECTION,
    OPERATIONS[ETSY].MATERIALS.ADD,
    OPERATIONS[ETSY].VARIATIONS,
    OPERATIONS[ETSY].PRICE,
    OPERATIONS[ETSY].QUANTITY,
    OPERATIONS[ETSY].SKU,
    OPERATIONS[ETSY].PERSONALIZATION.IS_PERSONALIZABLE_SET,
    OPERATIONS[ETSY].PERSONALIZATION.IS_REQUIRED_SET,
    OPERATIONS[ETSY].PERSONALIZATION.CHAR_COUNT_MAX_SET,
    OPERATIONS[ETSY].PERSONALIZATION.INSTRUCTIONS_SET,
    OPERATIONS[ETSY].SHIPPING_PROFILE,
    OPERATIONS[ETSY].WEIGHT,
    OPERATIONS[ETSY].LENGTH,
    OPERATIONS[ETSY].WIDTH,
    OPERATIONS[ETSY].HEIGHT,
    OPERATIONS[ETSY].RETURN_POLICY,
  ],
  [SHOPIFY]: [
    OPERATIONS[SHOPIFY].PHOTOS.CHANGE_TO,
    OPERATIONS[SHOPIFY].VIDEOS.ADD,
    OPERATIONS[SHOPIFY].TITLE,
    OPERATIONS[SHOPIFY].DESCRIPTION,
    OPERATIONS[SHOPIFY].TAGS.CHANGE_TO,
    OPERATIONS[SHOPIFY].PRODUCT_TYPE,
    OPERATIONS[SHOPIFY].VENDOR,
    OPERATIONS[SHOPIFY].COLLECTIONS,
    OPERATIONS[SHOPIFY].VARIATIONS,
    OPERATIONS[SHOPIFY].PAGE_TITLE,
    OPERATIONS[SHOPIFY].META_DESCRIPTION,
    OPERATIONS[SHOPIFY].URL_HANDLE,
  ],
};

export function getOperationsFromProduct({
  archived = EMPTY_MAP,
  channel,
  operation: source = {},
  operationTypes = OPERATION_TYPES,
  product,
  toImmutable = true,
}) {
  const result = toImmutable ? EMPTY_LIST : [];

  if (!operationTypes.hasOwnProperty(channel)) return result;

  function reduceOperationTypes(operations, type) {
    function addOperation(operationData) {
      const operation = { ...source, ...operationData };
      return toImmutable
        ? operations.push(Map(operation))
        : [...operations, operation];
    }

    switch (channel) {
      case ETSY: {
        switch (type) {
          case OPERATIONS[ETSY].PHOTOS.CHANGE_TO: {
            const value = product.getIn([PHOTOS, VALUE]);
            return addOperation({ type, value: toImmutable ? value : value.toJS() });
          }

          case OPERATIONS[ETSY].VIDEOS.ADD: {
            const value = product.getIn([VIDEOS, VALUE]);
            return addOperation({ type, value: toImmutable ? value : value.toJS() });
          }

          case OPERATIONS[ETSY].TITLE: {
            return addOperation({ type, value: product.getIn([TITLE, VALUE]) });
          }

          case OPERATIONS[ETSY].DESCRIPTION: {
            return addOperation({ type, value: product.getIn([DESCRIPTION, VALUE]) });
          }

          case OPERATIONS[ETSY].TAGS.CHANGE_TO: {
            const operation = { type };
            const value = product.getIn([TAGS, 'currentProfile'])
              ? product.getIn([TAGS, 'currentProfile', TAGS])
              : product
                .getIn([TAGS, VALUE])
                .mergeWith(keepOld, product.getIn([TAGS, NEW_TAGS], EMPTY_ORDERED_MAP))
                .toList();

            operation.value = toImmutable ? value : value.toArray();

            if (product.getIn([TAGS, 'currentProfile', 'profileId'])) {
              operation.tagsProfileId = product.getIn([TAGS, 'currentProfile', 'profileId']);
            }

            return addOperation(operation);
          }

          case OPERATIONS[ETSY].WHO_MADE: {
            return addOperation({ type, value: product.getIn([DETAILS, WHO_MADE, VALUE]) });
          }

          case OPERATIONS[ETSY].IS_SUPPLY: {
            return addOperation({ type, value: product.getIn([DETAILS, IS_SUPPLY, VALUE]) });
          }

          case OPERATIONS[ETSY].WHEN_MADE: {
            return addOperation({ type, value: product.getIn([DETAILS, WHEN_MADE, VALUE]) });
          }

          case OPERATIONS[ETSY].CATEGORY: {
            return addOperation({ type, value: product.getIn([DETAILS, CATEGORY, VALUE]) });
          }

          case OPERATIONS[ETSY].TAXONOMY_ATTRIBUTES: {
            const value = shapeTaxonomyAttributesForAPI({
              taxonomyAttributes: product.getIn([DETAILS, TAXONOMY_ATTRIBUTES]),
              toImmutable,
            });

            return addOperation({ type, value });
          }

          case OPERATIONS[ETSY].SHOULD_AUTO_RENEW: {
            return addOperation({ type, value: product.getIn([DETAILS, SHOULD_AUTO_RENEW, VALUE]) });
          }

          case OPERATIONS[ETSY].DIGITAL: {
            return addOperation({ type, value: product.getIn([DETAILS, DIGITAL, VALUE]) });
          }

          case OPERATIONS[ETSY].FILES.ADD: {
            if (
              !product.getIn([DETAILS, DIGITAL, VALUE]) || (
                !getSize(archived.getIn([FILES, VALUE])) &&
                !getSize(product.getIn([FILES, VALUE]))
              )
            ) {
              return operations;
            }

            let resultOperations = operations;
            const oldValue = archived.getIn([FILES, VALUE]);
            const currentValue = product.getIn([FILES, VALUE]);

            if (getSize(oldValue)) {
              for (let index = getSize(oldValue) - 1; index >= 0; index -= 1) {
                resultOperations = addOperation({
                  type: OPERATIONS[ETSY].FILES.DELETE,
                  value: Map({ targetIndex: index }),
                });
              }
            }

            if (getSize(currentValue)) {
              resultOperations = addOperation({ type, value: currentValue });
            }

            return resultOperations;
          }

          case OPERATIONS[ETSY].PRODUCTION_PARTNERS: {
            const value = product.getIn([DETAILS, PRODUCTION_PARTNERS, VALUE]);
            return addOperation({ type, value: toImmutable ? value : value.toArray() });
          }

          case OPERATIONS[ETSY].SECTION: {
            return addOperation({
              type,
              value: product.getIn([DETAILS, SECTION, VALUE]) || 'none',
            });
          }

          case OPERATIONS[ETSY].MATERIALS.ADD: {
            let resultOperations = operations;
            const oldValue = archived.getIn([DETAILS, MATERIALS, VALUE]);
            const currentValue = product.getIn([DETAILS, MATERIALS, VALUE]);

            if (getSize(oldValue)) {
              resultOperations = addOperation({
                type: OPERATIONS[ETSY].MATERIALS.DELETE,
                value: oldValue.toList().join(SEPARATOR.COMMA_SPACE),
              });
            }

            if (getSize(currentValue)) {
              resultOperations = addOperation({
                type,
                value: currentValue.toList().join(SEPARATOR.COMMA_SPACE),
              });
            }

            return resultOperations;
          }

          case OPERATIONS[ETSY].VARIATIONS: {
            if (
              product.getIn([DETAILS, DIGITAL, VALUE]) || (
                !getSize(archived.getIn([VARIATIONS, VARIATIONS])) &&
                !getSize(product.getIn([VARIATIONS, VARIATIONS]))
              )
            ) {
              return operations;
            }

            const operationData = shapeVariationsProfileForAPI({
              profile: product.get(VARIATIONS),
              productId: product.get('productId'),
              toImmutable,
            });

            return addOperation({
              type,
              value: get(VALUE)(operationData),
            });
          }

          case OPERATIONS[ETSY].PRICE: {
            const { individualPrice } = product.getIn([DETAILS, DIGITAL, VALUE])
              ? {}
              : getIndividualVariations({
                properties: [INDIVIDUAL.PRICE],
                variations: product.getIn([VARIATIONS, VARIATIONS]),
              });

            if (getSize(individualPrice)) return operations;

            const operationValue = [
              getInventoryOperationValue({
                channel,
                inventory: product.get(INVENTORY),
                toImmutable,
                type: PRICE,
              }),
            ];

            return addOperation({
              type,
              value: toImmutable ? List(operationValue) : operationValue,
            });
          }

          case OPERATIONS[ETSY].QUANTITY: {
            const { individualQuantity } = product.getIn([DETAILS, DIGITAL, VALUE])
              ? {}
              : getIndividualVariations({
                properties: [INDIVIDUAL.QUANTITY],
                variations: product.getIn([VARIATIONS, VARIATIONS]),
              });

            if (getSize(individualQuantity)) return operations;

            const operationValue = [
              getInventoryOperationValue({
                channel,
                inventory: product.get(INVENTORY),
                toImmutable,
                type: QUANTITY,
              }),
            ];

            return addOperation({
              type,
              value: toImmutable ? List(operationValue) : operationValue,
            });
          }

          case OPERATIONS[ETSY].SKU: {
            const { individualSKU } = product.getIn([DETAILS, DIGITAL, VALUE])
              ? {}
              : getIndividualVariations({
                properties: [INDIVIDUAL.SKU],
                variations: product.getIn([VARIATIONS, VARIATIONS]),
              });

            if (getSize(individualSKU)) return operations;

            const operationValue = [
              getInventoryOperationValue({
                channel,
                inventory: product.get(INVENTORY),
                toImmutable,
                type: SKU,
              }),
            ];

            return addOperation({
              type,
              value: toImmutable ? List(operationValue) : operationValue,
            });
          }

          case OPERATIONS[ETSY].PERSONALIZATION.IS_PERSONALIZABLE_SET: {
            return addOperation({
              type,
              value: product.getIn([PERSONALIZATION, ENABLED]),
            });
          }

          case OPERATIONS[ETSY].PERSONALIZATION.IS_REQUIRED_SET: {
            return product.getIn([PERSONALIZATION, ENABLED])
              ? addOperation({
                type,
                value: product.getIn([PERSONALIZATION, REQUIRED]),
              })
              : operations;
          }

          case OPERATIONS[ETSY].PERSONALIZATION.CHAR_COUNT_MAX_SET: {
            return product.getIn([PERSONALIZATION, ENABLED])
              ? addOperation({
                type,
                value: product.getIn([PERSONALIZATION, CHAR_COUNT_MAX, VALUE]),
              })
              : operations;
          }

          case OPERATIONS[ETSY].PERSONALIZATION.INSTRUCTIONS_SET: {
            return product.getIn([PERSONALIZATION, ENABLED])
              ? addOperation({
                type,
                value: product.getIn([PERSONALIZATION, INSTRUCTIONS, VALUE]),
              })
              : operations;
          }

          case OPERATIONS[ETSY].SHIPPING_PROFILE: {
            return product.getIn([DETAILS, DIGITAL, VALUE])
              ? operations
              : addOperation({ type, value: product.getIn([SHIPPING, PROFILE_ID, VALUE]) });
          }

          case OPERATIONS[ETSY].WEIGHT: {
            return product.getIn([DETAILS, DIGITAL, VALUE])
              ? operations
              : addOperation({ type, value: product.getIn([SHIPPING, WEIGHT, VALUE]) });
          }

          case OPERATIONS[ETSY].LENGTH: {
            return product.getIn([DETAILS, DIGITAL, VALUE])
              ? operations
              : addOperation({ type, value: product.getIn([SHIPPING, LENGTH, VALUE]) });
          }

          case OPERATIONS[ETSY].WIDTH: {
            return product.getIn([DETAILS, DIGITAL, VALUE])
              ? operations
              : addOperation({ type, value: product.getIn([SHIPPING, WIDTH, VALUE]) });
          }

          case OPERATIONS[ETSY].HEIGHT: {
            return product.getIn([DETAILS, DIGITAL, VALUE])
              ? operations
              : addOperation({ type, value: product.getIn([SHIPPING, HEIGHT, VALUE]) });
          }

          case OPERATIONS[ETSY].RETURN_POLICY: {
            return product.getIn([DETAILS, DIGITAL, VALUE])
              ? operations
              : addOperation({ type, value: product.getIn([SHIPPING, RETURN_POLICY, VALUE]) });
          }

          default: {
            return operations;
          }
        }
      }

      case SHOPIFY: {
        switch (type) {
          case OPERATIONS[SHOPIFY].PHOTOS.CHANGE_TO: {
            const value = product.getIn([PHOTOS, VALUE]);
            return addOperation({ type, value: toImmutable ? value : value.toJS() });
          }

          case OPERATIONS[SHOPIFY].VIDEOS.ADD: {
            const value = product.getIn([VIDEOS, VALUE]);
            return addOperation({ type, value: toImmutable ? value : value.toJS() });
          }

          case OPERATIONS[SHOPIFY].TITLE: {
            return addOperation({ type, value: product.getIn([TITLE, VALUE]) });
          }

          case OPERATIONS[SHOPIFY].DESCRIPTION: {
            return addOperation({ type, value: product.getIn([DESCRIPTION, VALUE]) });
          }

          case OPERATIONS[SHOPIFY].PAGE_TITLE: {
            return addOperation({ type, value: product.getIn([SEO, PAGE_TITLE, VALUE]) });
          }

          case OPERATIONS[SHOPIFY].META_DESCRIPTION: {
            return addOperation({ type, value: product.getIn([SEO, META_DESCRIPTION, VALUE]) });
          }

          case OPERATIONS[SHOPIFY].URL_HANDLE: {
            if (!SEO_URL_HANDLE_ENABLED) return operations;

            return addOperation({ type, value: product.getIn([SEO, URL_HANDLE, VALUE]) });
          }

          case OPERATIONS[SHOPIFY].TAGS.CHANGE_TO: {
            const operation = { type };
            const value = product.getIn([TAGS, 'currentProfile'])
              ? product.getIn([TAGS, 'currentProfile', TAGS])
              : product
                .getIn([TAGS, VALUE])
                .mergeWith(keepOld, product.getIn([TAGS, NEW_TAGS], EMPTY_ORDERED_MAP))
                .toList();

            operation.value = toImmutable ? value : value.toArray();

            if (product.getIn([TAGS, 'currentProfile', 'profileId'])) {
              operation.tagsProfileId = product.getIn([TAGS, 'currentProfile', 'profileId']);
            }

            return addOperation(operation);
          }

          case OPERATIONS[SHOPIFY].PRODUCT_TYPE: {
            const value = product.getIn([DETAILS, PRODUCT_TYPE, VALUE]);
            return value
              ? addOperation({ type, value })
              : operations;
          }

          case OPERATIONS[SHOPIFY].VENDOR: {
            const value = product.getIn([DETAILS, VENDOR, VALUE]);
            return value
              ? addOperation({ type, value })
              : operations;
          }

          case OPERATIONS[SHOPIFY].COLLECTIONS: {
            let collections = product.getIn([DETAILS, COLLECTIONS, VALUE]);

            if (!getSize(collections)) {
              collections = collections.push(EMPTY_STRING);
            }

            return addOperation({
              type,
              value: toImmutable ? collections : collections.toArray(),
            });
          }

          case OPERATIONS[SHOPIFY].VARIATIONS: {
            if (!getSize(product.getIn([VARIATIONS, VARIATIONS]))) {
              return addOperation({
                type,
                value: getInventoryOperationValue({
                  channel,
                  inventory: product.get(INVENTORY),
                  toImmutable,
                }),
              });
            }

            const operationData = shapeVariationsProfileForAPI({
              profile: product.get(VARIATIONS),
              productId: product.get('productId'),
              toImmutable,
            });

            const [value, shopifyMeta] = get(VALUE, 'shopifyMeta')(operationData);

            return addOperation({ shopifyMeta, type, value });
          }

          default: {
            return operations;
          }
        }
      }

      default: {
        return operations;
      }
    }
  }

  return operationTypes[channel].reduce(reduceOperationTypes, result);
}
