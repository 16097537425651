import { Set } from 'immutable';

import { getSize } from '../iterable/getSize';

import { OPERATIONS } from '../../constants/bulkEdit';
import { SEPARATOR } from '../../constants';
import { ETSY } from '../../constants/channels';

export function getEditedAttributesAndListings(operations) {
  function reduceOperations(result, operation) {
    switch (operation) {
      case OPERATIONS[ETSY].STATUS: {
        return result;
      }

      default: {
        result.attributes = result.attributes.add(operation.get('type').split(SEPARATOR.DOT)[0]);
        result.productIds = result.productIds.union(Set(operation.get('products')));
        return result;
      }
    }
  }

  const { attributes, productIds } = operations.reduce(
    reduceOperations,
    { attributes: Set(), productIds: Set() },
  );

  return {
    attributes: attributes.toList(),
    listings: getSize(productIds),
  };
}
