import { List, Map } from 'immutable';

import { getSize } from '../iterable/getSize';
import { get } from '../iterable/get';

import { OPERATIONS } from '../../constants/bulkEdit';
import { PHOTOS } from '../../constants/attributes';

// this function is only needed for bulk photo editor
// delete it and clean up all its references in case of FEATURE_FLAG_BULK_PHOTO_EDITOR removal
export function getPreviewOperations({ channel, preview = Map() }) {
  function reduceOperations(result, operation) {
    switch (operation.get('type')) {
      case OPERATIONS[channel].PHOTOS.EDIT: {
        return result.push(operation);
      }

      default: {
        return result;
      }
    }
  }

  function reducePreviewAttributes(result, value, attribute) {
    switch (attribute) {
      case PHOTOS: {
        const operations = get('operations')(value);

        return List.isList(operations) && getSize(operations)
          ? operations.reduce(reduceOperations, result)
          : result;
      }

      default: {
        return result;
      }
    }
  }

  return preview.reduce(reducePreviewAttributes, List());
}
