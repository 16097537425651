import Big from 'big.js';

import { PI_BY_180 } from '../constants/math';

export function degreesToRadians(degrees) {
  return degrees * PI_BY_180;
}

export function radiansToDegrees(radians) {
  return radians / PI_BY_180;
}

export function decrease(number) {
  return number - 1;
}

export function increase(number) {
  return number + 1;
}

export function discretise(value, decimals = 0) {
  const denomerator = decimals
    ? new Big(10).pow(decimals)
    : 1;

  function getFraction(number) {
    return new Big(number).div(denomerator);
  }

  const round = new Big(value).round(decimals, 0);
  const mod = round.mod(getFraction(10));

  return mod.gt(getFraction(4))
    ? round.plus(getFraction(5)).minus(mod).toNumber()
    : round.minus(mod).toNumber();
}
