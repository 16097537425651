import { url } from '../utils/theme';

import { EBAY, ETSY, SHOPIFY } from './channels';
import { MEDIA } from './socialMediaShare';
import { VELA } from '.';

import ebay from '../images/channels/ebay.svg';
import etsy from '../images/channels/etsy.svg';
import shopify from '../images/channels/shopify.svg';
import vela from '../images/channels/vela.svg';

import facebook from '../images/social-media/facebook.svg';
import pinterest from '../images/social-media/pinterest.svg';
import x from '../images/social-media/x.svg';

import transparencyDark from '../images/transparency-dark.svg';
import transparencyLight from '../images/transparency-light.svg';

export const VELA_SANS = 'VelaSans';

export const COLORS = 'colors';

export const CURSORS = 'cursors';

const CHANNELS = 'channels';

const SOCIAL_MEDIA = 'social-media';

const FONT = 'font';

const FONT_WEIGHTS = 'font-weights';

const ICONS = 'icons';

const MEASURES = 'measures';

export const VELA_THEME = 'velaTheme';

// theme names
export const THEME_NAME = {
  AUTO: 'auto',
  LIGHT: 'light',
  DARK: 'dark',
};

// value names
export const COLOR = {
  BACKGROUND_0: 'background-0',
  BACKGROUND_1: 'background-1',
  BACKGROUND_HOVER: 'background-hover',
  BACKGROUND_TRANSLUCENT: 'background-translucent',
  BORDER_ACTIVE: 'border-active',
  CLOVER: 'clover',
  CLOVER_ACTIVE: 'clover-active',
  CLOVER_HOVER: 'clover-hover',
  CLOVER_TRANSLUCENT: 'clover-translucent',
  GRAY_8: 'gray-8',
  GRAY_7: 'gray-7',
  GRAY_6: 'gray-6',
  GRAY_5: 'gray-5',
  GRAY_4: 'gray-4',
  GRAY_3: 'gray-3',
  GRAY_2: 'gray-2',
  GRAY_07: 'gray-07',
  GRAY_04: 'gray-04',
  GRAY_OPAQUE: 'gray-translucent',
  JADE: 'jade',
  JADE_TRANSLUCENT: 'jade-translucent',
  MINT: 'mint',
  MINT_ACTIVE: 'mint-active',
  MINT_HOVER: 'mint-hover',
  MINT_TRANSLUCENT: 'mint-translucent',
  OVERLAY: 'overlay',
  PINE: 'pine',
  PINE_ACTIVE: 'pine-active',
  PINE_HOVER: 'pine-hover',
  ROUGE: 'rouge',
  ROUGE_ACTIVE: 'rouge-active',
  ROUGE_HOVER: 'rouge-hover',
  ROUGE_TRANSLUCENT: 'rouge-translucent',
  SHADOW: 'shadow',
  SHADOW_SOFT: 'shadow-soft',
  TEXT: 'text',
  TEXT_ACTIVE: 'text-active',
  TOOLTIP: 'tooltip',
};

export const CURSOR = {
  AUTO: 'auto',
  CROSSHAIR: 'crosshair',
  DEFAULT: 'default',
  EW_RESIZE: 'ew-resize',
  GRAB: 'grab',
  GRABBING: 'grabbing',
  NESW_RESIZE: 'nesw-resize',
  NONE: 'none',
  NOT_ALLOWED: 'not-allowed',
  NS_RESIZE: 'ns-resize',
  NWSE_RESIZE: 'nwse-resize',
  POINTER: 'pointer',
  TEXT: 'text',
};

export const FONT_WEIGHT = {
  BOLD: '700',
  LIGHT: '300',
  MEDIUM: '500',
  REGULAR: '400',
  SEMI_BOLD: '600',
};

const ICON = {
  CLOSE: 'close',
  PHOTO_PLACEHOLDER: 'photo-placeholder',
  TRANSPARENCY: 'transparency',
};

export const MEASURE = {
  FOOTER_HEIGHT: 'footer-height',
  HEADER_WIDTH: 'header-width',
  INPUT_HEIGHT: 'input-height',
  MODAL_Z_INDEX: 'modal-z-index',
  SIDEBAR_WIDTH: 'sidebar-width',
  LINE_28: 'line-28',
  LINE_24: 'line-24',
  LINE_18: 'line-18',
  LINE_16: 'line-16',
  LINE_13: 'line-13',
  LINE_12: 'line-12',
  LINE_10: 'line-10',
  LINE_9: 'line-9',
  LINE_8: 'line-8',
  TOP_BAR_HEIGHT: 'top-bar-height',
  TOP_NAVIGATION_BAR: 'top_nav_bar',
};

// a path to value, array of strings
export const THEME = {
  COLORS: {
    BACKGROUND_0: [COLORS, COLOR.BACKGROUND_0],
    BACKGROUND_1: [COLORS, COLOR.BACKGROUND_1],
    BACKGROUND_HOVER: [COLORS, COLOR.BACKGROUND_HOVER],
    BACKGROUND_TRANSLUCENT: [COLORS, COLOR.BACKGROUND_TRANSLUCENT],
    BORDER_ACTIVE: [COLORS, COLOR.BORDER_ACTIVE],
    CLOVER: [COLORS, COLOR.CLOVER],
    CLOVER_ACTIVE: [COLORS, COLOR.CLOVER_ACTIVE],
    CLOVER_HOVER: [COLORS, COLOR.CLOVER_HOVER],
    CLOVER_TRANSLUCENT: [COLORS, COLOR.CLOVER_TRANSLUCENT],
    GRAY_8: [COLORS, COLOR.GRAY_8],
    GRAY_7: [COLORS, COLOR.GRAY_7],
    GRAY_6: [COLORS, COLOR.GRAY_6],
    GRAY_5: [COLORS, COLOR.GRAY_5],
    GRAY_4: [COLORS, COLOR.GRAY_4],
    GRAY_3: [COLORS, COLOR.GRAY_3],
    GRAY_2: [COLORS, COLOR.GRAY_2],
    GRAY_07: [COLORS, COLOR.GRAY_07],
    GRAY_04: [COLORS, COLOR.GRAY_04],
    GRAY_OPAQUE: [COLORS, COLOR.GRAY_OPAQUE],
    JADE: [COLORS, COLOR.JADE],
    JADE_TRANSLUCENT: [COLORS, COLOR.JADE_TRANSLUCENT],
    MINT: [COLORS, COLOR.MINT],
    MINT_ACTIVE: [COLORS, COLOR.MINT_ACTIVE],
    MINT_HOVER: [COLORS, COLOR.MINT_HOVER],
    MINT_TRANSLUCENT: [COLORS, COLOR.MINT_TRANSLUCENT],
    OVERLAY: [COLORS, COLOR.OVERLAY],
    PINE: [COLORS, COLOR.PINE],
    PINE_ACTIVE: [COLORS, COLOR.PINE_ACTIVE],
    PINE_HOVER: [COLORS, COLOR.PINE_HOVER],
    ROUGE: [COLORS, COLOR.ROUGE],
    ROUGE_ACTIVE: [COLORS, COLOR.ROUGE_ACTIVE],
    ROUGE_HOVER: [COLORS, COLOR.ROUGE_HOVER],
    ROUGE_TRANSLUCENT: [COLORS, COLOR.ROUGE_TRANSLUCENT],
    SHADOW: [COLORS, COLOR.SHADOW],
    SHADOW_SOFT: [COLORS, COLOR.SHADOW_SOFT],
    TEXT: [COLORS, COLOR.TEXT],
    TEXT_ACTIVE: [COLORS, COLOR.TEXT_ACTIVE],
    TOOLTIP: [COLORS, COLOR.TOOLTIP],
    CHANNELS: {
      [EBAY]: [COLORS, CHANNELS, EBAY],
      [ETSY]: [COLORS, CHANNELS, ETSY],
      [SHOPIFY]: [COLORS, CHANNELS, SHOPIFY],
      [VELA]: [COLORS, CHANNELS, VELA],
    },
    SOCIAL_MEDIA: {
      [MEDIA.FACEBOOK]: [COLORS, SOCIAL_MEDIA, MEDIA.FACEBOOK],
      [MEDIA.PINTEREST]: [COLORS, SOCIAL_MEDIA, MEDIA.PINTEREST],
      [MEDIA.X]: [COLORS, SOCIAL_MEDIA, MEDIA.X],
    },
  },
  CURSORS: {
    AUTO: [CURSORS, CURSOR.AUTO],
    DEFAULT: [CURSORS, CURSOR.DEFAULT],
    EW_RESIZE: [CURSORS, CURSOR.EW_RESIZE],
    GRAB: [CURSORS, CURSOR.GRAB],
    GRABBING: [CURSORS, CURSOR.GRABBING],
    NESW_RESIZE: [CURSORS, CURSOR.NESW_RESIZE],
    NOT_ALLOWED: [CURSORS, CURSOR.NOT_ALLOWED],
    NS_RESIZE: [CURSORS, CURSOR.NS_RESIZE],
    NWSE_RESIZE: [CURSORS, CURSOR.NWSE_RESIZE],
    POINTER: [CURSORS, CURSOR.POINTER],
    TEXT: [CURSORS, CURSOR.TEXT],
  },
  FONT: [FONT],
  FONT_WEIGHTS: {
    BOLD: [FONT_WEIGHTS, FONT_WEIGHT.BOLD],
    LIGHT: [FONT_WEIGHTS, FONT_WEIGHT.LIGHT],
    MEDIUM: [FONT_WEIGHTS, FONT_WEIGHT.MEDIUM],
    REGULAR: [FONT_WEIGHTS, FONT_WEIGHT.REGULAR],
    SEMI_BOLD: [FONT_WEIGHTS, FONT_WEIGHT.SEMI_BOLD],
  },
  ICONS: {
    TRANSPARENCY: [ICONS, ICON.TRANSPARENCY],
    CHANNELS: {
      [EBAY]: [ICONS, CHANNELS, EBAY],
      [ETSY]: [ICONS, CHANNELS, ETSY],
      [SHOPIFY]: [ICONS, CHANNELS, SHOPIFY],
      [VELA]: [ICONS, CHANNELS, VELA],
    },
    SOCIAL_MEDIA: {
      [MEDIA.FACEBOOK]: [ICONS, SOCIAL_MEDIA, MEDIA.FACEBOOK],
      [MEDIA.PINTEREST]: [ICONS, SOCIAL_MEDIA, MEDIA.PINTEREST],
      [MEDIA.X]: [ICONS, SOCIAL_MEDIA, MEDIA.X],
    },
  },
  MEASURES: {
    FOOTER_HEIGHT: [MEASURES, MEASURE.FOOTER_HEIGHT],
    HEADER_WIDTH: [MEASURES, MEASURE.HEADER_WIDTH],
    INPUT_HEIGHT: [MEASURES, MEASURE.INPUT_HEIGHT],
    LINE_28: [MEASURES, MEASURE.LINE_28],
    LINE_24: [MEASURES, MEASURE.LINE_24],
    LINE_18: [MEASURES, MEASURE.LINE_18],
    LINE_16: [MEASURES, MEASURE.LINE_16],
    LINE_13: [MEASURES, MEASURE.LINE_13],
    LINE_12: [MEASURES, MEASURE.LINE_12],
    LINE_10: [MEASURES, MEASURE.LINE_10],
    LINE_9: [MEASURES, MEASURE.LINE_9],
    LINE_8: [MEASURES, MEASURE.LINE_8],
    MODAL_Z_INDEX: [MEASURES, MEASURE.MODAL_Z_INDEX],
    SIDEBAR_WIDTH: [MEASURES, MEASURE.SIDEBAR_WIDTH],
    TOP_BAR_HEIGHT: [MEASURES, MEASURE.TOP_BAR_HEIGHT],
    TOP_NAVIGATION_BAR: [MEASURES, MEASURE.TOP_NAVIGATION_BAR],
  },
};

// shared values
export const THEME_COLORS = {
  [COLOR.CLOVER]: '#009B8A',
  [COLOR.CLOVER_ACTIVE]: '#008F7F',
  [COLOR.CLOVER_HOVER]: '#32AFA1',
  [COLOR.CLOVER_TRANSLUCENT]: 'rgba(0, 83, 81, 0.8)',
  [COLOR.JADE]: '#DEF6EE',
  [COLOR.JADE_TRANSLUCENT]: 'rgba(54, 211, 159, 0.15)',
  [COLOR.MINT]: '#7DDABA',
  [COLOR.MINT_ACTIVE]: '#62BC9D',
  [COLOR.MINT_HOVER]: '#84E6C4',
  [COLOR.MINT_TRANSLUCENT]: 'rgba(125, 218, 186, 0.2)',
  [COLOR.PINE]: '#005351',
  [COLOR.PINE_ACTIVE]: '#115552',
  [COLOR.PINE_HOVER]: '#0F6C69',
  [COLOR.SHADOW]: 'rgba(0, 0, 0, 0.2)',
  [COLOR.SHADOW_SOFT]: 'rgba(0, 0, 0, 0.06)',
  [COLOR.TEXT_ACTIVE]: '#FFFFFF',
};

const CHANNEL_COLORS = {
  [EBAY]: '#0064D2',
  [ETSY]: '#F26622',
  [SHOPIFY]: '#95BF47',
  [VELA]: '#005351',
};

const SOCIAL_MEDIA_COLORS = {
  [MEDIA.FACEBOOK]: '#3B5998',
  [MEDIA.PINTEREST]: '#C8232C',
  [MEDIA.X]: '#000',
};

const THEME_FONT_WEIGHTS = {
  [FONT_WEIGHT.BOLD]: FONT_WEIGHT.BOLD,
  [FONT_WEIGHT.LIGHT]: FONT_WEIGHT.LIGHT,
  [FONT_WEIGHT.MEDIUM]: FONT_WEIGHT.MEDIUM,
  [FONT_WEIGHT.REGULAR]: FONT_WEIGHT.REGULAR,
  [FONT_WEIGHT.SEMI_BOLD]: FONT_WEIGHT.SEMI_BOLD,
};

const THEME_ICONS = {
  [CHANNELS]: {
    [EBAY]: url(ebay),
    [ETSY]: url(etsy),
    [SHOPIFY]: url(shopify),
    [VELA]: url(vela),
  },
  [SOCIAL_MEDIA]: {
    [MEDIA.FACEBOOK]: url(facebook),
    [MEDIA.PINTEREST]: url(pinterest),
    [MEDIA.X]: url(x),
  },
};

export const THEME_MEASURES = {
  [MEASURE.FOOTER_HEIGHT]: '75px',
  [MEASURE.HEADER_WIDTH]: '60px',
  [MEASURE.INPUT_HEIGHT]: '34px',
  [MEASURE.LINE_28]: '28px',
  [MEASURE.LINE_24]: '24px',
  [MEASURE.LINE_18]: '18px',
  [MEASURE.LINE_16]: '16px',
  [MEASURE.LINE_13]: '13px',
  [MEASURE.LINE_12]: '12px',
  [MEASURE.LINE_10]: '10px',
  [MEASURE.LINE_9]: '9px',
  [MEASURE.LINE_8]: '8px',
  [MEASURE.MODAL_Z_INDEX]: 6,
  [MEASURE.SIDEBAR_WIDTH]: '206px',
  [MEASURE.TOP_BAR_HEIGHT]: '66px',
  [MEASURE.TOP_NAVIGATION_BAR]: '47PX',
};

// themes with shared and specific values
export const THEMES = {
  [THEME_NAME.DARK]: {
    name: THEME_NAME.DARK,
    [COLORS]: {
      ...THEME_COLORS,
      [CHANNELS]: CHANNEL_COLORS,
      [SOCIAL_MEDIA]: SOCIAL_MEDIA_COLORS,
      [COLOR.BACKGROUND_0]: '#2A2A2A',
      [COLOR.BACKGROUND_1]: '#252525',
      [COLOR.BACKGROUND_HOVER]: '#272727',
      [COLOR.BACKGROUND_TRANSLUCENT]: 'rgba(37, 37, 37, 0.9)',
      [COLOR.BORDER_ACTIVE]: THEME_COLORS[COLOR.CLOVER],
      [COLOR.GRAY_8]: 'rgba(255, 255, 255, 0.8)',
      [COLOR.GRAY_7]: 'rgba(255, 255, 255, 0.7)',
      [COLOR.GRAY_6]: 'rgba(255, 255, 255, 0.6)',
      [COLOR.GRAY_5]: 'rgba(255, 255, 255, 0.5)',
      [COLOR.GRAY_4]: 'rgba(255, 255, 255, 0.4)',
      [COLOR.GRAY_3]: 'rgba(255, 255, 255, 0.3)',
      [COLOR.GRAY_2]: 'rgba(255, 255, 255, 0.2)',
      [COLOR.GRAY_07]: 'rgba(255, 255, 255, 0.07)',
      [COLOR.GRAY_04]: 'rgba(255, 255, 255, 0.04)',
      [COLOR.GRAY_OPAQUE]: '#393939',
      [COLOR.OVERLAY]: 'rgba(0, 0, 0, 0.5)',
      [COLOR.ROUGE]: '#F13079',
      [COLOR.ROUGE_ACTIVE]: '#F02472',
      [COLOR.ROUGE_HOVER]: '#F24A8A',
      [COLOR.ROUGE_TRANSLUCENT]: 'rgba(241, 48, 121, 0.1)',
      [COLOR.TEXT]: 'rgba(255, 255, 255, 0.8)',
      [COLOR.TOOLTIP]: 'rgba(255, 255, 255, 0.2)',
    },
    [CURSORS]: CURSOR,
    [FONT]: `'${VELA_SANS}', sans-serif`,
    [FONT_WEIGHTS]: THEME_FONT_WEIGHTS,
    [ICONS]: {
      ...THEME_ICONS,
      [ICON.TRANSPARENCY]: url(transparencyDark),
    },
    [MEASURES]: THEME_MEASURES,
  },
  [THEME_NAME.LIGHT]: {
    name: THEME_NAME.LIGHT,
    [COLORS]: {
      ...THEME_COLORS,
      [CHANNELS]: CHANNEL_COLORS,
      [SOCIAL_MEDIA]: SOCIAL_MEDIA_COLORS,
      [COLOR.BACKGROUND_0]: '#FFFFFF',
      [COLOR.BACKGROUND_1]: '#F5F5F5',
      [COLOR.BACKGROUND_HOVER]: '#FAFAFA',
      [COLOR.BACKGROUND_TRANSLUCENT]: 'rgba(245, 245, 245, 0.9)',
      [COLOR.BORDER_ACTIVE]: THEME_COLORS[COLOR.PINE],
      [COLOR.GRAY_8]: 'rgba(0, 0, 0, 0.8)',
      [COLOR.GRAY_7]: 'rgba(0, 0, 0, 0.7)',
      [COLOR.GRAY_6]: 'rgba(0, 0, 0, 0.6)',
      [COLOR.GRAY_5]: 'rgba(0, 0, 0, 0.5)',
      [COLOR.GRAY_4]: 'rgba(0, 0, 0, 0.4)',
      [COLOR.GRAY_3]: 'rgba(0, 0, 0, 0.3)',
      [COLOR.GRAY_2]: 'rgba(0, 0, 0, 0.2)',
      [COLOR.GRAY_07]: 'rgba(0, 0, 0, 0.07)',
      [COLOR.GRAY_04]: 'rgba(0, 0, 0, 0.04)',
      [COLOR.GRAY_OPAQUE]: '#E3E3E3',
      [COLOR.OVERLAY]: 'rgba(255, 255, 255, 0.5)',
      [COLOR.ROUGE]: '#B23665',
      [COLOR.ROUGE_ACTIVE]: '#A4325D',
      [COLOR.ROUGE_HOVER]: '#BD517B',
      [COLOR.ROUGE_TRANSLUCENT]: 'rgba(178, 54, 101, 0.15)',
      [COLOR.TEXT]: 'rgba(0, 0, 0, 0.8)',
      [COLOR.TOOLTIP]: 'rgba(0, 0, 0, 0.8)',
    },
    [CURSORS]: CURSOR,
    [FONT]: `'${VELA_SANS}', sans-serif`,
    [FONT_WEIGHTS]: THEME_FONT_WEIGHTS,
    [ICONS]: {
      ...THEME_ICONS,
      [ICON.TRANSPARENCY]: url(transparencyLight),
    },
    [MEASURES]: THEME_MEASURES,
  },
};
