import { createGlobalStyle, css } from 'styled-components';

import { getFromTheme } from '../utils/theme';

import { THEME } from '../constants/theme';

const LayoutStyle = css`
  * {
    box-sizing: border-box;
    outline: none;
  }

  :after,
  :before {
    box-sizing: border-box;
  }

  html {
    position: relative;
    background: ${getFromTheme(THEME.COLORS.BACKGROUND_0)};
    height: 100%;
  }

  body {
    height: 100%;
    margin: 0;
    padding: 0;
    color: ${getFromTheme(THEME.COLORS.TEXT)};
    font-family: ${getFromTheme(THEME.FONT)};
    font-size: ${getFromTheme(THEME.MEASURES.LINE_13)};
    line-height: ${getFromTheme(THEME.MEASURES.LINE_18)};

    :not(.sb-show-main) {
      overflow: hidden;
    }
  }

  a,
  p,
  th,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div,
  span,
  button,
  strong {
    font-weight: ${getFromTheme(THEME.FONT_WEIGHTS.REGULAR)};
  }

  #app,
  #app div[data-reactroot] {
    height: 100%;
  }

  #app {
    position: relative;
    background-color: ${getFromTheme(THEME.COLORS.BACKGROUND_1)};
  }

  .app-body {
    background-color: ${getFromTheme(THEME.COLORS.BACKGROUND_1)};
    width: calc(100% - ${getFromTheme(THEME.MEASURES.HEADER_WIDTH)});
    height: 100%;
    display: flex;
    flex-flow: row;
    position: relative;
    overflow: hidden;

    &.paywall {
      position: relative;

      .app-listings,
      .sidebar > *:not(.shops-dropdown) {
        pointer-events: none !important;
        z-index: 0;
      }
    }

    .app-body-head {
      padding: 0 20px;
      width: 100%;
      height: ${getFromTheme(THEME.MEASURES.TOP_BAR_HEIGHT)};
      display: flex;
      align-items: center;
      border-bottom: 1px solid ${getFromTheme(THEME.COLORS.GRAY_07)};

      .app-body-head-title {
        margin-left: 20px;
        flex-grow: 1;
        display: flex;
        flex-flow: column;
        overflow: hidden;

        .shop-name {
          margin-bottom: 4px;
          color: ${getFromTheme(THEME.COLORS.GRAY_4)};
          font-size: ${getFromTheme(THEME.MEASURES.LINE_12)};
          line-height: ${getFromTheme(THEME.MEASURES.LINE_13)};
          text-transform: uppercase;
        }

        .badge {
          margin-right: 10px;
        }

        .app-body-head-title-heading {
          display: flex;
          align-items: center;
          overflow: hidden;
          font-size: ${getFromTheme(THEME.MEASURES.LINE_18)};
          line-height: 21px;
          white-space: nowrap;
          text-overflow: ellipsis;

          .badge {
            margin-right: 10px;
          }

          .edit-title-value {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.loading {
              width: 150px;
              height: 21px;
              border-radius: 2px;
            }
          }
        }
      }

      .app-body-head-buttons {
        display: flex;
        align-items: center;
        position: relative;

        button {
          margin: 0 0 0 14px;
        }
      }
    }

    .app-body-content {
      height: calc(100% - ${getFromTheme(THEME.MEASURES.TOP_BAR_HEIGHT)});
      width: calc(100% - ${getFromTheme(THEME.MEASURES.SIDEBAR_WIDTH)});
      position: relative;
      display: flex;
      overflow: hidden;
    }

    .sections-list {
      padding: 10px;
      width: 100%;
      display: flex;
      flex-flow: column;
      gap: 10px;
      overflow: hidden auto;

      &.with-footer {
        margin-bottom: ${getFromTheme(THEME.MEASURES.FOOTER_HEIGHT)};
      }
    }

    .sections-list-item {
      padding: 20px;
      background: ${getFromTheme(THEME.COLORS.BACKGROUND_0)};
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 ${getFromTheme(THEME.COLORS.SHADOW_SOFT)};
    }

    .sections-list-item-head {
      display: flex;

      .sections-list-item-head-title {
        margin: 0;
        height: 26px;
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: ${getFromTheme(THEME.MEASURES.LINE_18)};
        line-height: 26px;
      }

      & + .sections-list-item-content {
        margin-top: 20px;
      }
    }

    .app-body-foot {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: stretch;
      background-color: ${getFromTheme(THEME.COLORS.BACKGROUND_0)};
      border-top: 1px solid ${getFromTheme(THEME.COLORS.GRAY_07)};

      .app-body-foot-sidebar {
        position: relative;
        min-width: ${getFromTheme(THEME.MEASURES.SIDEBAR_WIDTH)};
        border-left: 1px solid ${getFromTheme(THEME.COLORS.GRAY_07)};

        &:after {
          content: '';
          padding: 2px 0;
          width: 1px;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background-color: ${getFromTheme(THEME.COLORS.GRAY_07)};
          background-clip: content-box;
        }
      }

      .app-body-foot-buttons {
        width: 100%;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .edit-sync-buttons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 14px;

          & > .menu-button .menu-caret {
            transform: rotate(180deg);
          }
        }

        .menu-button {
          min-width: unset;
        }
      }
    }
  }

  .app-container {
    height: 100%;
    width: 100%;
    min-width: 1152px;
    display: flex;
    flex-flow: row;

    &.mobile {
      min-width: 320px;
      justify-content: center;

      .app-header {
        display: none;
      }

      .app-body {
        width: 100%;
      }
    }
  }

  .app-listings {
    width: calc(100% - ${getFromTheme(THEME.MEASURES.SIDEBAR_WIDTH)});
    display: flex;
    flex-flow: column;
    position: relative;

    &:only-child {
      width: 100%;
    }

    .app-listings-head {
      padding: 0 20px;
      min-height: 74px;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: flex-start;

      .app-listings-head-details {
        flex: 0 0 auto;

        .app-listings-head-title {
          margin: 0 0 3px;
          color: ${getFromTheme(THEME.COLORS.GRAY_3)};
          font-size: ${getFromTheme(THEME.MEASURES.LINE_12)};
          font-weight: ${getFromTheme(THEME.FONT_WEIGHTS.REGULAR)};
          line-height: 14px;
          text-transform: uppercase;
        }

        .app-listings-head-type {
          margin: 0 18px 0 0;
          font-size: 19px;
          font-weight: ${getFromTheme(THEME.FONT_WEIGHTS.LIGHT)};
          line-height: 26px;
        }
      }

      .app-listings-head-actions {
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 14px;

        .app-listings-head-sync-buttons {
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 14px;
        }

        & > .menu-button {
          max-width: 190px;
        }

        .filter-search-input {
          width: 270px;
        }
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  p {
    margin: 0 0 1em 0;
  }

  img {
    display: block;
  }

  .error {
    color: ${getFromTheme(THEME.COLORS.ROUGE)};
  }

  .info {
    color: ${getFromTheme(THEME.COLORS.CLOVER)};
  }

  .w-100 {
    width: 100%;
  }

  .h-100 {
    height: 100%;
  }

  .d-flex {
    display: flex;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .d-none {
    display: none;
  }

  .disable-pointer-events {
    pointer-events: none;
  }

  .enable-pointer-events {
    pointer-events: auto;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .cursor-auto {
    cursor: auto !important;
  }

  .cursor-default {
    cursor: default !important;
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  .cursor-text {
    cursor: text !important;
  }

  .relative {
    position: relative;
  }

  .flex-direction-column {
    flex-direction: column;
  }

  .justify-content-center {
    justify-content: center;
  }

  .justify-content-space-between {
    justify-content: space-between;
  }

  .justify-content-start {
    justify-content: flex-start;
  }

  .justify-content-end {
    justify-content: flex-end;
  }

  .align-items-center {
    align-items: center;
  }

  .flex-all-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex-auto-size {
    flex: 1 1 auto;
  }
  .flex-fixed-size {
    flex: 1 0 auto;
  }

  .flex-grow-1 {
    flex-grow: 1;
  }

  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .text-left {
    text-align: left;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .no-overflow {
    overflow: unset !important;
  }

  .font-light {
    font-weight: ${getFromTheme(THEME.FONT_WEIGHTS.LIGHT)};
  }

  .font-regular {
    font-weight: ${getFromTheme(THEME.FONT_WEIGHTS.REGULAR)};
  }

  .font-medium {
    font-weight: ${getFromTheme(THEME.FONT_WEIGHTS.MEDIUM)};
  }

  .font-bold {
    font-weight: ${getFromTheme(THEME.FONT_WEIGHTS.BOLD)};
  }
`;

const ButtonStyle = css`
  .button,
  button,
  input[type="submit"] {
    margin: 0;
    padding: 7px 15px;
    display: block;
    background-color: ${getFromTheme(THEME.COLORS.BACKGROUND_0)};
    border: 1px solid ${getFromTheme(THEME.COLORS.GRAY_2)};
    border-radius: 3px;
    color: ${getFromTheme(THEME.COLORS.TEXT)};
    font-family: inherit;
    font-size: ${getFromTheme(THEME.MEASURES.LINE_13)};
    font-weight: ${getFromTheme(THEME.FONT_WEIGHTS.REGULAR)};
    text-decoration: none;
    line-height: ${getFromTheme(THEME.MEASURES.LINE_18)};
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.2s ease, border-color 0.2s ease, color 0.2s ease, opacity 0.2s ease;

    :hover {
      border-color: ${getFromTheme(THEME.COLORS.GRAY_3)};
    }

    :focus,
    &.focus {
      border-color: ${getFromTheme(THEME.COLORS.CLOVER)};
    }

    &.error {
      border-color: ${getFromTheme(THEME.COLORS.ROUGE)};
      color: ${getFromTheme(THEME.COLORS.TEXT)};

      :focus,
      &.focus {
        border-color: ${getFromTheme(THEME.COLORS.ROUGE)};
      }

      :hover {
        border-color: ${getFromTheme(THEME.COLORS.ROUGE_HOVER)};
      }

      :active {
        border-color: ${getFromTheme(THEME.COLORS.ROUGE_ACTIVE)};
      }
    }

    :disabled,
    &.inactive {
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    }

    &.invisible {
      visibility: hidden;
    }

    &[readonly] {
      pointer-events: none;
    }

    &.button-clover {
      background-color: ${getFromTheme(THEME.COLORS.CLOVER)};
      border-color: ${getFromTheme(THEME.COLORS.CLOVER)};
      color: ${getFromTheme(THEME.COLORS.TEXT_ACTIVE)};

      :focus,
      &.focus {
        border-color: ${getFromTheme(THEME.COLORS.CLOVER)};
      }

      :hover {
        background-color: ${getFromTheme(THEME.COLORS.CLOVER_HOVER)};
        border-color: ${getFromTheme(THEME.COLORS.CLOVER_HOVER)};
      }

      :active {
        background-color: ${getFromTheme(THEME.COLORS.CLOVER_ACTIVE)};
        border-color: ${getFromTheme(THEME.COLORS.CLOVER_ACTIVE)};
      }
    }

    &.button-rouge {
      background-color: ${getFromTheme(THEME.COLORS.ROUGE)};
      border-color: ${getFromTheme(THEME.COLORS.ROUGE)};
      color: ${getFromTheme(THEME.COLORS.TEXT_ACTIVE)};

      :focus,
      &.focus {
        border-color: ${getFromTheme(THEME.COLORS.ROUGE)};
      }

      :hover {
        background-color: ${getFromTheme(THEME.COLORS.ROUGE_HOVER)};
        border-color: ${getFromTheme(THEME.COLORS.ROUGE_HOVER)};
      }

      :active {
        background-color: ${getFromTheme(THEME.COLORS.ROUGE_ACTIVE)};
        border-color: ${getFromTheme(THEME.COLORS.ROUGE_ACTIVE)};
      }
    }
  }

  input[type="submit"] {
    -webkit-appearance: none;
  }
`;

const LinkStyle = css`
  .link,
  a:not(.button) {
    padding: 0;
    background: none;
    border: unset;
    color: ${getFromTheme(THEME.COLORS.CLOVER)};
    font-family: inherit;
    font-weight: ${getFromTheme(THEME.FONT_WEIGHTS.REGULAR)};
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease, opacity 0.2s ease;

    &.error {
      color: ${getFromTheme(THEME.COLORS.ROUGE)};
    }

    :disabled,
    &.disabled {
      opacity: 0.5;
      cursor: default;
    }

    :not(:disabled, .disabled) {
      :hover {
        color: ${getFromTheme(THEME.COLORS.CLOVER_HOVER)};
      }

      :active {
        color: ${getFromTheme(THEME.COLORS.CLOVER_ACTIVE)};
      }
    }

    &.error:not(:disabled, .disabled) {
      :hover {
        color: ${getFromTheme(THEME.COLORS.ROUGE_HOVER)};
      }

      :active {
        color: ${getFromTheme(THEME.COLORS.ROUGE_ACTIVE)};
      }
    }

    span {
      transition: inherit;
    }
  }
`;

const ModalStyle = css`
  .modal {
    .modal-overlay {
      background-color: ${getFromTheme(THEME.COLORS.CLOVER_TRANSLUCENT)};
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: ${getFromTheme(THEME.MEASURES.MODAL_Z_INDEX)};
    }

    .modal-content {
      min-width: 390px;
      max-height: calc(100vh - 40px);
      flex-shrink: 0;
      position: relative;
      background-color: ${getFromTheme(THEME.COLORS.BACKGROUND_0)};
      border-radius: 3px;
      overflow: hidden;
      box-shadow: 0 20px 40px ${getFromTheme(THEME.COLORS.SHADOW)};
      cursor: initial;

      &.photo-editor {
        overflow: unset;
      }
    }

    &.confirmation {
      .modal-content {
        padding: 25px;
        display: flex;
        flex-flow: column;

        .confirmation-title {
          margin: 0 15px 0 0;
          color: ${getFromTheme(THEME.COLORS.TEXT)};
          font-size: 19px;
          line-height: 26px;
        }

        .confirmation-desc {
          margin: 16px 50px 0 0;
          max-width: 340px;
          color: ${getFromTheme(THEME.COLORS.GRAY_6)};
          font-size: 12px;
          line-height: 18px;
        }

        .confirmation-close-button {
          width: 18px;
          height: 18px;
          position: absolute;
          right: 15px;
          top: 15px;
        }

        .confirmation-buttons {
          margin-top: 36px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 14px;

          .confirmation-buttons-spacer {
            flex-grow: 1;
          }
        }
      }

      &.edit-confirmation {
        &.schedule-updates .modal-content {
          overflow: unset;
        }
      }

      &.publish-product {
        .modal-content {
          min-width: 385px;
          max-width: 385px;
        }

        .confirmation-desc {
          margin-right: 8px;
        }
      }

      &.update-products {
        .confirmation-desc {
          margin-right: 0;
          max-width: 490px;
          font-size: 13px;
          line-height: 22px;
        }

        .menu-button {
          width: auto;
          min-width: unset;
        }
      }

      &.create-product {
        .modal-content {
          min-width: 486px;
          max-width: 486px;
          display: flex;
          overflow: hidden;
          flex-flow: column;
        }

        .confirmation-desc {
          margin-right: 0;
          max-width: unset;
        }
      }
    }

    &.admin-modal {
      display: initial;
      margin: initial;
      padding: initial;
      width: initial;
      max-height: initial;
      overflow-y: initial;
      position: initial;
      left: initial;
      right: initial;
      background-color: initial;
      border-radius: initial;
      will-change: initial;

      .modal-overlay {
        width: initial;
        height: initial;
        will-change: initial;
      }

      .bold {
        font-weight: ${getFromTheme(THEME.FONT_WEIGHTS.BOLD)};
      }

      .reassign-modal {
        width: 55%;
        max-width: 900px;
        max-height: calc(100vh - 40px);
        overflow-y: auto;

        .reassign-modal-body {
          margin-bottom: 30px;
        }

        .reassign-modal-footer {
          width: 100%;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }

    &.video-modal {
      .modal-content {
        max-width: 720px;
        min-width: 720px;
      }
    }

    &.waitlist-modal {
      .modal-content {
        max-width: 385px;
        min-width: 385px;

        .confirmation-desc {
          margin-right: 30px;
        }
      }
    }

    &.import-listings-load-file-modal {
      .modal-content {
        max-width: 600px;
        min-width: 600px;

        .confirmation-desc {
          margin-right: 30px;
          max-width: unset;
          font-size: 13px;
        }

        .confirmation-buttons {
          margin-top: 30px;
        }
      }
    }

    &.import-listings-map-fields-modal {
      .modal-content {
        padding: 18px;
        max-width: calc(100vw - 40px);
        min-width: unset;

        .confirmation-title {
          margin: 12px 27px 0 12px;
        }

        .confirmation-desc {
          margin: 0;
          padding: 16px 42px 0 12px;
          width: min-content;
          max-width: unset;
          min-width: 100%;
          font-size: 13px;
        }

        .confirmation-buttons {
          margin: 30px 12px 12px;
        }
      }
    }

    &.import-listings-manage-settings-modal {
      .modal-content {
        max-width: 480px;
        min-width: 480px;
      }
    }

    &.canva-message-modal {
      .modal-content {
        padding: 50px 50px 40px;
        max-width: 370px;
        min-width: 370px;
      }
    }

    &.export-listings-modal {
      .modal-content {
        max-width: 430px;
        min-width: 430px;

        .confirmation-desc {
          margin-right: 10px;
          max-width: unset;
          font-size: 13px;
        }
      }
    }
  }
`;

export default createGlobalStyle`
  ${LayoutStyle}
  ${ButtonStyle}
  ${LinkStyle}
  ${ModalStyle}
`;
