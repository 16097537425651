import { List, Map } from 'immutable';

import { isChannelFullySupported } from '../featureFlags';

export function canConnectShop({ connectedShops = List(), userShops = Map() }) {
  return function canConnect(shopId) {
    return (
      userShops.hasIn(['byId', shopId]) &&
      isChannelFullySupported(userShops.getIn(['byId', shopId, 'channel'])) &&
      !connectedShops.includes(shopId)
    );
  };
}
