import { EBAY, ETSY, SHOPIFY } from './channels';
import { MODALS } from './modal';

export const STATUSES = {
  ADMIN_REFRESH: 'admin_refresh',
  ADMIN_SYNC: 'admin_sync',
  APPLYING_OPERATIONS: 'applying_operations',
  APPLY_OPS_SYNC: 'apply_ops_sync',
  COPYING: 'copying',
  INACTIVE_USER: 'inactive_user',
  INCOMPLETE: 'incomplete', // Existing shop, import or sync was performed with error,
  INCOMPLETE_DUPLICATE: 'incomplete_duplicate',
  INCOMPLETE_IN_VACATION_MODE: 'incomplete_shop_sync_in_vacation_mode',
  INCOMPLETE_TOO_MANY_LISTINGS: 'incomplete_too_many_listings',
  INITIAL_SYNC: 'initial_sync',
  NOT_FOUND: 'not_found', // For Shopify not found means store is close,
  PENDING_FOR_DOWNLOAD: 'pending_for_download',
  PUBLISHING_COPY: 'publishing_copy',
  QUOTA_EXCEEDED: 'quota_exceeded',
  REFRESH_USER: 'refresh_user',
  REIMPORT_SHOP: 'reimport_shop',
  SAVING_COPY_CHANGES: 'saving_copy_changes',
  SCHEDULE_SYNC: 'schedule_sync',
  SHOP_IMPORT_FAILURE: 'shop_import_failure',
  SYNC: 'sync',
  SYNC_PENDING: 'sync_pending',
  TOKEN_REJECTED: 'token_rejected',
  UNAVAILABLE_FORBIDDEN: 'unavailable_forbidden',
  UNAVAILABLE_LOCKED: 'unavailable_locked',
  UNAVAILABLE_PAYMENT_REQUIRED: 'unavailable_payment_required',
  UNAVAILABLE_SHOP: 'unavailable_shop', // For Shopify unavailable shop means store is pause
  UNKNOWN_ERROR: 'unknown_error',
  UPLOAD_COMPLETE: 'upload_complete',
  UP_TO_DATE: 'up_to_date', // Synchronized and up to date,
  USER_INITIATED_REFRESH: 'user_initiated_refresh',
  SCHEDULED_UPDATE: 'scheduled_update',
};

export const POLLING_INTERVAL = {
  SHORT: 2000,
  LONG: 10 * 1000,
};

export const DAYS_TO_REFRESH = 7;

export const SYNC_INDICATOR = {
  HIDDEN: 'HIDDEN',
  PUBLISH: 'PUBLISH',
  REFRESH: 'REFRESH',
  SAVE: 'SAVE',
  SYNC: 'SYNC',
};

export const STEP = {
  DOWNLOAD_IMPORTING: 'download_importing',
  DOWNLOAD_SAVING: 'download_saving',
  EXPORTING: 'exporting',
  IMPORTING: 'importing',
  SAVING: 'saving',
};

export const STATUS_TO_INDICATOR = {
  [STATUSES.ADMIN_REFRESH]: SYNC_INDICATOR.REFRESH,
  [STATUSES.ADMIN_SYNC]: SYNC_INDICATOR.REFRESH,
  [STATUSES.APPLYING_OPERATIONS]: SYNC_INDICATOR.SYNC,
  [STATUSES.SAVING_COPY_CHANGES]: SYNC_INDICATOR.SAVE,
  [STATUSES.SCHEDULE_SYNC]: SYNC_INDICATOR.REFRESH,
  [STATUSES.SYNC]: SYNC_INDICATOR.SYNC,
};

export const SYNC_MODAL = {
  [STATUSES.COPYING]: MODALS.SYNC.COPY,
  [STATUSES.PUBLISHING_COPY]: MODALS.SYNC.PUBLISH,
  [STATUSES.SCHEDULED_UPDATE]: MODALS.SYNC.SCHEDULED_UPDATE,
  [STATUSES.USER_INITIATED_REFRESH]: MODALS.SYNC.REFRESH,
};

export const WAIT_FOR_SYNC = {
  FINISH: 'finish',
  START_OR_FINISH: 'start_finish',
};

export const SYNC_STEPS = {
  [EBAY]: {
    [MODALS.SYNC.REFRESH]: [
      STEP.IMPORTING,
    ],
  },
  [ETSY]: {
    [MODALS.SYNC.PUBLISH]: [
      STEP.EXPORTING,
    ],
    [MODALS.SYNC.REFRESH]: [
      STEP.IMPORTING,
    ],
    [MODALS.SYNC.SCHEDULED_UPDATE]: [
      STEP.IMPORTING,
      STEP.EXPORTING,
    ],
    [SYNC_INDICATOR.PUBLISH]: [
      STEP.EXPORTING,
    ],
    [SYNC_INDICATOR.REFRESH]: [
      STEP.IMPORTING,
    ],
    [SYNC_INDICATOR.SYNC]: [
      STEP.EXPORTING,
    ],
  },
  [SHOPIFY]: {
    [MODALS.SYNC.PUBLISH]: [
      STEP.EXPORTING,
      STEP.SAVING,
    ],
    [MODALS.SYNC.REFRESH]: [
      STEP.IMPORTING,
      STEP.SAVING,
    ],
    [MODALS.SYNC.SCHEDULED_UPDATE]: [
      STEP.DOWNLOAD_IMPORTING,
      STEP.DOWNLOAD_SAVING,
      STEP.EXPORTING,
      STEP.SAVING,
    ],
    [SYNC_INDICATOR.PUBLISH]: [
      STEP.EXPORTING,
      STEP.SAVING,
    ],
    [SYNC_INDICATOR.REFRESH]: [
      STEP.IMPORTING,
      STEP.SAVING,
    ],
    [SYNC_INDICATOR.SYNC]: [
      STEP.EXPORTING,
      STEP.SAVING,
    ],
  },
};

export const CSV_STATUS = {
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  NONE: 'NONE',
};

