import { fromBlob, fromBuffer } from 'file-type/browser';

import { getImageTypeByUrl } from '../photos';
import request from './request';

import { ENDPOINT, HEADERS } from '../../constants/api';
import { IMAGES_PER_PAGE } from '../../constants/mediaLibrary';
import { IMAGE_TYPE } from '../../constants/photos';

export default {
  createFolder({ db, name, shopId }) {
    return request.post({
      endpoint: ENDPOINT.WEB_V2,
      params: { db },
      payload: { folderName: name },
      url: `/shops/${shopId}/mediaLibrary/folders/`,
    });
  },
  deleteFolder({ db, folder, shopId }) {
    return request.delete({
      endpoint: ENDPOINT.WEB_V2,
      params: { db },
      url: `/shops/${shopId}/mediaLibrary/folders/${folder}`,
    });
  },
  deleteImages({ db, shopId, uuids }) {
    return request.delete({
      endpoint: ENDPOINT.WEB_V2,
      params: { db },
      payload: { uuids },
      url: `/shops/${shopId}/mediaLibrary/files`,
    });
  },
  downloadImage({ data, signal, url }) {
    return new Promise(function executor(resolve, reject) {
      if (data instanceof ArrayBuffer) {
        fromBuffer(data)
          .then(
            function onFileType({ ext, mime }) {
              if (signal && signal.aborted) return;

              resolve({ blob: new Blob([data]), format: ext, mime });
            },
            reject,
          );
      } else {
        const type = getImageTypeByUrl(url);

        if (type === null) reject('Unknown image type');

        const options = {
          credentials: type === IMAGE_TYPE.ETSY,
          signal,
          url,
        };

        if (options.credentials) {
          options.params = { url };
          options.url = '/getImage64';
        }

        request
          .get(options)
          .then(
            function onImageDownloaded(blob) {
              if (signal && signal.aborted) return;

              fromBlob(blob)
                .then(
                  function onFileType({ ext, mime }) {
                    resolve({ blob, format: ext, mime });
                  },
                  reject,
                );
            },
            reject,
          );
      }
    });
  },
  downloadImages({ db, shopId, uuids }) {
    if (typeof uuids === 'string') {
      return request.get( {
        endpoint: ENDPOINT.WEB_V2,
        params: { db },
        url: `/shops/${shopId}/mediaLibrary/download/${uuids}`,
      });
    } else if (Array.isArray(uuids)) {
      return request.post({
        endpoint: ENDPOINT.WEB_V2,
        params: { db },
        payload: { uuids: uuids },
        url: `/shops/${shopId}/mediaLibrary/bulkDownload`,
      });
    } else {
      return Promise.reject();
    }
  },
  getFolders({ db, shopId }) {
    return request.get({
      endpoint: ENDPOINT.WEB_V2,
      params: { db },
      url: `/shops/${shopId}/mediaLibrary/folders`,
    });
  },
  getImages({ db, folder, page, shopId, signal, query }) {
    const params = { db, limit: IMAGES_PER_PAGE, offset: page * IMAGES_PER_PAGE };

    if (query) {
      params.q = `alt_text:${query}`;
    }

    return request.get( {
      endpoint: ENDPOINT.WEB_V2,
      params,
      signal,
      url: folder
        ? `/shops/${shopId}/mediaLibrary/folders/${folder}/images`
        : `/shops/${shopId}/mediaLibrary/images`,
    });
  },
  moveImages({ db, shopId, source: sourceFolderUuid, target: destinationFolderUuid, uuids }) {
    return request.put( {
      endpoint: ENDPOINT.WEB_V2,
      params: { db },
      payload: {
        uuids,
        destinationFolderUuid,
        sourceFolderUuid,
      },
      url: `/shops/${shopId}/mediaLibrary/files/move`,
    });
  },
  getScene({ scene, signal }) {
    return request.get({
      endpoint: ENDPOINT.WEB_V2,
      params: { scene },
      signal,
      url: `/photoEditor/scenery`,
    });
  },
  getUnsplashDownloadURL(downloadLocationUrl) {
    return request.post({
      payload: { downloadLocationUrl },
      url: '/shops/unsplash/download',
    });
  },
  isolate(image) {
    const payload = new FormData();
    payload.append('image_file', image);

    return request.post({
      endpoint: ENDPOINT.WEB_V2,
      payload,
      url: `/photoEditor/isolate`,
    });
  },
  renameFolder({ db, folder, name, shopId }) {
    return request.put({
      endpoint: ENDPOINT.WEB_V2,
      params: { db },
      payload: { name },
      url: `/shops/${shopId}/mediaLibrary/folders/${folder}`,
    });
  },
  savePhoto({ db, folder, format, name, shopId, url, altText }) {
    return request.post({
      endpoint: ENDPOINT.WEB_V2,
      params: { db },
      payload: { fileName: name, folderUuid: folder || undefined, format, url, alt_text: altText },
      url: `/shops/${shopId}/mediaLibrary/files`,
    });
  },
  searchUnsplash(params) {
    return request.get({
      params,
      url: '/shops/unsplash/search',
    });
  },
  smartRemove({ image, mask }) {
    const payload = new FormData();
    payload.append('image_file', image);
    payload.append('mask_file', mask);

    return request.post({
      endpoint: ENDPOINT.WEB_V2,
      payload,
      url: `/photoEditor/smart-remove`,
    });
  },
  updatePhoto({ db, folder, format, name, shopId, url, uuid }) {
    return request.put({
      endpoint: ENDPOINT.WEB_V2,
      params: { db },
      payload: { fileName: name, folderUuid: folder, format, url },
      url: `/shops/${shopId}/mediaLibrary/files/${uuid}`,
    });
  },
  uploadToS3({ data, db, mime, shopId }) {
    return new Promise(function executor(resolve, reject) {
      request.get({
        endpoint: ENDPOINT.WEB_V2,
        params: { db, mime },
        url: `/shops/${shopId}/mediaLibrary/files/uploadUrl`,
      })
        .then(
          function onUploadURL({ uploadUrl }) {
            request.put({
              headers: { [HEADERS.CONTENT_TYPE]: mime, [HEADERS.EXPIRES]: 0 },
              payload: data,
              url: uploadUrl,
            })
              .then(
                function onUploaded() {
                  const url = new URL(uploadUrl);
                  resolve({ url: `${url.protocol}/${url.pathname}` });
                },
                reject,
              );
          },
          reject,
        );
    });
  },
};
