
import { Map } from 'immutable';

import { shapeVariationsProfileForApp } from '../profiles/shapeForApp';
import { isApplyDisabled } from './isApplyDisabled';
import { tagsFromArray } from '../tags';
import { shapeId } from '../listings/listings';

import { CATEGORY, TAGS, VARIATIONS } from '../../constants/attributes';
import { MENU_ITEM } from '../../constants/bulkEdit';
import { PROFILE } from '../../constants/profiles';

export function applyProfile({ bulkEdit: source, data, type }) {
  let bulkEdit = source;

  switch (type) {
    case PROFILE.TAGS: {
      if (!data?.profile || bulkEdit.getIn(['menu', 'selected', 'item']) !== MENU_ITEM.TAGS) {
        return bulkEdit;
      }

      const preview = bulkEdit.getIn(['preview', TAGS], Map());
      const profile = Map({
        profileId: shapeId(data.profile.id),
        [TAGS]: tagsFromArray(data.profile.tags),
      });

      bulkEdit = bulkEdit.setIn(['preview', TAGS], preview.set('profile', profile));
      break;
    }

    case PROFILE.VARIATIONS: {
      if (!data?.profile || bulkEdit.getIn(['menu', 'selected', 'item']) !== MENU_ITEM.VARIATIONS) {
        return bulkEdit;
      }

      if (bulkEdit.hasIn(['preview', CATEGORY])) {
        bulkEdit = bulkEdit
          .deleteIn(['preview', 'index'])
          .deleteIn(['preview', CATEGORY]);
      }

      bulkEdit = bulkEdit.setIn(['preview', VARIATIONS],
        shapeVariationsProfileForApp({ profile: data.profile, channel: bulkEdit.get('channel') })
      );

      break;
    }

    default: {
      break;
    }
  }

  return bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit));
}
