import XRegExp from 'xregexp';

import { startsWithAlphanumeric, usedMoreThanAllowed } from './utils';

import { MAX_TITLE_LENGTH, ERRORS } from '../../constants/validations';
import { ETSY } from '../../constants/channels';

export function getTitleError({ channel, value }) {
  if (!value.length) {
    return ERRORS.TITLE.REQUIRED;
  } else if (value.length > MAX_TITLE_LENGTH[channel]) {
    return ERRORS.TITLE.LENGTH_EXCEEDED[channel];
  }

  function forbiddenCharacters() {
    switch (channel) {
      case ETSY: {
        // https://www.etsy.com/developers/documentation/reference/listing
        // listing title regexp: /[^\p{L}\p{Nd}\p{P}\p{Sm}\p{Zs}™©®]/u
        return XRegExp('[^\\p{L}\\p{Nd}\\p{P}\\p{Sm}\\p{Zs}™©®]').test(value);
      }

      default: {
        return false;
      }
    }
  }

  switch (channel) {
    case ETSY: {
      if (!startsWithAlphanumeric(value)) {
        return ERRORS.TITLE.ALPHANUMERIC_BEGIN;
      } else if (forbiddenCharacters({ channel, value })) {
        return ERRORS.TITLE.FORBIDDEN_CHARACTERS[channel];
      } else if (usedMoreThanAllowed({ patterns: ['\\b\\p{Lu}{2,}\\b'], number: 3, value })) {
        return ERRORS.TITLE.CAPITALIZED_WORDS_NUMBER_EXCEEDED;
      } else if (usedMoreThanAllowed({ patterns: ['[&]', '[%]', '[\:]', '[\u0026]', '[\u0025]', '[\u003A]'], number: 1, value })) {
        return ERRORS.TITLE.SPECIAL_CHARACTERS_NUMBER_EXCEEDED;
      } else {
        return false;
      }
    }

    default: {
      return false;
    }
  }
}
