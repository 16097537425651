import { capitalize } from '../utils/string';
import { reduce } from '../utils/iterable/reduce';

import { SEPARATOR } from '../constants';

function toActionName(constant) {
  const words = constant.toLowerCase().split('_');
  const first = words.shift();
  return first + words.map((word) => capitalize(word)).join('');
}

export class Actions {
  constructor(ns = '') {
    Object.defineProperty(this, 'namespace', { value: ns });
    Object.defineProperty(this, 'parent', { value: null, writable: true });
  }

  setParent(parent) {
    this.parent = parent;
  }

  getNameSpace() {
    const ns = [];

    if (this.parent && !!this.parent.getNameSpace()) {
      ns.push(this.parent.getNameSpace());
    }

    if (!!this.namespace) {
      ns.push(this.namespace);
    }

    return ns.join(SEPARATOR.DOT);
  }

  withNameSpace(action) {
    return `${this.getNameSpace()}.${toActionName(action)}`;
  }

  addAsync(action) {
    this.add(action);
    this.add(`${action}_started`);
    this.add(`${action}_succeeded`);
    this.add(`${action}_failed`);
    return this;
  }

  add(action) {
    if (typeof action === 'string') {
      const actionKey = action.toUpperCase();
      Object.defineProperty(this, actionKey, { get: () => this.withNameSpace(action), enumerable: true });
    } else {
      const ns = action.namespace.toUpperCase();
      action.setParent(this);
      this[ns] = action;
    }
    return this;
  }

  actionCreators() {
    return reduce(this, {}, (result, value, key) => {
      result[toActionName(key)] = (payload) => ({ type: value, payload });
      return result;
    });
  }
}
