import { convertProfile } from './convert';
import { getCopyTitle } from './getCopyTitle';

import { PROFILE } from '../../constants/profiles';

export function copyProfile({ channel, profile: source, type }) {
  switch (type) {
    case PROFILE.LISTINGS: {
      return undefined;
    }

    default: {
      const profile = source
        .delete('id')
        .set('title', getCopyTitle(source.get('title')));

      return profile.get('channel') === channel
        ? profile
        : convertProfile({ type, profile, to: channel });
    }
  }
}
