import { Map } from 'immutable';
import { createBrowserHistory } from 'history';
import { createSearchParams, generatePath, matchPath } from 'react-router-dom';

import { isBillingError, isUnpaid } from './billing';
import { isImporting, isInvalid } from './shops';
import { isProductEdited } from './product/isProductEdited';
import { isValidNumber } from './number';
import { getSize } from './iterable/getSize';
import { store } from '../store';

import { PAGES, PAGE_TO_ROUTE_MAP, PARAMS, ROUTES } from '../constants/routes';
import { STATUSES } from '../constants/shops';
import { PROFILE } from '../constants/profiles';
import { STATUS } from '../constants/listings';
import { MODALS } from '../constants/modal';
import { PLAN } from '../constants/billing';

import Actions from '../actions';

export const history = createBrowserHistory();

export function navigateTo(route, external = false) {
  external
    ? window.location = route
    : history.push(route);
}

export function goBack(fallback = ROUTES.ROOT) {
  typeof history.goBack === 'function'
    ? history.goBack()
    : navigateTo(fallback);
}

export function openNewTab(route) {
  window.open(route, '_blank', 'noopener=true,noreferrer=true');
}

export function navigation({ dispatch = store.dispatch, state } = {}) {
  function getState() {
    return state || store.getState();
  }

  function getDefaultProfileType() {
    return PROFILE.LISTINGS;
  }

  function getPage() {
    const { location: { pathname }} = history;

    for (const page in PAGE_TO_ROUTE_MAP) {
      if (PAGE_TO_ROUTE_MAP.hasOwnProperty(page)) {
        const path = PAGE_TO_ROUTE_MAP[page];

        if (matchPath({ path, end: true }, pathname)) {
          return page;
        }
      }
    }

    return undefined;
  }

  const PAGE = getPage();

  function makeRoute({ page, route, ...rest }) {
    const params = { ...rest };

    switch (page) {
      case PAGES.BILLING: {
        if (rest[PARAMS.SHOP_ID]) {
          if (!isValidNumber(rest[PARAMS.SHOP_ID]) || !getState().hasIn(['shops', 'byId', rest[PARAMS.SHOP_ID]])) {
            const current = getState().getIn(['shops', 'current']);

            if (current) {
              params[PARAMS.SHOP_ID] = getState().getIn(['shops', 'current']);
            }
          }

          return `${PAGE_TO_ROUTE_MAP[page]}?${createSearchParams(rest)}`;
        }

        break;
      }

      case PAGES.BULK_EDIT:
      case PAGES.EDIT:
      case PAGES.LISTINGS:
      case PAGES.NEW:
      case PAGES.STUDIO:
      case PAGES.STUDIO_EDITOR:
      case PAGES.STUDIO_LIBRARY:
      case PAGES.PROFILES:
      case PAGES.SCHEDULED_UPDATES:
      case PAGES.TOOLS: {
        if (!isValidNumber(rest[PARAMS.SHOP_ID]) || !getState().hasIn(['shops', 'byId', rest[PARAMS.SHOP_ID]])) {
          const current = getState().getIn(['shops', 'current']);

          if (current) {
            params[PARAMS.SHOP_ID] = getState().getIn(['shops', 'current']);
          }
        }

        break;
      }

      default: {
        break;
      }
    }

    return route
      ? generatePath(route, params)
      : PAGE_TO_ROUTE_MAP.hasOwnProperty(page)
        ? generatePath(PAGE_TO_ROUTE_MAP[page], params)
        : undefined;
  }

  function canNavigateTo({ page, route }) {
    if (route === history.location.pathname) return false;

    switch (page) {
      case PAGES.STUDIO: {
        if (PAGE === PAGES.STUDIO_EDITOR || PAGE === PAGES.STUDIO_LIBRARY) {
          return false;
        }

        break;
      }

      default: {
        break;
      }
    }

    const shopId = getState().getIn(['shops', 'current']);

    const subscription = getState().getIn(['user', 'subscriptions', shopId]);
    const syncStatus = getState().getIn(['shops', 'byId', shopId, 'syncStatus']);

    if (
      isUnpaid(subscription) ||
      isBillingError({ plan: subscription.get('plan') || PLAN.ERROR, syncStatus }) ||
      isInvalid(syncStatus) ||
      isImporting(syncStatus) || (
        syncStatus === STATUSES.TOKEN_REJECTED ||
        syncStatus === STATUSES.INCOMPLETE_IN_VACATION_MODE ||
        (
          syncStatus === STATUSES.QUOTA_EXCEEDED &&
          !getState().getIn(['shops', 'hideRateLimitPage', shopId])
        )
      )
    ) {
      return true;
    }

    switch (PAGE) {
      case PAGES.BULK_EDIT: {
        dispatch(Actions.BulkEdit.closeBulkEdit({ route }));
        return false;
      }

      case PAGES.PROFILES: {
        if (getSize(getState().getIn(['profiles', 'editing']))) {
          dispatch(Actions.Profiles.setModal({ type: MODALS.PROFILES.LEAVE, route }));
          return false;
        }

        return true;
      }

      case PAGES.EDIT:
      case PAGES.NEW: {
        if (isProductEdited(getState().getIn(['edit', 'products'], Map()))) {
          dispatch(Actions.Edit.setModal({ type: MODALS.CONFIRMATIONS.CANCEL, route }));
          return false;
        }

        return true;
      }

      case PAGES.STUDIO_EDITOR: {
        if (getState().getIn(['photoEditor', 'hasUnsavedChanges'])) {
          dispatch(
            Actions.PhotoEditor.setValue({
              path: 'confirmation',
              value: Map({ type: MODALS.CONFIRMATIONS.LEAVE_PHOTO_EDITOR, route }),
            })
          );

          return false;
        }

        return true;
      }

      default: {
        return true;
      }
    }
  }

  function goTo({ page, ...rest }) {
    const params = { ...rest };

    switch (page) {
      case PAGES.LISTINGS: {
        params.route = `${ROUTES.SHOP.ROOT}${ROUTES.SHOP.LISTINGS.ROOT}${ROUTES.SHOP.LISTINGS.STATUS}`;

        if (!params[PARAMS.STATUS]) {
          params[PARAMS.STATUS] = (
            getState().getIn(['listings', 'status']) ||
            matchPath({ path: params.route, end: true }, history.location.pathname)?.params?.status ||
            STATUS.ACTIVE
          );
        }

        break;
      }

      case PAGES.STUDIO: {
        params.route = PAGE_TO_ROUTE_MAP[PAGES.STUDIO_EDITOR];
        break;
      }

      case PAGES.PROFILES: {
        params.route = `${ROUTES.SHOP.ROOT}${ROUTES.SHOP.PROFILES.ROOT}${ROUTES.SHOP.PROFILES.TYPE}`;

        if (!params[PARAMS.TYPE]) {
          params[PARAMS.TYPE] = (
            matchPath({ path: params.route, end: true }, history.location.pathname)?.params?.type ||
            getDefaultProfileType()
          );
        }

        break;
      }

      case PAGES.TOOLS: {
        switch (PAGE) {
          case PAGES.LISTINGS: {
            params.route = `${ROUTES.SHOP.ROOT}${ROUTES.SHOP.LISTINGS.ROOT}${ROUTES.SHOP.LISTINGS.STATUS}`;
            params[PARAMS.STATUS] = (
              getState().getIn(['listings', 'status']) ||
              matchPath({ path: params.route, end: true }, history.location.pathname)?.params?.status ||
              STATUS.ACTIVE
            );

            break;
          }

          case PAGES.PROFILES: {
            params.route = `${ROUTES.SHOP.ROOT}${ROUTES.SHOP.PROFILES.ROOT}${ROUTES.SHOP.PROFILES.TYPE}`;
            params[PARAMS.TYPE] = (
              matchPath({ path: params.route, end: true }, history.location.pathname)?.params?.type ||
              getDefaultProfileType()
            );

            break;
          }

          case PAGES.SCHEDULED_UPDATES: {
            params.route = PAGE_TO_ROUTE_MAP[PAGE];
            break;
          }

          default: {
            params.route = `${ROUTES.SHOP.ROOT}${ROUTES.SHOP.LISTINGS.ROOT}${ROUTES.SHOP.LISTINGS.STATUS}`;
            params[PARAMS.STATUS] = (
              getState().getIn(['listings', 'status']) ||
              STATUS.ACTIVE
            );

            break;
          }
        }

        break;
      }

      default: {
        break;
      }
    }

    const route = makeRoute({ page, ...params });

    if (canNavigateTo({ page, route })) {
      navigateTo(route);
    }
  }

  function goToShop(shopId) {
    let page = PAGE;

    if (
      page !== PAGES.LISTINGS &&
      page !== PAGES.STUDIO_EDITOR &&
      page !== PAGES.STUDIO_LIBRARY &&
      page !== PAGES.PROFILES &&
      page !== PAGES.SCHEDULED_UPDATES
    ) {
      page = PAGES.LISTINGS;
    }

    goTo({ page, shopId });
  }

  return {
    goTo,
    goToShop,
    makeRoute,
    PAGE,
  };
}
