import { Map } from 'immutable';

import { escapeRegex } from '../../string';

import { FIND, OPERATIONS, REPLACE } from '../../../constants/bulkEdit';
import { PAGE_TITLE, SEO, TITLE } from '../../../constants/attributes';
import { OVERSET, VALUE } from '../../../constants/product';
import { SHOPIFY } from '../../../constants/channels';

export default function seoTitle({ actions, operation, product }) {
  function setValueToProduct(value) {
    const result = {
      actions,
      product: product.setIn([SEO, PAGE_TITLE, VALUE], value),
    };

    if (!value) {
      result.product = result.product.setIn([SEO, PAGE_TITLE],
        Map({ [OVERSET]: true, [VALUE]: result.product.getIn([TITLE, VALUE]) })
      );
    } else if (value === result.product.getIn([TITLE, VALUE])) {
      result.product = result.product.setIn([SEO, PAGE_TITLE, OVERSET], true);
    } else if (result.product.getIn([SEO, PAGE_TITLE, OVERSET])) {
      result.product = result.product.deleteIn([SEO, PAGE_TITLE, OVERSET]);
    }

    return result;
  }

  switch (operation.get('type')) {
    case OPERATIONS[SHOPIFY].PAGE_TITLE.ADD_AFTER: {
      const value = `${product.getIn([SEO, PAGE_TITLE, VALUE])}${operation.get(VALUE)}`;
      return setValueToProduct(value);
    }

    case OPERATIONS[SHOPIFY].PAGE_TITLE.ADD_BEFORE: {
      const value = `${operation.get(VALUE)}${product.getIn([SEO, PAGE_TITLE, VALUE])}`;
      return setValueToProduct(value);
    }

    case OPERATIONS[SHOPIFY].PAGE_TITLE.DELETE: {
      const pattern = new RegExp(escapeRegex(operation.get(VALUE)), 'ig');
      const value = product.getIn([SEO, PAGE_TITLE, VALUE]).replace(pattern, '');
      return setValueToProduct(value);
    }

    case OPERATIONS[SHOPIFY].PAGE_TITLE.FIND_AND_REPLACE: {
      const pattern = new RegExp(escapeRegex(operation.getIn([VALUE, FIND])), 'ig');
      const value = product
        .getIn([SEO, PAGE_TITLE, VALUE])
        .replace(pattern, operation.getIn([VALUE, REPLACE]));

      return setValueToProduct(value);
    }

    case OPERATIONS[SHOPIFY].PAGE_TITLE.SET: {
      const value = operation.get(VALUE);
      return setValueToProduct(value);
    }

    default: {
      return { actions, product };
    }
  }
}
