import { Map } from 'immutable';

import { parseTagsFromInput, tagsFromArray } from '../../tags';
import { getTagsError } from '../../validations/tags';
import { clearErrors } from '../../errors';
import { keepOld } from '../../iterable/keepOld';

import { MAX_NUMBER_OF_TAGS } from '../../../constants/validations';
import { OPERATIONS } from '../../../constants/bulkEdit';
import { TAGS } from '../../../constants/attributes';

export default function tags({ actions, operation, product }) {
  const channel = product.get('channel');

  function validateAndApply(value) {
    return {
      actions,
      product: getTagsError({ channel, tags: value })
        ? product
        : clearErrors({ errors: [TAGS], product: product.set(TAGS, Map({ value })) }),
    };
  }

  switch (operation.get('type')) {
    case OPERATIONS[channel].TAGS.ADD: {
      const productValue = product.getIn([TAGS, 'value']);

      if (productValue.size >= MAX_NUMBER_OF_TAGS[channel]) break;

      const operationValue = parseTagsFromInput({
        channel,
        tags: productValue,
        value: operation.get('value'),
      });

      return validateAndApply(productValue.mergeWith(keepOld, operationValue));
    }

    case OPERATIONS[channel].TAGS.DELETE: {
      const operationValue = parseTagsFromInput({ channel, value: operation.get('value') });
      const value = product.getIn([TAGS, 'value']).deleteAll(operationValue.keySeq());
      return validateAndApply(value);
    }

    case OPERATIONS[channel].TAGS.CHANGE_TO: {
      const value = tagsFromArray(operation.get('value'));
      return validateAndApply(value);
    }

    default: {
      break;
    }
  }

  return { actions, product };
}
