import request from './request';

import { ENDPOINT, HEADERS } from '../../constants/api';

export async function getUserDetails() {
  return request.get({
    endpoint: ENDPOINT.AUTH,
    url: '/profile/getUserInfo',
  });
}

export async function updateUserDetails(payload) {
  return request.put({
    endpoint: ENDPOINT.AUTH,
    url: '/profile/updateUserInfo',
    payload,
  });
}

export async function updateUserEmail(payload) {
  return request.put({
    endpoint: ENDPOINT.AUTH,
    url: '/profile/updateEmail',
    payload,
  });
}

export async function updateUserPassword(payload) {
  return request.put({
    endpoint: ENDPOINT.AUTH,
    url: '/profile/updatePassword',
    payload,
  });
}

export async function uploadImageToS3({ hash, mime, data }) {
  const signedUrl = await request.get({
    endpoint: ENDPOINT.AUTH,
    url: '/profile/getImageUrl',
    params: { hash, mime },
  });

  if (signedUrl?.data?.uploadUrl) {
    return request.put({
      url: signedUrl.data.uploadUrl,
      payload: data,
      headers: { [HEADERS.CONTENT_TYPE]: mime, [HEADERS.EXPIRES]: 0 },
    });
  }

  return Promise.resolve();
}
