import ActionConstants, { Actions } from '../constants/actions';

const authConstants = new Actions('Auth')
  .addAsync('commit_password_reset')
  .addAsync('create_account')
  .add('remove_error')
  .addAsync('request_password_reset')
  .add('reset_password_status')
  .add('set_affiliate')
  .add('set_logged_in')
  .addAsync('sign_in');

ActionConstants.add(authConstants);

export default authConstants.actionCreators();
