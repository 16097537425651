import mixpanel from 'mixpanel-browser';
import { Set } from 'immutable';

import { getPlanName } from '../billing';
import { capitalize } from '../string';
import { store } from '../../store';

const PROJECT_TOKEN = process.env.MIXPANEL_TOKEN;

export default {
  init() {
    if (!PROJECT_TOKEN) return;

    this.initialized = true;
    mixpanel.init(PROJECT_TOKEN);
  },
  setDistinctId(email) {
    this.distinctId = email;
  },
  track(event, properties = {}) {
    if (!this.initialized || !this.distinctId || typeof event !== 'string') return;

    mixpanel.track(event, { ...properties, distinct_id: this.distinctId });
  },
  getChannelsAndPlans({ shopIds, state: provided }) {
    const state = provided || store.getState();

    function reduceShopIdsToChannelsSet(result, shopId) {
      const channelName = capitalize(state.getIn(['shops', 'byId', shopId, 'channel']));
      const multiple = `${channelName} multiple`;

      if (result.has(multiple)) {
        return result;
      } else if (result.has(channelName)) {
        return result.delete(channelName).add(multiple);
      } else {
        return result.add(channelName);
      }
    }

    function reduceShopIdsToPlanSet(result, shopId) {
      const planName = getPlanName(state.getIn(['user', 'subscriptions', shopId]));
      return planName && !result.has(planName)
        ? result.add(planName)
        : result;
    }

    return {
      channel: shopIds.reduce(reduceShopIdsToChannelsSet, Set()).toArray(),
      plan: shopIds.reduce(reduceShopIdsToPlanSet, Set()).toArray(),
    };
  },
};
