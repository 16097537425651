import { reduceErrors } from '../errors';
import { getSize } from '../iterable/getSize';

import { FIND, OPERATIONS, REPLACE, MENU_ITEM } from '../../constants/bulkEdit';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { ERROR, VALUE } from '../../constants/product';
import { DEFAULTS } from '../../constants';
import {
  BARCODE,
  CAP,
  CATEGORY,
  CHARGE_TAX,
  CHAR_COUNT_MAX,
  COLLECTIONS,
  CONTINUE_SELLING,
  COUNTRY_CODE,
  DESCRIPTION,
  ENABLED,
  FILES,
  HEIGHT,
  HS_CODE,
  INSTRUCTIONS,
  LENGTH,
  MATERIALS,
  META_DESCRIPTION,
  PAGE_TITLE,
  PERSONALIZATION,
  PHOTOS,
  PRICE,
  PRODUCTION_PARTNERS,
  PRODUCT_TYPE,
  PROFILE_ID,
  QUANTITY,
  REQUIRED,
  RETURN_POLICY,
  SECTION,
  SEO,
  SHIPPING,
  SKU,
  TAGS,
  TITLE,
  TRACK_QUANTITY,
  UNIT,
  URL_HANDLE,
  VARIATIONS,
  VENDOR,
  VIDEOS,
  WEIGHT,
  WIDTH,
} from '../../constants/attributes';

const { EMPTY_MAP } = DEFAULTS;

function about({ preview }) {
  return !preview.has('about');
}

function barcode({ channel, operation, preview }) {
  return (
    !!preview.get(ERROR) ||
    operation === OPERATIONS[channel].BARCODE.FIND_AND_REPLACE
      ? !preview.getIn([BARCODE, FIND]) || !preview.getIn([BARCODE, REPLACE])
      : !preview.has(BARCODE)
  );
}

function description({ channel, operation, preview }) {
  switch (operation) {
    case OPERATIONS[channel].DESCRIPTION.AI_GENERATED: {
      return (
        getSize(preview.getIn([DESCRIPTION, 'processed'])) === 0 ||
        getSize(preview.getIn([DESCRIPTION, 'processing'])) > 0
      );
    }

    case OPERATIONS[channel].DESCRIPTION.FIND_AND_REPLACE: {
      return !preview.getIn([DESCRIPTION, FIND]) || !preview.getIn([DESCRIPTION, REPLACE]);
    }

    default: {
      return !preview.has(DESCRIPTION);
    }
  }
}

function category({ preview }) {
  return !preview.get(CATEGORY);
}

function collections({ preview }) {
  return !getSize(preview.get(COLLECTIONS));
}

function countryCode({ preview }) {
  return !preview.getIn([SHIPPING, COUNTRY_CODE]);
}

function files({ preview }) {
  return !preview.get(FILES);
}

function hsCode({ preview }) {
  return !preview.getIn([SHIPPING, HS_CODE]);
}

function materials({ preview }) {
  return (
    !!preview.get(ERROR) ||
    !preview.get(MATERIALS)
  );
}

function personalization({ channel, preview }) {
  return (
    !!preview.getIn([PERSONALIZATION, CHAR_COUNT_MAX, ERROR]) ||
    !!preview.getIn([PERSONALIZATION, INSTRUCTIONS, ERROR]) || (
      preview.getIn([PERSONALIZATION, 'operation']) === OPERATIONS[channel].PERSONALIZATION.INSTRUCTIONS_FIND_AND_REPLACE && (
        !preview.getIn([PERSONALIZATION, INSTRUCTIONS, FIND]) ||
        !preview.getIn([PERSONALIZATION, INSTRUCTIONS, REPLACE])
      )
    ) || (
      !getSize(preview.getIn([PERSONALIZATION, INSTRUCTIONS])) &&
      !getSize(preview.getIn([PERSONALIZATION, CHAR_COUNT_MAX])) &&
      !preview.hasIn([PERSONALIZATION, REQUIRED]) &&
      !preview.hasIn([PERSONALIZATION, ENABLED])
    )
  );
}

function photos({ channel, operation, preview }) {
  switch (operation) {
    case OPERATIONS[channel].PHOTOS.ALT_TEXT: {
      return !preview.getIn([PHOTOS, VALUE]);
    }

    case OPERATIONS[channel].PHOTOS.ALT_TEXT_AI_GENERATED: {
      return (
        getSize(preview.getIn([PHOTOS, 'processed'])) === 0 ||
        getSize(preview.getIn([PHOTOS, 'processing'])) > 0
      );
    }

    default: {
      return !preview.hasIn([PHOTOS, 'operation']);
    }
  }
}

function videos({ preview }) {
  return !preview.hasIn([VIDEOS, 'operation']);
}

function price({ preview }) {
  return (
    !!preview.get(ERROR) || (
      !preview.getIn([PRICE, VALUE, VALUE]) &&
      !preview.getIn([PRICE, VALUE, 'cents']) &&
      !preview.getIn([PRICE, 'conversion']) &&
      !preview.getIn([CAP, 'conversion']) &&
      !preview.hasIn([PRICE, CHARGE_TAX])
    )
  );
}

function productType({ preview }) {
  return !preview.get(PRODUCT_TYPE);
}

function productionPartners({ preview }) {
  return !preview.has(PRODUCTION_PARTNERS);
}

function quantity({ preview }) {
  return (
    !!preview.get(ERROR) || (
      !preview.hasIn([QUANTITY, VALUE]) &&
      !preview.hasIn([QUANTITY, CONTINUE_SELLING]) &&
      !preview.hasIn([QUANTITY, TRACK_QUANTITY])
    )
  );
}

function returnPolicies({ preview }) {
  return (
    !preview.hasIn([SHIPPING, RETURN_POLICY])
  );
}

function section({ preview }) {
  return !preview.get(SECTION);
}

function shippingDimensions({ preview }) {
  return (
    !!getSize(preview.get(ERROR)) || (
      !preview.hasIn([SHIPPING, HEIGHT]) &&
      !preview.hasIn([SHIPPING, LENGTH]) &&
      !preview.hasIn([SHIPPING, WIDTH])
    )
  );
}

function shippingProfile({ preview }) {
  return !preview.getIn([SHIPPING, PROFILE_ID]);
}

function shippingWeight({ preview }) {
  return (
    !!preview.get(ERROR) ||
    !preview.hasIn([SHIPPING, WEIGHT])
  );
}

function sku({ channel, operation, preview }) {
  return (
    !!preview.get(ERROR) ||
    operation === OPERATIONS[channel].SKU.FIND_AND_REPLACE
      ? !preview.getIn([SKU, FIND]) || !preview.getIn([SKU, REPLACE])
      : !preview.has(SKU)
  );
}

function tags({ channel, operation, preview }) {
  switch (operation) {
    case OPERATIONS[channel].TAGS.AI_GENERATED: {
      return (
        getSize(preview.getIn([TAGS, 'processed'])) === 0 ||
        getSize(preview.getIn([TAGS, 'processing'])) > 0
      );
    }

    default: {
      return (
        preview.has(ERROR) || (
          !preview.hasIn([TAGS, 'profile']) &&
          !getSize(preview.getIn([TAGS, TAGS]))
        )
      );
    }
  }
}

function taxonomyAttributes({ preview, selectedMenuItem }) {
  return !preview.get(selectedMenuItem);
}

function title({ channel, operation, preview }) {
  switch (operation) {
    case OPERATIONS[channel].TITLE.AI_GENERATED: {
      return (
        getSize(preview.getIn([TITLE, 'processed'])) === 0 ||
        getSize(preview.getIn([TITLE, 'processing'])) > 0
      );
    }

    case OPERATIONS[channel].TITLE.FIND_AND_REPLACE: {
      return !preview.getIn([TITLE, FIND]) || !preview.getIn([TITLE, REPLACE]);
    }

    default: {
      return !preview.has(TITLE);
    }
  }
}

function variations({ channel, preview }) {
  function isPreviewingVariations() {
    switch (channel) {
      case ETSY: {
        return preview.has(VARIATIONS) || preview.has(CATEGORY);
      }

      case SHOPIFY: {
        return preview.has(VARIATIONS);
      }

      default:
        return false;
    }
  }

  return (
    !isPreviewingVariations() ||
    reduceErrors(preview.getIn([VARIATIONS, 'errors']))
  );
}

function vendor({ preview }) {
  return !preview.get(VENDOR);
}

function weight({ preview }) {
  return (
    !!preview.get(ERROR) || (
      !preview.getIn([SHIPPING, WEIGHT]) &&
      !preview.getIn([SHIPPING, UNIT])
    )
  );
}

function seo({ attribute, findAndReplace, preview }) {
  return (
    findAndReplace
      ? !preview.getIn([SEO, attribute, FIND]) || !preview.getIn([SEO, attribute, REPLACE])
      : !preview.hasIn([SEO, attribute])
  );
}

export function isApplyDisabled(bulkEdit) {
  const channel = bulkEdit.get('channel');
  const preview = bulkEdit.get('preview', EMPTY_MAP);
  const products = bulkEdit.getIn(['products', 'ids']);
  const selectedMenuItem = bulkEdit.getIn(['menu', 'selected', 'item']);
  const operation = bulkEdit.getIn(['menu', 'operation']);

  if (
    !products ||
    bulkEdit.get('processing') ||
    bulkEdit.get('saving') ||
    !getSize(bulkEdit.getIn(['products', 'selected']))
  ) {
    return true;
  }

  switch (selectedMenuItem) {
    case MENU_ITEM.ABOUT: {
      return about({ preview });
    }

    case MENU_ITEM.BARCODE: {
      return barcode({ channel, operation, preview });
    }

    case MENU_ITEM.BODY_HTML:
    case MENU_ITEM.DESCRIPTION: {
      return description({ channel, operation, preview });
    }

    case MENU_ITEM.CAP:
    case MENU_ITEM.CPI:
    case MENU_ITEM.PRICE: {
      return price({ preview });
    }

    case MENU_ITEM.CATEGORY: {
      return category({ preview });
    }

    case MENU_ITEM.COLLECTIONS: {
      return collections({ preview });
    }

    case MENU_ITEM.COUNTRY_CODE: {
      return countryCode({ preview });
    }

    case MENU_ITEM.FILES: {
      return files({ preview });
    }

    case MENU_ITEM.HS_CODE: {
      return hsCode({ preview });
    }

    case MENU_ITEM.ITEM_SIZE: {
      return shippingDimensions({ preview });
    }

    case MENU_ITEM.ITEM_WEIGHT: {
      return shippingWeight({ preview });
    }

    case MENU_ITEM.MATERIALS: {
      return materials({ preview });
    }

    case MENU_ITEM.PHOTOS: {
      return photos({ channel, operation, preview });
    }

    case MENU_ITEM.VIDEOS: {
      return videos({ preview });
    }

    case MENU_ITEM.PRODUCT_TYPE: {
      return productType({ preview });
    }

    case MENU_ITEM.PRODUCTION_PARTNER: {
      return productionPartners({ preview });
    }

    case MENU_ITEM.QUANTITY: {
      return quantity({ preview });
    }

    case MENU_ITEM.RETURN_POLICY: {
      return returnPolicies({ preview });
    }

    case MENU_ITEM.SECTION: {
      return section({ preview });
    }

    case MENU_ITEM.SHIPPING_PROFILE: {
      return shippingProfile({ preview });
    }

    case MENU_ITEM.SKU: {
      return sku({ channel, operation, preview });
    }

    case MENU_ITEM.TAGS: {
      return tags({ channel, operation, preview });
    }

    case MENU_ITEM.TITLE: {
      return title({ channel, operation, preview });
    }

    case MENU_ITEM.VARIATIONS: {
      return variations({ channel, preview });
    }

    case MENU_ITEM.VENDOR: {
      return vendor({ preview });
    }

    case MENU_ITEM.WEIGHT: {
      return weight({ preview });
    }

    case MENU_ITEM.PERSONALIZATION: {
      return personalization({ channel, preview });
    }

    case MENU_ITEM.PAGE_TITLE: {
      return seo({
        attribute: PAGE_TITLE,
        findAndReplace: operation === OPERATIONS[SHOPIFY].PAGE_TITLE.FIND_AND_REPLACE,
        preview,
      });
    }

    case MENU_ITEM.META_DESCRIPTION: {
      return seo({
        attribute: META_DESCRIPTION,
        findAndReplace: operation === OPERATIONS[SHOPIFY].META_DESCRIPTION.FIND_AND_REPLACE,
        preview,
      });
    }

    case MENU_ITEM.URL_HANDLE: {
      return seo({
        attribute: URL_HANDLE,
        findAndReplace: operation === OPERATIONS[SHOPIFY].URL_HANDLE.FIND_AND_REPLACE,
        preview,
      });
    }

    default: {
      if (bulkEdit.getIn(['menu', 'items', selectedMenuItem, 'isTaxonomy'])) {
        return taxonomyAttributes({ preview, selectedMenuItem });
      }

      return false;
    }
  }
}
