export function getFromState(...args) {
  return function mapState(state) {
    const result = {};

    for (const key of args) {
      if (typeof key === 'string') {
        result[key] = state.get(key);
      } else if (Array.isArray(key)) {
        result[key[key.length - 1]] = state.getIn(key);
      }
    }

    return result;
  };
}
