import { Map } from 'immutable';

import { parseMaterialsFromInput } from '../../tags';
import { getMaterialsError } from '../../validations/materials';
import { clearErrors } from '../../errors';
import { keepOld } from '../../iterable/keepOld';

import { MAX_NUMBER_OF_MATERIALS } from '../../../constants/validations';
import { OPERATIONS } from '../../../constants/bulkEdit';
import { MATERIALS } from '../../../constants/attributes';
import { SECTIONS } from '../../../constants/product';
import { ETSY } from '../../../constants/channels';

const { DETAILS } = SECTIONS;

export default function materials({ actions, operation, product }) {
  const channel = product.get('channel');

  function validateAndApply(value) {
    return {
      actions,
      product: getMaterialsError({ channel, materials: value })
        ? product
        : clearErrors({
          errors: [MATERIALS],
          path: DETAILS,
          product: product.setIn([DETAILS, MATERIALS], Map({ value })),
        }),
    };
  }

  switch (operation.get('type')) {
    case OPERATIONS[channel].MATERIALS.ADD: {
      const productValue = product.getIn([DETAILS, MATERIALS, 'value']);

      if (productValue.size >= MAX_NUMBER_OF_MATERIALS[channel]) break;

      const operationValue = parseMaterialsFromInput({
        channel,
        materials: productValue,
        value: operation.get('value'),
      });

      return validateAndApply(productValue.mergeWith(keepOld, operationValue));
    }

    case OPERATIONS[ETSY].MATERIALS.DELETE: {
      const operationValue = parseMaterialsFromInput({ channel, value: operation.get('value') });
      const value = product.getIn([DETAILS, MATERIALS, 'value']).deleteAll(operationValue.keySeq());
      return validateAndApply(value);
    }

    default: {
      break;
    }
  }

  return { actions, product };
}

