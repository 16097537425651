
import ActionConstants, { Actions } from '../constants/actions';

const listingsActions = new Actions('Listings')
  .add('add_listing_placeholder')
  .add('bootstrap')
  .add('clean_up')
  .addAsync('copy_listings')
  .addAsync('delete_listings')
  .addAsync('get_filters')
  .addAsync('get_listings')
  .addAsync('get_statuses')
  .addAsync('publish_listings')
  .add('set_data')
  .add('set_filter')
  .add('set_modal')
  .add('set_order')
  .add('set_page')
  .add('set_status')
  .addAsync('toggle_listings')
  .add('toggle_sidebar_group')
  .add('toggle_state');

ActionConstants.add(listingsActions);

export default listingsActions.actionCreators();
