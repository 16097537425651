import { PROFILE } from './profiles';

export const EVENT = {
  BULK_EDIT: {
    SCHEDULED: 'Bulk edit scheduled',
    START: 'Bulk edit',
    SYNC: 'Bulk edit sync',
  },
  CANVA: {
    CONNECT: 'Canva connect',
    EDIT: 'Canva edit',
    IMAGE_UPLOAD: 'Canva image upload',
  },
  CSV: {
    EXPORT: 'CSV Export',
    IMPORT: 'CSV Import',
  },
  LIBRARY: {
    FOLDER: {
      CREATE: 'Library folder create',
      RENAME: 'Library folder rename',
      DELETE: 'Library folder delete',
    },
    IMAGE: {
      MOVE: 'Library image move',
      DELETE: 'Library image delete',
      BULK_DELETE: 'Library image bulk delete',
      DOWNLOAD: 'Library single download',
      BULK_DOWNLOAD: 'Library bulk download',
      UPLOAD: 'Library upload',
      EDIT: 'Library edit',
    },
  },
  LISTING: {
    BULK_COPY: 'Bulk copy',
    BULK_COPY_PUBLISH: 'Bulk copy publish',
    BULK_COPY_SAVE_AS_DRAFT: 'Bulk copy save as draft',
    COPY: 'Copy listing',
    CREATE: 'VC - listing create',
    CREATE_FROM_PROFILE: 'VC - listing create from profile',
    PUBLISH_COPY: 'Publish copy',
  },
  PHOTO: {
    EDIT: 'Photo edit',
    EDIT_ACTION: 'Photo edit - action',
  },
  PROFILE: {
    [PROFILE.LISTINGS]: {
      APPLY: 'VC - listing profile applied',
      CREATE: 'VC - listing profile create',
    },
    [PROFILE.TAGS]: {
      CONNECT: 'Tags profile connect',
      CREATE: 'Tags profile create',
    },
    [PROFILE.VARIATIONS]: {
      CONNECT: 'Variations profile connect',
      CREATE: 'Variations profile create',
    },
  },
  SHOP: {
    DOWNGRADE: 'Downgrade shop',
    REFRESH: 'Refresh shop',
    SUBSCRIBE: 'Subscribe shop',
    UNSUBSCRIBE: 'Unsubscribe shop',
    UPGRADE: 'Upgrade shop',
  },
  VIDEO: {
    ADD: 'Add video',
    REPLACE: 'Replace video',
    DELETE: 'Delete video',
  },
};
