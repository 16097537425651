import { List } from 'immutable';

import { setPhotosErrors } from '../../product/errors';
import { applyOperations } from '../../photoEditor/applyOperations';
import { getAltTextError } from '../../validations/photos';
import { moveListItem } from '../../iterable/moveListItem';

import { MAX_NUMBER_OF_PHOTOS } from '../../../constants/validations';
import { OPERATIONS } from '../../../constants/bulkEdit';
import { PHOTOS } from '../../../constants/attributes';
import { VALUE } from '../../../constants/product';
import ACTIONS from '../../../constants/actions';

import Actions from '../../../actions';

export default function photos({ actions, bulkPhotoEditor, operation, product }) {
  const channel = product.get('channel');
  const productId = product.get('productId');

  function findProduct(item) {
    return item === productId;
  }

  function deletePhotos(result, photo, index) {
    return operation.getIn([VALUE, index])
      ? result
      : result.push(photo);
  }

  function replacePhotos(photo, index) {
    return operation.getIn([VALUE, index]) || photo;
  }

  function validateAndApply(value) {
    return {
      actions,
      product: setPhotosErrors({
        channel,
        product: product.setIn([PHOTOS, VALUE], value),
      }),
    };
  }

  function updateActions(photoIndex) {
    const originalId = product.getIn([PHOTOS, VALUE, photoIndex, 'id']);
    const payload = {
      originalId,
      url: product.getIn([PHOTOS, VALUE, photoIndex, 'fullsize_url']),
    };

    function findAction(item) {
      return item.type === ACTIONS.BULKEDIT.REMOVE_BACKGROUND;
    }

    function findPayload(item) {
      return item.originalId === originalId;
    }

    if (!bulkPhotoEditor.hasIn([PHOTOS, originalId, 'fullsize_url'])) {
      const actionIndex = actions.findIndex(findAction);

      if (actionIndex === -1) {
        actions.push(
          Actions.BulkEdit.removeBackground([payload])
        );
      } else {
        const payloadIndex = actions[actionIndex].payload.findIndex(findPayload);

        if (payloadIndex === -1) {
          actions[actionIndex].payload.push(payload);
        }
      }
    }

    return actions;
  }

  switch (operation.get('type')) {
    case OPERATIONS[channel].PHOTOS.ADD: {
      const value = product
        .getIn([PHOTOS, VALUE])
        .concat(operation.get(VALUE))
        .slice(0, MAX_NUMBER_OF_PHOTOS[channel]);

      return validateAndApply(value);
    }

    case OPERATIONS[channel].PHOTOS.DELETE: {
      return validateAndApply(
        product.getIn([PHOTOS, VALUE]).reduce(deletePhotos, List())
      );
    }

    case OPERATIONS[channel].PHOTOS.EDIT: {
      const photoIndex = operation.get('order') - 1;
      const productIndex = operation.get('products').findIndex(findProduct);
      const photo = product.getIn([PHOTOS, VALUE, photoIndex]);

      if (operation.getIn(['imageIds', productIndex]) === photo.get('id')) {
        return {
          actions: updateActions(photoIndex),
          product: product.setIn([PHOTOS, VALUE, photoIndex],
            operation.get(VALUE).reduce(applyOperations, photo),
          ),
        };
      }

      break;
    }

    case OPERATIONS[channel].PHOTOS.REPLACE: {
      return validateAndApply(
        product.getIn([PHOTOS, VALUE]).map(replacePhotos)
      );
    }

    case OPERATIONS[channel].PHOTOS.SWAP: {
      const value = moveListItem(
        product.getIn([PHOTOS, VALUE]),
        operation.getIn([VALUE, 'sourceIdx']),
        operation.getIn([VALUE, 'targetIdx'])
      );

      return { actions, product: product.setIn([PHOTOS, VALUE], value) };
    }

    case OPERATIONS[channel].PHOTOS.UPDATE: {
      const index = operation.getIn([VALUE, 'index']);

      if (!product.getIn([PHOTOS, VALUE, index])) {
        return { actions, product };
      }

      return operation
        .getIn([VALUE, 'attributes'])
        .reduce(
          function reduceAttributes(result, value, key) {
            switch (key) {
              case 'alt_text': {
                if (!getAltTextError({ channel, value })) {
                  result.product = result.product.setIn([PHOTOS, VALUE, index, key], value);
                }

                break;
              }

              default: {
                result.product = result.product.setIn([PHOTOS, VALUE, index, key], value);
                break;
              }
            }

            return result;
          },
          { actions, product },
        );
    }

    default: {
      break;
    }
  }

  return { actions, product };
}
