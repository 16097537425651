/* eslint-disable no-console, no-alert */
import { sideEffect } from 'redux-side-effects';
import { fromJS, List, Map, Set } from 'immutable';

import { getActualDate, isAfter } from '../utils/time';
import { addThousandsSeparator } from '../utils/number';
import { goBack, navigateTo } from '../utils/navigation';
import api from '../utils/api';

import { ADMIN as initialState } from '../initialState';
import { SEPARATOR } from '../constants';
import { ROUTES } from '../constants/routes';
import ACTIONS from '../constants/actions';

import Reducers, { Reducer } from '../classes/Reducer';
import Actions from '../actions';

const DEFAULT_LAST_SYNC_TIMESTAMP = '1998-12-31T23:00:00.000Z';

function getErrorString(error) {
  const errorStrings = [error?.toString()];

  if (error?.response?.text?.length > 0) {
    try {
      const responseBody = JSON.parse(error.response.text);

      if (responseBody && responseBody.error) {
        errorStrings.push(responseBody.error);
      }
    } catch (e) {
      errorStrings.push('Response body could not be parsed');
    }
  }

  return errorStrings.join(SEPARATOR.NEW_LINE);
}

function* setUnauthorized(reduction) {
  yield sideEffect(() => {
    navigateTo(ROUTES.ROOT);
  });

  return reduction.set('admin', Map());
}

function* getUserInfo(reduction) {
  yield sideEffect((dispatch) => {
    api
      .get({ url: '/admin/userInfo', doNotLogout: true })
      .then(
        (response) => dispatch(Actions.Admin.getUserInfoSucceeded(response)),
        () => dispatch(Actions.Admin.getUserInfoFailed())
      );
  });

  return reduction;
}

function* getUserInfoSucceeded(reduction, response) {
  yield sideEffect(dispatch => dispatch(Actions.Admin.getImpersonation()));

  const state = initialState
    .set('authorized', true)
    .set('userInfo', fromJS(response));

  return reduction.set('admin', state);
}

function* getImpersonation(reduction) {
  yield sideEffect((dispatch) => {
    api
      .get({ url: '/admin/impersonation', doNotLogout: true })
      .then(
        (response) => dispatch(Actions.Admin.getImpersonationSucceeded(response)),
        () => dispatch(Actions.Admin.getImpersonationFailed())
      );
  });

  return reduction;
}

function* getImpersonationSucceeded(reduction, impersonation) {
  return reduction.setIn(['admin', 'impersonation'], fromJS(impersonation));
}

function* impersonateUser(reduction, userId) {
  yield sideEffect((dispatch) => {
    api
      .get({ url: `/admin/impersonation/impersonate/${userId}` })
      .then(
        () => dispatch(Actions.Admin.impersonateUserSucceeded()),
        (error) => dispatch(Actions.Admin.impersonateUserFailed(error))
      );
  });

  return reduction;
}

function* impersonateUserFailed(reduction, error) {
  yield sideEffect(() => {
    alert('Error: could not impersonate user');
  });

  console.error(error);
  return reduction;
}

function* stopImpersonating(reduction) {
  yield sideEffect((dispatch) => {
    api
      .get({ url: '/admin/impersonation/cancel' })
      .then(
        () => dispatch(Actions.Admin.getImpersonation()),
        () => dispatch(Actions.Admin.stopImpersonatingFailed())
      );
  });

  return reduction;
}

function* getShopCounts(reduction) {
  yield sideEffect((dispatch) => {
    api
      .get({ url: '/admin/shops/counts' })
      .then(
        (response) => dispatch(Actions.Admin.getShopCountsSucceeded(response)),
        () => dispatch(Actions.Admin.getShopCountsFailed())
      );
  });

  return reduction;
}

function* getShopCountsSucceeded(reduction, shopCounts) {
  return reduction.setIn(['admin', 'shopCounts'], fromJS(shopCounts));
}

function* searchShops(reduction, query) {
  if (!query) {
    return reduction
      .deleteIn(['admin', 'shopsLookup', 'error'])
      .setIn(['admin', 'shopsLookup', 'query'], '')
      .setIn(['admin', 'shopsLookup', 'loading'], false)
      .setIn(['admin', 'shopsLookup', 'result'], List());
  }

  yield sideEffect((dispatch) => {
    api
      .get({ url: '/admin/shops/search', params: { query }})
      .then(
        (response) => dispatch(Actions.Admin.searchShopsSucceeded(response)),
        (error) => dispatch(Actions.Admin.searchShopsFailed(error))
      );
  });

  return reduction
    .deleteIn(['admin', 'shopsLookup', 'error'])
    .setIn(['admin', 'shopsLookup', 'loading'], true)
    .setIn(['admin', 'shopsLookup', 'query'], query);
}

function* searchShopsSucceeded(reduction, searchResult) {
  return reduction
    .setIn(['admin', 'shopsLookup', 'loading'], false)
    .setIn(['admin', 'shopsLookup', 'result'], fromJS(searchResult));
}

function* searchShopsFailed(reduction, error) {
  return reduction
    .setIn(['admin', 'shopsLookup', 'loading'], false)
    .setIn(['admin', 'shopsLookup', 'error'], error);
}

function* searchUsers(reduction, query) {
  if (!query) {
    return reduction
      .deleteIn(['admin', 'usersLookup', 'error'])
      .setIn(['admin', 'usersLookup', 'query'], '')
      .setIn(['admin', 'usersLookup', 'loading'], false)
      .setIn(['admin', 'usersLookup', 'result'], List());
  }

  yield sideEffect((dispatch) => {
    api
      .get({ url: '/admin/users/search', params: { query }})
      .then(
        (response) => dispatch(Actions.Admin.searchUsersSucceeded(response)),
        (error) => dispatch(Actions.Admin.searchUsersFailed(error))
      );
  });

  return reduction
    .deleteIn(['admin', 'usersLookup', 'error'])
    .setIn(['admin', 'usersLookup', 'loading'], true)
    .setIn(['admin', 'usersLookup', 'query'], query);
}

function* searchUsersSucceeded(reduction, searchResult) {
  return reduction
    .setIn(['admin', 'usersLookup', 'loading'], false)
    .setIn(['admin', 'usersLookup', 'result'], fromJS(searchResult));
}

function* searchUsersFailed(reduction, error) {
  return reduction
    .setIn(['admin', 'usersLookup', 'loading'], false)
    .setIn(['admin', 'usersLookup', 'error'], error);
}

function* getShopDetails(reduction, { shopId, db }) {
  yield sideEffect((dispatch) => {
    Promise.all([
      api.get({ url: `/admin/shops/${shopId}`, params: { db, denorm: true }}),
      api.get({ url: `/admin/shops/${shopId}/owners`, params: { db }}),
      api.get({ url: `/admin/shops/${shopId}/products/search`, params: { db }}),
    ])
      .then(
        ([shop, owners, products]) => dispatch(Actions.Admin.getShopDetailsSucceeded({ shop, owners, products })),
        (error) => dispatch(Actions.Admin.getShopDetailsFailed(error))
      );
  });

  return reduction.setIn(['admin', 'shopDetails', 'loading'], true);
}

function* getShopDetailsSucceeded(reduction, { shop, owners, products }) {
  return reduction
    .setIn(['admin', 'shopDetails', 'loading'], false)
    .setIn(['admin', 'shopDetails', 'shop'], fromJS(shop))
    .setIn(['admin', 'shopDetails', 'owners'], fromJS(owners))
    .setIn(['admin', 'shopDetails', 'productsStateCounts'], fromJS(products.stateCounts));
}

function* getShopDetailsFailed(reduction, error) {
  return reduction
    .setIn(['admin', 'shopDetails', 'loading'], false)
    .setIn(['admin', 'shopDetails', 'error'], getErrorString(error));
}

function* getUserDetails(reduction, userId) {
  yield sideEffect((dispatch) => {
    Promise.all([
      api.get({ url: `/admin/users/${userId}` }),
      api.get({ url: `/admin/users/${userId}/shops` }),
    ])
      .then(
        ([user, shops]) => dispatch(Actions.Admin.getUserDetailsSucceeded({ user, shops })),
        (error) => dispatch(Actions.Admin.getUserDetailsFailed(error))
      );
  });

  return reduction.setIn(['admin', 'userDetails', 'loading'], true);
}

function* getUserDetailsSucceeded(reduction, { user, shops }) {
  return reduction
    .setIn(['admin', 'userDetails', 'loading'], false)
    .setIn(['admin', 'userDetails', 'shops'], List(shops.map(Map)))
    .setIn(['admin', 'userDetails', 'user'], Map(user));
}

function* getUserDetailsFailed(reduction, error) {
  return reduction
    .setIn(['admin', 'userDetails', 'loading'], false)
    .setIn(['admin', 'userDetails', 'error'], getErrorString(error));
}

function* clearUserDetails(reduction) {
  return reduction.setIn(['admin', 'userDetails'], initialState.get('userDetails'));
}

function* clearShopDetails(reduction) {
  return reduction
    .setIn(['admin', 'shopDetails'], initialState.get('shopDetails'))
    .setIn(['admin', 'reassignShop'], initialState.get('reassignShop'));
}

function* syncShop(reduction, { shopId, db }) {
  yield sideEffect((dispatch) => {
    api
      .get({ url: `/admin/shops/${shopId}/sync`, params: { db }})
      .then(
        () => dispatch(Actions.Admin.scheduleShopPoll({ shopId, db })),
        (error) => dispatch(Actions.Admin.syncShopFailed(error))
      );
  });

  return reduction
    .setIn(['admin', 'shopDetails', 'syncStart'], getActualDate().toISOString())
    .setIn(['admin', 'shopDetails', 'syncInProgress'], true);
}

function* syncShopFailed(reduction, response) {
  const message = response.status === 400
    ? 'Error: could not duplicate sync shop'
    : 'Error: could not sync shop';

  yield sideEffect(() => {
    alert(message);
  });

  return reduction.setIn(['admin', 'shopDetails', 'syncInProgress'], false);
}

function* refreshShop(reduction, { shopId, db }) {
  yield sideEffect((dispatch) => {
    api
      .get({ url: `/admin/shops/${shopId}/refresh`, params: { db, userAction: 'adminRefresh' }})
      .then(
        () => dispatch(Actions.Admin.scheduleShopPoll({ shopId, db })),
        (error) => dispatch(Actions.Admin.refreshShopFailed(error))
      );
  });

  return reduction
    .setIn(['admin', 'shopDetails', 'syncStart'], getActualDate().toISOString())
    .setIn(['admin', 'shopDetails', 'refreshInProgress'], true);
}

function* refreshShopFailed(reduction, response) {
  const message = response.status === 400
    ? 'Error: could not duplicate refresh shop'
    : 'Error: could not refresh shop';

  yield sideEffect(() => {
    alert(message);
  });

  return reduction.setIn(['admin', 'shopDetails', 'syncInProgress'], false);
}

function* deleteShop(reduction, { shopId, db }) {
  yield sideEffect(() => {
    api
      .delete({ url: `/admin/shops/${shopId}`, params: { db }})
      .then(
        () => goBack(ROUTES.ADMIN.ROOT),
        () => alert('Error: could not sync delete shop')
      );
  });

  return reduction;
}

function* scheduleShopPoll(reduction, { shopId, db }) {
  yield sideEffect((dispatch) => {
    function callback() {
      dispatch(Actions.Admin.pollShopDetails({ shopId, db }));
    }

    setTimeout(callback, 5000);
  });

  return reduction;
}

function* pollShopDetails(reduction, { shopId, db }) {
  yield sideEffect((dispatch) => {
    function callback(shop) {
      if (
        shop.last_sync_timestamp === DEFAULT_LAST_SYNC_TIMESTAMP ||
        isAfter(new Date(shop.last_sync_timestamp), new Date(reduction.getIn(['admin', 'shopDetails', 'syncStart'])))
      ) {
        dispatch(Actions.Admin.pollShopDetailsSucceeded(shop));
      } else {
        dispatch(Actions.Admin.scheduleShopPoll({ shopId, db }));
      }
    }

    api
      .get({ url: `/admin/shops/${shopId}`, params: { db }})
      .then(
        callback,
        () => dispatch(Actions.Admin.pollShopDetailsFailed())
      );
  });

  return reduction;
}

function* pollShopDetailsSucceeded(reduction, { id: shopId, db }) {
  yield sideEffect(dispatch => dispatch(Actions.Admin.getShopDetails({ shopId, db })));

  return reduction
    .deleteIn(['admin', 'shopDetails', 'syncStart'])
    .setIn(['admin', 'shopDetails', 'syncInProgress'], false)
    .setIn(['admin', 'shopDetails', 'refreshInProgress'], false);
}

function* pollShopDetailsFailed(reduction) {
  return reduction
    .deleteIn(['admin', 'shopDetails', 'syncStart'])
    .setIn(['admin', 'shopDetails', 'syncInProgress'], false)
    .setIn(['admin', 'shopDetails', 'refreshInProgress'], false);
}

function* reassignShopOpenModal(reduction) {
  const state = initialState
    .get('reassignShop')
    .set('modalShown', true);

  return reduction.setIn(['admin', 'reassignShop'], state);
}

function* reassignShopCloseModal(reduction) {
  const state = initialState.get('reassignShop');
  return reduction.setIn(['admin', 'reassignShop'], state);
}

function* searchUsersForReassign(reduction, query) {
  if (!query) {
    return reduction
      .deleteIn(['admin', 'reassignShop', 'error'])
      .setIn(['admin', 'reassignShop', 'query'], '')
      .setIn(['admin', 'reassignShop', 'loading'], false)
      .setIn(['admin', 'reassignShop', 'result'], List());
  }

  const db = reduction.getIn(['admin', 'shopDetails', 'shop', 'db']);

  yield sideEffect((dispatch) => {
    api
      .get({ url: '/admin/users/searchSingleDb', params: { db, query }})
      .then(
        (response) => dispatch(Actions.Admin.searchUsersForReassignSucceeded(response)),
        (error) => dispatch(Actions.Admin.searchUsersForReassignFailed(error))
      );
  });

  return reduction
    .deleteIn(['admin', 'reassignShop', 'error'])
    .setIn(['admin', 'reassignShop', 'loading'], true)
    .setIn(['admin', 'reassignShop', 'query'], query);
}

function* searchUsersForReassignSucceeded(reduction, searchResult) {
  const shop = reduction.getIn(['admin', 'shopDetails', 'shop']);
  const shopCompanyId = shop.getIn(['accountsById', shop.get('account_id'), 'company_id']);
  const result = searchResult.filter(user => user.company_id !== shopCompanyId);

  return reduction
    .setIn(['admin', 'reassignShop', 'loading'], false)
    .setIn(['admin', 'reassignShop', 'result'], fromJS(result));
}

function* searchUsersForReassignFailed(reduction, error) {
  return reduction
    .setIn(['admin', 'reassignShop', 'loading'], false)
    .setIn(['admin', 'reassignShop', 'error'], error);
}

function* reassignShop(reduction, userId) {
  const shop = reduction.getIn(['admin', 'shopDetails', 'shop']);
  const shopId = shop.get('id');
  const db = shop.get('db');

  yield sideEffect((dispatch) => {
    api
      .get({ url: `/admin/shops/${shopId}/reassign/${userId}`, params: { db }})
      .then(
        () => dispatch(Actions.Admin.reassignShopSucceeded({ shopId, db })),
        (error) => dispatch(Actions.Admin.reassignShopFailed(error))
      );
  });

  return reduction;
}

function* reassignShopSucceeded(reduction, { shopId, db }) {
  yield sideEffect((dispatch) => {
    dispatch(Actions.Admin.getShopDetails({ shopId, db }));
    dispatch(Actions.Admin.reassignShopCloseModal());
  });

  return reduction;
}

function* reassignShopFailed(reduction, error) {
  return reduction.setIn(['admin', 'reassignShop', 'error'], getErrorString(error));
}

function* getApplicants(reduction) {
  yield sideEffect((dispatch) => {
    api.affiliates.getApplicants()
      .then(
        (response) => dispatch(Actions.Admin.getApplicantsSucceeded(response)),
        (error) => dispatch(Actions.Admin.getApplicantsFailed(error))
      );
  });

  return reduction.setIn(['admin', 'applicantsLookup', 'loading'], true);
}

function* getApplicantsSucceeded(reduction, response) {
  function reduceResponse(result, item) {
    return result.push(Map({
      id: item.id,
      email: item.email,
      affiliateStatus: item.affiliate_status,
      affiliateSurvey: item.affiliate_survey,
    }));
  }

  return reduction
    .setIn(['admin', 'applicantsLookup', 'loading'], false)
    .setIn(['admin', 'applicantsLookup', 'result'], response.applicants.reduce(reduceResponse, List()));
}

function* getApplicantsFailed(reduction, error) {
  return reduction
    .setIn(['admin', 'applicantsLookup', 'loading'], false)
    .setIn(['admin', 'applicantsLookup', 'error'], error);
}

function* activateApplicant(reduction, userId) {
  yield sideEffect((dispatch) => {
    api.affiliates.activateApplicant(userId)
      .then(
        () => {
          alert('Affiliate activation successful');
          return dispatch(Actions.Admin.activateApplicantSucceeded(userId));
        },
        (error) => {
          alert('Affiliate activation failed');
          return dispatch(Actions.Admin.activateApplicantFailed({ error, userId }));
        }
      );
  });

  return reduction
    .updateIn(['admin', 'applicantsLookup', 'processing'], Set(), function update(items) {
      return items.push(userId);
    });
}

function* activateApplicantSucceeded(reduction, userId) {
  yield sideEffect((dispatch) => {
    dispatch(Actions.Admin.getApplicants());
  });

  return reduction
    .updateIn(['admin', 'applicantsLookup', 'processing'], Set(), function update(items) {
      return items.filter(item => item !== userId);
    });
}

function* activateApplicantFailed(reduction, { error, userId }) {
  yield sideEffect(() => {
    alert('Error: could not activate referral');
  });

  console.error(error);
  return reduction
    .updateIn(['admin', 'applicantsLookup', 'processing'], Set(), function update(items) {
      return items.filter(item => item !== userId);
    });
}

function* rejectApplicant(reduction, userId) {
  yield sideEffect((dispatch) => {
    api.affiliates.rejectApplicant(userId)
      .then(
        () => {
          alert('Affiliate rejection successful');
          return dispatch(Actions.Admin.rejectApplicantSucceeded(userId));
        },
        (error) => {
          alert('Affiliate rejection failed');
          return dispatch(Actions.Admin.rejectApplicantFailed({ error, userId }));
        }
      );
  });

  return reduction
    .updateIn(['admin', 'applicantsLookup', 'processing'], Set(), function update(items) {
      return items.push(userId);
    });
}

function* rejectApplicantSucceeded(reduction, userId) {
  yield sideEffect((dispatch) => {
    dispatch(Actions.Admin.getApplicants());
  });

  return reduction
    .updateIn(['admin', 'applicantsLookup', 'processing'], Set(), function update(items) {
      return items.filter(item => item !== userId);
    });
}

function* rejectApplicantFailed(reduction, { error, userId }) {
  yield sideEffect(() => {
    alert('Error: could not reject referral');
  });

  console.error(error);
  return reduction
    .updateIn(['admin', 'applicantsLookup', 'processing'], Set(), function update(items) {
      return items.filter(item => item !== userId);
    });
}

function* getPayouts(reduction) {
  yield sideEffect((dispatch) => {
    api.affiliates.getPayouts()
      .then(
        (response) => dispatch(Actions.Admin.getPayoutsSucceeded(response)),
        (error) => dispatch(Actions.Admin.getPayoutsFailed(error))
      );
  });

  return reduction.setIn(['admin', 'payoutsLookup', 'loading'], true);
}

function* getPayoutsSucceeded(reduction, response) {
  function reduceResponse(result, item) {
    return result.push(Map({
      id: item.id,
      userId: item.referrer_user_id,
      email: item.referrer_email,
      milestone: addThousandsSeparator(item.milestone),
      totalPayout: `$${addThousandsSeparator(item.total_payout_amount / 100, 2, true)}`,
    }));
  }

  return reduction
    .setIn(['admin', 'payoutsLookup', 'loading'], false)
    .setIn(['admin', 'payoutsLookup', 'result'], response.data.reduce(reduceResponse, List()));
}

function* getPayoutsFailed(reduction, error) {
  return reduction
    .setIn(['admin', 'payoutsLookup', 'loading'], false)
    .setIn(['admin', 'payoutsLookup', 'error'], error);
}

function* getPayoutsDetails(reduction, { payoutId }) {
  yield sideEffect((dispatch) => {
    api.affiliates.getPayoutsDetails(payoutId)
      .then(
        (response) => dispatch(Actions.Admin.getPayoutsDetailsSucceeded(response)),
        (error) => dispatch(Actions.Admin.getPayoutsDetailsFailed(error))
      );
  });

  return reduction.setIn(['admin', 'payoutsDetails', 'loading'], true);
}

function* getPayoutsDetailsSucceeded(reduction, response) {
  const item = response.data;
  const payout = Map({
    id: item.id,
    referrerId: item.referrer_user_id,
    referrerEmail: item.referrer_email,
    milestone: addThousandsSeparator(item.milestone),
    convertedAmount: addThousandsSeparator(item.converted_amount / 100, 2, true),
    bonus: addThousandsSeparator(item.bonus / 100, 2, true),
    payoutAmount: addThousandsSeparator(item.total_payout_amount / 100, 2, true),
    payTo: item.pay_to,
    paymentMethod: item.payment_method,
    status: item.status,
    referees: List(item.referees.map(referee => Map({
      id: referee.user_id,
      email: referee.email,
      amount: addThousandsSeparator(referee.amount / 100, 2, true),
      milestone: addThousandsSeparator(referee.milestone),
      subscribedAt: referee.subscribed_at,
      shopId: referee.shop_id,
      db: referee.db,
    }))),
  });

  return reduction
    .setIn(['admin', 'payoutsDetails', 'loading'], false)
    .setIn(['admin', 'payoutsDetails', 'payout'], payout);
}

function* getPayoutsDetailsFailed(reduction, error) {
  return reduction
    .setIn(['admin', 'payoutsDetails', 'loading'], false)
    .setIn(['admin', 'payoutsDetails', 'error'], error);
}

function* clearPayoutsDetails(reduction) {
  return reduction.setIn(['admin', 'payoutsDetails'], initialState.get('payoutsDetails'));
}

function* markPayoutAsPaid(reduction, { payoutId }) {
  yield sideEffect((dispatch) => {
    api.affiliates.markPayoutAsPaid(payoutId)
      .then(
        () => dispatch(Actions.Admin.markPayoutAsPaidSucceeded(payoutId)),
        (error) => dispatch(Actions.Admin.markPayoutAsPaidFailed(error))
      );
  });

  return reduction.setIn(['admin', 'payoutsDetails', 'inProgress'], true);
}

function* markPayoutAsPaidSucceeded(reduction, payoutId) {
  yield sideEffect(dispatch => {
    dispatch(Actions.Admin.getPayoutsDetails({ payoutId }));
  });
  return reduction
    .setIn(['admin', 'payoutsDetails', 'inProgress'], false);
}

function* markPayoutAsPaidFailed(reduction, error) {
  return reduction
    .setIn(['admin', 'payoutsDetails', 'inProgress'], false)
    .setIn(['admin', 'payoutsDetails', 'error'], error);
}

function* getReferrals(reduction) {
  yield sideEffect((dispatch) => {
    api.affiliates.getReferrals()
      .then(
        (response) => dispatch(Actions.Admin.getReferralsSucceeded(response)),
        (error) => dispatch(Actions.Admin.getReferralsFailed(error))
      );
  });

  return reduction.setIn(['admin', 'referralsLookup', 'loading'], true);
}

function* getReferralsSucceeded(reduction, response) {
  function reduceResponse(result, item) {
    return result.push(Map({
      userId: item.referrer_user_id,
      email: item.email,
      refereesCount: item.refereescount,
    }));
  }

  return reduction
    .setIn(['admin', 'referralsLookup', 'loading'], false)
    .setIn(['admin', 'referralsLookup', 'result'], response.reduce(reduceResponse, List()));
}

function* getReferralsFailed(reduction, error) {
  return reduction
    .setIn(['admin', 'referralsLookup', 'loading'], false)
    .setIn(['admin', 'referralsLookup', 'error'], error);
}

function* getReferralsDetails(reduction, { referralId }) {
  yield sideEffect((dispatch) => {
    api.affiliates.getReferralsDetails(referralId)
      .then(
        (response) => dispatch(Actions.Admin.getReferralsDetailsSucceeded({ response, referralId } )),
        (error) => dispatch(Actions.Admin.getReferralsDetailsFailed(error))
      );
  });

  return reduction.setIn(['admin', 'referralsDetails', 'loading'], true);
}

function* getReferralsDetailsSucceeded(reduction, { response, referralId }) {
  const count = response.count;
  const details = response.details;

  function ReduceDetails(result, item) {
    return result.add(Map({
      id: item.id,
      userId: item.refereeUserId,
      email: item.refereeEmail,
      convertedAt: item.convertedAt,
      createdAt: item.createdAt,
      channelId: item.channel_id,
    }));
  }

  return reduction
    .setIn(['admin', 'referralsDetails', 'loading'], false)
    .setIn(['admin', 'referralsDetails', 'referral'], Map({
      count,
      referralId,
      details: details.reduce(ReduceDetails, Set()),
    }));
}

function* getReferralsDetailsFailed(reduction, error) {
  return reduction
    .setIn(['admin', 'referralsDetails', 'loading'], false)
    .setIn(['admin', 'referralsDetails', 'error'], error);
}

function* clearReferralsDetails(reduction) {
  return reduction.setIn(['admin', 'referralsDetails'], initialState.get('referralsDetails'));
}

Reducers.add(
  new Reducer('Admin')
    .add(ACTIONS.ADMIN.GET_USER_INFO, getUserInfo)
    .add(ACTIONS.ADMIN.GET_USER_INFO_SUCCEEDED, getUserInfoSucceeded)
    .add(ACTIONS.ADMIN.GET_USER_INFO_FAILED, setUnauthorized)
    .add(ACTIONS.ADMIN.GET_IMPERSONATION, getImpersonation)
    .add(ACTIONS.ADMIN.GET_IMPERSONATION_SUCCEEDED, getImpersonationSucceeded)
    .add(ACTIONS.ADMIN.GET_IMPERSONATION_FAILED, setUnauthorized)
    .add(ACTIONS.ADMIN.STOP_IMPERSONATING, stopImpersonating)
    .add(ACTIONS.ADMIN.STOP_IMPERSONATING_FAILED, setUnauthorized)
    .add(ACTIONS.ADMIN.GET_SHOP_COUNTS, getShopCounts)
    .add(ACTIONS.ADMIN.GET_SHOP_COUNTS_SUCCEEDED, getShopCountsSucceeded)
    .add(ACTIONS.ADMIN.GET_SHOP_COUNTS_FAILED, setUnauthorized)
    .add(ACTIONS.ADMIN.SEARCH_SHOPS, searchShops)
    .add(ACTIONS.ADMIN.SEARCH_SHOPS_SUCCEEDED, searchShopsSucceeded)
    .add(ACTIONS.ADMIN.SEARCH_SHOPS_FAILED, searchShopsFailed)
    .add(ACTIONS.ADMIN.SEARCH_USERS, searchUsers)
    .add(ACTIONS.ADMIN.SEARCH_USERS_SUCCEEDED, searchUsersSucceeded)
    .add(ACTIONS.ADMIN.SEARCH_USERS_FAILED, searchUsersFailed)
    .add(ACTIONS.ADMIN.IMPERSONATE_USER, impersonateUser)
    .add(ACTIONS.ADMIN.IMPERSONATE_USER_FAILED, impersonateUserFailed)
    .add(ACTIONS.ADMIN.IMPERSONATE_USER_SUCCEEDED, getImpersonation)
    .add(ACTIONS.ADMIN.GET_SHOP_DETAILS, getShopDetails)
    .add(ACTIONS.ADMIN.GET_SHOP_DETAILS_SUCCEEDED, getShopDetailsSucceeded)
    .add(ACTIONS.ADMIN.GET_SHOP_DETAILS_FAILED, getShopDetailsFailed)
    .add(ACTIONS.ADMIN.GET_USER_DETAILS, getUserDetails)
    .add(ACTIONS.ADMIN.GET_USER_DETAILS_SUCCEEDED, getUserDetailsSucceeded)
    .add(ACTIONS.ADMIN.GET_USER_DETAILS_FAILED, getUserDetailsFailed)
    .add(ACTIONS.ADMIN.CLEAR_SHOP_DETAILS, clearShopDetails)
    .add(ACTIONS.ADMIN.CLEAR_USER_DETAILS, clearUserDetails)
    .add(ACTIONS.ADMIN.SYNC_SHOP, syncShop)
    .add(ACTIONS.ADMIN.SYNC_SHOP_FAILED, syncShopFailed)
    .add(ACTIONS.ADMIN.REFRESH_SHOP, refreshShop)
    .add(ACTIONS.ADMIN.REFRESH_SHOP_FAILED, refreshShopFailed)
    .add(ACTIONS.ADMIN.DELETE_SHOP, deleteShop)
    .add(ACTIONS.ADMIN.SCHEDULE_SHOP_POLL, scheduleShopPoll)
    .add(ACTIONS.ADMIN.POLL_SHOP_DETAILS, pollShopDetails)
    .add(ACTIONS.ADMIN.POLL_SHOP_DETAILS_SUCCEEDED, pollShopDetailsSucceeded)
    .add(ACTIONS.ADMIN.POLL_SHOP_DETAILS_FAILED, pollShopDetailsFailed)
    .add(ACTIONS.ADMIN.REASSIGN_SHOP_OPEN_MODAL, reassignShopOpenModal)
    .add(ACTIONS.ADMIN.REASSIGN_SHOP_CLOSE_MODAL, reassignShopCloseModal)
    .add(ACTIONS.ADMIN.SEARCH_USERS_FOR_REASSIGN, searchUsersForReassign)
    .add(ACTIONS.ADMIN.SEARCH_USERS_FOR_REASSIGN_SUCCEEDED, searchUsersForReassignSucceeded)
    .add(ACTIONS.ADMIN.SEARCH_USERS_FOR_REASSIGN_FAILED, searchUsersForReassignFailed)
    .add(ACTIONS.ADMIN.REASSIGN_SHOP, reassignShop)
    .add(ACTIONS.ADMIN.REASSIGN_SHOP_SUCCEEDED, reassignShopSucceeded)
    .add(ACTIONS.ADMIN.REASSIGN_SHOP_FAILED, reassignShopFailed)
    .add(ACTIONS.ADMIN.GET_APPLICANTS, getApplicants)
    .add(ACTIONS.ADMIN.GET_APPLICANTS_SUCCEEDED, getApplicantsSucceeded)
    .add(ACTIONS.ADMIN.GET_APPLICANTS_FAILED, getApplicantsFailed)
    .add(ACTIONS.ADMIN.ACTIVATE_APPLICANT, activateApplicant)
    .add(ACTIONS.ADMIN.ACTIVATE_APPLICANT_SUCCEEDED, activateApplicantSucceeded)
    .add(ACTIONS.ADMIN.ACTIVATE_APPLICANT_FAILED, activateApplicantFailed)
    .add(ACTIONS.ADMIN.REJECT_APPLICANT, rejectApplicant)
    .add(ACTIONS.ADMIN.REJECT_APPLICANT_SUCCEEDED, rejectApplicantSucceeded)
    .add(ACTIONS.ADMIN.REJECT_APPLICANT_FAILED, rejectApplicantFailed)
    .add(ACTIONS.ADMIN.GET_PAYOUTS, getPayouts)
    .add(ACTIONS.ADMIN.GET_PAYOUTS_SUCCEEDED, getPayoutsSucceeded)
    .add(ACTIONS.ADMIN.GET_PAYOUTS_FAILED, getPayoutsFailed)
    .add(ACTIONS.ADMIN.GET_PAYOUTS_DETAILS, getPayoutsDetails)
    .add(ACTIONS.ADMIN.GET_PAYOUTS_DETAILS_SUCCEEDED, getPayoutsDetailsSucceeded)
    .add(ACTIONS.ADMIN.GET_PAYOUTS_DETAILS_FAILED, getPayoutsDetailsFailed)
    .add(ACTIONS.ADMIN.CLEAR_PAYOUTS_DETAILS, clearPayoutsDetails)
    .add(ACTIONS.ADMIN.MARK_PAYOUT_AS_PAID, markPayoutAsPaid)
    .add(ACTIONS.ADMIN.MARK_PAYOUT_AS_PAID_SUCCEEDED, markPayoutAsPaidSucceeded)
    .add(ACTIONS.ADMIN.MARK_PAYOUT_AS_PAID_FAILED, markPayoutAsPaidFailed)
    .add(ACTIONS.ADMIN.GET_REFERRALS, getReferrals)
    .add(ACTIONS.ADMIN.GET_REFERRALS_SUCCEEDED, getReferralsSucceeded)
    .add(ACTIONS.ADMIN.GET_REFERRALS_FAILED, getReferralsFailed)
    .add(ACTIONS.ADMIN.GET_REFERRALS_DETAILS, getReferralsDetails)
    .add(ACTIONS.ADMIN.GET_REFERRALS_DETAILS_SUCCEEDED, getReferralsDetailsSucceeded)
    .add(ACTIONS.ADMIN.GET_REFERRALS_DETAILS_FAILED, getReferralsDetailsFailed)
    .add(ACTIONS.ADMIN.CLEAR_REFERRALS_DETAILS, clearReferralsDetails),
);
