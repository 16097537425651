import { areShippingDimensionsOptional } from '../../product/checkAttribute';
import { getError, getNumberValue } from '..';
import { validateShipping } from '../../validations/shipping';
import { clearErrors } from '../../errors';
import { getSize } from '../../iterable/getSize';

import { SECTIONS, SHIPPING_ERRORS } from '../../../constants/product';
import { OPERATIONS } from '../../../constants/bulkEdit';
import { ETSY } from '../../../constants/channels';
import {
  DIGITAL,
  HEIGHT,
  LENGTH,
  PROFILE_ID,
  PROFILE_TYPE,
  SHIPPING,
  WEIGHT,
  WIDTH,
} from '../../../constants/attributes';

const { DETAILS } = SECTIONS;

function getAttributeByOperation(operationType) {
  if (/^itemHeight/.test(operationType)) {
    return HEIGHT;
  }

  if (/^itemLength/.test(operationType)) {
    return LENGTH;
  }

  if (/^itemWeight/.test(operationType)) {
    return WEIGHT;
  }

  if (/^itemWidth/.test(operationType)) {
    return WIDTH;
  }

  return undefined;
}

export default function shipping({ actions, operation, product: source, shopData }) {
  let product = source;
  const channel = product.get('channel');
  const operationType = operation.get('type');

  switch (operationType) {
    case OPERATIONS[ETSY].SHIPPING_PROFILE: {
      if (product.getIn([DETAILS, DIGITAL, 'value'])) break;

      const value = operation.get('value');
      const productValue = product.getIn([SHIPPING, PROFILE_ID, 'value']);

      if (value === productValue) break;

      const profileType = shopData.getIn(['shippingProfiles', 'byId', value, 'profileType']);
      const updatedShipping = product
        .get(SHIPPING)
        .setIn([PROFILE_ID, 'value'], value)
        .setIn([PROFILE_TYPE, 'value'], profileType);

      const errors = validateShipping({ channel, shipping: updatedShipping });

      if (!getSize(errors)) {
        product = clearErrors({
          errors: SHIPPING_ERRORS,
          path: SHIPPING,
          product: product.set(SHIPPING, updatedShipping),
        });
      }

      break;
    }

    case OPERATIONS[ETSY].HEIGHT.DECREASE_BY:
    case OPERATIONS[ETSY].HEIGHT.INCREASE_BY:
    case OPERATIONS[ETSY].HEIGHT.SET:
    case OPERATIONS[ETSY].LENGTH.DECREASE_BY:
    case OPERATIONS[ETSY].LENGTH.INCREASE_BY:
    case OPERATIONS[ETSY].LENGTH.SET:
    case OPERATIONS[ETSY].WEIGHT.DECREASE_BY:
    case OPERATIONS[ETSY].WEIGHT.INCREASE_BY:
    case OPERATIONS[ETSY].WEIGHT.SET:
    case OPERATIONS[ETSY].WIDTH.DECREASE_BY:
    case OPERATIONS[ETSY].WIDTH.INCREASE_BY:
    case OPERATIONS[ETSY].WIDTH.SET: {
      if (product.getIn([DETAILS, DIGITAL, 'value'])) break;

      const type = getAttributeByOperation(operationType);
      const operationValue = operation.get('value') || 0;
      const productValue = product.getIn([SHIPPING, type, 'value']) || 0;
      const value = getNumberValue({ operationType, operationValue, productValue });

      if (value === productValue) break;

      const validatorProps = {
        allowEmpty: areShippingDimensionsOptional(product.get(SHIPPING)),
      };

      validatorProps.allowZero = validatorProps.allowEmpty;

      if (type === LENGTH) {
        validatorProps[HEIGHT] = product.getIn([SHIPPING, HEIGHT, 'value']);
        validatorProps[WIDTH] = product.getIn([SHIPPING, WIDTH, 'value']);
      } else {
        validatorProps[LENGTH] = product.getIn([SHIPPING, LENGTH, 'value']);
      }

      if (getError({ channel, type, value, ...validatorProps })) break;

      product = clearErrors({
        errors: [type],
        path: SHIPPING,
        product: product.setIn([SHIPPING, type, 'value'], value),
      });

      break;
    }

    default: {
      break;
    }
  }

  return { actions, product };
}
