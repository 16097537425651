import { clearErrors } from '../../errors';
import { escapeRegex } from '../../string';
import { getError } from '..';

import { CHAR_COUNT_MAX, ENABLED, INSTRUCTIONS, PERSONALIZATION, REQUIRED } from '../../../constants/attributes';
import { OPERATIONS } from '../../../constants/bulkEdit';
import { DEFAULTS } from '../../../constants';

export default function personalization({ actions, operation, product }) {
  const channel = product.get('channel');

  function validateAndApply({ type, value }) {
    return {
      actions,
      product: getError({ channel, type, value })
        ? product
        : clearErrors({
          errors: [type],
          path: PERSONALIZATION,
          product: product.set(PERSONALIZATION,
            product.get(PERSONALIZATION).setIn([type, 'value'], value)
          ),
        }),
    };
  }

  switch (operation.get('type')) {
    case OPERATIONS[channel].PERSONALIZATION.IS_PERSONALIZABLE_SET: {
      return {
        actions,
        product: product.setIn([PERSONALIZATION, ENABLED], operation.get('value')),
      };
    }

    case OPERATIONS[channel].PERSONALIZATION.IS_REQUIRED_SET: {
      return {
        actions,
        product: product.setIn([PERSONALIZATION, REQUIRED], operation.get('value')),
      };
    }

    case OPERATIONS[channel].PERSONALIZATION.CHAR_COUNT_MAX_SET: {
      return validateAndApply({
        type: CHAR_COUNT_MAX,
        value: operation.get('value'),
      });
    }

    case OPERATIONS[channel].PERSONALIZATION.INSTRUCTIONS_SET: {
      return validateAndApply({
        type: INSTRUCTIONS,
        value: operation.get('value') || DEFAULTS.EMPTY_STRING,
      });
    }

    case OPERATIONS[channel].PERSONALIZATION.INSTRUCTIONS_DELETE: {
      return validateAndApply({
        type: INSTRUCTIONS,
        value: product
          .getIn([PERSONALIZATION, INSTRUCTIONS, 'value'])
          .replace(new RegExp(escapeRegex(operation.get('value')), 'ig'), ''),
      });
    }

    case OPERATIONS[channel].PERSONALIZATION.INSTRUCTIONS_ADD_AFTER: {
      return validateAndApply({
        type: INSTRUCTIONS,
        value: `${product.getIn([PERSONALIZATION, INSTRUCTIONS, 'value'])}${operation.get('value')}`,
      });
    }

    case OPERATIONS[channel].PERSONALIZATION.INSTRUCTIONS_ADD_BEFORE: {
      return validateAndApply({
        type: INSTRUCTIONS,
        value: `${operation.get('value')}${product.getIn([PERSONALIZATION, INSTRUCTIONS, 'value'])}`,
      });
    }

    case OPERATIONS[channel].PERSONALIZATION.INSTRUCTIONS_FIND_AND_REPLACE: {
      return validateAndApply({
        type: INSTRUCTIONS,
        value: product
          .getIn([PERSONALIZATION, INSTRUCTIONS, 'value'])
          .replace(
            new RegExp(escapeRegex(operation.getIn(['value', 'find'])), 'ig'),
            operation.getIn(['value', 'replace'])
          ),
      });
    }

    default: {
      return { actions, product };
    }
  }
}
