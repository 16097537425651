import request from './request';

import { ENDPOINT } from '../../constants/api';

export default {
  createDesign({ image, signal }) {
    return request.post({
      endpoint: ENDPOINT.WEB_V2,
      payload: { image_base64: image.split(',')[1] },
      signal,
      url: '/canva/designs',
    });
  },
  getDesigns({ params, signal }) {
    return request.get({
      endpoint: ENDPOINT.WEB_V2,
      params,
      signal,
      url: '/canva/designs',
    });
  },
  getDesignExportStatus({ jobId, signal }) {
    return request.get({
      endpoint: ENDPOINT.WEB_V2,
      signal,
      url: `/canva/designs/export/${jobId}`,
    });
  },
  exportDesign({ designId, signal }) {
    return request.post({
      endpoint: ENDPOINT.WEB_V2,
      payload: { design_id: designId },
      signal,
      url: '/canva/designs/export',
    });
  },
};
