import { List, Map } from 'immutable';

import { getActualDate, setMonth } from '../time';
import { shapeDate } from './listings';
import { increase } from '../math';
import { getSize } from '../iterable/getSize';

import { FILTER, LISTING } from '../../constants/listings';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { VELA } from '../../constants';

export function addPlaceholders({ placeholders, state, status }) {
  if (
    state.getIn(['pagination', 'currentPage']) > 0 ||
    getSize(state.getIn(['filters', 'selected']).delete(FILTER.PROFILE_TYPE))
  ) {
    return state;
  }

  function reduceNewProducts(result, product, productId) {
    function updateById(byId = Map()) {
      return byId.set(productId, product);
    }

    function updateIds(ids = List()) {
      return ids.unshift(productId);
    }

    return result
      .updateIn(['products', 'byId'], updateById)
      .updateIn(['products', 'ids'], updateIds)
      .updateIn(['statuses', 'counts', status], increase);
  }

  return placeholders.get('byId').reduce(reduceNewProducts, state);
}

export function filterPlaceholders({ shopId, state, status }) {
  function reducePlaceholderIds(result, productId, index) {
    return (
      !result.getIn(['byId', productId, 'isPlaceholderForNewProduct']) &&
      state.hasIn(['products', 'byId', productId])
    )
      ? result.deleteIn(['byId', productId]).deleteIn(['ids', index])
      : result;
  }

  return getSize(state.getIn(['products', 'ids']))
    ? state
      .getIn(['placeholders', shopId, status, 'ids'])
      .reduce(reducePlaceholderIds, state.getIn(['placeholders', shopId, status]))
    : state.getIn(['placeholders', shopId, status]);
}

export function getPlaceholder({ channel, product: source }) {
  const today = getActualDate();
  let listing = source
    .set('isProcessing', true)
    .delete(LISTING[VELA].IS_COMPLETE);

  switch (channel) {
    case ETSY: {
      listing = listing
        .set(LISTING[VELA].UPDATED_AT, shapeDate(today.getTime()))
        .set(LISTING[VELA].EXPIRES_AT, shapeDate(setMonth(today, today.getMonth() + 4).getTime()));
      break;
    }

    case SHOPIFY: {
      listing = listing.set(LISTING[VELA].PUBLISHED_AT, shapeDate(today.getTime()));
      break;
    }

    default: {
      break;
    }
  }

  return listing;
}
