import { Map } from 'immutable';

import { getVariationNameError } from './../validations/profile';
import { updateVariations } from './updateVariations';

import { CUSTOM_PROPERTY_IDS } from '../../constants/taxonomy';
import { ETSY, SHOPIFY } from '../../constants/channels';

export function deleteVariation({ product, profile, variationIndex }) {
  const channel = profile.get('channel');

  function reduceErrors(result, value, key) {
    return key < variationIndex
      ? result.set(key, value)
      : key > variationIndex
        ? result.set(key - 1, value)
        : result;
  }

  function updatePropertyId(leftVariation, leftVariationIndex) {
    return CUSTOM_PROPERTY_IDS.includes(leftVariation.get('property'))
      ? leftVariation.set('property', CUSTOM_PROPERTY_IDS[leftVariationIndex])
      : leftVariation;
  }

  let errors = profile.getIn(['errors', 'variations']).reduce(reduceErrors, Map());
  let variations = profile.get('variations').splice(variationIndex, 1);

  function updateNameErrors(result, leftVariation, leftVariationIndex) {
    const error = getVariationNameError({
      channel,
      variations,
      variationIndex: leftVariationIndex,
      name: leftVariation.get('formattedName'),
    });

    return error
      ? result.setIn([leftVariationIndex, 'name'], error)
      : result.deleteIn([leftVariationIndex, 'name']);
  }

  switch (channel) {
    case ETSY: {
      variations = variations.map(updatePropertyId);
      break;
    }

    case SHOPIFY: {
      errors = variations.reduce(updateNameErrors, errors);
      break;
    }

    default: {
      break;
    }
  }

  return updateVariations({
    profile: profile.setIn(['errors', 'variations'], errors),
    product,
    variations,
    validateOfferings: true,
    validateOptions: true,
  });
}
