import ActionConstants, { Actions } from '../constants/actions';

const billingConstants = new Actions('Billing')
  .add('clean_up')
  .addAsync('get_customer')
  .add('get_invoices')
  .add('get_shops')
  .addAsync('get_shop')
  .addAsync('manage_shop')
  .addAsync('set_invoices_tab')
  .add('toggle_payment_method_modal');

ActionConstants.add(billingConstants);

export default billingConstants.actionCreators();
