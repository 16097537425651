import XRegExp from 'xregexp';

export function startsWithAlphanumeric(value) {
  return XRegExp('^[\\p{L}\\p{N}]').test(value);
}

export function usedMoreThanAllowed({ patterns, number, value }) {
  function reducePatterns(invalid, pattern) {
    return invalid || XRegExp.match(value, XRegExp(pattern, 'g'), 'all').length > number;
  }

  return patterns.reduce(reducePatterns, false);
}
