import ActionConstants, { Actions } from '../constants/actions';

const affiliateConstants = new Actions('Affiliate')
  .add('bootstrap')
  .add('clean_up')
  .addAsync('get_history')
  .addAsync('get_link')
  .addAsync('get_payout')
  .addAsync('request_payout')
  .addAsync('send_apply')
  .add('set_data');

ActionConstants.add(affiliateConstants);

export default affiliateConstants.actionCreators();
