import FEATURE_FLAGS from '../constants/featureFlags';
import { ETSY, SHOPIFY } from '../constants/channels';
import { FEATURES } from '../constants/billing';

export function isWhitelisted({ item, whitelist }) {
  switch (whitelist) {
    case 'all': {
      return true;
    }

    case 'none': {
      return false;
    }

    default: {
      return whitelist.has(item);
    }
  }
}

export function isFeatureEnabled({ feature, ...params }) {
  switch (feature) {
    case FEATURES.BULK_PHOTO_EDITOR: {
      return (
        FEATURE_FLAGS.BULK_PHOTO_EDITOR_ENABLED &&
        !isFeatureEnabled({ feature: FEATURES.CANVA, ...params })
      );
    }

    case FEATURES.CANVA: {
      return (
        FEATURE_FLAGS.CANVA_ENABLED &&
        isWhitelisted({ item: params.userId, whitelist: FEATURE_FLAGS.CANVA_USERS })
      );
    }

    case FEATURES.CSV_EXPORT: {
      return (
        FEATURE_FLAGS.CSV_EXPORT_ENABLED &&
        isWhitelisted({ item: params.userId, whitelist: FEATURE_FLAGS.CSV_EXPORT_USERS })
      );
    }

    case FEATURES.CSV_IMPORT: {
      return (
        FEATURE_FLAGS.CSV_IMPORT_ENABLED &&
        isWhitelisted({ item: params.userId, whitelist: FEATURE_FLAGS.CSV_IMPORT_USERS })
      );
    }

    case FEATURES.CSV_OVERRIDE: {
      return (
        FEATURE_FLAGS.CSV_OVERRIDE_ENABLED &&
        isWhitelisted({ item: params.userId, whitelist: FEATURE_FLAGS.CSV_OVERRIDE_USERS })
      );
    }

    case FEATURES.EBAY_INTEGRATION: {
      return (
        !isFeatureEnabled({ feature: FEATURES.CANVA, ...params }) &&
        FEATURE_FLAGS.EBAY_INTEGRATION_ENABLED &&
        isWhitelisted({ item: params.userId, whitelist: FEATURE_FLAGS.EBAY_INTEGRATION_USERS })
      );
    }

    case FEATURES.FILES_TO_S3: {
      return (
        FEATURE_FLAGS.FILES_TO_S3_ENABLED &&
          isWhitelisted({ item: params.userId, whitelist: FEATURE_FLAGS.FILES_TO_S3_USERS })
      );
    }

    case FEATURES.SALES_PROFILES: {
      return (
        FEATURE_FLAGS.SALES_PROFILES_ENABLED &&
        isWhitelisted({ item: params.db, whitelist: FEATURE_FLAGS.SALES_PROFILES_SHARDS }) &&
        isWhitelisted({ item: params.shopId, whitelist: FEATURE_FLAGS.SALES_PROFILES_SHOPS })
      );
    }

    case FEATURES.SEO_URL_HANDLE: {
      return (
        FEATURE_FLAGS.SEO_URL_HANDLE_ENABLED &&
        isWhitelisted({ item: params.userId, whitelist: FEATURE_FLAGS.SEO_URL_HANDLE_USERS })
      );
    }

    case FEATURES.SHOPIFY_INVENTORY_POLICY: {
      return (
        FEATURE_FLAGS.SHOPIFY_INVENTORY_POLICY_ENABLED &&
        isWhitelisted({ item: params.userId, whitelist: FEATURE_FLAGS.SHOPIFY_INVENTORY_POLICY_USERS })
      );
    }

    case FEATURES.UI_UPDATES_AUGUST: {
      return (
        FEATURE_FLAGS.UI_UPDATES_AUGUST_ENABLED &&
        isWhitelisted({ item: params.userId, whitelist: FEATURE_FLAGS.UI_UPDATES_AUGUST_USERS })
      );
    }

    default: {
      return false;
    }
  }
}

export function isChannelFullySupported(channel) {
  switch (channel) {
    case ETSY:
    case SHOPIFY: {
      return true;
    }

    default: {
      return false;
    }
  }
}
