import XRegExp from 'xregexp';
import pluralize from 'pluralize';

import { tagsArrayFromString } from '../tags';
import { getSize } from '../iterable/getSize';

import { ERRORS, MAX_NUMBER_OF_TAGS, MAX_TAG_LENGTH } from '../../constants/validations';
import { ETSY, SHOPIFY } from '../../constants/channels';

export function getTagError({ channel, tag }) {
  function onlyAllowedCharacters() {
    switch (channel) {
      case ETSY: {
        if (!XRegExp('^[\\p{L}\\p{Nd}]').test(tag)) {
          return ERRORS.TAGS.ALPHANUMERIC_BEGIN;
        }

        if (!XRegExp(`^[\\p{L}\\p{Nd}- '™©®]*$`).test(tag)) {
          return ERRORS.TAGS.FORBIDDEN_CHARACTERS[channel];
        }

        return false;
      }

      case SHOPIFY: {
        return !XRegExp('[\\p{L}\\p{Nd}]').test(tag) && ERRORS.TAGS.ALPHANUMERIC_CONTAIN;
      }

      default: {
        return false;
      }
    }
  }

  return tag.length > MAX_TAG_LENGTH[channel]
    ? ERRORS.TAGS.LENGTH_EXCEEDED[channel]
    : onlyAllowedCharacters({ channel, tag });
}

export function getTagsError({ channel, tags }) {
  function reduceTags(result, tag) {
    return result || getTagError({ channel, tag });
  }

  const size = getSize(tags);

  if (MAX_NUMBER_OF_TAGS.hasOwnProperty(channel) && size > MAX_NUMBER_OF_TAGS[channel]) {
    return `${pluralize('tag', size - MAX_NUMBER_OF_TAGS[channel], true)} over limit`;
  }

  return tags.reduce(reduceTags, false);
}

export function getTagsInputError({ channel, value = '' }) {
  return getTagsError({ channel, tags: tagsArrayFromString(value) });
}
