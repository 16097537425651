import { sortCaseIgnore } from './array';
import { isTruthy } from './bool';
import { getSize } from './iterable/getSize';
import { keepOld } from './iterable/keepOld';
import { get } from './iterable/get';

import { CUSTOM_PROPERTY_IDS, COMMON_PROPERTY_IDS } from '../constants/taxonomy';
import { DEFAULTS, NONE } from '../constants';
import { PLACEHOLDERS } from '../constants/product';
import { CATEGORY } from '../constants/attributes';
import DATA from '../data';

export function getOptions(id) {
  if (!id || !DATA.TAXONOMY[id] || !DATA.TAXONOMY[id].fullPath) return [DATA.TAXONOMY.taxonomy.ids];

  // get current taxonomy
  const taxonomy = DATA.TAXONOMY[id];

  // are there children for current taxonomy?
  const options = [];

  // push options for each part of the path into options array
  function reduceFullPath(result, tid) {
    options.push(result.ids);
    return result[tid];
  }

  const remaining = taxonomy.fullPath.reduce(reduceFullPath, DATA.TAXONOMY.taxonomy);

  // if remainder has some options, append them as well
  if (remaining && remaining.ids && remaining.ids.length) {
    options.push(remaining.ids);
  }

  return options.map(function mapOptions(categories) {
    return categories.sort(function sortCategories(x, y) {
      return sortCaseIgnore(get([x, 'name'])(DATA.TAXONOMY), get([y, 'name'])(DATA.TAXONOMY));
    });
  });
}

export function getValues(id) {
  if (!id || !DATA.TAXONOMY[id] || !DATA.TAXONOMY[id].fullPath) return [undefined];
  return DATA.TAXONOMY[id].fullPath;
}

const optionFormatterCache = {};

export function getOptionFormatter(scaleId) {
  if (!scaleId) return keepOld;

  if (!optionFormatterCache[scaleId]) {
    const scaleData = DATA.SCALES_LOOKUP[scaleId];

    if (scaleData) {
      const { name } = scaleData;
      optionFormatterCache[scaleId] = function format(value = '') {
        return `${value} ${name}`;
      };
    } else {
      optionFormatterCache[scaleId] = keepOld;
    }
  }

  return optionFormatterCache[scaleId];
}

function getAvailablePropertyIds({ taxonomyId }) {
  if (!taxonomyId) return Object.keys(DATA.STANDARD_PROPERTIES);

  const taxonomySpecificData = get(taxonomyId)(DATA.TAXONOMY_SPECIFIC_PROPERTIES) || {};
  const [
    extraProperties = [],
    missingProperties = [],
  ] = get('extraProperties', 'missingProperties')(taxonomySpecificData);

  return COMMON_PROPERTY_IDS
    .filter(propertyId => !missingProperties.includes(propertyId))
    .concat(extraProperties);
}

function getAvailableScaleIds({ propertyId, taxonomyId }) {
  const taxonomySpecificData = get(taxonomyId)(DATA.TAXONOMY_SPECIFIC_PROPERTIES) || {};
  const overridePropertyData = get(['overrides', propertyId])(taxonomySpecificData) || {};
  const standardPropertyData = get(propertyId)(DATA.STANDARD_PROPERTIES) || {};
  const extraScaleIds = get('extraScales')(overridePropertyData) || [];
  const missingScaleIds = get('missingScales')(overridePropertyData) || [];
  const standardScaleIds = get('scaleIds')(standardPropertyData) || [];

  return standardScaleIds
    .filter((scaleId) => !missingScaleIds.includes(scaleId))
    .concat(extraScaleIds);
}

function getStandardOptionIdsForScale({ scaleId }) {
  return get([scaleId, 'suggestedOptionIds'])(DATA.SCALES_LOOKUP) || null;
}

function getAllPossibleOptions({ propertyId, scaleId = null }) {
  const propertyKey = String(propertyId);
  const scaleKey = scaleId ? String(scaleId) : 'none';
  return get([propertyKey, scaleKey])(DATA.SUGGESTED_OPTIONS_LOOKUP);
}

function expandOptions({ optionIds, propertyId, scaleId }) {
  const allPossibleOptions = getAllPossibleOptions({ propertyId, scaleId });

  if (!getSize(allPossibleOptions)) return [];

  function reduceOptionIds(result, optionId) {
    function findOption({ id }) {
      return id === optionId;
    }

    const option = allPossibleOptions.find(findOption);

    if (option) {
      result.push(option);
    }

    return result;
  }

  return optionIds.reduce(reduceOptionIds, []);
}

export function getUiState(config = {}, forVariations = true) {
  const propertiesKey = forVariations ? 'availableVariations' : 'availableAttributes';
  let result = {
    availableScales: [],
    availableOptions: [],
  };

  if (!config.taxonomyId) return result;

  const taxonomyId = String(config.taxonomyId);
  const taxonomySpecificData = get(taxonomyId)(DATA.TAXONOMY_SPECIFIC_PROPERTIES) || {};
  const availablePropertyIds = getAvailablePropertyIds({ taxonomyId });

  function getCustomDisplayName(propertyId) {
    return get(['overrides', propertyId, 'displayName'])(taxonomySpecificData) || false;
  }

  // now expand the property and scales
  const allAvailableProperties = availablePropertyIds
    .map((propertyId) => {
      const isCustomProperty = CUSTOM_PROPERTY_IDS.indexOf(propertyId) !== -1;

      if (isCustomProperty && config.propertyId !== propertyId) return null;

      const {
        attributes,
        displayName,
        isMultivalued,
        name,
        required,
        variations,
      } = get(propertyId)(DATA.STANDARD_PROPERTIES) || {};

      const property = {
        attributes,
        displayName: isCustomProperty
          ? config.displayName
          : getCustomDisplayName(propertyId) || displayName,
        id: propertyId,
        isMultivalued,
        name: isCustomProperty ? config.displayName : name,
        required,
        variations,
      };

      if (typeof get(['overrides', propertyId, 'required'])(taxonomySpecificData) === 'boolean') {
        property.required = get(['overrides', propertyId, 'required'])(taxonomySpecificData);
      }

      return property;
    })
    .filter(isTruthy)
    .sort(function sortAttributes(x, y) {
      return x.required !== y.required
        ? y.required - x.required
        : 0;
    });

  // filter for attributes or variations
  const filterKey = forVariations ? 'variations' : 'attributes';
  result = {
    ...result,
    [propertiesKey]: allAvailableProperties.filter(property => !!get(filterKey)(property)),
  };

  // if there's no propertyId chosen, we're done
  if (!config.propertyId) return result;

  // if the property is not supported for the taxonomy, we're done
  if (!result[propertiesKey].find(item => String(get('id')(item)) === String(config.propertyId))) {
    return result;
  }

  const availableScaleIds = getAvailableScaleIds({ propertyId: config.propertyId, taxonomyId });

  // expand to the full scale definitions. NOTE: Unlike property info, the names of scales are never overridden
  result = {
    ...result,
    availableScales: availableScaleIds.map(scaleId => get(scaleId)(DATA.SCALES_LOOKUP) || {}),
  };

  // Determine if the configuration is complete enough for options and if so, populate the options
  const readyForPropertyLevelOptions = !!(config.taxonomyId && config.propertyId && !result.availableScales?.length);
  const readyForScaleLevelOptions = !!(config.taxonomyId && config.propertyId && result.availableScales?.length > 0 && config.scaleId);
  let availableOptionIds = [];

  if (readyForPropertyLevelOptions) {
    availableOptionIds = (
      get(['overrides', config.propertyId, 'suggestedOptionIds'])(taxonomySpecificData) ||
      get([config.propertyId, 'suggestedOptionIds'])(DATA.STANDARD_PROPERTIES) ||
      []
    );
  } else if (readyForScaleLevelOptions) {
    availableOptionIds = (
      get(['overrides', config.propertyId, 'scales', config.scaleId, 'suggestedOptionIds'])(taxonomySpecificData) ||
      getStandardOptionIdsForScale({ scaleId: config.scaleId }) ||
      []
    );
  }

  if (readyForPropertyLevelOptions && !forVariations) {
    const selectedValueIds = (
      get(['overrides', config.propertyId, 'selectedValueIds'])(taxonomySpecificData) ||
      get([config.propertyId, 'selectedValueIds'])(DATA.STANDARD_PROPERTIES) ||
      []
    );

    result.required = typeof get(['overrides', config.propertyId, 'required'])(taxonomySpecificData) === 'boolean'
      ? get(['overrides', config.propertyId, 'required'])(taxonomySpecificData)
      : get([config.propertyId, 'required'])(DATA.STANDARD_PROPERTIES) || false;

    // NOTE: weird Etsy logic-- if an attributed is required and selected values are provided,
    // then the suggested option list is ignored and replaced with the selected values.  As an example
    // look at the Occasion attribute on taxonomy 1242 or 1243...
    if (result.required && selectedValueIds.length) {
      result.selectedOptions = selectedValueIds;
    }
  }

  return {
    ...result,
    readyForOptions: readyForPropertyLevelOptions || readyForScaleLevelOptions,
    availableOptions: expandOptions({
      optionIds: availableOptionIds,
      propertyId: config.propertyId,
      scaleId: config.scaleId,
    }),
  };
}

export function getAvailable({ category, property, scale }) {
  const {
    availableScales = [],
    availableOptions = [],
    availableVariations = [],
  } = getUiState({ taxonomyId: category, propertyId: property, scaleId: scale });

  function formatProperty({ displayName, id, name }) {
    return {
      id,
      name: displayName || name,
    };
  }

  return {
    availableScales: availableScales.map(formatProperty),
    availableOptions: availableOptions.map(formatProperty),
    availableVariations: availableVariations.map(formatProperty),
  };
}

export function getAttribute({ category = -1, property, scale = null, withNone = false }) {
  const result = getUiState({ taxonomyId: category, propertyId: property, scaleId: scale }, false);

  if (DATA.STANDARD_PROPERTIES.hasOwnProperty(property)) {
    const {
      name = '',
      displayName = '',
      required = false,
      attributes = true,
      variations = false,
      isMultivalued = false,
    } = DATA.STANDARD_PROPERTIES[property];

    result.attributes = attributes;
    result.displayName = displayName;
    result.isMultivalued = isMultivalued;
    result.propertyId = property;
    result.name = name;
    result.required = required;
    result.variations = variations;
    result.totalOptions = getSize(getAllPossibleOptions({ propertyId: property, scaleId: scale }));
  }

  if (
    withNone &&
    result.availableOptions.length &&
    result.availableOptions.length !== result.selectedOptions?.length
  ) {
    result.availableOptions.unshift({ id: -1, name: NONE });
  }

  return result;
}

export function getName(value) {
  const placeholder = PLACEHOLDERS[CATEGORY];

  switch (value) {
    case -1:
    case DEFAULTS.MINUS_ONE: {
      return NONE;
    }

    case 0:
    case DEFAULTS.ZERO: {
      return placeholder;
    }

    default: {
      return get([value, 'name'])(DATA.TAXONOMY) || placeholder;
    }
  }
}

export function getCategories(taxonomyId) {
  return DATA.TAXONOMY[taxonomyId]?.fullPath || [];
}

export function getCategoryIndex(taxonomyId) {
  return (DATA.TAXONOMY[taxonomyId]?.fullPath?.length || 1) - 1;
}

export function getCategoryAndIndex(callback) {
  return function changeCategory(currentCategory, currentIndex) {
    return function handler(newCategory) {
      const unset = newCategory === -1;
      const index = unset && currentIndex > 0
        ? currentIndex - 1
        : currentIndex;

      const category = unset
        ? currentIndex
          ? String(currentCategory)
          : DEFAULTS.ZERO
        : String(newCategory);

      callback({ category, index });
    };
  };
}

export function getTopTaxonomyId(taxonomyId) {
  return get([taxonomyId, 'fullPath', 0])(DATA.TAXONOMY) || 0;
}

export function findTaxonomyAttributeById({ options, id }) {
  function findOption(option) {
    return option.id === id;
  }

  return options.find(findOption);
}

export function hasSuggestedOptions({ suggestedOptionIds }) {
  return !!suggestedOptionIds?.length;
}

