import { List, Map } from 'immutable';

import { toCamelCase } from './string';
import { isObject } from './isObject';
import { reduce } from './iterable/reduce';

export function shapeSubscriptionsForApp({ subscriptions }) {
  function reduceSubscriptions(result, subscription, shopId) {
    return result.set(shopId, Map(subscription));
  }

  return reduce(subscriptions, Map(), reduceSubscriptions);
}

export function shapeUserDataForApp({ data, user }) {
  if (data.last_seen_shop) {
    data.last_seen_shop = {
      id: data.last_seen_shop.id,
      db: data.last_seen_shop.db,
    };
  }

  function reduceUserData(result, value, key) {
    function addValue(shapedValue) {
      return List.isList(result)
        ? result.push(shapedValue)
        : result.set(toCamelCase(key), shapedValue);
    }

    if (Array.isArray(value)) {
      return addValue(value.reduce(reduceUserData, List()));
    } else if (isObject(value)) {
      return addValue(reduce(value, Map(), reduceUserData));
    } else {
      return addValue(value);
    }
  }

  return reduce(data, user, reduceUserData);
}
