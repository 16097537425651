import request from './request';

import { ENDPOINT } from '../../constants/api';
import { PROFILE } from '../../constants/profiles';
import { SHOPIFY } from '../../constants/channels';

export default {
  apply({ channel, db, payload, products, profileId, shopId, type }) {
    switch (type) {
      case PROFILE.LISTINGS: {
        return request.post({
          endpoint: ENDPOINT.AUTH_V2,
          payload,
          url: `/listing/profiles/${profileId}/propagate`,
        });
      }

      case PROFILE.SALES: {
        return request.put({
          params: { db },
          payload: { product_ids: products },
          url: `/shops/${shopId}/profiles/sales/add/${profileId}`,
        });
      }

      case PROFILE.TAGS: {
        const operation = {
          products,
          tagsProfileId: profileId,
          type: 'tags.changeTo',
          value: payload.tags,
        };

        return request.put({
          params: { db },
          payload: [operation],
          url: `/shops/${shopId}/products`,
        });
      }

      case PROFILE.VARIATIONS: {
        const operation = {
          products,
          type: 'variationsInventory.changeTo',
          value: payload.value,
        };

        if (channel === SHOPIFY) {
          operation.templateId = payload.templateId;
          operation.shopifyMeta = payload.shopifyMeta;
        }

        return request.put({
          params: { db },
          payload: [operation],
          url: `/shops/${shopId}/products`,
        });
      }

      default: {
        return Promise.reject();
      }
    }
  },
  create({ db, payload, shopId, type }) {
    switch (type) {
      case PROFILE.LISTINGS: {
        return request.post({
          endpoint: ENDPOINT.AUTH,
          payload,
          url: '/profile/listing',
        });
      }

      case PROFILE.SALES: {
        delete payload.id;

        return request.post({
          params: { db },
          payload,
          url: `/shops/${shopId}/profiles/sales`,
        });
      }

      case PROFILE.TAGS: {
        delete payload.id;

        return request.post({
          params: { db },
          payload,
          url: `/shops/${shopId}/profiles/tags`,
        });
      }

      case PROFILE.VARIATIONS: {
        delete payload.id;

        return request.post({
          params: { db },
          payload,
          url: `/shops/${shopId}/templates`,
        });
      }

      default: {
        return Promise.reject();
      }
    }
  },
  disconnectListing({ db, productId, profileId, shopId, type }) {
    switch (type) {
      case PROFILE.LISTINGS: {
        return request.delete({
          endpoint: ENDPOINT.AUTH_V2,
          params: { db },
          url: `/listing/profiles/${profileId}/product/${productId}`,
        });
      }

      case PROFILE.SALES: {
        return request.put({
          params: { db },
          url: `/shops/${shopId}/profiles/sales/remove/${productId}`,
        });
      }

      case PROFILE.TAGS: {
        return request.put({
          params: { db },
          url: `/shops/${shopId}/profiles/remove/${productId}`,
        });
      }

      case PROFILE.VARIATIONS: {
        return request.put({
          params: { db },
          url: `/shops/${shopId}/templates/remove/${productId}`,
        });
      }

      default: {
        return Promise.reject();
      }
    }
  },
  get({ db, limit, offset, query, shopId, signal, type }) {
    const params = { db, limit, offset };

    switch (type) {
      case PROFILE.LISTINGS: {
        if (query) {
          params.name = query;
        }

        return request.get({
          endpoint: ENDPOINT.AUTH_V2,
          params,
          signal,
          url: `/listing/profiles/shops/${shopId}`,
        });
      }

      case PROFILE.SALES: {
        if (query) {
          params.profile_name = query;
        }

        return request.get({
          params,
          signal,
          url: `/shops/${shopId}/profiles/sales`,
        });
      }

      case PROFILE.TAGS: {
        if (query) {
          params.profile_name = query;
        }

        return request.get({
          params,
          signal,
          url: `/shops/${shopId}/profiles/tags`,
        });
      }

      case PROFILE.VARIATIONS: {
        if (query) {
          params.title = query;
        }

        return request.get({
          params,
          signal,
          url: `/shops/${shopId}/templates`,
        });
      }

      default: {
        return Promise.reject();
      }
    }
  },
  getListings({ db, profileId, shopId, signal, type }) {
    switch (type) {
      case PROFILE.LISTINGS: {
        return request.get({
          endpoint: ENDPOINT.AUTH_V2,
          params: { db },
          signal,
          url: `/listing/profiles/shops/${shopId}/${profileId}/products`,
        });
      }

      case PROFILE.SALES: {
        return request.get({
          params: { db },
          signal,
          url: `/shops/${shopId}/profiles/sales/linkedproducts/${profileId}`,
        });
      }

      case PROFILE.TAGS: {
        return request.get({
          params: { db },
          signal,
          url: `/shops/${shopId}/profiles/tags/linkedproducts/${profileId}`,
        });
      }

      case PROFILE.VARIATIONS: {
        return request.get({
          params: { db },
          signal,
          url: `/shops/${shopId}/linkedproducts/${profileId}`,
        });
      }

      default: {
        return Promise.reject();
      }
    }
  },
  getLookup({ type, ...rest }) {
    switch (type) {
      case PROFILE.SALES: {
        const { params, shopId } = rest;

        return request.get({
          params,
          url: `/shops/${shopId}/profiles/sales/lookup`,
        });
      }

      case PROFILE.TAGS: {
        const { params, shopId } = rest;

        return request.get({
          params,
          url: `/shops/${shopId}/profiles/tags/lookup`,
        });
      }

      case PROFILE.VARIATIONS: {
        const { params, shopId } = rest;

        return request.get({
          params,
          url: `/shops/${shopId}/lookuptemplates`,
        });
      }

      default: {
        return Promise.reject();
      }
    }
  },
  getProfile({ db, profileId, shopId, signal, type }) {
    switch (type) {
      case PROFILE.SALES: {
        return request.get({
          params: { db },
          signal,
          url: `/shops/${shopId}/profiles/sales/${profileId}`,
        });
      }

      case PROFILE.TAGS: {
        return request.get({
          params: { db },
          signal,
          url: `/shops/${shopId}/profiles/tags/${profileId}`,
        });
      }

      case PROFILE.VARIATIONS: {
        return request.get({
          params: { db },
          signal,
          url: `/shops/${shopId}/template/${profileId}`,
        });
      }

      default: {
        return Promise.reject();
      }
    }
  },
  remove({ db, profileId, shopId, type }) {
    switch (type) {
      case PROFILE.LISTINGS: {
        return request.delete({
          endpoint: ENDPOINT.AUTH,
          url: `/profile/listing/${profileId}`,
        });
      }

      case PROFILE.SALES: {
        return request.delete({
          params: { db },
          url: `/shops/${shopId}/profiles/sales/${profileId}`,
        });
      }

      case PROFILE.TAGS: {
        return request.delete({
          params: { db },
          url: `/shops/${shopId}/profiles/tags/${profileId}`,
        });
      }

      case PROFILE.VARIATIONS: {
        return request.delete({
          params: { db },
          url: `/shops/${shopId}/templates/${profileId}`,
        });
      }

      default: {
        return Promise.reject();
      }
    }
  },
  totals({ db, shopId, signal, type }) {
    switch (type) {
      case PROFILE.LISTINGS: {
        return request.get({
          endpoint: ENDPOINT.AUTH_V2,
          params: { db },
          signal,
          url: `/listing/profiles/shops/${shopId}/totals`,
        });
      }

      default: {
        return Promise.reject();
      }
    }
  },
  update({ db, payload, profileId, shopId, type }) {
    switch (type) {
      case PROFILE.LISTINGS: {
        return request.put({
          endpoint: ENDPOINT.AUTH,
          payload,
          url: `/profile/listing/${profileId}`,
        });
      }

      case PROFILE.SALES: {
        return request.put({
          params: { db },
          payload,
          url: `/shops/${shopId}/profiles/sales/${profileId}`,
        });
      }

      case PROFILE.TAGS: {
        return request.put({
          params: { db },
          payload,
          url: `/shops/${shopId}/profiles/tags/${profileId}`,
        });
      }

      case PROFILE.VARIATIONS: {
        return request.post({
          params: { db },
          payload,
          url: `/shops/${shopId}/templates`,
        });
      }

      default: {
        return Promise.reject();
      }
    }
  },
};
