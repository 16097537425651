import { escapeRegex } from '../../string';

import { URL_HANDLE, URL_NUMERATION, SEO } from '../../../constants/attributes';
import { FIND, OPERATIONS, REPLACE } from '../../../constants/bulkEdit';
import { SHOPIFY } from '../../../constants/channels';
import { VALUE } from '../../../constants/product';

export function seoUrl({ actions, operation, product }) {
  function setValueToProduct(value) {
    return {
      actions,
      product: product.setIn([SEO, URL_HANDLE, VALUE], value),
    };
  }

  switch (operation.get('type')) {
    case OPERATIONS[SHOPIFY].URL_HANDLE.ADD_AFTER: {
      const value = `${product.getIn([SEO, URL_HANDLE, VALUE])}${operation.get(VALUE)}`;
      return setValueToProduct(value);
    }

    case OPERATIONS[SHOPIFY].URL_HANDLE.ADD_BEFORE: {
      const value = `${operation.get(VALUE)}${product.getIn([SEO, URL_HANDLE, VALUE])}`;
      return setValueToProduct(value);
    }

    case OPERATIONS[SHOPIFY].URL_HANDLE.DELETE: {
      const pattern = new RegExp(escapeRegex(operation.get(VALUE)), 'ig');
      const value = product.getIn([SEO, URL_HANDLE, VALUE]).replace(pattern, '');
      return setValueToProduct(value);
    }

    case OPERATIONS[SHOPIFY].URL_HANDLE.FIND_AND_REPLACE: {
      const pattern = new RegExp(escapeRegex(operation.getIn([VALUE, FIND])), 'ig');
      const value = product
        .getIn([SEO, URL_HANDLE, VALUE])
        .replace(pattern, operation.getIn([VALUE, REPLACE]));

      return setValueToProduct(value);
    }

    case OPERATIONS[SHOPIFY].URL_HANDLE.SET: {
      const value = operation.get(VALUE);
      return setValueToProduct(value);
    }

    default: {
      return { actions, product };
    }
  }
}

export function seoUrlNumeration({ actions, operation, product }) {
  function setValueToProduct(value) {
    return {
      actions,
      product: product.setIn([SEO, URL_NUMERATION, VALUE], value),
    };
  }

  switch (operation.get('type')) {
    case OPERATIONS[SHOPIFY].URL_NUMERATION.SET: {
      const value = operation.get(VALUE);
      return setValueToProduct(value);
    }

    default: {
      return { actions, product };
    }
  }
}
