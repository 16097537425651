import { List, Map, Set } from 'immutable';

import { MAX_NUMBER_OF_FILES, MAX_NUMBER_OF_VIDEOS } from './validations';
import { DEFAULTS, NONE, VELA } from '.';
import { ETSY, SHOPIFY } from './channels';
import ATTRIBUTES from './attributes';

export const THUMBNAIL_SIZE = 128;
export const THUMBNAIL_GAP = 12;

export const NEW = 'new';

export const ERROR = 'error';

export const OVERSET = 'overset';

export const VALUE = 'value';

export const TAG = {
  DELETE: 'delete',
  ERROR,
  NEW,
  PRODUCT: 'product',
  READONLY: 'readonly',
};

export const ENDPOINTS = {
  CREATE: {
    [ETSY]: 'products/create',
    [SHOPIFY]: 'product',
    LINKED: 'mergeNewProducts',
  },
};

export const SECTIONS = {
  DESCRIPTION: ATTRIBUTES.DESCRIPTION,
  DETAILS: 'details',
  FILES: ATTRIBUTES.FILES,
  INVENTORY: 'inventory',
  PERSONALIZATION: ATTRIBUTES.PERSONALIZATION,
  PHOTOS: ATTRIBUTES.PHOTOS,
  VIDEOS: ATTRIBUTES.VIDEOS,
  PRICING: 'pricing',
  SEO: ATTRIBUTES.SEO,
  SHIPPING: ATTRIBUTES.SHIPPING,
  TAGS: ATTRIBUTES.TAGS,
  TITLE: ATTRIBUTES.TITLE,
  VARIATIONS: ATTRIBUTES.VARIATIONS,
};

export const FORMS = [
  SECTIONS.PHOTOS,
  SECTIONS.VIDEOS,
  SECTIONS.TITLE,
  SECTIONS.DESCRIPTION,
  SECTIONS.TAGS,
  SECTIONS.PRICING,
  SECTIONS.INVENTORY,
  SECTIONS.VARIATIONS,
  SECTIONS.DETAILS,
  SECTIONS.PERSONALIZATION,
  SECTIONS.SHIPPING,
  SECTIONS.FILES,
  SECTIONS.SEO,
];

export const FORMS_WITH_VELA_TAB = Set([
  SECTIONS.PHOTOS,
  SECTIONS.TITLE,
  SECTIONS.DESCRIPTION,
  SECTIONS.INVENTORY,
  SECTIONS.PRICING,
  SECTIONS.TAGS,
  SECTIONS.VARIATIONS,
]);

export const FORM_TITLE = {
  [SECTIONS.DESCRIPTION]: 'Description',
  [SECTIONS.DETAILS]: 'Listing details',
  [SECTIONS.FILES]: 'Digital files',
  [SECTIONS.INVENTORY]: 'Inventory',
  [SECTIONS.PERSONALIZATION]: 'Personalization',
  [SECTIONS.PHOTOS]: 'Photos',
  [SECTIONS.VIDEOS]: 'Video',
  [SECTIONS.PRICING]: 'Price',
  [SECTIONS.SHIPPING]: 'Shipping',
  [SECTIONS.TAGS]: 'Tags',
  [SECTIONS.TITLE]: 'Title',
  [SECTIONS.VARIATIONS]: 'Variations',
  [SECTIONS.SEO]: 'Search engine listing',
};

export const FORM_TITLE_BADGE = {
};

const COMMON_OPERATIONS = {
  STATUS: 'status.set',
  TITLE: 'title.set',
  TAGS: {
    ADD: 'tags.add',
    DELETE: 'tags.delete',
    CHANGE_TO: 'tags.changeTo',
  },
  PHOTOS: {
    ADD: 'photos.add',
    CHANGE_TO: 'photos.changeTo',
    DELETE: 'photos.delete',
    REPLACE: 'photos.replace',
    SWAP: 'photos.swap',
    UPDATE: 'photos.update',
  },
  VIDEOS: {
    ADD: 'videos.add',
    DELETE: 'videos.delete',
    REPLACE: 'videos.replace',
  },
  SKU: 'skuInventory.changeTo',
  PRICE: 'priceInventory.changeTo',
  QUANTITY: 'quantityInventory.changeTo',
  VARIATIONS: 'variationsInventory.changeTo',
};

export const OPERATIONS = {
  [ETSY]: {
    ...COMMON_OPERATIONS,
    CATEGORY: 'taxonomy.set',
    DESCRIPTION: 'description.set',
    DIGITAL: 'isDigital.set',
    FILES: {
      ADD: 'files.add',
      DELETE: 'files.delete',
      SWAP: 'files.swap',
      REPLACE: 'files.replace',
    },
    HEIGHT: 'itemHeight.set',
    IS_SUPPLY: 'is_supply.set',
    LENGTH: 'itemLength.set',
    MATERIALS: {
      ADD: 'materials.add',
      DELETE: 'materials.delete',
    },
    PERSONALIZATION: {
      IS_PERSONALIZABLE_SET: 'isPersonalizable.set',
      IS_REQUIRED_SET: 'personalizationIsRequired.set',
      CHAR_COUNT_MAX_SET: 'personalizationCharCountMax.set',
      INSTRUCTIONS_SET: 'personalizationInstructions.set',
    },
    PRODUCTION_PARTNERS: 'productionPartners.set',
    SHOULD_AUTO_RENEW: 'should_auto_renew.set',
    SECTION: 'section.set',
    SHIPPING_PROFILE: 'shipping_template_id.set',
    TAXONOMY_ATTRIBUTES: 'attributes.set',
    WEIGHT: 'itemWeight.set',
    WHEN_MADE: 'when_made.set',
    WHO_MADE: 'who_made.set',
    WIDTH: 'itemWidth.set',
    RETURN_POLICY: 'return_policy_id.set',
  },
  [SHOPIFY]: {
    ...COMMON_OPERATIONS,
    COLLECTIONS: 'collection_ids.set',
    DESCRIPTION: 'body_html.set',
    PRODUCT_TYPE: 'product_type.set',
    VENDOR: 'vendor.set',
    PAGE_TITLE: 'seoTitle.set',
    META_DESCRIPTION: 'seoDescription.set',
    URL_HANDLE: 'seoUrl.set',
    URL_REDIRECT: 'seoRedirect.set',
  },
  [VELA]: COMMON_OPERATIONS,
};

export const FILES_TO_DISPLAY = {
  [ETSY]: MAX_NUMBER_OF_FILES[ETSY],
};

export const PHOTOS_TO_DISPLAY = 10;

export const VIDEOS_TO_DISPLAY = {
  [ETSY]: MAX_NUMBER_OF_VIDEOS[ETSY],
  [SHOPIFY]: 8,
};

export const PLACEHOLDERS = {
  [ATTRIBUTES.ALT_TEXT]: 'Enter alt text',
  [ATTRIBUTES.BARCODE]: 'Enter barcode',
  [ATTRIBUTES.CAP]: 'CAP',
  [ATTRIBUTES.CATEGORY]: 'Choose Category',
  [ATTRIBUTES.CHAR_COUNT_MAX]: 'Limit',
  [ATTRIBUTES.COLLECTIONS]: 'Choose Collections',
  [ATTRIBUTES.COUNTRY_CODE]: 'Choose country/region',
  [ATTRIBUTES.CPI]: 'CPI',
  [ATTRIBUTES.DESCRIPTION]: 'Enter description',
  [ATTRIBUTES.HEIGHT]: 'Height',
  [ATTRIBUTES.INSTRUCTIONS]: 'Instructions',
  [ATTRIBUTES.IS_SUPPLY]: 'What is it?',
  [ATTRIBUTES.LENGTH]: 'Length',
  [ATTRIBUTES.PAGE_TITLE]: 'Enter page title',
  [ATTRIBUTES.MATERIALS]: 'Enter materials',
  [ATTRIBUTES.PRICE]: 'Enter price',
  [ATTRIBUTES.PRODUCTION_PARTNERS]: 'Choose Production partner',
  [ATTRIBUTES.PRODUCT_TYPE]: 'Choose Product type',
  [ATTRIBUTES.PROFILE_ID]: 'Choose Shipping profile',
  [ATTRIBUTES.RETURN_POLICY]: 'Choose Return policy',
  [ATTRIBUTES.QUANTITY]: 'Enter quantity',
  [ATTRIBUTES.SECTION]: 'Choose Section',
  [ATTRIBUTES.SKU]: 'Enter SKU',
  [ATTRIBUTES.TAGS]: 'Enter tags',
  [ATTRIBUTES.TITLE]: 'Enter title',
  [ATTRIBUTES.VENDOR]: 'Choose Vendor',
  [ATTRIBUTES.WEIGHT]: 'Weight',
  [ATTRIBUTES.WHEN_MADE]: 'When was it made?',
  [ATTRIBUTES.WHO_MADE]: 'Who made this?',
  [ATTRIBUTES.WIDTH]: 'Width',
  PROFILE: 'Choose Profile',
  SCALE: 'Choose Scale',
  UNIT: 'Choose a unit',
};

export const EMPTY_PROFILES = Map({
  byId: Map({ [DEFAULTS.MINUS_ONE]: Map({ name: NONE }) }),
  options: List([DEFAULTS.MINUS_ONE]),
});

export const ATTRIBUTE_DEFAULTS = {
  [ETSY]: {
    [ATTRIBUTES.DESCRIPTION]: DEFAULTS.EMPTY_STRING,
    [ATTRIBUTES.PHOTOS]: DEFAULTS.EMPTY_LIST,
    [ATTRIBUTES.VIDEOS]: DEFAULTS.EMPTY_LIST,
    [ATTRIBUTES.PRICE]: DEFAULTS.ZERO_CENT,
    [ATTRIBUTES.QUANTITY]: DEFAULTS.ONE,
    [ATTRIBUTES.SKU]: DEFAULTS.EMPTY_STRING,
    [ATTRIBUTES.TAGS]: DEFAULTS.EMPTY_ORDERED_MAP,
    [ATTRIBUTES.TITLE]: DEFAULTS.EMPTY_STRING,
  },
  [SHOPIFY]: {
    [ATTRIBUTES.BARCODE]: DEFAULTS.EMPTY_STRING,
    [ATTRIBUTES.CAP]: DEFAULTS.ZERO_CENT,
    [ATTRIBUTES.CHARGE_TAX]: DEFAULTS.TRUE,
    [ATTRIBUTES.COLLECTIONS]: DEFAULTS.EMPTY_LIST,
    [ATTRIBUTES.CONTINUE_SELLING]: DEFAULTS.TRUE,
    [ATTRIBUTES.COUNTRY_CODE]: DEFAULTS.NULL,
    [ATTRIBUTES.CPI]: DEFAULTS.ZERO_CENT,
    [ATTRIBUTES.DESCRIPTION]: DEFAULTS.EMPTY_STRING,
    [ATTRIBUTES.HS_CODE]: DEFAULTS.NULL,
    [ATTRIBUTES.PHOTOS]: DEFAULTS.EMPTY_LIST,
    [ATTRIBUTES.VIDEOS]: DEFAULTS.EMPTY_LIST,
    [ATTRIBUTES.PHYSICAL]: DEFAULTS.FALSE,
    [ATTRIBUTES.PRICE]: DEFAULTS.ZERO_CENT,
    [ATTRIBUTES.QUANTITY]: DEFAULTS.ZERO,
    [ATTRIBUTES.SKU]: DEFAULTS.EMPTY_STRING,
    [ATTRIBUTES.TAGS]: DEFAULTS.EMPTY_ORDERED_MAP,
    [ATTRIBUTES.TITLE]: DEFAULTS.EMPTY_STRING,
    [ATTRIBUTES.TRACK_QUANTITY]: DEFAULTS.FALSE,
    [ATTRIBUTES.UNIT]: DEFAULTS.UNIT,
    [ATTRIBUTES.WEIGHT]: DEFAULTS.ZERO,
  },
  [VELA]: {
    [ATTRIBUTES.DESCRIPTION]: DEFAULTS.EMPTY_STRING,
    [ATTRIBUTES.PHOTOS]: DEFAULTS.EMPTY_LIST,
    [ATTRIBUTES.VIDEOS]: DEFAULTS.EMPTY_LIST,
    [ATTRIBUTES.PRICE]: DEFAULTS.ZERO_CENT,
    [ATTRIBUTES.QUANTITY]: DEFAULTS.ONE,
    [ATTRIBUTES.SKU]: DEFAULTS.EMPTY_STRING,
    [ATTRIBUTES.TAGS]: DEFAULTS.EMPTY_ORDERED_MAP,
    [ATTRIBUTES.TITLE]: DEFAULTS.EMPTY_STRING,
  },
};

export const DEFAULT_OPTION = Map({
  shopify_name: 'Title',
  shopify_position: 1,
  shopify_values: List(['Default Title']),
});

export const DEFAULT_VARIANT = Map({
  shopify_position: '1',
  shopify_grams: DEFAULTS.NULL,
  shopify_product_id: 0,
  shopify_sku: DEFAULTS.NULL,
  shopify_option1: 'Default Title',
  shopify_option2: DEFAULTS.NULL,
  shopify_option3: DEFAULTS.NULL,
  shopify_requires_shipping: true,
  shopify_inventory_quantity: DEFAULTS.ZERO,
  shopify_barcode: DEFAULTS.NULL,
  shopify_taxable: DEFAULTS.TRUE,
  shopify_inventory_policy: 'deny',
  shopify_price: DEFAULTS.ZERO,
  shopify_compare_at_price: DEFAULTS.NULL,
  shopify_weight: DEFAULTS.ZERO,
  shopify_weight_unit: DEFAULTS.UNIT,
  shopify_id: 0,
  shopify_image_id: DEFAULTS.NULL,
  deleted: DEFAULTS.FALSE,
  id: DEFAULTS.EMPTY_STRING,
  shopify_harmonized_system_code: DEFAULTS.NULL,
  shopify_country_code_of_origin: DEFAULTS.NULL,
  shopify_cost: DEFAULTS.NULL,
  shopify_fulfillment_service: DEFAULTS.NULL,
  shopify_title: 'Default Title',
  shopify_old_inventory_quantity: DEFAULTS.EMPTY_STRING,
  shopify_inventory_management: SHOPIFY,
  shopify_admin_graphql_api_id: DEFAULTS.EMPTY_STRING,
});

export const EMPTY_VARIATIONS = {
  [SHOPIFY]: Map({
    options: List([
      Map({
        shopify_name: DEFAULTS.EMPTY_STRING,
        shopify_position: '1',
        shopify_values: DEFAULTS.EMPTY_LIST,
      }),
      Map({
        shopify_name: DEFAULTS.EMPTY_STRING,
        shopify_position: '2',
        shopify_values: DEFAULTS.EMPTY_LIST,
      }),
      Map({
        shopify_name: DEFAULTS.EMPTY_STRING,
        shopify_position: '3',
        shopify_values: DEFAULTS.EMPTY_LIST,
      }),
    ]),
    variants: DEFAULTS.EMPTY_LIST,
  }),
};

export const ABOUT_TYPES = {
  [ETSY]: [
    ATTRIBUTES.WHO_MADE,
    ATTRIBUTES.IS_SUPPLY,
    ATTRIBUTES.WHEN_MADE,
  ],
};

export const ABOUT_VALUES = {
  [ETSY]: {
    [ATTRIBUTES.WHO_MADE]: {
      I_DID: 'i_did',
      COLLECTIVE: 'collective',
      SOMEONE_ELSE: 'someone_else',
    },
    [ATTRIBUTES.IS_SUPPLY]: {
      FINISHED_PRODUCT: false,
      SUPPLY_OR_TOOL: true,
    },
    [ATTRIBUTES.WHEN_MADE]: {
      MADE_TO_ORDER: 'made_to_order',
      '2020_2024': '2020_2024',
      '2010_2019': '2010_2019',
      '2005_2009': '2005_2009',
      BEFORE_2005: 'before_2005',
      '2000_2004': '2000_2004',
      '1990S': '1990s',
      '1980S': '1980s',
      '1970S': '1970s',
      '1960S': '1960s',
      '1950S': '1950s',
      '1940S': '1940s',
      '1930S': '1930s',
      '1920S': '1920s',
      '1910S': '1910s',
      '1900S': '1900s',
      '1800S': '1800s',
      '1700S': '1700s',
      BEFORE_1700: 'before_1700',
    },
  },
};

export const ABOUT_MAP = {
  [ETSY]: {
    [ATTRIBUTES.WHO_MADE]: {
      [ABOUT_VALUES[ETSY][ATTRIBUTES.WHO_MADE].I_DID]: 'I did',
      [ABOUT_VALUES[ETSY][ATTRIBUTES.WHO_MADE].COLLECTIVE]: 'A member of my shop',
      [ABOUT_VALUES[ETSY][ATTRIBUTES.WHO_MADE].SOMEONE_ELSE]: 'Another company or person',
    },
    [ATTRIBUTES.IS_SUPPLY]: {
      [ABOUT_VALUES[ETSY][ATTRIBUTES.IS_SUPPLY].SUPPLY_OR_TOOL]: 'A supply or tool to make things',
      [ABOUT_VALUES[ETSY][ATTRIBUTES.IS_SUPPLY].FINISHED_PRODUCT]: 'A finished product',
    },
    [ATTRIBUTES.WHEN_MADE]: {
      [ABOUT_VALUES[ETSY][ATTRIBUTES.WHEN_MADE].MADE_TO_ORDER]: 'Made To Order',
      [ABOUT_VALUES[ETSY][ATTRIBUTES.WHEN_MADE]['2020_2024']]: '2020 - 2024',
      [ABOUT_VALUES[ETSY][ATTRIBUTES.WHEN_MADE]['2010_2019']]: '2010 - 2019',
      [ABOUT_VALUES[ETSY][ATTRIBUTES.WHEN_MADE]['2005_2009']]: '2005 - 2009',
      [ABOUT_VALUES[ETSY][ATTRIBUTES.WHEN_MADE].BEFORE_2005]: 'Before 2005',
      [ABOUT_VALUES[ETSY][ATTRIBUTES.WHEN_MADE]['2000_2004']]: '2000 - 2004',
      [ABOUT_VALUES[ETSY][ATTRIBUTES.WHEN_MADE]['1900S']]: '1900 - 1909',
      [ABOUT_VALUES[ETSY][ATTRIBUTES.WHEN_MADE].BEFORE_1700]: 'Before 1700',
    },
  },
};

export const ABOUT_LIST = {
  [ETSY]: {
    [ATTRIBUTES.WHO_MADE]: Object.values(ABOUT_VALUES[ETSY][ATTRIBUTES.WHO_MADE]),
    [ATTRIBUTES.IS_SUPPLY]: Object.values(ABOUT_VALUES[ETSY][ATTRIBUTES.IS_SUPPLY]),
    [ATTRIBUTES.WHEN_MADE]: Object.values(ABOUT_VALUES[ETSY][ATTRIBUTES.WHEN_MADE]),
  },
};

export const ABOUT_OPERATIONS = {
  [ATTRIBUTES.WHO_MADE]: OPERATIONS[ETSY].WHO_MADE,
  [ATTRIBUTES.IS_SUPPLY]: OPERATIONS[ETSY].IS_SUPPLY,
  [ATTRIBUTES.WHEN_MADE]: OPERATIONS[ETSY].WHEN_MADE,
};

export const ABOUT_LABELS = {
  [ATTRIBUTES.WHO_MADE]: 'Who made it?',
  [ATTRIBUTES.IS_SUPPLY]: 'What is it?',
  [ATTRIBUTES.WHEN_MADE]: {
    true: 'When it was made?',
    false: 'When did you make it?',
  },
};

export const PRODUCTION_PARTNERS_TYPE = {
  OPTIONAL: 'optional',
  REQUIRED: 'required',
  UNAVAILABLE: 'unavailable',
};

export const FIELDS = {
  [ETSY]: {
    LISTING_ID: 'listing_id',
    STATUS: 'state',
    TITLE: 'title',
    DESCRIPTION: 'description',
    TAXONOMY_ID: 'taxonomy_id',
    SECTION_ID: 'section_id',
    SHOP_SECTION_ID: 'shop_section_id',
    TAGS: 'tags',
    MATERIALS: 'materials',
    CREATION_TSZ: 'creation_tsz',
    ENDING_TSZ: 'ending_tsz',
    STATE_TSZ: 'state_tsz',
    LAST_MODIFIED_TSZ: 'last_modified_tsz',
    IS_COMPLETE: 'is_complete',
    ID: 'id',
    SHOP_ID: 'shop_id',
    PRICE: 'price',
    QUANTITY: 'quantity',
    SKU: 'sku',
    PHOTOS: 'photos',
    IS_INVALID: '_hive_is_invalid',
    INVALID_REASON: '_hive_invalid_reason',
    MODIFIED_BY_HIVE: 'modified_by_hive',
    HIVE_LAST_MODIFIED_TSZ: '_hive_last_modified_tsz',
    CHANGED_PROPERTIES: '_hive_changed_properties',
    ON_NEW_SCHEMA: '_hive_on_new_schema',
    LAST_SYNC: '_hive_last_sync',
    CAN_WRITE_INVENTORY: 'can_write_inventory',
    VARIATIONS: 'variations',
    PRODUCT_OFFERINGS: 'productOfferings',
    ATTRIBUTES: 'attributes',
    CHANNELID: 'channelid',
    TEMPLATEID: 'templateid',
    TEMPLATENAME: 'templatename',
    TEMPLATES: 'templates',
    PRODUCT_ID: 'product_id',
    IMAGE_ID: 'image_id',
    ORDER_ID: 'order_id',
    CHANNEL_IMAGE_ID: 'channel_image_id',
    IMAGES_COUNT: 'images_count',
    SHIPPING_TEMPLATE_ID: 'shipping_template_id',
    IS_DIGITAL: 'is_digital',
    LINKED_PRODUCTS_SHOP_IDS: 'merged_products_shop_ids',
    LINKED_PRODUCTS_SHOP_CHANNELS: 'merged_products_shop_channels',
    PRICE_VARIANT: 'priceVariant',
    THUMBNAIL_URL: 'thumbnailUrl',
    CHANNEL_PRODUCT_ID: 'listing_id',
    LINK_ID: 'link_id',
  },
  [SHOPIFY]: {
    ID: 'id',
    STATUS: 'status',
    PRODUCT_ID: 'product_id',
    SHOPIFY_PRODUCT_ID: 'shopify_product_id',
    TITLE: 'title',
    BODY_HTML: 'body_html',
    IMAGES: 'images',
    PHOTOS: 'photos',
    TAGS: 'tags',
    PRODUCT_TYPE: 'product_type',
    VENDOR: 'vendor',
    SHOP_ID: 'shop_id',
    PUBLISHED_AT: 'published_at',
    UPDATED_AT: 'updated_at',
    IS_COMPLETE: 'is_complete',
    IS_INVALID: '_hive_is_invalid',
    INVALID_REASON: '_hive_invalid_reason',
    HIVE_UPDATED_AT: '_hive_updated_at',
    MODIFIED_BY_HIVE: '_hive_modified_by_hive',
    CHANGED_PROPERTIES: 'changed_properties',
    LAST_SYNC: 'last_sync',
    COLLECTIONS: 'collection_title',
    ADMIN_GRAPHQL_API_ID: 'admin_graphql_api_id',
    COLLECTION_IDS: 'collection_ids',
    VARIATIONS: 'variations',
    PRICE: 'price',
    SKU: 'sku',
    BARCODE: 'barcode',
    INVENTORY_MANAGEMENT: 'inventory_management',
    INVENTORY_QUANTITY: 'inventory_quantity',
    COMPARE_AT_PRICE: 'compare_at_price',
    QUANTITY: 'quantity',
    INVALID: 'invalid',
    MESSAGE: 'message',
    TEMPLATES: 'templates',
    LINKED_PRODUCTS_SHOP_IDS: 'mergedProductsShopIds',
    LINKED_PRODUCTS_SHOP_CHANNELS: 'mergedProductsShopChannels',
    PRICE_VARIANT: 'priceVariant',
    THUMBNAIL_URL: 'thumbnailUrl',
    CHANNEL_PRODUCT_ID: 'productId',
    LINK_ID: 'linkId',
  },
  [VELA]: {
    CHANNEL: 'channel',
    CHANNEL_ID: 'channelId',
    CHANNEL_PRODUCT_ID: 'channelProductId',
    COLLECTIONS: 'collections',
    CREATED_AT: 'createdAt',
    EXPIRES_AT: 'expiresAt',
    ID: 'productId',
    IS_COMPLETE: 'isComplete',
    LINK_ID: 'linkId',
    PRICE: 'price',
    PRICE_VARIANT: 'priceVariant',
    PRODUCT_ID: 'productId',
    PUBLISHED_AT: 'publishedAt',
    QUANTITY: 'quantity',
    SECTION: 'section',
    SHOP_ID: 'shopId',
    STATUS: 'status',
    THUMBNAIL_URL: 'thumbnailUrl',
    TITLE: 'title',
    UPDATED_AT: 'updatedAt',
    LINKED_PRODUCTS_SHOP_IDS: 'linkedProductsShopIds',
    LINKED_PRODUCTS_SHOP_CHANNELS: 'linkedProductsShopChannels',
  },
};

export const SHIPPING_PROFILE = {
  FIXED: 'fixed',
  CALCULATED: 'calculated',
};

export const SHIPPING_PROFILE_MAP = {
  0: SHIPPING_PROFILE.FIXED,
  1: SHIPPING_PROFILE.CALCULATED,
};

export const SHIPPING_ERRORS = [
  ATTRIBUTES.PROFILE_ID,
  ATTRIBUTES.HEIGHT,
  ATTRIBUTES.LENGTH,
  ATTRIBUTES.WEIGHT,
  ATTRIBUTES.WIDTH,
];

export const DIMENSIONS = {
  [ETSY]: [ATTRIBUTES.LENGTH, ATTRIBUTES.WIDTH, ATTRIBUTES.HEIGHT],
};

export const PERSONALIZATION_ERRORS = [ATTRIBUTES.CHAR_COUNT_MAX, ATTRIBUTES.INSTRUCTIONS];

export const MESSAGES = {
  CUSTOM_SHIPPING_OPTIONS: 'Custom shipping options',
  DEFINED_BY_VARIATION: 'Defined by Variation',
  INSTRUCTIONS: 'Example: Enter the name you want on the necklace. Max 12 characters, no\nspaces, no special characters. Thank you!',
  NOT_AVAILABLE: 'Not available',
  NOT_SUPPORTED: 'Not supported',
  PUBLISH_PROHIBITED: 'prohibited this listing from being published',
  REFRESH_SHOP_FOR_PERSONALIZATION: 'Click "Refresh shop" to import your personalizations from Etsy and edit them in Vela',
  UPLOAD_NOT_SUPPORTED: 'Digital files cannot be included for "Made to Order" listings. Upload files after purchase to complete the order.',
};

export const TAXONOMY_ATTRIBUTE = {
  BOOL: 'bool',
  MULTI: 'multi',
  SCALED: 'scaled',
  SINGLE: 'single',
};
