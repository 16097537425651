import { List, Map } from 'immutable';

import { shapeOfferingForApp, shapeVariationForApp } from './profiles/shapeForApp';
import { isValidNumber, toFixed, toString } from './number';
import { shapeVariationsProfileForAPI } from './profiles/shapeForAPI';
import { getInventoryOperationValue } from './product/getOperationValue';
import { reduceErrors } from './errors';
import { getSize } from './iterable/getSize';
import { toJS } from './iterable/toJS';
import { get } from './iterable/get';

import { DEFAULT_OPTION, DEFAULT_VARIANT, ERROR, SECTIONS, VALUE } from '../constants/product';
import { INVENTORY_OPERATIONS } from '../constants/bulkEdit';
import { DEFAULTS, UNITS } from '../constants';
import { ETSY, SHOPIFY } from '../constants/channels';
import { INDIVIDUAL } from '../constants/profiles';
import {
  BARCODE,
  CAP,
  CHARGE_TAX,
  CONTINUE_SELLING,
  COUNTRY_CODE,
  CPI,
  DEFAULT_VALUE,
  HS_CODE,
  PHOTOS,
  PHYSICAL,
  PRICE,
  QUANTITY,
  SKU,
  TRACK_QUANTITY,
  UNIT,
  VARIATIONS,
  WEIGHT,
} from '../constants/attributes';

const { INVENTORY } = SECTIONS;

function getOperationType({ channel, type }) {
  try {
    return INVENTORY_OPERATIONS[channel][type];
  } catch (error) {
    return undefined;
  }
}

export function makeOperation({ channel, productId, type, value }) {
  const operationType = getOperationType({ channel, type });

  if (!operationType || !value) return undefined;

  const operation = Map({ type: operationType, value });

  return productId
    ? operation.set('products', List([productId]))
    : operation;
}

export function makeInlineOperationValue({ channel, data, type }) {
  switch (channel) {
    case SHOPIFY: {
      const { value } = data;

      switch (type) {
        case BARCODE: {
          return Map({ shopify_barcode: value || DEFAULTS.EMPTY_STRING });
        }

        case CAP: {
          return Map({ shopify_compare_at_price: value || DEFAULTS.ZERO_CENT });
        }

        case CHARGE_TAX: {
          return Map({ shopify_taxable: value || DEFAULTS.FALSE });
        }

        case CONTINUE_SELLING: {
          return Map({ shopify_inventory_policy: value ? 'continue' : 'deny' });
        }

        case COUNTRY_CODE: {
          return Map({ shopify_country_code_of_origin: value || DEFAULTS.NULL });
        }

        case CPI: {
          return Map({ shopify_cost: value || DEFAULTS.ZERO_CENT });
        }

        case HS_CODE: {
          return Map({ shopify_harmonized_system_code: value || DEFAULTS.NULL });
        }

        case PHYSICAL: {
          return Map({ shopify_requires_shipping: value || DEFAULTS.FALSE });
        }

        case PRICE: {
          return Map({ shopify_price: value || DEFAULTS.ZERO_CENT });
        }

        case QUANTITY: {
          return Map({ shopify_inventory_quantity: value || DEFAULTS.ZERO });
        }

        case SKU: {
          return Map({ shopify_sku: value || DEFAULTS.EMPTY_STRING });
        }

        case TRACK_QUANTITY: {
          return Map({ shopify_inventory_management: value ? SHOPIFY : DEFAULTS.NULL });
        }

        case UNIT: {
          return Map({ shopify_weight_unit: value });
        }

        case WEIGHT: {
          return Map({ shopify_weight: value || DEFAULTS.ZERO });
        }

        default: {
          return undefined;
        }
      }
    }

    default: {
      return undefined;
    }
  }
}

export function makeOperationValue({ channel, data, overset, product, type }) {
  const productId = product.get('productId');

  if (!getOperationType({ channel, type })) {
    return undefined;
  }

  function inventoryLevelErrors() {
    function reduceInventoryForErrors(result, item, key) {
      return result || ((key in INVENTORY_OPERATIONS[channel]) && !!item.get(ERROR));
    }

    switch (channel) {
      case ETSY: {
        return !!product.getIn([INVENTORY, type, ERROR]);
      }

      case SHOPIFY: {
        return product.get(INVENTORY).reduce(reduceInventoryForErrors, false);
      }

      default:
        return false;
    }
  }

  function variationsLevelErrors() {
    switch (channel) {
      case ETSY: {
        return !!product.getIn([INVENTORY, type, ERROR]);
      }

      case SHOPIFY: {
        return reduceErrors(product.getIn(['variations', 'errors']));
      }

      default:
        return false;
    }
  }

  if (overset) {
    if (variationsLevelErrors()) return undefined;

    switch (channel) {
      case ETSY: {
        const { offeringId = null, combination = null, value } = data || {};
        if (
          (Map.isMap(combination) && offeringId === null) ||
          (isValidNumber(offeringId) && combination === null)
        ) {
          return getInventoryOperationValue({ channel, combination, offeringId, type, value });
        }

        return undefined;
      }

      case SHOPIFY: {
        return shapeVariationsProfileForAPI({
          productId,
          profile: product.get('variations'),
          toImmutable: true,
        })
          .get(VALUE);
      }

      default: {
        return undefined;
      }
    }
  } else {
    if (inventoryLevelErrors()) return undefined;

    switch (channel) {
      case ETSY: {
        return getInventoryOperationValue({ channel, inventory: product.get(INVENTORY), type });
      }

      case SHOPIFY: {
        return getInventoryOperationValue({ channel, inventory: product.get(INVENTORY) });
      }

      default:
        return undefined;
    }
  }
}

export function getInventoryFromVariations({ channel, imagesById, product: source, variations }) {
  let product = source;

  function reduceOfferingsToTrackQuantity(tracking, offering) {
    return tracking && get('shopify_inventory_management')(offering) === SHOPIFY;
  }

  switch (channel) {
    case SHOPIFY: {
      if (
        getSize(get('options')(variations)) &&
        getSize(get('variants')(variations)) &&
        !(
          get(['options', 0, 'shopify_name'])(variations) === 'Title' &&
          get(['variants', 0, 'shopify_option1'])(variations) === 'Default Title'
        )
      ) {
        const overset = true;
        const firstOffering = get(['variants', 0])(variations);
        const sku = get('shopify_sku')(firstOffering);
        const cpi = get('shopify_cost')(firstOffering);
        const price = get('shopify_price')(firstOffering);
        const weight = get('shopify_weight')(firstOffering);
        const barcode = get('shopify_barcode')(firstOffering);
        const unit = get('shopify_weight_unit')(firstOffering);
        const chargeTax = get('shopify_taxable')(firstOffering);
        const cap = get('shopify_compare_at_price')(firstOffering);
        const physical = get('shopify_requires_shipping')(firstOffering);
        const quantity = get('shopify_inventory_quantity')(firstOffering);
        const hsCode = get('shopify_harmonized_system_code')(firstOffering);
        const inventoryPolicy = get('shopify_inventory_policy')(firstOffering);
        const countryCode = get('shopify_country_code_of_origin')(firstOffering);
        const trackQuantity = get('shopify_inventory_management')(firstOffering) === SHOPIFY;
        const variationsTrackQuantity = get('variants')(variations).reduce(
          reduceOfferingsToTrackQuantity,
          true
        );

        product = product
          .setIn([VARIATIONS, TRACK_QUANTITY], variationsTrackQuantity)
          .setIn([VARIATIONS, VARIATIONS],
            List(get('options')(variations).filter(get('shopify_name')).map(shapeVariationForApp({ channel })))
          )
          .setIn([VARIATIONS, 'offerings'],
            List(
              get('variants')(variations).map(
                shapeOfferingForApp({
                  channel,
                  forProduct: true,
                  imagesById,
                  photos: product.getIn([PHOTOS, VALUE]),
                  variations: get('options')(variations),
                })
              )
            )
          );

        product = product
          .setIn([INVENTORY, BARCODE], Map({ overset, value: barcode || '' }))
          .setIn([INVENTORY, CAP], Map({ overset, value: toFixed(cap, 2) || '' }))
          .setIn([INVENTORY, CHARGE_TAX], Map({ value: chargeTax }))
          .setIn([INVENTORY, COUNTRY_CODE], Map({ value: countryCode }))
          .setIn([INVENTORY, CONTINUE_SELLING], Map({ value: inventoryPolicy === 'continue' }))
          .setIn([INVENTORY, CPI], Map({ overset, value: toFixed(cpi, 2) || '' }))
          .setIn([INVENTORY, HS_CODE], Map({ value: hsCode }))
          .setIn([INVENTORY, PHYSICAL], Map({ overset, value: physical }))
          .setIn([INVENTORY, PRICE], Map({ overset, value: toFixed(price, 2) || '' }))
          .setIn([INVENTORY, QUANTITY], Map({ overset, value: toString(quantity) || '' }))
          .setIn([INVENTORY, SKU], Map({ overset, value: sku || '' }))
          .setIn([INVENTORY, TRACK_QUANTITY], Map({ value: trackQuantity }))
          .setIn([INVENTORY, UNIT], Map({ value: unit || UNITS[0] }))
          .setIn([INVENTORY, WEIGHT], Map({ value: weight || '' }))
          .setIn([INVENTORY, DEFAULT_VALUE],
            Map({
              options: List([DEFAULT_OPTION]),
              variants: List([
                Map({
                  ...toJS(firstOffering),
                  id: -1,
                  deleted: false,
                  shopify_position: 1,
                  shopify_title: 'Default Title',
                  shopify_option1: 'Default Title',
                  shopify_option2: null,
                  shopify_option3: null,
                  shopify_id: 0,
                  shopify_created_at: null,
                  shopify_updated_at: null,
                  shopify_inventory_item_id: undefined,
                  shopify_admin_graphql_api_id: undefined,
                  shopify_cost: toFixed(cpi, 2),
                  shopify_price: toFixed(price, 2),
                  shopify_compare_at_price: toFixed(cap, 2),
                }),
              ]),
            })
          );
      } else {
        const productId = product.get('productId');
        const channelProductId = product.get('channelProductId');
        const firstOffering = get(['variants', 0])(variations) || DEFAULT_VARIANT;
        const sku = get('shopify_sku')(firstOffering);
        const cpi = get('shopify_cost')(firstOffering);
        const price = get('shopify_price')(firstOffering);
        const weight = get('shopify_weight')(firstOffering);
        const barcode = get('shopify_barcode')(firstOffering);
        const unit = get('shopify_weight_unit')(firstOffering);
        const chargeTax = get('shopify_taxable')(firstOffering);
        const cap = get('shopify_compare_at_price')(firstOffering);
        const physical = get('shopify_requires_shipping')(firstOffering);
        const quantity = get('shopify_inventory_quantity')(firstOffering);
        const hsCode = get('shopify_harmonized_system_code')(firstOffering);
        const inventoryPolicy = get('shopify_inventory_policy')(firstOffering);
        const countryCode = get('shopify_country_code_of_origin')(firstOffering);
        const trackQuantity = get('shopify_inventory_management')(firstOffering) === SHOPIFY;

        product = product
          .set(VARIATIONS,
            Map({
              offerings: List(),
              variations: List(),
              [INDIVIDUAL.BARCODE]: true,
              [INDIVIDUAL.PRICE]: true,
              [INDIVIDUAL.SHIPPING]: true,
              [INDIVIDUAL.SKU]: true,
              [TRACK_QUANTITY]: trackQuantity,
            })
          )
          .setIn([INVENTORY, BARCODE], Map({ value: barcode || '' }))
          .setIn([INVENTORY, CAP], Map({ value: toFixed(cap, 2) || '' }))
          .setIn([INVENTORY, CHARGE_TAX], Map({ value: chargeTax }))
          .setIn([INVENTORY, CONTINUE_SELLING], Map({ value: inventoryPolicy === 'continue' }))
          .setIn([INVENTORY, COUNTRY_CODE], Map({ value: countryCode }))
          .setIn([INVENTORY, CPI], Map({ value: toFixed(cpi, 2) || '' }))
          .setIn([INVENTORY, HS_CODE], Map({ value: hsCode }))
          .setIn([INVENTORY, PHYSICAL], Map({ value: physical }))
          .setIn([INVENTORY, PRICE], Map({ value: toFixed(price, 2) || '' }))
          .setIn([INVENTORY, QUANTITY], Map({ value: toString(quantity) || '' }))
          .setIn([INVENTORY, SKU], Map({ value: sku || '' }))
          .setIn([INVENTORY, TRACK_QUANTITY], Map({ value: trackQuantity }))
          .setIn([INVENTORY, UNIT], Map({ value: unit || UNITS[0] }))
          .setIn([INVENTORY, WEIGHT], Map({ value: weight || '' }))
          .setIn([INVENTORY, DEFAULT_VALUE],
            Map({
              options: List([DEFAULT_OPTION]),
              variants: List([
                Map({
                  ...toJS(firstOffering),
                  id: -1,
                  deleted: false,
                  shopify_position: 1,
                  shopify_title: 'Default Title',
                  shopify_option1: 'Default Title',
                  shopify_option2: null,
                  shopify_option3: null,
                  shopify_id: 0,
                  shopify_created_at: null,
                  shopify_updated_at: null,
                  shopify_inventory_item_id: undefined,
                  shopify_admin_graphql_api_id: undefined,
                  shopify_product_id: channelProductId,
                  vela_product_id: productId,
                  shopify_cost: toFixed(cpi, 2) || '',
                  shopify_price: toFixed(price, 2) || '',
                  shopify_compare_at_price: toFixed(cap, 2) || '',
                }),
              ]),
            })
          );
      }

      return product.setIn([VARIATIONS, 'channel'], channel);
    }

    default: {
      return product;
    }
  }
}
