import ActionConstants, { Actions } from '../constants/actions';

const connectProfileActions = new Actions('ConnectProfile')
  .add('bootstrap')
  .add('clean_up')
  .addAsync('connect_products')
  .addAsync('get_connected_products')
  .addAsync('get_filters')
  .addAsync('get_listings')
  .addAsync('get_statuses')
  .add('load_more')
  .add('set_connected_products')
  .add('set_filter')
  .add('set_status')
  .add('toggle_product')
  .addAsync('toggle_all_products');

ActionConstants.add(connectProfileActions);

export default connectProfileActions.actionCreators();
