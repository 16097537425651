import { areSame } from './../bool';
import { get } from './../iterable/get';

import DATA from '../../data';

export function findAnalogue(props, match) {
  return function find(item) {
    let result = false;

    for (const key in props) {
      if (props.hasOwnProperty(key)) {
        const itemValue = get(key)(item);
        const valueToCompare = props[key];

        result = typeof match === 'function'
          ? match(itemValue, valueToCompare)
          : areSame(itemValue, valueToCompare);
      }
    }

    return result;
  };
}

export function findAnalogueScale(scale) {
  return findAnalogue({ name: DATA.SCALES_LOOKUP[scale]?.name });
}
