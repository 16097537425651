import bodyHTML from './bodyHTML';
import description from './description';
import details from './details';
import files from './files';
import inventory from './inventory';
import materials from './materials';
import personalization from './personalization';
import photos from './photos';
import returnPolicy from './returnPolicy';
import seoTitle from './seoTitle';
import seoDescription from './seoDescription';
import { seoUrl, seoUrlNumeration } from './seoUrl';
import shipping from './shipping';
import tags from './tags';
import title from './title';
import variations from './variations';
import videos from './videos';
import { getSize } from '../../iterable/getSize';

import { ETSY, SHOPIFY } from '../../../constants/channels';
import { OPERATIONS } from '../../../constants/bulkEdit';

function applyOperationToProduct(props) {
  const { actions, operation, product } = props;
  const channel = product.get('channel');

  switch (operation.get('type')) {
    case OPERATIONS[ETSY].CATEGORY:
    case OPERATIONS[ETSY].IS_SUPPLY:
    case OPERATIONS[ETSY].PRODUCTION_PARTNERS:
    case OPERATIONS[ETSY].SECTION:
    case OPERATIONS[ETSY].WHO_MADE:
    case OPERATIONS[ETSY].WHEN_MADE:
    case OPERATIONS[SHOPIFY].COLLECTIONS:
    case OPERATIONS[SHOPIFY].PRODUCT_TYPE:
    case OPERATIONS[SHOPIFY].VENDOR: {
      return details(props);
    }

    case OPERATIONS[ETSY].TAXONOMY_ATTRIBUTES: {
      return details(props);
    }

    case OPERATIONS[ETSY].DESCRIPTION.ADD_AFTER:
    case OPERATIONS[ETSY].DESCRIPTION.ADD_BEFORE:
    case OPERATIONS[ETSY].DESCRIPTION.DELETE:
    case OPERATIONS[ETSY].DESCRIPTION.FIND_AND_REPLACE:
    case OPERATIONS[ETSY].DESCRIPTION.SET: {
      return description(props);
    }

    case OPERATIONS[SHOPIFY].DESCRIPTION.ADD_AFTER:
    case OPERATIONS[SHOPIFY].DESCRIPTION.ADD_BEFORE:
    case OPERATIONS[SHOPIFY].DESCRIPTION.DELETE:
    case OPERATIONS[SHOPIFY].DESCRIPTION.FIND_AND_REPLACE:
    case OPERATIONS[SHOPIFY].DESCRIPTION.SET: {
      return bodyHTML(props);
    }

    case OPERATIONS[SHOPIFY].META_DESCRIPTION.ADD_AFTER:
    case OPERATIONS[SHOPIFY].META_DESCRIPTION.ADD_BEFORE:
    case OPERATIONS[SHOPIFY].META_DESCRIPTION.DELETE:
    case OPERATIONS[SHOPIFY].META_DESCRIPTION.FIND_AND_REPLACE:
    case OPERATIONS[SHOPIFY].META_DESCRIPTION.SET: {
      return seoDescription(props);
    }

    case OPERATIONS[ETSY].MATERIALS.ADD:
    case OPERATIONS[ETSY].MATERIALS.DELETE: {
      return materials(props);
    }

    case OPERATIONS[channel].PHOTOS.ADD:
    case OPERATIONS[channel].PHOTOS.DELETE:
    case OPERATIONS[channel].PHOTOS.EDIT:
    case OPERATIONS[channel].PHOTOS.REPLACE:
    case OPERATIONS[channel].PHOTOS.SWAP:
    case OPERATIONS[channel].PHOTOS.UPDATE: {
      return photos(props);
    }

    case OPERATIONS[channel].VIDEOS.ADD:
    case OPERATIONS[channel].VIDEOS.REPLACE:
    case OPERATIONS[channel].VIDEOS.DELETE: {
      return videos(props);
    }

    case OPERATIONS[channel].TAGS.ADD:
    case OPERATIONS[channel].TAGS.DELETE:
    case OPERATIONS[channel].TAGS.CHANGE_TO: {
      return tags(props);
    }

    case OPERATIONS[channel].TITLE.ADD_AFTER:
    case OPERATIONS[channel].TITLE.ADD_BEFORE:
    case OPERATIONS[channel].TITLE.DELETE:
    case OPERATIONS[channel].TITLE.FIND_AND_REPLACE:
    case OPERATIONS[channel].TITLE.SET: {
      return title(props);
    }

    case OPERATIONS[SHOPIFY].PAGE_TITLE.ADD_AFTER:
    case OPERATIONS[SHOPIFY].PAGE_TITLE.ADD_BEFORE:
    case OPERATIONS[SHOPIFY].PAGE_TITLE.DELETE:
    case OPERATIONS[SHOPIFY].PAGE_TITLE.FIND_AND_REPLACE:
    case OPERATIONS[SHOPIFY].PAGE_TITLE.SET: {
      return seoTitle(props);
    }

    case OPERATIONS[SHOPIFY].URL_HANDLE.ADD_AFTER:
    case OPERATIONS[SHOPIFY].URL_HANDLE.ADD_BEFORE:
    case OPERATIONS[SHOPIFY].URL_HANDLE.DELETE:
    case OPERATIONS[SHOPIFY].URL_HANDLE.FIND_AND_REPLACE:
    case OPERATIONS[SHOPIFY].URL_HANDLE.SET: {
      return seoUrl(props);
    }

    case OPERATIONS[SHOPIFY].URL_NUMERATION.SET: {
      return seoUrlNumeration(props);
    }

    case OPERATIONS[SHOPIFY].BARCODE.DELETE:
    case OPERATIONS[SHOPIFY].BARCODE.CHANGE_TO:
    case OPERATIONS[SHOPIFY].BARCODE.ADD_AFTER:
    case OPERATIONS[SHOPIFY].BARCODE.ADD_BEFORE:
    case OPERATIONS[SHOPIFY].BARCODE.FIND_AND_REPLACE:
    case OPERATIONS[SHOPIFY].CAP.CHANGE_TO:
    case OPERATIONS[SHOPIFY].CAP.DECREASE_BY:
    case OPERATIONS[SHOPIFY].CAP.INCREASE_BY:
    case OPERATIONS[SHOPIFY].CAP.COPY_FROM_PRICE:
    case OPERATIONS[SHOPIFY].CHARGE_TAX.SET:
    case OPERATIONS[SHOPIFY].CONTINUE_SELLING.SET:
    case OPERATIONS[SHOPIFY].COUNTRY_CODE.SET:
    case OPERATIONS[SHOPIFY].CPI.CHANGE_TO:
    case OPERATIONS[SHOPIFY].CPI.DECREASE_BY:
    case OPERATIONS[SHOPIFY].CPI.INCREASE_BY:
    case OPERATIONS[SHOPIFY].HS_CODE.SET:
    case OPERATIONS[SHOPIFY].INVENTORY_INLINE_EDIT:
    case OPERATIONS[channel].PRICE.CHANGE_TO:
    case OPERATIONS[channel].PRICE.DECREASE_BY:
    case OPERATIONS[channel].PRICE.INCREASE_BY:
    case OPERATIONS[SHOPIFY].PRICE.COPY_FROM_CAP:
    case OPERATIONS[channel].QUANTITY.CHANGE_TO:
    case OPERATIONS[channel].QUANTITY.DECREASE_BY:
    case OPERATIONS[channel].QUANTITY.INCREASE_BY:
    case OPERATIONS[channel].SKU.DELETE:
    case OPERATIONS[channel].SKU.CHANGE_TO:
    case OPERATIONS[channel].SKU.ADD_AFTER:
    case OPERATIONS[channel].SKU.ADD_BEFORE:
    case OPERATIONS[channel].SKU.FIND_AND_REPLACE:
    case OPERATIONS[SHOPIFY].TRACK_QUANTITY.SET:
    case OPERATIONS[SHOPIFY].WEIGHT.CHANGE_TO:
    case OPERATIONS[SHOPIFY].WEIGHT.INCREASE_BY:
    case OPERATIONS[SHOPIFY].WEIGHT.DECREASE_BY: {
      return inventory(props);
    }

    case OPERATIONS[ETSY].SHIPPING_PROFILE:
    case OPERATIONS[ETSY].HEIGHT.DECREASE_BY:
    case OPERATIONS[ETSY].HEIGHT.INCREASE_BY:
    case OPERATIONS[ETSY].HEIGHT.SET:
    case OPERATIONS[ETSY].LENGTH.DECREASE_BY:
    case OPERATIONS[ETSY].LENGTH.INCREASE_BY:
    case OPERATIONS[ETSY].LENGTH.SET:
    case OPERATIONS[ETSY].WEIGHT.DECREASE_BY:
    case OPERATIONS[ETSY].WEIGHT.INCREASE_BY:
    case OPERATIONS[ETSY].WEIGHT.SET:
    case OPERATIONS[ETSY].WIDTH.DECREASE_BY:
    case OPERATIONS[ETSY].WIDTH.INCREASE_BY:
    case OPERATIONS[ETSY].WIDTH.SET: {
      return shipping(props);
    }

    case OPERATIONS[SHOPIFY].BARCODE.INLINE_EDIT:
    case OPERATIONS[SHOPIFY].CAP.INLINE_EDIT:
    case OPERATIONS[SHOPIFY].CHARGE_TAX.INLINE_EDIT:
    case OPERATIONS[SHOPIFY].CONTINUE_SELLING.INLINE_EDIT:
    case OPERATIONS[SHOPIFY].COUNTRY_CODE.INLINE_EDIT:
    case OPERATIONS[SHOPIFY].CPI.INLINE_EDIT:
    case OPERATIONS[SHOPIFY].HS_CODE.INLINE_EDIT:
    case OPERATIONS[SHOPIFY].PHYSICAL.INLINE_EDIT:
    case OPERATIONS[SHOPIFY].PRICE.INLINE_EDIT:
    case OPERATIONS[SHOPIFY].QUANTITY.INLINE_EDIT:
    case OPERATIONS[SHOPIFY].SKU.INLINE_EDIT:
    case OPERATIONS[SHOPIFY].WEIGHT.INLINE_EDIT:
    case OPERATIONS[channel].VARIATIONS: {
      return variations(props);
    }

    case OPERATIONS[ETSY].FILES.ADD:
    case OPERATIONS[ETSY].FILES.DELETE:
    case OPERATIONS[ETSY].FILES.REPLACE:
    case OPERATIONS[ETSY].FILES.SWAP: {
      return files(props);
    }

    case OPERATIONS[ETSY].PERSONALIZATION.CHAR_COUNT_MAX_SET:
    case OPERATIONS[ETSY].PERSONALIZATION.INSTRUCTIONS_ADD_AFTER:
    case OPERATIONS[ETSY].PERSONALIZATION.INSTRUCTIONS_ADD_BEFORE:
    case OPERATIONS[ETSY].PERSONALIZATION.INSTRUCTIONS_DELETE:
    case OPERATIONS[ETSY].PERSONALIZATION.INSTRUCTIONS_FIND_AND_REPLACE:
    case OPERATIONS[ETSY].PERSONALIZATION.INSTRUCTIONS_SET:
    case OPERATIONS[ETSY].PERSONALIZATION.IS_PERSONALIZABLE_SET:
    case OPERATIONS[ETSY].PERSONALIZATION.IS_REQUIRED_SET: {
      return personalization(props);
    }

    case OPERATIONS[ETSY].RETURN_POLICY: {
      return returnPolicy(props);
    }

    default: {
      return { actions, product };
    }
  }
}

export function applyOperationsToProducts({ actions = [], operations, products, confirmation, ...rest }) {
  if (!getSize(operations) || !getSize(products?.get('byId'))) return { actions, products };

  function reduceOperations(result, operation) {
    return operation.get('products').includes(result.product.get('productId'))
      ? applyOperationToProduct({ operation, confirmation, ...result, ...rest })
      : result;
  }

  function applyOperationsToProduct(result, product, productId) {
    const updates = operations.reduce(reduceOperations, { actions: result.actions, product });
    return {
      confirmation: updates.confirmation,
      actions: updates.actions,
      products: result.products.setIn(['byId', productId], updates.product),
    };
  }

  return products.get('byId').reduce(applyOperationsToProduct, { actions, products });
}
