import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { LastLocationContext } from '../contexts/lastLocation';

export function useLastRoute(fallback) {
  function getFallback() {
    return typeof fallback === 'function'
      ? fallback()
      : fallback;
  }

  const current = useLocation();
  const last = useContext(LastLocationContext);
  const [lastRoute, setLastRoute] = useState();

  useEffect(
    function effect() {
      if (!lastRoute && last.pathname !== current.pathname) {
        setLastRoute(last.pathname || getFallback());
      }
    },
    [last],
  );

  return lastRoute;
}
