import { List, Map } from 'immutable';
import { v4 } from 'uuid';

import { formatFileSize, toFixed } from './number';
import { getUploadedVideoError } from './validations/videos';
import { drawImageOnCanvas } from './studio/editor/canvas';
import { getPlanName } from './billing';
import mixpanel from './tracking/mixpanel';
import api from './api/request';

import { EVENT_EDIT_TYPE, EVENT_VIDEO_TYPE, MIME_TO_EXT as VIDEO_MIME_TO_EXT, VIDEO_TYPE } from '../constants/video';
import { MIME as IMAGE_MIME, MIME_TO_EXT as IMAGE_MIME_TO_EXT } from '../constants/photoEditor';
import { ERRORS, VIDEO_DURATION_MINIMUM } from '../constants/validations';
import { ENDPOINT, HEADERS, TARGET_TYPE } from '../constants/api';
import { DEFAULTS, SEPARATOR } from '../constants';
import { OPERATIONS } from '../constants/product';
import { SHOPIFY } from '../constants/channels';
import { EVENT } from '../constants/tracking';

const { EMPTY_LIST, EMPTY_STRING } = DEFAULTS;
const { CONTENT_TYPE, EXPIRES } = HEADERS;

export function getVideoTypeByURL(url) {
  if (!url) {
    return null;
  } else if (/videos\.(getvela|vela-dev|vela-test)\.com/.test(url)) {
    return VIDEO_TYPE.AWS_S3;
  } else if (/cdn\.shopify/.test(url)) {
    return VIDEO_TYPE.SHOPIFY;
  } else if (/(etsy|etsystatic)\.com/.test(url)) {
    return VIDEO_TYPE.ETSY;
  } else if (/(youtube|youtu)\.(com|be)/.test(url)) {
    return VIDEO_TYPE.YOUTUBE;
  } else if (/vimeo\.com/.test(url)) {
    return VIDEO_TYPE.VIMEO;
  } else {
    return null;
  }
}

export function getEmbeddedURL(url) {
  switch (getVideoTypeByURL(url)) {
    case VIDEO_TYPE.YOUTUBE: {
      const videoUrl = new URL(url);
      const embeddedURL = new URL('https://www.youtube.com/embed');

      if (/youtu\.be/.test(videoUrl.host)) {
        embeddedURL.pathname = `${embeddedURL.pathname}/${videoUrl.pathname}`;
      } else if (/youtube\.com/.test(videoUrl.host)) {
        embeddedURL.pathname = `${embeddedURL.pathname}/${videoUrl.searchParams.get('v')}`;
      }

      return embeddedURL.toString();
    }

    case VIDEO_TYPE.VIMEO: {
      const videoUrl = new URL(url);
      const embeddedURL = new URL('https://player.vimeo.com/video');
      const [, id, host] = videoUrl.pathname.split('/');
      embeddedURL.pathname = `${embeddedURL.pathname}/${id}`;

      if (host) {
        embeddedURL.searchParams.set('h', host);
      }

      return embeddedURL.toString();
    }

    default: {
      return null;
    }
  }
}

export function getThumbnailURL(url) {
  switch (getVideoTypeByURL(url)) {
    case VIDEO_TYPE.YOUTUBE: {
      const videoUrl = new URL(url);
      const id = videoUrl.host === 'youtu.be' ? videoUrl.pathname.slice(1) : videoUrl.searchParams.get('v');
      const thumbnailURL = new URL('https://i.ytimg.com');
      thumbnailURL.pathname = `/vi/${id}/hqdefault.jpg`;
      return thumbnailURL.toString();
    }

    case VIDEO_TYPE.VIMEO: {
      const videoUrl = new URL(url);
      const thumbnailURL = new URL('https://vumbnail.com');
      const pathname = videoUrl.pathname.replace(
        /\//g,
        function replaceSlash(substring, index, string) {
          switch (index) {
            case 0: {
              return substring;
            }

            case string.length - 1: {
              return EMPTY_STRING;
            }

            default: {
              return SEPARATOR.COLON;
            }
          }
        }
      );

      thumbnailURL.pathname = `${pathname}.jpg`;
      return thumbnailURL.toString();
    }

    default: {
      return null;
    }
  }
}

export function shapeVideoForApp({ channel, file, signal, validate = true }) {
  return new Promise(function executor(resolve, reject) {
    if (signal.aborted) return;

    const url = URL.createObjectURL(file);
    const { size, type } = file;

    function onValidated() {
      if (signal.aborted) return;

      const videoReader = new FileReader();

      async function onVideoReaderLoad() {
        if (signal.aborted) return;

        const element = document.createElement('video');
        element.addEventListener('error', reject);

        function onPlay() {
          if (signal.aborted) return;

          const { duration, videoHeight: height, videoWidth: width } = element;

          if (duration < VIDEO_DURATION_MINIMUM[channel]) {
            reject(ERRORS.VIDEOS.SMALL_DURATION[channel]);
          }
          if (duration < 5 && channel === SHOPIFY) {
            element.currentTime = 1000;
          } else {
            element.currentTime = 5000;
          }

          element.pause();

          function onBlob(blob) {
            if (signal.aborted) return;

            const thumbnailReader = new FileReader();

            function onThumbnailReaderLoad() {
              const thumbnail = Map({
                data: thumbnailReader.result,
                mime: IMAGE_MIME.PNG,
              });

              const video = Map({
                duration,
                fullsize_url: url,
                mime: type,
                data: videoReader.result,
                key: v4(),
                size,
                thumbnail,
                thumbnail_url: URL.createObjectURL(blob),
              });

              resolve(video);
            }

            thumbnailReader.addEventListener('error', reject);
            thumbnailReader.addEventListener('load', onThumbnailReaderLoad);
            thumbnailReader.readAsArrayBuffer(blob);
          }

          drawImageOnCanvas(element, 0, 0, width, height).getBlob().then(onBlob).catch(reject);
        }

        element.muted = true;
        element.playsInline = true;
        element.src = url;
        element.play().then(onPlay).catch(reject);
      }

      videoReader.addEventListener('error', reject);
      videoReader.addEventListener('load', onVideoReaderLoad);
      videoReader.readAsArrayBuffer(file);
    }

    if (validate) {
      getUploadedVideoError({ channel, file, url }).then(onValidated).catch(reject);
    } else {
      onValidated();
    }
  });
}

export function videosFromProduct({ videos, productId }) {
  function findItem(item) {
    return item.listing_id === productId;
  }

  const item = videos.find(findItem);

  return item
    ? List(item.videos.map(Map))
    : EMPTY_LIST;
}

export function trackVideoEvent({ channel, editType, operation, shopId, user, video }) {
  const plan = getPlanName(user.getIn(['subscriptions', shopId]));

  function getEventProperties() {
    const properties = {
      plan,
      channel,
      edit_type: editType,
    };

    if (video) {
      const videoType = getVideoTypeByURL(video.get('fullsize_url'));

      if (videoType === VIDEO_TYPE.VIMEO || videoType === VIDEO_TYPE.YOUTUBE) {
        properties.type = EVENT_VIDEO_TYPE.URL;
      } else {
        properties.type = EVENT_VIDEO_TYPE.VIDEO;
        properties.video_format = video.get('mime');
        properties.video_size_in_mb = formatFileSize(video.get('size'));
        properties.video_length_in_secods = toFixed(video.get('duration'), 2);
      }
    }

    return properties;
  }

  switch (operation) {
    case OPERATIONS[channel].VIDEOS.ADD: {
      const properties = getEventProperties();
      mixpanel.track(EVENT.VIDEO.ADD, properties);
      break;
    }

    case OPERATIONS[channel].VIDEOS.DELETE: {
      mixpanel.track(EVENT.VIDEO.DELETE, { plan, channel, edit_type: EVENT_EDIT_TYPE.BULK });
      break;
    }

    case OPERATIONS[channel].VIDEOS.REPLACE: {
      const properties = getEventProperties();
      mixpanel.track(EVENT.VIDEO.REPLACE, properties);
      break;
    }

    default: {
      break;
    }
  }
}

export function uploadVideoAndThumbnail({ db, shopId, signal, video, userId }) {
  function uploadData({ url, ...request }) {
    return new Promise(function executor(resolve, reject) {
      if (signal.aborted) return;

      if (!url) {
        resolve();
        return;
      }

      function onUpload() {
        if (signal.aborted) return;

        resolve();
      }

      api
        .put({ ...request, url })
        .then(onUpload)
        .catch(reject);
    });
  }

  return new Promise(function executor(resolve, reject) {
    const key = video.get('key');
    const thumbnail = video.get('thumbnail');

    function onUrls(urls) {
      if (signal.aborted) return;

      function onUpload() {
        if (signal.aborted) return;

        const videoUrl = `${ENDPOINT.AWS_S3_VIDEOS}/${userId}/${db}/${shopId}/${key}.${VIDEO_MIME_TO_EXT[video.get('mime')]}`;
        const thumbnailUrl = `${ENDPOINT.AWS_S3_THUMBNAILS}/${userId}/${db}/${shopId}/${key}.${IMAGE_MIME_TO_EXT[thumbnail.get('mime')]}`;
        resolve(Map({ fullsize_url: videoUrl, thumbnail_url: thumbnailUrl }));
      }

      Promise
        .all([
          uploadData({
            headers: { [CONTENT_TYPE]: video.get('mime'), [EXPIRES]: 0 },
            payload: video.get('data'),
            signal,
            url: urls[0]?.uploadUrl,
          }),
          uploadData({
            headers: { [CONTENT_TYPE]: thumbnail.get('mime'), [EXPIRES]: 0 },
            payload: thumbnail.get('data'),
            signal,
            url: urls[1]?.uploadUrl,
          }),
        ])
        .then(onUpload)
        .catch(reject);
    }

    const videoParams = {
      contentType: video.get('mime'),
      db,
      targetType: TARGET_TYPE.VIDEO,
      uuid: key,
    };

    const thumbnailParams = {
      contentType: video.getIn(['thumbnail', 'mime']),
      db,
      targetType: TARGET_TYPE.THUMBNAIL,
      uuid: key,
    };

    Promise
      .all([
        api.get({ endpoint: ENDPOINT.WEB_V2, params: videoParams, signal, url: `/videos/${shopId}/uploadUrl` }),
        api.get({ endpoint: ENDPOINT.WEB_V2, params: thumbnailParams, signal, url: `/videos/${shopId}/uploadUrl` }),
      ])
      .then(onUrls)
      .catch(reject);
  });
}
