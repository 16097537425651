import { Map } from 'immutable';
import { v4 } from 'uuid';

export function newNotification(notification) {
  return Map({ ...notification, id: v4() });
}

export function notificationExists({ notification, notifications }) {
  function reduceNotifications(result, item) {
    if (result) return result;

    for (const key in notification) {
      if (
        notification.hasOwnProperty(key) &&
        item.get(key) === notification[key] &&
        key !== 'type'
      ) {
        return true;
      }
    }

    return result;
  }

  return notifications.reduce(reduceNotifications, false);
}
