import { Set } from 'immutable';

export function getIndexes({ operationType, operations }) {
  function reduceOperations(result, operation) {
    return operation.get('type') === operationType
      ? result.union(operation.get('indexes'))
      : result;
  }

  return operations.reduce(reduceOperations, Set());
}
