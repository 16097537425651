import { Map, Set } from 'immutable';

import { getName, getTopTaxonomyId } from '../taxonomy';
import { sortCaseIgnore } from '../array';
import { shapeNumber } from './listings';
import { isPublished } from './statuses';
import { getSize } from '../iterable/getSize';
import { reduce } from '../iterable/reduce';

import { CHANNEL_FILTERS, FILTER, FILTER_API_KEY, FILTER_NAME, MAX_FILTER_ITEMS, PROFILE_TYPES, STATUS } from '../../constants/listings';
import { DEFAULTS, FALSE, TRUE } from '../../constants';
import { THEME } from '../../constants/theme';

const { EMPTY_LIST, EMPTY_MAP, EMPTY_SET, MINUS_TWO } = DEFAULTS;

export function getDefaultFilters({ channel, filters = EMPTY_MAP }) {
  function reduceChannelFiltersToExpanded(expanded, filter) {
    return filters.hasIn(['expanded', filter])
      ? expanded.add(filter)
      : expanded;
  }

  return Map({
    expanded: CHANNEL_FILTERS[channel].reduce(reduceChannelFiltersToExpanded, EMPTY_SET),
    order: EMPTY_MAP,
    selected: EMPTY_MAP,
  });
}

export function shapeFiltersForApp({ channel, filters, response, shopData, status }) {
  function shapeValues({ filter, type, values = {}}) {
    function addValues(result, item) {
      const { id, ...rest } = item;

      return result
        .update('byId', function updateById(byId) {
          return byId.set(id, Map(rest));
        })
        .update('ids', function updateIds(ids) {
          return ids.push(id);
        });
    }

    function getValues(result, count, value) {
      result.push({ count: shapeNumber(count), id: value, name: value, value });

      return result;
    }

    function sortAlphabetically(x, y) {
      return sortCaseIgnore(x.name, y.name);
    }

    function sortByCount(x, y) {
      return x.count === y.count
        ? sortCaseIgnore(x.name, y.name)
        : y.count - x.count;
    }

    function reduceCategories(result, count, category) {
      const id = getTopTaxonomyId(category);

      if (id === 0) return result;

      const index = result.findIndex(function findSame(item) {
        return item.id === id;
      });

      if (index === -1) {
        result.push({ count: shapeNumber(count), id, name: getName(id), value: Set([category]) });
      } else {
        result[index].count += shapeNumber(count);
        result[index].value = result[index].value.add(category);
      }

      return result;
    }

    function reduceCollections(result, count, id) {
      const name = shopData.getIn(['collections', 'byId', id, 'label']);

      if (name) {
        result.push({ count: shapeNumber(count), id, name, value: id });
      }

      return result;
    }

    function reduceProfiles(result, count, id) {
      if (!shopData.hasIn(['profiles', type, 'byId', id])) return result;

      const name = shopData.getIn(['profiles', type, 'byId', id, 'name']);

      result.push({ count: shapeNumber(count), id, name, value: id });

      return result;
    }

    function reduceSections(result, count, id) {
      const name = shopData.getIn(['sections', 'byId', id]);

      if (name) {
        result.push({ count: shapeNumber(count), id, name, value: id });
      }

      return result;
    }

    const result = Map({ byId: EMPTY_MAP, ids: EMPTY_LIST });

    switch (filter) {
      case FILTER.CATEGORY: {
        if (!getSize(values)) return result;

        return reduce(values, [], reduceCategories).sort(sortByCount).reduce(addValues, result);
      }

      case FILTER.COLLECTIONS: {
        if (!getSize(values)) return result;

        return reduce(values, [], reduceCollections).sort(sortByCount).reduce(addValues, result);
      }

      case FILTER.EXISTING: {
        const options = [
          {
            count: values ? shapeNumber(values[FALSE]) : 0,
            id: FALSE,
            name: 'New listings',
            value: FALSE,
          },
          {
            count: values ? shapeNumber(values[TRUE]) : 0,
            id: TRUE,
            name: 'Existing listings',
            value: TRUE,
          },
        ];

        return options.reduce(addValues, Map({ byId: EMPTY_MAP, ids: EMPTY_LIST }));
      }

      case FILTER.IS_COMPLETE: {
        const options = [
          {
            count: shapeNumber(values[TRUE]),
            id: TRUE,
            indicator: THEME.COLORS.CLOVER,
            name: 'Complete',
            value: TRUE,
          },
          {
            count: shapeNumber(values[FALSE]),
            id: FALSE,
            indicator: THEME.COLORS.ROUGE,
            name: 'Incomplete',
            value: FALSE,
          },
        ];

        return options.reduce(addValues, Map({ byId: EMPTY_MAP, ids: EMPTY_LIST }));
      }

      case FILTER.PROFILE: {
        if (!getSize(values)) return result;

        const { [MINUS_TWO]: count, ...rest } = values;

        if (!getSize(rest)) return result;

        const options = reduce(rest, [], reduceProfiles).sort(sortByCount);

        if (!getSize(options)) return result;

        if (shapeNumber(count)) {
          options.push(
            {
              count: shapeNumber(count),
              id: MINUS_TWO,
              name: `No ${FILTER_NAME[FILTER.PROFILE]}`,
              value: MINUS_TWO,
            },
          );
        }

        return options.reduce(addValues, result);
      }

      case FILTER.SECTION: {
        if (!getSize(values)) return result;

        return reduce(values, [], reduceSections).sort(sortByCount).reduce(addValues, result);
      }

      case FILTER.TAGS: {
        if (!getSize(values)) return result;

        return reduce(values, [], getValues).sort(sortAlphabetically).reduce(addValues, result);
      }

      default: {
        if (!getSize(values)) return result;

        return reduce(values, [], getValues).sort(sortByCount).reduce(addValues, result);
      }
    }
  }

  function reduceChannelFilters(source, filter) {
    let result = source;

    function updateOptions(options) {
      return options.push(filter);
    }

    switch (filter) {
      case FILTER.EXISTING: {
        if (status !== STATUS.UNPUBLISHED) break;

        result = result.setIn(['values', filter], shapeValues({ filter, values: response[FILTER_API_KEY[filter]] }));

        break;
      }

      case FILTER.IS_COMPLETE: {
        if (isPublished(status)) break;

        result = result
          .setIn(['values', filter], shapeValues({ filter, values: response[FILTER_API_KEY[filter]] }))
          .update('options', updateOptions);

        break;
      }

      case FILTER.PROFILE: {
        let values = EMPTY_MAP;

        for (const type of PROFILE_TYPES) {
          const shaped = shapeValues({
            filter: FILTER.PROFILE,
            type,
            values: response[FILTER_API_KEY[FILTER.PROFILE][type]],
          });

          if (!getSize(shaped.get('ids'))) {
            continue;
          }

          values = values.set(type, shaped);
        }

        if (!getSize(values)) {
          return result
            .deleteIn(['expanded', filter])
            .deleteIn(['selected', FILTER.PROFILE_TYPE]);
        }

        result = result
          .setIn(['values', filter], values)
          .update('options', updateOptions)
          .updateIn(['selected', FILTER.PROFILE_TYPE], function updateType(type) {
            function exists(item) {
              return values.has(item);
            }

            return type && exists(type)
              ? type
              : PROFILE_TYPES.find(exists);
          });

        result = result
          .update('expanded', function updateExpanded(expanded = EMPTY_SET) {
            return (
              expanded.has(filter) &&
              getSize(values.getIn([result.getIn(['selected', FILTER.PROFILE_TYPE]), 'ids'])) <= MAX_FILTER_ITEMS
            )
              ? expanded.delete(filter)
              : expanded;
          });

        break;
      }

      default: {
        const values = shapeValues({ filter, values: response[FILTER_API_KEY[filter]] });
        const size = getSize(values.get('ids'));

        if (!size) return result;

        result = result
          .setIn(['values', filter], values)
          .update('expanded', function updateExpanded(expanded = EMPTY_SET) {
            return expanded.has(filter) && size <= MAX_FILTER_ITEMS
              ? expanded.delete(filter)
              : expanded;
          })
          .update('options', updateOptions);

        break;
      }
    }

    return result;
  }

  return CHANNEL_FILTERS[channel].reduce(
    reduceChannelFilters,
    filters.set('options', EMPTY_LIST).set('values', EMPTY_MAP),
  );
}

export function shapeFiltersForAPI({ filters, status }) {
  function reduceFilters(result, value, key) {
    switch (key) {
      case FILTER.EXISTING: {
        if (status === STATUS.UNPUBLISHED) {
          result[FILTER_API_KEY[key]] = value;
        }

        break;
      }

      case FILTER.IS_COMPLETE: {
        if (!isPublished(status) && getSize(value) === 1) {
          result[FILTER_API_KEY[key]] = value.toList().get(0);
        }

        break;
      }

      case FILTER.PROFILE: {
        const type = filters.get(FILTER.PROFILE_TYPE);

        if (type && getSize(value)) {
          result[FILTER_API_KEY[key][type]] = value.toArray();
        }

        break;
      }

      case FILTER.PROFILE_TYPE: {
        break;
      }

      case FILTER.TITLE: {
        if (getSize(value)) {
          result[FILTER_API_KEY[key]] = value;
        }

        break;
      }

      default: {
        if (getSize(value)) {
          result[FILTER_API_KEY[key]] = value.toArray();
        }

        break;
      }
    }

    return result;
  }

  const initial = {
    [FILTER_API_KEY[FILTER.STATUS]]: status,
  };

  return filters.reduce(reduceFilters, initial);
}

export function onlyComplete(filters = EMPTY_MAP) {
  return (
    filters.hasIn([FILTER.IS_COMPLETE, TRUE]) &&
    !filters.hasIn([FILTER.IS_COMPLETE, FALSE])
  );
}
