import { List, Map, Set } from 'immutable';

import { addThousandsSeparator } from '../number';
import { formatDate } from '../time';
import { shapeId } from '../listings/listings';
import { reduce } from '../iterable/reduce';

import { FILTER_NAME, FIELD_TO_MENU_ITEM_MAP, UPDATE } from '../../constants/scheduledUpdates';
import { DEFAULTS, SEPARATOR } from '../../constants';

export function shapeCountsForApp({ counts, menu: initialMenu = Map() }) {
  function reduceCounts(menu, count, status) {
    function updateById(byId = Map()) {
      return byId.set(status,
        Map({
          counts: Map({
            formatted: addThousandsSeparator(count),
            raw: parseInt(count, 10),
          }),
          name: FILTER_NAME[status],
        })
      );
    }

    function updateOptions(options = List()) {
      return status === UPDATE.PENDING
        ? options.unshift(status)
        : options.push(status);
    }

    return menu
      .update('byId', updateById)
      .update('options', updateOptions);
  }

  return reduce(counts, initialMenu, reduceCounts);
}

export function shapeUpdate({ channel, update }) {
  const {
    created_at: created,
    changed_fields: changedFields,
    product_count: count,
    scheduled_at: scheduled,
  } = update;

  function reduceFields(result, field) {
    const key = FIELD_TO_MENU_ITEM_MAP[channel][field];

    if (key && !result.fields.has(key)) {
      result.fields = result.fields.add(key);
      result.sections = result.sections.push(key);
    }

    return result;
  }

  return Map({
    created: Map({
      formatted: formatDate({ options: { dateStyle: 'short' }, date: created }),
      raw: created,
    }),
    products: addThousandsSeparator(count),
    scheduled: Map({
      formatted: formatDate({ options: { dateStyle: 'short', timeStyle: 'short' }, date: scheduled })
        .replace(SEPARATOR.COMMA, DEFAULTS.EMPTY_STRING),
      raw: scheduled,
    }),
    sections: changedFields
      .reduce(reduceFields, { sections: List(), fields: Set() })
      .sections
      .join(SEPARATOR.COMMA_SPACE),
  });
}

export function shapeUpdatesForApp({ channel, data, updates: initialUpdates = Map() }) {
  function reduceUpdates(result, update) {
    const updateId = shapeId(update.id);

    function updateById(byId = Map()) {
      return byId.set(updateId, shapeUpdate({ channel, update }));
    }

    function updateIds(ids = List()) {
      return ids.push(updateId);
    }

    return result
      .update('byId', updateById)
      .update('ids', updateIds);
  }

  return data.reduce(reduceUpdates, initialUpdates);
}
