import request from './request';

import { CHANNEL_ID, ETSY } from '../../constants/channels';
import { ENDPOINT } from '../../constants/api';

export async function getById({ channel, db, payload, shopId, signal }) {
  return new Promise(function executor(resolve, reject) {
    request
      .post({
        url: `/shops/${shopId}/products`,
        payload,
        params: { db },
        signal,
      })
      .then(
        function onProducts(data) {
          if (data.count) {
            request
              .post({
                endpoint: ENDPOINT.WEB_V2,
                params: { db },
                payload: { listing_ids: data.products },
                signal,
                url: `/videos/${channel}/${shopId}/search`,
              })
              .then(
                function onVideos(videos) {
                  data.videos = videos.data;
                  resolve(data);
                },
                reject,
              );
          } else {
            data.videos = [];
            resolve(data);
          }
        },
        reject,
      );
  });
}

export async function copyProducts({ db, payload, shopId }) {
  return request.post({
    url: `/shops/${shopId}/products/copy`,
    payload: payload,
    params: { db },
  });
}

export async function getLinkedProducts({ db, linkId, productId }) {
  return request.get({
    url: `/products/${productId}/mergedProducts/${linkId}`,
    params: { db },
  });
}

export async function bulkEditProducts({ db, operations, params, shopId }) {
  return request.put({
    url: `/shops/${shopId}/products`,
    params: { db, editType: 'bulk', ...params },
    payload: operations,
  });
}

export async function editSingleProduct({ db, operations, shopId, params }) {
  return request.put({
    url: `/shops/${shopId}/products`,
    params: { db, editType: 'single', ...params },
    payload: operations,
  });
}

export async function editLinkedProducts({
  db,
  existingProducts,
  linkId,
  newProducts,
  productsToEdit,
  shopId,
}) {
  return request.post({
    url: `/shop/${shopId}/${linkId ? `editMergedProducts` : `mergeNewProducts`}`,
    params: { db },
    payload: { existingProductsToMerge: existingProducts, newProducts, productsToEdit },
  });
}

export async function getInvalid({ attribute, db, id, shopId, signal }) {
  return request.get({
    params: { attribute, db, id },
    url: `/shops/${shopId}/products/invalid`,
    signal,
  });
}

export async function getInvalidCounts({ db, id, shopId }) {
  return request.get({
    url: `/shops/${shopId}/products/invalid/count`,
    params: { db, id },
  });
}

export async function search({ db, payload, shopId, signal }) {
  return request.put({
    url: `/shops/${shopId}/products/search`,
    params: { db },
    payload,
    signal,
  });
}

export async function deleteProducts({ channel, db, products, shopId }) {
  const operation = { type: 'products.delete', products };

  switch (channel) {
    case ETSY: {
      operation.channelId = CHANNEL_ID[ETSY];
      operation.shopId = shopId;
      operation.value = 'delete';
      break;
    }

    default: {
      break;
    }
  }

  return request.delete({
    params: { db },
    payload: [operation],
    url: `/shops/${shopId}/products`,
  });
}

export async function uploadFiles({ files }) {
  return request.post({
    payload: { files },
    url: '/digital_files/upload',
  });
}

export async function searchByTitle({ channel, db, limit, offset, shopId, signal, title }) {
  return request.get({
    endpoint: ENDPOINT.WEB_V2,
    params: { db, limit, offset, title },
    signal,
    url: `/${channel}/${shopId}/search`,
  });
}

export async function photoOverride({ channel, shopId, listingId, payload }) {
  return request.put({
    endpoint: ENDPOINT.WEB_V2,
    payload: payload,
    url: `/${channel}/${shopId}/listings/${listingId}`,
  });
}

export async function getForSharing({ channel, db, productId, shopId, signal }) {
  return request.get({
    endpoint: ENDPOINT.WEB_V2,
    params: { db },
    signal,
    url: `/social/${channel}/${shopId}/preview/details/${productId}`,
  });
}

export async function shareProduct({ background, db, product, shopId, signal, template, theme }) {
  return request.post({
    endpoint: ENDPOINT.WEB_V2,
    params: { db },
    payload: { product: { ...product, background, theme }, template },
    signal,
    url: `/social/${shopId}/share-product`,
  });
}

export function bulkOverride({ db, listings, shopId, signal }) {
  return request.post({
    endpoint: ENDPOINT.BULK_OVERRIDE,
    params: { db },
    payload: { listing_ids: listings },
    signal,
    url: `/shops/${shopId}/bulk-override`,
  });
}
