import { Map } from 'immutable';

import { reduceShopIdsToChannels, sortShops } from './shapeForApp';
import { getConnectedShopIds } from './getConnectedShopIds';
import { areComplete } from './isComplete';
import { newProduct } from '../new/product';
import { getSize } from '../iterable/getSize';

import { DEFAULTS } from '../../constants';
import { STATUS } from '../../constants/listings';
import { NEW } from '../../constants/product';

export function newEdit({ currentShopId } = {}) {
  const edit = Map({
    channels: Map(),
    isComplete: DEFAULTS.FALSE,
    linkId: DEFAULTS.NULL,
    products: Map(),
    productId: NEW,
    shops: DEFAULTS.EMPTY_LIST,
    title: 'New listing',
  });

  return edit.set('shopId', currentShopId);
}

export function editNewProduct({
  currentShopId,
  edit: source,
  products = Map(),
  shopId,
  userShops,
}) {
  let data = (getSize(source) ? source : newEdit({ currentShopId }));
  const shops = data
    .get('shops')
    .push(shopId)
    .sort(
      sortShops({
        first: data.get('shopId'),
        prioritized: getConnectedShopIds(data.get('products')),
        sorted: userShops.get('options'),
      })
    );

  if (data.has('channels')) {
    data = data.set('channels', shops.reduce(reduceShopIdsToChannels(userShops.get('byId')), Map()));
  }

  data = data
    .set('shops', shops)
    .setIn(['statuses', shopId], STATUS.ACTIVE)
    .setIn(['products', shopId],
      products.get(shopId, newProduct({ channel: userShops.getIn(['byId', shopId, 'channel']) }))
    );

  return data.set('isComplete', areComplete(data.get('products')));
}
