import request from './request';

import { ENDPOINT } from '../../constants/api';
import { STATUSES } from '../../constants/shops';
import { EBAY } from '../../constants/channels';

export default {
  refresh({ channel, db, shopId }) {
    switch (channel) {
      case EBAY: {
        return request.get({
          endpoint: ENDPOINT.WEB_V2,
          url: `/${channel}/${shopId}/refresh`,
        });
      }

      default: {
        return request.get({
          params: {
            db,
            userAction: STATUSES.USER_INITIATED_REFRESH,
          },
          url: `/shops/${shopId}/refresh`,
        });
      }
    }
  },
};
