import { Map } from 'immutable';

import { getActualDate, setDay, setMinutes } from '../time';

import { DEFAULTS, VELA } from '../../constants';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { PRICE_TYPES } from '../../constants/bulkEdit';
import ATTRIBUTES from '../../constants/attributes';
import {
  INDIVIDUAL,
  NEW_PROFILE_ERRORS,
  PROFILE,
  SALE_PROFILE_TAGS_OPERATION,
  SALE_PROFILE_TITLE_OPERATION,
  SALE_PROFILE_DESCRIPTION_OPERATION,
} from '../../constants/profiles';

const { EMPTY_LIST, EMPTY_ORDERED_MAP, EMPTY_STRING, FALSE, TRUE, ZERO } = DEFAULTS;

export function newProfile({ type, channel, forBulk = false, forProduct = false }) {
  switch (type) {
    case PROFILE.LISTINGS: {
      return Map({
        title: EMPTY_STRING,
      });
    }

    case PROFILE.SALES: {
      const today = getActualDate();
      const profile = Map({
        id: 0,
        channel,
        title: EMPTY_STRING,
        startDate: setMinutes(today, today.getMinutes() + 10).getTime(), // now + 10 mins
        endDate: setDay(today, today.getDate() + 7).getTime(), // now + 7 days
        price: 0,
        priceType: PRICE_TYPES[0],
        cents: EMPTY_STRING,
        titleValue: EMPTY_STRING,
        titleOperation: SALE_PROFILE_TITLE_OPERATION.ADD_BEFORE,
        descriptionValue: EMPTY_STRING,
        descriptionOperation: SALE_PROFILE_DESCRIPTION_OPERATION[channel].ADD_BEFORE,
        tagsOperation: SALE_PROFILE_TAGS_OPERATION.ADD,
        tags: EMPTY_ORDERED_MAP,
        newTags: EMPTY_ORDERED_MAP,
        errors: NEW_PROFILE_ERRORS[type],
        invalid: TRUE,
      });

      switch (channel) {
        case SHOPIFY: {
          return profile.set('isConvertPriceToCap', FALSE);
        }

        default: {
          return profile;
        }
      }
    }

    case PROFILE.TAGS: {
      return Map({
        id: 0,
        channel,
        title: EMPTY_STRING,
        errors: NEW_PROFILE_ERRORS[type],
        invalid: FALSE,
        tags: EMPTY_ORDERED_MAP,
      });
    }

    case PROFILE.VARIATIONS: {
      let profile = Map({
        channel,
        errors: NEW_PROFILE_ERRORS[type],
        offerings: EMPTY_LIST,
        variations: EMPTY_LIST,
      });

      if (!forProduct) {
        profile = profile
          .set('id', forBulk ? -1 : 0)
          .set('invalid', channel === ETSY && !forBulk)
          .set('title', EMPTY_STRING);
      }

      switch (channel) {
        case ETSY: {
          return profile
            .set(ATTRIBUTES.CATEGORY, ZERO)
            .set('hasBothIndividual', FALSE)
            .set(INDIVIDUAL.SKU, FALSE)
            .set(INDIVIDUAL.PRICE, FALSE)
            .set(INDIVIDUAL.QUANTITY, FALSE)
            .setIn(['errors', ATTRIBUTES.CATEGORY], !forBulk && !forProduct);
        }

        case SHOPIFY: {
          return profile
            .set(ATTRIBUTES.TRACK_QUANTITY, FALSE)
            .set(INDIVIDUAL.BARCODE, TRUE)
            .set(INDIVIDUAL.PRICE, TRUE)
            .set(INDIVIDUAL.SHIPPING, TRUE)
            .set(INDIVIDUAL.SKU, TRUE)
            .set('photosHidden', forProduct);
        }

        case VELA: {
          return profile.set('photosHidden', true);
        }

        default: {
          return profile;
        }
      }
    }

    default: {
      return null;
    }
  }
}
