export const DATE = 'date';

export const TIME = 'time';

export const SECOND = 1000;

export const MINUTE = 60 * SECOND;

export const HOUR = 60 * MINUTE;

export const DAY = 24 * HOUR;

export const ORDINAL = {
  ST: 'st',
  ND: 'nd',
  RD: 'rd',
  TH: 'th',
};

export const ABBREVIATION = {
  DAY: 'd',
  HOUR: 'h',
  MINUTE: 'm',
  SECOND: 's',
};
