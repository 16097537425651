import ActionConstants, { Actions } from '../constants/actions';

const userConstants = new Actions('User')
  .addAsync('change_theme')
  .addAsync('get_config')
  .addAsync('get_subscriptions')
  .addAsync('get_user_data')
  .addAsync('get_user_profile')
  .add('set_profile_value');

ActionConstants.add(userConstants);

export default userConstants.actionCreators();
