/* eslint-disable no-console */
import { Map, Set } from 'immutable';

import { reduceShopIdsToChannels, shapePersonalizationForApp, shapeProductForApp, sortShops } from '../product/shapeForApp';
import { newDetails, newProduct, newShipping } from '../new/product';
import { getOperationsFromProduct } from '../product/getOperationsFromProduct';
import { getIndividualVariations } from '../variations/getIndividualVariations';
import { shapeNewProductForAPI, shapeOperationsForAPI } from '../product/shapeForAPI';
import { filterOperations } from '../product/operations';
import { applyProfile } from '../product/applyProfile';
import { clearProfile } from '../product/clearProfile';
import { areComplete } from '../product/isComplete';
import { newProfile } from '../new/profile';
import { getSize } from '../iterable/getSize';
import { reduce } from '../iterable/reduce';
import { store } from '../../store';
import api from '../api';

import { FORMS, NEW, OPERATIONS, SECTIONS, VALUE } from '../../constants/product';
import { CHANNEL_ID, ETSY, SHOPIFY } from '../../constants/channels';
import { SEO_URL_HANDLE_ENABLED } from '../../constants/featureFlags';
import { MESSAGE, NOTIFICATION } from '../../constants/notifications';
import { INDIVIDUAL, PROFILE } from '../../constants/profiles';
import { DEFAULTS } from '../../constants';
import { STATUS } from '../../constants/listings';
import {
  BARCODE,
  CAP,
  CATEGORY,
  CHARGE_TAX,
  COLLECTIONS,
  CONTINUE_SELLING,
  COUNTRY_CODE,
  CPI,
  DIGITAL,
  ENABLED,
  HEIGHT,
  HS_CODE,
  INSTRUCTIONS,
  IS_SUPPLY,
  LENGTH,
  MATERIALS,
  META_DESCRIPTION,
  NEW_MATERIALS,
  NEW_TAGS,
  PAGE_TITLE,
  PHYSICAL,
  PRICE,
  PRODUCTION_PARTNERS,
  PRODUCT_TYPE,
  PROFILE_ID,
  QUANTITY,
  SECTION,
  SHOULD_AUTO_RENEW,
  SKU,
  TAGS,
  TAXONOMY_ATTRIBUTES,
  TRACK_QUANTITY,
  UNIT,
  URL_HANDLE,
  VARIATIONS,
  VENDOR,
  WEIGHT,
  WHEN_MADE,
  WHO_MADE,
  WIDTH,
} from '../../constants/attributes';

import Actions from '../../actions';

const { EMPTY_LIST, EMPTY_MAP, EMPTY_ORDERED_MAP, EMPTY_SET, EMPTY_STRING, FALSE, NULL, TRUE, ZERO } = DEFAULTS;

export function filterSectionsForChannels({ channels }) {
  return function filterSections(section) {
    switch (section) {
      case SECTIONS.FILES:
      case SECTIONS.PERSONALIZATION: {
        return channels.has(ETSY);
      }

      case SECTIONS.SEO: {
        return channels.has(SHOPIFY);
      }

      default: {
        return TRUE;
      }
    }
  };
}

export function updateNewListingsProfile({ profile, userShops }) {
  const shopIds = profile.get('shopIds', EMPTY_SET);

  if (!getSize(shopIds)) {
    return profile.delete('data');
  }

  function updateProfileData(data = EMPTY_MAP) {
    function reduceShopIdsToProducts(result, shopId) {
      return result.set(shopId,
        data.hasIn(['products', shopId])
          ? data.getIn(['products', shopId])
          : newProduct({ channel: userShops.getIn(['byId', shopId, 'channel']), emptyValues: true })
            .set('status', STATUS.TEMPLATE)
            .set('errors', EMPTY_MAP)
      );
    }

    const shops = shopIds.toList().sort(sortShops({ sorted: userShops.get('options') }));
    const channels = shops.reduce(reduceShopIdsToChannels(userShops.get('byId')), EMPTY_MAP);
    const products = shops.reduce(reduceShopIdsToProducts, EMPTY_MAP);
    const sections = data
      .get('sections', Set(FORMS))
      .filter(filterSectionsForChannels({ channels: profile.get('channels', EMPTY_SET) }));

    return data
      .set('channels', channels)
      .set('isComplete', areComplete(products))
      .set('products', products)
      .set('productId', NEW)
      .set('sections', sections)
      .set('shops', shops);
  }

  return profile.update('data', updateProfileData);
}

export function excludeSectionsFromTemplate({ excludedSections, template: source }) {
  function reduceSections(template, excluded, section) {
    if (!excluded) return template;

    const channel = template.get('channel');
    const operations = template.get('operations', EMPTY_LIST);

    function filterDetailsOperations(operation) {
      switch (channel) {
        case ETSY: {
          switch (operation.get('type')) {
            case OPERATIONS[ETSY].CATEGORY:
            case OPERATIONS[ETSY].DIGITAL:
            case OPERATIONS[ETSY].IS_SUPPLY:
            case OPERATIONS[ETSY].MATERIALS.ADD:
            case OPERATIONS[ETSY].MATERIALS.DELETE:
            case OPERATIONS[ETSY].PRODUCTION_PARTNERS:
            case OPERATIONS[ETSY].SECTION:
            case OPERATIONS[ETSY].SHOULD_AUTO_RENEW:
            case OPERATIONS[ETSY].TAXONOMY_ATTRIBUTES:
            case OPERATIONS[ETSY].WHEN_MADE:
            case OPERATIONS[ETSY].WHO_MADE: {
              return FALSE;
            }

            default: {
              return TRUE;
            }
          }
        }

        case SHOPIFY: {
          switch (operation.get('type')) {
            case OPERATIONS[SHOPIFY].COLLECTIONS:
            case OPERATIONS[SHOPIFY].PRODUCT_TYPE:
            case OPERATIONS[SHOPIFY].VENDOR: {
              return FALSE;
            }

            default: {
              return TRUE;
            }
          }
        }

        default: {
          return TRUE;
        }
      }
    }

    function filterFilesOperations(operation) {
      switch (channel) {
        case ETSY: {
          switch (operation.get('type')) {
            case OPERATIONS[ETSY].FILES.ADD:
            case OPERATIONS[ETSY].FILES.DELETE:
            case OPERATIONS[ETSY].FILES.REPLACE:
            case OPERATIONS[ETSY].FILES.SWAP: {
              return FALSE;
            }

            default: {
              return TRUE;
            }
          }
        }

        default: {
          return TRUE;
        }
      }
    }

    function filterPersonalizationOperations(operation) {
      switch (channel) {
        case ETSY: {
          switch (operation.get('type')) {
            case OPERATIONS[ETSY].PERSONALIZATION.CHAR_COUNT_MAX_SET:
            case OPERATIONS[ETSY].PERSONALIZATION.INSTRUCTIONS_SET:
            case OPERATIONS[ETSY].PERSONALIZATION.IS_PERSONALIZABLE_SET:
            case OPERATIONS[ETSY].PERSONALIZATION.IS_REQUIRED_SET: {
              return FALSE;
            }

            default: {
              return TRUE;
            }
          }
        }

        default: {
          return TRUE;
        }
      }
    }

    function filterShippingOperations(operation) {
      switch (channel) {
        case ETSY: {
          switch (operation.get('type')) {
            case OPERATIONS[ETSY].SHIPPING_PROFILE:
            case OPERATIONS[ETSY].WEIGHT:
            case OPERATIONS[ETSY].LENGTH:
            case OPERATIONS[ETSY].WIDTH:
            case OPERATIONS[ETSY].HEIGHT: {
              return FALSE;
            }

            default: {
              return TRUE;
            }
          }
        }

        default: {
          return TRUE;
        }
      }
    }

    switch (section) {
      case SECTIONS.PHOTOS: {
        return template
          .set(SECTIONS.PHOTOS, Map({ [VALUE]: EMPTY_LIST }))
          .set('operations', operations.filter(filterOperations(/^photos/, FALSE)));
      }

      case SECTIONS.VIDEOS: {
        return template
          .set(SECTIONS.VIDEOS, Map({ [VALUE]: EMPTY_LIST }))
          .set('operations', operations.filter(filterOperations(/^videos/, FALSE)));
      }

      case SECTIONS.TITLE: {
        return template
          .set(SECTIONS.TITLE, Map({ [VALUE]: EMPTY_STRING }))
          .set('operations',
            operations.filter(filterOperations(OPERATIONS[channel].TITLE, FALSE))
          );
      }

      case SECTIONS.DESCRIPTION: {
        return template
          .set(SECTIONS.DESCRIPTION, Map({ [VALUE]: EMPTY_STRING }))
          .set('operations',
            operations.filter(filterOperations(OPERATIONS[channel].DESCRIPTION, FALSE))
          );
      }

      case SECTIONS.TAGS: {
        return template
          .set(SECTIONS.TAGS, Map({ [VALUE]: EMPTY_ORDERED_MAP }))
          .set('operations', operations.filter(filterOperations(/^tags/, FALSE)));
      }

      case SECTIONS.DETAILS: {
        return template
          .set(SECTIONS.DETAILS, newDetails({ channel }))
          .set('operations', operations.filter(filterOperations(filterDetailsOperations)));
      }

      case SECTIONS.PRICING: {
        switch (channel) {
          case ETSY: {
            const variations = template.getIn([SECTIONS.VARIATIONS, VARIATIONS]);
            const individual = getIndividualVariations({ properties: [INDIVIDUAL.PRICE], variations });

            return getSize(individual[INDIVIDUAL.PRICE])
              ? template
              : template
                .setIn([SECTIONS.INVENTORY, PRICE], Map({ [VALUE]: EMPTY_STRING }))
                .set('operations', operations.filter(filterOperations(OPERATIONS[ETSY].PRICE, false)));
          }

          case SHOPIFY: {
            return getSize(template.getIn([SECTIONS.VARIATIONS, VARIATIONS]))
              ? template
              : template
                .setIn([SECTIONS.INVENTORY, CAP], Map({ [VALUE]: EMPTY_STRING }))
                .setIn([SECTIONS.INVENTORY, CPI], Map({ [VALUE]: EMPTY_STRING }))
                .setIn([SECTIONS.INVENTORY, CHARGE_TAX], Map({ [VALUE]: TRUE }))
                .setIn([SECTIONS.INVENTORY, PRICE], Map({ [VALUE]: EMPTY_STRING }))
                .set('operations', operations.filter(filterOperations(OPERATIONS[SHOPIFY].VARIATIONS, false)));
          }

          default: {
            return template;
          }
        }
      }

      case SECTIONS.SEO: {
        switch (channel) {
          case SHOPIFY: {
            return getSize(template.getIn([SECTIONS.SEO]))
              ? template
              : template
                .setIn([SECTIONS.SEO, PAGE_TITLE], Map({ [VALUE]: EMPTY_STRING }))
                .setIn([SECTIONS.SEO, META_DESCRIPTION], Map({ [VALUE]: EMPTY_STRING }))
                .setIn([SECTIONS.SEO, URL_HANDLE], Map({ [VALUE]: TRUE }))
                .set('operations', operations.filter(filterOperations(OPERATIONS[SHOPIFY].SEO, false)));
          }
          default: {
            return template;
          }
        }
      }

      case SECTIONS.INVENTORY: {
        switch (channel) {
          case ETSY: {
            let updatedProduct = template;
            const variations = template.getIn([SECTIONS.VARIATIONS, VARIATIONS]);
            const individual = getSize(variations)
              ? getIndividualVariations({ properties: [INDIVIDUAL.QUANTITY, INDIVIDUAL.SKU], variations })
              : {};

            if (!getSize(individual[INDIVIDUAL.QUANTITY])) {
              updatedProduct = updatedProduct
                .setIn([SECTIONS.INVENTORY, QUANTITY], Map({ [VALUE]: EMPTY_STRING }))
                .set('operations', operations.filter(filterOperations(OPERATIONS[ETSY].QUANTITY, false)));
            }

            if (!getSize(individual[INDIVIDUAL.SKU])) {
              updatedProduct = updatedProduct
                .setIn([SECTIONS.INVENTORY, SKU], Map({ [VALUE]: EMPTY_STRING }))
                .set('operations', operations.filter(filterOperations(OPERATIONS[ETSY].SKU, false)));
            }

            return updatedProduct;
          }

          case SHOPIFY: {
            return getSize(template.getIn([SECTIONS.VARIATIONS, VARIATIONS]))
              ? template
              : template
                .setIn([SECTIONS.INVENTORY, BARCODE], Map({ [VALUE]: EMPTY_STRING }))
                .setIn([SECTIONS.INVENTORY, QUANTITY], Map({ [VALUE]: EMPTY_STRING }))
                .setIn([SECTIONS.INVENTORY, SKU], Map({ [VALUE]: EMPTY_STRING }))
                .setIn([SECTIONS.INVENTORY, TRACK_QUANTITY], Map({ [VALUE]: TRUE }))
                .setIn([SECTIONS.INVENTORY, CONTINUE_SELLING], Map({ [VALUE]: FALSE }))
                .set('operations', operations.filter(filterOperations(OPERATIONS[SHOPIFY].VARIATIONS, false)));
          }

          default: {
            return template;
          }
        }
      }

      case SECTIONS.VARIATIONS: {
        return template
          .set(SECTIONS.VARIATIONS,
            newProfile({ channel, forProduct: true, type: PROFILE.VARIATIONS })
          )
          .set('operations',
            operations.filter(filterOperations(OPERATIONS[channel].VARIATIONS, FALSE))
          );
      }

      case SECTIONS.PERSONALIZATION: {
        switch (channel) {
          case ETSY: {
            return template
              .set(SECTIONS.PERSONALIZATION, shapePersonalizationForApp({}))
              .set('operations', operations.filter(filterPersonalizationOperations));
          }

          default: {
            return template;
          }
        }
      }

      case SECTIONS.SHIPPING: {
        switch (channel) {
          case ETSY: {
            return template.getIn([SECTIONS.DETAILS, DIGITAL, VALUE])
              ? template
              : template
                .set(SECTIONS.SHIPPING, newShipping({ channel }))
                .set('operations', operations.filter(filterShippingOperations));
          }

          case SHOPIFY: {
            return getSize(template.getIn([SECTIONS.VARIATIONS, VARIATIONS]))
              ? template
              : template
                .setIn([SECTIONS.INVENTORY, COUNTRY_CODE], Map({ [VALUE]: NULL }))
                .setIn([SECTIONS.INVENTORY, HS_CODE], Map({ [VALUE]: NULL }))
                .setIn([SECTIONS.INVENTORY, WEIGHT], Map({ [VALUE]: EMPTY_STRING }))
                .setIn([SECTIONS.INVENTORY, UNIT], Map({ [VALUE]: UNIT }))
                .setIn([SECTIONS.INVENTORY, PHYSICAL], Map({ [VALUE]: FALSE }))
                .set('operations', operations.filter(filterOperations(OPERATIONS[SHOPIFY].VARIATIONS, false)));
          }

          default: {
            return template;
          }
        }
      }
      case SECTIONS.FILES: {
        switch (channel) {
          case ETSY: {
            return template.getIn([SECTIONS.DETAILS, DIGITAL, VALUE])
              ? template
                .set(SECTIONS.FILES, Map({ [VALUE]: EMPTY_LIST }))
                .set('operations', operations.filter(filterFilesOperations))
              : template;
          }

          default: {
            return template;
          }
        }
      }

      default: {
        return template;
      }
    }
  }

  return reduce(excludedSections, source, reduceSections);
}

export function shapeTemplatesForPropagateAPI({ excludedSections, products, userShops }) {
  function reduceProducts(templates, product, shopId) {
    if (!getSize(product.get('operations'))) return templates;

    const template = excludeSectionsFromTemplate({ excludedSections, template: product });
    const operations = template.get('operations');

    if (!getSize(operations)) return templates;

    templates.push({
      channel: template.get('channel'),
      operations: shapeOperationsForAPI({ operations }),
      shopId,
      shopDb: userShops.getIn(['byId', shopId, 'db']),
    });

    return templates;
  }

  return products.reduce(reduceProducts, []);
}

export function shapeTemplatesForAPI({ excludedSections, products, userShops, userId }) {
  function reduceProducts(templates, product, shopId) {
    let template = product;
    const channel = template.get('channel');
    const templateId = template.get('templateId');

    if (templateId) {
      template = template.set('operations',
        getOperationsFromProduct({ channel, product: template })
      );
    }

    templates.push({
      channel_id: CHANNEL_ID[channel],
      operations: shapeNewProductForAPI({
        channel,
        product: excludeSectionsFromTemplate({ excludedSections, template }),
        shopId,
        status: STATUS.TEMPLATE,
        templateId,
        userId,
      }),
      shopId,
      userDb: userShops.getIn(['byId', shopId, 'db']),
    });

    return templates;
  }

  return products.reduce(reduceProducts, []);
}

export function getExcludedSections({ channels, products, sections: source }) {
  const channelForms = FORMS.filter(filterSectionsForChannels({ channels }));
  const sections = source || Set(channelForms);

  function reduceForms(excluded, section) {
    if (!sections.has(section)) {
      excluded[section] = TRUE;
      return excluded;
    }

    function arePhotosEmpty(empty, product) {
      return (
        empty &&
        !getSize(product.getIn([SECTIONS.PHOTOS, VALUE]))
      );
    }

    function isTitleEmpty(empty, product) {
      return (
        empty &&
        !getSize(product.getIn([SECTIONS.TITLE, VALUE]))
      );
    }

    function isDescriptionEmpty(empty, product) {
      return (
        empty &&
        !getSize(product.getIn([SECTIONS.DESCRIPTION, VALUE]))
      );
    }

    function isSeoEmpty(empty, product) {
      return (
        empty &&
        !getSize(product.getIn([SECTIONS.SEO, PAGE_TITLE, VALUE])) &&
        !getSize(product.getIn([SECTIONS.SEO, META_DESCRIPTION, VALUE])) &&
        (
          !SEO_URL_HANDLE_ENABLED ||
          !getSize(product.getIn([SECTIONS.SEO, URL_HANDLE, VALUE]))
        )
      );
    }

    function areTagsEmpty(empty, product) {
      return (
        empty && (
          !product.hasIn([TAGS, 'currentProfile']) &&
          !getSize(product.getIn([SECTIONS.TAGS, VALUE])) &&
          !getSize(product.getIn([SECTIONS.TAGS, NEW_TAGS]))
        )
      );
    }

    function isPricingEmpty(empty, product) {
      if (!empty) return empty;

      switch (product.get('channel')) {
        case ETSY: {
          const variations = product.getIn([SECTIONS.VARIATIONS, VARIATIONS]);
          const individual = getSize(variations)
            ? getIndividualVariations({ properties: [INDIVIDUAL.PRICE], variations })
            : {};

          return (
            !!getSize(individual[INDIVIDUAL.PRICE]) ||
            !getSize(product.getIn([SECTIONS.INVENTORY, PRICE, VALUE]))
          );
        }

        case SHOPIFY: {
          return (
            !!getSize(product.getIn([SECTIONS.VARIATIONS, VARIATIONS])) || (
              !getSize(product.getIn([SECTIONS.INVENTORY, CAP, VALUE])) &&
              !getSize(product.getIn([SECTIONS.INVENTORY, CPI, VALUE])) &&
              !getSize(product.getIn([SECTIONS.INVENTORY, PRICE, VALUE]))
            )
          );
        }

        default: {
          return empty;
        }
      }
    }

    function isInventoryEmpty(empty, product) {
      if (!empty) return empty;

      switch (product.get('channel')) {
        case ETSY: {
          const variations = product.getIn([SECTIONS.VARIATIONS, VARIATIONS]);
          const individual = getSize(variations)
            ? getIndividualVariations({ properties: [INDIVIDUAL.QUANTITY, INDIVIDUAL.SKU], variations })
            : {};

          return (
            (
              !!getSize(individual[INDIVIDUAL.QUANTITY]) ||
              !getSize(product.getIn([SECTIONS.INVENTORY, QUANTITY, VALUE]))
            ) && (
              !!getSize(individual[INDIVIDUAL.SKU]) ||
              !getSize(product.getIn([SECTIONS.INVENTORY, SKU, VALUE]))
            )
          );
        }

        case SHOPIFY: {
          return (
            !!getSize(product.getIn([SECTIONS.VARIATIONS, VARIATIONS])) || (
              !getSize(product.getIn([SECTIONS.INVENTORY, BARCODE, VALUE])) &&
              !getSize(product.getIn([SECTIONS.INVENTORY, QUANTITY, VALUE])) &&
              !getSize(product.getIn([SECTIONS.INVENTORY, SKU, VALUE]))
            )
          );
        }

        default: {
          return empty;
        }
      }
    }

    function areVariationsEmpty(empty, product) {
      if (!empty) return empty;

      switch (product.get('channel')) {
        case ETSY: {
          return (
            product.getIn([SECTIONS.DETAILS, DIGITAL, VALUE]) ||
            !getSize(product.getIn([SECTIONS.VARIATIONS, VARIATIONS]))
          );
        }

        case SHOPIFY: {
          return !getSize(product.getIn([SECTIONS.VARIATIONS, VARIATIONS]));
        }

        default: {
          return empty;
        }
      }
    }

    function isPersonalizationEmpty(empty, product) {
      if (!empty) return empty;

      switch (product.get('channel')) {
        case ETSY: {
          return (
            !product.getIn([SECTIONS.PERSONALIZATION, ENABLED]) ||
            !getSize(product.getIn([SECTIONS.PERSONALIZATION, INSTRUCTIONS, VALUE]))
          );
        }

        case SHOPIFY: {
          return TRUE;
        }

        default: {
          return empty;
        }
      }
    }

    function isShippingEmpty(empty, product) {
      if (!empty) return empty;

      switch (product.get('channel')) {
        case ETSY: {
          return (
            product.getIn([SECTIONS.SHIPPING, PROFILE_ID, VALUE]) === NULL &&
            !getSize(product.getIn([SECTIONS.SHIPPING, WEIGHT, VALUE])) &&
            !getSize(product.getIn([SECTIONS.SHIPPING, LENGTH, VALUE])) &&
            !getSize(product.getIn([SECTIONS.SHIPPING, WIDTH, VALUE])) &&
            !getSize(product.getIn([SECTIONS.SHIPPING, HEIGHT, VALUE]))
          );
        }

        case SHOPIFY: {
          return (
            !!getSize(product.getIn([SECTIONS.VARIATIONS, VARIATIONS])) ||
            !product.getIn([SECTIONS.INVENTORY, PHYSICAL, VALUE]) || (
              !getSize(product.getIn([SECTIONS.INVENTORY, WEIGHT, VALUE])) &&
              product.getIn([SECTIONS.INVENTORY, HS_CODE, VALUE]) === NULL &&
              product.getIn([SECTIONS.INVENTORY, COUNTRY_CODE, VALUE]) === NULL
            )
          );
        }

        default: {
          return empty;
        }
      }
    }

    function areFilesEmpty(empty, product) {
      if (!empty) return empty;

      switch (product.get('channel')) {
        case ETSY: {
          return (
            !product.getIn([SECTIONS.DETAILS, DIGITAL, VALUE]) ||
            !getSize(product.getIn([SECTIONS.FILES, VALUE]))
          );
        }

        case SHOPIFY: {
          return TRUE;
        }

        default: {
          return empty;
        }
      }
    }

    function areDetailsEmpty(empty, product) {
      if (!empty) return empty;

      switch (product.get('channel')) {
        case ETSY: {
          return (
            product.getIn([SECTIONS.DETAILS, CATEGORY, VALUE]) === ZERO &&
            product.getIn([SECTIONS.DETAILS, WHO_MADE, VALUE]) === NULL &&
            product.getIn([SECTIONS.DETAILS, IS_SUPPLY, VALUE]) === NULL &&
            product.getIn([SECTIONS.DETAILS, WHEN_MADE, VALUE]) === NULL &&
            product.getIn([SECTIONS.DETAILS, SECTION, VALUE]) === NULL &&
            !getSize(product.getIn([SECTIONS.DETAILS, TAXONOMY_ATTRIBUTES])) &&
            !getSize(product.getIn([SECTIONS.DETAILS, PRODUCTION_PARTNERS, VALUE])) &&
            !getSize(product.getIn([SECTIONS.DETAILS, MATERIALS, VALUE])) &&
            !getSize(product.getIn([SECTIONS.DETAILS, MATERIALS, NEW_MATERIALS])) &&
            !product.get('operations', EMPTY_LIST).find(filterOperations(OPERATIONS[ETSY].DIGITAL)) && (
              product.getIn([SECTIONS.DETAILS, DIGITAL, VALUE])
                ? areFilesEmpty(empty, product)
                : isShippingEmpty(empty, product)
            ) &&
            !product.get('operations', EMPTY_LIST).find(filterOperations(OPERATIONS[ETSY].SHOULD_AUTO_RENEW)) && (
              !product.getIn([SECTIONS.DETAILS, SHOULD_AUTO_RENEW, VALUE])
            )
          );
        }

        case SHOPIFY: {
          return (
            product.getIn([SECTIONS.DETAILS, VENDOR, VALUE]) === NULL &&
            product.getIn([SECTIONS.DETAILS, PRODUCT_TYPE, VALUE]) === NULL &&
            !getSize(product.getIn([SECTIONS.DETAILS, COLLECTIONS, VALUE]))
          );
        }

        default: {
          return empty;
        }
      }
    }

    function areVideosEmpty(empty, product) {
      return (
        empty &&
        !getSize(product.getIn([SECTIONS.VIDEOS, VALUE]))
      );
    }

    switch (section) {
      case SECTIONS.PHOTOS: {
        excluded[section] = products.reduce(arePhotosEmpty, TRUE);
        break;
      }

      case SECTIONS.TITLE: {
        excluded[section] = products.reduce(isTitleEmpty, TRUE);
        break;
      }

      case SECTIONS.DESCRIPTION: {
        excluded[section] = products.reduce(isDescriptionEmpty, TRUE);
        break;
      }

      case SECTIONS.TAGS: {
        excluded[section] = products.reduce(areTagsEmpty, TRUE);
        break;
      }

      case SECTIONS.DETAILS: {
        excluded[section] = products.reduce(areDetailsEmpty, TRUE);
        break;
      }

      case SECTIONS.PRICING: {
        excluded[section] = products.reduce(isPricingEmpty, TRUE);
        break;
      }

      case SECTIONS.INVENTORY: {
        excluded[section] = products.reduce(isInventoryEmpty, TRUE);
        break;
      }

      case SECTIONS.VARIATIONS: {
        excluded[section] = products.reduce(areVariationsEmpty, TRUE);
        break;
      }

      case SECTIONS.PERSONALIZATION: {
        excluded[section] = products.reduce(isPersonalizationEmpty, TRUE);
        break;
      }

      case SECTIONS.SEO: {
        excluded[section] = products.reduce(isSeoEmpty, TRUE);
        break;
      }

      case SECTIONS.SHIPPING: {
        excluded[section] = products.reduce(isShippingEmpty, TRUE);
        break;
      }

      case SECTIONS.FILES: {
        excluded[section] = products.reduce(areFilesEmpty, TRUE);
        break;
      }

      case SECTIONS.VIDEOS: {
        excluded[section] = products.reduce(areVideosEmpty, TRUE);
        break;
      }

      default: {
        excluded[section] = FALSE;
        break;
      }
    }

    return excluded;
  }
  return channelForms.reduce(reduceForms, {});
}

export function loadTemplates({ controller, dispatch, mounted, profile, profileId }) {
  const { signal } = controller.start();

  function onResponses(responses) {
    if (!mounted.current || signal.aborted) return;

    controller.stop();

    function reduceResponses(result, response, index) {
      if (response.status !== 'fulfilled' || !getSize(response.value.products)) return result;

      const template = profile.getIn(['templates', index]);

      function addShopId(items = EMPTY_LIST) {
        return items.push(template.get('shopId'));
      }

      try {
        const product = shapeProductForApp({
          channel: template.get('channel'),
          data: response.value,
          shopId: template.get('shopId'),
        });

        return result
          .set('isComplete', result.get('isComplete') && product.get('isComplete'))
          .update('shops', addShopId)
          .updateIn(['channels', template.get('channel')], addShopId)
          .setIn(['products', template.get('shopId')],
            product
              .set('productId', NEW)
              .set('templateId', profileId === NEW ? undefined : template.get('productId'))
              .set('status', STATUS.TEMPLATE)
          );
      } catch (e) {
        console.error(e);
        return result;
      }
    }

    const data = responses.reduce(
      reduceResponses,
      Map({
        channels: EMPTY_MAP,
        isComplete: TRUE,
        productId: NEW,
        products: EMPTY_MAP,
        profile: profileId === NEW ? undefined : profileId,
        sections: profile.get('sections'),
        shops: EMPTY_LIST,
      }),
    );

    if (getSize(data.get('products'))) {
      dispatch(Actions.Profiles.editTemplate({ data, profileId }));
    } else {
      dispatch(
        Actions.Notifications.add({
          type: NOTIFICATION.ERROR,
          message: MESSAGE.FAIL.LOAD_DATA,
        })
      );
    }
  }

  const requests = [];

  profile.get('templates').forEach(function forEachTemplate(template) {
    const channel = template.get('channel');
    const db = template.get('db');
    const productId = template.get('productId');
    const shopId = template.get('shopId');

    dispatch(Actions.Data.getAllData(shopId));

    requests.push(
      api.products.getById({ channel, db, payload: { id: [productId] }, shopId, signal }),
    );
  });

  Promise.allSettled(requests).then(onResponses);
}

export function setProductProfile({
  controller,
  data,
  dispatch,
  editData,
  fromVelaForm,
  mounted,
  profileId,
  shopId,
  setModal,
  type,
}) {
  if (controller.isActive()) {
    controller.stop();
  }

  const shop = store.getState().getIn(['shops', 'byId', shopId], EMPTY_MAP);
  const channel = shop.get('channel');
  const db = shop.get('db');

  if (!profileId) {
    const { state } = clearProfile({
      channel,
      forBulk: true,
      fromVelaForm,
      shopId,
      state: data,
      type,
    });

    editData(state);

    return;
  }

  const { signal } = controller.start();

  function onFail(error) {
    if (signal.aborted || !mounted.current) return;

    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_PROFILE,
      })
    );
  }

  function onSuccess(response) {
    if (!mounted.current) return;

    const { confirmation, state } = applyProfile({
      channel,
      data: response,
      fromVelaForm,
      profileId,
      shopId,
      state: data,
      type,
    });

    if (confirmation) {
      setModal({
        product: state.getIn(['products', shopId]),
        shopId,
        type: confirmation,
      });
    } else {
      editData(state);
    }
  }

  api.profiles.getProfile({ db, profileId, shopId, signal, type }).then(onSuccess, onFail);
}

