import { List, Map, Set } from 'immutable';

import { shapeId } from '../listings/listings';
import { getSize } from '../iterable/getSize';
import { reduce } from '../iterable/reduce';

import { ATTRIBUTES_TO_MENU_ITEM_MAP, ATTRIBUTE_TO_MENU_ITEM_MAP, MENU_ITEM } from '../../constants/bulkEdit';
import { DESCRIPTION, TAXONOMY_ATTRIBUTES, WEIGHT } from '../../constants/attributes';
import { ETSY } from '../../constants/channels';

export function shapeErrorCountsForApp({ channel, data }) {
  function reduceErrorCounts(result, value, key) {
    const count = parseInt(value, 10);
    const item = ATTRIBUTES_TO_MENU_ITEM_MAP[channel][key.replace('_error_count', '')];
    return item ? result.set(item, count) : result;
  }

  return reduce(data, Map(), reduceErrorCounts);
}

function getSectionsByErrorKey({ channel, key, value }) {
  switch (key) {
    case DESCRIPTION: {
      return channel === ETSY
        ? MENU_ITEM.DESCRIPTION
        : MENU_ITEM.BODY_HTML;
    }

    case WEIGHT: {
      return channel === ETSY
        ? MENU_ITEM.ITEM_WEIGHT
        : MENU_ITEM.WEIGHT;
    }

    case TAXONOMY_ATTRIBUTES: {
      return value.keySeq().toSet().map(shapeId);
    }

    default: {
      return /^variations/.test(key)
        ? MENU_ITEM.VARIATIONS
        : ATTRIBUTE_TO_MENU_ITEM_MAP[key];
    }
  }
}

function decrementCount(count = 0) {
  return Math.max(count - 1, 0);
}

function incrementCount(count = 0) {
  return count + 1;
}

export function setErrorCounts({ channel, counts, products }) {
  function reduceErrors(result, value, key) {
    const sections = getSectionsByErrorKey({ channel, key, value });
    return sections
      ? Set.isSet(sections)
        ? result.union(sections)
        : result.add(sections)
      : result;
  }

  function reduceErrorKeys(result, key) {
    return result.update(key, incrementCount);
  }

  function reduceProducts(result, product) {
    return product.get('errors')
      .reduce(reduceErrors, Set())
      .reduce(reduceErrorKeys, result);
  }

  return products.get('byId').reduce(reduceProducts, counts);
}

export function updateErrorCounts({ channel, oldProduct, newProduct }) {
  function getSectionsWithErrors(product) {
    function reduceErrors(result, value, key) {
      const sections = getSectionsByErrorKey({ channel, key, value });
      return sections
        ? Set.isSet(sections)
          ? result.union(sections)
          : result.add(sections)
        : result;
    }

    return product.get('errors', Map()).reduce(reduceErrors, Set());
  }

  function reduceSections({ current, old }) {
    return function reduceKeys(result, key) {
      if (old.has(key) && !current.has(key)) {
        result.toDecrement = result.toDecrement.push(key);
      } else if (!old.has(key) && current.has(key)) {
        result.toIncrement = result.toIncrement.push(key);
      }

      return result;
    };
  }

  function incrementKeyCount(result, key) {
    return result.update(key, incrementCount);
  }

  function decrementKeyCount(result, key) {
    return result.update(key, decrementCount);
  }

  return function updateCounts(source = Map()) {
    let counts = source;
    const oldSections = getSectionsWithErrors(oldProduct);
    const currentSections = getSectionsWithErrors(newProduct);
    const { toDecrement, toIncrement } = oldSections.union(currentSections).reduce(
      reduceSections({ current: currentSections, old: oldSections }),
      { toDecrement: List(), toIncrement: List() }
    );

    if (getSize(toDecrement)) {
      counts = toDecrement.reduce(decrementKeyCount, counts);
    }

    if (getSize(toIncrement)) {
      counts = toIncrement.reduce(incrementKeyCount, counts);
    }

    return counts;
  };
}
