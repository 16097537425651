import api from '../api';

export function removeBG({ images, onFail, onSuccess }) {
  function reduceImages(result, image) {
    const url = image.get('url');

    async function callback(resolve) {
      try {
        const processedUrl = await api.images.removeBG({ url });
        onSuccess({
          image,
          processedUrl,
        });
      } catch (error) {
        onFail({
          error,
          image,
          originalUrl: url,
        });
      }

      resolve();
    }

    result.push(new Promise(callback));

    return result;
  }

  return Promise.all(images.reduce(reduceImages, []));
}
