import { List, Map } from 'immutable';

import { isChannelFullySupported, isFeatureEnabled } from './featureFlags';
import { getActualDate, getDateDifference, isAfter } from './time';
import { keysToCamelCase, toLowerCase } from './string';
import { navigation } from './navigation';
import { getSize } from './iterable/getSize';
import { get } from './iterable/get';

import { MESSAGE, NOTIFICATION } from '../constants/notifications';
import { CHANNEL_BY_ID } from '../constants/channels';
import { FEATURES } from '../constants/billing';
import { DEFAULTS } from '../constants';
import { STATUS } from '../constants/listings';
import { MODALS } from '../constants/modal';
import { PAGES } from '../constants/routes';
import {
  CSV_STATUS,
  DAYS_TO_REFRESH,
  POLLING_INTERVAL,
  STATUSES,
  STATUS_TO_INDICATOR,
  SYNC_INDICATOR,
  SYNC_MODAL,
  SYNC_STEPS,
  WAIT_FOR_SYNC,
} from '../constants/shops';

import Actions from '../actions';

const { EMPTY_LIST, EMPTY_MAP } = DEFAULTS;

export function shapeShopsForApp(data) {
  function sortShopIds(x, y) {
    const shopX = data.byId[x];
    const shopY = data.byId[y];
    const shopXChannelId = shopX.channel_id;
    const shopYChannelId = shopY.channel_id;

    if (shopXChannelId !== shopYChannelId) {
      return shopXChannelId - shopYChannelId;
    }

    return toLowerCase(shopX.name) > toLowerCase(shopY.name) ? 1 : -1;
  }

  function reduceShopIds(result, shopId) {
    const { channel_id: channelId, processing_metadata: processingMetadata, ...rest } = data.byId[shopId];
    const channel = CHANNEL_BY_ID[channelId];

    if (!isChannelFullySupported(channel) && isFeatureEnabled({ feature: FEATURES.CANVA, userId: data.userId })) {
      return result;
    }

    result.byId = result.byId.set(shopId, Map({
      ...keysToCamelCase(rest),
      channelId: channelId,
      channel: CHANNEL_BY_ID[channelId],
      processingMetadata: Map(keysToCamelCase(processingMetadata)),
    }));

    result.options = result.options.push(shopId);

    return result;
  }

  return data.options.map(String).sort(sortShopIds).reduce(reduceShopIds, { byId: EMPTY_MAP, options: EMPTY_LIST });
}

export function isImporting(status) {
  return (
    !status ||
    status === STATUSES.INACTIVE_USER ||
    status === STATUSES.INITIAL_SYNC ||
    status === STATUSES.REFRESH_USER ||
    status === STATUSES.REIMPORT_SHOP
  );
}

export function isInvalid(status) {
  switch (status) {
    case STATUSES.INACTIVE_USER:
    case STATUSES.INCOMPLETE:
    case STATUSES.INCOMPLETE_DUPLICATE:
    case STATUSES.INCOMPLETE_TOO_MANY_LISTINGS:
    case STATUSES.NOT_FOUND:
    case STATUSES.SHOP_IMPORT_FAILURE:
    case STATUSES.UNAVAILABLE_SHOP:
    case STATUSES.UNKNOWN_ERROR: {
      return true;
    }

    default: {
      return false;
    }
  }
}

export function isSyncing(status) {
  switch (status) {
    case STATUSES.INCOMPLETE:
    case STATUSES.INCOMPLETE_DUPLICATE:
    case STATUSES.INCOMPLETE_IN_VACATION_MODE:
    case STATUSES.INCOMPLETE_TOO_MANY_LISTINGS:
    case STATUSES.QUOTA_EXCEEDED:
    case STATUSES.SHOP_IMPORT_FAILURE:
    case STATUSES.TOKEN_REJECTED:
    case STATUSES.UNAVAILABLE_FORBIDDEN:
    case STATUSES.UNAVAILABLE_LOCKED:
    case STATUSES.UNAVAILABLE_SHOP:
    case STATUSES.UNAVAILABLE_PAYMENT_REQUIRED:
    case STATUSES.UNKNOWN_ERROR:
    case STATUSES.UPLOAD_COMPLETE:
    case STATUSES.UP_TO_DATE: {
      return false;
    }

    default: {
      return true;
    }
  }
}

export function csvIsInProgress(status) {
  return status === CSV_STATUS.IN_PROGRESS;
}

export function isUpToDate(status) {
  return !isSyncing(status);
}

export function operationsApplied(status) {
  switch (status) {
    case STATUSES.SAVING_COPY_CHANGES:
    case STATUSES.SYNC:
    case STATUSES.SYNC_PENDING: {
      return true;
    }

    default: {
      return isUpToDate(status);
    }
  }
}

export function allShopsAreUpToDate(shopsById) {
  function reduceStatus(result, shop) {
    return result && isUpToDate(shop.get('syncStatus'));
  }

  return shopsById.reduce(reduceStatus, true);
}

export function getIntervalsFromConfig(state) {
  return {
    short: state.getIn(['user', 'config', 'shopsPollingIntervalShort'], POLLING_INTERVAL.SHORT),
    long: state.getIn(['user', 'config', 'shopsPollingIntervalLong'], POLLING_INTERVAL.LONG),
  };
}

export function getPollingInterval({ currentShopId, intervals, state }) {
  function getIntervalForShop(shopId) {
    return (
      isSyncing(state.getIn(['shops', 'byId', shopId, 'syncStatus'])) ||
      csvIsInProgress(state.getIn(['shops', 'importData', shopId, 'status']))
    )
      ? intervals.short
      : intervals.long;
  }

  function reduceShopIdsInEdit(result, shopId) {
    if (result === intervals.short) return result;

    return getIntervalForShop(shopId);
  }

  return getSize(state.getIn(['edit', 'shops']))
    ? state.getIn(['edit', 'shops']).reduce(reduceShopIdsInEdit, intervals.long)
    : getIntervalForShop(currentShopId);
}

export function shopSyncFinished({ current, last, shopId }) {
  if (!last.has(shopId)) return false;

  const currentState = current.getIn([shopId, 'syncStatus']);
  const lastState = last.getIn([shopId, 'syncStatus']);
  const currentTimestamp = current.getIn([shopId, 'lastSyncTimestamp']);
  const lastTimestamp = last.getIn([shopId, 'lastSyncTimestamp'], currentTimestamp);

  return (
    !isSyncing(currentState) && (
      isSyncing(lastState) ||
      isAfter(new Date(currentTimestamp), new Date(lastTimestamp))
    )
  );
}

export function shopSyncStarted({ current, last, shopId }) {
  const currentState = current.getIn([shopId, 'syncStatus']);
  const lastState = last.getIn([shopId, 'syncStatus']);
  return isSyncing(currentState) && (lastState === undefined || !isSyncing(lastState));
}

export function getShopSyncData({ oldData, shop, syncFinished }) {
  const channel = shop.get('channel');
  const syncStatus = shop.get('syncStatus');
  let step = shop.get('syncStatusStep');
  let modal = SYNC_MODAL[syncStatus];
  let indicator = !modal && STATUS_TO_INDICATOR[syncStatus];

  if (syncStatus === STATUSES.APPLYING_OPERATIONS) {
    if (oldData.get('modal')) {
      if (
        oldData.get('modal') === MODALS.SYNC.PUBLISH ||
        oldData.get('modal') === MODALS.SYNC.SCHEDULED_UPDATE
      ) {
        indicator = undefined;
        modal = oldData.get('modal');
        step = get([channel, modal, 0])(SYNC_STEPS);
      }
    } else if (oldData.get('indicator')) {
      if (
        oldData.get('indicator') === SYNC_INDICATOR.SAVE ||
        oldData.get('indicator') === SYNC_INDICATOR.PUBLISH
      ) {
        indicator = oldData.get('indicator');
        step = get([channel, indicator, 0])(SYNC_STEPS);
      }
    }
  }

  if (!modal && !indicator && (oldData.get('indicator') || oldData.get('modal'))) {
    if (syncFinished && oldData.get('indicator') === SYNC_INDICATOR.HIDDEN) return undefined;

    if (!syncFinished || oldData.get('complete')) return oldData;

    let newData = oldData
      .set('complete', true)
      .set('processed', oldData.get('total'))
      .set('progress', 1)
      .set('started', true)
      .set('success', true);

    if (!oldData.get('step')) {
      const steps = List(SYNC_STEPS[channel][(oldData.get('indicator') || oldData.get('modal'))]);
      newData = newData
        .set('step', steps.get(0))
        .set('steps', steps);
    } else {
      const lastStep = oldData.getIn(['steps', getSize(oldData.get('steps')) - 1]);

      if (oldData.get('step') !== lastStep) {
        newData = newData.set('step', lastStep);
      }
    }

    return newData;
  } else if (indicator && oldData.get('indicator') && indicator !== oldData.get('indicator')) {
    indicator = oldData.get('indicator');
  } else if (
    modal &&
    oldData.get('modal') &&
    modal !== oldData.get('modal') &&
    !oldData.get('hidden')
  ) {
    modal = oldData.get('modal');
  }

  function getMetaData() {
    return shop.get('processingMetadata', EMPTY_MAP).merge(oldData.get('metaData', EMPTY_MAP));
  }

  function getCounts({ areSame }) {
    if (oldData.get('step') && oldData.get('step') !== step && !oldData.get('success')) {
      return {
        processed: oldData.get('total'),
        progress: 1,
        success: true,
        started: true,
        step: oldData.get('step'),
        steps: oldData.get('steps'),
        total: oldData.get('total'),
      };
    }

    let processed = Math.max(shop.get('productsProcessedCount') || 0, 0);
    let total = Math.max(shop.get('productsToProcessCount') || 0, 0);

    if (areSame) {
      processed = Math.max(processed, oldData.get('processed') || 0);
      total = Math.max(total, oldData.get('total') || 0);
    }

    const progress = processed && total && (processed / total);
    const started = !!progress || !!total;

    return { processed, progress, started, total };
  }

  if (indicator) {
    if (indicator === SYNC_INDICATOR.HIDDEN) return Map({ indicator });

    const steps = List(SYNC_STEPS[channel][indicator]);
    const areSame = (
      oldData.get('indicator') === indicator &&
      oldData.get('step') === step
    );

    return Map({
      metaData: getMetaData(),
      indicator,
      step,
      steps,
      ...getCounts({ areSame }),
    });
  }

  if (modal) {
    const metaData = getMetaData();
    const steps = List(SYNC_STEPS[channel][modal]);
    const areSame = (
      oldData.get('modal') === modal &&
      oldData.get('step') === step
    );

    return Map({
      metaData,
      modal,
      step,
      steps,
      ...getCounts({ areSame }),
    });
  }

  return undefined;
}

function getShopImportData({ oldData, shop }) {
  const importStatus = shop.getIn(['csvImport', 'status']);

  switch (importStatus) {
    case CSV_STATUS.IN_PROGRESS: {
      const processed = Math.max(shop.getIn(['csvImport', 'processed']) || 0, 0);
      const total = Math.max(shop.getIn(['csvImport', 'total']) || 0, processed);
      const progress = processed && total && (processed / total);
      const started = processed > 0;
      return Map({
        processed,
        progress,
        started,
        status: importStatus,
        total,
      });
    }

    default: {
      if (csvIsInProgress(oldData.get('status')) && !oldData.get('complete')) {
        return oldData
          .set('complete', true)
          .set('processed', oldData.get('total'))
          .set('progress', 1)
          .set('started', true);
      }

      return undefined;
    }
  }
}

export function updateState({ current, shopsById }) {
  return function reduceShopIds({ actions, state: oldState }, shopId) {
    let state = oldState;
    const last = state.getIn(['shops', 'byId'], EMPTY_MAP);

    function reduceShopIdsInEdit(result, item) {
      if (result.reload) return result;

      switch (result.waitForSync) {
        case WAIT_FOR_SYNC.FINISH: {
          result.reload = shopSyncFinished({ current: shopsById, last, shopId: item });
          break;
        }

        default: {
          result.reload = (
            shopSyncStarted({ current: shopsById, last, shopId: item }) ||
            shopSyncFinished({ current: shopsById, last, shopId: item })
          );

          break;
        }
      }

      return result;
    }

    if (
      state.hasIn(['shops', 'hideRateLimitPage', shopId]) &&
      shopsById.getIn([shopId, 'syncStatus']) !== STATUSES.QUOTA_EXCEEDED
    ) {
      state = state.deleteIn(['shops', 'hideRateLimitPage', shopId]);
    }

    // If syncStatus is INACTIVE_USER it means user of this shop has not logged in for 90 days.
    if (shopsById.getIn([shopId, 'syncStatus']) === STATUSES.INACTIVE_USER) {
      if (!state.getIn(['shops', 'refreshing', shopId])) {
        actions.push(Actions.Shops.refreshUserShop(shopId));
      }
    } else if (state.getIn(['shops', 'refreshing', shopId])) {
      state = state.deleteIn(['shops', 'refreshing', shopId]);

      if (!getSize(state.getIn(['shops', 'refreshing']))) {
        state = state.deleteIn(['shops', 'refreshing']);
      }
    }

    const { PAGE } = navigation({ state });
    const syncStarted = shopSyncStarted({ current: shopsById, last, shopId });
    const syncFinished = !syncStarted && shopSyncFinished({ current: shopsById, last, shopId });
    const importFinished = (
      csvIsInProgress(last.getIn([shopId, 'csvImport', 'status'])) &&
      !csvIsInProgress(shopsById.getIn([shopId, 'csvImport', 'status']))
    );

    if (state.getIn(['shops', 'waitForSync'])) {
      if (state.getIn(['bulkEdit', 'shopId']) === current) {
        switch (state.getIn(['shops', 'waitForSync'])) {
          case WAIT_FOR_SYNC.FINISH: {
            if (syncFinished) {
              state = state.deleteIn(['shops', 'waitForSync']);

              actions.push(Actions.BulkEdit.waitForOperations());
            }

            break;
          }

          default: {
            if (syncStarted || syncFinished) {
              state = state.deleteIn(['shops', 'waitForSync']);

              actions.push(Actions.BulkEdit.waitForOperations());
            }

            break;
          }
        }
      } else if (getSize(state.getIn(['edit', 'shops']))) {
        if (
          state
            .getIn(['edit', 'shops'])
            .reduce(reduceShopIdsInEdit, { reload: false, waitForSync: state.getIn(['shops', 'waitForSync']) })
            .reload
        ) {
          state = state.deleteIn(['shops', 'waitForSync']);

          actions.push(Actions.Edit.waitForOperations());
        }
      } else {
        state = state.deleteIn(['shops', 'waitForSync']);
      }
    }

    if (syncFinished) {
      actions.push(Actions.Data.getShopData({ force: true, shopId }));

      switch (PAGE) {
        case PAGES.BULK_EDIT: {
          if (state.getIn(['bulkEdit', 'shopId']) === shopId && state.getIn(['bulkEdit', 'saving'])) {
            actions.push(Actions.BulkEdit.waitForOperations());
          }

          break;
        }

        case PAGES.LISTINGS: {
          if (
            state.getIn(['listings', 'shopId']) === shopId && (
              state.getIn(['shops', 'syncData', shopId, 'modal']) || (
                state.getIn(['shops', 'syncData', shopId, 'indicator']) &&
                state.getIn(['shops', 'syncData', shopId, 'indicator']) !== SYNC_INDICATOR.HIDDEN
              ) ||
              getSize(state.getIn(['listings', 'placeholders', shopId])) ||
              getSize(state.getIn(['listings', 'products', 'new', shopId])) ||
              getSize(state.getIn(['listings', 'products', 'processing', shopId]))
            )
          ) {
            actions.push(Actions.Listings.getFilters());
            actions.push(Actions.Listings.getListings());
            actions.push(Actions.Listings.getStatuses());
          }

          break;
        }

        default: {
          break;
        }
      }
    }

    if (importFinished) {
      if (
        PAGE !== PAGES.LISTINGS ||
        state.getIn(['listings', 'shopId']) !== shopId ||
        state.getIn(['listings', 'status']) !== STATUS.UNPUBLISHED
      ) {
        const success = shopsById.getIn([shopId, 'csvImport', 'status']) !== CSV_STATUS.FAILED;
        actions.push(
          Actions.Notifications.add({
            type: success ? NOTIFICATION.SUCCESS : NOTIFICATION.ERROR,
            message: success ? MESSAGE.SUCCESS.IMPORT_CSV : MESSAGE.FAIL.IMPORT_CSV,
          })
        );
      }

      if (PAGE === PAGES.LISTINGS && state.getIn(['listings', 'shopId']) === shopId) {
        actions.push(Actions.Listings.getStatuses());

        if (state.getIn(['listings', 'status']) === STATUS.UNPUBLISHED) {
          actions.push(Actions.Listings.getFilters());
          actions.push(Actions.Listings.getListings());
        }
      }
    }

    const shop = shopsById.get(shopId);
    const syncStatus = shop.get('syncStatus');
    const syncData = getShopSyncData({
      oldData: state.getIn(['shops', 'syncData', shopId], EMPTY_MAP),
      shop,
      syncFinished,
    });

    state = syncData && (shopId === current || !syncData.get('complete'))
      ? state.setIn(['shops', 'syncData', shopId], syncData)
      : state.deleteIn(['shops', 'syncData', shopId]);

    const importData = getShopImportData({
      importFinished,
      oldData: state.getIn(['shops', 'importData', shopId], EMPTY_MAP),
      shop,
    });

    state = importData && (shopId === current || !importData.get('complete'))
      ? state.setIn(['shops', 'importData', shopId], importData)
      : state.deleteIn(['shops', 'importData', shopId]);

    if (
      (syncFinished || !state.hasIn(['shops', 'refreshRequired', shopId])) &&
      !isInvalid(syncStatus) &&
      !isImporting(syncStatus) &&
      !(
        syncStatus === STATUSES.TOKEN_REJECTED ||
        syncStatus === STATUSES.INCOMPLETE_IN_VACATION_MODE ||
        (
          syncStatus === STATUSES.QUOTA_EXCEEDED &&
          !state.getIn(['shops', 'hideRateLimitPage', shopId])
        )
      )
    ) {
      const syncTimestamp = shop.get('lastSuccessfulSyncAt') || shop.get('lastSyncTimestamp');
      const actualDate = getActualDate();
      const lastSyncDate = new Date(syncTimestamp);
      const { days } = getDateDifference(actualDate, lastSyncDate, false, false);

      if (days >= DAYS_TO_REFRESH) {
        if (!state.getIn(['shops', 'refreshRequired', shopId, 'modal'])) {
          state = state.setIn(['shops', 'refreshRequired', shopId],
            Map({ daysFromSync: days, modal: true })
          );
        }
      } else {
        state = state.setIn(['shops', 'refreshRequired', shopId], Map({ modal: false }));
      }
    }

    return { actions, state };
  };
}
