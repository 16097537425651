/* eslint-disable no-console */
import { sideEffect } from 'redux-side-effects';
import { Map } from 'immutable';

import { shapeSubscriptionsForApp, shapeUserDataForApp } from '../utils/user';
import { getFromLocalStorage, saveToLocalStorage } from '../utils/localStorage';
import amplitude from '../utils/tracking/amplitude';
import mixpanel from '../utils/tracking/mixpanel';
import api from '../utils/api';

import { MESSAGE, NOTIFICATION } from '../constants/notifications';
import { MESSENGER_LAUNCHER_ID } from '../constants';
import { VELA_THEME } from '../constants/theme';
import ACTIONS from '../constants/actions';

import Reducers, { Reducer } from '../classes/Reducer';
import Actions from '../actions';

function* changeTheme(reduction, { theme }) {
  yield sideEffect((dispatch) => {
    const oldTheme = reduction.getIn(['user', VELA_THEME]);
    api.user
      .setProfileValue({ [VELA_THEME]: theme })
      .then(
        () => dispatch(Actions.User.changeThemeSucceeded({ theme })),
        (error) => dispatch(Actions.User.changeThemeFailed({ error, oldTheme })),
      );
  });

  return reduction
    .deleteIn(['user', 'loaded'])
    .setIn(['user', 'loading'], true)
    .setIn(['user', VELA_THEME], theme);
}

function* changeThemeFailed(reduction, { error, oldTheme }) {
  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.CHANGE_THEME,
      })
    );
  });

  return reduction
    .deleteIn(['user', 'loading'])
    .setIn(['user', 'loaded'], true)
    .setIn(['user', VELA_THEME], oldTheme);
}

function* changeThemeSucceeded(reduction, { theme }) {
  yield sideEffect(() => {
    saveToLocalStorage({ [VELA_THEME]: theme });
  });

  return reduction
    .deleteIn(['user', 'loading'])
    .setIn(['user', 'loaded'], true);
}

function* getUserData(reduction, payload = {}) {
  const { code, shopId } = payload;

  yield sideEffect((dispatch) => {
    api.user
      .getUserData()
      .then(
        (data) => dispatch(Actions.User.getUserDataSucceeded({ code, data, shopId })),
        (error) => dispatch(Actions.User.getUserDataFailed(error))
      );
  });

  return reduction.setIn(['user', 'loading'], true);
}

function* getUserDataFailed(reduction) {
  yield sideEffect((dispatch) => {
    dispatch(Actions.User.getConfig());
  });

  return reduction
    .deleteIn(['auth', 'loading'])
    .deleteIn(['user', 'loading'])
    .setIn(['user', 'loaded'], true)
    .setIn(['user', 'loggedIn'], false);
}

function* getUserDataSucceeded(reduction, { code, data, shopId }) {
  if (!reduction.getIn(['user', 'loading'])) return reduction;

  let user = shapeUserDataForApp({
    data,
    user: reduction
      .get('user', Map())
      .delete('loading')
      .set('loaded', true)
      .set('loggedIn', true),
  });

  if (!user.get(VELA_THEME)) {
    const theme = reduction.getIn(['user', VELA_THEME]);
    user = user.set(VELA_THEME, theme);

    yield sideEffect(() => {
      api.user.setProfileValue({ [VELA_THEME]: theme });
    });
  }

  if (getFromLocalStorage({ [VELA_THEME]: undefined })[VELA_THEME] !== user.get(VELA_THEME)) {
    saveToLocalStorage({ [VELA_THEME]: user.get(VELA_THEME) });
  }

  yield sideEffect((dispatch) => {
    if (code) {
      dispatch(Actions.Shops.validateOauthToken(code));
    }

    dispatch(Actions.User.getConfig());
    dispatch(Actions.User.getUserProfile());
    dispatch(Actions.User.getSubscriptions());
    dispatch(
      Actions.Shops.getShops(
        shopId ||
        reduction.getIn(['shops', 'current']) ||
        user.getIn(['lastSeenShop', 'id'])
      )
    );
  });

  return reduction.deleteIn(['auth', 'loading']).set('user', user);
}

function* getConfig(reduction) {
  yield sideEffect((dispatch) => {
    api.user
      .getConfig()
      .then(
        (config) => dispatch(Actions.User.getConfigSucceeded(config)),
        (error) => dispatch(Actions.User.getConfigFailed(error)),
      );
  });

  return reduction;
}

function* getConfigFailed(reduction, error) {
  yield sideEffect(() => {
    console.error(error);
  });

  return reduction;
}

function* getConfigSucceeded(reduction, config) {
  return reduction.setIn(['user', 'config'], Map(config));
}

function* getSubscriptions(reduction) {
  yield sideEffect((dispatch) => {
    api.billing.getSubscriptions()
      .then(
        (response) => dispatch(Actions.User.getSubscriptionsSucceeded(response)),
        (error) => dispatch(Actions.User.getSubscriptionsFailed(error)),
      );
  });

  return reduction;
}

function* getSubscriptionsFailed(reduction, error) {
  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.ERROR,
        keep: true,
      })
    );
  });

  return reduction;
}

function* getSubscriptionsSucceeded(reduction, response) {
  return reduction.setIn(['user', 'subscriptions'], shapeSubscriptionsForApp({ subscriptions: response }));
}

function* getUserProfile(reduction) {
  yield sideEffect((dispatch) => {
    api.user
      .getJWTData()
      .then(
        (response) => dispatch(Actions.User.getUserProfileSucceeded(response)),
        (error) => dispatch(Actions.User.getUserProfileFailed(error)),
      );
  });

  return reduction;
}

function* getUserProfileFailed(reduction, error) {
  console.error(error);
  return reduction;
}

function* getUserProfileSucceeded(reduction, data) {
  const { avatar, email, firstName, lastName, userHash, userId, userName } = data;

  if (email !== reduction.getIn(['user', 'profile', 'email'])) {
    yield sideEffect(() => {
      amplitude.setUserId(email);
      mixpanel.setDistinctId(email);

      if ('Intercom' in window) {
        window.Intercom(
          'update',
          {
            email: userName,
            user_id: userId,
            user_hash: userHash,
            custom_launcher_selector: `#${MESSENGER_LAUNCHER_ID}`,
            hide_default_launcher: true,
            alignment: 'left',
            horizontal_padding: 21,
            vertical_padding: 100,
          }
        );
      }
    });
  }

  return reduction.setIn(['user', 'profile'], Map({ avatar, email, firstName, lastName }));
}

function* setProfileValue(reduction, { name, saveInDB = true, value }) {
  if (typeof value !== 'string') return reduction;

  if (saveInDB) {
    yield sideEffect(() => {
      api.user.setProfileValue({ [name]: value });
    });
  }

  return reduction.setIn(['user', name], value);
}

Reducers.add(
  new Reducer('User')
    .add(ACTIONS.USER.CHANGE_THEME, changeTheme)
    .add(ACTIONS.USER.CHANGE_THEME_FAILED, changeThemeFailed)
    .add(ACTIONS.USER.CHANGE_THEME_SUCCEEDED, changeThemeSucceeded)
    .add(ACTIONS.USER.GET_CONFIG, getConfig)
    .add(ACTIONS.USER.GET_CONFIG_FAILED, getConfigFailed)
    .add(ACTIONS.USER.GET_CONFIG_SUCCEEDED, getConfigSucceeded)
    .add(ACTIONS.USER.GET_SUBSCRIPTIONS, getSubscriptions)
    .add(ACTIONS.USER.GET_SUBSCRIPTIONS_FAILED, getSubscriptionsFailed)
    .add(ACTIONS.USER.GET_SUBSCRIPTIONS_SUCCEEDED, getSubscriptionsSucceeded)
    .add(ACTIONS.USER.GET_USER_DATA, getUserData)
    .add(ACTIONS.USER.GET_USER_DATA_FAILED, getUserDataFailed)
    .add(ACTIONS.USER.GET_USER_DATA_SUCCEEDED, getUserDataSucceeded)
    .add(ACTIONS.USER.GET_USER_PROFILE, getUserProfile)
    .add(ACTIONS.USER.GET_USER_PROFILE_FAILED, getUserProfileFailed)
    .add(ACTIONS.USER.GET_USER_PROFILE_SUCCEEDED, getUserProfileSucceeded)
    .add(ACTIONS.USER.SET_PROFILE_VALUE, setProfileValue)
);
