import { List, Map, OrderedMap, OrderedSet, Set } from 'immutable';

import { CHANNEL_ID, ETSY, SHOPIFY } from './channels';
import {
  CAP,
  CHAR_COUNT_MAX,
  CPI,
  HEIGHT,
  LENGTH,
  PRICE,
  QUANTITY,
  WEIGHT,
  WIDTH,
} from './attributes';

export const TRUE = 'true';

export const FALSE = 'false';

export const SHOPS_POLL_INTERVAL_SHORT = 1000;

export const SHOPS_POLL_INTERVAL_LONG = 60 * 1000;

export const SEARCH_DEBOUNCE_TIMEOUT = 500;

export const MENU_NAVIGATION_DELAY = 500;

export const SHOPS_DROPDOWN_CHANNEL_ORDER = [CHANNEL_ID[ETSY], CHANNEL_ID[SHOPIFY]];

export const THUMBNAIL_SIZE = 53;

export const NONE = 'None';

export const VELA = 'vela';

export const MESSENGER_LAUNCHER_ID = 'intercom-messenger-launcher';

export const SEPARATOR = {
  COLON: ':',
  COMMA: ',',
  COMMA_SPACE: ', ',
  DASH: '—',
  DOT: '.',
  NEW_LINE: '\n',
  HYPHEN: '-',
  HYPHEN_SPACE: ' - ',
  SLASH: '/',
  SPACE: ' ',
  UNDERSCORE: '_',
  VERTICAL_BAR: '|',
};

export const UNITS = ['lb', 'oz', 'kg', 'g'];

export const DEFAULTS = {
  EMPTY_STRING: '',
  EMPTY_LIST: List(),
  EMPTY_MAP: Map(),
  EMPTY_ORDERED_MAP: OrderedMap(),
  EMPTY_ORDERED_SET: OrderedSet(),
  EMPTY_SET: Set(),
  FALSE: false,
  MINUS_ONE: '-1',
  MINUS_TWO: '-2',
  NULL: null,
  ONE: '1',
  ONE_CENT: '0.01',
  TRUE: true,
  UNIT: UNITS[0],
  ZERO: '0',
  ZERO_CENT: '0.00',
};

export const INPUT = {
  TEXT: 'text',
  NUMBER: 'number',
  RANGE: 'range',
};

export const NUMBER_INPUT = {
  CENTS: 'cents',
  FONT_SIZE: 'font_size',
  FRACTION: 'fraction',
  CAP,
  CHAR_COUNT_MAX,
  CPI,
  HEIGHT,
  INTEGER_HEIGHT: 'int_height',
  INTEGER_WIDTH: 'int_width',
  LENGTH,
  PRICE,
  QUANTITY,
  WEIGHT,
  WIDTH,
};
