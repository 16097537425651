import { Map } from 'immutable';

import { getOptionFormatter } from '../taxonomy';
import { getTagError } from '../validations/tags';
import { setProfileErrors } from '../validations/profile';
import { updateOfferings } from '../variations/updateOfferings';
import { reduceErrors } from '../errors';

import { CUSTOM_PROPERTY_IDS } from '../../constants/taxonomy';
import { INDIVIDUAL, PROFILE } from '../../constants/profiles';
import { MAX_NUMBER_OF_TAGS } from '../../constants/validations';
import { DEFAULTS, UNITS } from '../../constants';
import { SECTIONS, VALUE } from '../../constants/product';
import { ETSY, SHOPIFY } from '../../constants/channels';
import {
  BARCODE,
  CAP,
  CATEGORY,
  COUNTRY_CODE,
  CPI,
  HS_CODE,
  PHYSICAL,
  PRICE,
  QUANTITY,
  SKU,
  TRACK_QUANTITY,
  UNIT,
  VARIATIONS,
  VISIBILITY,
  WEIGHT,
} from '../../constants/attributes';

function convertEtsyVariationsToShopify({ product, profile: source }) {
  const emptyValue = product ? DEFAULTS.EMPTY_STRING : DEFAULTS.NULL;
  let profile = source;
  const variations = profile.get(VARIATIONS);

  function reduceVariations(result, variation, variationIndex) {
    result.individualQuantity = result.individualQuantity || variation.get(INDIVIDUAL.QUANTITY);
    result.formatters[variationIndex] = getOptionFormatter(variation.get('scale'));
    return result;
  }

  const { formatters, individualQuantity = false } = variations.reduce(reduceVariations, { formatters: [] });

  function mapVariations(variation, variationIndex) {
    function mapOptions(option, optionIndex) {
      return option
        .delete('valueId')
        .set('id', -1 - optionIndex)
        .set('value', formatters[variationIndex](option.get('value')));
    }

    return variation
      .delete(INDIVIDUAL.PRICE)
      .delete(INDIVIDUAL.QUANTITY)
      .delete(INDIVIDUAL.SKU)
      .delete('property')
      .delete('scale')
      .set('id', -1 - variationIndex)
      .set('options', variation.get('options').map(mapOptions));
  }

  function mapOfferings(offering) {
    function mapOptions(option, optionIndex) {
      return option
        .delete('optionId')
        .delete('variationId')
        .set('name', formatters[optionIndex](option.get('name')));
    }

    return offering
      .delete('visibility')
      .set('id', DEFAULTS.EMPTY_STRING)
      .set(BARCODE, emptyValue)
      .set(CAP, emptyValue)
      .set(COUNTRY_CODE, emptyValue)
      .set(CPI, emptyValue)
      .set(HS_CODE, emptyValue)
      .set(PHYSICAL, DEFAULTS.TRUE)
      .set(PRICE, offering.get(PRICE))
      .set(QUANTITY, offering.get(QUANTITY))
      .set(SKU, offering.get(SKU))
      .set(TRACK_QUANTITY, individualQuantity)
      .set(UNIT, UNITS[0])
      .set(WEIGHT, emptyValue)
      .set('options', offering.get('options').map(mapOptions));
  }

  profile = profile
    .delete(INDIVIDUAL.BOTH_VARIATIONS)
    .delete(INDIVIDUAL.QUANTITY)
    .delete(CATEGORY)
    .delete('errors')
    .set('channel', SHOPIFY)
    .set(TRACK_QUANTITY, individualQuantity)
    .set('offerings', profile.get('offerings').map(mapOfferings))
    .set(VARIATIONS, variations.map(mapVariations));

  profile = setProfileErrors({ type: VARIATIONS, profile });
  return profile.set('invalid', reduceErrors(profile.get('errors')));
}

function convertShopifyVariationsToEtsy({ product, profile: source }) {
  const emptyValue = product ? DEFAULTS.EMPTY_STRING : DEFAULTS.NULL;
  let profile = source;

  function setOfferings(value) {
    return value.set('offerings',
      updateOfferings({ profile: value, variations: value.get(VARIATIONS) })
    );
  }

  function reduceOfferings(result, offering) {
    result.individualPrice = result.individualPrice || offering.get(PRICE) !== emptyValue;
    result.individualQuantity = result.individualQuantity || offering.get(TRACK_QUANTITY);
    result.individualSKU = result.individualSKU || offering.get(SKU) !== emptyValue;
    return result;
  }

  const {
    individualSKU = DEFAULTS.FALSE,
    individualPrice = DEFAULTS.FALSE,
    individualQuantity = DEFAULTS.FALSE,
  } = profile.get('offerings').reduce(reduceOfferings, {});

  function mapVariations(variation, variationIndex) {
    function mapOptions(option, optionIndex) {
      return option
        .set('id', -1 - optionIndex)
        .set('valueId', DEFAULTS.NULL);
    }

    return variation
      .set('id', -1 - variationIndex)
      .set(INDIVIDUAL.SKU, individualSKU)
      .set(INDIVIDUAL.PRICE, individualPrice)
      .set(INDIVIDUAL.QUANTITY, individualQuantity)
      .set('property', CUSTOM_PROPERTY_IDS[variationIndex])
      .set('options', variation.get('options').map(mapOptions));
  }

  const variations = profile.get(VARIATIONS).slice(0, 2).map(mapVariations);

  function mapOfferings(offering) {
    function mapOptions(option, optionIndex) {
      function findOption(variationOption) {
        return variationOption.get('value') === option.get('name');
      }

      const variationId = variations.getIn([optionIndex, 'id']);
      const optionId = variations.getIn([optionIndex, 'options'])
        .find(findOption)
        .get('id');

      return option
        .set('optionId', optionId)
        .set('variationId', variationId);
    }

    const price = individualPrice
      ? offering.get(PRICE)
      : product
        ? product.getIn([SECTIONS.INVENTORY, PRICE, VALUE], emptyValue)
        : emptyValue;

    const quantity = individualQuantity
      ? offering.get(QUANTITY)
      : product
        ? product.getIn([SECTIONS.INVENTORY, QUANTITY, VALUE], emptyValue)
        : emptyValue;

    const sku = individualSKU
      ? offering.get(SKU)
      : product
        ? product.getIn([SECTIONS.INVENTORY, SKU, VALUE], emptyValue)
        : emptyValue;

    return Map({
      id: DEFAULTS.EMPTY_STRING,
      options: offering.get('options').slice(0, 2).map(mapOptions),
      [PRICE]: price,
      [QUANTITY]: quantity,
      [SKU]: sku,
      [VISIBILITY]: DEFAULTS.TRUE,
    });
  }

  const offerings = profile.get('offerings').map(mapOfferings);

  profile = setOfferings(
    profile
      .delete(TRACK_QUANTITY)
      .delete(INDIVIDUAL.BARCODE)
      .delete(INDIVIDUAL.SHIPPING)
      .set(CATEGORY, product ? product.getIn([SECTIONS.DETAILS, CATEGORY, VALUE], DEFAULTS.ZERO) : DEFAULTS.ZERO)
      .set('channel', ETSY)
      .set(INDIVIDUAL.BOTH_VARIATIONS, individualPrice || individualQuantity || individualSKU)
      .set(INDIVIDUAL.PRICE, individualPrice)
      .set(INDIVIDUAL.QUANTITY, individualQuantity)
      .set(INDIVIDUAL.SKU, individualSKU)
      .set('offerings', offerings)
      .set(VARIATIONS, variations)
  );

  profile = setProfileErrors({ type: VARIATIONS, profile });
  return profile.set('invalid', reduceErrors(profile.get('errors')));
}

function convertTagsProfile({ channel, profile }) {
  function filterTags(tag) {
    return !getTagError({ channel, tag });
  }

  const tags = profile.get('tags').filter(filterTags);

  return profile
    .set('tags', tags)
    .set('channel', channel)
    .set('invalid', tags.size > MAX_NUMBER_OF_TAGS[channel]);
}

function convertSalesProfile({ channel, profile }) {
  if (profile.get('channel') === channel) return profile;

  switch (channel) {
    case ETSY: {
      return profile.delete('isConvertPriceToCap', false);
    }

    case SHOPIFY: {
      return profile.set('isConvertPriceToCap', false);
    }

    default:
      return profile;
  }
}

export function convertProfile({ profile, to, type, ...rest }) {
  switch (type) {
    case PROFILE.VARIATIONS:
      switch (profile.get('channel')) {
        case ETSY: {
          switch (to) {
            case SHOPIFY: {
              return convertEtsyVariationsToShopify({ profile, ...rest });
            }

            default: {
              return profile;
            }
          }
        }

        case SHOPIFY: {
          switch (to) {
            case ETSY: {
              return convertShopifyVariationsToEtsy({ profile, ...rest });
            }

            default: {
              return profile;
            }
          }
        }

        default:
          return profile;
      }

    case PROFILE.TAGS: {
      return convertTagsProfile({ channel: to, profile });
    }

    case PROFILE.SALES: {
      return convertSalesProfile({ channel: to, profile });
    }

    default: {
      return profile;
    }
  }
}
