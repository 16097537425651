import { EBAY, ETSY, SHOPIFY } from './channels';

export const CONTENT_TYPE = {
  CSV: 'text/csv',
  JSON: 'application/json',
  TEXT: 'text/plain',
};

export const HEADERS = {
  CONTENT_TYPE: 'Content-Type',
  EXPIRES: 'Expires',
  CONTENT_DISPOSITION: 'Content-Disposition',
};

export const TARGET_TYPE = {
  VIDEO: 'video',
  THUMBNAIL: 'thumbnail',
};

export const STATUS = {
  UNAUTHORIZED: 401,
};

export const ENDPOINT = {
  AUTH: `${process.env.AUTH_API_ENDPOINT}/api/v1`,
  AUTH_V2: `${process.env.AUTH_API_ENDPOINT}/api/v2`,
  ASSETS: process.env.ASSETS_ENDPOINT,
  AWS_S3_FILES: `https://${process.env.AWS_FILES_BUCKET_NAME}`,
  AWS_S3_IMAGES: `https://s3.amazonaws.com/${process.env.AWS_IMAGES_BUCKET_NAME}`,
  AWS_S3_THUMBNAILS: `https://${process.env.AWS_THUMBNAILS_BUCKET_NAME}`,
  AWS_S3_VIDEOS: `https://${process.env.AWS_VIDEOS_BUCKET_NAME}`,
  BULK_OVERRIDE: process.env.BULK_OVERRIDE_ENDPOINT,
  CONNECT_CHANNEL: {
    [EBAY]: `${process.env.WEB_API_ENDPOINT}/api/v2/ebay/getUserAuth`,
    [ETSY]: `${process.env.WEB_API_ENDPOINT}/auth/etsy`,
    [SHOPIFY]: `${process.env.WEB_API_ENDPOINT}/shopifyAppStore`,
  },
  CSV: process.env.CSV_API_ENDPOINT,
  LOGOUT: `${process.env.WEB_API_ENDPOINT}/logout`,
  SCHEDULED_UPDATES: process.env.SCHEDULED_UPDATES_API_ENDPOINT,
  TAXONOMY: `${process.env.TAXONOMY_ENDPOINT}/v1`,
  WEB: `${process.env.WEB_API_ENDPOINT}/api/v1`,
  WEB_V2: `${process.env.WEB_API_ENDPOINT}/api/v2`,
};

export const ERROR_KEYS = [
  'message',
  'error_message',
  'error',
  'reason',
  'data',
];
