import request from './request';

import { ENDPOINT } from '../../constants/api';

export default {
  get({ signal }) {
    return request.get({
      endpoint: ENDPOINT.AUTH_V2,
      signal,
      url: '/channel_waitlist',
    });
  },
  save({ payload, signal }) {
    return request.post({
      endpoint: ENDPOINT.AUTH_V2,
      payload,
      signal,
      url: '/channel_waitlist',
    });
  },
};
