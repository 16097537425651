import { Map } from 'immutable';

import { clearErrors, reduceErrors, removeErrors, setErrors } from '../errors';
import { getWeightError, validateShipping } from '../validations/shipping';
import { validateTaxonomyAttributes } from '../validations/taxonomyAttributes';
import { validatePersonalization } from '../validations/personalization';
import { getIndividualVariations } from '../variations/getIndividualVariations';
import { isUploadFilesSupported } from './checkAttribute';
import { getReturnPolicyError } from '../validations/returnPolicy';
import { getDescriptionError } from '../validations/description';
import { getMaterialsError } from '../validations/materials';
import { setProfileErrors } from '../validations/profile';
import { getPhotosError } from '../validations/photos';
import { getVideosError } from '../validations/videos';
import { getTitleError } from '../validations/title';
import { validateFiles } from '../validations/files';
import { setIsComplete } from './isComplete';
import { getTagsError } from '../validations/tags';
import { getSize } from '../iterable/getSize';
import {
  getBarcodeError,
  getCAPError,
  getCPIError,
  getPriceError,
  getQuantityError,
  getSKUError,
} from '../validations/inventory';
import {
  getCategoryError,
  getIsSupplyError,
  getProductionPartnersError,
  getWhenMadeError,
  getWhoMadeError,
} from '../validations/details';

import { ERROR, OVERSET, PERSONALIZATION_ERRORS, SECTIONS, SHIPPING_ERRORS, VALUE } from '../../constants/product';
import { ERRORS as PROFILE_ERRORS, INDIVIDUAL, INDIVIDUAL_FLAGS } from '../../constants/profiles';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { DEFAULTS } from '../../constants';
import { ERRORS } from '../../constants/validations';
import { STATUS } from '../../constants/listings';
import {
  BARCODE,
  CAP,
  CATEGORY,
  CPI,
  DESCRIPTION,
  DIGITAL,
  FILES,
  HEIGHT,
  IS_SUPPLY,
  LENGTH,
  MATERIALS,
  META_DESCRIPTION,
  PAGE_TITLE,
  PERSONALIZATION,
  PHOTOS,
  PHYSICAL,
  PRICE,
  PRODUCTION_PARTNERS,
  PROFILE_ID,
  QUANTITY,
  RETURN_POLICY,
  SEO,
  SHIPPING,
  SKU,
  TAGS,
  TAXONOMY_ATTRIBUTES,
  TITLE,
  TRACK_QUANTITY,
  URL_HANDLE,
  VARIATIONS, VIDEOS,
  WEIGHT,
  WHEN_MADE,
  WHO_MADE,
  WIDTH,
} from '../../constants/attributes';

const { DETAILS, INVENTORY } = SECTIONS;

export function shouldValidate({ product, type }) {
  if (product.get('status') !== STATUS.TEMPLATE) return true;

  switch (type) {
    case FILES:
    case DESCRIPTION:
    case PHOTOS:
    case VIDEOS:
    case TITLE: {
      return !!getSize(product.getIn([type, VALUE]));
    }

    case PAGE_TITLE:
    case META_DESCRIPTION:
    case URL_HANDLE: {
      return !!getSize(product.getIn([SEO, type, VALUE]));
    }

    case BARCODE:
    case CAP:
    case CPI:
    case QUANTITY:
    case PRICE:
    case SKU:
    case WEIGHT: {
      return !!getSize(product.getIn([INVENTORY, type, VALUE]));
    }

    case CATEGORY: {
      return product.getIn([DETAILS, type, VALUE]) !== DEFAULTS.ZERO;
    }

    case IS_SUPPLY:
    case WHEN_MADE:
    case WHO_MADE: {
      return product.getIn([DETAILS, type, VALUE]) !== DEFAULTS.NULL;
    }

    case PRODUCTION_PARTNERS: {
      return !!getSize(product.getIn([DETAILS, type, VALUE]));
    }

    case RETURN_POLICY:
    case SHIPPING: {
      return false;
    }

    case TAXONOMY_ATTRIBUTES: {
      return product.getIn([DETAILS, CATEGORY, VALUE]) !== DEFAULTS.ZERO;
    }

    default: {
      return true;
    }
  }
}

function updateError({ oversetType, path, product, type, validator }) {
  function getPath(keys) {
    return path
      ? typeof path === 'string'
        ? [path, ...keys]
        : Array.isArray(path)
          ? [...path, ...keys]
          : keys
      : keys;
  }

  if (oversetType && product.getIn(getPath([oversetType, OVERSET]))) {
    return product.getIn(getPath([type, ERROR])) || product.getIn(['errors', type])
      ? clearErrors({ errors: [type], path, product })
      : product;
  }

  const error = validator(product.getIn(getPath([type, VALUE])));

  return error
    ? setErrors({ errors: Map({ [type]: error }), path, product })
    : product.getIn(getPath([type, ERROR])) || product.getIn(['errors', type])
      ? clearErrors({ errors: [type], path, product })
      : product;
}

function updateErrors({ errorsTypes, path, product, validator }) {
  const errors = validator(product);

  return getSize(errors)
    ? setErrors({ errors, path, product })
    : clearErrors({ errors: errorsTypes, path, product });
}

export function setPhotosErrors({ channel, product }) {
  return updateError({
    product,
    type: PHOTOS,
    validator(photos) {
      return shouldValidate({ product, type: PHOTOS }) && getPhotosError({ channel, photos });
    },
  });
}

export function setVideosError({ channel, product }) {
  return updateError({
    product,
    type: VIDEOS,
    validator(videos) {
      return shouldValidate({ product, type: VIDEOS }) && getVideosError({ channel, videos });
    },
  });
}

export function setTitleErrors({ channel, product }) {
  return updateError({
    product,
    type: TITLE,
    validator(value) {
      return shouldValidate({ product, type: TITLE }) && getTitleError({ channel, value });
    },
  });
}

export function setDescriptionErrors({ channel, product }) {
  return updateError({
    product,
    type: DESCRIPTION,
    validator(value) {
      return shouldValidate({ product, type: DESCRIPTION }) && getDescriptionError({ channel, value });
    },
  });
}

export function setWhoMadeErrors({ channel, product }) {
  return updateError({
    path: DETAILS,
    product,
    type: WHO_MADE,
    validator(value) {
      return shouldValidate({ product, type: WHO_MADE }) && getWhoMadeError({ channel, value });
    },
  });
}

export function setIsSupplyErrors({ channel, product }) {
  return updateError({
    path: DETAILS,
    product,
    type: IS_SUPPLY,
    validator(value) {
      return shouldValidate({ product, type: IS_SUPPLY }) && getIsSupplyError({ channel, value });
    },
  });
}

export function setWhenMadeErrors({ channel, product }) {
  return updateError({
    path: DETAILS,
    product,
    type: WHEN_MADE,
    validator(value) {
      return shouldValidate({ product, type: WHEN_MADE }) && (
        getWhenMadeError({ channel, value }) || (
          product.getIn([DETAILS, DIGITAL, VALUE]) &&
          !isUploadFilesSupported({ channel, product }) &&
          !!getSize(product.getIn([FILES, VALUE])) &&
          ERRORS.DETAILS.DIGITAL_MADE_TO_ORDER
        )
      );
    },
  });
}

export function setCategoryErrors({ channel, product }) {
  return updateError({
    path: DETAILS,
    product,
    type: CATEGORY,
    validator(value) {
      return shouldValidate({ product, type: CATEGORY }) && getCategoryError({ channel, value });
    },
  });
}

export function setFilesErrors({ channel, product }) {
  return updateErrors({
    errorsTypes: [FILES],
    product,
    validator(source) {
      return shouldValidate({ product, type: FILES }) && validateFiles({ channel, product: source });
    },
  });
}

export function setShippingErrors({ channel, product }) {
  return updateErrors({
    errorsTypes: SHIPPING_ERRORS,
    path: SHIPPING,
    product,
    validator(source) {
      return source.getIn([DETAILS, DIGITAL, VALUE])
        ? Map()
        : validateShipping({
          allowEmpty: !shouldValidate({ product, type: SHIPPING }) || undefined,
          channel,
          shipping: source.get(SHIPPING),
        });
    },
  });
}

export function setProductionPartnersErrors({ channel, product }) {
  return updateError({
    path: DETAILS,
    product,
    type: PRODUCTION_PARTNERS,
    validator(productionPartners) {
      return (
        shouldValidate({ product, type: PRODUCTION_PARTNERS }) &&
        getProductionPartnersError({
          channel,
          productionPartners,
          isSupply: product.getIn([DETAILS, IS_SUPPLY, VALUE]),
          whoMade: product.getIn([DETAILS, WHO_MADE, VALUE]),
          whenMade: product.getIn([DETAILS, WHEN_MADE, VALUE]),
        })
      );
    },
  });
}

function setPersonalizationErrors({ channel, product }) {
  return updateErrors({
    errorsTypes: PERSONALIZATION_ERRORS,
    path: PERSONALIZATION,
    product,
    validator(source) {
      return shouldValidate({ product, type: PERSONALIZATION })
        ? validatePersonalization({ channel, personalization: source.get(PERSONALIZATION) })
        : Map();
    },
  });
}

export function setTagsErrors({ channel, product }) {
  return updateError({
    product,
    type: TAGS,
    validator(tags) {
      return shouldValidate({ product, type: TAGS }) && getTagsError({ channel, tags });
    },
  });
}

function setMaterialsErrors({ channel, product }) {
  return updateError({
    path: DETAILS,
    product,
    type: MATERIALS,
    validator(materials) {
      return (
        shouldValidate({ product, type: MATERIALS }) &&
        getMaterialsError({ channel, materials })
      );
    },
  });
}

export function setPriceErrors({ channel, product }) {
  return updateError({
    path: INVENTORY,
    product,
    type: PRICE,
    oversetType: PRICE,
    validator(price) {
      return shouldValidate({ product, type: PRICE }) && getPriceError({ channel, price });
    },
  });
}

export function setQuantityErrors({ channel, product }) {
  return updateError({
    path: INVENTORY,
    product,
    type: QUANTITY,
    oversetType: QUANTITY,
    validator(quantity) {
      return (
        shouldValidate({ product, type: QUANTITY }) &&
        (channel !== SHOPIFY || product.getIn([INVENTORY, TRACK_QUANTITY, VALUE])) &&
        getQuantityError({ channel, quantity })
      );
    },
  });
}

export function setSKUErrors({ channel, product }) {
  return updateError({
    path: INVENTORY,
    product,
    type: SKU,
    oversetType: SKU,
    validator(sku) {
      return shouldValidate({ product, type: SKU }) && getSKUError({ channel, sku });
    },
  });
}

export function setBarcodeErrors({ channel, product }) {
  return updateError({
    path: INVENTORY,
    product,
    type: BARCODE,
    oversetType: BARCODE,
    validator(barcode) {
      return shouldValidate({ product, type: BARCODE }) && getBarcodeError({ channel, barcode });
    },
  });
}

export function setCAPErrors({ channel, product }) {
  return updateError({
    path: INVENTORY,
    product,
    type: CAP,
    oversetType: CAP,
    validator(cap) {
      return shouldValidate({ product, type: CAP }) && getCAPError({ channel, cap });
    },
  });
}

export function setCPIErrors({ channel, product }) {
  return updateError({
    path: INVENTORY,
    product,
    type: CPI,
    oversetType: CPI,
    validator(cpi) {
      return shouldValidate({ product, type: CPI }) && getCPIError({ channel, cpi });
    },
  });
}

export function setWeightErrors({ channel, product }) {
  return updateError({
    path: INVENTORY,
    product,
    type: WEIGHT,
    oversetType: PHYSICAL,
    validator(weight) {
      return (
        shouldValidate({ product, type: WEIGHT }) &&
        product.getIn([INVENTORY, PHYSICAL, VALUE]) &&
        getWeightError({ channel, weight })
      );
    },
  });
}

export function setTaxonomyAttributesErrors({ channel, product }) {
  return updateErrors({
    errorsTypes: [TAXONOMY_ATTRIBUTES],
    path: DETAILS,
    product,
    validator(source) {
      return shouldValidate({ product, type: TAXONOMY_ATTRIBUTES })
        ? validateTaxonomyAttributes({ channel, product: source })
        : Map();
    },
  });
}

export function setReturnPolicyErrors({ channel, product }) {
  return updateError({
    path: SHIPPING,
    product,
    type: RETURN_POLICY,
    validator(returnPolicyId) {
      return (
        !product.getIn([DETAILS, DIGITAL, VALUE]) &&
        shouldValidate({ product, type: RETURN_POLICY }) &&
        getReturnPolicyError({ channel, returnPolicyId })
      );
    },
  });
}

export function updateProductErrorsFromVariations(result, errors, type) {
  let product = result;
  const channel = product.get('channel');

  function reduceErrorsFor(name) {
    return function reduce(error, item) {
      return error || item.get(name);
    };
  }

  switch (type) {
    case VARIATIONS: {
      const nameError = errors.reduce(reduceErrorsFor('name'), undefined);
      const optionsError = errors.reduce(reduceErrorsFor('options'), undefined);

      if (nameError) {
        product = product.setIn(['errors', VARIATIONS, 'name'], nameError);
      } else {
        product = product.deleteIn(['errors', VARIATIONS, 'name']);
      }

      if (optionsError) {
        product = product.setIn(['errors', VARIATIONS, 'option'], optionsError);
      } else {
        product = product.deleteIn(['errors', VARIATIONS, 'option']);
      }

      if (channel === ETSY) {
        const scaleError = errors.reduce(reduceErrorsFor('scale'), undefined);

        if (scaleError) {
          product = product.setIn(['errors', VARIATIONS, 'scale'], PROFILE_ERRORS.SCALE);
        } else {
          product = product.deleteIn(['errors', VARIATIONS, 'scale']);
        }
      }

      break;
    }

    case CATEGORY: {
      break;
    }

    default: {
      const error = reduceErrors(errors, false);

      if (error) {
        product = product.setIn(['errors', VARIATIONS, type], error);
      } else {
        product = product.deleteIn(['errors', VARIATIONS, type]);
      }

      break;
    }
  }

  if (product.hasIn(['errors', VARIATIONS]) && !getSize(product.getIn(['errors', VARIATIONS]))) {
    product = product.deleteIn(['errors', VARIATIONS]);
  }

  return product;
}

export function updateProductVariationsErrors({ channel, product: source }) {
  let product = source;

  switch (channel) {
    case ETSY: {
      const individual = product.getIn([DETAILS, DIGITAL, VALUE])
        ? {}
        : getIndividualVariations({
          properties: INDIVIDUAL_FLAGS[channel],
          variations: product.getIn([VARIATIONS, VARIATIONS]),
        });

      product = setPriceErrors({
        channel,
        product: getSize(individual[INDIVIDUAL.PRICE])
          ? product.setIn([INVENTORY, PRICE, OVERSET], true)
          : product.deleteIn([INVENTORY, PRICE, OVERSET]),
      });

      product = setQuantityErrors({
        channel,
        product: getSize(individual[INDIVIDUAL.QUANTITY])
          ? product.setIn([INVENTORY, QUANTITY, OVERSET], true)
          : product.deleteIn([INVENTORY, QUANTITY, OVERSET]),
      });

      product = setSKUErrors({
        channel,
        product: getSize(individual[INDIVIDUAL.SKU])
          ? product.setIn([INVENTORY, SKU, OVERSET], true)
          : product.deleteIn([INVENTORY, SKU, OVERSET]),
      });

      break;
    }

    case SHOPIFY: {
      if (getSize(product.getIn([VARIATIONS, VARIATIONS]))) {
        if (!product.getIn([INVENTORY, BARCODE, OVERSET])) {
          product = setBarcodeErrors({
            channel,
            product: product.setIn([INVENTORY, BARCODE, OVERSET], true),
          });
        } else if (product.getIn([INVENTORY, BARCODE, ERROR]) || product.getIn(['errors', BARCODE])) {
          product = clearErrors({ errors: [BARCODE], path: INVENTORY, product });
        }

        if (!product.getIn([INVENTORY, CAP, OVERSET])) {
          product = setCAPErrors({
            channel,
            product: product.setIn([INVENTORY, CAP, OVERSET], true),
          });
        } else if (product.getIn([INVENTORY, CAP, ERROR]) || product.getIn(['errors', CAP])) {
          product = clearErrors({ errors: [CAP], path: INVENTORY, product });
        }

        if (!product.getIn([INVENTORY, CPI, OVERSET])) {
          product = setCPIErrors({
            channel,
            product: product.setIn([INVENTORY, CPI, OVERSET], true),
          });
        } else if (product.getIn([INVENTORY, CPI, ERROR]) || product.getIn(['errors', CPI])) {
          product = clearErrors({ errors: [CPI], path: INVENTORY, product });
        }

        if (!product.getIn([INVENTORY, PRICE, OVERSET])) {
          product = setPriceErrors({
            channel,
            product: product.setIn([INVENTORY, PRICE, OVERSET], true),
          });
        } else if (product.getIn([INVENTORY, PRICE, ERROR]) || product.getIn(['errors', PRICE])) {
          product = clearErrors({ errors: [PRICE], path: INVENTORY, product });
        }

        if (!product.getIn([INVENTORY, QUANTITY, OVERSET])) {
          product = setQuantityErrors({
            channel,
            product: product.setIn([INVENTORY, QUANTITY, OVERSET], true),
          });
        } else if (product.getIn([INVENTORY, QUANTITY, ERROR]) || product.getIn(['errors', QUANTITY])) {
          product = clearErrors({ errors: [QUANTITY], path: INVENTORY, product });
        }

        if (!product.getIn([INVENTORY, SKU, OVERSET])) {
          product = setSKUErrors({
            channel,
            product: product.setIn([INVENTORY, SKU, OVERSET], true),
          });
        } else if (product.getIn([INVENTORY, SKU, ERROR]) || product.getIn(['errors', SKU])) {
          product = clearErrors({ errors: [SKU], path: INVENTORY, product });
        }

        if (!product.getIn([INVENTORY, PHYSICAL, OVERSET])) {
          product = setWeightErrors({
            channel,
            product: product.setIn([INVENTORY, PHYSICAL, OVERSET], true),
          });
        } else if (product.getIn([INVENTORY, WEIGHT, ERROR]) || product.getIn(['errors', WEIGHT])) {
          product = clearErrors({ errors: [WEIGHT], path: INVENTORY, product });
        }
      } else {
        product = setBarcodeErrors({
          channel,
          product: product.deleteIn([INVENTORY, BARCODE, OVERSET]),
        });

        product = setCAPErrors({
          channel,
          product: product.deleteIn([INVENTORY, CAP, OVERSET]),
        });

        product = setCPIErrors({
          channel,
          product: product.deleteIn([INVENTORY, CPI, OVERSET]),
        });

        product = setPriceErrors({
          channel,
          product: product.deleteIn([INVENTORY, PRICE, OVERSET]),
        });

        product = setQuantityErrors({
          channel,
          product: product.deleteIn([INVENTORY, QUANTITY, OVERSET]),
        });

        product = setSKUErrors({
          channel,
          product: product.deleteIn([INVENTORY, SKU, OVERSET]),
        });

        product = setWeightErrors({
          channel,
          product: product.deleteIn([INVENTORY, PHYSICAL, OVERSET]),
        });
      }

      break;
    }

    default: {
      break;
    }
  }

  return product.has('errors') && getSize(product.getIn([VARIATIONS, 'errors']))
    ? product.getIn([VARIATIONS, 'errors']).reduce(updateProductErrorsFromVariations, product)
    : product;
}

export function setVariationsErrors({ channel, product: source }) {
  let product = source;

  if (product.getIn([DETAILS, DIGITAL, VALUE])) {
    product = product.updateIn([VARIATIONS, 'errors'], removeErrors);
  } else {
    product = product.set(VARIATIONS,
      setProfileErrors({ type: VARIATIONS, profile: product.get(VARIATIONS) })
    );
  }

  return updateProductVariationsErrors({ channel, product, profile: product.get(VARIATIONS) });
}

export function setProductErrors({ product: source }) {
  let product = source.set('errors', Map());
  const channel = product.get('channel');

  if (product.getIn([DETAILS, DIGITAL, VALUE])) {
    product = product.updateIn([VARIATIONS, 'errors'], removeErrors);
  } else {
    product = product.set(VARIATIONS,
      setProfileErrors({
        ignoreCategory: product.get('status') === STATUS.TEMPLATE,
        profile: product.get(VARIATIONS),
        type: VARIATIONS,
      })
    );
  }

  product = product
    .getIn([VARIATIONS, 'errors'])
    .reduce(updateProductErrorsFromVariations, product);

  const errors = product.get('errors', Map());

  switch (channel) {
    case ETSY: {
      if (!errors.get(WHO_MADE)) {
        product = setWhoMadeErrors({ channel, product });
      }

      if (!errors.get(IS_SUPPLY)) {
        product = setIsSupplyErrors({ channel, product });
      }

      if (!errors.get(WHEN_MADE)) {
        product = setWhenMadeErrors({ channel, product });
      }

      if (!errors.get(CATEGORY)) {
        product = setCategoryErrors({ channel, product });
      }

      if (!errors.get(MATERIALS)) {
        product = setMaterialsErrors({ channel, product });
      }

      if (!errors.get(PRODUCTION_PARTNERS)) {
        product = setProductionPartnersErrors({ channel, product });
      }

      product = setPersonalizationErrors({ channel, product });

      product = setTaxonomyAttributesErrors({ channel, product });

      if (product.getIn([DETAILS, DIGITAL, VALUE])) {
        if (!errors.get(FILES)) {
          product = setFilesErrors({ channel, product });
        }
      } else {
        product = setReturnPolicyErrors({ channel, product });

        if (
          !errors.get(PROFILE_ID) ||
          !errors.get(HEIGHT) ||
          !errors.get(LENGTH) ||
          !errors.get(WEIGHT) ||
          !errors.get(WIDTH)
        ) {
          product = setShippingErrors({ channel, product });
        }
      }

      break;
    }

    case SHOPIFY: {
      if (!errors.get(BARCODE)) {
        product = setBarcodeErrors({ channel, product });
      }

      if (!errors.get(CAP)) {
        product = setCAPErrors({ channel, product });
      }

      if (!errors.get(CPI)) {
        product = setCPIErrors({ channel, product });
      }

      break;
    }

    default: {
      break;
    }
  }

  if (!errors.get(DESCRIPTION)) {
    product = setDescriptionErrors({ channel, product });
  }

  if (!errors.get(PHOTOS)) {
    product = setPhotosErrors({ channel, product });
  }

  if (!errors.get(PRICE)) {
    product = setPriceErrors({ channel, product });
  }

  if (!errors.get(QUANTITY)) {
    product = setQuantityErrors({ channel, product });
  }

  if (!errors.get(SKU)) {
    product = setSKUErrors({ channel, product });
  }

  if (!errors.get(TAGS)) {
    product = setTagsErrors({ channel, product });
  }

  if (!errors.get(TITLE)) {
    product = setTitleErrors({ channel, product });
  }

  return setIsComplete(product);
}
