import { createImageElement } from '../../../utils/studio/editor';

import { MIME } from '../../../constants/photoEditor';

export default class OffscreenCanvas {
  constructor(width, height) {
    this.canvas = document.createElement('canvas');
    this.canvas.width = width;
    this.canvas.height = height;
    this.canvas.getBlob = this.getBlob.bind(this);
    this.canvas.getImage = this.getImage.bind(this);
    return this.canvas;
  }

  getBlob(type = MIME.PNG) {
    const canvas = this.canvas;
    return new Promise(function executor(resolve) {
      canvas.toBlob(resolve, type);
    });
  }

  getImage() {
    const canvas = this.canvas;
    return createImageElement({ src: canvas.toDataURL() });
  }
}
