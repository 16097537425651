import { Map, Set } from 'immutable';

import { NEW } from '../../constants/product';

export function getConnectedShopIds(products = Map()) {
  function reduceProducts(connected, product, shopId) {
    return product.get('productId') === NEW
      ? connected
      : connected.add(shopId);
  }

  return products.reduce(reduceProducts, Set());
}
