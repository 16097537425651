import { FALSE, TRUE } from '../constants';

export function isTrue(value) {
  return String(value).toLowerCase() === TRUE;
}

export function isFalse(value) {
  return String(value).toLowerCase() === FALSE;
}

export function isBoolOrBoolString(value) {
  return typeof value === 'boolean' || value === TRUE || value === FALSE;
}

export function toBoolString(value) {
  return isTrue(value) ? TRUE : FALSE;
}

export function fromBoolString(value) {
  return isTrue(value);
}

export function isTruthy(value) {
  return !!value;
}

export function areSame(x, y) {
  return x === y;
}

export function not(value) {
  return !value;
}
