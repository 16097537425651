export const BUTTON = {
  ADJUSTMENTS: 'adjustments',
  ALT_TEXT: 'alt-text',
  BLUR: 'blur',
  CHECK: 'check',
  CLOCK: 'clock',
  CLOSE: 'close',
  COMMUNITY: 'community',
  COPY: 'copy',
  CROP: 'crop',
  DELETE: 'delete',
  DOWNLOAD: 'download',
  EDIT: 'edit',
  ELLIPSIS: 'ellipsis',
  ENHANCE: 'enhance',
  ERASE: 'erase',
  FAIL: 'fail',
  HELP: 'help',
  IMAGE: 'image',
  IMPORT: 'import',
  INFO: 'info',
  ISOLATE: 'isolate',
  LIST: 'list',
  MAGIC_WAND: 'magic-wand',
  MAGIC_WAND_ANIMATION: 'magic-wand-animation',
  MINUS: 'minus',
  OBJECT: 'object',
  PLAY: 'play',
  PLUS: 'plus',
  PROFILES: 'profiles',
  PROMOTE: 'promote',
  PUBLISH: 'publish',
  REFRESH: 'refresh',
  SCHEDULE: 'schedule',
  SEARCH: 'search',
  SHARE: 'share',
  SIDEBAR: 'sidebar',
  SUCCESS: 'success',
  SUPPORT: 'support',
  TEXT: 'text',
  TOOLS: 'tools',
  UNDO: 'undo',
  UPLOAD: 'upload',
};

export const TITLE = {
  [BUTTON.COPY]: 'Copy listings',
  [BUTTON.DELETE]: 'Delete listings',
  [BUTTON.EDIT]: 'Edit listings',
  [BUTTON.PUBLISH]: 'Publish listings',
  [BUTTON.SEARCH]: 'Search listings',
  [BUTTON.SHARE]: 'Share listings',
};
