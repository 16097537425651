/* eslint-disable camelcase, no-console */
import { sideEffect } from 'redux-side-effects';
import { Map } from 'immutable';

import { addChannelData, isChannelDataLoaded, shapeDataForApp } from '../utils/data';
import { isChannelFullySupported } from '../utils/featureFlags';
import { sortCaseIgnoreWithNone } from '../utils/array';
import { getSize } from '../utils/iterable/getSize';
import api from '../utils/api';

import { MESSAGE, NOTIFICATION } from '../constants/notifications';
import { CHANNEL_DATA_KEYS } from '../constants/data';
import { ETSY, SHOPIFY } from '../constants/channels';
import { ENDPOINT } from '../constants/api';
import { DEFAULTS } from '../constants';
import { PROFILE } from '../constants/profiles';
import ACTIONS from '../constants/actions';

import Reducers, { Reducer } from '../classes/Reducer';
import Actions from '../actions';

const { EMPTY_LIST, EMPTY_MAP } = DEFAULTS;

function* addProductType(reduction, { shopId, productType }) {
  let productTypes = reduction.getIn(['data', 'shopsData', shopId, 'productTypes'], EMPTY_LIST);

  if (productType && !productTypes.includes(productType)) {
    productTypes = productTypes.push(productType).sort(sortCaseIgnoreWithNone);
  }

  return reduction.setIn(['data', 'shopsData', shopId, 'productTypes'], productTypes);
}

function* addSection(reduction, { shopId, section }) {
  let sections = reduction.getIn(['data', 'shopsData', shopId, 'sections'], EMPTY_MAP);

  function updateById(byId = EMPTY_MAP) {
    return byId.set(section, section);
  }

  function updateOptions(options = EMPTY_LIST) {
    return options.push(section);
  }

  function sortSections(sectionsById) {
    return function sort(x, y) {
      return sortCaseIgnoreWithNone(sectionsById.get(x), sectionsById.get(y));
    };
  }

  if (!sections.hasIn(['byId', section])) {
    const byId = sections.get('byId').update(updateById);
    const options = sections.get('options').update(updateOptions).sort(sortSections(byId));
    sections = sections.set('byId', byId).set('options', options);
  }

  return reduction.setIn(['data', 'shopsData', shopId, 'sections'], sections);
}

function* addVendor(reduction, { shopId, vendor }) {
  let vendors = reduction.getIn(['data', 'shopsData', shopId, 'vendors'], EMPTY_LIST);

  if (vendor && !vendors.includes(vendor)) {
    vendors = vendors.push(vendor).sort(sortCaseIgnoreWithNone);
  }

  return reduction.setIn(['data', 'shopsData', shopId, 'vendors'], vendors);
}

function* getAllData(reduction, shopId) {
  yield sideEffect((dispatch) => {
    if (!reduction.hasIn(['shops', 'byId', shopId])) return;

    const channel = reduction.getIn(['shops', 'byId', shopId, 'channel']);

    if (!isChannelFullySupported(channel)) return;

    if (
      !reduction.getIn(['data', 'loading', 'channel', channel]) &&
      !reduction.getIn(['data', 'loaded', 'channel', channel])
    ) {
      dispatch(Actions.Data.getChannelData({ channel }));
    }

    if (
      !reduction.getIn(['data', 'loaded', 'shop', shopId]) &&
      !reduction.getIn(['data', 'loading', 'shop', shopId])
    ) {
      dispatch(Actions.Data.getShopData({ shopId }));
    }

    if (
      !reduction.getIn(['data', 'loaded', 'profiles', shopId, PROFILE.TAGS]) &&
      !reduction.getIn(['data', 'loading', 'profiles', shopId, PROFILE.TAGS])
    ) {
      dispatch(Actions.Data.getProfiles({ shopId, type: PROFILE.TAGS }));
    }

    if (
      !reduction.getIn(['data', 'loaded', 'profiles', shopId, PROFILE.VARIATIONS]) &&
      !reduction.getIn(['data', 'loading', 'profiles', shopId, PROFILE.VARIATIONS])
    ) {
      dispatch(Actions.Data.getProfiles({ shopId, type: PROFILE.VARIATIONS }));
    }
  });

  return reduction;
}

function* getChannelData(reduction, { channel }) {
  if (
    reduction.getIn(['data', 'loaded', 'channel', channel]) ||
    reduction.getIn(['data', 'loading', 'channel', channel])
  ) {
    return reduction;
  }

  if (!CHANNEL_DATA_KEYS.hasOwnProperty(channel)) {
    return reduction.setIn(['data', 'loaded', 'channel', channel]);
  }

  yield sideEffect((dispatch) => {
    function processCountries(data) {
      function addCountry(result, { code, name }) {
        result.map[code] = name;
        result.list.push({ code, name });
        return result;
      }

      const { map, list } = data.reduce(addCountry, { map: {}, list: [] });
      dispatch(Actions.Data.getChannelDataSucceeded({ channel, data: list, key: 'COUNTRIES_LIST' }));
      dispatch(Actions.Data.getChannelDataSucceeded({ channel, data: map, key: 'COUNTRIES_MAP' }));
    }

    switch (channel) {
      case ETSY: {
        for (const key of CHANNEL_DATA_KEYS[channel]) {
          api
            .get({ endpoint: ENDPOINT.TAXONOMY, url: `/${key}.json` })
            .then(
              (data) => dispatch(Actions.Data.getChannelDataSucceeded({ channel, data, key })),
              (error) => dispatch(Actions.Data.getChannelDataFailed({ error, channel })),
            );
        }

        break;
      }

      case SHOPIFY: {
        api
          .get({ url: '/countries' })
          .then(
            processCountries,
            (error) => dispatch(Actions.Data.getChannelDataFailed({ channel, error })),
          );

        break;
      }

      default: {
        break;
      }
    }
  });

  return reduction.setIn(['data', 'loading', 'channel', channel], true);
}

function* getChannelDataFailed(reduction, { channel, error }) {
  yield sideEffect((dispatch) => {
    console.error('Error encountered while getting channel data: ', error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_DATA,
      })
    );
  });

  return reduction.deleteIn(['data', 'loading', 'channel', channel]);
}

function* getChannelDataSucceeded(reduction, { channel, data, key }) {
  addChannelData({ data, key });

  return isChannelDataLoaded(channel)
    ? reduction
      .deleteIn(['data', 'loading', 'channel', channel])
      .setIn(['data', 'loaded', 'channel', channel], true)
    : reduction;
}

function* getProfiles(reduction, { force = false, shopId, type }) {
  if (
    reduction.getIn(['data', 'loading', 'profiles', shopId, type]) ||
    !reduction.hasIn(['shops', 'byId', shopId]) || (
      !force && reduction.getIn(['data', 'loaded', 'profiles', shopId, type])
    )
  ) {
    return reduction;
  }

  yield sideEffect(async (dispatch) => {
    const db = reduction.getIn(['shops', 'byId', shopId, 'db']);

    switch (type) {
      case PROFILE.LISTINGS: {
        try {
          const totals = await api.profiles.totals({ db, shopId, type });

          const profiles = api.profiles.get({
            db,
            limit: totals.count,
            offset: 0,
            shopId,
            type,
          });

          profiles.then(
            (profileList) => dispatch(
              Actions.Data.getProfilesSucceeded({
                data: { profiles: profileList.data },
                shopId,
                type,
              })
            ),
            (error) => dispatch(Actions.Data.getProfilesFailed({ error, shopId, type })),
          );
        } catch (error) {
          dispatch(Actions.Data.getProfilesFailed({ error, shopId, type }));
        }

        break;
      }

      default: {
        api.profiles
          .getLookup({ params: { db }, shopId, type })
          .then(
            (data) => dispatch(Actions.Data.getProfilesSucceeded({ data, shopId, type })),
            (error) => dispatch(Actions.Data.getProfilesFailed({ error, shopId, type }))
          );
        break;
      }
    }
  });

  return reduction.setIn(['data', 'loading', 'profiles', shopId, type], true);
}

function* getProfilesFailed(reduction, { error, shopId, type }) {
  yield sideEffect((dispatch) => {
    console.error('Error encountered while getting profiles lookup: ', error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_PROFILES,
      })
    );
  });

  return reduction.deleteIn(['data', 'loading', 'profiles', shopId, type]);
}

function* getProfilesSucceeded(reduction, { data, shopId, type }) {
  if (!data?.profiles) {
    yield sideEffect((dispatch) => {
      dispatch(Actions.Data.getProfilesFailed(data));
    });

    return reduction;
  }

  const profiles = shapeDataForApp.profiles({ profiles: data.profiles, type, userShops: reduction.get('shops') });

  return reduction
    .deleteIn(['data', 'loading', 'profiles', shopId, type])
    .setIn(['data', 'loaded', 'profiles', shopId, type], true)
    .setIn(['data', 'shopsData', shopId, 'profiles', type], profiles);
}

function* getShopData(reduction, { force = false, shopId }) {
  if (
    reduction.getIn(['data', 'loading', 'shop', shopId]) ||
    !reduction.hasIn(['shops', 'byId', shopId]) || (
      !force && reduction.getIn(['data', 'loaded', 'shop', shopId])
    )
  ) {
    return reduction;
  }

  const channel = reduction.getIn(['shops', 'byId', shopId, 'channel']);
  const params = {
    db: reduction.getIn(['shops', 'byId', shopId, 'db']),
  };

  if (!isChannelFullySupported(channel)) {
    return reduction
      .setIn(['data', 'loaded', 'shop', shopId], true)
      .setIn(['data', 'shopsData', shopId], EMPTY_MAP);
  }

  yield sideEffect((dispatch) => {
    const requests = [
      api.get({ url: `/shops/${shopId}/channelData`, params }),
    ];

    switch (channel) {
      case ETSY: {
        requests.push(
          api.get({ url: `/shops/${shopId}/productionPartners`, params }),
          api.get({ url: `/shops/${shopId}/returnPolicies`, params }),
          api.get({ url: `/shops/${shopId}/shippingTemplates`, params }),
        );

        break;
      }

      default: {
        break;
      }
    }

    Promise.all(requests)
      .then(
        (responses) => dispatch(Actions.Data.getShopDataSucceeded({ channel, responses, shopId })),
        (error) => dispatch(Actions.Data.getShopDataFailed({ error, shopId }))
      );
  });

  return reduction.setIn(['data', 'loading', 'shop', shopId], true);
}

function* getShopDataFailed(reduction, { error, shopId }) {
  yield sideEffect((dispatch) => {
    console.error('Error encountered while getting shop data: ', error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_DATA,
      })
    );
  });

  return reduction.deleteIn(['data', 'loading', 'shop', shopId], true);
}

function* getShopDataSucceeded(reduction, { channel, responses, shopId }) {
  function updateShopData(source = EMPTY_MAP) {
    const [shopData] = responses;

    let data = Map({ ...shapeDataForApp.shopData({ channel, shopData }), profiles: source.get('profiles', EMPTY_MAP) });

    switch (channel) {
      case ETSY: {
        const [
          ,
          { productionPartners } = {},
          { returnPolicies } = {},
          { shippingTemplates } = {},
        ] = responses;

        data = data
          .set('productionPartners', shapeDataForApp.productionPartners(productionPartners))
          .set('returnPolicies', shapeDataForApp.returnPolicies(returnPolicies))
          .set('shippingProfiles', shapeDataForApp.shippingProfiles(shippingTemplates));

        break;
      }

      default: {
        break;
      }
    }

    return data;
  }

  return reduction
    .deleteIn(['data', 'loading', 'shop', shopId])
    .setIn(['data', 'loaded', 'shop', shopId], true)
    .updateIn(['data', 'shopsData', shopId], updateShopData);
}

function* setData(reduction, { path, value }) {
  const fullPath = Array.isArray(path)
    ? ['data', ...path]
    : ['data', path];

  if (value !== undefined) return reduction.setIn(fullPath, value);

  let state = reduction.deleteIn(fullPath);

  fullPath.pop();

  while (fullPath.length > 1) {
    if (!getSize(state.getIn(fullPath))) {
      state = state.deleteIn(fullPath);
    }

    fullPath.pop();
  }

  return state;
}

Reducers.add(
  new Reducer('Data')
    .add(ACTIONS.DATA.ADD_PRODUCT_TYPE, addProductType)
    .add(ACTIONS.DATA.ADD_SECTION, addSection)
    .add(ACTIONS.DATA.ADD_VENDOR, addVendor)
    .add(ACTIONS.DATA.GET_ALL_DATA, getAllData)
    .add(ACTIONS.DATA.GET_CHANNEL_DATA, getChannelData)
    .add(ACTIONS.DATA.GET_CHANNEL_DATA_FAILED, getChannelDataFailed)
    .add(ACTIONS.DATA.GET_CHANNEL_DATA_SUCCEEDED, getChannelDataSucceeded)
    .add(ACTIONS.DATA.GET_PROFILES, getProfiles)
    .add(ACTIONS.DATA.GET_PROFILES_FAILED, getProfilesFailed)
    .add(ACTIONS.DATA.GET_PROFILES_SUCCEEDED, getProfilesSucceeded)
    .add(ACTIONS.DATA.GET_SHOP_DATA, getShopData)
    .add(ACTIONS.DATA.GET_SHOP_DATA_FAILED, getShopDataFailed)
    .add(ACTIONS.DATA.GET_SHOP_DATA_SUCCEEDED, getShopDataSucceeded)
    .add(ACTIONS.DATA.SET_DATA, setData)
);
