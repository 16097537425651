import { getSize } from '../iterable/getSize';

import { ATTRIBUTE_DEFAULTS } from '../../constants/product';
import { DEFAULTS, VELA } from '../../constants';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { INDIVIDUAL } from '../../constants/profiles';
import {
  BARCODE,
  CAP,
  CHARGE_TAX,
  CONTINUE_SELLING,
  COUNTRY_CODE,
  CPI,
  HS_CODE,
  PHYSICAL,
  PRICE,
  QUANTITY,
  SKU,
  TRACK_QUANTITY,
  UNIT,
  VISIBILITY,
  WEIGHT,
} from '../../constants/attributes';

export function getGenericOffering({ channel, individual, offerings, product, variations }) {
  switch (channel) {
    case ETSY: {
      const genericOffering = {
        [VISIBILITY]: DEFAULTS.TRUE,
      };

      if (product) {
        genericOffering[PRICE] = getSize(individual[INDIVIDUAL.PRICE])
          ? ATTRIBUTE_DEFAULTS[ETSY][PRICE]
          : product.get(PRICE);

        genericOffering[QUANTITY] = getSize(individual[INDIVIDUAL.QUANTITY])
          ? ATTRIBUTE_DEFAULTS[ETSY][QUANTITY]
          : product.get(QUANTITY);

        genericOffering[SKU] = getSize(individual[INDIVIDUAL.SKU])
          ? ATTRIBUTE_DEFAULTS[ETSY][SKU]
          : product.get(SKU);
      } else {
        genericOffering[PRICE] = DEFAULTS.NULL;
        genericOffering[QUANTITY] = DEFAULTS.NULL;
        genericOffering[SKU] = DEFAULTS.NULL;
      }

      return genericOffering;
    }

    case SHOPIFY: {
      const genericOffering = {
        imageId: DEFAULTS.NULL,
        imageHash: DEFAULTS.NULL,
      };

      if (product) {
        genericOffering[BARCODE] = product.get(BARCODE) || ATTRIBUTE_DEFAULTS[SHOPIFY][BARCODE];
        genericOffering[CAP] = product.get(CAP) || ATTRIBUTE_DEFAULTS[SHOPIFY][CAP];
        genericOffering[CHARGE_TAX] = product.has(CHARGE_TAX) ? product.get(CHARGE_TAX) : ATTRIBUTE_DEFAULTS[SHOPIFY][CHARGE_TAX];
        genericOffering[CONTINUE_SELLING] = product.has(CONTINUE_SELLING) ? product.get(CONTINUE_SELLING) : ATTRIBUTE_DEFAULTS[SHOPIFY][CONTINUE_SELLING];
        genericOffering[COUNTRY_CODE] = product.get(COUNTRY_CODE) || ATTRIBUTE_DEFAULTS[SHOPIFY][COUNTRY_CODE];
        genericOffering[CPI] = product.get(CPI) || ATTRIBUTE_DEFAULTS[SHOPIFY][CPI];
        genericOffering[HS_CODE] = product.get(HS_CODE) || ATTRIBUTE_DEFAULTS[SHOPIFY][HS_CODE];
        genericOffering[PHYSICAL] = product.has(CHARGE_TAX) ? product.get(PHYSICAL) : ATTRIBUTE_DEFAULTS[SHOPIFY][PHYSICAL];
        genericOffering[PRICE] = product.get(PRICE) || ATTRIBUTE_DEFAULTS[SHOPIFY][PRICE];
        genericOffering[QUANTITY] = (getSize(variations) === 1 && getSize(variations.getIn([0, 'options'])) === 1 && product.get(QUANTITY)) || ATTRIBUTE_DEFAULTS[SHOPIFY][QUANTITY];
        genericOffering[SKU] = getSize(offerings) ? ATTRIBUTE_DEFAULTS[SHOPIFY][SKU] : product.get(SKU) || ATTRIBUTE_DEFAULTS[SHOPIFY][SKU];
        genericOffering[TRACK_QUANTITY] = product.has(CHARGE_TAX) ? product.get(TRACK_QUANTITY) : ATTRIBUTE_DEFAULTS[SHOPIFY][TRACK_QUANTITY];
        genericOffering[UNIT] = product.get(UNIT) || ATTRIBUTE_DEFAULTS[SHOPIFY][UNIT];
        genericOffering[WEIGHT] = product.get(WEIGHT) || ATTRIBUTE_DEFAULTS[SHOPIFY][WEIGHT];
      } else {
        genericOffering[BARCODE] = DEFAULTS.NULL;
        genericOffering[CAP] = DEFAULTS.NULL;
        genericOffering[CHARGE_TAX] = DEFAULTS.TRUE;
        genericOffering[CONTINUE_SELLING] = DEFAULTS.NULL;
        genericOffering[COUNTRY_CODE] = DEFAULTS.NULL;
        genericOffering[CPI] = DEFAULTS.NULL;
        genericOffering[HS_CODE] = DEFAULTS.NULL;
        genericOffering[PHYSICAL] = DEFAULTS.TRUE;
        genericOffering[PRICE] = DEFAULTS.NULL;
        genericOffering[QUANTITY] = DEFAULTS.NULL;
        genericOffering[SKU] = DEFAULTS.NULL;
        genericOffering[TRACK_QUANTITY] = DEFAULTS.FALSE;
        genericOffering[UNIT] = DEFAULTS.UNIT;
        genericOffering[WEIGHT] = DEFAULTS.NULL;
      }

      return genericOffering;
    }

    case VELA: {
      return product
        ? {
          [PRICE]: product.get(PRICE) || ATTRIBUTE_DEFAULTS[VELA][PRICE],
          [QUANTITY]: (getSize(variations) === 1 && getSize(variations.getIn([0, 'options'])) === 1 && product.get(QUANTITY)) || ATTRIBUTE_DEFAULTS[VELA][QUANTITY],
          [SKU]: getSize(offerings) ? ATTRIBUTE_DEFAULTS[VELA][SKU] : product.get(SKU) || ATTRIBUTE_DEFAULTS[VELA][SKU],
        }
        : {
          [PRICE]: DEFAULTS.NULL,
          [QUANTITY]: DEFAULTS.NULL,
          [SKU]: DEFAULTS.NULL,
        };
    }

    default: {
      return {};
    }
  }
}
