import request from './request';

import { ENDPOINT } from '../../constants/api';

export default {
  generateAltText({ image, signal }) {
    return request.post({
      endpoint: ENDPOINT.WEB_V2,
      payload: { image },
      signal,
      url: '/ai/generate-alt-text',
    });
  },
  generateText({ channel, destination, input, operation, signal }) {
    return request.post({
      endpoint: ENDPOINT.WEB_V2,
      payload: {
        channel,
        destination_field: destination,
        input,
        operation,
      },
      signal,
      url: '/ai/product-field-text-generator',
    });
  },
};
