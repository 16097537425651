import { isImmutable } from 'immutable';

import { isObject } from '../isObject';
import { getSize } from './getSize';

export function areEqual(item, ...rest) {
  if (!getSize(rest)) return false;

  function compare(x, y) {
    if (typeof x !== typeof y) {
      return false;
    }

    switch (typeof x) {
      case 'number': {
        return (isNaN(x) && isNaN(y)) || x === y;
      }

      case 'object': {
        if (isImmutable(x)) {
          if (!isImmutable(y) || getSize(x) !== getSize(y)) return false;

          for (const [key, value] of x.entries()) {
            if (!y.has(key) || !areEqual(value, y.get(key))) return false;
          }

          return true;
        } else if (isImmutable(y)) {
          return false;
        }

        if (Array.isArray(x)) {
          if (!Array.isArray(y) || getSize(x) !== getSize(y)) return false;

          for (let index = 0; index < getSize(x); index += 1) {
            if (!areEqual(x[index], y[index])) return false;
          }

          return true;
        } else if (Array.isArray(y)) {
          return false;
        }

        if (isObject(x)) {
          if (!isObject(y) || getSize(x) !== getSize(y)) return false;

          for (const key in x) {
            if (x.hasOwnProperty(key)) {
              if (!y.hasOwnProperty(key) || !areEqual(x[key], y[key])) return false;
            }
          }

          return true;
        } else if (isObject(y)) {
          return false;
        }

        return x === y;
      }

      default: {
        return x === y;
      }
    }
  }

  for (const part of rest) {
    if (!compare(item, part)) return false;
  }

  return true;
}
