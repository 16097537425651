import { isMobileOnly } from 'react-device-detect';
import { List, Map } from 'immutable';

import { getFromLocalStorage } from './utils/localStorage';

import { THEME_NAME, VELA_THEME } from './constants/theme';

export const ADMIN = Map({
  shopCounts: Map(),
  shopsLookup: Map({
    query: '',
    result: List(),
    loading: false,
  }),
  usersLookup: Map({
    query: '',
    result: List(),
    loading: false,
  }),
  applicantsLookup: Map({
    result: List(),
    loading: false,
    processing: List(),
  }),
  payoutsLookup: Map({
    result: List(),
    loading: false,
  }),
  shopDetails: Map({
    loading: false,
    syncInProgress: false,
    refreshInProgress: false,
  }),
  userDetails: Map({
    loading: false,
  }),
  payoutsDetails: Map({
    loading: false,
  }),
  referralsLookup: Map({
    loading: false,
    result: List(),
  }),
  referralsDetails: Map({
    loading: false,
  }),
  reassignShop: Map({
    user: Map(),
    query: '',
    result: List(),
    loading: false,
  }),
  impersonation: Map(),
});

export default Map({
  admin: Map(),
  affiliate: Map(),
  auth: Map(),
  billing: Map(),
  bulkEdit: Map(),
  bulkPhotoEditor: Map(),
  data: Map({
    isMobileOnly,
  }),
  edit: Map(),
  listings: Map(),
  mediaLibrary: Map(),
  notifications: List(),
  photoEditor: Map(),
  profiles: Map(),
  scheduledUpdates: Map(),
  shops: Map(),
  upload: Map(),
  user: Map({
    ...getFromLocalStorage({ [VELA_THEME]: THEME_NAME.LIGHT }),
  }),
});
