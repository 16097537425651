import React, { createContext, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

export const LastLocationContext = createContext({});
LastLocationContext.displayName = 'LastLocationContext';

export default function LastLocationProvider({ children }) {
  const current = useLocation();
  const [next, setNext] = useState();
  const [last, setLast] = useState({});

  useLayoutEffect(
    function effect() {
      if (next) {
        setLast(next);
      }

      setNext(current);
    },
    [current],
  );

  return (
    <LastLocationContext.Provider value={last}>
      {children}
    </LastLocationContext.Provider>
  );
}

LastLocationProvider.propTypes = {
  children: PropTypes.node,
};
