import { Map } from 'immutable';

import { isValidNumber } from '../number';

import { DEFAULTS } from '../../constants';

export function shapeTaxonomyAttributesForAPI({ taxonomyAttributes, toImmutable = true }) {
  function reduceTaxonomyAttributes(result, value, propertyId) {
    if (!isValidNumber(propertyId)) return result;

    const scaleId = value.get('scaleId', null);
    const values = value.get('values', DEFAULTS.EMPTY_LIST);
    const valueIds = value.get('valueIds', DEFAULTS.EMPTY_LIST);
    const attribute = {
      scaleId,
      values: toImmutable ? values : values.toArray(),
      valueIds: toImmutable ? valueIds : valueIds.toArray(),
    };

    if (toImmutable) {
      return result.set(propertyId, Map(attribute));
    }

    result[propertyId] = attribute;
    return result;
  }

  return taxonomyAttributes.reduce(reduceTaxonomyAttributes, toImmutable ? DEFAULTS.EMPTY_MAP : {});
}
