import { findExistingOffering } from './findExistingOffering';
import { isTruthy } from '../bool';
import { getSize } from '../iterable/getSize';

import { ETSY, SHOPIFY } from '../../constants/channels';
import { VELA } from '../../constants';
import {
  BARCODE,
  CAP,
  CHARGE_TAX,
  CONTINUE_SELLING,
  COUNTRY_CODE,
  CPI,
  HS_CODE,
  PHYSICAL,
  PRICE,
  QUANTITY,
  SKU,
  TRACK_QUANTITY,
  UNIT,
  VISIBILITY,
  WEIGHT,
} from '../../constants/attributes';

export function getOffering({ channel, offerings, config, genericOffering }) {
  switch (channel) {
    case ETSY: {
      const { properties, individualSKU, individualPrice, individualQuantity } = config;
      const existing = findExistingOffering({ channel, offerings, properties });

      if (!existing) return genericOffering;

      return {
        [VISIBILITY]: existing.get(VISIBILITY),
        [PRICE]: individualPrice ? existing.get(PRICE) : genericOffering[PRICE],
        [QUANTITY]: individualQuantity ? existing.get(QUANTITY) : genericOffering[QUANTITY],
        [SKU]: individualSKU ? existing.get(SKU) : genericOffering[SKU],
      };
    }

    case SHOPIFY: {
      const { names } = config;
      const newOfferingNames = names.filter(isTruthy);
      const existing = findExistingOffering({ channel, offerings, names });

      if (!existing) return genericOffering;

      return {
        imageHash: existing.get('imageHash'),
        imageId: existing.get('imageId'),
        [BARCODE]: existing.get(BARCODE),
        [CAP]: existing.get(CAP),
        [CHARGE_TAX]: existing.get(CHARGE_TAX),
        [CONTINUE_SELLING]: existing.get(CONTINUE_SELLING),
        [COUNTRY_CODE]: existing.get(COUNTRY_CODE),
        [CPI]: existing.get(CPI),
        [HS_CODE]: existing.get(HS_CODE),
        [PHYSICAL]: existing.get(PHYSICAL),
        [PRICE]: existing.get(PRICE),
        [QUANTITY]: existing.get(TRACK_QUANTITY)
          ? existing.get(QUANTITY)
          : genericOffering[QUANTITY],
        [SKU]: getSize(newOfferingNames) > getSize(existing.get('options'))
          ? genericOffering[SKU]
          : existing.get(SKU),
        [TRACK_QUANTITY]: existing.get(TRACK_QUANTITY),
        [UNIT]: existing.get(UNIT),
        [WEIGHT]: existing.get(WEIGHT),
      };
    }

    case VELA: {
      const { names } = config;
      const newOfferingNames = names.filter(isTruthy);
      const existing = findExistingOffering({ channel, offerings, names });

      if (!existing) return genericOffering;

      return {
        [PRICE]: existing.get(PRICE),
        [QUANTITY]: existing.get(QUANTITY),
        [SKU]: getSize(newOfferingNames) > getSize(existing.get('options'))
          ? genericOffering[SKU]
          : existing.get(SKU),
      };
    }

    default:
      return {};
  }
}
