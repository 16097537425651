export function createPolling() {
  return {
    start(timeoutId) {
      this.timeoutId = timeoutId;
    },
    stop() {
      if (this.timeoutId) {
        this.timeoutId = clearTimeout(this.timeoutId);
      }
    },
  };
}

export function createController() {
  return {
    isActive() {
      return !!this.controller;
    },
    start() {
      if (this.isActive()) {
        this.controller.abort();
      }

      this.controller = new AbortController();
      return this.controller;
    },
    stop() {
      if (this.isActive()) {
        this.controller.abort();
        delete this.controller;
      }
    },
  };
}
