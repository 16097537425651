import { List, Map } from 'immutable';
import moment from 'moment';

import { getExcludedSections, shapeTemplatesForAPI } from './listingsProfiles';
import { getOptionFormatter } from '../taxonomy';
import { keepOld } from '../iterable/keepOld';
import { get } from '../iterable/get';

import { INDIVIDUAL, NUMBER_OF_VARIATIONS, PROFILE } from '../../constants/profiles';
import { CUSTOM_PROPERTY_IDS } from '../../constants/taxonomy';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { DEFAULTS } from '../../constants';
import { NEW } from '../../constants/product';
import {
  BARCODE,
  CAP,
  CATEGORY,
  CHARGE_TAX,
  CONTINUE_SELLING,
  COUNTRY_CODE,
  CPI,
  HS_CODE,
  PHYSICAL,
  PRICE,
  QUANTITY,
  SKU,
  TRACK_QUANTITY,
  UNIT,
  VARIATIONS,
  WEIGHT,
} from '../../constants/attributes';

const { EMPTY_LIST, EMPTY_ORDERED_MAP, EMPTY_STRING, FALSE, NULL, TRUE, ZERO } = DEFAULTS;

export function shapeVariationsProfileForAPI({ profile, productId, toImmutable = FALSE }) {
  const title = profile.get('title');
  const id = profile.get('id');
  const channel = profile.get('channel');
  const invalid = profile.get('invalid');
  const offerings = profile.get('offerings');
  const variations = profile.get(VARIATIONS);
  const templateId = parseInt(id, 10);
  const isProduct = !!productId;
  const isExist = !!id;
  const result = {};

  if (isExist && (!isProduct || channel === SHOPIFY)) {
    result.templateId = templateId;
  }

  if (!isProduct) {
    result.title = title;
    result.isActive = TRUE;
    result.invalid = invalid;
    result.editMode = isExist;
    result.isUniqueProfile = TRUE;
  }

  let individualSKU;
  let individualPrice;
  let individualQuantity;

  function shapeVariationForAPI(variationIndex) {
    function mapEtsyOptions(scale) {
      return function map(item) {
        const optionId = item.get('id');
        const value = item.get('value');
        const valueId = item.get('valueId');
        const imageHash = isProduct ? item.get('imageHash', NULL) : NULL;
        const label = getOptionFormatter(scale)(value);
        const option = {
          label,
          value,
          valueId,
          imageHash,
          id: optionId,
          isAvailable: NULL,
        };

        return toImmutable ? Map(option) : option;
      };
    }

    switch (channel) {
      case ETSY: {
        const variation = {
          id: -1 - variationIndex,
          options: toImmutable ? EMPTY_LIST : [],
        };

        if (!variations.has(variationIndex)) return toImmutable ? Map(variation) : variation;

        const source = variations.get(variationIndex);

        if (source.get('id')) {
          variation.id = source.get('id');
        }

        variation.propertyId = source.get('property');
        variation.formattedName = source.get('formattedName');
        variation.influencesSku = source.get(INDIVIDUAL.SKU);
        variation.influencesPrice = source.get(INDIVIDUAL.PRICE);
        variation.influencesQuantity = source.get(INDIVIDUAL.QUANTITY);
        variation.scalingOptionId = source.get('scale') || NULL;
        variation.isCustomProperty = CUSTOM_PROPERTY_IDS[variationIndex] === source.get('property');
        const options = source.get('options').map(mapEtsyOptions(source.get('scale')));
        variation.options = toImmutable ? options : options.toArray();

        individualSKU = individualSKU || source.get(INDIVIDUAL.SKU);
        individualPrice = individualPrice || source.get(INDIVIDUAL.PRICE);
        individualQuantity = individualQuantity || source.get(INDIVIDUAL.QUANTITY);

        return toImmutable ? Map(variation) : variation;
      }

      case SHOPIFY: {
        const variation = {
          shopify_name: EMPTY_STRING,
          shopify_position: String(variationIndex + 1),
          shopify_values: toImmutable ? EMPTY_LIST : [],
        };

        if (!variations.has(variationIndex)) return toImmutable ? Map(variation) : variation;

        const source = variations.get(variationIndex);
        variation.shopify_name = source.get('formattedName');
        variation.shopify_position = variationIndex + 1;
        const options = source.get('options').map(get('value'));
        variation.shopify_values = toImmutable ? options : options.toArray();

        if (isExist) {
          variation.id = (!isProduct && source.get('id')) || EMPTY_STRING;
          variation.shopify_template_id = id;
        }

        return toImmutable ? Map(variation) : variation;
      }

      default:
        return NULL;
    }
  }

  function shapeOfferingForAPI(source, offeringIndex) {
    function mapEtsyOptions(item) {
      const optionId = item.get('optionId');
      const variationId = item.get('variationId');
      const option = { optionId, variationId };
      return toImmutable ? Map(option) : option;
    }

    switch (channel) {
      case ETSY: {
        const options = source.get('options').map(mapEtsyOptions);
        const offering = {
          id: source.get('id') || -1 - offeringIndex,
          visibility: source.get('visibility'),
          sku: isProduct || individualSKU ? source.get(SKU) : NULL,
          price: isProduct || individualPrice ? source.get(PRICE) : NULL,
          quantity: isProduct || individualQuantity ? source.get(QUANTITY) : NULL,
          variationOptions: toImmutable ? options : options.toArray(),
        };

        return toImmutable ? Map(offering) : offering;
      }

      case SHOPIFY: {
        const option1 = source.getIn(['options', 0, 'name'], NULL);
        const option2 = source.getIn(['options', 1, 'name'], NULL);
        const option3 = source.getIn(['options', 2, 'name'], NULL);
        const physical = source.get(PHYSICAL);
        const weight = (physical && source.get(WEIGHT)) || (isProduct ? ZERO : NULL);
        const offering = {
          deleted: FALSE,
          id: (!isProduct && source.get('id')) || EMPTY_STRING,
          shopify_admin_graphql_api_id: NULL,
          shopify_created_at: NULL,
          shopify_updated_at: NULL,
          shopify_fulfillment_service: isProduct ? NULL : EMPTY_STRING,
          shopify_grams: NULL,
          shopify_inventory_item_id: NULL,
          shopify_old_inventory_quantity: NULL,
          shopify_option1: option1,
          shopify_option2: option2,
          shopify_option3: option3,
          shopify_position: offeringIndex + 1,
          shopify_cost: source.get(CPI) || (isProduct ? ZERO : NULL),
          shopify_sku: source.get(SKU) || NULL,
          shopify_price: source.get(PRICE) || (isProduct ? ZERO : NULL),
          shopify_weight: weight,
          shopify_image_id: isProduct ? source.get('imageId', NULL) : NULL,
          shopify_barcode: source.get(BARCODE) || NULL,
          shopify_weight_unit: source.get(UNIT) || (isProduct ? UNIT : NULL),
          shopify_image_hash: isProduct ? source.get('imageHash', NULL) : NULL,
          shopify_taxable: source.get(CHARGE_TAX) || FALSE,
          shopify_compare_at_price: source.get(CAP) || (isProduct ? ZERO : NULL),
          shopify_inventory_quantity: source.get(QUANTITY) || (isProduct ? ZERO : NULL),
          shopify_requires_shipping: physical || FALSE,
          shopify_harmonized_system_code: source.get(HS_CODE) || NULL,
          shopify_country_code_of_origin: source.get(COUNTRY_CODE) || NULL,
          shopify_inventory_management: source.get(TRACK_QUANTITY) ? SHOPIFY : NULL,
          shopify_inventory_policy: source.get(CONTINUE_SELLING) ? 'continue' : 'deny',
        };

        if (!isProduct || productId === NEW) {
          offering.shopify_product_id = 0;
        }

        if (isExist) {
          offering.shopify_template_id = String(id);
          offering.shopify_title = source.get('options').reduce(
            (name, option, optionIndex) =>
              optionIndex === 0 ? option.get('name') : `${name} / ${option.get('name')}`,
            EMPTY_STRING
          );
        } else {
          offering.shopify_id = 0;
        }

        if (source.get(PRICE) !== ZERO && source.get(PRICE) !== NULL) {
          const error = profile.getIn(['errors', PRICE, offeringIndex]);
          offering.invalid_price = !!error;
          offering.message_price = error || NULL;
        }

        if (source.get(CAP) !== ZERO && source.get(CAP) !== NULL) {
          const error = profile.getIn(['errors', CAP, offeringIndex]);
          offering.invalid_compare_at_price = !!error;
          offering.message_compare_at_price = error || NULL;
        }

        if (source.get(CPI) !== ZERO && source.get(CPI) !== NULL) {
          const error = profile.getIn(['errors', CPI, offeringIndex]);
          offering.invalid_cost = !!error;
          offering.message_cost = error || NULL;
        }

        if (
          source.get(TRACK_QUANTITY) &&
          source.get(QUANTITY) !== ZERO &&
          source.get(QUANTITY) !== NULL
        ) {
          const error = profile.getIn(['errors', QUANTITY, offeringIndex]);
          offering.invalid_quantity = !!error;
          offering.message_quantity = error || NULL;
        }

        if (source.get(SKU)) {
          const error = profile.getIn(['errors', SKU, offeringIndex]);
          offering.invalid_sku = !!error;
          offering.message_sku = error || NULL;
        }

        if (source.get(BARCODE)) {
          const error = profile.getIn(['errors', BARCODE, offeringIndex]);
          offering.invalid_barcode = !!error;
          offering.message_barcode = error || NULL;
        }

        return toImmutable ? Map(offering) : offering;
      }

      default:
        return undefined;
    }
  }

  switch (channel) {
    case ETSY: {
      const shapedVariations = [...Array(NUMBER_OF_VARIATIONS[channel]).keys()].map(shapeVariationForAPI);
      const shapedOfferings = offerings.map(shapeOfferingForAPI);

      const value = {
        taxonomyId: profile.get(CATEGORY),
        variations: toImmutable ? List(shapedVariations) : shapedVariations,
        offerings: toImmutable ? shapedOfferings : shapedOfferings.toArray(),
      };

      if (isExist) {
        value.templateid = templateId;

        if (templateId !== -1) {
          value.templatename = title;
        }
      }

      if (toImmutable) {
        result.value = Map(value);
      } else {
        result.value = value;
      }

      break;
    }

    case SHOPIFY: {
      const options = [...Array(NUMBER_OF_VARIATIONS[channel]).keys()].map(shapeVariationForAPI);
      const variants = offerings.map(shapeOfferingForAPI);
      const value = {
        options: toImmutable ? List(options) : options,
        variants: toImmutable ? variants : variants.toArray(),
      };

      if (toImmutable) {
        result.value = Map(value);
      } else {
        result.value = value;
      }

      if (isProduct) {
        const shopifyMeta = {
          individualSKU: TRUE,
          individualPrice: TRUE,
          individualBarcode: TRUE,
          individualShipping: TRUE,
        };

        if (toImmutable) {
          result.shopifyMeta = Map(shopifyMeta);
        } else {
          result.shopifyMeta = shopifyMeta;
        }
      } else {
        result.individualSKU = TRUE;
        result.individualPrice = TRUE;
        result.individualBarcode = TRUE;
        result.individualShipping = TRUE;

        if (isExist) {
          const shopifyMeta = { templateId };

          if (toImmutable) {
            result.shopifyMeta = Map(shopifyMeta);
          } else {
            result.shopifyMeta = shopifyMeta;
          }
        }
      }

      break;
    }

    default:
      break;
  }

  return toImmutable ? Map(result) : result;
}

export function shapeTagsProfileForAPI({ profile }) {
  const id = profile.get('id');
  const invalid = profile.get('invalid');
  const profileName = profile.get('title');
  const tags = profile.get('tags')
    .mergeWith(keepOld, profile.get('newTags', EMPTY_ORDERED_MAP))
    .toList()
    .toArray();

  return {
    id,
    tags,
    invalid,
    profileName,
  };
}

export function shapeSaleProfileForAPI({ profile }) {
  const id = profile.get('id');
  const name = profile.get('title');
  const channel = profile.get('channel');

  const shapedProfile = {
    id,
    name,
    start_date: moment.utc(profile.get('startDate')).format(),
    end_date: moment.utc(profile.get('endDate')).format(),
    price_value: profile.get(PRICE),
    price_type: profile.get('priceType'),
    cents_value: profile.get('cents'),
    convert_to_cap: profile.get('isConvertPriceToCap'),
    description_value: profile.get('descriptionValue'),
    description_operation: profile.get('descriptionOperation'),
    title_value: profile.get('titleValue'),
    title_operation: profile.get('titleOperation'),
    tags_operation: profile.get('tagsOperation'),
    tags: Object.values(profile.get('tags', EMPTY_ORDERED_MAP).merge(profile.get('newTags', EMPTY_ORDERED_MAP)).toJS()),
  };

  if (channel === SHOPIFY) {
    shapedProfile.convert_to_cap = profile.get('isConvertPriceToCap', FALSE);
  }

  return shapedProfile;
}

export function shapeListingsProfileForAPI({ profile, userShops, userId }) {
  const title = profile.get('title');
  const data = profile.get('data');
  const products = data.get('products');
  const excludedSections = getExcludedSections({
    channels: profile.get('channels'),
    products,
    sections: data.get('sections'),
  });

  const templates = shapeTemplatesForAPI({
    excludedSections,
    products,
    userShops,
    userId,
  });

  return { excluded_sections: excludedSections, name: title, templates };
}

export function shapeForAPI({ profile, type, ...rest }) {
  switch (type) {
    case PROFILE.LISTINGS: {
      return shapeListingsProfileForAPI({ profile, ...rest });
    }

    case PROFILE.SALES: {
      return shapeSaleProfileForAPI({ profile });
    }

    case PROFILE.TAGS: {
      return shapeTagsProfileForAPI({ profile });
    }

    case PROFILE.VARIATIONS: {
      return shapeVariationsProfileForAPI({ profile });
    }

    default:
      return {};
  }
}
