/* eslint-disable react/prop-types */
import React from 'react';
import pluralize from 'pluralize';

import { CHANNEL_NAME } from './channels';
import { PROFILE } from './profiles';

export const MODALS = {
  AFFILIATE: {
    PAYOUT: {
      REQUEST: 'affiliates_payout_request',
      SUCCESS: 'affiliates_payout_success',
    },
    SURVEY: {
      APPLY: 'affiliates_survey_apply',
      CHANNELS: 'affiliates_survey_channels',
      EXPERIENCE: 'affiliates_survey_experience',
      SUCCESS: 'affiliates_survey_success',
    },
  },
  AVATAR: 'avatar',
  BILLING: {
    MANAGE: 'manage',
    SUCCESS: 'success',
  },
  CANVA: {
    CONNECTION: {
      FAILED: 'canva_auth_failed',
      SUCCESS: 'canva_auth_success',
    },
    SELECT_DESIGN: 'canva_select_design',
  },
  CONFIRMATIONS: {
    BULK_CHANGE_CATEGORY: 'bulk_change_category',
    CANCEL: 'cancel',
    CHANGE_CATEGORY: 'change_category',
    CLOSE_PHOTO_EDITOR: 'close_photo_editor',
    DELETE: 'delete',
    LEAVE_PHOTO_EDITOR: 'leave_photo_editor',
    PUBLISH: 'publish',
    UPDATE_EXISTING: 'update_existing',
  },
  COPY_CONTENT: 'copy_content',
  CREATE_LISTING: 'create_listing',
  CSV: {
    EXPORT_LISTINGS: 'csv_export_listings',
    IMPORT_LISTINGS: {
      LOAD_FILE: 'csv_import_listings_load_file',
      MAP_FIELDS: 'csv_import_listings_map_fields',
    },
    MANAGE_SETTINGS: 'csv_export_listings',
  },
  PROFILES: {
    CANCEL: 'profiles_cancel',
    CONNECT_LISTINGS: 'profiles_connect_listings',
    CREATE: 'profiles_create',
    DELETE: 'profiles_delete',
    LEAVE: 'profiles_leave',
  },
  SCHEDULE_UPDATES: 'schedule_updates',
  STUDIO: {
    LIBRARY: {
      CREATE_FOLDER: 'media_library_create_folder',
      DELETE_FOLDER: 'media_library_delete_folder',
      RENAME_FOLDER: 'media_library_rename_folder',
      MOVE_INTO_NEW_FOLDER: 'media_library_move_into_new_folder',
    },
    SELECT_PHOTOS: 'select_photos',
  },
  SYNC: {
    COPY: 'sync_copy',
    PUBLISH: 'sync_publish',
    REFRESH: 'sync_refresh',
    REFRESH_REQUIRED: 'sync_refresh_required',
    SCHEDULED_UPDATE: 'sync_scheduled_update',
  },
  VIDEOS: {
    EXTERNAL: 'video_external_video_url',
  },
  ALT_TEXT: 'alt-text',
  WAIT_LIST: 'wait-list',
};

export const TITLES = {
  [MODALS.AFFILIATE.SURVEY.APPLY]: 'Welcome to Vela Affiliates!',
  [MODALS.AFFILIATE.SURVEY.CHANNELS]: 'Affiliate program',
  [MODALS.AFFILIATE.SURVEY.EXPERIENCE]: 'Affiliate program',
  [MODALS.AFFILIATE.SURVEY.SUCCESS]: 'Affiliate program',
  [MODALS.AFFILIATE.PAYOUT.REQUEST]: 'Request Payout',
  [MODALS.AFFILIATE.PAYOUT.SUCCESS]: 'Payout Submitted!',
  [MODALS.ALT_TEXT]: 'Edit alt text',
  [MODALS.AVATAR]: 'Crop your photo',
  [MODALS.CANVA.CONNECTION.FAILED]: 'Canva failed to connect',
  [MODALS.CANVA.CONNECTION.SUCCESS]: 'Canva connected',
  [MODALS.CANVA.SELECT_DESIGN]: 'Select designs from Canva',
  [MODALS.CONFIRMATIONS.BULK_CHANGE_CATEGORY]: 'Changing category will reset attributes',
  [MODALS.CONFIRMATIONS.CANCEL]: 'Cancel updates?',
  [MODALS.CONFIRMATIONS.CHANGE_CATEGORY]: 'Changing category will reset attributes',
  [MODALS.CONFIRMATIONS.CLOSE_PHOTO_EDITOR]: 'Close photo editor?',
  [MODALS.CONFIRMATIONS.DELETE]: 'Delete listings?',
  [MODALS.CONFIRMATIONS.LEAVE_PHOTO_EDITOR]: 'Leave photo editor?',
  [MODALS.CONFIRMATIONS.PUBLISH]: 'Publish a new listing',
  [MODALS.CONFIRMATIONS.UPDATE_EXISTING]: 'Update existing listings?',
  [MODALS.COPY_CONTENT]: 'Add shop',
  [MODALS.CSV.IMPORT_LISTINGS.LOAD_FILE]: 'Import CSV',
  [MODALS.CSV.MANAGE_SETTINGS]: 'Manage import settings',
  [MODALS.PROFILES.CANCEL]: 'Cancel editing profile?',
  [MODALS.PROFILES.CREATE]: {
    [PROFILE.LISTINGS]: 'Create Listings Profile',
    [PROFILE.TAGS]: 'Create Tags Profile',
    [PROFILE.VARIATIONS]: 'Create Variations Profile',
  },
  [MODALS.PROFILES.DELETE]: 'Delete profile?',
  [MODALS.PROFILES.LEAVE]: 'Cancel editing profile and leave?',
  [MODALS.SCHEDULE_UPDATES]: 'Schedule update',
  [MODALS.STUDIO.SELECT_PHOTOS]: 'Select photos',
  [MODALS.STUDIO.LIBRARY.CREATE_FOLDER]: 'New folder',
  [MODALS.STUDIO.LIBRARY.MOVE_INTO_NEW_FOLDER]: 'New folder for images',
  [MODALS.STUDIO.LIBRARY.DELETE_FOLDER]: 'Delete Folder?',
  [MODALS.STUDIO.LIBRARY.RENAME_FOLDER]: 'Rename folder',
  [MODALS.SYNC.COPY]: 'Copying listings',
  [MODALS.SYNC.PUBLISH]: 'Publishing listings',
  [MODALS.SYNC.REFRESH]: 'Refreshing shop',
  [MODALS.SYNC.REFRESH_REQUIRED]: 'Refresh shop',
  [MODALS.SYNC.SCHEDULED_UPDATE]: 'Scheduled Update',
  [MODALS.VIDEOS.EXTERNAL]: 'Add file from URL',
  [MODALS.WAIT_LIST]: 'Channel waitlist',
};

export const DESCRIPTIONS = {
  [MODALS.AFFILIATE.SURVEY.APPLY]: 'We appreciate your interest in becoming a Vela Affiliate! To get started, please take a minute to complete a quick three question survey.',
  [MODALS.AFFILIATE.SURVEY.CHANNELS]: 'Excluding Etsy and Shopify, select the channels where else do you currently sell or would like to?',
  [MODALS.AFFILIATE.PAYOUT.REQUEST]: 'Select your preferred payout method',
  [MODALS.AFFILIATE.PAYOUT.SUCCESS]: 'We have received your submission and it is currently being processed. Depending on the method selected it can take up to 5-7 business days for the payout to hit your account.',
  [MODALS.AVATAR]: 'Adjust your avatar',
  [MODALS.CANVA.CONNECTION.FAILED]: 'There was an error when connecting your Canva account to Vela.',
  [MODALS.CANVA.CONNECTION.SUCCESS]: 'Your Canva account has been successfully connected to Vela.',
  [MODALS.CANVA.SELECT_DESIGN]: 'You can multiselect designs from your library',
  [MODALS.CONFIRMATIONS.BULK_CHANGE_CATEGORY]: 'One or more of this listing’s attributes aren’t relevant in the new category, so they’ll be removed. Any variations will remain unchanged.',
  [MODALS.CONFIRMATIONS.CANCEL]: 'You have updates that have NOT been synced. Closing this page without syncing will cause all updates to be lost.',
  [MODALS.CONFIRMATIONS.CHANGE_CATEGORY]: 'One or more of this listing’s attributes aren’t relevant in the new category, so they’ll be removed. Any variations will remain unchanged.',
  [MODALS.CONFIRMATIONS.CLOSE_PHOTO_EDITOR]: 'You have updates that have NOT been saved. Closing the editor without saving will cause all updates to be lost.',
  [MODALS.CONFIRMATIONS.DELETE]: 'Listings will be deleted in both Vela and the channel and can not be recovered.',
  [MODALS.CONFIRMATIONS.LEAVE_PHOTO_EDITOR]: 'You have updates that have NOT been saved. Leaving the editor without saving will cause all updates to be lost.',
  [MODALS.CONFIRMATIONS.PUBLISH]: (
    <>
      There's a non-refundable fee of $0.20 for each new listing-by publishing, you agree to pay
      this fee.
      <br /><br />
      The publication date will reset to today's date, and the listing will expire four months from
      today. You can turn off auto-renew anytime in Listings. For more details see Etsy’s Fees &
      Payments Policy.
      <br /><br />
      Etsy auto-renews every four months at $0.20 per listing, you can change this anytime in Vela
      or Etsy.
    </>
  ),
  [MODALS.CONFIRMATIONS.UPDATE_EXISTING]: 'These listings match listings already in your shop. Would you like to update the existing listings or publish new listings?',
  [MODALS.COPY_CONTENT]: 'Copy content from an existing shop or use a new listing',
  [MODALS.CSV.EXPORT_LISTINGS]: 'Provide the email address where you’d like the CSV file sent once the export is complete.',
  [MODALS.CSV.IMPORT_LISTINGS.LOAD_FILE]: 'You can upload any CSV file, but we recommend using our CSV template for the best results and easy attribute mapping.',
  [MODALS.CSV.IMPORT_LISTINGS.MAP_FIELDS]: 'Importing will not overwrite any existing products that have the same product handle and won’t be published. Review the matching column titles and use the dropdowns to make any changes needed.',
  [MODALS.PROFILES.CANCEL]: 'All unsaved changes will be lost.',
  [MODALS.PROFILES.CREATE]: 'New profiles will be saved and can be edited within Profiles',
  [MODALS.PROFILES.DELETE]: 'Profile will be deleted and can not be recovered. Connected listings will be saved.',
  [MODALS.PROFILES.LEAVE]: 'All unsaved changes will be lost.',
  [MODALS.SCHEDULE_UPDATES]: 'Schedule a timed update for selected attributes',
  [MODALS.STUDIO.LIBRARY.DELETE_FOLDER]: 'Photos inside the folder will be deleted and can not be recovered.',
  [MODALS.STUDIO.SELECT_PHOTOS]: 'You can multiselect photos from your library',
  [MODALS.SYNC.COPY]: 'The time this takes will depend on the number of listings copied',
  [MODALS.SYNC.PUBLISH]: 'The time this takes will depend on the number of listings to be published',
  [MODALS.SYNC.REFRESH]: 'The time this takes will depend on the number of listings in your shop',
  [MODALS.SYNC.REFRESH_REQUIRED]({ channel, daysFromSync }) {
    return (
      <>
        It looks like your shop was last refreshed <span>{pluralize('day', daysFromSync, true)}</span> ago.
        Click “Refresh shop” to import the latest from {CHANNEL_NAME[channel]} and ensure Vela is up to date.
      </>
    );
  },
  [MODALS.SYNC.SCHEDULED_UPDATE]: (
    <>
      A scheduled update you created is being implemented.<br />
      The time it takes will depend on the number<br />
      of listings included in the update.
    </>
  ),
  [MODALS.VIDEOS.EXTERNAL]: 'Youtube or Vimeo URL',
  [MODALS.WAIT_LIST]: 'Select the channels where you sell (or would like to) to receive first access as they are launched in Vela',
};
