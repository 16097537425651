import { startsWithAlphanumeric, usedMoreThanAllowed } from './utils';
import { getWeightError } from './shipping';
// import { getAvailable } from '../taxonomy';
import { reduceErrors } from '../errors';
import { getSize } from '../iterable/getSize';
import { get } from '../iterable/get';
import {
  getBarcodeError,
  getCAPError,
  getCPIError,
  getPriceError,
  getQuantityError,
  getSKUError,
} from './inventory';

import { ERRORS as PRODUCT_ERRORS, MAX_NUMBER_OF_TAGS } from '../../constants/validations';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { DEFAULTS, VELA } from '../../constants';
import {
  BARCODE,
  CAP,
  CATEGORY,
  CPI,
  PHYSICAL,
  PRICE,
  QUANTITY,
  SKU,
  TAGS,
  TRACK_QUANTITY,
  VARIATIONS,
  VISIBILITY,
  WEIGHT,
} from '../../constants/attributes';
import {
  ERRORS,
  INDIVIDUAL,
  MAXIMUM_NUMBER_OF_OFFERINGS,
  MAXIMUM_NUMBER_OF_OPTIONS,
  MAXIMUM_OPTION_NAME_LENGTH,
  MAXIMUM_PROFILE_NAME_LENGTH,
  MAXIMUM_VARIATION_NAME_LENGTH,
  NUMBER_OF_VARIATIONS,
  OFFERINGS,
  PROFILE,
} from '../../constants/profiles';

const { EMPTY_LIST, EMPTY_MAP, ZERO } = DEFAULTS;

export function getOptionNameError({ channel, value }) {
  if (
    MAXIMUM_OPTION_NAME_LENGTH.hasOwnProperty(channel) &&
    String(value).length > MAXIMUM_OPTION_NAME_LENGTH[channel]
  ) {
    return ERRORS.OPTION_NAME_LENGTH_EXCEEDED[channel];
  } else if (channel === ETSY && /[\^$`]/.test(value)) {
    return ERRORS.FORBIDDEN_CHARACTERS[channel];
  }

  return false;
}

export function getOptionsErrors(profile) {
  const channel = profile.get('channel');
  const errors = profile.get('errors', EMPTY_MAP);
  const variations = profile.get(VARIATIONS);

  function setOptionErrors(result, variation, variationIndex) {
    function mapOptions(option) {
      return getOptionNameError({ channel, value: option.get('value') });
    }

    return result.set(variationIndex, variation.get('options').map(mapOptions));
  }

  return errors.set('options', variations.reduce(setOptionErrors, errors.get('options', EMPTY_LIST)));
}

export function getVariationNameError({ channel, variations, variationIndex, name }) {
  if (
    MAXIMUM_VARIATION_NAME_LENGTH.hasOwnProperty(channel) &&
    name.length > MAXIMUM_VARIATION_NAME_LENGTH[channel]
  ) {
    return ERRORS.VARIATION_NAME_LENGTH_EXCEEDED[channel];
  }

  function findSameVariation(variation) {
    return !!getSize(variation.get('options')) && variation.get('formattedName') === name;
  }

  switch (channel) {
    case ETSY: {
      return /[\^$`]/.test(name) && ERRORS.FORBIDDEN_CHARACTERS[ETSY];
    }

    case SHOPIFY: {
      if (
        !name &&
        variations.getIn([variationIndex, 'formattedName']) !== undefined &&
        getSize(variations.getIn([variationIndex, 'options']))
      ) {
        return ERRORS.VARIATION_NAME_REQUIRED;
      } else if (/\s\/\s/.test(name)) {
        return ERRORS.FORBIDDEN_CHARACTERS_SEQUENCE[SHOPIFY];
      }

      return (
        variations.delete(variationIndex).findIndex(findSameVariation) !== -1 &&
        ERRORS.SAME_NAME_VARIATION
      );
    }

    case VELA: {
      if (
        !name &&
        variations.getIn([variationIndex, 'formattedName']) !== undefined &&
        getSize(variations.getIn([variationIndex, 'options'])) > 0
      ) {
        return ERRORS.VARIATION_NAME_REQUIRED;
      } else {
        return false;
      }
    }

    default: {
      return false;
    }
  }
}

export function getProfileTitleError({ channel, title, titles, type }) {
  if (!title) {
    return ERRORS.NAME.EMPTY;
  } else if (titles?.has(title)) {
    return ERRORS.NAME.NON_UNIQUE;
  } else if (
    MAXIMUM_PROFILE_NAME_LENGTH.hasOwnProperty(channel) &&
    title.length > MAXIMUM_PROFILE_NAME_LENGTH[channel]
  ) {
    return ERRORS.NAME.LENGTH_EXCEEDED[channel];
  }

  if (type === PROFILE.SALES && channel === ETSY) {
    if (!startsWithAlphanumeric(title)) {
      return ERRORS.NAME.ALPHANUMERIC_BEGIN;
    } else if (/[^A-z0-9+\-=\/*%:& ]+/ug.test(title)) {
      return ERRORS.NAME.FORBIDDEN_CHARACTERS;
    } else if (usedMoreThanAllowed({ patterns: ['\\b\\p{Lu}{2,}\\b'], number: 3, value: title })) {
      return ERRORS.NAME.CAPITALIZED_WORDS_NUMBER_EXCEEDED;
    } else if (usedMoreThanAllowed({ patterns: ['[&]', '[%]', '[\:]', '[\u0026]', '[\u0025]', '[\u003A]'], number: 1, value: title })) {
      return ERRORS.NAME.SPECIAL_CHARACTERS_NUMBER_EXCEEDED;
    }
  }

  return false;
}

export function getOfferingError({ type, profile, variations, offerings }) {
  const channel = profile.get('channel');
  const individualProperty = get([channel, type, 'individualProperty'])(OFFERINGS) || type;

  function reduceOfferingsForQuantity(result, offering) {
    return result && (!offering.get(VISIBILITY) || !parseInt(offering.get(QUANTITY), 10));
  }

  function reduceVariationsForIndividualProperty(result, variation) {
    return result || variation.get(individualProperty);
  }

  function reduceOfferingsForVisibility(result, offering) {
    return result && !offering.get(VISIBILITY);
  }

  function valueRequired(offering) {
    switch (channel) {
      case ETSY: {
        return (
          offering.get(VISIBILITY) && (
            profile.get(individualProperty) ||
            variations.reduce(reduceVariationsForIndividualProperty, false)
          )
        );
      }

      case SHOPIFY: {
        switch (individualProperty) {
          case TRACK_QUANTITY: {
            return offering.get(individualProperty, false);
          }

          case INDIVIDUAL.SHIPPING: {
            return offering.get(PHYSICAL, false);
          }

          default: {
            return true;
          }
        }
      }

      default: {
        return false;
      }
    }
  }

  function mapOfferingsForBarcodeError(offering) {
    if (!valueRequired(offering)) return false;

    return getBarcodeError({ channel, barcode: offering.get(BARCODE) });
  }

  function mapOfferingsForSKUError(offering) {
    return valueRequired(offering) && getSKUError({ channel, sku: offering.get(SKU) });
  }

  function mapOfferingsForPriceError(offering) {
    return valueRequired(offering) && getPriceError({ channel, price: offering.get(PRICE) });
  }

  function mapOfferingsForCAPError(offering) {
    return valueRequired(offering) && getCAPError({ channel, cap: offering.get(CAP) });
  }

  function mapOfferingsForCPIError(offering) {
    return valueRequired(offering) && getCPIError({ channel, cpi: offering.get(CPI) });
  }

  function mapOfferingsForQuantityError(offering) {
    return valueRequired(offering) && getQuantityError({ channel, quantity: offering.get(QUANTITY), allowZero: true });
  }

  function mapOfferingsForWeightError(offering) {
    return valueRequired(offering) && getWeightError({ channel, weight: offering.get(WEIGHT) });
  }

  switch (type) {
    case CAP: {
      return offerings.map(mapOfferingsForCAPError);
    }

    case CPI: {
      return offerings.map(mapOfferingsForCPIError);
    }

    case PRICE: {
      return offerings.map(mapOfferingsForPriceError);
    }

    case QUANTITY: {
      return channel === ETSY && offerings.reduce(reduceOfferingsForQuantity, true)
        ? (
          variations.reduce(reduceVariationsForIndividualProperty, false) &&
          PRODUCT_ERRORS.QUANTITY.IS_ZERO_OFFERING
        )
        : offerings.map(mapOfferingsForQuantityError);
    }

    case SKU: {
      return offerings.map(mapOfferingsForSKUError);
    }

    case BARCODE: {
      return offerings.map(mapOfferingsForBarcodeError);
    }

    case VISIBILITY: {
      return (
        !!getSize(offerings) &&
        offerings.reduce(reduceOfferingsForVisibility, true) &&
        ERRORS.NO_VISIBLE
      );
    }

    case WEIGHT: {
      return offerings.map(mapOfferingsForWeightError);
    }

    default: {
      return undefined;
    }
  }
}

export function getOfferingsErrors(profile) {
  const variations = profile.get(VARIATIONS);
  const offerings = profile.get('offerings');
  const channel = profile.get('channel');
  let errors = profile.get('errors', EMPTY_MAP);

  for (const type in OFFERINGS[channel]) {
    if (get([channel, type, 'individualProperty'])(OFFERINGS)) {
      errors = errors.set(type,
        getOfferingError({
          type,
          profile,
          offerings,
          variations,
        })
      );
    }
  }

  return errors;
}

export function getVariationsErrors(profile) {
  const channel = profile.get('channel');
  const errors = profile.get('errors', EMPTY_MAP);
  const offeringsNumberExceeded = ERRORS.OFFERINGS_NUMBER_EXCEEDED[channel];
  const optionsNumberExceeded = ERRORS.OPTIONS_NUMBER_EXCEEDED[channel];
  let variationsErrors = errors.get(VARIATIONS, EMPTY_MAP);

  function getVariationOptionsError({ options = EMPTY_LIST, offerings = EMPTY_LIST }) {
    switch (channel) {
      case ETSY: {
        if (getSize(options) > MAXIMUM_NUMBER_OF_OPTIONS[channel]) {
          return ERRORS.OPTIONS_NUMBER_EXCEEDED[channel];
        }

        break;
      }

      default: {
        break;
      }
    }

    return (
      !!getSize(options) &&
      MAXIMUM_NUMBER_OF_OFFERINGS.hasOwnProperty(channel) &&
      getSize(offerings) > MAXIMUM_NUMBER_OF_OFFERINGS[channel] &&
      offeringsNumberExceeded
    );
  }

  function setNoOptionsError(result, variation, variationIndex) {
    if (!getSize(variation.get('options'))) {
      return result.setIn([variationIndex, 'options'], ERRORS.NO_OPTIONS);
    } else if (result.getIn([variationIndex, 'options']) === ERRORS.NO_OPTIONS) {
      return result.deleteIn([variationIndex, 'options']);
    } else {
      return result;
    }
  }

  // function setScaleError(source, variation, variationIndex) {
  //   let result = source;
  //   const scale = variation.get('scale');

  //   if (scale === 0) {
  //     result = result.setIn([variationIndex, 'scale'], true);
  //   } else {
  //     const category = profile.get(CATEGORY);
  //     const property = variation.get('property');

  //     if (!scale && !!getSize(getAvailable({ category, property }).availableScales)) {
  //       result = result.setIn([variationIndex, 'scale'], true);
  //     } else {
  //       result = result.deleteIn([variationIndex, 'scale']);
  //     }
  //   }

  //   return setNoOptionsError(result, variation, variationIndex);
  // }

  function setOptionsLimitError(result, variation, variationIndex) {
    if (
      getVariationOptionsError({ channel, options: variation.get('options') })
    ) {
      return result.setIn([variationIndex, 'options'], optionsNumberExceeded);
    } else if (
      result.getIn([variationIndex, 'options']) === optionsNumberExceeded ||
      result.getIn([variationIndex, 'options']) === offeringsNumberExceeded
    ) {
      return result.deleteIn([variationIndex, 'options']);
    } else {
      return result;
    }
  }

  function setOfferingsLimitError(result, variation, variationIndex) {
    if (
      getVariationOptionsError({
        channel,
        offerings: profile.get('offerings'),
        options: variation.get('options'),
      })
    ) {
      return result.setIn([variationIndex, 'options'], offeringsNumberExceeded);
    } else if (result.getIn([variationIndex, 'options']) === offeringsNumberExceeded) {
      return result.deleteIn([variationIndex, 'options']);
    } else {
      return result;
    }
  }

  switch (channel) {
    case ETSY: {
      // variationsErrors = profile.get(VARIATIONS).reduce(setScaleError, variationsErrors);
      variationsErrors = profile.get(VARIATIONS).reduce(setNoOptionsError, variationsErrors);

      if (!profile.get('hasBothIndividual')) {
        return errors.set(VARIATIONS,
          profile.get(VARIATIONS).reduce(setOptionsLimitError, variationsErrors)
        );
      }

      break;
    }

    case VELA:
    case SHOPIFY: {
      variationsErrors = profile.get(VARIATIONS).reduce(setNoOptionsError, variationsErrors);
      break;
    }

    default: {
      break;
    }
  }

  return errors.set(VARIATIONS,
    profile.get(VARIATIONS).reduce(setOfferingsLimitError, variationsErrors)
  );
}

export function setProfileErrors({ type, profile: source, ignoreCategory = false }) {
  let profile = source;
  let errors = profile.get('errors', EMPTY_MAP);
  const channel = profile.get('channel');

  switch (type) {
    case PROFILE.VARIATIONS: {
      const variations = profile.get(VARIATIONS);

      for (let variationIndex = 0; variationIndex < NUMBER_OF_VARIATIONS[channel]; variationIndex++) {
        const variation = variations.get(variationIndex);
        let error = EMPTY_MAP;

        if (variation) {
          error = error.set('name',
            getVariationNameError({
              channel,
              variations,
              variationIndex,
              name: variation.get('formattedName'),
            })
          );
        }

        errors = errors.setIn([VARIATIONS, variationIndex], error);
      }

      if (!ignoreCategory && channel === ETSY && profile.get(CATEGORY) === ZERO) {
        errors = errors.set(CATEGORY, true);
      }

      profile = profile.set('errors', errors);
      profile = profile.set('errors', getOptionsErrors(profile));
      profile = profile.set('errors', getVariationsErrors(profile));
      profile = profile.set('errors', getOfferingsErrors(profile));
      break;
    }

    case PROFILE.TAGS: {
      profile = profile.setIn(['errors', TAGS], getSize(profile.get(TAGS)) > MAX_NUMBER_OF_TAGS[channel]);
      break;
    }

    case PROFILE.SALES: {
      profile = profile
        .set('errors', errors)
        .setIn(['errors', TAGS], getSize(profile.get(TAGS)) > MAX_NUMBER_OF_TAGS[channel])
        .setIn(['errors', PRICE], !profile.get(PRICE) || profile.get(PRICE) <= 0);
      break;
    }

    default: {
      profile = profile.set('errors', errors);
      break;
    }
  }

  return profile.set('invalid', reduceErrors(profile.get('errors')));
}
