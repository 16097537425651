import { useEffect, useRef } from 'react';

import { useMount } from './mount';

export function useTimeout({ callback: handler, timeout }) {
  const timeoutId = useRef();
  const mounted = useMount();

  function cleanUp() {
    if (timeoutId.current) {
      timeoutId.current = clearTimeout(timeoutId.current);
    }
  }

  useEffect(
    function effect() {
      return cleanUp;
    },
    []
  );

  useEffect(
    function effect() {
      cleanUp();

      function callback() {
        if (!mounted.current) return;

        handler();
      }

      if (Number.isFinite(timeout) && timeout > 0) {
        timeoutId.current = setTimeout(callback, timeout);
      }
    },
    [handler, timeout]
  );
}
