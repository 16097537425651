import request from './request';

export async function getConfig() {
  return request.get({ url: '/config' });
}

export async function getJWTData() {
  return request.get({ url: '/users/jwtdata' });
}

export async function getUserData() {
  return request.get({ url: '/users', doNotLogout: true });
}

export async function setProfileValue(payload) {
  return request.put({ url: '/users', payload });
}
