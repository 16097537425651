import { List, Map } from 'immutable';

import { shapePersonalizationForApp } from '../product/shapeForApp';
import { newProfile } from './profile';
import { reduce } from '../iterable/reduce';

import { SEO_URL_HANDLE_ENABLED } from '../../constants/featureFlags';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { DEFAULTS } from '../../constants';
import { PROFILE } from '../../constants/profiles';
import ATTRIBUTES from '../../constants/attributes';
import {
  ATTRIBUTE_DEFAULTS,
  DEFAULT_OPTION,
  DEFAULT_VARIANT,
  NEW,
  OVERSET,
  SECTIONS,
} from '../../constants/product';

function makeValue(value) {
  return Map({ value });
}

function reduceDefaults(result, value, key) {
  result[key] = makeValue(value);
  return result;
}

const DEFAULT_VALUES = reduce(DEFAULTS, {}, reduceDefaults);

export function newDetails({ channel }) {
  switch (channel) {
    case ETSY: {
      return Map({
        [ATTRIBUTES.CATEGORY]: DEFAULT_VALUES.ZERO,
        [ATTRIBUTES.DIGITAL]: DEFAULT_VALUES.FALSE,
        [ATTRIBUTES.IS_SUPPLY]: DEFAULT_VALUES.NULL,
        [ATTRIBUTES.MATERIALS]: DEFAULT_VALUES.EMPTY_ORDERED_MAP,
        [ATTRIBUTES.PRODUCTION_PARTNERS]: DEFAULT_VALUES.EMPTY_LIST,
        [ATTRIBUTES.SECTION]: DEFAULT_VALUES.NULL,
        [ATTRIBUTES.SHOULD_AUTO_RENEW]: DEFAULT_VALUES.FALSE,
        [ATTRIBUTES.TAXONOMY_ATTRIBUTES]: Map(),
        [ATTRIBUTES.WHEN_MADE]: DEFAULT_VALUES.NULL,
        [ATTRIBUTES.WHO_MADE]: DEFAULT_VALUES.NULL,
      });
    }

    case SHOPIFY: {
      return Map({
        [ATTRIBUTES.COLLECTIONS]: DEFAULT_VALUES.EMPTY_LIST,
        [ATTRIBUTES.PRODUCT_TYPE]: DEFAULT_VALUES.NULL,
        [ATTRIBUTES.VENDOR]: DEFAULT_VALUES.NULL,
      });
    }

    default: {
      return Map();
    }
  }
}

export function newInventory({ channel, emptyValues }) {
  const inventory = Map({
    [ATTRIBUTES.PRICE]: emptyValues ? DEFAULT_VALUES.EMPTY_STRING : DEFAULT_VALUES.ZERO_CENT,
    [ATTRIBUTES.SKU]: DEFAULT_VALUES.EMPTY_STRING,
  });

  switch (channel) {
    case ETSY: {
      return inventory.set(ATTRIBUTES.QUANTITY,
        emptyValues
          ? DEFAULT_VALUES.EMPTY_STRING
          : DEFAULT_VALUES.ONE
      );
    }

    case SHOPIFY: {
      const DEFAULT_VALUE = Map({
        options: List([DEFAULT_OPTION]),
        variants: List([DEFAULT_VARIANT]),
      });

      return inventory
        .set(ATTRIBUTES.BARCODE, DEFAULT_VALUES.EMPTY_STRING)
        .set(ATTRIBUTES.CAP, DEFAULT_VALUES.EMPTY_STRING)
        .set(ATTRIBUTES.CHARGE_TAX, DEFAULT_VALUES.TRUE)
        .set(ATTRIBUTES.CONTINUE_SELLING, DEFAULT_VALUES.FALSE)
        .set(ATTRIBUTES.COUNTRY_CODE, DEFAULT_VALUES.NULL)
        .set(ATTRIBUTES.CPI, DEFAULT_VALUES.EMPTY_STRING)
        .set(ATTRIBUTES.DEFAULT_VALUE, DEFAULT_VALUE)
        .set(ATTRIBUTES.HS_CODE, DEFAULT_VALUES.NULL)
        .set(ATTRIBUTES.PHYSICAL, DEFAULT_VALUES.TRUE)
        .set(ATTRIBUTES.QUANTITY, emptyValues ? DEFAULT_VALUES.EMPTY_STRING : DEFAULT_VALUES.ZERO)
        .set(ATTRIBUTES.TRACK_QUANTITY, DEFAULT_VALUES.TRUE)
        .set(ATTRIBUTES.UNIT, DEFAULT_VALUES.UNIT)
        .set(ATTRIBUTES.WEIGHT, emptyValues ? DEFAULT_VALUES.EMPTY_STRING : DEFAULT_VALUES.ZERO);
    }

    default: {
      return inventory;
    }
  }
}

export function newShipping({ channel }) {
  switch (channel) {
    case ETSY: {
      return Map({
        [ATTRIBUTES.HEIGHT]: DEFAULT_VALUES.NULL,
        [ATTRIBUTES.LENGTH]: DEFAULT_VALUES.NULL,
        [ATTRIBUTES.PROFILE_ID]: DEFAULT_VALUES.NULL,
        [ATTRIBUTES.PROFILE_TYPE]: DEFAULT_VALUES.NULL,
        [ATTRIBUTES.RETURN_POLICY]: DEFAULT_VALUES.NULL,
        [ATTRIBUTES.WEIGHT]: DEFAULT_VALUES.NULL,
        [ATTRIBUTES.WIDTH]: DEFAULT_VALUES.NULL,
      });
    }

    default: {
      return undefined;
    }
  }
}

export function newProduct({ channel, emptyValues }) {
  let product = Map({
    channel,
    productId: NEW,
    [SECTIONS.DESCRIPTION]: makeValue(ATTRIBUTE_DEFAULTS[channel][ATTRIBUTES.DESCRIPTION]),
    [SECTIONS.PHOTOS]: makeValue(ATTRIBUTE_DEFAULTS[channel][ATTRIBUTES.PHOTOS]),
    [SECTIONS.VIDEOS]: makeValue(ATTRIBUTE_DEFAULTS[channel][ATTRIBUTES.VIDEOS]),
    [SECTIONS.TAGS]: makeValue(ATTRIBUTE_DEFAULTS[channel][ATTRIBUTES.TAGS]),
    [SECTIONS.TITLE]: makeValue(ATTRIBUTE_DEFAULTS[channel][ATTRIBUTES.TITLE]),
  });

  switch (channel) {
    case ETSY: {
      product = product
        .set(SECTIONS.DETAILS, newDetails({ channel }))
        .set(SECTIONS.INVENTORY, newInventory({ channel, emptyValues }))
        .set(SECTIONS.PERSONALIZATION, shapePersonalizationForApp({}))
        .set(SECTIONS.SHIPPING, newShipping({ channel }))
        .set(SECTIONS.VARIATIONS, newProfile({ channel, forProduct: true, type: PROFILE.VARIATIONS }));

      break;
    }

    case SHOPIFY: {
      product = product
        .set(SECTIONS.DETAILS, newDetails({ channel }))
        .set(SECTIONS.INVENTORY, newInventory({ channel, emptyValues }))
        .set(SECTIONS.VARIATIONS, newProfile({ channel, forProduct: true, type: PROFILE.VARIATIONS }))
        .set(SECTIONS.SEO, Map({
          [ATTRIBUTES.PAGE_TITLE]: DEFAULT_VALUES.EMPTY_STRING.set(OVERSET, true),
          [ATTRIBUTES.META_DESCRIPTION]: DEFAULT_VALUES.EMPTY_STRING.set(OVERSET, true),
        }));

      if (SEO_URL_HANDLE_ENABLED) {
        product = product.setIn([SECTIONS.SEO, ATTRIBUTES.URL_HANDLE], DEFAULT_VALUES.EMPTY_STRING);
      }

      break;
    }

    default: {
      break;
    }
  }

  return product;
}
