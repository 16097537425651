import { List, Map, OrderedMap, Set } from 'immutable';
import moment from 'moment';

import { isValidNumber, toFixed, toString } from '../number';
import { findAnalogue, findAnalogueScale } from '../variations/findAnalogue';
import { filterSectionsForChannels } from './listingsProfiles';
import { getIndividualVariations } from '../variations/getIndividualVariations';
import { setProfileErrors } from '../validations/profile';
import { tagsFromArray } from '../tags';
import { getAvailable } from '../taxonomy';
import { sortShops } from '../product/shapeForApp';
import { shapeId } from '../listings/listings';
import { getSize } from '../iterable/getSize';
import { find } from '../iterable/find';
import { get } from '../iterable/get';

import { INDIVIDUAL, INDIVIDUAL_FLAGS, NEW_PROFILE_ERRORS, PROFILE } from '../../constants/profiles';
import { CUSTOM_PROPERTY_IDS } from '../../constants/taxonomy';
import { CHANNEL_BY_ID, ETSY, SHOPIFY } from '../../constants/channels';
import { DEFAULTS } from '../../constants';
import { FORMS } from '../../constants/product';
import {
  BARCODE,
  CAP,
  CHARGE_TAX,
  CONTINUE_SELLING,
  COUNTRY_CODE,
  CPI,
  HS_CODE,
  PHOTOS,
  PHYSICAL,
  PRICE,
  QUANTITY,
  SKU,
  TRACK_QUANTITY,
  UNIT,
  WEIGHT,
} from '../../constants/attributes';

export function sortEtsyVariations(x, y) {
  function getKey(key, instance) {
    return Map.isMap(instance)
      ? instance.get(key)
      : typeof instance === 'object' && instance !== null
        ? instance[key]
        : undefined;
  }

  return getKey('first', y) - getKey('first', x);
}

function getOptionImageHash({ option, imagesById, photos }) {
  const [imageHash, channelImageId, velaImageId] = get('imageHash', 'channelImageId', 'velaImageId')(option);
  let result;

  function findImage(image) {
    return get('channel_image_id')(image) === channelImageId;
  }

  function findPhoto(value, key) {
    return function findItem(item) {
      return get(key)(item) === value;
    };
  }

  if (imageHash) {
    result = imageHash;
  } else if (imagesById) {
    if (velaImageId) {
      result = get([velaImageId, 'hash'])(imagesById);
    } else if (channelImageId) {
      const sameImage = find(imagesById, findImage);

      if (sameImage) {
        result = get('hash')(sameImage);
      }
    }
  } else if (photos && (velaImageId || channelImageId)) {
    const value = velaImageId || channelImageId;
    const key = velaImageId ? 'id' : 'channel_image_id';
    const photo = photos.find(findPhoto(value, key));

    if (photo) {
      const hash = get('hash')(photo);
      result = hash || null;
    }
  }

  return result || null;
}

function getOfferingImageHashAndImageId({ imagesById, offering, photos }) {
  const [
    velaImageId,
    imageHash,
    channelImageId,
  ] = get('vela_image_id', 'shopify_image_hash', 'shopify_image_id')(offering);

  const result = {
    imageId: null,
    imageHash: null,
  };

  function findPhoto(photo) {
    return get('id')(photo) === velaImageId;
  }

  if (imageHash) {
    result.imageHash = imageHash;
  } else if (velaImageId) {
    if (imagesById) {
      const hash = get([velaImageId, 'hash'])(imagesById);
      result.imageHash = hash || null;
    } else if (photos) {
      const photo = photos.find(findPhoto);

      if (photo) {
        const hash = get('hash')(photo);
        result.imageHash = hash || null;
      }
    }
  } else if (channelImageId) {
    result.imageId = channelImageId;
  }

  return result;
}

function fixCustomProperty({ property, variationIndex }) {
  return property === CUSTOM_PROPERTY_IDS[variationIndex]
    ? property
    : CUSTOM_PROPERTY_IDS[variationIndex];
}

export function shapeVariationForApp({ channel, category, imagesById, photos }) {
  function mapOptions(option) {
    const unifiedOption = {};

    switch (channel) {
      case ETSY: {
        const [id, value, valueId] = get('id', 'value', 'valueId')(option);
        unifiedOption.id = id;
        unifiedOption.value = value;
        unifiedOption.valueId = valueId;
        unifiedOption.imageHash = getOptionImageHash({ option, imagesById, photos });
        break;
      }

      case SHOPIFY: {
        unifiedOption.value = option.trim();
        break;
      }

      default:
        break;
    }

    return Map(unifiedOption);
  }

  return function map(variation, variationIndex) {
    const unifiedVariation = {};

    switch (channel) {
      case ETSY: {
        const [
          formattedName,
          id,
          options,
          propertyId,
          individualPrice = false,
          individualQuantity = false,
          individualSKU = false,
          scalingOptionId,
        ] = get(
          'formattedName',
          'id',
          'options',
          'propertyId',
          'influencesPrice',
          'influencesQuantity',
          'influencesSku',
          'scalingOptionId',
        )(variation);

        const scale = isValidNumber(scalingOptionId) ? parseInt(scalingOptionId, 10) : undefined;
        const property = isValidNumber(propertyId) ? parseInt(propertyId, 10) : undefined;
        unifiedVariation.formattedName = formattedName;
        unifiedVariation.id = id;
        unifiedVariation.individualPrice = individualPrice;
        unifiedVariation.individualQuantity = individualQuantity;
        unifiedVariation.individualSKU = individualSKU;
        unifiedVariation.options = List(options.map(mapOptions));
        unifiedVariation.property = property;

        if (category && category !== DEFAULTS.ZERO) {
          if (!CUSTOM_PROPERTY_IDS.includes(property)) {
            const { availableScales, availableVariations } = getAvailable({ category, property, scale });
            const sameVariation = availableVariations.find(findAnalogue({ id: property }));

            if (sameVariation) {
              if (sameVariation.name !== formattedName) {
                unifiedVariation.formattedName = sameVariation.name;
              }
            } else {
              const analogueVariation = availableVariations.find(findAnalogue({ name: formattedName }));

              if (analogueVariation && analogueVariation.id !== property) {
                unifiedVariation.property = analogueVariation.id;
              }
            }

            if (getSize(availableScales)) {
              if (!scale) {
                unifiedVariation.scale = 0;
              } else {
                const analogueScale = availableScales.find(findAnalogueScale(scale));

                if (analogueScale && analogueScale.id !== scale) {
                  unifiedVariation.scale = analogueScale.id;
                } else {
                  unifiedVariation.scale = scale;
                }
              }
            } else if (scale) {
              unifiedVariation.scale = scale;
            }
          } else {
            unifiedVariation.formattedName = formattedName;
            unifiedVariation.property = fixCustomProperty({ property, variationIndex });
          }
        } else {
          unifiedVariation.formattedName = formattedName;
          unifiedVariation.property = CUSTOM_PROPERTY_IDS.includes(property)
            ? fixCustomProperty({ property, variationIndex })
            : property;
        }

        break;
      }

      case SHOPIFY: {
        const [
          id,
          values,
          formattedName,
        ] = get('id', 'shopify_values', 'shopify_name')(variation);
        const options = values.map(mapOptions);

        unifiedVariation.formattedName = formattedName;
        unifiedVariation.id = id === undefined ? -1 - variationIndex : id;
        unifiedVariation.options = List(options);

        break;
      }

      default:
        break;
    }

    return Map(unifiedVariation);
  };
}

export function shapeOfferingForApp({ channel, forProduct = false, imagesById, photos, variations }) {
  function mapEtsyOptions(option) {
    const [optionId, variationId] = get('optionId', 'variationId')(option);

    function findVariationById(variation) {
      return variationId === get('id')(variation);
    }

    function findOptionById(variationOption) {
      return optionId === get('id')(variationOption);
    }

    const sameVariation = variations.find(findVariationById);
    const options = get('options')(sameVariation);
    const sameOption = options.find(findOptionById);
    const name = get('value')(sameOption);

    return Map({
      name,
      optionId,
      variationId,
    });
  }

  function reduceShopifyOptions(result, name) {
    return name
      ? result.push(Map({ name: name.trim() }))
      : result;
  }

  return function map(offering, offeringIndex) {
    const unset = forProduct ? DEFAULTS.EMPTY_STRING : null;

    switch (channel) {
      case ETSY: {
        const [
          id,
          price,
          quantity,
          sku,
          visibility,
          variationOptions,
        ] = get('id', PRICE, QUANTITY, SKU, 'visibility', 'variationOptions')(offering);

        return Map({
          id,
          visibility,
          sku: sku || unset,
          price: toFixed(price, 2) || unset,
          quantity: toString(quantity) || unset,
          options: List(variationOptions).map(mapEtsyOptions),
        });
      }

      case SHOPIFY: {
        const [
          id,
          sku,
          cpi,
          price,
          weight,
          option1,
          option2,
          option3,
          barcode,
          unit,
          chargeTax,
          cap,
          physical,
          quantity,
          hsCode,
          inventoryPolicy,
          countryCode,
          inventoryManagement,
        ] = get(
          'id',
          'shopify_sku',
          'shopify_cost',
          'shopify_price',
          'shopify_weight',
          'shopify_option1',
          'shopify_option2',
          'shopify_option3',
          'shopify_barcode',
          'shopify_weight_unit',
          'shopify_taxable',
          'shopify_compare_at_price',
          'shopify_requires_shipping',
          'shopify_inventory_quantity',
          'shopify_harmonized_system_code',
          'shopify_inventory_policy',
          'shopify_country_code_of_origin',
          'shopify_inventory_management',
        )(offering);

        return Map({
          [BARCODE]: barcode || unset,
          [CAP]: toFixed(cap, 2) || unset,
          [CHARGE_TAX]: chargeTax,
          [CONTINUE_SELLING]: inventoryPolicy === 'continue',
          [COUNTRY_CODE]: countryCode || unset,
          [CPI]: toFixed(cpi, 2) || unset,
          [HS_CODE]: hsCode || unset,
          [PHYSICAL]: physical,
          [PRICE]: toFixed(price, 2) || unset,
          [QUANTITY]: quantity || unset,
          [SKU]: sku || unset,
          [TRACK_QUANTITY]: inventoryManagement === SHOPIFY,
          [WEIGHT]: weight || unset,
          [UNIT]: unit || unset,
          id: id || -1 - offeringIndex,
          options: [option1, option2, option3].reduce(reduceShopifyOptions, List()),
          ...getOfferingImageHashAndImageId({ imagesById, offering, photos }),
        });
      }

      default: {
        return Map();
      }
    }
  };
}

// DEV-1481 corrupted data fix
function reduceEtsyOfferings({ channel, variations }) {
  return function reduceOfferings(result, offering) {
    if (getSize(get('variationOptions')(offering))) {
      result.push(shapeOfferingForApp({ channel, variations })(offering));
    }

    return result;
  };
}

export function shapeVariationsProfileForApp({ profile: source, channel, product }) {
  const [
    title,
    value,
    invalid,
    templateId,
    productsCount,
  ] = get('title', 'value', 'invalid', 'templateId', 'productsCount')(source);

  const photos = product?.get(PHOTOS);
  const profile = {
    title,
    channel,
    invalid,
    id: String(templateId),
    listingsCount: parseInt(productsCount, 10) || 0,
    errors: NEW_PROFILE_ERRORS[PROFILE.VARIATIONS],
  };

  function reduceOfferingsToTrackQuantity(tracking, offering) {
    return tracking && offering.get(TRACK_QUANTITY);
  }

  switch (channel) {
    case ETSY: {
      const [
        category,
        variations,
        offerings,
      ] = get('taxonomyId', 'variations', 'offerings')(value);

      profile.category = category;
      profile.variations = variations.filter(get('formattedName')).map(shapeVariationForApp({ channel, category, photos }));
      profile.offerings = offerings.reduce(reduceEtsyOfferings({ channel, variations }), []);

      const numberOfVariations = getSize(profile.variations);
      const individual = getIndividualVariations({
        properties: INDIVIDUAL_FLAGS[channel],
        variations: profile.variations,
      });

      profile[INDIVIDUAL.PRICE] = individual[INDIVIDUAL.PRICE].size === numberOfVariations;
      profile[INDIVIDUAL.QUANTITY] = individual[INDIVIDUAL.QUANTITY].size === numberOfVariations;
      profile[INDIVIDUAL.SKU] = individual[INDIVIDUAL.SKU].size === numberOfVariations;
      profile.hasBothIndividual = (
        profile[INDIVIDUAL.PRICE] ||
        profile[INDIVIDUAL.QUANTITY] ||
        profile[INDIVIDUAL.SKU]
      );

      break;
    }

    case SHOPIFY: {
      const [options, variants] = get('options', 'variants')(value);
      profile[INDIVIDUAL.BARCODE] = true;
      profile[INDIVIDUAL.PRICE] = true;
      profile[INDIVIDUAL.SHIPPING] = true;
      profile[INDIVIDUAL.SKU] = true;
      profile.variations = options.filter(get('shopify_name')).map(shapeVariationForApp({ channel }));
      profile.offerings = variants.map(shapeOfferingForApp({ channel, forProduct: !!product, photos }));
      profile[TRACK_QUANTITY] = profile.offerings.reduce(reduceOfferingsToTrackQuantity, true);
      break;
    }

    default: {
      break;
    }
  }

  if (Array.isArray(profile.offerings)) {
    profile.offerings = List(profile.offerings);
  }

  if (Array.isArray(profile.variations)) {
    profile.variations = List(profile.variations);
  }

  return Map(profile);
}

export function shapeTagsProfileForApp({ profile, channel }) {
  const {
    id,
    tags,
    invalid,
    profile_name: title,
    products_count: productsCount,
  } = profile;

  return Map({
    id,
    title,
    channel,
    invalid,
    listingsCount: parseInt(productsCount, 10) || 0,
    tags: tagsFromArray(tags),
  });
}

export function shapeSaleProfileForApp({ profile, channel }) {
  const {
    id,
    name: title,
    price_value: price,
    price_type: priceType,
    products_count: productsCount,
    convert_to_cap: isConvertPriceToCap,
    title_value: titleValue,
    title_operation: titleOperation,
    description_value: descriptionValue,
    description_operation: descriptionOperation,
    start_date: startDate,
    end_date: endDate,
    status,
    tags = [],
    cents_value: cents,
    tags_operation: tagsOperation,
  } = profile;
  return Map({
    id,
    title,
    status,
    channel,
    startDate: moment(startDate).valueOf(),
    endDate: moment(endDate).valueOf(),
    price: parseInt(price, 10),
    priceType,
    listingsCount: parseInt(productsCount, 10) || 0,
    isConvertPriceToCap,
    titleValue,
    titleOperation,
    descriptionValue,
    descriptionOperation,
    tags: tagsFromArray(tags),
    newTags: OrderedMap(),
    cents: cents || DEFAULTS.EMPTY_STRING,
    tagsOperation,
  });
}

export function shapeListingsProfileForApp({ profile, userShops }) {
  const { id, connected_listings: listingsCount, name: title } = profile;

  function reduceTemplates(result, template) {
    const shopId = shapeId(template.shopId);
    const productId = shapeId(template.id);
    const db = template.userDb;

    if (!isValidNumber(productId) || (userShops && userShops.getIn(['byId', shopId, 'db']) !== db)) {
      return result;
    }

    const channel = CHANNEL_BY_ID[template.channel_id];
    result.channels = result.channels.add(channel);
    result.shopIds = result.shopIds.add(shopId);
    result.templates = result.templates.push(Map({ channel, db, productId, shopId }));
    return result;
  }

  function filterSectionsByExcluded(section) {
    return !profile.excluded_sections[section];
  }

  function sortTemplates(x, y) {
    return sortShops({ sorted: userShops.get('options') })(x.get('shopId'), y.get('shopId'));
  }

  const { channels, shopIds, templates } = profile.templates.reduce(
    reduceTemplates,
    { channels: Set(), shopIds: Set(), templates: List() }
  );

  const sections = Set(
    FORMS
      .filter(filterSectionsForChannels({ channels }))
      .filter(filterSectionsByExcluded)
  );

  return Map({
    channels,
    id: shapeId(id),
    listingsCount: parseInt(listingsCount, 10) || 0,
    sections,
    shopIds,
    title,
    templates: userShops
      ? templates.sort(sortTemplates)
      : templates,
  });
}

export function shapeProfileForApp({ type, profile, ...rest }) {
  switch (type) {
    case PROFILE.LISTINGS: {
      return shapeListingsProfileForApp({ profile, ...rest });
    }
    case PROFILE.SALES: {
      return shapeSaleProfileForApp({ profile, ...rest });
    }

    case PROFILE.TAGS: {
      return shapeTagsProfileForApp({ profile, ...rest });
    }

    case PROFILE.VARIATIONS: {
      return shapeVariationsProfileForApp({ profile, ...rest });
    }

    default: {
      return profile;
    }
  }
}

export function shapeListingsForApp({ response, type }) {
  function getThumbnailUrl(product) {
    switch (type) {
      case PROFILE.LISTINGS: {
        return product.thumbnail_url;
      }

      default: {
        return product.image;
      }
    }
  }

  function reduceProducts(result, product) {
    const { id, title } = product;

    return result.push(
      Map({
        productId: shapeId(id),
        title,
        thumbnailUrl: getThumbnailUrl(product),
      })
    );
  }

  try {
    switch (type) {
      case PROFILE.LISTINGS: {
        return response.data.reduce(reduceProducts, List());
      }

      default: {
        return response.connectedProducts[0].products.reduce(reduceProducts, List());
      }
    }
  } catch (error) {
    return undefined;
  }
}

export function shapeProfilesForApp({ channel, profiles, state: source, type, userShops }) {
  const state = source
    .set('byId', Map())
    .set('ids', List())
    .set('listings', Map())
    .set('titles', Set());

  function getId(profile) {
    switch (type) {
      case PROFILE.VARIATIONS: {
        return shapeId(profile.templateId);
      }

      default: {
        return shapeId(profile.id);
      }
    }
  }

  function getProfile(profile) {
    switch (type) {
      case PROFILE.LISTINGS: {
        return shapeProfileForApp({ profile, type, userShops });
      }

      default: {
        return setProfileErrors({
          profile: shapeProfileForApp({ channel, type, profile }),
          type,
        });
      }
    }
  }

  function reduceProfiles(reduction, item) {
    const profileId = getId(item);
    const profile = getProfile(item);

    function updateEditing(editing) {
      return editing.get(profileId) === null
        ? editing.set(profileId, profile)
        : editing;
    }

    function updateById(byId) {
      return byId.set(profileId, profile);
    }

    function updateIds(ids) {
      return ids.push(profileId);
    }

    function updateListings(listings) {
      return source.hasIn(['listings', profileId])
        ? listings.set(profileId, source.getIn(['listings', profileId]))
        : listings;
    }

    function updateTitles(titles) {
      return titles.add(profile.get('title'));
    }

    let result = reduction
      .update('byId', updateById)
      .update('ids', updateIds);

    if (result.has('editing')) {
      result = result.update('editing', updateEditing);
    }

    if (result.has('listings')) {
      result = result.update('listings', updateListings);
    }

    if (result.has('titles')) {
      result = result.update('titles', updateTitles);
    }

    return result;
  }

  switch (type) {
    case PROFILE.LISTINGS: {
      return profiles.data.reduce(reduceProfiles, state);
    }

    case PROFILE.VARIATIONS: {
      return (profiles.profiles || profiles.templates).reduce(reduceProfiles, state);
    }

    default: {
      return profiles.profiles.reduce(reduceProfiles, state);
    }
  }
}
