import { List, OrderedMap } from 'immutable';

import { toLowerCase, trim } from './string';
import { isTruthy } from './bool';
import { getSize } from './iterable/getSize';

import { MAX_NUMBER_OF_TAGS, MAX_NUMBER_OF_MATERIALS } from '../constants/validations';
import { DEFAULTS, SEPARATOR } from '../constants';

export function addOrRemoveTags(result, value, key) {
  return result.has(key)
    ? result.delete(key)
    : result.set(key, value);
}

export function tagsArrayFromString(string) {
  return string.split(/^\s+|\s*,\s*|\s+$/).filter(isTruthy);
}

export function tagsFromInput({ value, tags, limit }) {
  function reduceTags(result, tag) {
    if (limit !== undefined && tags !== undefined && getSize(result) + getSize(tags) >= limit) {
      return result;
    }

    const key = toLowerCase(tag);
    return tags?.has(key) || result.has(key)
      ? result
      : result.set(key, tag);
  }

  return tagsArrayFromString(value).reduce(reduceTags, OrderedMap());
}

export function parseTagsFromInput({ channel, value, tags }) {
  return tagsFromInput({ value, tags, limit: MAX_NUMBER_OF_TAGS[channel] });
}

export function parseMaterialsFromInput({ channel, value, materials: tags }) {
  return tagsFromInput({ value, tags, limit: MAX_NUMBER_OF_MATERIALS[channel] });
}

export function tagsFromArray(array) {
  function reduceTags(tags, item) {
    const tag = trim(item);
    return tag
      ? tags.set(toLowerCase(tag), tag)
      : tags;
  }

  return array.reduce(reduceTags, OrderedMap());
}

export function tagsToList({ channel, tags }) {
  return OrderedMap.isOrderedMap(tags)
    ? tags.toList()
    : Array.isArray(tags)
      ? List(tags.map(trim))
      : typeof tags === 'string'
        ? parseTagsFromInput({ channel, value: tags, tags: OrderedMap() }).toList()
        : List();
}

export function removeTagFromInput({ tag, input }) {
  return input.replace(tag, DEFAULTS.EMPTY_STRING).replace(/^,(\s?)+|,(\s?)+(?=,)|,(\s?)+$/, DEFAULTS.EMPTY_STRING);
}

export function tagsToString({ channel, tags }) {
  return OrderedMap.isOrderedMap(tags)
    ? tags.toList().slice(0, MAX_NUMBER_OF_TAGS[channel]).join(SEPARATOR.COMMA)
    : Array.isArray(tags)
      ? tags.slice(0, MAX_NUMBER_OF_TAGS[channel]).join(SEPARATOR.COMMA)
      : typeof tags === 'string'
        ? tags
        : DEFAULTS.EMPTY_STRING;
}

export function materialsToString({ channel, materials }) {
  return OrderedMap.isOrderedMap(materials)
    ? materials.toList().slice(0, MAX_NUMBER_OF_MATERIALS[channel]).join(SEPARATOR.COMMA)
    : Array.isArray(materials)
      ? materials.slice(0, MAX_NUMBER_OF_MATERIALS[channel]).joi(SEPARATOR.COMMA)
      : typeof materials === 'string'
        ? materials
        : DEFAULTS.EMPTY_STRING;
}

export function filterNewTags(tags) {
  return function filter(tag, key) {
    return !tags.has(key);
  };
}

export function tagsToArray(tags) {
  return tags.toList().toArray();
}
