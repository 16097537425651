export const APPLY_STATUS = {
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  REJECTED: 'rejected',
  SUBMITTED: 'submitted',
};

export const QUESTIONS = {
  AFFILIATE: {
    GROUPS: 'groups',
    COMPANIES: 'companies',
  },
  DESCRIPTIONS: {
    COMPANIES: 'description_companies',
  },
};

export const AFFILIATE_QUESTIONS = {
  [QUESTIONS.AFFILIATE.GROUPS]: 'Are you an active leader, member, or content creator for any groups, channels, platforms, relevant to ecommerce?',
  [QUESTIONS.AFFILIATE.COMPANIES]: 'Are you currently or have ever been an affiliate for any other companies?',
};

export const AFFILIATE_DESCRIPTIONS = {
  [QUESTIONS.DESCRIPTIONS.COMPANIES]: 'If so, this isn’t a dealbreaker for working with Vela and may even be a benefit, we’re just curious to know who’s had experience with such engagements.',
};

export const MILESTONES = [
  { bonus: 0, referrals: 0, labels: [] },
  { bonus: 50, referrals: 5, labels: ['$50 bonus', '5 referrals'] },
  { bonus: 100, referrals: 10, labels: ['$100 bonus', '10 referrals'] },
  { bonus: 250, referrals: 25, labels: ['$250 bonus', '25 referrals'] },
  { bonus: 500, referrals: 50, labels: ['$500 bonus', '50 referrals'] },
  { bonus: 750, referrals: 75, labels: ['$750 bonus', '75 referrals'] },
  { bonus: 1000, referrals: 100, labels: ['$1K bonus', '100 referrals'] },
];

export const MILESTONE_STATUS = {
  AVAILABLE: 'AVAILABLE',
  REQUESTED: 'REQUESTED',
  PAID: 'PAID',
};

export const PAYOUT_METHOD = {
  AMAZON: 'amazon',
  PAYPAL: 'paypal',
  VENMO: 'venmo',
};

export const PAYOUT_METHODS = [
  PAYOUT_METHOD.VENMO,
  PAYOUT_METHOD.PAYPAL,
  PAYOUT_METHOD.AMAZON,
];

export const PAYOUT_PLACEHOLDER = {
  [PAYOUT_METHOD.AMAZON]: 'Your Amazon Email ',
  [PAYOUT_METHOD.PAYPAL]: 'Your PayPal Email or Paypal ID',
  [PAYOUT_METHOD.VENMO]: 'Your Venmo Email or Venmo ID',
};

export const REFERRAL_STATUS = {
  PENDING: 'Awaiting Upgrade',
  UPGRADED: 'Upgraded',
};
