import ActionConstants, { Actions } from '../constants/actions';

const mediaLibraryConstants = new Actions('Media_Library')
  .add('bootstrap')
  .add('clean_up')
  .addAsync('create_folder')
  .addAsync('delete_folder')
  .addAsync('delete_images')
  .addAsync('download_images')
  .addAsync('get_folders')
  .addAsync('get_images')
  .addAsync('move_images')
  .addAsync('rename_folder')
  .add('set_value')
  .add('toggle_image_state')
  .addAsync('upload_files');

ActionConstants.add(mediaLibraryConstants);

export default mediaLibraryConstants.actionCreators();
