import { getSize } from '../iterable/getSize';
import { reduce } from './../iterable/reduce';

import { ETSY, SHOPIFY } from '../../constants/channels';
import { VISIBILITY } from '../../constants/attributes';
import { VELA } from '../../constants';

export function findExistingOffering({ channel, offerings, properties, names }) {
  function filterOfferings(offering) {
    function reduceProperties(all, property) {
      function findOption(option) {
        function reduceProperty(result, value, key) {
          return result && option.get(key) === value;
        }

        return reduce(property, true, reduceProperty);
      }

      return all && offering.get('options').find(findOption);
    }

    return properties.reduce(reduceProperties, true);
  }

  function reduceVisible(visible, offering) {
    return visible || offering.get(VISIBILITY);
  }

  function findSameOffering(offering) {
    const options = offering.get('options');

    if (getSize(names) > getSize(options)) {
      names.splice(getSize(options) - getSize(names));
    }

    function reduceNames(result, name, index) {
      return result && (name ? options.getIn([index, 'name']) === name : result);
    }

    return names.reduce(reduceNames, true);
  }

  switch (channel) {
    case ETSY: {
      const allSuitableOfferings = offerings.filter(filterOfferings);

      return allSuitableOfferings.has(0)
        ? allSuitableOfferings
          .get(0)
          .set(VISIBILITY, allSuitableOfferings.reduce(reduceVisible, false))
        : undefined;
    }

    case VELA:
    case SHOPIFY: {
      return offerings.find(findSameOffering);
    }

    default: {
      return undefined;
    }
  }
}
