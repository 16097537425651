import ActionConstants, { Actions } from '../constants/actions';

const photoEditorConstants = new Actions('Photo_Editor')
  .add('listing_selected')
  .add('reset_state')
  .add('set_value');

ActionConstants.add(photoEditorConstants);

export default photoEditorConstants.actionCreators();
