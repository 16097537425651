import ActionConstants, { Actions } from '../constants/actions';

const shopConstants = new Actions('Shops')
  .addAsync('get_shops')
  .addAsync('refresh_user_initiated_shop')
  .addAsync('refresh_user_shop')
  .add('reschedule_shops_polling')
  .add('set_data')
  .addAsync('validate_oauth_token');

ActionConstants.add(shopConstants);

export default shopConstants.actionCreators();
