import { CURSOR, VELA_SANS } from './theme';
import { HEIGHT, WIDTH } from './attributes';
import { BUTTON } from './button';

export const DEFAULT_COLOR = '#FFF';

export const CLASS_NAME = {
  BACKGROUND: 'background',
  TEXT: 'text',
};

export const COMPOSITE = {
  DESTINATION_IN: 'destination-in',
  DESTINATION_OUT: 'destination-out',
  DESTINATION_OVER: 'destination-over',
};

export const STROKE = {
  ROUND: 'round',
};

export const PATTERN = {
  REPEAT: 'repeat',
};

export const MIME = {
  PNG: 'image/png',
  GIF: 'image/gif',
  HEIC: 'image/heic',
  JPG: 'image/jpeg',
  TIFF: 'image/tiff',
  WEBP: 'image/webp',
  QUICKTIME: 'video/quicktime',
};

export const EXT = {
  PNG: 'png',
  GIF: 'gif',
  HEIC: 'heic',
  JPG: 'jpg',
  JPEG: 'jpeg',
  TIFF: 'tiff',
  WEBP: 'webp',
};

export const MIME_TO_EXT = {
  [MIME.PNG]: EXT.PNG,
  [MIME.GIF]: EXT.GIF,
  [MIME.HEIC]: EXT.HEIC,
  [MIME.JPG]: EXT.JPG,
  [MIME.TIFF]: EXT.TIFF,
  [MIME.WEBP]: EXT.WEBP,
};

export const EXT_TO_MIME = {
  [EXT.PNG]: MIME.PNG,
  [EXT.GIF]: MIME.GIF,
  [EXT.HEIC]: MIME.HEIC,
  [EXT.JPG]: MIME.JPG,
  [EXT.JPEG]: MIME.JPG,
  [EXT.TIFF]: EXT.TIFF,
  [EXT.WEBP]: MIME.WEBP,
};

export const CROSS_ORIGIN = {
  ANONYMOUS: 'anonymous',
};

export const ORIGIN = {
  BOTTOM: 'bottom',
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
};

export const ORIGIN_OPPOSITE = {
  [ORIGIN.BOTTOM]: ORIGIN.TOP,
  [ORIGIN.CENTER]: ORIGIN.CENTER,
  [ORIGIN.LEFT]: ORIGIN.RIGHT,
  [ORIGIN.RIGHT]: ORIGIN.LEFT,
  [ORIGIN.TOP]: ORIGIN.BOTTOM,
};

export const DIMENSION = { WIDTH, HEIGHT };

export const DIMENSION_OPPOSITE = {
  [HEIGHT]: WIDTH,
  [WIDTH]: HEIGHT,
};

export const DIMENSION_OFFSET = {
  [HEIGHT]: ORIGIN.TOP,
  [WIDTH]: ORIGIN.LEFT,
};

export const DIMENSION_SCALE = {
  [HEIGHT]: 'scaleY',
  [WIDTH]: 'scaleX',
};

export const TEXT_ALIGN = {
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
};

export const TOOLS = {
  BANNERS: 'Banners',
  CLEANUP: 'Cleanup',
  CROP: 'Crop',
  ISOLATE: 'Isolate',
  REFINE: 'Refine',
  SCENES: 'Scenes',
  TEXT: 'Text',
};

export const TOOL_TO_BUTTON_MAP = {
  [TOOLS.BANNERS]: BUTTON.PROMOTE,
  [TOOLS.CLEANUP]: BUTTON.ERASE,
  [TOOLS.CROP]: BUTTON.CROP,
  [TOOLS.ISOLATE]: BUTTON.ISOLATE,
  [TOOLS.REFINE]: BUTTON.ADJUSTMENTS,
  [TOOLS.SCENES]: BUTTON.OBJECT,
  [TOOLS.TEXT]: BUTTON.TEXT,
};

const OBJECT_CHANGES = {
  ADD: 'add',
  DELETE: 'delete',
  STACK: 'stack',
  TRANSFORM: 'transform',
};

export const CHANGES = {
  [TOOLS.BANNERS]: {
    ...OBJECT_CHANGES,
    BACKGROUND_COLOR: 'backgroundColor',
    FONT_FAMILY: 'fontFamily',
    OPACITY: 'opacity',
    TEXT: 'text',
    TEXT_COLOR: 'textColor',
  },
  [TOOLS.ISOLATE]: {
    BLUR: 'backgroundBlur',
    COLOR: 'backgroundColor',
    EDIT: 'edit',
    IMAGE: 'backgroundImage',
    REMOVE: 'remove',
  },
  [TOOLS.REFINE]: {
    ADJUST: 'adjust',
    PRESET: 'preset',
  },
  [TOOLS.SCENES]: OBJECT_CHANGES,
  [TOOLS.TEXT]: {
    ...OBJECT_CHANGES,
    ALIGNMENT: 'textAlign',
    BACKGROUND_COLOR: 'backgroundColor',
    EDIT: 'edit',
    FONT_COLOR: 'fill',
    FONT_FAMILY: 'fontFamily',
    FONT_SIZE: 'fontSize',
    LINE_HEIGHT: 'lineHeight',
    OPACITY: 'opacity',
    TEXT: 'text',
    TEXT_COLOR: 'textColor',
  },
};

export const CROP = {
  COMMON: {
    ORIGINAL: 'co-or',
    SQUARE: 'co-sq',
    FOUR_BY_THREE: 'co-ft',
    SIXTEEN_BY_NINE: 'co-sn',
  },
  FACEBOOK: {
    POST: 'fa-po',
    PROFILE: 'fa-pr',
    TITLE: 'fa-ti',
  },
  INSTAGRAM: {
    LANDSCAPE: 'in-la',
    PORTRAIT: 'in-po',
    REEL: 'in-re',
    SQUARE: 'in-sq',
  },
  X: {
    POST: 'x-po',
    PROFILE: 'x-pr',
    TITLE: 'x-ti',
  },
};

export const CROP_TEMPLATE = {
  [CROP.COMMON.ORIGINAL]: {},
  [CROP.COMMON.FOUR_BY_THREE]: {
    aspectRatio: 4 / 3,
  },
  [CROP.COMMON.SIXTEEN_BY_NINE]: {
    aspectRatio: 16 / 9,
  },
  [CROP.COMMON.SQUARE]: {
    aspectRatio: 1,
  },
  [CROP.FACEBOOK.POST]: {
    aspectRatio: 940 / 788,
    dimensions: {
      height: 788,
      width: 940,
    },
  },
  [CROP.FACEBOOK.PROFILE]: {
    aspectRatio: 1,
    dimensions: {
      height: 300,
      width: 300,
    },
  },
  [CROP.FACEBOOK.TITLE]: {
    aspectRatio: 1200 / 627,
    dimensions: {
      height: 627,
      width: 1200,
    },
  },
  [CROP.INSTAGRAM.LANDSCAPE]: {
    aspectRatio: 1080 / 566,
    dimensions: {
      height: 566,
      width: 1080,
    },
  },
  [CROP.INSTAGRAM.PORTRAIT]: {
    aspectRatio: 1080 / 1350,
    dimensions: {
      height: 1350,
      width: 1080,
    },
  },
  [CROP.INSTAGRAM.REEL]: {
    aspectRatio: 1080 / 1920,
    dimensions: {
      height: 1920,
      width: 1080,
    },
  },
  [CROP.INSTAGRAM.SQUARE]: {
    aspectRatio: 1,
    dimensions: {
      height: 1080,
      width: 1080,
    },
  },
  [CROP.X.POST]: {
    aspectRatio: 1024 / 512,
    dimensions: {
      height: 512,
      width: 1024,
    },
  },
  [CROP.X.PROFILE]: {
    aspectRatio: 1,
    dimensions: {
      height: 400,
      width: 400,
    },
  },
  [CROP.X.TITLE]: {
    aspectRatio: 1500 / 500,
    dimensions: {
      height: 500,
      width: 1500,
    },
  },
};

export const ISOLATE_BRUSH_MODE = {
  ERASE: 'Erase',
  RESTORE: 'Restore',
};

export const FLIP = {
  HORIZONTALLY: 'Horizontally',
  VERTICALLY: 'Vertically',
};

export const LAYER = {
  UP: 'Move up',
  DOWN: 'Move down',
};

export const LAYER_OPTIONS = [
  LAYER.UP,
  LAYER.DOWN,
];

export const ZOOM_STEPS = {
  DOWN: [0.1, 0.20, 0.33, 0.5, 0.75, 1],
  UP: [1, 2, 3, 5, 7.5, 10],
};

export const PAN_OFFSET = 10;

export const BRUSH_SIZE = 100;

export const NEW_OBJECT_SHIFT = 15;

export const CONTROL = {
  BOTTOM: {
    LEFT: 'bl',
    RIGHT: 'br',
  },
  MIDDLE: {
    LEFT: 'ml',
    RIGHT: 'mr',
  },
  ROTATE: 'mtr',
  TOP: {
    LEFT: 'tl',
    RIGHT: 'tr',
  },
};

export const CONTROL_PROPS = {
  [CONTROL.BOTTOM.LEFT]: {
    x: -0.5,
    y: 0.5,
  },
  [CONTROL.BOTTOM.RIGHT]: {
    x: 0.5,
    y: 0.5,
  },
  [CONTROL.MIDDLE.LEFT]: {
    x: -0.5,
    y: 0,
  },
  [CONTROL.MIDDLE.RIGHT]: {
    x: 0.5,
    y: 0,
  },
  [CONTROL.TOP.LEFT]: {
    x: -0.5,
    y: -0.5,
  },
  [CONTROL.TOP.RIGHT]: {
    x: 0.5,
    y: -0.5,
  },
  [CONTROL.ROTATE]: {
    offsetY: -32,
    x: 0,
    y: -0.5,
    withConnection: true,
  },
};

export const CURSOR_INDEX = [
  CURSOR.EW_RESIZE,
  CURSOR.NWSE_RESIZE,
  CURSOR.NS_RESIZE,
  CURSOR.NESW_RESIZE,
  CURSOR.EW_RESIZE,
  CURSOR.NWSE_RESIZE,
  CURSOR.NS_RESIZE,
  CURSOR.NESW_RESIZE,
  CURSOR.EW_RESIZE,
];

export const NAMES = {
  BANNER: 'banner',
  CLEANUP_BRUSH: 'cleanup-brush',
  CROP_AREA: 'crop-area',
  ISOLATE_BRUSH: 'isolate-brush',
  MAIN_IMAGE: 'main-image',
  SCENERY: 'scenery',
  SHAPE: 'basic-shape',
  TEXT: 'text',
};

export const ACTIONS = {
  ADD_TO_HISTORY: 'addToHistory',
  REDO_CHANGE: 'redoChange',
  RESET_STATE: 'resetState',
  SET_TOOL: 'setTool',
  SET_VALUE: 'setValue',
  UNDO_CHANGE: 'undoChange',
  UPDATE_STATE: 'updateState',
};

const ISOLATE_PALETTE = [
  '#F7EF99',
  '#F1BB87',
  '#F78E69',
  '#553A41',
  '#777DA7',
  '#E63946',
  '#FCA311',
  '#A8DADC',
  '#457B9D',
  '#14213D',
];

const BANNERS_PALETTE = [
  '#F89339',
  '#44547C',
  '#F84D34',
  '#009B8A',
  '#7DDABA',
];

const TEXT_PALETTE = [
  'rgba(255, 255, 255, 1)',
  'rgba(178, 54, 101, 1)',
  'rgba(0, 83, 81, 1)',
  'rgba(0, 155, 138, 1)',
];

export const INITIAL_STATE = {
  banners: {
    group: NAMES.BANNER,
  },
  cleanup: {
    brushSize: BRUSH_SIZE,
    isModified: false,
    showChanges: true,
  },
  crop: {},
  history: { index: -1, items: [] },
  isolate: {
    addBackground: {
      tab: CHANGES[TOOLS.ISOLATE].COLOR,
    },
    editBackground: {
      tab: ISOLATE_BRUSH_MODE.ERASE,
      brushSize: BRUSH_SIZE,
    },
  },
  objects: {
    byId: {},
    ids: [],
  },
  palettes: {
    banners_back: [...BANNERS_PALETTE],
    banners_text: ['rgba(0, 0, 0, 1)', ...TEXT_PALETTE],
    isolate: [...ISOLATE_PALETTE],
    text_back: ['rgba(0, 0, 0, 0)', ...TEXT_PALETTE],
    text_front: ['rgba(0, 0, 0, 1)', ...TEXT_PALETTE],
  },
  refine: {},
  scenery: {
    groups: {
      ids: [],
      byId: {},
    },
  },
  tools: {
    current: TOOLS.ISOLATE,
    items: [
      TOOLS.ISOLATE,
      TOOLS.CLEANUP,
      TOOLS.CROP,
      TOOLS.REFINE,
      TOOLS.TEXT,
      TOOLS.BANNERS,
      TOOLS.SCENES,
    ],
  },
  zoom: {},
};

export const SAVE = {
  CURRENT: 'Update current photo',
  NEW: 'Save as new photo',
  REPLACE: 'Update listing photos',
  LIBRARY: 'Save to the Library',
};

export const SAVE_OPTIONS = [SAVE.CURRENT, SAVE.NEW];

export const FONT = {
  BARLOW: 'Barlow',
  CAVEAT_BRUSH: 'Caveat Brush',
  DELICIOUS_HANDRAWN: 'Delicious Handrawn',
  LATO: 'Lato',
  MERRIWEATHER: 'Merriweather',
  PACIFICO: 'Pacifico',
  PLAYFAIR: 'Playfair Display',
  SANS_SERIF: 'sans-serif',
  SATISFY: 'Satisfy',
  VELA_SANS: VELA_SANS,
  ZILLA_SLAB: 'Zilla Slab',
};

export const FILTER = {
  BRIGHTNESS: 'Brightness',
  CONTRAST: 'Contrast',
  CONVOLUTE: 'Convolute',
  EXPOSURE: 'Exposure',
  GAMMA: 'Gamma',
  HIGHLIGHTS: 'Highlights',
  SATURATION: 'Saturation',
  SHADOWS: 'Shadows',
};

export const ADJUSTMENT = {
  BRIGHTNESS: FILTER.BRIGHTNESS,
  CLARITY: 'Clarity',
  CONTRAST: FILTER.CONTRAST,
  EXPOSURE: FILTER.EXPOSURE,
  GAMMA: FILTER.GAMMA,
  HIGHLIGHTS: FILTER.HIGHLIGHTS,
  SATURATION: FILTER.SATURATION,
  SHADOWS: FILTER.SHADOWS,
};

export const ADJUSTMENT_TO_FILTER_MAP = {
  [ADJUSTMENT.BRIGHTNESS]: FILTER.BRIGHTNESS,
  [ADJUSTMENT.CLARITY]: FILTER.CONVOLUTE,
  [ADJUSTMENT.CONTRAST]: FILTER.CONTRAST,
  [ADJUSTMENT.EXPOSURE]: FILTER.EXPOSURE,
  [ADJUSTMENT.GAMMA]: FILTER.GAMMA,
  [ADJUSTMENT.HIGHLIGHTS]: FILTER.HIGHLIGHTS,
  [ADJUSTMENT.SATURATION]: FILTER.SATURATION,
  [ADJUSTMENT.SHADOWS]: FILTER.SHADOWS,
};

export const BULK_PHOTOS_LIMIT = 25;

export const BULK_THUMBNAIL_SIZE = 145;

export const GRADIENT_HEIGHT = 64;

export const GRADIENT_SPEED = 2;

export const UNSPLASH_SAMPLES = [
  {
    hash: '0117bd8ac6bced279ae18d8ce49fec9f2c4518ab',
    fullsize_url: 'https://images.unsplash.com/photo-1528458909336-e7a0adfed0a5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2000&q=80',
  },
  {
    hash: '59b9acba8f3b1fb3c4f31a444a5849b0333a1347',
    fullsize_url: 'https://images.unsplash.com/photo-1551554781-c46200ea959d?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2000&q=80',
  },
  {
    hash: '79e0dafbf4753e47ebb81ce5552bb7827c0d371e',
    fullsize_url: 'https://images.unsplash.com/photo-1495195129352-aeb325a55b65?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2000&q=80',
  },
  {
    hash: '70e9fd8ec400fb7210c2e228d95a840932995a4b',
    fullsize_url: 'https://images.unsplash.com/photo-1487088678257-3a541e6e3922?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2000&q=80',
  },
  {
    hash: '44cf47ce59d703eee0a9d3cef19057e7ccc51df7',
    fullsize_url: 'https://images.unsplash.com/32/Mc8kW4x9Q3aRR3RkP5Im_IMG_4417.jpg?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2000&q=80',
  },
  {
    hash: 'e096b845f15cbc8f97c6afd6da20cfc18b13a97a',
    fullsize_url: 'https://images.unsplash.com/photo-1531315630201-bb15abeb1653?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1250&q=80',
  },
];

export const OPERATIONS = {
  REMOVE_BACKGROUND: 'remove_bg',
};

export const TRACKING_EVENT = {
  TOOLS: {
    [TOOLS.CLEANUP]: 'Editor cleanup',
    [TOOLS.ISOLATE]: 'Editor isolate',
    BACKGROUND: {
      COLOR: 'Editor isolate color',
      IMAGE: 'Editor isolate image',
    },
    [TOOLS.CROP]: 'Editor crop',
    [TOOLS.REFINE]: 'Editor refine',
    [TOOLS.TEXT]: 'Editor text',
    [TOOLS.BANNERS]: 'Editor banner',
    [TOOLS.SCENES]: 'Editor scenes',
  },
  IMAGE: {
    LISTING: 'Editor search listing',
    DROP: 'Editor drop file',
  },
  ACTION: {
    UPDATE: 'Editor update listing',
    SAVE: 'Editor save library',
  },
};
