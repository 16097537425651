import { Set } from 'immutable';

import { ETSY, SHOPIFY } from './channels';
import { PROFILE } from './profiles';
import { VELA } from '.';

export const PAGE_SIZE = 25;

export const COPY_LIMIT = 250;

export const PUBLISH_LIMIT = 100;

export const MAX_FILTER_ITEMS = 5;

export const ORDER = {
  ASCENDING: true,
  DESCENDING: false,
};

export const ORDER_INDICATOR = {
  [ORDER.ASCENDING]: ' ↑',
  [ORDER.DESCENDING]: ' ↓',
};

export const SELECTION = {
  ALL: 'all',
  COMPLETE: 'complete',
  INCOMPLETE: 'incomplete',
  NONE: 'none',
  PAGE: 'page',
};

export const STATUS = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  COPIED: 'copied',
  DRAFT: 'draft',
  INACTIVE: 'inactive',
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished',
  TEMPLATE: 'template',
};

export const STATUS_NAME = {
  [STATUS.ACTIVE]: 'Active',
  [STATUS.ARCHIVED]: 'Archived',
  [STATUS.COPIED]: 'Copy',
  [STATUS.DRAFT]: 'Draft',
  [STATUS.INACTIVE]: 'Inactive',
  [STATUS.PUBLISHED]: 'Published',
  [STATUS.UNPUBLISHED]: 'Unpublished',
};

export const CHANNEL_STATUSES = {
  [ETSY]: [
    STATUS.ACTIVE,
    STATUS.DRAFT,
    STATUS.INACTIVE,
  ],
  [SHOPIFY]: [
    STATUS.ACTIVE,
    STATUS.DRAFT,
    STATUS.ARCHIVED,
  ],
};

export const VELA_STATUSES = [
  STATUS.COPIED,
  STATUS.UNPUBLISHED,
];

export const STATUS_TYPE = {
  CHANNEL: 'channel',
  VELA,
};

export const STATUS_INDEX = {
  [STATUS.ACTIVE]: 5,
  [STATUS.ARCHIVED]: 2,
  [STATUS.COPIED]: 0,
  [STATUS.DRAFT]: 4,
  [STATUS.INACTIVE]: 3,
  [STATUS.UNPUBLISHED]: 1,
};

export const CHANNEL_STATUS_MAP = {
  [ETSY]: {
    [STATUS.ARCHIVED]: STATUS.INACTIVE,
  },
  [SHOPIFY]: {
    [STATUS.INACTIVE]: STATUS.ARCHIVED,
  },
};

export const PROFILE_TYPES = [
  PROFILE.LISTINGS,
  PROFILE.VARIATIONS,
  PROFILE.TAGS,
];

export const FILTER = {
  COLLECTIONS: 'collections',
  EXISTING: 'existing',
  IS_COMPLETE: 'isComplete',
  MATERIALS: 'materials',
  PRODUCT_TYPE: 'productType',
  PROFILE: 'profile',
  PROFILE_TYPE: 'profileType',
  SECTION: 'sections',
  STATUS: 'status',
  TAGS: 'tags',
  TITLE: 'title',
  CATEGORY: 'category',
  VENDOR: 'vendor',
};

export const FILTER_NAME = {
  [FILTER.CATEGORY]: 'Category',
  [FILTER.COLLECTIONS]: 'Collections',
  [FILTER.IS_COMPLETE]: 'Status',
  [FILTER.MATERIALS]: 'Materials',
  [FILTER.PRODUCT_TYPE]: 'Product type',
  [FILTER.PROFILE]: 'Profile',
  [FILTER.SECTION]: 'Section',
  [FILTER.TAGS]: 'Tags',
  [FILTER.VENDOR]: 'Vendor',
};

export const FILTER_API_KEY = {
  [FILTER.CATEGORY]: 'taxonomy_ids',
  [FILTER.COLLECTIONS]: 'collection_ids',
  [FILTER.EXISTING]: 'existing',
  [FILTER.IS_COMPLETE]: 'is_complete',
  [FILTER.MATERIALS]: 'materials',
  [FILTER.PRODUCT_TYPE]: 'product_types',
  [FILTER.PROFILE]: {
    [PROFILE.LISTINGS]: 'listings_profile_ids',
    [PROFILE.TAGS]: 'tags_profile_ids',
    [PROFILE.VARIATIONS]: 'variations_profile_ids',
  },
  [FILTER.SECTION]: 'section_ids',
  [FILTER.STATUS]: 'status',
  [FILTER.TAGS]: 'tags',
  [FILTER.TITLE]: 'title',
  [FILTER.VENDOR]: 'vendors',
};

export const CHANNEL_FILTERS = {
  [ETSY]: [
    FILTER.EXISTING,
    FILTER.IS_COMPLETE,
    FILTER.PROFILE,
    FILTER.CATEGORY,
    FILTER.SECTION,
    FILTER.TAGS,
    FILTER.MATERIALS,
  ],
  [SHOPIFY]: [
    FILTER.EXISTING,
    FILTER.IS_COMPLETE,
    FILTER.PROFILE,
    FILTER.COLLECTIONS,
    FILTER.PRODUCT_TYPE,
    FILTER.VENDOR,
    FILTER.TAGS,
  ],
};

export const LISTING = {
  [ETSY]: {
    CHANNEL_PRODUCT_ID: 'listing_id',
    CREATED_AT: 'creation_tsz',
    EXPIRES_AT: 'ending_tsz',
    IS_COMPLETE: 'is_complete',
    LINK_ID: 'link_id',
    LINKED_PRODUCTS_SHOP_IDS: 'merged_products_shop_ids',
    PHOTO: 'photo',
    PRICE: 'price',
    PRICE_VARIANT: 'price_variant',
    PRODUCT_ID: 'id',
    QUANTITY: 'quantity',
    SECTION_ID: 'section_id',
    TITLE: 'title',
    UPDATED_AT: '_hive_last_modified_tsz',
  },
  [SHOPIFY]: {
    CHANNEL_PRODUCT_ID: 'product_id',
    COLLECTION_IDS: 'collection_ids',
    IS_COMPLETE: 'is_complete',
    LINK_ID: 'link_id',
    LINKED_PRODUCTS_SHOP_IDS: 'merged_products_shop_ids',
    PHOTO: 'photo',
    PRICE: 'price',
    PRICE_VARIANT: 'price_variant',
    PRODUCT_ID: 'id',
    PUBLISHED_AT: 'published_at',
    QUANTITY: 'quantity',
    TITLE: 'title',
    UPDATED_AT: 'updated_at',
  },
  [VELA]: {
    CHANNEL_PRODUCT_ID: 'channelProductId',
    COLLECTIONS: 'collections',
    EXPIRES_AT: 'expiresAt',
    IS_COMPLETE: 'isComplete',
    LINK_ID: 'linkId',
    LINKED_PRODUCTS_SHOP_IDS: 'linkedProductsShopIds',
    LINKED_PRODUCTS_SHOP_CHANNELS: 'linkedProductsShopChannels',
    PHOTO: 'photo',
    PRICE: 'price',
    PRICE_VARIANT: 'priceVariant',
    PRODUCT_ID: 'productId',
    PUBLISHED_AT: 'publishedAt',
    QUANTITY: 'quantity',
    SECTION: 'section',
    TITLE: 'title',
    UPDATED_AT: 'updatedAt',
  },
};

export const ALPHABETICAL_ORDER = {
  [ETSY]: { by: LISTING[ETSY].TITLE, type: true },
  [SHOPIFY]: { by: LISTING[SHOPIFY].TITLE, type: true },
};

const TABLE_COLUMN = {
  COLLECTIONS: 'collections',
  CONTROLS: 'controls',
  EXPIRED: 'expired',
  EXPIRES: 'expires',
  PRICE: 'price',
  PUBLISHED: 'published',
  QUANTITY: 'quantity',
  SECTION: 'section',
  SHOPS: 'shops',
  TITLE: 'title',
  TOGGLE: 'toggle',
  UPDATED: 'updated',
};

export const TABLE = {
  COLUMN: TABLE_COLUMN,
  COLUMNS: {
    [ETSY]: {
      [STATUS.ACTIVE]: [
        TABLE_COLUMN.TITLE,
        TABLE_COLUMN.QUANTITY,
        TABLE_COLUMN.PRICE,
        TABLE_COLUMN.EXPIRES,
        TABLE_COLUMN.SECTION,
      ],
      [STATUS.DRAFT]: [
        TABLE_COLUMN.TITLE,
        TABLE_COLUMN.QUANTITY,
        TABLE_COLUMN.PRICE,
        TABLE_COLUMN.UPDATED,
        TABLE_COLUMN.SECTION,
      ],
      [STATUS.INACTIVE]: [
        TABLE_COLUMN.TITLE,
        TABLE_COLUMN.QUANTITY,
        TABLE_COLUMN.PRICE,
        TABLE_COLUMN.EXPIRED,
        TABLE_COLUMN.SECTION,
      ],
      [STATUS.COPIED]: [
        TABLE_COLUMN.TITLE,
        TABLE_COLUMN.QUANTITY,
        TABLE_COLUMN.PRICE,
        TABLE_COLUMN.SECTION,
      ],
      [STATUS.UNPUBLISHED]: [
        TABLE_COLUMN.TITLE,
        TABLE_COLUMN.QUANTITY,
        TABLE_COLUMN.PRICE,
        TABLE_COLUMN.SECTION,
      ],
    },
    [SHOPIFY]: {
      [STATUS.ACTIVE]: [
        TABLE_COLUMN.TITLE,
        TABLE_COLUMN.QUANTITY,
        TABLE_COLUMN.PRICE,
        TABLE_COLUMN.PUBLISHED,
        TABLE_COLUMN.COLLECTIONS,
      ],
      [STATUS.DRAFT]: [
        TABLE_COLUMN.TITLE,
        TABLE_COLUMN.QUANTITY,
        TABLE_COLUMN.PRICE,
        TABLE_COLUMN.UPDATED,
        TABLE_COLUMN.COLLECTIONS,
      ],
      [STATUS.ARCHIVED]: [
        TABLE_COLUMN.TITLE,
        TABLE_COLUMN.QUANTITY,
        TABLE_COLUMN.PRICE,
        TABLE_COLUMN.COLLECTIONS,
      ],
      [STATUS.COPIED]: [
        TABLE_COLUMN.TITLE,
        TABLE_COLUMN.QUANTITY,
        TABLE_COLUMN.PRICE,
        TABLE_COLUMN.COLLECTIONS,
      ],
      [STATUS.UNPUBLISHED]: [
        TABLE_COLUMN.TITLE,
        TABLE_COLUMN.QUANTITY,
        TABLE_COLUMN.PRICE,
        TABLE_COLUMN.COLLECTIONS,
      ],
    },
  },
  COLUMN_NAME: {
    [ETSY]: {
      [TABLE_COLUMN.EXPIRED]: 'Expired on',
      [TABLE_COLUMN.EXPIRES]: 'Expires on',
      [TABLE_COLUMN.PRICE]: 'Price',
      [TABLE_COLUMN.QUANTITY]: 'Stock',
      [TABLE_COLUMN.SECTION]: 'Section',
      [TABLE_COLUMN.SHOPS]: 'Shops',
      [TABLE_COLUMN.TITLE]: 'Title',
      [TABLE_COLUMN.UPDATED]: 'Last update',
    },
    [SHOPIFY]: {
      [TABLE_COLUMN.COLLECTIONS]: 'Collections',
      [TABLE_COLUMN.PRICE]: 'Price',
      [TABLE_COLUMN.PUBLISHED]: 'Published on',
      [TABLE_COLUMN.QUANTITY]: 'Stock',
      [TABLE_COLUMN.SHOPS]: 'Shops',
      [TABLE_COLUMN.TITLE]: 'Title',
      [TABLE_COLUMN.UPDATED]: 'Last update',
    },
  },
  COLUMN_ORDER: {
    [ETSY]: {
      [TABLE_COLUMN.EXPIRED]: LISTING[ETSY].EXPIRES_AT,
      [TABLE_COLUMN.EXPIRES]: LISTING[ETSY].EXPIRES_AT,
      [TABLE_COLUMN.PRICE]: LISTING[ETSY].PRICE,
      [TABLE_COLUMN.QUANTITY]: LISTING[ETSY].QUANTITY,
      [TABLE_COLUMN.SECTION]: LISTING[ETSY].SECTION_ID,
      [TABLE_COLUMN.TITLE]: LISTING[ETSY].TITLE,
      [TABLE_COLUMN.UPDATED]: LISTING[ETSY].UPDATED_AT,
    },
    [SHOPIFY]: {
      [TABLE_COLUMN.COLLECTIONS]: LISTING[SHOPIFY].COLLECTION_IDS,
      [TABLE_COLUMN.PRICE]: LISTING[SHOPIFY].PRICE,
      [TABLE_COLUMN.PUBLISHED]: LISTING[SHOPIFY].PUBLISHED_AT,
      [TABLE_COLUMN.QUANTITY]: LISTING[SHOPIFY].QUANTITY,
      [TABLE_COLUMN.TITLE]: LISTING[SHOPIFY].TITLE,
      [TABLE_COLUMN.UPDATED]: LISTING[SHOPIFY].UPDATED_AT,
    },
  },
};

export const STATUS_ORDER = {
  [ETSY]: {
    [STATUS.ACTIVE]: Set([
      LISTING[ETSY].EXPIRES_AT,
      LISTING[ETSY].PRICE,
      LISTING[ETSY].QUANTITY,
      LISTING[ETSY].SECTION_ID,
      LISTING[ETSY].TITLE,
    ]),
    [STATUS.DRAFT]: Set([
      LISTING[ETSY].PRICE,
      LISTING[ETSY].QUANTITY,
      LISTING[ETSY].SECTION_ID,
      LISTING[ETSY].TITLE,
      LISTING[ETSY].UPDATED_AT,
    ]),
    [STATUS.INACTIVE]: Set([
      LISTING[ETSY].EXPIRES_AT,
      LISTING[ETSY].PRICE,
      LISTING[ETSY].QUANTITY,
      LISTING[ETSY].SECTION_ID,
      LISTING[ETSY].TITLE,
    ]),
    [STATUS.COPIED]: Set([
      LISTING[ETSY].CREATED_AT,
      LISTING[ETSY].PRICE,
      LISTING[ETSY].QUANTITY,
      LISTING[ETSY].SECTION_ID,
      LISTING[ETSY].TITLE,
    ]),
    [STATUS.UNPUBLISHED]: Set([
      LISTING[ETSY].PRICE,
      LISTING[ETSY].QUANTITY,
      LISTING[ETSY].SECTION_ID,
      LISTING[ETSY].TITLE,
    ]),
  },
  [SHOPIFY]: {
    [STATUS.ACTIVE]: Set([
      LISTING[SHOPIFY].COLLECTION_IDS,
      LISTING[SHOPIFY].PRICE,
      LISTING[SHOPIFY].PUBLISHED_AT,
      LISTING[SHOPIFY].QUANTITY,
      LISTING[SHOPIFY].TITLE,
    ]),
    [STATUS.DRAFT]: Set([
      LISTING[SHOPIFY].COLLECTION_IDS,
      LISTING[SHOPIFY].PRICE,
      LISTING[SHOPIFY].QUANTITY,
      LISTING[SHOPIFY].TITLE,
      LISTING[SHOPIFY].UPDATED_AT,
    ]),
    [STATUS.ARCHIVED]: Set([
      LISTING[SHOPIFY].COLLECTION_IDS,
      LISTING[SHOPIFY].PRICE,
      LISTING[SHOPIFY].QUANTITY,
      LISTING[SHOPIFY].TITLE,
    ]),
    [STATUS.COPIED]: Set([
      LISTING[SHOPIFY].COLLECTION_IDS,
      LISTING[SHOPIFY].PRICE,
      LISTING[SHOPIFY].QUANTITY,
      LISTING[SHOPIFY].TITLE,
    ]),
    [STATUS.UNPUBLISHED]: Set([
      LISTING[SHOPIFY].COLLECTION_IDS,
      LISTING[SHOPIFY].PRICE,
      LISTING[SHOPIFY].QUANTITY,
      LISTING[SHOPIFY].TITLE,
    ]),
  },
};
