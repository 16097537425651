export function retainPhotos(props) {
  let { productOptions, profileOptions } = props;

  function retainPhoto(profileOption, sourceIndex) {
    const value = profileOption.get('value');

    function findSameOption(productOption) {
      return productOption.get('value') === value;
    }

    const sameOptionIndex = productOptions.findIndex(findSameOption);

    if (sameOptionIndex > -1) {
      const hash = productOptions.getIn([sameOptionIndex, 'imageHash'], null);
      profileOptions = profileOptions.setIn([sourceIndex, 'imageHash'], hash);
      productOptions = productOptions.splice(sameOptionIndex, 1);
    }
  }

  profileOptions.forEach(retainPhoto);

  return profileOptions;
}
