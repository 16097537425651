import { getSize } from '../iterable/getSize';

import { DEFAULTS, SEPARATOR } from '../../constants';
import { ERROR } from '../../constants/product';
import { ETSY } from '../../constants/channels';
import {
  ERRORS,
  IMAGE_FILE_FORMATS,
  MAX_ALT_TEXT_LENGTH,
  MAX_PHOTO_SIZE,
  PHOTO_DIMENSIONS_MAXIMUM,
  PHOTO_DIMENSIONS_MINIMUM,
} from '../../constants/validations';

export async function getUploadedPhotoError({ channel, file, url }) {
  return new Promise(function executor(resolve, reject) {
    const imageType = (file?.type || DEFAULTS.EMPTY_STRING).split(SEPARATOR.SLASH)[1];

    if (
      !imageType || (
        IMAGE_FILE_FORMATS.hasOwnProperty(channel) &&
        !IMAGE_FILE_FORMATS[channel].has(imageType.toLowerCase())
      )
    ) {
      reject(ERRORS.PHOTOS.UNSUPPORTED_FORMAT[channel]);
    }

    if (MAX_PHOTO_SIZE.hasOwnProperty(channel) && file.size > MAX_PHOTO_SIZE[channel]) {
      reject(ERRORS.PHOTOS.FILE_TOO_BIG[channel]);
    }

    const image = document.createElement('img');

    image.onerror = function imageError() {
      reject(ERRORS.PHOTOS.UNKNOWN);
    };

    image.onload = function imageLoaded() {
      // check size constraints before loading it into buffer
      const { width, height } = image;

      if (PHOTO_DIMENSIONS_MINIMUM.hasOwnProperty(channel)) {
        const minimum = PHOTO_DIMENSIONS_MINIMUM[channel];

        if (width < minimum || height < minimum) {
          reject(ERRORS.PHOTOS.TOO_SMALL[channel]);
        }
      }

      if (PHOTO_DIMENSIONS_MAXIMUM.hasOwnProperty(channel)) {
        const maximum = PHOTO_DIMENSIONS_MAXIMUM[channel];

        if (width > maximum || height > maximum) {
          reject(ERRORS.PHOTOS.TOO_BIG[channel]);
        }
      }

      resolve();
    };

    image.src = url || URL.createObjectURL(file);
  });
}

export function getPhotosError({ channel, photos }) {
  switch (channel) {
    case ETSY: {
      if (!getSize(photos)) return ERRORS.PHOTOS.REQUIRED;

      break;
    }

    default: {
      if (!getSize(photos)) return false;

      break;
    }
  }

  function reducePhotos(error, photo) {
    return error || photo.get(ERROR, false);
  }

  return photos.reduce(reducePhotos, false);
}

export function getAltTextError({ channel, value }) {
  return (
    channel in MAX_ALT_TEXT_LENGTH &&
    getSize(value) > MAX_ALT_TEXT_LENGTH[channel] &&
    ERRORS.PHOTOS.ALT_TEXT_TOO_LONG[channel]
  );
}

export function getPhotoAttributeError({ attribute, channel, value }) {
  switch (attribute) {
    case 'alt_text': {
      return getAltTextError({ channel, value });
    }

    default: {
      return false;
    }
  }
}
