import { List, Map } from 'immutable';

import { getIndividualVariations } from './getIndividualVariations';
import { findExistingOffering } from './findExistingOffering';
import { findSuitableOffering } from './findSuitableOffering';
import { getGenericOffering } from './getGenericOffering';
import { getOffering } from './getOffering';
import { getSize } from '../iterable/getSize';

import { INDIVIDUAL, INDIVIDUAL_FLAGS } from '../../constants/profiles';
import { PRICE, QUANTITY, SKU } from '../../constants/attributes';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { VELA } from '../../constants';

export function updateOfferings({ profile, variations, product }) {
  const channel = profile.get('channel');
  const offerings = profile.get('offerings');
  let newOfferings = List();

  if (!getSize(variations)) return newOfferings;

  let id = 0;

  function findVariationById(variationId) {
    return function find(variation) {
      return variation.get('id') === variationId;
    };
  }

  switch (channel) {
    case ETSY: {
      const hasBothIndividual = profile.get(INDIVIDUAL.BOTH_VARIATIONS);
      const individual = getIndividualVariations({ variations, properties: INDIVIDUAL_FLAGS[channel] });
      const hasIndividualPrice = !!getSize(individual[INDIVIDUAL.PRICE]);
      const hasIndividualQuantity = !!getSize(individual[INDIVIDUAL.QUANTITY]);
      const hasIndividualSKU = !!getSize(individual[INDIVIDUAL.SKU]);
      const genericOffering = getGenericOffering({ channel, individual, product });

      for (const option1 of variations.getIn([0, 'options'])) {
        const name1 = option1.get('value');
        const option1Id = option1.get('id');
        const variation1Id = variations.getIn([0, 'id']);
        const config = {
          individualSKU: variations.getIn([0, INDIVIDUAL.SKU]),
          individualPrice: variations.getIn([0, INDIVIDUAL.PRICE]),
          individualQuantity: variations.getIn([0, INDIVIDUAL.QUANTITY]),
          properties: [{ optionId: option1Id, variationId: variation1Id }],
        };

        if (!getSize(variations.getIn([1, 'options']))) {
          id -= 1;

          const options = List([
            Map({ optionId: option1Id, variationId: variation1Id, name: name1 }),
          ]);

          newOfferings = newOfferings.push(
            Map({
              id,
              options,
              ...getOffering({ channel, offerings, config, genericOffering }),
            })
          );
        } else {
          for (const option2 of variations.getIn([1, 'options'])) {
            id -= 1;
            const name2 = option2.get('value');
            const option2Id = option2.get('id');
            const variation2Id = variations.getIn([1, 'id']);
            const options = List([
              Map({ optionId: option1Id, variationId: variation1Id, name: name1 }),
              Map({ optionId: option2Id, variationId: variation2Id, name: name2 }),
            ]);

            if (getSize(offerings.getIn([0, 'options'])) < getSize(variations)) {
              newOfferings = newOfferings.push(
                Map({
                  id,
                  options,
                  ...getOffering({ channel, offerings, config, genericOffering }),
                })
              );
            } else {
              let existing = findExistingOffering({
                channel,
                offerings,
                properties: [
                  { optionId: option1Id, variationId: variation1Id },
                  { optionId: option2Id, variationId: variation2Id },
                ],
              });

              if (existing) {
                const existingId = existing.get('id');

                if (Number.isFinite(existingId) && existingId < 0) {
                  existing = existing.set('id', id);
                }

                newOfferings = newOfferings.push(existing);
              } else {
                if (hasBothIndividual) {
                  newOfferings = newOfferings.push(Map({ id, options, ...genericOffering }));
                } else {
                  const { visibility } = genericOffering;
                  let { sku, price, quantity } = genericOffering;

                  if (hasIndividualPrice) {
                    const variationId = individual[INDIVIDUAL.PRICE].first();
                    const optionId = variationId === variation1Id
                      ? option1Id
                      : option2Id;

                    const suitableOffering = findSuitableOffering({
                      optionId,
                      variationId,
                      offerings,
                    });

                    price = suitableOffering
                      ? suitableOffering.get(PRICE)
                      : genericOffering.price;
                  }

                  if (hasIndividualQuantity) {
                    const variationId = individual[INDIVIDUAL.QUANTITY].first();
                    const optionId = variationId === variation1Id
                      ? option1Id
                      : option2Id;

                    const suitableOffering = findSuitableOffering({
                      optionId,
                      variationId,
                      offerings,
                    });

                    quantity = suitableOffering
                      ? suitableOffering.get(QUANTITY)
                      : genericOffering.quantity;
                  }

                  if (hasIndividualSKU) {
                    const variationId = individual[INDIVIDUAL.SKU].first();
                    const optionId = variationId === variation1Id
                      ? option1Id
                      : option2Id;

                    const suitableOffering = findSuitableOffering({
                      optionId,
                      variationId,
                      offerings,
                    });

                    sku = suitableOffering
                      ? suitableOffering.get(SKU)
                      : genericOffering.sku;
                  }

                  newOfferings = newOfferings.push(
                    Map({ id, options, price, quantity, sku, visibility })
                  );
                }
              }
            }
          }
        }
      }

      return newOfferings;
    }

    case SHOPIFY: {
      const genericOffering = getGenericOffering({ channel, offerings, product, variations });

      for (const option1 of variations.getIn([0, 'options'])) {
        const name1 = option1.get('value');
        const variation1Id = variations.getIn([0, 'id']);
        if (!getSize(variations.getIn([1, 'options']))) {
          id -= 1;

          const options = List([
            Map({ name: name1 }),
          ]);

          const names = Array(2).fill(undefined);
          names[profile.get('variations').findIndex(findVariationById(variation1Id))] = name1;

          newOfferings = newOfferings.push(
            Map({
              id,
              options,
              ...getOffering({ channel, offerings, config: { names }, genericOffering }),
            })
          );
        } else {
          for (const option2 of variations.getIn([1, 'options'])) {
            const name2 = option2.get('value');
            const variation2Id = variations.getIn([1, 'id']);

            if (!getSize(variations.getIn([2, 'options']))) {
              id -= 1;

              const options = List([
                Map({ name: name1 }),
                Map({ name: name2 }),
              ]);

              const names = Array(3).fill(undefined);
              names[profile.get('variations').findIndex(findVariationById(variation1Id))] = name1;
              names[profile.get('variations').findIndex(findVariationById(variation2Id))] = name2;

              newOfferings = newOfferings.push(
                Map({
                  id,
                  options,
                  ...getOffering({ channel, offerings, config: { names }, genericOffering }),
                })
              );
            } else {
              for (const option3 of variations.getIn([2, 'options'])) {
                const name3 = option3.get('value');

                id -= 1;

                const names = [name1, name2, name3];
                const options = List([
                  Map({ name: name1 }),
                  Map({ name: name2 }),
                  Map({ name: name3 }),
                ]);

                newOfferings = newOfferings.push(
                  Map({
                    id,
                    options,
                    ...getOffering({ channel, offerings, config: { names }, genericOffering }),
                  })
                );
              }
            }
          }
        }
      }

      return newOfferings;
    }

    case VELA: {
      const genericOffering = getGenericOffering({ channel, offerings, product, variations });

      for (const option1 of variations.getIn([0, 'options'])) {
        const name1 = option1.get('value');
        const option1Id = option1.get('id');
        const variation1Id = variations.getIn([0, 'id']);
        if (!getSize(variations.getIn([1, 'options']))) {
          id -= 1;

          const options = List([
            Map({ optionId: option1Id, variationId: variation1Id, name: name1 }),
          ]);

          const names = Array(2).fill(undefined);
          names[profile.get('variations').findIndex(findVariationById(variation1Id))] = name1;

          newOfferings = newOfferings.push(
            Map({
              id,
              options,
              ...getOffering({ channel, offerings, config: { names }, genericOffering }),
            })
          );
        } else {
          for (const option2 of variations.getIn([1, 'options'])) {
            const name2 = option2.get('value');
            const option2Id = option2.get('id');
            const variation2Id = variations.getIn([1, 'id']);

            if (!getSize(variations.getIn([2, 'options']))) {
              id -= 1;

              const options = List([
                Map({ optionId: option1Id, variationId: variation1Id, name: name1 }),
                Map({ optionId: option2Id, variationId: variation2Id, name: name2 }),
              ]);

              const names = Array(3).fill(undefined);
              names[profile.get('variations').findIndex(findVariationById(variation1Id))] = name1;
              names[profile.get('variations').findIndex(findVariationById(variation2Id))] = name2;

              newOfferings = newOfferings.push(
                Map({
                  id,
                  options,
                  ...getOffering({ channel, offerings, config: { names }, genericOffering }),
                })
              );
            } else {
              for (const option3 of variations.getIn([2, 'options'])) {
                const name3 = option3.get('value');
                const option3Id = option3.get('id');
                const variation3Id = variations.getIn([2, 'id']);

                id -= 1;

                const names = [name1, name2, name3];
                const options = List([
                  Map({ optionId: option1Id, variationId: variation1Id, name: name1 }),
                  Map({ optionId: option2Id, variationId: variation2Id, name: name2 }),
                  Map({ optionId: option3Id, variationId: variation3Id, name: name3 }),
                ]);

                newOfferings = newOfferings.push(
                  Map({
                    id,
                    options,
                    ...getOffering({ channel, offerings, config: { names }, genericOffering }),
                  })
                );
              }
            }
          }
        }
      }

      return newOfferings;
    }

    default: {
      return newOfferings;
    }
  }
}
