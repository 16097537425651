export const BULK_DOWNLOAD_LIMIT = 25;

export const THUMBNAIL_SIZE = 178;

export const IMAGES_PER_PAGE = 25;

export const CONFIRMATION_MODAL_TYPE = {
  IMAGES: 0,
  FOLDER: 1,
};

export const FOLDER_MENU_ITEMS = {
  RENAME: 0,
  DELETE: 1,
};

export const FOLDER_CONTROLS = [
  { id: FOLDER_MENU_ITEMS.RENAME, name: 'Rename' },
  { id: FOLDER_MENU_ITEMS.DELETE, name: 'Delete' },
];
