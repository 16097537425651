import { List } from 'immutable';

import { setFilesErrors, setWhenMadeErrors } from '../../product/errors';
import { isUploadFilesSupported } from '../../product/checkAttribute';
import { validateNames } from '../../validations/files';
import { moveListItem } from '../../iterable/moveListItem';
import { getSize } from '../../iterable/getSize';
import { get } from '../../iterable/get';

import { MAX_NUMBER_OF_FILES } from '../../../constants/validations';
import { OPERATIONS } from '../../../constants/bulkEdit';
import { FILES } from '../../../constants/attributes';
import { VALUE } from '../../../constants/product';

export default function files({ actions, operation, product }) {
  const channel = product.get('channel');
  const operationType = operation.get('type');

  function validateAndApply(updatedProduct) {
    return {
      actions,
      product: setFilesErrors({ channel, product: updatedProduct }),
    };
  }

  switch (operationType) {
    case OPERATIONS[channel].FILES.ADD: {
      if (!isUploadFilesSupported({ channel, product })) break;

      const productFiles = product.getIn([FILES, VALUE], List());

      if (getSize(productFiles) >= MAX_NUMBER_OF_FILES[channel]) break;

      const file = operation.get(VALUE);
      const fileNames = productFiles.map(get('name'));

      if (validateNames({ channel, file, fileNames, operationType })) break;

      return validateAndApply(product.setIn([FILES, VALUE], productFiles.push(file)));
    }

    case OPERATIONS[channel].FILES.DELETE: {
      if (!getSize(product.getIn([FILES, VALUE]))) break;

      const value = product
        .getIn([FILES, VALUE], List())
        .splice(operation.getIn([VALUE, 'targetIndex']), 1);

      return validateAndApply(
        setWhenMadeErrors({
          channel,
          product: product.setIn([FILES, VALUE], value),
        })
      );
    }

    case OPERATIONS[channel].FILES.REPLACE: {
      if (!isUploadFilesSupported({ channel, product }) || !getSize(product.getIn([FILES, VALUE]))) {
        break;
      }

      const productFiles = product.getIn([FILES, VALUE], List());
      const file = operation.getIn([VALUE, 'replacement']);
      const index = operation.getIn([VALUE, 'targetIndex']);

      if (!product.getIn([FILES, VALUE, index])) break;

      const fileNames = productFiles.map(get('name'));

      if (validateNames({ channel, file, fileNames, operationType, startIndex: index })) break;

      return validateAndApply(product.setIn([FILES, VALUE], productFiles.splice(index, 1, file)));
    }

    case OPERATIONS[channel].FILES.SWAP: {
      if (!isUploadFilesSupported({ channel, product }) || !getSize(product.getIn([FILES, VALUE]))) {
        break;
      }

      const value = moveListItem(
        product.getIn([FILES, VALUE]),
        operation.getIn([VALUE, 'sourceIndex']),
        operation.getIn([VALUE, 'targetIndex'])
      );

      return validateAndApply(product.setIn([FILES, VALUE], value));
    }

    default: {
      break;
    }
  }

  return { actions, product };
}
