
import { useDrag, useDrop } from 'react-dnd';

function areSameType(x, y) {
  return x.type === y.type;
}

export function useDragDrop({ enabled, id, onCancel, onDrag, onDrop, onHover, type }) {
  const [dragProps, drag, preview] = useDrag({
    type,
    collect(monitor) {
      return {
        isDragging: monitor.isDragging(),
      };
    },
    canDrag() {
      return enabled;
    },
    end(item, monitor) {
      if (!areSameType(item, monitor.getItem())) {
        return;
      }

      monitor.didDrop() ? onDrop() : onCancel();
    },
    item() {
      typeof onDrag === 'function' && onDrag();
      return { id, type, onCancel, onDrop, onHover };
    },
  });

  const [dropProps, drop] = useDrop({
    accept: type,
    canDrop(item, monitor) {
      return areSameType(item, monitor.getItem());
    },
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
      };
    },
    hover(item, monitor) {
      if (!areSameType(item, monitor.getItem())) return;

      if (typeof item.onHover === 'function') {
        onHover(item.id, id);
      }
    },
  });

  return { drag, drop, preview, ...dragProps, ...dropProps };
}
