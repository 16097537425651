import ACTIONS from '../../../constants/actions';
import { Set, Map } from 'immutable';
import mixpanel from '../../tracking/mixpanel';
import { store } from '../../../store';
import { TRACKING_EVENT, ACTIONS as PHOTO_ACTIONS } from '../../../constants/photoEditor';
import { CHANNEL_NAME } from '../../../constants/channels';
import { getPlanName } from '../../billing';
import { getSize } from '../../iterable/getSize';

const EVENT_ACTIONS = Set([
  ACTIONS.PHOTO_EDITOR.SET_VALUE,
]);

const EDITOR_ACTIONS = Set([
  PHOTO_ACTIONS.SET_TOOL,
  PHOTO_ACTIONS.ADD_TO_HISTORY,
]);

function trackActionEvent(photoEditor, actionType, payload, state) {
  if ( typeof payload.path === 'string' && payload.path === 'listings' && photoEditor.get('listing')) {
    mixpanel.track(TRACKING_EVENT.IMAGE.LISTING, getProperties(state) );
  }

  if ( typeof payload.path === 'string' && payload.path === 'photos' && payload.value.get('selected')) {
    mixpanel.track(TRACKING_EVENT.IMAGE.DROP,
      { ...getProperties(state), number_of_files: getSize(payload.value.get('byId')) });
  }
}
export const photoEditorMiddleware = storeMiddleware => next => action => {
  if ( EVENT_ACTIONS.has(action.type)) {
    const state = storeMiddleware.getState();
    const photoEditor = state.getIn(['photoEditor']);
    trackActionEvent(photoEditor, action.type, action.payload, state);
  }
  return next(action);
};

function trackImmerActionEvent(payload, actionType, state) {
  if (actionType === PHOTO_ACTIONS.SET_TOOL) {
    mixpanel.track(TRACKING_EVENT.TOOLS[payload], getProperties(state));
  }
}

export function immerMiddleware(action, state) {
  if (EDITOR_ACTIONS.has(action.type)) {
    trackImmerActionEvent(action.payload, action.type, state);
  }
}

function getProperties(provided) {
  const state = provided || store.getState();
  let shopId; let channelName; let planName;
  if (state instanceof Map) {
    shopId = state.getIn(['shops', 'current']);
    channelName = CHANNEL_NAME[state.getIn(['shops', 'byId', shopId, 'channel'])];
    planName = getPlanName(state.getIn(['user', 'subscriptions', shopId]));
  } else {
    shopId = state.shopId;
    channelName = state.channel;
    planName = getPlanName(store.getState().getIn(['user', 'subscriptions', shopId]));
  }
  return {
    channel: channelName,
    plan: planName,
  };
}
