import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import * as Sentry from '@sentry/react';

import { isChannelFullySupported, isFeatureEnabled } from '../utils/featureFlags';
import { getPlanName, isBillingError, isUnpaid } from '../utils/billing';
import { getFromState } from '../utils/getFromState';
import { getSize } from '../utils/iterable/getSize';
import { toJS } from '../utils/iterable/toJS';

import { FEATURES, PLAN } from '../constants/billing';

import { Immutable } from '../prop-types';

export const ShopsContext = createContext({ shopsLoaded: false });
ShopsContext.displayName = 'ShopsContext';

const FEATURES_TO_CHECK = [
  FEATURES.SALES_PROFILES,
];

function ContextProvider({ children, shops, user }) {
  const current = shops.get('current');
  const byId = shops.get('byId');
  const loaded = user.get('loaded');
  const userId = user.get('userId');
  const subscriptions = user.get('subscriptions');
  const value = useMemo(
    function factory() {
      const result = {
        hasShops: !!getSize(byId),
        multipleShops: getSize(byId) > 1,
        shopsLoaded: !!byId,
        featureEnabled: {},
        shops,
      };

      if (result.shopsLoaded) {
        if (result.hasShops) {
          const shopId = current;
          const shop = byId.get(shopId, Map());
          result.shopId = shopId;
          result.db = shop.get('db');
          result.shop = shop;
          result.channel = shop.get('channel');
          result.channelId = shop.get('channelId');
        }

        result.subscriptionsLoaded = (loaded && (!result.hasShops || !!subscriptions));

        if (result.hasShops && subscriptions) {
          for (const feature of FEATURES_TO_CHECK) {
            result.featureEnabled[feature] = isFeatureEnabled({
              feature,
              channel: byId.getIn([current, 'channel']),
              db: byId.getIn([current, 'db']),
              shopId: current,
              shopIds: shops.get('options'),
              subscriptions,
              userId,
            });
          }
        }

        if (
          result.hasShops &&
          result.subscriptionsLoaded &&
          isChannelFullySupported(result.channel)
        ) {
          const subscription = subscriptions.get(result.shopId);

          if (!subscription) {
            result.subscriptionError = true;
            Sentry.captureException(
              'Subscription not found',
              {
                tags: {
                  userId,
                  shopId: result.shopId,
                  db: result.db,
                },
                extra: {
                  shops: toJS(byId),
                  subscriptions: toJS(subscriptions),
                },
              }
            );
          } else {
            result.plan = subscription.get('plan');
            result.planName = getPlanName(subscription);
            result.subscriptionError = isBillingError({
              syncStatus: byId.getIn([result.shopId, 'syncStatus']),
              plan: subscription.get('plan', PLAN.ERROR),
            });

            result.unpaid = !result.subscriptionError && isUnpaid(subscription);
          }
        } else {
          result.subscriptionError = false;
          result.unpaid = false;
        }
      }

      return result;
    },
    [current, byId, loaded, subscriptions]
  );

  return (
    <ShopsContext.Provider value={value}>
      {children}
    </ShopsContext.Provider>
  );
}

ContextProvider.propTypes = {
  children: PropTypes.node,
  shops: Immutable.Map,
  user: Immutable.Map,
};

export default connect(getFromState('shops', 'user'))(ContextProvider);
