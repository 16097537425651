import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isFeatureEnabled } from '../utils/featureFlags';
import { getFromState } from '../utils/getFromState';

import { FEATURES } from '../constants/billing';

import { Immutable } from '../prop-types';

export const UserContext = createContext({ shopsLoaded: false });
UserContext.displayName = 'UserContext';

const FEATURES_TO_CHECK = [
  FEATURES.CANVA,
  FEATURES.CSV_EXPORT,
  FEATURES.CSV_IMPORT,
  FEATURES.CSV_OVERRIDE,
  FEATURES.EBAY_INTEGRATION,
  FEATURES.FILES_TO_S3,
  FEATURES.SHOPIFY_INVENTORY_POLICY,
  FEATURES.UI_UPDATES_AUGUST,
];

function ContextProvider({ children, user }) {
  const loaded = user.get('loaded');
  const userId = user.get('userId');
  const value = useMemo(
    function factory() {
      const featureEnabled = {};

      if (loaded) {
        for (const feature of FEATURES_TO_CHECK) {
          featureEnabled[feature] = isFeatureEnabled({ feature, userId });
        }
      }

      return {
        featureEnabled,
        loaded,
        userId,
        user,
      };
    },
    [user]
  );

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
}

ContextProvider.propTypes = {
  children: PropTypes.node,
  user: Immutable.Map,
};

export default connect(getFromState('user'))(ContextProvider);
