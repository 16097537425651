/* eslint-disable no-console */
import { sideEffect } from 'redux-side-effects';
import { List, Map, Set } from 'immutable';

import { shapeCountsForApp, shapeUpdatesForApp } from '../utils/scheduledUpdates/shapeForApp';
import { getFirstNonZeroOption } from '../utils/scheduledUpdates/getFirstNonZeroOption';
import { createController } from '../utils/reducer';
import { getSize } from '../utils/iterable/getSize';
import { get } from '../utils/iterable/get';
import api from '../utils/api';

import { UPDATE, UPDATES_PER_PAGE } from '../constants/scheduledUpdates';
import { MESSAGE, NOTIFICATION } from '../constants/notifications';
import { POLLING_INTERVAL } from '../constants/shops';
import { MODALS } from '../constants/modal';
import ACTIONS from '../constants/actions';

import Reducers, { Reducer } from '../classes/Reducer';
import Actions from '../actions';

const controller = createController();

function* bootstrap(reduction) {
  const shopId = reduction.getIn(['shops', 'current']);
  const shop = reduction.getIn(['shops', 'byId', shopId]);
  const channel = shop.get('channel');
  const db = shop.get('db');

  yield sideEffect((dispatch) => {
    dispatch(
      Actions.ScheduledUpdates.loadUpdates({
        getNextScheduled: true,
        skipEmpty: !reduction.hasIn(['scheduledUpdates', 'nextScheduled']),
        updateCounts: true,
      })
    );
  });

  return reduction.set('scheduledUpdates',
    Map({
      channel,
      db,
      menu: Map({
        selected: reduction.getIn(['scheduledUpdates', 'menu', 'selected'], UPDATE.PENDING),
      }),
      nextScheduled: reduction.getIn(['scheduledUpdates', 'nextScheduled'], Map()),
      pagination: Map({ limit: UPDATES_PER_PAGE, offset: 0 }),
      shopId,
    })
  );
}

function* cleanUp(reduction) {
  return reduction.set('scheduledUpdates',
    Map({
      nextScheduled: reduction.getIn(['scheduledUpdates', 'nextScheduled'], Map()),
    })
  );
}

function* deleteUpdates(reduction, ids) {
  const data = reduction.get('scheduledUpdates');
  const processing = data.getIn(['updates', 'processing'], Set()).union(Set(ids));

  yield sideEffect((dispatch) => {
    const db = data.get('db');
    const shopId = data.get('shopId');

    function mapIds(updateId) {
      return api.scheduledUpdates.delete({ db, shopId, updateId });
    }

    Promise
      .all(ids.map(mapIds))
      .then(
        () => dispatch(Actions.ScheduledUpdates.deleteUpdatesSucceeded({ processing, shopId })),
        (error) =>
          dispatch(Actions.ScheduledUpdates.deleteUpdatesFailed({ error, processing, shopId })),
      );
  });

  return reduction.setIn(['scheduledUpdates', 'updates', 'processing'], processing);
}

function* deleteUpdatesFailed(reduction, { error, processing, shopId }) {
  function updateSelected(selected = Set()) {
    return selected.subtract(processing);
  }

  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.DELETE_UPDATES,
      })
    );

    if (reduction.getIn(['scheduledUpdates', 'shopId']) === shopId) {
      dispatch(
        Actions.ScheduledUpdates.loadUpdates({
          getNextScheduled: true,
          updateCounts: true,
        })
      );
    }
  });

  return reduction.getIn(['scheduledUpdates', 'shopId']) === shopId
    ? reduction.updateIn(['scheduledUpdates', 'updates', 'selected'], updateSelected)
    : reduction;
}

function* deleteUpdatesSucceeded(reduction, { processing, shopId }) {
  function updateSelected(selected = Set()) {
    return selected.subtract(processing);
  }

  yield sideEffect((dispatch) => {
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.SUCCESS,
        message: MESSAGE.SUCCESS.DELETE_UPDATES,
      })
    );

    if (reduction.getIn(['scheduledUpdates', 'shopId']) === shopId) {
      dispatch(
        Actions.ScheduledUpdates.loadUpdates({
          getNextScheduled: true,
          updateCounts: true,
        })
      );
    }
  });

  return reduction.getIn(['scheduledUpdates', 'shopId']) === shopId
    ? reduction.updateIn(['scheduledUpdates', 'updates', 'selected'], updateSelected)
    : reduction;
}

function* loadUpdates(reduction, { getNextScheduled, updateCounts, skipEmpty } = {}) {
  const { signal } = controller.start();
  let data = reduction.get('scheduledUpdates');
  let shopId = data.get('shopId');

  if (!shopId && getNextScheduled) {
    shopId = reduction.getIn(['shops', 'current']);
  }

  const db = reduction.getIn(['shops', 'byId', shopId, 'db']);

  if (getNextScheduled) {
    data = data.setIn(['scheduledUpdates', 'nextScheduled', shopId, 'loading'], true);
  }

  yield sideEffect((dispatch) => {
    const requests = [];
    const status = data.getIn(['menu', 'selected']);
    const offset = data.getIn(['pagination', 'offset']);

    if (data.get('shopId') === shopId) {
      const limit = data.getIn(['pagination', 'limit']);
      requests.push(api.scheduledUpdates.getUpdates({ db, limit, offset, shopId, signal, status }));
    } else {
      requests.push(Promise.resolve());
    }

    if (getNextScheduled || updateCounts) {
      requests.push(api.scheduledUpdates.getCounts({ db, shopId, signal }));
    } else {
      requests.push(Promise.resolve());
    }

    if (getNextScheduled && (offset > 0 || status !== UPDATE.PENDING)) {
      requests.push(
        api.scheduledUpdates.getUpdates({
          db,
          limit: 1,
          offset: 0,
          shopId,
          signal,
          status: UPDATE.PENDING,
        }),
      );
    }

    Promise
      .all(requests)
      .then(
        (responses) =>
          dispatch(
            Actions.ScheduledUpdates.loadUpdatesSucceeded({
              responses,
              shopId,
              skipEmpty,
            })
          ),
        (error) =>
          dispatch(Actions.ScheduledUpdates.loadUpdatesFailed({ error, shopId, signal })),
      );
  });

  return reduction
    .setIn(['scheduledUpdates', 'keepOnEmpty'], true)
    .setIn(['scheduledUpdates', 'loading'], true);
}

function* loadUpdatesFailed(reduction, { error, shopId, signal }) {
  if (signal.aborted) return reduction;

  controller.stop();

  yield sideEffect((dispatch) => {
    console.error(error);

    if (reduction.getIn(['scheduledUpdates', 'shopId']) === shopId) {
      dispatch(
        Actions.Notifications.add({
          type: NOTIFICATION.ERROR,
          message: MESSAGE.FAIL.LOAD_UPDATES,
        })
      );
    }
  });

  return reduction
    .deleteIn(['scheduledUpdates', 'loading'])
    .deleteIn(['scheduledUpdates', 'nextScheduled', shopId, 'loading']);
}

function* loadUpdatesSucceeded(reduction, { responses, shopId, skipEmpty }) {
  controller.stop();

  let data = reduction
    .get('scheduledUpdates')
    .delete('loading')
    .deleteIn(['nextScheduled', shopId, 'loading']);

  const [currentPage, counts, nextScheduled] = responses;
  const status = data.getIn(['menu', 'selected']);
  const offset = data.getIn(['pagination', 'offset']);

  if (nextScheduled) {
    data = data.setIn(['nextScheduled', shopId, 'nextUpdate'],
      get(['scheduled_updates', 0, 'scheduled_at'])(nextScheduled),
    );
  } else if (offset === 0 && status === UPDATE.PENDING) {
    data = data.setIn(['nextScheduled', shopId, 'nextUpdate'],
      get(['scheduled_updates', 0, 'scheduled_at'])(currentPage),
    );
  }

  if (counts) {
    data = data.setIn(['nextScheduled', shopId, 'total'],
      parseInt(counts[UPDATE.PENDING] || 0, 10)
    );

    if (data.get('shopId') === shopId) {
      data = data
        .setIn(['pagination', 'total'], parseInt(counts[status] || 0, 10))
        .set('menu', shapeCountsForApp({ counts, menu: Map({ selected: status }) }));
    }
  } else if (data.get('shopId') === shopId) {
    data = data.setIn(['pagination', 'total'],
      data.getIn(['menu', 'byId', status, 'counts', 'raw'])
    );
  }

  if (currentPage && data.get('shopId') === shopId) {
    const channel = data.get('channel');
    const limit = data.getIn(['pagination', 'limit']);
    const page = offset / limit;

    if (!getSize(currentPage.scheduled_updates)) {
      if (page > 0) {
        if (counts) {
          const total = data.getIn(['pagination', 'total']);
          const reminder = total % limit;
          const newOffset = reminder
            ? total - reminder
            : Math.max(0, total - limit);

          data = data.setIn(['pagination', 'offset'], newOffset);
        }

        yield sideEffect((dispatch) => {
          dispatch(Actions.ScheduledUpdates.loadUpdates({ updateCounts: !counts }));
        });

        data = data.set('loading', true);
      } else {
        if (skipEmpty) {
          const firstNonZeroOption = getFirstNonZeroOption(data.get('menu'));

          if (firstNonZeroOption && firstNonZeroOption !== status) {
            yield sideEffect((dispatch) => {
              dispatch(Actions.ScheduledUpdates.loadUpdates({ updateCounts: !counts }));
            });

            return reduction.set('scheduledUpdates',
              data.setIn(['menu', 'selected'], firstNonZeroOption)
            );
          }
        }

        data = data
          .setIn(['pagination', 'page'], 0)
          .setIn(['pagination', 'start'], 0)
          .setIn(['pagination', 'end'], 0)
          .set('updates', Map({ byId: Map(), ids: List(), selected: Set(), status }));
      }
    } else {
      data = data
        .setIn(['pagination', 'page'], page)
        .setIn(['pagination', 'start'], offset + 1)
        .setIn(['pagination', 'end'], Math.min(offset + limit, data.getIn(['pagination', 'total'])))
        .set('updates',
          shapeUpdatesForApp({
            channel,
            data: currentPage.scheduled_updates,
            status,
            updates: Map({
              selected: data.getIn(['updates', 'selected'], Set()),
              status,
            }),
          }),
        );
    }
  }

  return reduction.set('scheduledUpdates', data);
}

function* setPage(reduction, page) {
  yield sideEffect((dispatch) => {
    dispatch(Actions.ScheduledUpdates.loadUpdates());
  });

  const offset = page * reduction.getIn(['scheduledUpdates', 'pagination', 'limit']);

  return reduction
    .setIn(['scheduledUpdates', 'pagination', 'page'], page)
    .setIn(['scheduledUpdates', 'pagination', 'offset'], offset);
}

function* setStatus(reduction, status) {
  yield sideEffect((dispatch) => {
    dispatch(Actions.ScheduledUpdates.loadUpdates());
  });

  return reduction
    .setIn(['scheduledUpdates', 'menu', 'selected'], status)
    .setIn(['scheduledUpdates', 'pagination', 'offset'], 0)
    .setIn(['scheduledUpdates', 'loading'], true);
}

function* showSyncModal(reduction, { shopId }) {
  yield sideEffect((dispatch) => {
    dispatch(
      Actions.Shops.setData({
        path: ['syncData', shopId],
        value: Map({ modal: MODALS.SYNC.SCHEDULED_UPDATE }),
      })
    );

    dispatch(
      Actions.Shops.rescheduleShopsPolling(
        reduction.getIn(['user', 'config', 'shopsPollingIntervalShort']) ||
        POLLING_INTERVAL.SHORT
      )
    );
  });

  return reduction;
}

function* toggleUpdate(reduction, updateId) {
  function updateSelected(selected = Set()) {
    return selected.has(updateId)
      ? selected.delete(updateId)
      : selected.add(updateId);
  }

  return reduction.updateIn(['scheduledUpdates', 'updates', 'selected'], updateSelected);
}

Reducers.add(
  new Reducer('ScheduledUpdates')
    .add(ACTIONS.SCHEDULEDUPDATES.BOOTSTRAP, bootstrap)
    .add(ACTIONS.SCHEDULEDUPDATES.CLEAN_UP, cleanUp)
    .add(ACTIONS.SCHEDULEDUPDATES.DELETE_UPDATES, deleteUpdates)
    .add(ACTIONS.SCHEDULEDUPDATES.DELETE_UPDATES_FAILED, deleteUpdatesFailed)
    .add(ACTIONS.SCHEDULEDUPDATES.DELETE_UPDATES_SUCCEEDED, deleteUpdatesSucceeded)
    .add(ACTIONS.SCHEDULEDUPDATES.LOAD_UPDATES, loadUpdates)
    .add(ACTIONS.SCHEDULEDUPDATES.LOAD_UPDATES_FAILED, loadUpdatesFailed)
    .add(ACTIONS.SCHEDULEDUPDATES.LOAD_UPDATES_SUCCEEDED, loadUpdatesSucceeded)
    .add(ACTIONS.SCHEDULEDUPDATES.SET_PAGE, setPage)
    .add(ACTIONS.SCHEDULEDUPDATES.SET_STATUS, setStatus)
    .add(ACTIONS.SCHEDULEDUPDATES.SHOW_SYNC_MODAL, showSyncModal)
    .add(ACTIONS.SCHEDULEDUPDATES.TOGGLE_UPDATE, toggleUpdate)
);
