import { Map, Set } from 'immutable';

import { CHANNEL_STATUSES, CHANNEL_STATUS_MAP, STATUS, STATUS_TYPE, VELA_STATUSES } from '../../constants/listings';
import { DEFAULTS } from '../../constants';

const { EMPTY_MAP } = DEFAULTS;

export function getDefaultStatuses({ channel, status = STATUS.ACTIVE, statuses = EMPTY_MAP }) {
  return Map({
    expanded: statuses.get('expanded', Set([STATUS_TYPE.CHANNEL, STATUS_TYPE.VELA])),
    selected: CHANNEL_STATUS_MAP[channel][status] || status,
  });
}

export function getFirstNonEmptyStatus({ channel, counts }) {
  for (const status of CHANNEL_STATUSES[channel]) {
    if (counts.get(status)) {
      return status;
    }
  }

  for (const status of VELA_STATUSES) {
    if (counts.get(status)) {
      return status;
    }
  }

  return undefined;
}

export function shapeCountsForApp({ channel, response }) {
  let counts = EMPTY_MAP;

  for (const status of CHANNEL_STATUSES[channel]) {
    counts = counts.set(status, response[status] || 0);
  }

  for (const status of VELA_STATUSES) {
    counts = counts.set(status, response[status] || 0);
  }

  return counts;
}

export function isPublished(status) {
  switch (status) {
    case STATUS.COPIED:
    case STATUS.TEMPLATE:
    case STATUS.UNPUBLISHED: {
      return false;
    }

    default: {
      return true;
    }
  }
}
