import { newNotification, notificationExists } from '../utils/notifications';

import Reducers, { Reducer } from '../classes/Reducer';

import ACTIONS from '../constants/actions';

function* add(reduction, notification) {
  function updateNotifications(notifications) {
    return notifications.push(newNotification(notification));
  }

  return notificationExists({ notification, notifications: reduction.get('notifications') })
    ? reduction
    : reduction.update('notifications', updateNotifications);
}

function* remove(reduction, id) {
  function updateNotifications(items) {
    function filterNotifications(notification) {
      return notification.get('id') !== id;
    }

    return items.filter(filterNotifications);
  }

  return reduction.update('notifications', updateNotifications);
}

Reducers.add(
  new Reducer('Notifications')
    .add(ACTIONS.NOTIFICATIONS.ADD, add)
    .add(ACTIONS.NOTIFICATIONS.REMOVE, remove)
);
