import { Set } from 'immutable';

import { setVideosError } from '../../product/errors';

import { MAX_NUMBER_OF_VIDEOS } from '../../../constants/validations';
import { OPERATIONS } from '../../../constants/bulkEdit';
import { VIDEOS } from '../../../constants/attributes';
import { VALUE } from '../../../constants/product';

export default function videos({ actions, operation, product }) {
  const channel = product.get('channel');

  function replaceVideos(video, index) {
    return video && operation.getIn([VALUE, 'index']) === index
      ? operation.getIn([VALUE, 'video'])
      : video;
  }

  function validateAndApply(value) {
    return {
      actions,
      product: setVideosError({
        channel,
        product: product.setIn([VIDEOS, VALUE], value),
      }),
    };
  }

  switch (operation.get('type')) {
    case OPERATIONS[channel].VIDEOS.ADD: {
      let value = product.getIn([VIDEOS, VALUE]).concat(operation.get(VALUE));

      if (MAX_NUMBER_OF_VIDEOS.hasOwnProperty(channel)) {
        value = value.slice(0, MAX_NUMBER_OF_VIDEOS[channel]);
      }

      return validateAndApply(value);
    }

    case OPERATIONS[channel].VIDEOS.DELETE: {
      const indexes = Set(operation.getIn([VALUE, 'index']));
      return validateAndApply(
        product
          .getIn([VIDEOS, VALUE])
          .filter(function filterVideos(video, index) {
            return !indexes.has(index);
          })
      );
    }

    case OPERATIONS[channel].VIDEOS.REPLACE: {
      return validateAndApply(product.getIn([VIDEOS, VALUE]).map(replaceVideos));
    }

    default: {
      break;
    }
  }

  return { actions, product };
}
