import { Set } from 'immutable';

import { getPlanName } from '../../billing';
import { getSize } from '../../iterable/getSize';
import mixpanel from '../../tracking/mixpanel';

import { CHANNEL_NAME } from '../../../constants/channels';
import { EVENT } from '../../../constants/tracking';
import ACTIONS from '../../../constants/actions';

const EVENT_ACTIONS = Set([
  ACTIONS.MEDIA_LIBRARY.CREATE_FOLDER,
  ACTIONS.MEDIA_LIBRARY.DELETE_FOLDER,
  ACTIONS.MEDIA_LIBRARY.DELETE_IMAGES,
  ACTIONS.MEDIA_LIBRARY.DOWNLOAD_IMAGES,
  ACTIONS.MEDIA_LIBRARY.MOVE_IMAGES,
  ACTIONS.MEDIA_LIBRARY.RENAME_FOLDER,
  ACTIONS.MEDIA_LIBRARY.UPLOAD_FILES,
  ACTIONS.MEDIA_LIBRARY.SET_VALUE,
]);

function trackActionEvent(mediaLibrary, actionType, payload, state) {
  switch (actionType) {
    case ACTIONS.MEDIA_LIBRARY.CREATE_FOLDER: {
      mixpanel.track(EVENT.LIBRARY.FOLDER.CREATE, getProperties(state));
      break;
    }
    case ACTIONS.MEDIA_LIBRARY.DELETE_FOLDER: {
      mixpanel.track(EVENT.LIBRARY.FOLDER.DELETE, getProperties(state));
      break;
    }
    case ACTIONS.MEDIA_LIBRARY.DELETE_IMAGES: {
      const uuids = payload.uuids;
      const imagesSize = getSize(uuids);
      const eventType = imagesSize > 1 ? EVENT.LIBRARY.IMAGE.BULK_DELETE : EVENT.LIBRARY.IMAGE.DELETE;
      let properties = getProperties(state);
      properties = imagesSize > 1 ? { ...properties, number_of_files: imagesSize } : properties;
      mixpanel.track( eventType, properties);
      break;
    }
    case ACTIONS.MEDIA_LIBRARY.DOWNLOAD_IMAGES: {
      const uuids = payload.uuids;
      const singleDownload = typeof uuids === 'string';
      const properties = getProperties(state);
      const decorateProperties = singleDownload ?
        getProperties(state) : { ...properties, number_of_files: getSize(uuids) };
      mixpanel.track(singleDownload ?
        EVENT.LIBRARY.IMAGE.DOWNLOAD : EVENT.LIBRARY.IMAGE.BULK_DOWNLOAD, decorateProperties);
      break;
    }
    case ACTIONS.MEDIA_LIBRARY.MOVE_IMAGES: {
      mixpanel.track(EVENT.LIBRARY.IMAGE.MOVE, getProperties(state));
      break;
    }
    case ACTIONS.MEDIA_LIBRARY.RENAME_FOLDER: {
      mixpanel.track(EVENT.LIBRARY.FOLDER.RENAME, getProperties(state));
      break;
    }
    case ACTIONS.MEDIA_LIBRARY.UPLOAD_FILES: {
      const files = getSize(payload.files);
      if (!files) {
        const count = mediaLibrary.getIn(['uploading', 'count']);
        mixpanel.track(EVENT.LIBRARY.IMAGE.UPLOAD, { ...getProperties(state), number_of_files: count });
      }
      break;
    }
    case ACTIONS.MEDIA_LIBRARY.SET_VALUE: {
      if (payload.path && Array.isArray(payload.path)
        && payload.path[0] === 'images' && payload.path[1] === 'editing'
        && !mediaLibrary.getIn(['images', 'editing'])) {
        mixpanel.track(EVENT.LIBRARY.IMAGE.EDIT, getProperties(state));
      }
      break;
    }
    default: {
      break;
    }
  }
}
export const libraryMiddleware = store => next => action => {
  if ( EVENT_ACTIONS.has(action.type)) {
    const mediaLibrary = store.getState().getIn(['mediaLibrary']);
    const state = store.getState();
    trackActionEvent(mediaLibrary, action.type, action.payload, state);
  }
  return next(action);
};

function getProperties(state) {
  const shopId = state.getIn(['shops', 'current']);
  const channelName = CHANNEL_NAME[state.getIn(['shops', 'byId', shopId, 'channel'])];
  const planName = getPlanName(state.getIn(['user', 'subscriptions', shopId]));
  return {
    channel: channelName,
    plan: planName,
  };
}
