/* eslint-disable no-console */
import { sideEffect } from 'redux-side-effects';
import { List, Map, Set } from 'immutable';
import SHA1 from 'crypto-js/sha1';

import { getFilterIdsForMenuItem, shapeFiltersForAPI, updateFiltersForMenuItem } from '../utils/bulkEdit';
import { clearMenuTaxonomyAttrubutes, updateMenuTaxonomyAttrubutes } from '../utils/bulkEdit/taxonomy';
import { setErrorCounts, shapeErrorCountsForApp, updateErrorCounts } from '../utils/bulkEdit/errors';
import { getImageTypeByUrl, makeThumbnailUrl } from '../utils/photos';
import { getEditedAttributesAndListings } from '../utils/product/getEditedAttributesAndListings';
import { filterNewTags, tagsFromArray } from '../utils/tags';
import { applyOperationsToProducts } from '../utils/bulkEdit/operations';
import { applyPreviewOperations } from '../utils/bulkEdit/applyPreviewOperations';
import { getPreviewOperations } from '../utils/bulkEdit/getPreviewOperations';
import { originalToProcessed } from '../utils/photoEditor/shapePhotosById';
import { shapeProductForApp } from '../utils/product/shapeForApp';
import { isFeatureEnabled } from '../utils/featureFlags';
import { createController } from '../utils/reducer';
import { getListingsPath } from '../utils/listings/getListingsPath';
import { isApplyDisabled } from '../utils/bulkEdit/isApplyDisabled';
import { applyProfile } from '../utils/bulkEdit/applyProfile';
import { getTagError } from '../utils/validations/tags';
import { getPageInfo } from '../utils/pagination';
import { getPlanName } from '../utils/billing';
import { navigateTo } from '../utils/navigation';
import { removeBG } from '../utils/photoEditor/removeBG';
import { isTruthy } from '../utils/bool';
import { shapeId } from '../utils/listings/listings';
import { getSize } from '../utils/iterable/getSize';
import { convert } from '../utils/product/convert';
import amplitude from '../utils/tracking/amplitude';
import mixpanel from '../utils/tracking/mixpanel';
import api from '../utils/api';

import { DESCRIPTION, DIGITAL, PHOTOS, PHYSICAL, TAGS, TITLE } from '../constants/attributes';
import { SYNC_INDICATOR, POLLING_INTERVAL, WAIT_FOR_SYNC } from '../constants/shops';
import { MAX_NUMBER_OF_AI_TAGS, MAX_NUMBER_OF_TAGS } from '../constants/validations';
import { DEFAULTS, SEPARATOR, THUMBNAIL_SIZE } from '../constants';
import { PAGE_SIZE, SELECTION, STATUS } from '../constants/listings';
import { CHANNEL_NAME, ETSY, SHOPIFY } from '../constants/channels';
import { MESSAGE, NOTIFICATION } from '../constants/notifications';
import { OPERATION } from '../constants/ai';
import { FEATURES } from '../constants/billing';
import { PROFILE } from '../constants/profiles';
import { MODALS } from '../constants/modal';
import { EVENT } from '../constants/tracking';
import { VALUE } from '../constants/product';
import ACTIONS from '../constants/actions';
import {
  FILTER,
  MENU_ITEM,
  MENU_ITEMS,
  MENU_ITEMS_TO_ATTRIBUTE_MAP,
  MENU_SECTION,
  MENU_SECTIONS,
  MENU_SECTION_ITEMS,
  OPERATIONS,
  SAVE_TYPE,
} from '../constants/bulkEdit';

import Reducers, { Reducer } from '../classes/Reducer';
import Actions from '../actions';

const { EMPTY_LIST, EMPTY_MAP, EMPTY_ORDERED_MAP, EMPTY_SET, EMPTY_STRING } = DEFAULTS;

const aiGenerateController = createController();
const categoriesController = createController();
const invalidController = createController();
const listingsController = createController();
const listingIdsController = createController();

function abort(controller) {
  if (controller.isActive()) {
    controller.stop();
  }
}

function abortAll() {
  [
    aiGenerateController,
    categoriesController,
    invalidController,
    listingsController,
    listingIdsController,
  ].forEach(abort);
}

function* addOperation(reduction, operation) {
  function updateEdited(edited = EMPTY_SET) {
    return edited.union(operation.get('products'));
  }

  function updateOperations(operations = EMPTY_LIST) {
    return operations.push(operation);
  }

  // DEV-676 Amplitude events trimming
  // const [menuItem, type] = operation.get('type').split('.');

  // if (menuItem !== 'status') {
  //   yield sideEffect(() => {
  //     amplitude.logEvent({ type: `Edited ${menuItem} inline`, options: { type }});
  //   });
  // }

  return reduction
    .updateIn(['bulkEdit', 'operations'], updateOperations)
    .updateIn(['bulkEdit', 'products', 'edited'], updateEdited);
}

function* addPendingUpdates(reduction, section) {
  const menuItem = section || reduction.getIn(['bulkEdit', 'menu', 'selected', 'item']);
  return reduction.setIn(['bulkEdit', 'menu', 'pendingUpdates', menuItem], true);
}

function* addPreview(reduction, attributes) {
  let bulkEdit = reduction.get('bulkEdit');

  bulkEdit = bulkEdit.set('preview',
    bulkEdit.get('preview', EMPTY_MAP).merge(attributes)
  );

  yield sideEffect((dispatch) => {
    const previewOperations = getPreviewOperations({ channel: bulkEdit.get('channel'), preview: bulkEdit.get('preview') });

    if (getSize(previewOperations)) {
      const shopId = bulkEdit.get('shopId');
      const shopData = reduction.getIn(['data', 'shopsData', shopId]);
      const { actions } = applyOperationsToProducts({
        bulkPhotoEditor: bulkEdit.get('bulkPhotoEditor'),
        operations: previewOperations,
        products: bulkEdit.get('products'),
        shopData,
      });

      if (getSize(actions)) {
        actions.forEach(dispatch);
      }
    }
  });

  return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
}

function* aiDiscard(reduction, { attribute, page }) {
  abort(aiGenerateController);
  let bulkEdit = reduction.get('bulkEdit');
  const preview = bulkEdit.getIn(['preview', attribute], EMPTY_MAP);
  const operations = preview.get('operations', EMPTY_MAP).delete(page);
  const processed = preview.get('processed', EMPTY_MAP).delete(page);
  const processing = preview.get('processing', EMPTY_MAP).delete(page);
  const values = preview.get('values', EMPTY_MAP).filter(function filterValues(value, productId) {
    return !preview.hasIn(['processed', page, productId]);
  });

  if (getSize(operations)) {
    bulkEdit = bulkEdit.setIn(['preview', attribute, 'operations'], operations);
  } else {
    bulkEdit = bulkEdit.deleteIn(['preview', attribute, 'operations']);
  }

  if (getSize(processed)) {
    bulkEdit = bulkEdit.setIn(['preview', attribute, 'processed'], processed);
  } else {
    bulkEdit = bulkEdit.deleteIn(['preview', attribute, 'processed']);
  }

  if (getSize(processing)) {
    bulkEdit = bulkEdit.setIn(['preview', attribute, 'processing'], processing);
  } else {
    bulkEdit = bulkEdit.deleteIn(['preview', attribute, 'processing']);
  }

  if (getSize(values)) {
    bulkEdit = bulkEdit.setIn(['preview', attribute, 'values'], values);
  } else {
    bulkEdit = bulkEdit.deleteIn(['preview', attribute, 'values']);
  }

  if (!getSize(operations) && !getSize(processing) && !getSize(processed) && !getSize(values)) {
    if (attribute === TAGS && bulkEdit.hasIn(['preview', attribute, 'profile'])) {
      bulkEdit = bulkEdit.set('preview',
        Map({
          [attribute]: Map({
            profile: bulkEdit.getIn(['preview', attribute, 'profile']),
          }),
        })
      );
    } else {
      bulkEdit = bulkEdit.set('preview', EMPTY_MAP);
    }
  }

  return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
}

function* aiGenerate(reduction, { attribute, operation, options = {}, page, productIds }) {
  abort(aiGenerateController);
  let bulkEdit = reduction.get('bulkEdit');
  const channel = bulkEdit.get('channel');
  const products = bulkEdit.get('products');
  const preview = bulkEdit.getIn(['preview', attribute], EMPTY_MAP);

  function reduceProductIds(result, productId) {
    if (!products.hasIn(['selected', productId])) return result;

    const product = products.getIn(['byId', productId]);
    const title = product.getIn([TITLE, VALUE]) || EMPTY_STRING;
    const description = product.getIn([DESCRIPTION, VALUE]) || EMPTY_STRING;
    const photos = product.getIn([PHOTOS, VALUE]) || EMPTY_LIST;

    switch (attribute) {
      case DESCRIPTION: {
        if (!title || !description) return result;

        switch (options.operation) {
          case OPERATION.ENLARGE:
          case OPERATION.EVENTS:
          case OPERATION.IMPROVE:
          case OPERATION.SHORTEN:
          case OPERATION.TONE: {
            if (!title || !description) return result;

            break;
          }

          default: {
            if (!description) return result;

            break;
          }
        }

        const request = {
          channel,
          destination: DESCRIPTION,
          input: {
            [DESCRIPTION]: description,
          },
          operation: options.operation,
          productId,
        };

        switch (options.operation) {
          case OPERATION.ENLARGE:
          case OPERATION.IMPROVE:
          case OPERATION.SHORTEN: {
            request.input[TITLE] = title;
            break;
          }

          case OPERATION.EVENTS: {
            request.input[TITLE] = title;
            request.input.event = options.option;
            break;
          }

          case OPERATION.TONE: {
            request.input[TITLE] = title;
            request.input.tone = options.option;
            break;
          }

          default: {
            break;
          }
        }

        result.processing = result.processing.add(productId);
        result.requests.push(request);
        break;
      }

      case PHOTOS: {
        preview.get('indexes').forEach(function forEachIndex(index) {
          const url = photos.getIn([index, 'fullsize_url']);
          const imageType = getImageTypeByUrl(url);

          if (imageType && imageType !== 'local') {
            if (!getSize(result.processing)) {
              result.processing = Map({ [productId]: Set([index]) });
            } else if (!result.processing.has(productId)) {
              result.processing = result.processing.set(productId, Set([index]));
            } else {
              result.processing = result.processing.update(productId, function updateProcessing(processing) {
                return processing.add(index);
              });
            }

            result.requests.push({ image: url, index, productId });
          }
        });

        break;
      }

      case TAGS: {
        if (!title || !description) return result;

        const tags = preview.getIn([TAGS, 'profile', TAGS]) || product.getIn([TAGS, VALUE]) || EMPTY_ORDERED_MAP;

        if (
          options.operation === OPERATION.ADD_MAXIMUM &&
          MAX_NUMBER_OF_AI_TAGS.hasOwnProperty(channel) &&
          getSize(tags) >= MAX_NUMBER_OF_AI_TAGS[channel]
        ) {
          return result;
        }

        const request = {
          channel,
          destination: TAGS,
          input: {
            [DESCRIPTION]: convert({ from: channel, to: ETSY, type: DESCRIPTION, value: description }),
            [TITLE]: title,
          },
          operation: options.operation,
          productId,
        };

        switch (options.operation) {
          case OPERATION.ADD_MAXIMUM: {
            request.input[TAGS] = tags.toList().slice(0, MAX_NUMBER_OF_TAGS[channel]).join(SEPARATOR.COMMA_SPACE);
            break;
          }

          default: {
            break;
          }
        }

        result.processing = result.processing.add(productId);
        result.requests.push(request);
        break;
      }

      case TITLE: {
        switch (options.operation) {
          case OPERATION.ENLARGE: {
            if (!description || !title) return result;

            break;
          }

          default: {
            if (!title) return result;

            break;
          }
        }

        const request = {
          channel,
          destination: TITLE,
          input: {
            [TITLE]: title,
          },
          operation: options.operation,
          productId,
        };

        switch (options.operation) {
          case OPERATION.ENLARGE: {
            request.input[DESCRIPTION] = convert({ from: channel, to: ETSY, type: DESCRIPTION, value: description });
            break;
          }

          default: {
            break;
          }
        }

        result.processing = result.processing.add(productId);
        result.requests.push(request);
        break;
      }

      default: {
        break;
      }
    }

    return result;
  }

  const { processing, requests } = productIds.reduce(
    reduceProductIds,
    { processing: preview.getIn(['processing', page], EMPTY_SET), requests: [] },
  );

  if (getSize(requests)) {
    yield sideEffect((dispatch) => {
      const { signal } = aiGenerateController.start();

      function forEachRequest({ productId, ...request }) {
        function onError(error) {
          if (signal.aborted) return;

          dispatch(
            Actions.BulkEdit.aiGenerateFailed({
              attribute,
              error,
              page,
              productId,
              request,
              signal,
            })
          );
        }

        function onResponse(response) {
          dispatch(
            Actions.BulkEdit.aiGenerateSucceeded({
              attribute,
              page,
              productId,
              request,
              response,
            })
          );
        }

        switch (attribute) {
          case PHOTOS: {
            api.ai.generateAltText({ ...request, signal }).then(onResponse, onError);
            break;
          }

          default: {
            api.ai.generateText({ ...request, signal }).then(onResponse, onError);
            break;
          }
        }
      }

      requests.forEach(forEachRequest);
    });
  }

  if (getSize(processing)) {
    if (!preview.has('operation') || preview.get('operation') !== operation) {
      bulkEdit = bulkEdit.setIn(['preview', attribute, 'operation'], operation);
    }

    bulkEdit = bulkEdit.setIn(['preview', attribute, 'processing', page], processing);

    if (options.operation) {
      bulkEdit = bulkEdit.setIn(['preview', attribute, 'operations', page], options.operation);
    }
  } else {
    bulkEdit = bulkEdit.deleteIn(['preview', attribute, 'processing', page]);

    if (!getSize(bulkEdit.getIn(['preview', attribute, 'processing']))) {
      bulkEdit = bulkEdit.deleteIn(['preview', attribute, 'processing']);

      if (
        !getSize(bulkEdit.getIn(['preview', attribute, 'processed'])) &&
        !getSize(bulkEdit.getIn(['preview', attribute, 'values']))
      ) {
        switch (attribute) {
          case TAGS: {
            if (bulkEdit.hasIn(['preview', attribute, 'profile'])) {
              bulkEdit = bulkEdit.set('preview',
                Map({
                  [attribute]: Map({
                    profile: bulkEdit.getIn(['preview', attribute, 'profile']),
                  }),
                })
              );
            } else {
              bulkEdit = bulkEdit.set('preview', EMPTY_MAP);
            }
            break;
          }

          default: {
            bulkEdit = bulkEdit.set('preview', EMPTY_MAP);
            break;
          }
        }
      }
    }
  }

  return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
}

function* aiGenerateCancel(reduction) {
  abort(aiGenerateController);

  const bulkEdit = reduction.get('bulkEdit').set('preview', EMPTY_MAP);

  return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
}

function* aiGenerateFailed(reduction, { attribute, error, page, productId, request, signal }) {
  if (signal.aborted) return reduction;

  if (!reduction.hasIn(['bulkEdit', 'preview', attribute, 'processing', page, productId])) {
    abort(aiGenerateController);
    return reduction;
  }

  if (error) {
    console.error(error);
  }

  let bulkEdit = reduction.get('bulkEdit');

  function updatePreview(source = EMPTY_MAP) {
    let preview = source;

    switch (attribute) {
      case PHOTOS: {
        preview = preview.deleteIn(['processing', page, productId, request.index]);

        if (!getSize(preview.getIn(['processing', page, productId]))) {
          preview = preview.deleteIn(['processing', page, productId]);
        }

        break;
      }

      default: {
        preview = preview.deleteIn(['processing', page, productId]);
        break;
      }
    }

    if (!getSize(preview.getIn(['processing', page]))) {
      preview = preview.deleteIn(['processing', page]);
    }

    if (!getSize(preview.get('processing'))) {
      preview = preview.delete('processing');
    }

    if (!getSize(preview.get('processing')) && !getSize(preview.get('processed')) && !getSize(preview.get('values'))) {
      return undefined;
    }

    return preview;
  }

  bulkEdit = bulkEdit.updateIn(['preview', attribute], updatePreview);

  if (!getSize(bulkEdit.getIn(['preview', attribute]))) {
    yield sideEffect((dispatch) => {
      dispatch(
        Actions.Notifications.add({
          message: MESSAGE.FAIL.GENERATE,
          type: NOTIFICATION.ERROR,
        })
      );
    });

    bulkEdit = bulkEdit.set('preview', EMPTY_MAP);
  }

  return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
}

function* aiGenerateSucceeded(reduction, { attribute, page, productId, request, response }) {
  if (!reduction.hasIn(['bulkEdit', 'preview', attribute, 'processing', page, productId])) {
    abort(aiGenerateController);
    return reduction;
  }

  let bulkEdit = reduction.get('bulkEdit');
  const channel = bulkEdit.get('channel');

  function updatePreview(source = EMPTY_MAP) {
    function updateProcessing(processing) {
      switch (attribute) {
        case PHOTOS: {
          const updated = processing.deleteIn([productId, request.index]);
          return getSize(updated.get(productId))
            ? updated
            : updated.delete(productId);
        }

        default: {
          return processing.delete(productId);
        }
      }
    }

    function updateProcessed(processed) {
      switch (attribute) {
        case PHOTOS: {
          return (processed || EMPTY_MAP).update(productId, function updateProcessedIndexes(indexes) {
            return (indexes || EMPTY_SET).add(request.index);
          });
        }

        default: {
          return (processed || EMPTY_SET).add(productId);
        }
      }
    }

    function updateValue(value) {
      switch (attribute) {
        case PHOTOS: {
          return (value || EMPTY_MAP).set(request.index, response.altText);
        }

        case TAGS: {
          const parsedTags = tagsFromArray(response.text).filter(function filterErroneous(tag) {
            return !getTagError({ channel, tag });
          });

          switch (request.operation) {
            case OPERATION.ADD_MAXIMUM: {
              const tags = (
                source.getIn(['profile', TAGS]) ||
                bulkEdit.getIn(['products', 'byId', productId, TAGS, VALUE]) ||
                EMPTY_ORDERED_MAP
              );

              return parsedTags
                .filter(filterNewTags(tags))
                .slice(0, MAX_NUMBER_OF_TAGS[channel] - getSize(tags));
            }

            default: {
              return parsedTags.slice(0, MAX_NUMBER_OF_TAGS[channel]);
            }
          }
        }

        default: {
          return response.text;
        }
      }
    }

    let preview = source
      .updateIn(['processed', page], updateProcessed)
      .updateIn(['processing', page], updateProcessing)
      .updateIn(['values', productId], updateValue);

    if (!getSize(preview.getIn(['processing', page]))) {
      preview = preview.deleteIn(['processing', page]);
    }

    if (!getSize(preview.get('processing'))) {
      preview = preview.delete('processing');
    }

    return preview;
  }

  bulkEdit = bulkEdit.updateIn(['preview', attribute], updatePreview);

  return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
}

function* applyPreview(reduction) {
  if (
    !getSize(reduction.get('bulkEdit')) || (
      getSize(reduction.getIn(['bulkEdit', 'preview']))
        ? reduction.getIn(['bulkEdit', 'applyDisabled'])
        : !getSize(reduction.getIn(['bulkEdit', 'operations']))
    )
  ) {
    return reduction;
  }

  let previewOperations = EMPTY_LIST;
  let bulkEdit = reduction.get('bulkEdit');
  let operations = bulkEdit.get('operations', EMPTY_LIST);
  const shopId = bulkEdit.get('shopId');
  const channel = bulkEdit.get('channel');
  const shopData = reduction.getIn(['data', 'shopsData', shopId]);
  const {
    actions: previewActions,
    edited = bulkEdit.getIn(['products', 'selected']),
    payload,
    preview = EMPTY_MAP,
  } = applyPreviewOperations(bulkEdit);

  const selectedMenuItem = bulkEdit.getIn(['menu', 'selected', 'item']);

  function makeOperation(operation) {
    if (
      (
        !operation.has('value') &&
        !operation.has('values') &&
        selectedMenuItem &&
        !operation.has(selectedMenuItem)
      ) ||
      !operation.has('type')
    ) {
      return undefined;
    }

    // DEV-676 Amplitude events trimming
    // const [menuItem, type] = operation.get('type').split('.');

    // amplitude.logEvent({
    //   type: `Edited ${menuItem} in bulk`,
    //   options: { type, listings: getSize(edited) },
    // });

    return operation.set('products', edited.toList());
  }

  if (getSize(previewActions)) {
    yield sideEffect((dispatch) => {
      previewActions.forEach(dispatch);
    });
  }

  if (Array.isArray(payload)) {
    previewOperations = List(payload.map(makeOperation).filter(isTruthy));
  } else if (Map.isMap(payload)) {
    previewOperations = previewOperations.push(makeOperation(payload)).filter(isTruthy);
  } else if (List.isList(payload)) {
    previewOperations = payload;
  }

  operations = operations.concat(previewOperations);

  if (!getSize(operations)) {
    return reduction;
  }

  function updateEdited(oldEdited = EMPTY_SET) {
    return oldEdited.union(edited);
  }

  yield sideEffect((dispatch) => {
    dispatch(Actions.BulkEdit.addPendingUpdates());
  });

  const selected = bulkEdit.getIn(['menu', 'selected', 'item']);

  const { actions: operationActions, confirmation, products } = applyOperationsToProducts({
    bulkPhotoEditor: bulkEdit.get('bulkPhotoEditor'),
    operations: previewOperations,
    products: bulkEdit.get('products').update('edited', updateEdited),
    shopData,
    selected,
  });

  if (confirmation && !bulkEdit.has('modal')) {
    yield sideEffect((dispatch) => {
      dispatch(Actions.BulkEdit.setModal({ type: confirmation }));
    });

    return reduction;
  }

  bulkEdit = bulkEdit
    .set('operations', operations)
    .set('preview', preview)
    .set('products', products);

  if (bulkEdit.get('vela')) {
    yield sideEffect((dispatch) => {
      dispatch(Actions.BulkEdit.saveAs(SAVE_TYPE.SAVE));
    });
  } else {
    if (getSize(operationActions)) {
      yield sideEffect((dispatch) => {
        operationActions.forEach(dispatch);
      });
    }

    bulkEdit = bulkEdit.setIn(['errors', 'counts'],
      setErrorCounts({ channel, counts: EMPTY_MAP, products: bulkEdit.get('products') })
    );
  }

  return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
}

function* bootstrap(reduction, fallback) {
  abortAll();
  let bulkEdit = reduction.get('bulkEdit');
  const channel = bulkEdit.get('channel');
  const shopId = bulkEdit.get('shopId');
  const db = bulkEdit.get('db');
  const products = bulkEdit.getIn(['products', 'all']);

  if (!shopId) {
    yield sideEffect(() => {
      navigateTo(fallback);
    });

    return reduction;
  }

  bulkEdit = bulkEdit
    .set('applyDisabled', true)
    .set('errors', Map({ products: EMPTY_SET, counts: EMPTY_MAP }))
    .set('filters', Map({ ids: products, limit: PAGE_SIZE, offset: 0 }));

  let menu = Map({
    expanded: Map({ [MENU_SECTION.MEDIA]: true }),
    items: MENU_ITEMS[channel].delete(MENU_ITEM.TAXONOMY_ATTRIBUTES),
    sections: Map({
      groups: MENU_SECTIONS[channel],
      items: MENU_SECTION_ITEMS[channel],
    }),
    selected: Map({ group: MENU_SECTION.MEDIA, item: MENU_ITEM.PHOTOS }),
  });

  if (isFeatureEnabled({ feature: FEATURES.BULK_PHOTO_EDITOR, userId: reduction.getIn(['user', 'userId']) })) {
    menu = menu.updateIn(['items', MENU_ITEM.PHOTOS, 'operations'], function update(operations) {
      return operations.push(OPERATIONS[channel].PHOTOS.EDIT);
    });
  }

  if (
    channel === SHOPIFY &&
    isFeatureEnabled({ feature: FEATURES.SEO_URL_HANDLE, userId: reduction.getIn(['user', 'userId']) })
  ) {
    menu = menu.updateIn(['sections', 'items', MENU_SECTION.SEO], function update(items) {
      return items.push(MENU_ITEM.URL_HANDLE);
    });
  }

  if (getSize(menu.getIn(['items', menu.getIn(['selected', 'item']), 'operations']))) {
    menu = menu.set('operation', menu.getIn(['items', menu.getIn(['selected', 'item']), 'operations', 0]));
  }

  switch (channel) {
    case ETSY: {
      yield sideEffect((dispatch) => {
        const { signal } = categoriesController.start();
        const ids = products.toArray();

        Promise.all([
          api.listings.getListingIds({ channel, db, payload: { ids, [FILTER[ETSY].IS_DIGITAL]: true }, shopId, signal }),
          api.listings.getTaxonomyIds({ channel, db, payload: { ids }, shopId, signal }),
        ])
          .then(
            (responses) => dispatch(Actions.BulkEdit.bootstrapSucceeded({ menu, responses })),
            (error) => dispatch(Actions.BulkEdit.bootstrapFailed({ error, fallback, signal })),
          );
      });

      break;
    }

    default: {
      bulkEdit = bulkEdit
        .set('menu', menu.set('type', PHYSICAL))
        .setIn(['products', PHYSICAL], products);

      break;
    }
  }

  yield sideEffect((dispatch) => {
    dispatch(Actions.Data.getShopData({ shopId }));
    dispatch(Actions.Data.getProfiles({ shopId, type: PROFILE.TAGS }));
    dispatch(Actions.Data.getProfiles({ shopId, type: PROFILE.VARIATIONS }));
    dispatch(Actions.BulkEdit.getErrorCounts());
    dispatch(Actions.BulkEdit.getProducts());

    if (bulkEdit.get('vela')) {
      dispatch(Actions.BulkEdit.getInvalidProducts({ menu, products }));
    }
  });

  return reduction.set('bulkEdit', bulkEdit);
}

function* bootstrapFailed(reduction, { error, fallback, signal }) {
  if (signal.aborted || !getSize(reduction.get('bulkEdit'))) return reduction;

  abort(categoriesController);

  yield sideEffect(() => {
    navigateTo(fallback);
    console.error(error);
  });

  return reduction;
}

function* bootstrapSucceeded(reduction, { menu: source, responses }) {
  if (!getSize(reduction.get('bulkEdit'))) return reduction;

  abort(categoriesController);

  let menu = source;
  let bulkEdit = reduction.get('bulkEdit');
  const channel = bulkEdit.get('channel');

  function updateListings(items) {
    return items.push(MENU_ITEM.FILES);
  }

  function updateInventory(items) {
    function filterItems(item) {
      switch (item) {
        case MENU_ITEM.VARIATIONS: {
          return false;
        }

        default: {
          return true;
        }
      }
    }

    return items.filter(filterItems);
  }

  function updateSectionIds(sections) {
    function filterSections(section) {
      switch (section) {
        case MENU_SECTION.SHIPPING: {
          return false;
        }

        default: {
          return true;
        }
      }
    }

    return sections.filter(filterSections);
  }

  function reduceIds(result, productId) {
    if (!result.digital.has(productId)) {
      result.physical = result.physical.push(productId);
    }

    return result;
  }

  if (getSize(responses[0]) !== getSize(reduction.getIn(['bulkEdit', 'products', 'all']))) {
    if (getSize(responses[0])) {
      const digital = responses[0].map(shapeId);
      menu = menu.updateIn(['sections', 'items', MENU_SECTION.LISTINGS], updateListings);

      bulkEdit = bulkEdit
        .setIn(['products', DIGITAL], List(digital))
        .setIn(['products', PHYSICAL],
          bulkEdit
            .getIn(['products', 'all'])
            .reduce(reduceIds, { digital: Set(digital), physical: EMPTY_LIST }).physical
        );
    } else {
      menu = menu.set('type', PHYSICAL);
      bulkEdit = bulkEdit.setIn(['products', PHYSICAL], bulkEdit.getIn(['products', 'all']));
    }
  } else {
    menu = menu
      .set('type', DIGITAL)
      .updateIn(['sections', 'groups'], updateSectionIds)
      .updateIn(['sections', 'items', MENU_SECTION.INVENTORY], updateInventory)
      .updateIn(['sections', 'items', MENU_SECTION.LISTINGS], updateListings);

    bulkEdit = bulkEdit.setIn(['products', DIGITAL], bulkEdit.getIn(['products', 'all']));
  }

  if (getSize(responses[1])) {
    const categories = Set(responses[1]);
    menu = updateMenuTaxonomyAttrubutes({ categories, channel, menu });
  } else {
    menu = menu.deleteIn(['sections', 'items', MENU_SECTION.TAXONOMY_ATTRIBUTES]);
  }

  return reduction.set('bulkEdit', bulkEdit.set('menu', menu));
}

function* closeBulkEdit(reduction, { route, repercussion = false } = {}) {
  if (repercussion) {
    return reduction.set('bulkEdit', EMPTY_MAP);
  }

  if (
    getSize(reduction.getIn(['bulkEdit', 'operations'])) || (
      reduction.getIn(['bulkEdit', 'vela']) &&
      getSize(reduction.getIn(['bulkEdit', 'preview']))
    )
  ) {
    yield sideEffect((dispatch) => {
      dispatch(
        Actions.BulkEdit.setModal({
          type: MODALS.CONFIRMATIONS.CANCEL,
          route: route || getListingsPath(reduction),
        })
      );
    });

    return reduction;
  }

  yield sideEffect(() => {
    abortAll();
    navigateTo(route || getListingsPath(reduction));
  });

  return reduction.set('bulkEdit', EMPTY_MAP);
}

function* getCategories(reduction) {
  yield sideEffect((dispatch) => {
    const { signal } = categoriesController.start();
    const bulkEdit = reduction.get('bulkEdit');
    const channel = bulkEdit.get('channel');
    const db = bulkEdit.get('db');
    const shopId = bulkEdit.get('shopId');
    const ids = bulkEdit.getIn(['products', 'all']).toArray();

    api.listings
      .getTaxonomyIds({ channel, db, payload: { ids }, shopId, signal })
      .then(
        (response) => dispatch(Actions.BulkEdit.getCategoriesSucceeded(response)),
        (error) => dispatch(Actions.BulkEdit.getCategoriesFailed({ error, signal })),
      );
  });

  return reduction;
}

function* getCategoriesFailed(reduction, { error, signal }) {
  if (signal.aborted || !getSize(reduction.get('bulkEdit'))) return reduction;

  abort(categoriesController);

  yield sideEffect((dispatch) => {
    console.error(error);

    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_DATA,
      })
    );
  });

  return reduction;
}

function* getCategoriesSucceeded(reduction, response) {
  if (!getSize(reduction.get('bulkEdit'))) return reduction;

  abort(categoriesController);

  const bulkEdit = reduction.get('bulkEdit');
  const channel = bulkEdit.get('channel');
  let menu = clearMenuTaxonomyAttrubutes(bulkEdit.get('menu'));

  if (getSize(response)) {
    const categories = Set(response);
    menu = updateMenuTaxonomyAttrubutes({ categories, channel, menu });

    if (menu.getIn(['selected', 'group']) === MENU_SECTION.TAXONOMY_ATTRIBUTES) {
      const selectedMenuItem = menu.getIn(['selected', 'item']);

      if (
        !menu.hasIn(['items', selectedMenuItem]) ||
        !menu.getIn(['items', selectedMenuItem, 'filters'], EMPTY_MAP).equals(
          bulkEdit.getIn(['menu', 'items', selectedMenuItem, 'filters'], EMPTY_MAP)
        )
      ) {
        yield sideEffect((dispatch) => {
          const item = menu.getIn(['sections', 'items', MENU_SECTION.TAXONOMY_ATTRIBUTES, 0]);
          dispatch(Actions.BulkEdit.selectMenuItem({ group: MENU_SECTION.TAXONOMY_ATTRIBUTES, item }));
        });
      }
    }
  } else {
    menu = menu.deleteIn(['sections', 'items', MENU_SECTION.TAXONOMY_ATTRIBUTES]);

    if (menu.getIn(['selected', 'group']) === MENU_SECTION.TAXONOMY_ATTRIBUTES) {
      yield sideEffect((dispatch) => {
        dispatch(Actions.BulkEdit.selectMenuItem({ group: MENU_SECTION.MEDIA, item: MENU_ITEM.PHOTOS }));
      });
    }
  }

  return reduction.setIn(['bulkEdit', 'menu'], menu);
}

function* getErrorCounts(reduction) {
  if (!reduction.getIn(['bulkEdit', 'vela'])) return reduction;

  const bulkEdit = reduction.get('bulkEdit');
  const db = bulkEdit.get('db');
  const shopId = bulkEdit.get('shopId');
  const id = bulkEdit.getIn(['products', 'all']).toArray();

  yield sideEffect((dispatch) => {
    api
      .products
      .getInvalidCounts({ db, id, shopId })
      .then(
        (data) => dispatch(Actions.BulkEdit.getErrorCountsSucceeded(data)),
        (error) => dispatch(Actions.BulkEdit.getErrorCountsFailed(error))
      );
  });

  return reduction.set('bulkEdit', bulkEdit);
}

function* getErrorCountsFailed(reduction, error) {
  if (!getSize(reduction.get('bulkEdit'))) return reduction;

  console.error(error);

  return reduction;
}

function* getErrorCountsSucceeded(reduction, data) {
  if (!getSize(reduction.get('bulkEdit'))) return reduction;

  const channel = reduction.getIn(['bulkEdit', 'channel']);

  return reduction.setIn(['bulkEdit', 'errors', 'counts'],
    shapeErrorCountsForApp({ channel, data })
  );
}

function* getFilterIds(reduction, selection = SELECTION.ALL) {
  abort(listingIdsController);

  const bulkEdit = reduction.get('bulkEdit').set('processing', true);

  yield sideEffect((dispatch) => {
    const { signal } = listingIdsController.start();
    const channel = bulkEdit.get('channel');
    const db = bulkEdit.get('db');
    const shopId = bulkEdit.get('shopId');
    const payload = shapeFiltersForAPI({
      channel,
      errors: bulkEdit.get('errors'),
      filters: bulkEdit.get('filters'),
      menu: bulkEdit.get('menu'),
      products: bulkEdit.get('products'),
      selection: bulkEdit.get('selection'),
    });

    api.listings
      .getListingIds({ channel, db, payload, shopId, signal })
      .then(
        (response) => {
          if (Array.isArray(response)) {
            dispatch(Actions.BulkEdit.getFilterIdsSucceeded({ response, selection }));
          } else {
            dispatch(Actions.BulkEdit.getFilterIdsFailed({ error: `Response is not an array: ${response}`, signal }));
          }
        },
        (error) => dispatch(Actions.BulkEdit.getFilterIdsFailed({ error, signal })),
      );
  });

  return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
}

function* getFilterIdsFailed(reduction, { error, signal }) {
  if (signal.aborted || !getSize(reduction.get('bulkEdit'))) return reduction;

  abort(listingIdsController);

  yield sideEffect((dispatch) => {
    console.error(error);

    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_LISTINGS,
      })
    );
  });

  return reduction.deleteIn(['bulkEdit', 'processing']);
}

function* getFilterIdsSucceeded(reduction, { response, selection }) {
  if (!getSize(reduction.get('bulkEdit'))) return reduction;

  abort(listingIdsController);

  const products = response.map(shapeId);

  yield sideEffect((dispatch) => {
    if (getSize(products)) {
      dispatch(Actions.BulkEdit.getProducts({ selection }));
    } else {
      dispatch(
        Actions.BulkEdit.getProductsSucceeded({
          data: { count: 0 },
          selection: SELECTION.NONE,
        })
      );
    }
  });

  return reduction.setIn(['bulkEdit', 'filters', 'ids'], List(products));
}

function* getInvalidProducts(reduction, { menu, products } = {}) {
  if (!reduction.getIn(['bulkEdit', 'vela'])) return reduction;

  abort(invalidController);

  const bulkEdit = reduction.get('bulkEdit');
  const db = bulkEdit.get('db');
  const channel = bulkEdit.get('channel');
  const shopId = bulkEdit.get('shopId');
  const id = (products || bulkEdit.getIn(['products', 'all'])).toArray();
  const menuItem = (menu || bulkEdit.get('menu')).getIn(['selected', 'item']);
  const attribute = MENU_ITEMS_TO_ATTRIBUTE_MAP[channel][menuItem];

  yield sideEffect((dispatch) => {
    const { signal } = invalidController.start();

    api.products
      .getInvalid({ attribute, db, id, shopId, signal })
      .then(
        (response) => dispatch(Actions.BulkEdit.getInvalidProductsSucceeded({ response })),
        (error) => dispatch(Actions.BulkEdit.getInvalidProductsFailed({ error, signal }))
      );
  });

  return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
}

function* getInvalidProductsFailed(reduction, { error, signal }) {
  if (signal.aborted || !getSize(reduction.get('bulkEdit'))) return reduction;

  abort(invalidController);
  console.error(error);

  return reduction;
}

function* getInvalidProductsSucceeded(reduction, { response }) {
  if (!getSize(reduction.get('bulkEdit'))) return reduction;

  abort(invalidController);

  let bulkEdit = reduction.get('bulkEdit');

  function shapeProducts() {
    return (response.products_ids || []).map(shapeId);
  }

  if (bulkEdit.get('vela') && bulkEdit.get('selection') === SELECTION.INCOMPLETE) {
    if (!getSize(response.products_ids)) {
      bulkEdit = bulkEdit.setIn(['errors', 'products'], EMPTY_LIST);

      yield sideEffect((dispatch) => {
        dispatch(Actions.BulkEdit.setSelection(SELECTION.ALL));
      });
    } else {
      const ids = shapeProducts();

      bulkEdit = bulkEdit
        .setIn(['errors', 'products'], List(ids))
        .setIn(['filters', 'ids'], List(ids));

      yield sideEffect((dispatch) => {
        dispatch(Actions.BulkEdit.getProducts({ selection: SELECTION.INCOMPLETE }));
      });
    }
  } else {
    bulkEdit = bulkEdit.setIn(['errors', 'products'], List(shapeProducts()));
  }

  return reduction.set('bulkEdit', bulkEdit);
}

function* getPhotosByIndex(reduction, index) {
  const bulkEdit = reduction.get('bulkEdit');
  const shopId = bulkEdit.get('shopId');
  const db = bulkEdit.get('db');
  const productIds = bulkEdit.getIn(['products', 'selected']).keySeq().toArray();

  yield sideEffect((dispatch) => {
    api
      .images
      .getByIndex({ db, index, productIds, shopId })
      .then(
        ({ images }) => dispatch(Actions.BulkEdit.getPhotosByIndexSucceeded({ images, index })),
        (error) => dispatch(Actions.BulkEdit.getPhotosByIndexFailed({ error, index }))
      );
  });

  return reduction.setIn(['bulkEdit', 'preview', PHOTOS, 'processing'], index);
}

function* getPhotosByIndexFailed(reduction, { error, index }) {
  if (
    !getSize(reduction.get('bulkEdit')) ||
    reduction.getIn(['bulkEdit', 'preview', PHOTOS, 'processing']) !== index
  ) {
    return reduction;
  }

  console.error(error);

  yield sideEffect((dispatch) => {
    dispatch(
      Actions.Notifications.add({ type: NOTIFICATION.ERROR, message: MESSAGE.FAIL.LOAD_DATA })
    );
  });

  return getSize(reduction.getIn(['bulkEdit', 'preview', PHOTOS, PHOTOS]))
    ? reduction.deleteIn(['bulkEdit', 'preview', PHOTOS, 'processing'])
    : reduction.setIn(['bulkEdit', 'preview'], EMPTY_MAP);
}

function* getPhotosByIndexSucceeded(reduction, { images, index }) {
  if (
    !getSize(reduction.get('bulkEdit')) ||
    reduction.getIn(['bulkEdit', 'preview', PHOTOS, 'processing']) !== index
  ) {
    return reduction;
  }

  if (getSize(images)) {
    yield sideEffect((dispatch) => {
      function reduceImages(result, image) {
        const { id, product_id: productId } = image;
        result.imageIds.push(id);
        result.productIds.push(String(productId));
        return result;
      }

      const byId = reduction
        .getIn(['bulkEdit', 'bulkPhotoEditor', PHOTOS], EMPTY_MAP)
        .reduce(originalToProcessed, EMPTY_MAP);

      const { imageIds, productIds } = images.reduce(reduceImages, { imageIds: [], productIds: [] });

      dispatch(Actions.BulkPhotoEditor.startEditing({ byId, imageIds, index, productIds }));
    });
  } else {
    yield sideEffect((dispatch) => {
      dispatch(
        Actions.Notifications.add({ type: NOTIFICATION.ERROR, message: MESSAGE.FAIL.NO_PHOTOS })
      );
    });
  }

  return getSize(reduction.getIn(['bulkEdit', 'preview', PHOTOS, PHOTOS]))
    ? reduction.deleteIn(['bulkEdit', 'preview', PHOTOS, 'processing'])
    : reduction.setIn(['bulkEdit', 'preview'], EMPTY_MAP);
}

function* getProducts(reduction, { selection } = {}) {
  abort(listingsController);
  const bulkEdit = reduction.get('bulkEdit').set('processing', true);
  const channel = bulkEdit.get('channel');
  const db = bulkEdit.get('db');
  const shopId = bulkEdit.get('shopId');
  const filters = bulkEdit.get('filters');
  const payload = {
    id: filters.get('ids').toArray(),
    limit: filters.get('limit'),
    offset: filters.get('offset'),
  };

  yield sideEffect((dispatch) => {
    const { signal } = listingsController.start();
    api.products
      .getById({ channel, db, payload, shopId, signal })
      .then(
        (data) => dispatch(Actions.BulkEdit.getProductsSucceeded({ data, selection, signal })),
        (error) => dispatch(Actions.BulkEdit.getProductsFailed({ error, signal }))
      );
  });

  return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
}

function* getProductsFailed(reduction, { error, signal }) {
  if (signal.aborted || !getSize(reduction.get('bulkEdit'))) return reduction;

  abort(listingsController);

  yield sideEffect((dispatch) => {
    console.error('Error encountered while getting products: ', error);

    if (!reduction.hasIn(['bulkEdit', 'filters'])) {
      dispatch(Actions.BulkEdit.closeBulkEdit());
    }

    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_DATA,
      })
    );
  });

  const bulkEdit = reduction.get('bulkEdit').delete('processing');

  return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
}

function* getProductsSucceeded(reduction, { data, selection, signal }) {
  if (!getSize(reduction.get('bulkEdit'))) return reduction;

  let bulkEdit = reduction.get('bulkEdit');
  const filters = bulkEdit.get('filters');
  const channel = bulkEdit.get('channel');
  const shopId = bulkEdit.get('shopId');

  if (!data?.count) {
    bulkEdit = bulkEdit
      .delete('processing')
      .set('page', getPageInfo(filters, 0))
      .setIn(['products', 'byId'], EMPTY_MAP)
      .setIn(['products', 'ids'], EMPTY_LIST)
      .setIn(['products', 'total'], 0);

    if (selection) {
      bulkEdit = bulkEdit.setIn(['products', 'selected'], EMPTY_SET);
    }

    return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
  } else {
    bulkEdit = bulkEdit.setIn(['products', 'total'], data.count);
  }

  function reduceProductIds(result, productId) {
    function updateById(byId = EMPTY_MAP) {
      return byId.set(String(productId),
        shapeProductForApp({ channel, data, productId, shopId })
      );
    }

    function updateIds(ids = EMPTY_LIST) {
      return ids.push(String(productId));
    }

    return result
      .update('byId', updateById)
      .update('ids', updateIds);
  }

  const operations = bulkEdit.get('operations');
  const bulkPhotoEditor = bulkEdit.get('bulkPhotoEditor');
  const shopData = reduction.getIn(['data', 'shopsData', shopId]);
  let products;

  try {
    products = data.products.reduce(reduceProductIds, bulkEdit.get('products').delete('byId').delete('ids'));
  } catch (error) {
    yield sideEffect((dispatch) => {
      dispatch(Actions.BulkEdit.getProductsFailed({ error, signal }));
    });

    return reduction;
  }

  abort(listingsController);

  if (selection) {
    switch (selection) {
      case SELECTION.ALL: {
        products = products.set('selected', filters.get('ids').toSet());
        break;
      }

      case SELECTION.INCOMPLETE: {
        products = products.set('selected', bulkEdit.getIn(['errors', 'products']).toSet());
        break;
      }

      case SELECTION.NONE: {
        products = products.set('selected', EMPTY_SET);
        break;
      }

      case SELECTION.PAGE: {
        products = products.set('selected', products.get('ids').toSet());
        break;
      }

      default: {
        break;
      }
    }
  }

  const updates = applyOperationsToProducts({
    bulkPhotoEditor,
    operations,
    products,
    shopData,
    selected: bulkEdit.getIn(['menu', 'selected', 'item']),
  });

  bulkEdit = bulkEdit
    .delete('processing')
    .set('page', getPageInfo(filters, data.count))
    .set('products', updates.products);

  if (!bulkEdit.get('vela')) {
    bulkEdit = bulkEdit.setIn(['errors', 'counts'],
      setErrorCounts({ channel, counts: EMPTY_MAP, products: updates.products })
    );
  }

  yield sideEffect((dispatch) => {
    const preview = bulkEdit.get('preview');
    const previewOperations = getPreviewOperations({ channel, preview });
    const { actions } = getSize(previewOperations)
      ? applyOperationsToProducts({
        actions: updates.actions,
        bulkPhotoEditor,
        operations: previewOperations,
        products: updates.products,
        shopData,
      })
      : updates;

    if (getSize(actions)) {
      actions.forEach(dispatch);
    }
  });

  return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
}

function* getProfileById(reduction, { profileId, type }) {
  const shopId = reduction.getIn(['bulkEdit', 'shopId']);
  const db = reduction.getIn(['bulkEdit', 'db']);

  yield sideEffect((dispatch) => {
    api.profiles
      .getProfile({ db, profileId, shopId, type })
      .then(
        (data) => dispatch(Actions.BulkEdit.getProfileByIdSucceeded({ type, data })),
        error => dispatch(Actions.BulkEdit.getProfileByIdFailed(error))
      );
  });

  return reduction;
}

function* getProfileByIdFailed(reduction, error) {
  if (!getSize(reduction.get('bulkEdit'))) return reduction;

  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_PROFILE,
      })
    );
  });

  return reduction;
}

function* getProfileByIdSucceeded(reduction, { type, data }) {
  const bulkEdit = reduction.get('bulkEdit');
  return getSize(bulkEdit)
    ? reduction.set('bulkEdit', applyProfile({ bulkEdit, data, type }))
    : reduction;
}

function* hideCountdown(reduction, timestamp) {
  return reduction.setIn(['bulkEdit', 'hiddenCountdown'], timestamp);
}

function* removeBackground(reduction, payload) {
  function reduceActions(result, action) {
    const { originalId, url } = action;
    let processedId = result.photos.getIn([originalId, 'processedId']);

    if (!processedId) {
      processedId = SHA1(originalId).toString();
      result.photos = result.photos.set(originalId,
        Map({
          id: processedId,
          loaded: false,
          originalId,
          processedId,
        })
      );
    }

    result.toRemoveBG = result.toRemoveBG.push(
      Map({
        originalId,
        processedId,
        url: makeThumbnailUrl(url, THUMBNAIL_SIZE),
      })
    );

    return result;
  }

  const { photos, toRemoveBG } = payload.reduce(
    reduceActions,
    {
      photos: reduction.getIn(['bulkEdit', 'bulkPhotoEditor', PHOTOS]),
      toRemoveBG: EMPTY_LIST,
    }
  );

  yield sideEffect(async (dispatch) => {
    function onFail(params) {
      dispatch(Actions.BulkEdit.removeBackgroundFailed(params));
    }

    function onSuccess(params) {
      dispatch(Actions.BulkEdit.removeBackgroundSucceeded(params));
    }

    removeBG({ images: toRemoveBG, onFail, onSuccess });
  });

  return reduction.setIn(['bulkEdit', 'bulkPhotoEditor', PHOTOS], photos);
}

function* removeBackgroundFailed(reduction, { error, image, originalUrl }) {
  if (
    !getSize(reduction.get('bulkEdit')) ||
    !reduction.hasIn(['bulkEdit', 'bulkPhotoEditor', PHOTOS, image.get('originalId')])
  ) {
    return reduction;
  }

  yield sideEffect((dispatch) => {
    if (typeof error === 'string' && /Failed to fetch/.test(error)) {
      console.error(error);
    } else {
      console.error(`Failed to remove background for URL: `, originalUrl, '\n', error);
    }

    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.PROCESS_IMAGES,
      })
    );
  });

  return reduction;
}

function* removeBackgroundSucceeded(reduction, { image, processedUrl }) {
  if (
    !getSize(reduction.get('bulkEdit')) ||
    !reduction.hasIn(['bulkEdit', 'bulkPhotoEditor', PHOTOS, image.get('originalId')])
  ) {
    return reduction;
  }

  function updateImage(value) {
    return value.set('loaded', true).set('fullsize_url', processedUrl);
  }

  return reduction.updateIn(['bulkEdit', 'bulkPhotoEditor', PHOTOS, image.get('originalId')], updateImage);
}

function* removePreview(reduction, attributes) {
  if (!getSize(reduction.getIn(['bulkEdit', 'preview']))) return reduction;

  let bulkEdit = reduction.get('bulkEdit');
  const preview = bulkEdit.get('preview', EMPTY_MAP);

  function reduceAttributes(result, key) {
    if (Array.isArray(key)) {
      return result.deleteIn(key);
    } else if (typeof key === 'string') {
      return result.delete(key);
    } else {
      return result;
    }
  }

  switch (typeof attributes) {
    case 'string': {
      bulkEdit = bulkEdit.deleteIn(['preview', attributes]);
      break;
    }

    case 'object': {
      if (Array.isArray(attributes) || List.isList(attributes)) {
        bulkEdit = bulkEdit.set('preview', attributes.reduce(reduceAttributes, preview));
      } else {
        bulkEdit = bulkEdit.set('preview', EMPTY_MAP);
      }

      break;
    }

    default: {
      bulkEdit = bulkEdit.set('preview', EMPTY_MAP);
      break;
    }
  }

  return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
}

function* saveAs(reduction, saveType) {
  switch (saveType) {
    case SAVE_TYPE.SAVE: {
      const bulkEdit = reduction
        .get('bulkEdit')
        .delete('modal')
        .set('saving', true);

      const shopId = bulkEdit.get('shopId');
      const channel = bulkEdit.get('channel');
      const route = bulkEdit.getIn(['modal', 'route']);
      const products = bulkEdit.getIn(['products', 'edited']).toList();

      yield sideEffect((dispatch) => {
        if (bulkEdit.get('status') === STATUS.COPIED) {
          // this operation is required to Save copied listings withing Vela db
          // and do not trigger sync process to the channel
          const operation = Map({ products, type: OPERATIONS[channel].STATUS, value: STATUS.COPIED });
          dispatch(Actions.BulkEdit.addOperation(operation));
        }

        dispatch(Actions.BulkEdit.syncUpdates({ route }));
        dispatch(
          Actions.Shops.setData({
            path: ['syncData', shopId],
            value: Map({
              indicator: SYNC_INDICATOR.SAVE,
            }),
          })
        );

        dispatch(
          Actions.Listings.toggleState({
            listings: products,
            state: ['processing', shopId],
          })
        );

        // DEV-676 Amplitude events trimming
        // amplitude.logEvent('Clicked "Save" in bulk edit');
      });

      return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
    }

    case SAVE_TYPE.SYNC: {
      yield sideEffect((dispatch) => {
        const route = reduction.getIn(['bulkEdit', 'modal', 'route']);
        const shopId = reduction.getIn(['bulkEdit', 'shopId']);
        const channel = reduction.getIn(['bulkEdit', 'channel']);
        const total = reduction.getIn(['bulkEdit', 'products', 'total']) || 0;

        dispatch(Actions.BulkEdit.syncUpdates({ route }));
        dispatch(
          Actions.Shops.setData({
            path: ['syncData', shopId],
            value: Map({
              indicator: SYNC_INDICATOR.SYNC,
            }),
          })
        );

        amplitude.logEvent({
          type: 'Started sync',
          options: {
            channel,
            type: 'bulk edit',
            number: total,
          },
        });
      });

      return reduction.deleteIn(['bulkEdit', 'modal']);
    }

    default: {
      return reduction;
    }
  }
}

function* scheduleUpdates(reduction, { bulkEdit, startDate }) {
  if (!bulkEdit) {
    const empty = reduction.get('bulkEdit')
      .set('processing', true)
      .set('syncStarted', true)
      .delete('operations')
      .deleteIn(['menu', 'pendingUpdates']);

    const shopId = empty.get('shopId');
    const { attributes, listings } = getEditedAttributesAndListings(
      reduction.getIn(['bulkEdit', 'operations'])
    );

    mixpanel.track(
      EVENT.BULK_EDIT.SCHEDULED,
      {
        channel: CHANNEL_NAME[reduction.getIn(['bulkEdit', 'channel'])],
        included_attributes: attributes.toArray(),
        plan: getPlanName(reduction.getIn(['user', 'subscriptions', shopId])),
        shop_id: shopId,
        total_listings: listings,
      }
    );

    yield sideEffect((dispatch) => {
      dispatch(Actions.BulkEdit.scheduleUpdates({ bulkEdit: reduction.get('bulkEdit'), startDate }));
    });

    return reduction.set('bulkEdit', empty.set('applyDisabled', isApplyDisabled(empty)));
  }

  yield sideEffect((dispatch) => {
    const channel = bulkEdit.get('channel');
    const db = bulkEdit.get('db');
    const shopId = bulkEdit.get('shopId');
    const operations = bulkEdit.get('operations').toJS();

    api
      .scheduledUpdates
      .create({ channel, db, operations, shopId, startDate })
      .then(
        () => dispatch(Actions.BulkEdit.scheduleUpdatesSucceeded()),
        (error) => dispatch(Actions.BulkEdit.scheduleUpdatesFailed({ bulkEdit, error })),
      );
  });

  return reduction.deleteIn(['bulkEdit', 'syncStarted']);
}

function* scheduleUpdatesFailed(reduction, { bulkEdit, error }) {
  yield sideEffect((dispatch) => {
    console.error('Error encountered while scheduling updates: ', error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.SCHEDULE_UPDATES,
      })
    );
  });

  return getSize(reduction.get('bulkEdit'))
    ? reduction.set('bulkEdit', bulkEdit)
    : reduction;
}

function* scheduleUpdatesSucceeded(reduction) {
  yield sideEffect((dispatch) => {
    dispatch(Actions.ScheduledUpdates.loadUpdates({ getNextScheduled: true }));
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.SUCCESS,
        message: MESSAGE.SUCCESS.SCHEDULE_UPDATES,
      })
    );
  });

  let bulkEdit = reduction.get('bulkEdit');

  if (!getSize(bulkEdit)) return reduction;

  bulkEdit = bulkEdit.delete('processing');

  return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
}

function* search(reduction, query) {
  if (!getSize(reduction.get('bulkEdit'))) return reduction;

  let bulkEdit = reduction.get('bulkEdit');
  let filters = bulkEdit.get('filters');
  const channel = bulkEdit.get('channel');

  if ((!query && !filters.get(FILTER[channel].TITLE)) || query === filters.get(FILTER[channel].TITLE)) return reduction;

  abort(listingsController);
  abort(listingIdsController);

  if (query) {
    filters = filters.set(FILTER[channel].TITLE, query);
  } else {
    filters = filters.delete(FILTER[channel].TITLE);
  }

  bulkEdit = bulkEdit.set('filters', filters.delete('ids').set('offset', 0)).set('processing', true);

  yield sideEffect((dispatch) => {
    dispatch(Actions.BulkEdit.getFilterIds(SELECTION.ALL));
  });

  return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
}

function* selectOperation(reduction, operation) {
  const bulkEdit = reduction.get('bulkEdit').setIn(['menu', 'operation'], operation);
  return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
}

function* selectMenuItem(reduction, { group, item }) {
  let bulkEdit = reduction.get('bulkEdit');
  const channel = bulkEdit.get('channel');
  const menu = bulkEdit.get('menu');
  const filters = bulkEdit.get('filters');
  const products = bulkEdit.get('products');
  const updatedFilters = updateFiltersForMenuItem({ channel, filters, item, menu, products });

  if (updatedFilters) {
    abort(invalidController);
    abort(listingsController);
    abort(listingIdsController);

    yield sideEffect((dispatch) => {
      if (bulkEdit.get('vela')) {
        dispatch(Actions.BulkEdit.getInvalidProducts());

        if (bulkEdit.get('selection') !== SELECTION.INCOMPLETE) {
          dispatch(Actions.BulkEdit.getFilterIds(SELECTION.ALL));
        }
      } else if (filters.get(FILTER[channel].TITLE) || !updatedFilters.has('ids')) {
        dispatch(Actions.BulkEdit.getFilterIds(SELECTION.ALL));
      } else {
        dispatch(Actions.BulkEdit.getProducts({ selection: SELECTION.ALL }));
      }
    });

    bulkEdit = bulkEdit
      .set('processing', true)
      .set('filters', updatedFilters)
      .deleteIn(['products', 'ids'])
      .deleteIn(['products', 'byId']);
  } else if (bulkEdit.get('vela')) {
    abort(invalidController);

    yield sideEffect((dispatch) => {
      dispatch(Actions.BulkEdit.getInvalidProducts());
    });

    if (bulkEdit.get('selection') === SELECTION.INCOMPLETE) {
      abort(listingsController);
      abort(listingIdsController);

      bulkEdit = bulkEdit
        .set('processing', true)
        .deleteIn(['products', 'ids'])
        .deleteIn(['products', 'byId']);
    }
  }

  bulkEdit = bulkEdit.delete('preview').setIn(['menu', 'selected'], Map({ group, item }));

  if (getSize(bulkEdit.getIn(['menu', 'items', item, 'operations']))) {
    bulkEdit = bulkEdit.setIn(['menu', 'operation'], bulkEdit.getIn(['menu', 'items', item, 'operations', 0]));
  } else {
    bulkEdit = bulkEdit.deleteIn(['menu', 'operation']);
  }

  return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
}

function* setBanner(reduction, payload) {
  return payload
    ? reduction.setIn(['bulkEdit', 'banner'], Map(payload))
    : reduction.deleteIn(['bulkEdit', 'banner']);
}

function* setData(reduction, data) {
  function updateBulkEdit(bulkEdit = EMPTY_MAP) {
    let result = bulkEdit;

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] === undefined) {
          result = result.delete(key);
        } else {
          result = result.set(key, data[key]);
        }
      }
    }

    return result;
  }

  return reduction.update('bulkEdit', updateBulkEdit);
}

function* setModal(reduction, payload) {
  return payload
    ? reduction.setIn(['bulkEdit', 'modal'], Map(payload))
    : reduction.deleteIn(['bulkEdit', 'modal']);
}

function* setPage(reduction, page) {
  yield sideEffect((dispatch) => {
    dispatch(Actions.BulkEdit.getProducts());
  });

  return reduction
    .setIn(['bulkEdit', 'page', 'currentPage'], page)
    .setIn(['bulkEdit', 'filters', 'offset'], page * PAGE_SIZE);
}

function* setProduct(reduction, newProduct) {
  let state = reduction;
  const path = ['bulkEdit', 'products', 'byId', newProduct.get('productId')];
  const oldProduct = state.getIn(path);

  if (!oldProduct) return state;

  if (!state.getIn(['bulkEdit', 'vela'])) {
    if (!oldProduct.get('errors').equals(newProduct.get('errors'))) {
      const channel = state.getIn(['bulkEdit', 'channel']);
      state = state.updateIn(['bulkEdit', 'errors', 'counts'],
        updateErrorCounts({ channel, oldProduct, newProduct })
      );
    }
  }

  return state.setIn(path, newProduct);
}

function* setSelection(reduction, selection) {
  let bulkEdit = reduction.get('bulkEdit');
  const channel = bulkEdit.get('channel');
  const menu = bulkEdit.get('menu');
  const products = bulkEdit.get('products');

  switch (selection) {
    case SELECTION.ALL: {
      if (bulkEdit.get('selection')) {
        bulkEdit = bulkEdit.setIn(['filters', 'offset'], 0);

        if (bulkEdit.getIn(['filters', FILTER[channel].TITLE])) {
          yield sideEffect((dispatch) => {
            dispatch(Actions.BulkEdit.getFilterIds(selection));
          });
        } else {
          const ids = getFilterIdsForMenuItem({ menu, products });

          if (ids) {
            if (ids.equals(bulkEdit.getIn(['filters', 'ids']))) {
              yield sideEffect((dispatch) => {
                dispatch(Actions.BulkEdit.getProducts(selection));
              });
            } else {
              yield sideEffect((dispatch) => {
                dispatch(Actions.BulkEdit.getFilterIds(selection));
              });
              bulkEdit = bulkEdit.setIn(['filters', 'ids'], ids);
            }
          } else {
            yield sideEffect((dispatch) => {
              dispatch(Actions.BulkEdit.getFilterIds(selection));
            });
          }
        }
      } else {
        bulkEdit = bulkEdit.setIn(['products', 'selected'], bulkEdit.getIn(['filters', 'ids']).toSet());
      }

      bulkEdit = bulkEdit.delete('selection');
      break;
    }

    case SELECTION.INCOMPLETE: {
      if (bulkEdit.get('selection') === SELECTION.INCOMPLETE) return reduction;

      bulkEdit = bulkEdit
        .set('selection', SELECTION.INCOMPLETE)
        .setIn(['filters', 'offset'], 0);

      if (bulkEdit.getIn(['filters', FILTER[channel].TITLE])) {
        yield sideEffect((dispatch) => {
          dispatch(Actions.BulkEdit.getFilterIds(selection));
        });
      } else {
        const incomplete = bulkEdit.getIn(['errors', 'products']);

        bulkEdit = bulkEdit.setIn(['filters', 'ids'], incomplete);

        yield sideEffect((dispatch) => {
          dispatch(Actions.BulkEdit.getProducts({ selection }));
        });
      }

      break;
    }

    case SELECTION.NONE: {
      if (!bulkEdit.get('selection') && !getSize(bulkEdit.getIn(['products', 'selected']))) {
        return reduction;
      }

      if (bulkEdit.get('selection')) {
        bulkEdit = bulkEdit.delete('selection').setIn(['filters', 'offset'], 0);

        yield sideEffect((dispatch) => {
          dispatch(Actions.BulkEdit.getFilterIds(selection));
        });

        break;
      }

      bulkEdit = bulkEdit.setIn(['products', 'selected'], EMPTY_SET);
      break;
    }

    case SELECTION.PAGE: {
      const page = bulkEdit.getIn(['products', 'ids']).toSet();
      const selected = bulkEdit.getIn(['products', 'selected']);
      bulkEdit = bulkEdit.setIn(['products', 'selected'],
        products.get('total') > PAGE_SIZE && getSize(selected) === products.get('total')
          ? page
          : getSize(page.intersect(selected)) === getSize(page)
            ? selected.subtract(page)
            : selected.union(page)
      );
      break;
    }

    default: {
      return reduction;
    }
  }

  return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
}

function* syncUpdates(reduction, { bulkEdit, route }) {
  if (!bulkEdit) {
    let empty = reduction
      .get('bulkEdit')
      .set('syncStarted', true)
      .delete('operations')
      .delete('waitForSync')
      .deleteIn(['menu', 'pendingUpdates']);

    const shopId = reduction.getIn(['bulkEdit', 'shopId']);
    const { attributes, listings } = getEditedAttributesAndListings(
      reduction.getIn(['bulkEdit', 'operations'])
    );

    mixpanel.track(
      EVENT.BULK_EDIT.SYNC,
      {
        channel: CHANNEL_NAME[reduction.getIn(['bulkEdit', 'channel'])],
        included_attributes: attributes.toArray(),
        plan: getPlanName(reduction.getIn(['user', 'subscriptions', shopId])),
        shop_id: shopId,
        total_listings: listings,
      }
    );

    if (!empty.get('saving')) {
      empty = empty
        .deleteIn(['products', 'byId'])
        .deleteIn(['products', 'edited'])
        .deleteIn(['products', 'ids'])
        .deleteIn(['products', 'total']);
    }

    yield sideEffect((dispatch) => {
      dispatch(Actions.BulkEdit.syncUpdates({ bulkEdit: reduction.get('bulkEdit'), route }));
    });

    return reduction.set('bulkEdit', empty.set('applyDisabled', isApplyDisabled(empty)));
  }

  const operations = bulkEdit.get('operations').toJS();
  const shopId = bulkEdit.get('shopId');
  const db = bulkEdit.get('db');
  const interval = reduction.getIn(['user', 'config', 'shopsPollingIntervalShort']);
  const waitForSync = bulkEdit.get('waitForSync', WAIT_FOR_SYNC.START_OR_FINISH);

  yield sideEffect((dispatch) => {
    dispatch(Actions.Shops.rescheduleShopsPolling(interval || POLLING_INTERVAL.SHORT));
    api.products.bulkEditProducts({ db, operations, shopId })
      .then(
        () => dispatch(Actions.BulkEdit.syncUpdatesSucceeded({ route, waitForSync })),
        (error) => dispatch(Actions.BulkEdit.syncUpdatesFailed({ bulkEdit, error }))
      );
  });

  return reduction.deleteIn(['bulkEdit', 'syncStarted']);
}

function* syncUpdatesFailed(reduction, { bulkEdit, error }) {
  yield sideEffect((dispatch) => {
    console.error('Error encountered while syncing updates: ', error);
    dispatch(Actions.Notifications.add({ type: NOTIFICATION.ERROR, message: MESSAGE.FAIL.SYNC }));
    dispatch(Actions.Shops.setData({ path: ['syncData', bulkEdit.get('shopId')] }));
  });

  return getSize(reduction.get('bulkEdit'))
    ? reduction.set('bulkEdit', bulkEdit)
    : reduction;
}

function* syncUpdatesSucceeded(reduction, { route, waitForSync }) {
  yield sideEffect((dispatch) => {
    if (route) {
      navigateTo(route);
    } else if (!reduction.getIn(['bulkEdit', 'saving'])) {
      dispatch(Actions.Shops.setData({ path: 'waitForSync', value: waitForSync }));
    }
  });

  return reduction;
}

function* toggleProduct(reduction, id) {
  function toggle(selected) {
    return selected.has(id)
      ? selected.delete(id)
      : selected.add(id);
  }

  const bulkEdit = reduction
    .get('bulkEdit')
    .updateIn(['products', 'selected'], toggle);

  return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
}

function* updateMenuForCategory(reduction, category) {
  const bulkEdit = reduction.get('bulkEdit');
  const channel = bulkEdit.get('channel');
  const menu = bulkEdit.get('menu');
  const categories = Set([category]);

  return reduction.setIn(['bulkEdit', 'menu'],
    updateMenuTaxonomyAttrubutes({ categories, channel, edited: true, menu })
  );
}

function* waitForOperations(reduction) {
  if (!getSize(reduction.get('bulkEdit'))) return reduction;

  const bulkEdit = reduction
    .get('bulkEdit')
    .delete('saving')
    .set('processing', true)
    .set('waitingForOperationsToBeApplied', true);

  return reduction.set('bulkEdit', bulkEdit.set('applyDisabled', isApplyDisabled(bulkEdit)));
}

Reducers.add(
  new Reducer('BulkEdit')
    .add(ACTIONS.BULKEDIT.ADD_OPERATION, addOperation)
    .add(ACTIONS.BULKEDIT.ADD_PENDING_UPDATES, addPendingUpdates)
    .add(ACTIONS.BULKEDIT.ADD_PREVIEW, addPreview)
    .add(ACTIONS.BULKEDIT.AI_DISCARD, aiDiscard)
    .add(ACTIONS.BULKEDIT.AI_GENERATE, aiGenerate)
    .add(ACTIONS.BULKEDIT.AI_GENERATE_CANCEL, aiGenerateCancel)
    .add(ACTIONS.BULKEDIT.AI_GENERATE_FAILED, aiGenerateFailed)
    .add(ACTIONS.BULKEDIT.AI_GENERATE_SUCCEEDED, aiGenerateSucceeded)
    .add(ACTIONS.BULKEDIT.APPLY_PREVIEW, applyPreview)
    .add(ACTIONS.BULKEDIT.BOOTSTRAP, bootstrap)
    .add(ACTIONS.BULKEDIT.BOOTSTRAP_FAILED, bootstrapFailed)
    .add(ACTIONS.BULKEDIT.BOOTSTRAP_SUCCEEDED, bootstrapSucceeded)
    .add(ACTIONS.BULKEDIT.CLOSE_BULK_EDIT, closeBulkEdit)
    .add(ACTIONS.BULKEDIT.GET_CATEGORIES, getCategories)
    .add(ACTIONS.BULKEDIT.GET_CATEGORIES_FAILED, getCategoriesFailed)
    .add(ACTIONS.BULKEDIT.GET_CATEGORIES_SUCCEEDED, getCategoriesSucceeded)
    .add(ACTIONS.BULKEDIT.GET_ERROR_COUNTS, getErrorCounts)
    .add(ACTIONS.BULKEDIT.GET_ERROR_COUNTS_FAILED, getErrorCountsFailed)
    .add(ACTIONS.BULKEDIT.GET_ERROR_COUNTS_SUCCEEDED, getErrorCountsSucceeded)
    .add(ACTIONS.BULKEDIT.GET_FILTER_IDS, getFilterIds)
    .add(ACTIONS.BULKEDIT.GET_FILTER_IDS_FAILED, getFilterIdsFailed)
    .add(ACTIONS.BULKEDIT.GET_FILTER_IDS_SUCCEEDED, getFilterIdsSucceeded)
    .add(ACTIONS.BULKEDIT.GET_INVALID_PRODUCTS, getInvalidProducts)
    .add(ACTIONS.BULKEDIT.GET_INVALID_PRODUCTS_FAILED, getInvalidProductsFailed)
    .add(ACTIONS.BULKEDIT.GET_INVALID_PRODUCTS_SUCCEEDED, getInvalidProductsSucceeded)
    .add(ACTIONS.BULKEDIT.GET_PHOTOS_BY_INDEX, getPhotosByIndex)
    .add(ACTIONS.BULKEDIT.GET_PHOTOS_BY_INDEX_SUCCEEDED, getPhotosByIndexSucceeded)
    .add(ACTIONS.BULKEDIT.GET_PHOTOS_BY_INDEX_FAILED, getPhotosByIndexFailed)
    .add(ACTIONS.BULKEDIT.GET_PRODUCTS, getProducts)
    .add(ACTIONS.BULKEDIT.GET_PRODUCTS_SUCCEEDED, getProductsSucceeded)
    .add(ACTIONS.BULKEDIT.GET_PRODUCTS_FAILED, getProductsFailed)
    .add(ACTIONS.BULKEDIT.GET_PROFILE_BY_ID, getProfileById)
    .add(ACTIONS.BULKEDIT.GET_PROFILE_BY_ID_FAILED, getProfileByIdFailed)
    .add(ACTIONS.BULKEDIT.GET_PROFILE_BY_ID_SUCCEEDED, getProfileByIdSucceeded)
    .add(ACTIONS.BULKEDIT.HIDE_COUNTDOWN, hideCountdown)
    .add(ACTIONS.BULKEDIT.REMOVE_BACKGROUND, removeBackground)
    .add(ACTIONS.BULKEDIT.REMOVE_BACKGROUND_FAILED, removeBackgroundFailed)
    .add(ACTIONS.BULKEDIT.REMOVE_BACKGROUND_SUCCEEDED, removeBackgroundSucceeded)
    .add(ACTIONS.BULKEDIT.REMOVE_PREVIEW, removePreview)
    .add(ACTIONS.BULKEDIT.SAVE_AS, saveAs)
    .add(ACTIONS.BULKEDIT.SCHEDULE_UPDATES, scheduleUpdates)
    .add(ACTIONS.BULKEDIT.SCHEDULE_UPDATES_FAILED, scheduleUpdatesFailed)
    .add(ACTIONS.BULKEDIT.SCHEDULE_UPDATES_SUCCEEDED, scheduleUpdatesSucceeded)
    .add(ACTIONS.BULKEDIT.SEARCH, search)
    .add(ACTIONS.BULKEDIT.SELECT_OPERATION, selectOperation)
    .add(ACTIONS.BULKEDIT.SELECT_MENU_ITEM, selectMenuItem)
    .add(ACTIONS.BULKEDIT.SET_BANNER, setBanner)
    .add(ACTIONS.BULKEDIT.SET_DATA, setData)
    .add(ACTIONS.BULKEDIT.SET_MODAL, setModal)
    .add(ACTIONS.BULKEDIT.SET_PAGE, setPage)
    .add(ACTIONS.BULKEDIT.SET_PRODUCT, setProduct)
    .add(ACTIONS.BULKEDIT.SET_SELECTION, setSelection)
    .add(ACTIONS.BULKEDIT.SYNC_UPDATES, syncUpdates)
    .add(ACTIONS.BULKEDIT.SYNC_UPDATES_FAILED, syncUpdatesFailed)
    .add(ACTIONS.BULKEDIT.SYNC_UPDATES_SUCCEEDED, syncUpdatesSucceeded)
    .add(ACTIONS.BULKEDIT.TOGGLE_PRODUCT, toggleProduct)
    .add(ACTIONS.BULKEDIT.UPDATE_MENU_FOR_CATEGORY, updateMenuForCategory)
    .add(ACTIONS.BULKEDIT.WAIT_FOR_OPERATIONS, waitForOperations)
);
