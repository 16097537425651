import { DIGITAL, IS_SUPPLY, PROFILE_ID, PROFILE_TYPE, WHEN_MADE, WHO_MADE } from '../../constants/attributes';
import { DEFAULTS } from '../../constants';
import { ETSY } from '../../constants/channels';
import {
  ABOUT_LIST,
  ABOUT_VALUES,
  PRODUCTION_PARTNERS_TYPE,
  SECTIONS,
  SHIPPING_PROFILE,
  SHIPPING_PROFILE_MAP,
  VALUE,
} from '../../constants/product';

const { [WHEN_MADE]: WHEN_MADE_LIST } = ABOUT_LIST[ETSY];
const {
  [IS_SUPPLY]: { FINISHED_PRODUCT, SUPPLY_OR_TOOL },
  [WHEN_MADE]: { BEFORE_2005 },
  [WHO_MADE]: { SOMEONE_ELSE },
} = ABOUT_VALUES[ETSY];

const { DETAILS } = SECTIONS;

const VINTAGE_INDEX = WHEN_MADE_LIST.indexOf(BEFORE_2005);

export function isVintage(whenMade) {
  return WHEN_MADE_LIST.indexOf(whenMade) >= VINTAGE_INDEX;
}

export function getProductionPartnersType({ isSupply, whenMade, whoMade }) {
  if ((whoMade === SOMEONE_ELSE && isSupply === SUPPLY_OR_TOOL) || isVintage(whenMade)) {
    return PRODUCTION_PARTNERS_TYPE.UNAVAILABLE;
  }

  if (whoMade === SOMEONE_ELSE && isSupply === FINISHED_PRODUCT) {
    return PRODUCTION_PARTNERS_TYPE.REQUIRED;
  }

  return PRODUCTION_PARTNERS_TYPE.OPTIONAL;
}

export function areShippingOptionsCustom(shipping) {
  return (
    shipping.getIn([PROFILE_ID, VALUE]) !== DEFAULTS.NULL &&
    shipping.getIn([PROFILE_TYPE, VALUE]) === DEFAULTS.NULL
  );
}

export function areShippingDimensionsOptional(shipping) {
  return (
    areShippingOptionsCustom(shipping) ||
    SHIPPING_PROFILE_MAP[shipping.getIn([PROFILE_TYPE, VALUE])] !== SHIPPING_PROFILE.CALCULATED
  );
}

export function isUploadFilesSupported({ channel, product }) {
  switch (channel) {
    case ETSY: {
      return (
        product.getIn([DETAILS, DIGITAL, VALUE]) &&
        product.getIn([DETAILS, WHEN_MADE, VALUE]) !== ABOUT_VALUES[channel][WHEN_MADE].MADE_TO_ORDER
      );
    }

    default: {
      return false;
    }
  }
}
