
import { List, Map, OrderedMap } from 'immutable';

import { filterOperations, replaceOperation } from './operations';
import { areComplete, setIsComplete } from './isComplete';
import { getAttributesForCategory } from './taxonomy';
import { getCategoryError } from '../validations/details';
import { editVariations } from '../edit/variations';
import { newProfile } from '../new/profile';
import { editTags } from '../edit/tags';
import { getSize } from '../iterable/getSize';
import { convert } from './convert';
import {
  setBarcodeErrors,
  setCAPErrors,
  setCategoryErrors,
  setCPIErrors,
  setPriceErrors,
  setQuantityErrors,
  setSKUErrors,
  setTaxonomyAttributesErrors,
  setWeightErrors,
  updateProductVariationsErrors,
} from './errors';

import { ERROR, OPERATIONS, OVERSET, SECTIONS, VALUE } from '../../constants/product';
import { MAX_NUMBER_OF_TAGS } from '../../constants/validations';
import { DEFAULTS, VELA } from '../../constants';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { PROFILE } from '../../constants/profiles';
import {
  BARCODE,
  CAP,
  CATEGORY,
  CPI,
  NEW_TAGS,
  PHYSICAL,
  PRICE,
  QUANTITY,
  SKU,
  TAGS,
  TAXONOMY_ATTRIBUTES,
  VARIATIONS,
} from '../../constants/attributes';

const { DETAILS, INVENTORY } = SECTIONS;

export function clearProfile({ channel, forBulk, fromVelaForm, shopId, state, type }) {
  function reduceChannelsForTags(productsByShopId, shopIds, shopsChannel) {
    const newTags = state.getIn([VELA, TAGS, NEW_TAGS]);

    function reduceShopIds(result, productShopId) {
      function updateProduct(source) {
        let product = source
          .deleteIn([TAGS, NEW_TAGS])
          .deleteIn([TAGS, 'currentProfile'])
          .setIn([TAGS, VALUE], OrderedMap())
          .set('operations',
            source
              .get('operations', DEFAULTS.EMPTY_LIST)
              .filter(filterOperations(OPERATIONS[shopsChannel].TAGS.CHANGE_TO, false))
          );

        if (getSize(newTags)) {
          const updates = editTags({
            channel: shopsChannel,
            newTags: newTags.slice(0, MAX_NUMBER_OF_TAGS[shopsChannel]),
            operation: OPERATIONS[shopsChannel].TAGS.CHANGE_TO,
            product,
          });

          product = updates.product.set('operations',
            updates.product.get('operations').concat(List(updates.operations))
          );
        } else {
          product = product.set('operations',
            product
              .get('operations')
              .push(
                Map({
                  type: OPERATIONS[shopsChannel].TAGS.CHANGE_TO,
                  value: DEFAULTS.EMPTY_LIST,
                })
              )
          );
        }

        return setIsComplete(product);
      }

      return result.update(productShopId, updateProduct);
    }

    return shopIds.reduce(reduceShopIds, productsByShopId);
  }

  function reduceChannelsForVariations(profile) {
    return function reduceChannels(productsByShopId, shopIds, shopsChannel) {
      function reduceShopIds(result, productShopId) {
        function updateProduct(product) {
          const updates = editVariations({
            channel: shopsChannel,
            product: product
              .set(VARIATIONS,
                newProfile({ channel: shopsChannel, forBulk, forProduct: true, type: PROFILE.VARIATIONS })
              ),
            value: convert({
              from: VELA,
              product,
              to: shopsChannel,
              type: VARIATIONS,
              value: profile,
            }),
          });

          return setIsComplete(
            updates.product.set('operations',
              updates.operations.reduce(
                replaceOperation,
                updates.product.get('operations', DEFAULTS.EMPTY_LIST)
              )
            )
          );
        }

        return result.update(productShopId, updateProduct);
      }

      return shopIds.reduce(reduceShopIds, productsByShopId);
    };
  }

  if (fromVelaForm) {
    switch (type) {
      case PROFILE.TAGS: {
        const products = state
          .get('channels')
          .reduce(reduceChannelsForTags, state.get('products'));

        return {
          state: state
            .deleteIn([VELA, TAGS, 'currentProfile'])
            .set('products', products)
            .set('isComplete', areComplete(products)),
        };
      }

      case PROFILE.VARIATIONS: {
        let product = state.get(VELA, Map());
        const profile = (
          product.getIn([VARIATIONS, 'oldProfile']) ||
          newProfile({ channel: VELA, forBulk, forProduct: true, type: PROFILE.VARIATIONS })
        );

        product = product.set(VARIATIONS, profile);
        const products = state
          .get('channels')
          .reduce(reduceChannelsForVariations(profile), state.get('products'));

        return {
          state: state
            .set(VELA, product)
            .set('products', products)
            .set('isComplete', areComplete(products)),
        };
      }

      default: {
        return { state };
      }
    }
  }

  let product = state.getIn(['products', shopId]);

  switch (type) {
    case PROFILE.TAGS: {
      const freeSpace = MAX_NUMBER_OF_TAGS[channel] - getSize(product.getIn([TAGS, VALUE]));
      const newTags = freeSpace > 0
        ? product.getIn([TAGS, NEW_TAGS], OrderedMap()).slice(0, freeSpace)
        : OrderedMap();

      const updates = editTags({
        channel,
        newTags,
        operation: OPERATIONS[channel].TAGS.CHANGE_TO,
        product: product.deleteIn([TAGS, 'currentProfile']),
      });

      product = updates.product.set('operations',
        product
          .get('operations', DEFAULTS.EMPTY_LIST)
          .filter(filterOperations(OPERATIONS[channel].TAGS.CHANGE_TO, false))
          .concat(List(updates.operations))
      );

      break;
    }

    case PROFILE.VARIATIONS: {
      const profile = product.getIn([VARIATIONS, 'oldProfile']);

      if (!profile) return { state };

      switch (channel) {
        case ETSY: {
          if (product.getIn([DETAILS, CATEGORY, VALUE]) !== profile.get(CATEGORY)) {
            product = product = setCategoryErrors({
              channel: ETSY,
              product: product.setIn([DETAILS, CATEGORY, VALUE], profile.get(CATEGORY)),
            });

            const { taxonomyAttributes } = getAttributesForCategory({
              category: product.getIn([DETAILS, CATEGORY, VALUE]),
              taxonomyAttributes: product.getIn([DETAILS, TAXONOMY_ATTRIBUTES], Map()),
            });

            product = setTaxonomyAttributesErrors({
              channel,
              product: product.setIn([DETAILS, TAXONOMY_ATTRIBUTES], taxonomyAttributes),
            });
          }

          break;
        }

        default: {
          break;
        }
      }

      product = product.set(VARIATIONS, profile);

      if (getSize(profile.get(VARIATIONS))) {
        product = updateProductVariationsErrors({ channel, product });

        switch (channel) {
          case ETSY: {
            const categoryValue = product.getIn([DETAILS, CATEGORY, VALUE]);
            const categoryError = getCategoryError({ channel, value: categoryValue });

            if (categoryError) {
              product = product
                .setIn(['errors', CATEGORY], categoryError)
                .setIn([DETAILS, CATEGORY, ERROR], categoryError);
            }

            break;
          }

          default: {
            break;
          }
        }
      } else {
        switch (channel) {
          case ETSY: {
            const categoryValue = product.getIn([ DETAILS, CATEGORY, VALUE]);
            const categoryError = getCategoryError({ channel, value: categoryValue });

            if (categoryError) {
              product = product
                .setIn(['errors', CATEGORY], categoryError)
                .setIn([DETAILS, CATEGORY, ERROR], categoryError);
            }

            product = product
              .deleteIn([INVENTORY, SKU, OVERSET])
              .deleteIn([INVENTORY, PRICE, OVERSET])
              .deleteIn([INVENTORY, QUANTITY, OVERSET]);

            product = setPriceErrors({ channel, product });
            product = setQuantityErrors({ channel, product });
            product = setSKUErrors({ channel, product });

            break;
          }

          case SHOPIFY: {
            product = product
              .deleteIn([INVENTORY, CAP, OVERSET])
              .deleteIn([INVENTORY, CPI, OVERSET])
              .deleteIn([INVENTORY, SKU, OVERSET])
              .deleteIn([INVENTORY, PRICE, OVERSET])
              .deleteIn([INVENTORY, BARCODE, OVERSET])
              .deleteIn([INVENTORY, QUANTITY, OVERSET])
              .deleteIn([INVENTORY, PHYSICAL, OVERSET]);

            product = setBarcodeErrors({ channel, product });
            product = setCAPErrors({ channel, product });
            product = setCPIErrors({ channel, product });
            product = setPriceErrors({ channel, product });
            product = setQuantityErrors({ channel, product });
            product = setSKUErrors({ channel, product });
            product = setWeightErrors({ channel, product });

            break;
          }

          default: {
            break;
          }
        }
      }

      product = product
        .deleteIn([VARIATIONS, 'operations'])
        .deleteIn([VARIATIONS, 'currentProfile'])
        .set('operations',
          product
            .get('operations')
            .filter(filterOperations(OPERATIONS[channel].VARIATIONS, false))
            .concat(product.getIn([VARIATIONS, 'operations'], List()))
        );

      break;
    }

    default: {
      break;
    }
  }

  const products = state.get('products').set(shopId, setIsComplete(product));

  return {
    state: state
      .set('products', products)
      .set('isComplete', areComplete(products)),
  };
}
