export const ALT_TEXT = 'altText';
export const BARCODE = 'barcode';
export const CAP = 'cap';
export const CATEGORY = 'category';
export const CHARGE_TAX = 'chargeTax';
export const CHAR_COUNT_MAX = 'charCountMax';
export const COLLECTIONS = 'collections';
export const CONTINUE_SELLING = 'continueSelling';
export const COUNTRY_CODE = 'countryCode';
export const CPI = 'cpi';
export const DEFAULT_VALUE = 'defaultValue';
export const DESCRIPTION = 'description';
export const DIGITAL = 'digital';
export const ENABLED = 'enabled';
export const FILES = 'files';
export const HEIGHT = 'height';
export const HS_CODE = 'hsCode';
export const INSTRUCTIONS = 'instructions';
export const IS_SUPPLY = 'isSupply';
export const LENGTH = 'length';
export const MATERIALS = 'materials';
export const META_DESCRIPTION = 'metaDescription';
export const NEW_MATERIALS = 'newMaterials';
export const NEW_TAGS = 'newTags';
export const PAGE_TITLE = 'pageTitle';
export const PERSONALIZATION = 'personalization';
export const PHOTOS = 'photos';
export const VIDEOS = 'videos';
export const THUMBNAILS = 'thumbnails';
export const PHYSICAL = 'physical';
export const PRICE = 'price';
export const PRODUCTION_PARTNERS = 'productionPartners';
export const PRODUCT_TYPE = 'productType';
export const PROFILE_ID = 'profileId';
export const PROFILE_TYPE = 'profileType';
export const QUANTITY = 'quantity';
export const REQUIRED = 'required';
export const RETURN_POLICY = 'returnPolicy';
export const SECTION = 'section';
export const SEO = 'seo';
export const SHIPPING = 'shipping';
export const SHOULD_AUTO_RENEW = 'shouldAutoRenew';
export const SKU = 'sku';
export const TAGS = 'tags';
export const TAXONOMY_ATTRIBUTES = 'taxonomyAttributes';
export const TITLE = 'title';
export const TRACK_QUANTITY = 'trackQuantity';
export const UNIT = 'unit';
export const URL_HANDLE = 'urlHandle';
export const URL_NUMERATION = 'urlNumeration';
export const URL_REDIRECT = 'urlRedirect';
export const VARIATIONS = 'variations';
export const VENDOR = 'vendor';
export const VISIBILITY = 'visibility';
export const WEIGHT = 'weight';
export const WHEN_MADE = 'whenMade';
export const WHO_MADE = 'whoMade';
export const WIDTH = 'width';

export default {
  ALT_TEXT,
  BARCODE,
  CAP,
  CATEGORY,
  CHARGE_TAX,
  CHAR_COUNT_MAX,
  COLLECTIONS,
  CONTINUE_SELLING,
  COUNTRY_CODE,
  CPI,
  DEFAULT_VALUE,
  DESCRIPTION,
  DIGITAL,
  ENABLED,
  FILES,
  HEIGHT,
  HS_CODE,
  INSTRUCTIONS,
  IS_SUPPLY,
  LENGTH,
  MATERIALS,
  META_DESCRIPTION,
  NEW_MATERIALS,
  NEW_TAGS,
  PAGE_TITLE,
  PERSONALIZATION,
  PHOTOS,
  VIDEOS,
  THUMBNAILS,
  PHYSICAL,
  PRICE,
  PRODUCTION_PARTNERS,
  PRODUCT_TYPE,
  PROFILE_ID,
  PROFILE_TYPE,
  QUANTITY,
  REQUIRED,
  RETURN_POLICY,
  SECTION,
  SEO,
  SHIPPING,
  SHOULD_AUTO_RENEW,
  SKU,
  TAGS,
  TAXONOMY_ATTRIBUTES,
  TITLE,
  TRACK_QUANTITY,
  UNIT,
  URL_HANDLE,
  URL_NUMERATION,
  URL_REDIRECT,
  VARIATIONS,
  VENDOR,
  VISIBILITY,
  WEIGHT,
  WHEN_MADE,
  WHO_MADE,
  WIDTH,
};
