import { ENDPOINT } from './api';
import { NEW } from './product';

export const PAGES = {
  ACCOUNT: 'account',
  ADD_SHOP: 'add-shop',
  ADMIN: 'admin',
  AFFILIATE: 'affiliate',
  AUTH: 'auth',
  APPLICANTS: 'applicants',
  BILLING: 'billing',
  BULK_EDIT: 'bulk-edit',
  EDIT: 'edit',
  EDITOR: 'editor',
  LIBRARY: 'library',
  LISTINGS: 'listings',
  INFO: 'info',
  NEW,
  REFERRALS: 'referrals',
  STUDIO: 'studio',
  STUDIO_EDITOR: 'studio-editor',
  STUDIO_LIBRARY: 'studio-library',
  PROFILES: 'profiles',
  PAYOUTS: 'payouts',
  RESET_PASSWORD: 'reset-password',
  SCHEDULED_UPDATES: 'scheduled-updates',
  SHOPS: 'shops',
  SIGN_IN: 'sign-in',
  SIGN_UP: 'sign-up',
  SOCIAL_MEDIA_SHARE: 'share',
  TOOLS: 'tools',
  USERS: 'users',
  WELCOME: 'welcome',
};

export const PARAMS = {
  AFFILIATE: 'affiliate',
  LINK_DATA: 'linkData',
  LINK_ID: 'linkId',
  PAYOUT_ID: 'payoutId',
  PRODUCT_ID: 'productId',
  SHOP_ID: 'shopId',
  STATUS: 'status',
  USER_ID: 'userId',
  TYPE: 'type',
};

export const ROUTES = {
  ACCOUNT: {
    ROOT: `/${PAGES.ACCOUNT}`,
    AFFILIATE: `/${PAGES.AFFILIATE}`,
    BILLING: `/${PAGES.BILLING}`,
  },
  ADD_SHOP: `/${PAGES.ADD_SHOP}`,
  ADMIN: {
    ROOT: `/${PAGES.ADMIN}`,
    SHOPS: {
      ROOT: `/${PAGES.SHOPS}`,
      DETAILS: `/:${PARAMS.SHOP_ID}`,
    },
    USERS: {
      ROOT: `/${PAGES.USERS}`,
      DETAILS: `/:${PARAMS.USER_ID}`,
    },
    APPLICANTS: {
      ROOT: `/${PAGES.APPLICANTS}`,
    },
    PAYOUTS: {
      ROOT: `/${PAGES.PAYOUTS}`,
      DETAILS: `/:${PARAMS.PAYOUT_ID}`,
    },
    REFERRALS: {
      ROOT: `/${PAGES.REFERRALS}`,
      DETAILS: `/:${PARAMS.USER_ID}`,
    },
  },
  AFFILIATES: `/i/:${PARAMS.AFFILIATE}`,
  AUTH: {
    ROOT: `/${PAGES.AUTH}`,
    SIGN_IN: `/${PAGES.SIGN_IN}`,
    SIGN_UP: `/${PAGES.SIGN_UP}`,
    RESET_PASSWORD: {
      ROOT: `/${PAGES.RESET_PASSWORD}`,
      DETAILS: `/:${PARAMS.LINK_ID}?/:${PARAMS.LINK_DATA}?`,
    },
  },
  INFO: `/${PAGES.INFO}`,
  ROOT: '/',
  SHOP: {
    ROOT: `/:${PARAMS.SHOP_ID}`,
    LISTINGS: {
      ROOT: `/${PAGES.LISTINGS}`,
      BULK_EDIT: `/${PAGES.BULK_EDIT}`,
      EDIT: `/${PAGES.EDIT}/:${PARAMS.PRODUCT_ID}`,
      NEW: `/${PAGES.NEW}`,
      SOCIAL_MEDIA_SHARE: `/${PAGES.SOCIAL_MEDIA_SHARE}/:${PARAMS.PRODUCT_ID}`,
      STATUS: `/:${PARAMS.STATUS}`,
    },
    PROFILES: {
      ROOT: `/${PAGES.PROFILES}`,
      TYPE: `/:${PARAMS.TYPE}`,
    },
    SCHEDULED_UPDATES: {
      ROOT: `/${PAGES.SCHEDULED_UPDATES}`,
    },
    STUDIO: {
      ROOT: `/${PAGES.STUDIO}`,
      EDITOR: `/${PAGES.EDITOR}`,
      LIBRARY: `/${PAGES.LIBRARY}`,
    },
  },
  WELCOME: `/${PAGES.WELCOME}`,
  WILDCARD: '/*',
};

export const PAGE_TO_ROUTE_MAP = {
  [PAGES.ACCOUNT]: ROUTES.ACCOUNT.ROOT,
  [PAGES.ADD_SHOP]: ROUTES.ADD_SHOP,
  [PAGES.ADMIN]: `${ROUTES.ADMIN.ROOT}${ROUTES.WILDCARD}`,
  [PAGES.AFFILIATE]: `${ROUTES.ACCOUNT.ROOT}${ROUTES.ACCOUNT.AFFILIATE}`,
  [PAGES.AUTH]: `${ROUTES.AUTH.ROOT}${ROUTES.WILDCARD}`,
  [PAGES.BILLING]: `${ROUTES.ACCOUNT.ROOT}${ROUTES.ACCOUNT.BILLING}`,
  [PAGES.BULK_EDIT]: `${ROUTES.SHOP.ROOT}${ROUTES.SHOP.LISTINGS.ROOT}${ROUTES.SHOP.LISTINGS.BULK_EDIT}`,
  [PAGES.EDIT]: `${ROUTES.SHOP.ROOT}${ROUTES.SHOP.LISTINGS.ROOT}${ROUTES.SHOP.LISTINGS.EDIT}`,
  [PAGES.SOCIAL_MEDIA_SHARE]: `${ROUTES.SHOP.ROOT}${ROUTES.SHOP.LISTINGS.ROOT}${ROUTES.SHOP.LISTINGS.SOCIAL_MEDIA_SHARE}`,
  [PAGES.NEW]: `${ROUTES.SHOP.ROOT}${ROUTES.SHOP.LISTINGS.ROOT}${ROUTES.SHOP.LISTINGS.NEW}`,
  [PAGES.LISTINGS]: `${ROUTES.SHOP.ROOT}${ROUTES.SHOP.LISTINGS.ROOT}${ROUTES.WILDCARD}`,
  [PAGES.INFO]: ROUTES.INFO,
  [PAGES.STUDIO_EDITOR]: `${ROUTES.SHOP.ROOT}${ROUTES.SHOP.STUDIO.ROOT}${ROUTES.SHOP.STUDIO.EDITOR}`,
  [PAGES.STUDIO_LIBRARY]: `${ROUTES.SHOP.ROOT}${ROUTES.SHOP.STUDIO.ROOT}${ROUTES.SHOP.STUDIO.LIBRARY}`,
  [PAGES.PROFILES]: `${ROUTES.SHOP.ROOT}${ROUTES.SHOP.PROFILES.ROOT}${ROUTES.WILDCARD}`,
  [PAGES.SCHEDULED_UPDATES]: `${ROUTES.SHOP.ROOT}${ROUTES.SHOP.SCHEDULED_UPDATES.ROOT}`,
  [PAGES.WELCOME]: ROUTES.WELCOME,
};

export const EXTERNAL_LINKS = {
  CANVA_AUTH: `${ENDPOINT.AUTH}/canva/oauth`,
  COMMUNITY: 'https://getvela.com/community',
  POSTER: {
    AFFILIATES: 'https://i.ytimg.com/vi/0jtwOh8kBi8/maxresdefault.jpg',
    INTRO: 'https://i.ytimg.com/vi/sUKGOQ4Cay8/maxresdefault.jpg',
  },
  VIDEO: {
    AFFILIATES: 'https://www.youtube.com/embed/0jtwOh8kBi8',
    INTRO: 'https://www.youtube.com/embed/sUKGOQ4Cay8',
  },
  WEBSITE: {
    PRICING: 'https://welcome.getvela.com/pricing',
  },
};

export const TOOLTIPS = {
  [PAGES.LISTINGS]: 'Listings',
  [PAGES.PROFILES]: 'Profiles',
  [PAGES.SCHEDULED_UPDATES]: 'Scheduled updates',
  [PAGES.STUDIO]: 'Studio',
  [PAGES.TOOLS]: 'Listings',
};

export const SHOP_PAGES = [
  PAGES.LISTINGS,
  PAGES.SCHEDULED_UPDATES,
  PAGES.PROFILES,
];

export const STUDIO_PAGES = [
  PAGES.STUDIO_EDITOR,
  PAGES.STUDIO_LIBRARY,
];
