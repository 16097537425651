import { useEffect, useState, useLayoutEffect } from 'react';

import { areEqual } from '../utils/iterable/areEqual';

const INITIAL_SIZE = { height: 0, width: 0 };
const INITIAL_OVERFLOW = { x: false, y: false };

export function useMediaQuery({ enabled = true, query }) {
  function mediaQuery() {
    return window.matchMedia(query);
  }

  function getInitialState() {
    return mediaQuery().matches;
  }

  const [state, setState] = useState(getInitialState);

  useEffect(
    function effect() {
      const mediaQueryList = mediaQuery();

      function changeState(event) {
        setState(event.matches);
      }

      function cleanUp() {
        try {
          mediaQueryList.removeEventListener('change', changeState);
        } catch (error) {
          mediaQueryList.removeListener(changeState);
        }
      }

      if (!enabled) return cleanUp();

      try {
        mediaQueryList.addEventListener('change', changeState);
      } catch (error) {
        mediaQueryList.addListener(changeState);
      }

      return cleanUp;
    },
    [enabled]
  );

  return state;
}

export function useWindowSize() {
  const [state, setState] = useState(INITIAL_SIZE);

  function onResize() {
    setState({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }

  useLayoutEffect(
    function effect() {
      onResize();
      window.addEventListener('resize', onResize);

      return function cleanUp() {
        window.removeEventListener('resize', onResize);
      };
    },
    []
  );

  return state;
}

export function useElementSize({ boundings = 'offset', ref }) {
  function getInitialState() {
    function reduceBoundings(result, bounding) {
      result[bounding] = INITIAL_SIZE;
      return result;
    }

    if (Array.isArray(boundings)) {
      return boundings.reduce(reduceBoundings, {});
    } else {
      return INITIAL_SIZE;
    }
  }

  const [state, setState] = useState(getInitialState);

  useLayoutEffect(
    function effect() {
      if (!ref.current) {
        const initialState = getInitialState();

        if (state !== initialState) {
          setState(initialState);
        }

        return undefined;
      }

      function onResize() {
        function getDimensions(bounding) {
          const { [`${bounding}Height`]: height, [`${bounding}Width`]: width } = ref.current;
          return { height, width };
        }

        function reduceBoundings(result, bounding) {
          result[bounding] = getDimensions(bounding);
          return result;
        }

        function getState() {
          if (Array.isArray(boundings)) {
            return boundings.reduce(reduceBoundings, {});
          } else {
            return getDimensions(boundings);
          }
        }

        setState(function updateState(oldState) {
          const newState = getState();
          return areEqual(newState, oldState)
            ? oldState
            : newState;
        });
      }

      const resizeObserver = new ResizeObserver(onResize);

      resizeObserver.observe(ref.current);

      return function cleanUp() {
        resizeObserver.disconnect();
      };
    },
    [boundings, ref]
  );

  return state;
}

export function useElementOverflow({ ref }) {
  const [state, setState] = useState(INITIAL_OVERFLOW);

  useLayoutEffect(
    function effect() {
      if (!ref.current) {
        if (state !== INITIAL_OVERFLOW) {
          setState(INITIAL_OVERFLOW);
        }

        return undefined;
      }

      function onResize() {
        const { clientHeight, clientWidth, scrollHeight, scrollWidth } = ref.current;
        const x = scrollWidth > clientWidth;
        const y = scrollHeight > clientHeight;
        setState(function updateState(oldState) {
          return oldState.x === x && oldState.y === y
            ? oldState
            : { x, y };
        });
      }

      const resizeObserver = new ResizeObserver(onResize);

      resizeObserver.observe(ref.current);

      return function cleanUp() {
        resizeObserver.disconnect();
      };
    },
    [ref.current]
  );

  return state;
}
