import { List, Map, Set } from 'immutable';
import SHA1 from 'crypto-js/sha1';

import { makeThumbnailUrl } from '../photos';
import { getIndexes } from './getIndexes';

import { BULK_THUMBNAIL_SIZE, OPERATIONS } from '../../constants/photoEditor';

function addBG({ indexes: sourceIndexes, properties, operations }) {
  const indexes = sourceIndexes.intersect(
    getIndexes({ operationType: OPERATIONS.REMOVE_BACKGROUND, operations })
  );

  function findOperation(operation) {
    return (
      operation.get('type') === OPERATIONS.REMOVE_BACKGROUND && (
        (
          !properties.size &&
          !operation.get('properties')?.size
        ) || (
          properties.has('color') &&
          operation.hasIn(['properties', 'color']) &&
          properties.get('color') === operation.getIn(['properties', 'color'])
        ) || (
          properties.has('image') &&
          operation.hasIn(['properties', 'image']) &&
          properties.getIn(['image', 'id']) === operation.getIn(['properties', 'image', 'id'])
        )
      )
    );
  }

  function newOperaiton() {
    const operation = Map({ type: OPERATIONS.REMOVE_BACKGROUND });
    return properties.size
      ? operation.set('properties', properties)
      : operation;
  }

  function reduceOperations(currentIndex) {
    function subtractIndexes(value) {
      return value.subtract(indexes);
    }

    function unionIndexes(value = Set()) {
      return value.union(indexes);
    }

    return function reduce(result, operation, operationIndex) {
      if (currentIndex === operationIndex) {
        return result.push(operation.update('indexes', unionIndexes));
      }

      const updatedOperation = operation.update('indexes', subtractIndexes);

      return updatedOperation.get('indexes').size
        ? result.push(updatedOperation)
        : result;
    };
  }

  const operationIndex = operations.findIndex(findOperation);
  return operationIndex === -1
    ? operations.push(newOperaiton()).reduce(reduceOperations(operations.size), List())
    : operations.reduce(reduceOperations(operationIndex), List());
}

function removeBG({ indexes: sourceIndexes, operations, photos }) {
  function findOperation(operation) {
    return (
      operation.get('type') === OPERATIONS.REMOVE_BACKGROUND &&
      !operation.get('properties')?.size
    );
  }

  function reduceIndexes(result, imageIndex) {
    const originalId = result.photos.getIn(['ids', imageIndex]);
    let processedId = result.toRemoveBG.getIn([originalId, 'processedId']);

    function updateById(value) {
      return value.set(processedId,
        Map({ id: processedId, loaded: value.has(originalId), originalId, processedId })
      );
    }

    function addIndex(value) {
      return value.add(imageIndex);
    }

    function updateIds(value) {
      return value.set(imageIndex, processedId);
    }

    if (!processedId) {
      processedId = SHA1(originalId).toString();

      if (!result.photos.hasIn(['byId', processedId])) {
        result.photos = result.photos.update('byId', updateById);

        if (result.photos.hasIn(['byId', originalId])) {
          result.toRemoveBG = result.toRemoveBG.set(originalId,
            Map({
              indexes: Set([imageIndex]),
              originalId,
              processedId,
              url: makeThumbnailUrl(
                result.photos.getIn(['byId', originalId, 'fullsize_url']),
                BULK_THUMBNAIL_SIZE
              ),
            })
          );
        }
      }
    } else {
      result.toRemoveBG = result.toRemoveBG.updateIn([originalId, 'indexes'], addIndex);
    }

    result.photos = result.photos.update('ids', updateIds);

    return result;
  }

  const operationIndex = operations.findIndex(findOperation);
  const indexes = sourceIndexes.subtract(
    getIndexes({ operationType: OPERATIONS.REMOVE_BACKGROUND, operations })
  );

  function unionIndexes(value) {
    return value.union(indexes);
  }

  const updatedOperations = operationIndex === -1
    ? operations.push(
      Map({
        type: OPERATIONS.REMOVE_BACKGROUND,
        indexes,
      })
    )
    : operations.updateIn([operationIndex, 'indexes'], unionIndexes);

  return indexes.reduce(
    reduceIndexes,
    {
      operations: updatedOperations,
      photos,
      toRemoveBG: Map(),
    }
  );
}

function restoreOriginal({ indexes: sourceIndexes, operations, photos }) {
  const indexes = sourceIndexes.intersect(
    getIndexes({ operationType: OPERATIONS.REMOVE_BACKGROUND, operations })
  );

  function updateIds(value) {
    function restoreOriginalId(result, imageIndex) {
      return result.set(imageIndex, photos.getIn(['byId', result.get(imageIndex), 'originalId']));
    }

    return indexes.reduce(restoreOriginalId, value);
  }

  function updateById({ byId: value, ids }) {
    function filterById(item) {
      return item.get('fullsize_url') || ids.includes(item.get('processedId'));
    }

    return value.filter(filterById);
  }

  function reduceOperations(result, operation) {
    if (operation.get('type') !== OPERATIONS.REMOVE_BACKGROUND) {
      return result.push(operation);
    }

    function subtractIndexes(value) {
      return value.subtract(indexes);
    }

    const updatedOperation = operation.update('indexes', subtractIndexes);

    return updatedOperation.get('indexes').size
      ? result.push(updatedOperation)
      : result;
  }

  const ids = updateIds(photos.get('ids'));
  const byId = updateById({ byId: photos.get('byId'), ids });

  return {
    photos: photos.set('byId', byId).set('ids', ids),
    operations: operations.reduce(reduceOperations, List()),
  };
}

export default {
  addBG,
  removeBG,
  restoreOriginal,
};
