import ActionConstants, { Actions } from '../constants/actions';

const adminConstants = new Actions('Admin')
  .addAsync('sync_shop')
  .addAsync('delete_shop')
  .addAsync('refresh_shop')
  .addAsync('search_shops')
  .addAsync('search_users')
  .addAsync('get_user_info')
  .addAsync('reassign_shop')
  .addAsync('get_shop_counts')
  .addAsync('get_shop_details')
  .addAsync('get_user_details')
  .addAsync('impersonate_user')
  .addAsync('poll_shop_details')
  .addAsync('get_impersonation')
  .addAsync('stop_impersonating')
  .addAsync('search_users_for_reassign')
  .addAsync('get_applicants')
  .addAsync('activate_applicant')
  .addAsync('reject_applicant')
  .addAsync('get_payouts')
  .addAsync('get_payouts_details')
  .addAsync('mark_payout_as_paid')
  .addAsync('get_referrals')
  .addAsync('get_referrals_details')
  .add('schedule_shop_poll')
  .add('clear_shop_details')
  .add('clear_user_details')
  .add('clear_payouts_details')
  .add('clear_referrals_details')
  .add('reassign_shop_open_modal')
  .add('reassign_shop_close_modal');

ActionConstants.add(adminConstants);

export default adminConstants.actionCreators();
