import { List, Map } from 'immutable';

import { getAttributesForCategory, getOversetAttributes } from '../../product/taxonomy';
import { getProductionPartnersType } from '../../product/checkAttribute';
import { getTaxonomyAttribute } from '../taxonomy';
import { isTruthy } from '../../bool';
import { getSize } from '../../iterable/getSize';
import {
  setCategoryErrors,
  setFilesErrors,
  setIsSupplyErrors,
  setProductionPartnersErrors,
  setWhenMadeErrors,
  setWhoMadeErrors,
  setTaxonomyAttributesErrors,
} from '../../product/errors';

import { ERROR, PRODUCTION_PARTNERS_TYPE, SECTIONS, TAXONOMY_ATTRIBUTE, VALUE } from '../../../constants/product';
import { ETSY, SHOPIFY } from '../../../constants/channels';
import { OPERATIONS } from '../../../constants/bulkEdit';
import { MODALS } from '../../../constants/modal';
import {
  CATEGORY,
  COLLECTIONS,
  IS_SUPPLY,
  PRODUCTION_PARTNERS,
  PRODUCT_TYPE,
  SECTION,
  TAXONOMY_ATTRIBUTES,
  VARIATIONS,
  VENDOR,
  WHEN_MADE,
  WHO_MADE,
} from '../../../constants/attributes';

const { DETAILS } = SECTIONS;

function getAttributeByOperation(operationType) {
  if (/^is_supply/.test(operationType)) {
    return IS_SUPPLY;
  }

  if (/^product_type/.test(operationType)) {
    return PRODUCT_TYPE;
  }

  if (/^section/.test(operationType)) {
    return SECTION;
  }

  if (/^vendor/.test(operationType)) {
    return VENDOR;
  }

  if (/^when_made/.test(operationType)) {
    return WHEN_MADE;
  }

  if (/^who_made/.test(operationType)) {
    return WHO_MADE;
  }

  return undefined;
}

export default function details({ actions, operation, product: source, confirmation: prevConfirmation }) {
  const operationType = operation.get('type');
  let product = source;
  let confirmation = prevConfirmation;

  switch (operationType) {
    case OPERATIONS[ETSY].CATEGORY: {
      const category = operation.get(VALUE);
      product = setCategoryErrors({
        channel: ETSY,
        product: product
          .setIn([DETAILS, CATEGORY, VALUE], category)
          .deleteIn([VARIATIONS, 'errors', CATEGORY])
          .setIn([VARIATIONS, CATEGORY], category),
      });

      const { taxonomyAttributes } = getAttributesForCategory({
        category,
        taxonomyAttributes: product.getIn([DETAILS, TAXONOMY_ATTRIBUTES], Map()),
      });

      product = setTaxonomyAttributesErrors({
        channel: ETSY,
        product: product.setIn([DETAILS, TAXONOMY_ATTRIBUTES], taxonomyAttributes),
      });

      if (!confirmation) {
        confirmation = MODALS.CONFIRMATIONS.BULK_CHANGE_CATEGORY;
      }

      break;
    }

    case OPERATIONS[ETSY].TAXONOMY_ATTRIBUTES: {
      if (product.getIn([DETAILS, CATEGORY, ERROR])) break;

      const category = product.getIn([DETAILS, CATEGORY, VALUE]);

      operation.get(VALUE).forEach(function forEachOperation(operationValue, key) {
        const property = parseInt(key, 10);

        if (getOversetAttributes(product).has(property)) {
          return;
        }

        const scaleId = operationValue.get('scaleId');
        const values = operationValue.get('values');
        const valueIds = operationValue.get('valueIds');
        const attribute = getTaxonomyAttribute({ category, property, scale: scaleId });
        let value = Map({ scaleId, values, valueIds });

        function filterValueIds(valueId) {
          return attribute.hasIn(['options', 'byId', valueId]);
        }

        function getOptionLabel(valueId) {
          return attribute.getIn(['options', 'byId', valueId, 'name']);
        }

        if (attribute.get('type') === TAXONOMY_ATTRIBUTE.SCALED) {
          if (scaleId !== null && attribute.hasIn(['scales', 'byId', scaleId])) {
            return;
          }
        } else {
          if (getSize(valueIds)) {
            value = value.set('valueIds', valueIds.filter(filterValueIds))
            ;

            if (getSize(value.get('valueIds'))) {
              if (getSize(value.get('valueIds')) !== getSize(valueIds)) {
                value = value.set('values', valueIds.map(getOptionLabel));
              }
            } else {
              return;
            }
          } else if (attribute.get('required')) {
            return;
          }
        }

        const taxonomyAttributes = product
          .getIn([DETAILS, TAXONOMY_ATTRIBUTES])
          .set(property, Map({ scaleId, values, valueIds }));

        const updatedProduct = setTaxonomyAttributesErrors({
          channel: ETSY,
          product: product.setIn([DETAILS, TAXONOMY_ATTRIBUTES], taxonomyAttributes),
        });

        if (!updatedProduct.getIn([DETAILS, TAXONOMY_ATTRIBUTES, ERROR])) {
          product = updatedProduct;
        }
      });

      break;
    }

    case OPERATIONS[ETSY].SECTION:
    case OPERATIONS[SHOPIFY].PRODUCT_TYPE:
    case OPERATIONS[SHOPIFY].VENDOR: {
      const type = getAttributeByOperation(operationType);
      const value = operation.get(VALUE);
      product = product.setIn([DETAILS, type, VALUE], value);
      break;
    }

    case OPERATIONS[ETSY].IS_SUPPLY: {
      const value = operation.get(VALUE);
      product = product.setIn([DETAILS, IS_SUPPLY, VALUE], value);
      product = setIsSupplyErrors({ channel: ETSY, product });
      product = setProductionPartnersErrors({ channel: ETSY, product });
      break;
    }

    case OPERATIONS[ETSY].WHEN_MADE: {
      const value = operation.get(VALUE);
      product = product.setIn([DETAILS, WHEN_MADE, VALUE], value);
      product = setWhenMadeErrors({ channel: ETSY, product });
      product = setProductionPartnersErrors({ channel: ETSY, product });
      product = setFilesErrors({ channel: ETSY, product });

      break;
    }

    case OPERATIONS[ETSY].WHO_MADE: {
      const value = operation.get(VALUE);
      product = product.setIn([DETAILS, WHO_MADE, VALUE], value);
      product = setWhoMadeErrors({ channel: ETSY, product });
      product = setProductionPartnersErrors({ channel: ETSY, product });
      break;
    }

    case OPERATIONS[ETSY].PRODUCTION_PARTNERS: {
      const value = operation.get(VALUE);
      const isSupply = product.getIn([DETAILS, IS_SUPPLY, VALUE]);
      const whenMade = product.getIn([DETAILS, WHEN_MADE, VALUE]);
      const whoMade = product.getIn([DETAILS, WHO_MADE, VALUE]);
      const type = getProductionPartnersType({ isSupply, whenMade, whoMade });

      if (type === PRODUCTION_PARTNERS_TYPE.UNAVAILABLE) {
        product = setProductionPartnersErrors({
          channel: ETSY,
          product: product.setIn([DETAILS, PRODUCTION_PARTNERS, VALUE], List()),
        });

        break;
      }

      const updatedProduct = setProductionPartnersErrors({
        channel: ETSY,
        product: product.setIn([DETAILS, PRODUCTION_PARTNERS, VALUE], value),
      });

      if (!updatedProduct.getIn([DETAILS, PRODUCTION_PARTNERS, ERROR])) {
        product = updatedProduct;
      }

      break;
    }

    case OPERATIONS[SHOPIFY].COLLECTIONS: {
      const collections = List(operation.get(VALUE)).filter(isTruthy);
      product = product.setIn([DETAILS, COLLECTIONS, VALUE], collections);
      break;
    }

    default: {
      break;
    }
  }

  return { confirmation, actions, product };
}
