import { Set } from 'immutable';

import { isTrue, isTruthy } from '../utils/bool';

import { DEFAULTS, SEPARATOR } from '.';

function makeWhitelist(featureFlag) {
  switch (featureFlag) {
    case 'all':
    case 'none': {
      return featureFlag;
    }

    default: {
      return Set((featureFlag || DEFAULTS.EMPTY_STRING).split(SEPARATOR.COMMA).filter(isTruthy));
    }
  }
}

export const BULK_PHOTO_EDITOR_ENABLED = isTrue(process.env.FEATURE_FLAG_BULK_PHOTO_EDITOR);

export const CANVA_ENABLED = isTrue(process.env.FEATURE_FLAG_CANVA);
export const CANVA_USERS = makeWhitelist(process.env.FEATURE_FLAG_CANVA_USERS);

export const CSV_EXPORT_ENABLED = isTrue(process.env.FEATURE_FLAG_CSV_EXPORT);
export const CSV_EXPORT_USERS = makeWhitelist(process.env.FEATURE_FLAG_CSV_EXPORT_USERS);

export const CSV_IMPORT_ENABLED = isTrue(process.env.FEATURE_FLAG_CSV_IMPORT);
export const CSV_IMPORT_USERS = makeWhitelist(process.env.FEATURE_FLAG_CSV_IMPORT_USERS);

export const CSV_OVERRIDE_ENABLED = isTrue(process.env.FEATURE_FLAG_CSV_OVERRIDE);
export const CSV_OVERRIDE_USERS = makeWhitelist(process.env.FEATURE_FLAG_CSV_OVERRIDE_USERS);

export const DELETE_SHOP_ENABLED = isTrue(process.env.FEATURE_FLAG_DELETE_SHOP);

export const EBAY_INTEGRATION_ENABLED = isTrue(process.env.FEATURE_FLAG_EBAY_INTEGRATION);
export const EBAY_INTEGRATION_USERS = makeWhitelist(process.env.FEATURE_FLAG_EBAY_INTEGRATION_USERS);

export const FILES_TO_S3_ENABLED = isTrue(process.env.FEATURE_FLAG_FILES_TO_S3);
export const FILES_TO_S3_USERS = makeWhitelist(process.env.FEATURE_FLAG_FILES_TO_S3_USERS);

export const SALES_PROFILES_ENABLED = isTrue(process.env.FEATURE_FLAG_SALES_PROFILES);
export const SALES_PROFILES_SHARDS = makeWhitelist(process.env.FEATURE_FLAG_SALES_PROFILES_SHARDS);
export const SALES_PROFILES_SHOPS = makeWhitelist(process.env.FEATURE_FLAG_SALES_PROFILES_SHOPS);

export const SEO_URL_HANDLE_ENABLED = isTrue(process.env.FEATURE_FLAG_SEO_URL_HANDLE);
export const SEO_URL_HANDLE_USERS = makeWhitelist(process.env.FEATURE_FLAG_SEO_URL_HANDLE_USERS);

export const SHOPIFY_INVENTORY_POLICY_ENABLED = isTrue(process.env.FEATURE_FLAG_SHOPIFY_INVENTORY_POLICY);
export const SHOPIFY_INVENTORY_POLICY_USERS = makeWhitelist(process.env.FEATURE_FLAG_SHOPIFY_INVENTORY_POLICY_USERS);

export const UI_UPDATES_AUGUST_ENABLED = isTrue(process.env.FEATURE_FLAG_UI_UPDATES_AUGUST);
export const UI_UPDATES_AUGUST_USERS = makeWhitelist(process.env.FEATURE_FLAG_UI_UPDATES_AUGUST_USERS);

export default {
  BULK_PHOTO_EDITOR_ENABLED,
  CANVA_ENABLED,
  CANVA_USERS,
  CSV_EXPORT_ENABLED,
  CSV_EXPORT_USERS,
  CSV_IMPORT_ENABLED,
  CSV_IMPORT_USERS,
  CSV_OVERRIDE_ENABLED,
  CSV_OVERRIDE_USERS,
  DELETE_SHOP_ENABLED,
  EBAY_INTEGRATION_ENABLED,
  EBAY_INTEGRATION_USERS,
  FILES_TO_S3_ENABLED,
  FILES_TO_S3_USERS,
  SALES_PROFILES_ENABLED,
  SALES_PROFILES_SHARDS,
  SALES_PROFILES_SHOPS,
  SEO_URL_HANDLE_ENABLED,
  SEO_URL_HANDLE_USERS,
  SHOPIFY_INVENTORY_POLICY_ENABLED,
  SHOPIFY_INVENTORY_POLICY_USERS,
  UI_UPDATES_AUGUST_ENABLED,
  UI_UPDATES_AUGUST_USERS,
};
