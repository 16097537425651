import { Map } from 'immutable';

import { DEFAULTS, UNITS } from '../../constants';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { VALUE } from '../../constants/product';
import {
  BARCODE,
  CAP,
  CHARGE_TAX,
  CONTINUE_SELLING,
  COUNTRY_CODE,
  CPI,
  DEFAULT_VALUE,
  HS_CODE,
  PHYSICAL,
  PRICE,
  QUANTITY,
  SKU,
  TRACK_QUANTITY,
  UNIT,
  WEIGHT,
} from '../../constants/attributes';

export function getInventoryOperationValue({
  channel,
  combination = null,
  offeringId = null,
  inventory = Map(),
  toImmutable = true,
  type,
  value: source,
}) {
  function wrapOperationValue(operationValue) {
    return toImmutable
      ? Map(operationValue)
      : operationValue;
  }

  function getPriceOperationValue(value) {
    return wrapOperationValue({ rounding: DEFAULTS.EMPTY_STRING, value });
  }

  switch (channel) {
    case ETSY: {
      const value = source === undefined
        ? inventory.getIn([type, VALUE])
        : source;

      return wrapOperationValue({
        combination,
        offeringId,
        value: type === PRICE
          ? getPriceOperationValue(value)
          : value,
      });
    }

    case SHOPIFY: {
      const barcode = inventory.getIn([BARCODE, VALUE]) || DEFAULTS.NULL;
      const cap = inventory.getIn([CAP, VALUE]) || DEFAULTS.ZERO_CENT;
      const countryCode = inventory.getIn([COUNTRY_CODE, VALUE]) || DEFAULTS.NULL;
      const cpi = inventory.getIn([CPI, VALUE]) || DEFAULTS.ZERO_CENT;
      const hsCode = inventory.getIn([HS_CODE, VALUE]) || DEFAULTS.NULL;
      const inventoryPolicy = inventory.getIn([CONTINUE_SELLING, VALUE]) ? 'continue' : 'deny';
      const inventoryManagement = inventory.getIn([TRACK_QUANTITY, VALUE]) ? SHOPIFY : DEFAULTS.NULL;
      const price = inventory.getIn([PRICE, VALUE]) || DEFAULTS.ZERO_CENT;
      const quantity = inventory.getIn([QUANTITY, VALUE]) || DEFAULTS.ZERO;
      const sku = inventory.getIn([SKU, VALUE]) || DEFAULTS.NULL;
      const weight = inventory.getIn([WEIGHT, VALUE]) || DEFAULTS.ZERO;
      const unit = inventory.getIn([UNIT, VALUE]) || UNITS[0];
      const chargeTax = inventory.getIn([CHARGE_TAX, VALUE]);
      const taxable = typeof chargeTax === 'boolean' ? chargeTax : DEFAULTS.TRUE;
      const physical = inventory.getIn([PHYSICAL, VALUE]);
      const requiresShipping = typeof physical === 'boolean' ? physical : DEFAULTS.TRUE;
      const operationValue = inventory.get(DEFAULT_VALUE)
        .setIn(['variants', 0, 'shopify_barcode'], barcode)
        .setIn(['variants', 0, 'shopify_compare_at_price'], cap)
        .setIn(['variants', 0, 'shopify_cost'], cpi)
        .setIn(['variants', 0, 'shopify_country_code_of_origin'], countryCode)
        .setIn(['variants', 0, 'shopify_harmonized_system_code'], hsCode)
        .setIn(['variants', 0, 'shopify_inventory_management'], inventoryManagement)
        .setIn(['variants', 0, 'shopify_inventory_policy'], inventoryPolicy)
        .setIn(['variants', 0, 'shopify_inventory_quantity'], quantity)
        .setIn(['variants', 0, 'shopify_price'], price)
        .setIn(['variants', 0, 'shopify_requires_shipping'], requiresShipping)
        .setIn(['variants', 0, 'shopify_sku'], sku)
        .setIn(['variants', 0, 'shopify_taxable'], taxable)
        .setIn(['variants', 0, 'shopify_weight'], weight)
        .setIn(['variants', 0, 'shopify_weight_unit'], unit);

      return toImmutable
        ? operationValue
        : operationValue.toJS();
    }

    default: {
      return undefined;
    }
  }
}
