export const TEMPLATE = {
  BASIC: 'Basic',
  CODY: 'Cody',
  CONTRAST: 'Contrast',
  DILATED: 'Dilated',
  MARBLE: 'Marble',
  ROUNDED: 'Rounded',
  VERSO: 'Verso',
};

export const MEDIA = {
  FACEBOOK: 'Facebook',
  PINTEREST: 'Pinterest',
  X: 'X',
};

export const THEME = {
  DARK: 'dark',
  LIGHT: 'light',
};

export const CHANNEL_ICON_RATIO = {
  Amazon: 65 / 45,
  eBay: 54 / 45,
  Etsy: 45 / 45,
  Faire: 97 / 45,
  Shopify: 79 / 45,
  Squarespace: 117 / 45,
  WIX: 54 / 45,
  WooCommerce: 49 / 45,
};
