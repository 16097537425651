import request from './request';

import { CHANNEL_ID } from '../../constants/channels';
import { ENDPOINT } from '../../constants/api';

export default {
  getCounts({ db, shopId, signal }) {
    return request.get({
      endpoint: ENDPOINT.SCHEDULED_UPDATES,
      signal,
      url: `/counters/${db}/${shopId}`,
    });
  },
  getUpdates({ db, limit, offset, shopId, signal, status }) {
    return request.get({
      endpoint: ENDPOINT.SCHEDULED_UPDATES,
      params: { limit, offset, status },
      signal,
      url: `/${db}/${shopId}`,
    });
  },
  create({ channel, db, operations, shopId, startDate }) {
    return request.post({
      endpoint: ENDPOINT.SCHEDULED_UPDATES,
      payload: {
        userDb: db,
        shopId: parseInt(shopId, 10),
        channelId: CHANNEL_ID[channel],
        operations,
        startDate,
      },
      url: '',
    });
  },
  delete({ db, shopId, updateId }) {
    return request.delete({
      endpoint: ENDPOINT.SCHEDULED_UPDATES,
      url: `/${db}/${shopId}/${updateId}`,
    });
  },
};
