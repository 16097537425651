import { Map } from 'immutable';

import { reduce } from './iterable/reduce';

export function toLowerCase(string) {
  return typeof string?.toLowerCase === 'function' ? string.toLowerCase() : string;
}

export function toUpperCase(string) {
  return typeof string?.toUpperCase === 'function' ? string.toUpperCase() : string;
}

export function toString(value) {
  return typeof value?.toString === 'function' ? value.toString() : value;
}

export function trim(value) {
  return typeof value?.trim === 'function' ? value.trim() : value;
}

export function capitalize(string, everyWord = false) {
  const result = String(string);
  const pattern = everyWord
    ? RegExp('(^\\w|\\s+\\w)', 'g')
    : RegExp('(^\\w|\\.\\s+\\w)', 'g');

  return result
    ? result.replace(pattern, toUpperCase)
    : string;
}

export function escapeRegex(string) {
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function toCamelCase(key) {
  function callback(match, index) {
    return index === 0
      ? toLowerCase(match)
      : capitalize(toLowerCase(match.substring(1)));
  }

  const value = String(key);

  return value
    ? value.replace(/[-_][a-z0-9]+/ig, callback)
    : key;
}

export function toSnakeCase(key) {
  function callback(match, index) {
    return index === 0
      ? toLowerCase(match)
      : `_${toLowerCase(match)}`;
  }

  const value = String(key);

  return value
    ? value.replace(/[A-Z0-9]+/g, callback)
    : key;
}

export function keysToCamelCase(instance) {
  function mapCallback(result, value, key) {
    return result.set(toCamelCase(key), keysToCamelCase(value));
  }

  function objectCallback(result, value, key) {
    result[toCamelCase(key)] = keysToCamelCase(value);
    return result;
  }

  return Map.isMap(instance)
    ? instance.reduce(mapCallback, Map())
    : instance instanceof Object
      ? reduce(instance, Array.isArray(instance) ? [] : {}, objectCallback)
      : instance;
}

export function trimLeadingSpace(value) {
  return value.replace(/^\s+/g, '');
}

export function snakify(str) {
  if (!str) return null;
  return str
    .replace(/[^\w]/g, '_')
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match) => {
      if (+match === 0) return '';
      return match.toLowerCase();
    })
    .replace(/^_+|_+(?=_)|_+$/gi, '');
}

export function isEmptyOrNull(string) {
  return !string || (typeof string === 'string' && string.trim() === 'null');
}
