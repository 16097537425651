import { getProductionPartnersType } from '../product/checkAttribute';
import { usedMoreThanAllowed } from './utils';

import { ABOUT_LIST, PRODUCTION_PARTNERS_TYPE } from '../../constants/product';
import { IS_SUPPLY, WHEN_MADE, WHO_MADE } from '../../constants/attributes';
import { ETSY } from '../../constants/channels';
import {
  ERRORS,
  MAX_PRODUCT_TYPE_LENGTH,
  MAX_SECTION_LENGTH,
  MAX_VENDOR_LENGTH,
} from '../../constants/validations';

export function getWhoMadeError({ channel, value }) {
  switch (channel) {
    case ETSY: {
      return (
        !ABOUT_LIST[channel][WHO_MADE].includes(value) &&
        ERRORS.DETAILS.ABOUT[WHO_MADE]
      );
    }

    default: {
      return false;
    }
  }
}

export function getIsSupplyError({ channel, value }) {
  switch (channel) {
    case ETSY: {
      return (
        !ABOUT_LIST[channel][IS_SUPPLY].includes(value) &&
        ERRORS.DETAILS.ABOUT[IS_SUPPLY]
      );
    }

    default: {
      return false;
    }
  }
}

export function getWhenMadeError({ channel, value }) {
  switch (channel) {
    case ETSY: {
      return (
        !ABOUT_LIST[channel][WHEN_MADE].includes(value) &&
        ERRORS.DETAILS.ABOUT[WHEN_MADE]
      );
    }

    default: {
      return false;
    }
  }
}

export function getCategoryError({ channel, value }) {
  switch (channel) {
    case ETSY: {
      return !parseInt(value, 10) && ERRORS.DETAILS.CATEGORY;
    }

    default: {
      return false;
    }
  }
}

export function getSectionError(value) {
  if (!value) {
    return false;
  } else if (value.length > MAX_SECTION_LENGTH) {
    return ERRORS.DETAILS.SECTION.LENGTH_EXCEEDED;
  } else if (/[\^`]/.test(value)) {
    return ERRORS.DETAILS.SECTION.FORBIDDEN_CHARACTERS;
  } else if (usedMoreThanAllowed({ patterns: ['\\b\\p{Lu}{2,}\\b'], number: 3, value })) {
    return ERRORS.DETAILS.SECTION.CAPITALIZED_WORDS_NUMBER_EXCEEDED;
  } else if (usedMoreThanAllowed({ patterns: ['[&]', '[%]', '[\:]', '[\u0026]', '[\u0025]', '[\u003A]'], number: 1, value })) {
    return ERRORS.DETAILS.SECTION.SPECIAL_CHARACTERS_NUMBER_EXCEEDED;
  } else {
    return false;
  }
}

export function getProductTypeError(value) {
  return value && value.length > MAX_PRODUCT_TYPE_LENGTH && ERRORS.DETAILS.PRODUCT_TYPE;
}

export function getVendorError(value) {
  return value && value.length > MAX_VENDOR_LENGTH && ERRORS.DETAILS.VENDOR;
}

export function getProductionPartnersError({ isSupply, productionPartners, whenMade, whoMade }) {
  const type = getProductionPartnersType({ isSupply, whenMade, whoMade });

  if (type === PRODUCTION_PARTNERS_TYPE.REQUIRED && !productionPartners?.size) {
    return ERRORS.DETAILS.PRODUCTION_PARTNERS.REQUIRED;
  }

  return false;
}
