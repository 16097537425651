import ActionConstants, { Actions } from '../constants/actions';

const profilesConstants = new Actions('Profiles')
  .addAsync('apply')
  .add('bootstrap')
  .add('clean_up')
  .addAsync('copy')
  .addAsync('disconnect_listing')
  .add('edit')
  .add('edit_template')
  .add('filter')
  .addAsync('get_listings')
  .addAsync('get_profiles')
  .addAsync('remove')
  .addAsync('save')
  .add('search')
  .add('set_modal')
  .add('set_page')
  .add('stop_waiting_for_listings')
  .add('toggle_template')
  .add('wait_for_upload_and_save');

ActionConstants.add(profilesConstants);

export default profilesConstants.actionCreators();
