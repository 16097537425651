import { clearErrors } from '../../errors';

import { DIGITAL, RETURN_POLICY, SHIPPING } from '../../../constants/attributes';
import { SECTIONS, VALUE } from '../../../constants/product';

const { DETAILS } = SECTIONS;

export default function returnPolicy({ actions, operation, product }) {
  return {
    actions,
    product: product.getIn([DETAILS, DIGITAL, VALUE])
      ? product
      : clearErrors({
        errors: [RETURN_POLICY],
        path: SHIPPING,
        product: product.setIn([SHIPPING, RETURN_POLICY, VALUE], operation.get('value')),
      }),
  };
}
