import { isImmutable } from 'immutable';

import { isObject } from '../isObject';

export function reduce(instance, initialState, callback) {
  if (!instance) return initialState;

  let result = initialState;

  if (isImmutable(instance)) {
    let index = 0;
    result = instance.reduce(
      function reduceImmutable(reduction, value, key) {
        return callback(reduction, value, key, index++);
      },
      initialState
    );
  } else if (Array.isArray(instance) || instance instanceof NodeList) {
    for (let index = 0; index < instance.length; index++) {
      result = callback(result, instance[index], index);
    }
  } else if (isObject(instance)) {
    let index = 0;

    for (const key in instance) {
      if (instance.hasOwnProperty(key)) {
        result = callback(result, instance[key], key, index);
        index += 1;
      }
    }
  }

  return result;
}
