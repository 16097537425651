import ActionConstants, { Actions } from '../constants/actions';

const scheduledUpdatesConstants = new Actions('ScheduledUpdates')
  .add('bootstrap')
  .add('clean_up')
  .addAsync('delete_updates')
  .addAsync('get_next_scheduled')
  .addAsync('load_updates')
  .add('set_page')
  .add('set_status')
  .add('show_sync_modal')
  .add('toggle_update');

ActionConstants.add(scheduledUpdatesConstants);

export default scheduledUpdatesConstants.actionCreators();
