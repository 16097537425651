import request from './request';

import { CONTENT_TYPE, ENDPOINT, HEADERS } from '../../constants/api';

export default {
  deleteSetting({ id, signal }) {
    return request.delete({
      endpoint: ENDPOINT.WEB_V2,
      signal,
      url: `/import/mapping/profile/${id}`,
    });
  },
  export({ db, payload, shopId }) {
    return request.post({
      endpoint: ENDPOINT.CSV,
      payload,
      params: { db },
      url: `/shops/${shopId}/export`,
    });
  },
  getSettings({ channel, db, shopId }) {
    return request.get({
      endpoint: ENDPOINT.WEB_V2,
      params: { db },
      url: `/import/mapping/profile/${channel}/${shopId}`,
    });
  },
  importFile({ payload }) {
    return request.post({
      endpoint: ENDPOINT.CSV,
      payload,
      url: `/csv/import`,
    });
  },
  saveSetting({ id, name, signal }) {
    return request.put({
      endpoint: ENDPOINT.WEB_V2,
      payload: { name },
      signal,
      url: `/import/mapping/profile/${id}`,
    });
  },
  uploadToS3({ data, db, shopId }) {
    return new Promise(function executor(resolve, reject) {
      request.get({
        endpoint: ENDPOINT.WEB_V2,
        params: { db },
        url: `/import/${shopId}/uploadUrl`,
      })
        .then(
          function onUploadURL({ fileKey, uploadUrl }) {
            request
              .put({ headers: { [HEADERS.CONTENT_TYPE]: CONTENT_TYPE.CSV }, payload: data, url: uploadUrl })
              .then(
                function onUploaded() {
                  const url = new URL(uploadUrl);
                  resolve({ key: fileKey, url: `${url.protocol}/${url.pathname}` });
                },
                reject,
              );
          },
          reject,
        );
    });
  },
};
