import Big from 'big.js';

import { isValidNumber } from './number';

import { DAY, HOUR, ORDINAL } from '../constants/time';

export function getActualDate() {
  return new Date();
}

export function setYear(date, year) {
  if (
    !(date instanceof Date) ||
    !Number.isFinite(year)
  ) {
    return undefined;
  }

  return new Date(new Date(date).setFullYear(year));
}

export function setMonth(date, month) {
  if (
    !(date instanceof Date) ||
    !Number.isFinite(month)
  ) {
    return undefined;
  }

  return new Date(new Date(date).setMonth(month));
}

export function setDay(date, day) {
  if (
    !(date instanceof Date) ||
    !Number.isFinite(day)
  ) {
    return undefined;
  }

  return new Date(new Date(date).setDate(day));
}

export function setHours(date, hours) {
  if (
    !(date instanceof Date) ||
    !Number.isFinite(hours)
  ) {
    return undefined;
  }

  return new Date(new Date(date).setHours(hours));
}

export function setMinutes(date, minutes) {
  if (
    !(date instanceof Date) ||
    !Number.isFinite(minutes)
  ) {
    return undefined;
  }

  return new Date(new Date(date).setMinutes(minutes));
}

export function setSeconds(date, seconds) {
  if (
    !(date instanceof Date) ||
    !Number.isFinite(seconds)
  ) {
    return undefined;
  }

  return new Date(new Date(new Date(date).setSeconds(seconds)).setMilliseconds(0));
}

export function setTime(date, hours = 0, minutes = 0, seconds = 0) {
  if (
    !(date instanceof Date) ||
    !Number.isFinite(hours) ||
    !Number.isFinite(minutes) ||
    !Number.isFinite(seconds)
  ) {
    return undefined;
  }

  return setSeconds(setMinutes(setHours(date, hours), minutes), seconds);
}

export function setDate(date, year, month, day) {
  if (
    !(date instanceof Date) ||
    !Number.isFinite(year) ||
    !Number.isFinite(month) ||
    !Number.isFinite(day)
  ) {
    return undefined;
  }

  return setDay(setMonth(setYear(date, year), month), day);
}

export function isToday(date) {
  if (!(date instanceof Date)) return undefined;

  const today = getActualDate();
  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
}

export function isBefore(date, dateToCompare) {
  if (
    !(date instanceof Date) ||
    !(dateToCompare instanceof Date)
  ) {
    return undefined;
  }

  return Big(date.getTime()).lt(Big(dateToCompare.getTime()));
}

export function isAfter(date, dateToCompare) {
  if (
    !(date instanceof Date) ||
    !(dateToCompare instanceof Date)
  ) {
    return undefined;
  }

  return Big(date.getTime()).gt(Big(dateToCompare.getTime()));
}

export function formatDate({ locale, options, date }) {
  return Intl.DateTimeFormat(locale, options).format(new Date(date));
}

export function getFormattedDate(date) {
  const locale = 'en-us';
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return formatDate({ locale, options, date });
}

export function getDateDifference(
  date,
  dateToCompare,
  years = true,
  months = true,
  days = true,
  hours = true,
  minutes = true,
) {
  if (
    !(date instanceof Date) ||
    !(dateToCompare instanceof Date)
  ) {
    return undefined;
  }

  const result = {
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  let difference = Big(date.getTime())
    .minus(dateToCompare.getTime())
    .div(1000)
    .round(0, 0);

  let firstDate = difference.s > 0
    ? dateToCompare
    : date;

  difference = difference.abs();

  result.seconds += difference.mod(60).toNumber();
  difference = difference.minus(result.seconds);

  if (!minutes) {
    result.seconds += difference.toNumber();
    return result;
  } else {
    difference = difference.div(60);
  }

  result.minutes += difference.mod(60).toNumber();
  difference = difference.minus(result.minutes);

  if (!hours) {
    result.minutes += difference.toNumber();
    return result;
  } else {
    difference = difference.div(60);
  }

  result.hours += difference.mod(24).toNumber();
  difference = difference.minus(result.hours);

  if (!days) {
    result.hours += difference.toNumber();
    return result;
  } else {
    difference = difference.div(24);
  }

  while (difference.gt(0)) {
    const month = firstDate.getMonth() + 1;
    const daysInMonth = new Date(firstDate.getYear(), month, 0).getDate();

    if (difference.gt(daysInMonth)) {
      if (!months) {
        result.days += daysInMonth;
      } else {
        result.months += 1;

        if (years && result.months === 12) {
          result.months = 0;
          result.years += 1;
        }
      }

      firstDate = setMonth(firstDate, month);
      difference = difference.minus(daysInMonth);
    } else {
      result.days += difference.toNumber();
      break;
    }
  }

  return result;
}

export function getDaysDifference(date, dateToCompare, daysToHours = 1, round = Big.roundDown) {
  if (
    !(date instanceof Date) ||
    !(dateToCompare instanceof Date)
  ) {
    return {};
  }

  const diff = Big(date.getTime()).minus(Big(dateToCompare.getTime())).abs();
  const days = diff.div(DAY).round(0, round);

  if (days.lte(Big(daysToHours))) {
    const hours = diff.div(HOUR).round(0, round);

    if (hours.lte(Big(daysToHours).times(24))) {
      return { hours: hours.toNumber() };
    }
  }

  return { days: days.toNumber() };
}

export function addDaySuffix(day) {
  if (!isValidNumber(day)) return day;

  function addSuffix(suffix) {
    return `${day}${suffix}`;
  }

  const number = parseInt(day, 10);

  if (number >= 10 && number <= 20) return addSuffix(ORDINAL.TH);

  switch (number % 10) {
    case 1: {
      return addSuffix(ORDINAL.ST);
    }

    case 2: {
      return addSuffix(ORDINAL.ND);
    }

    case 3: {
      return addSuffix(ORDINAL.RD);
    }

    default: {
      return addSuffix(ORDINAL.TH);
    }
  }
}
