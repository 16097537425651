/* eslint-disable no-console */
import { List, Map } from 'immutable';
import CryptoJS from 'crypto-js';

import { getUploadedPhotoError } from './validations/photos';
import { isTruthy } from './bool';
import api from './api/request';

import { DEFAULTS, SEPARATOR } from '../constants';
import { ENDPOINT, HEADERS } from '../constants/api';
import { IMAGE_TYPE } from '../constants/photos';
import { MESSAGE } from '../constants/notifications';
import { MIME } from '../constants/photoEditor';

const { CONTENT_TYPE, EXPIRES } = HEADERS;

export function arrangeUploadedPhotos(photos) {
  function reducePhotos(result, photo, index) {
    return result.set(index, photo);
  }

  return photos.reduce(reducePhotos, DEFAULTS.EMPTY_LIST).filter(isTruthy);
}

export function getImageTypeByUrl(url) {
  if (!url) {
    return null;
  } else if (
    /\.amazonaws\.com/.test(url) ||
    /library\.(getvela|vela-dev|vela-test)\.com/.test(url)
  ) {
    return IMAGE_TYPE.AWS_S3;
  } else if (/localhost|blob:http/.test(url)) {
    return IMAGE_TYPE.LOCAL;
  } else if (/images\.unsplash\.com/.test(url)) {
    return IMAGE_TYPE.UNSPLASH;
  } else if (/cdn\.shopify/.test(url)) {
    return IMAGE_TYPE.SHOPIFY;
  } else if (/(etsy|etsystatic)\.com/.test(url)) {
    return IMAGE_TYPE.ETSY;
  } else if (/\.ebayimg\.com/.test(url)) {
    return IMAGE_TYPE.EBAY;
  } else if (/\.canva\.com/.test(url)) {
    return IMAGE_TYPE.CANVA;
  } else {
    return null;
  }
}

export function makeThumbnailUrl(url, width) {
  if (!url || typeof url !== 'string' || /^data:image/.test(url) || !Number.isFinite(width)) {
    return url;
  }

  const type = getImageTypeByUrl(url);
  const scaledWidth = Math.floor(width * window.devicePixelRatio);

  switch (type) {
    case IMAGE_TYPE.ETSY: {
      return url.replace(/il_(fullxfull|\d+xN|\d+x\d+)/, `il_${scaledWidth}xN`);
    }

    case IMAGE_TYPE.SHOPIFY: {
      return url.replace(/(_(\d+x|\d+x\d+|x\d+|small|medium|large))?(?=\.(gif|jpg|jpeg|png|webp)(\?[^?]+)?$)/, `_${scaledWidth}x`);
    }

    case IMAGE_TYPE.UNSPLASH: {
      const size = scaledWidth.toString();
      const result = new URL(url);
      const sourceParams = new URLSearchParams(result.search);
      const resultParams = new URLSearchParams();
      resultParams.set('ixlib', sourceParams.get('ixlib'));
      resultParams.set('ixid', sourceParams.get('ixid'));
      resultParams.set('auto', 'format');
      resultParams.set('fit', 'crop');
      resultParams.set('q', '80');
      resultParams.set('w', size);
      resultParams.set('h', size);
      result.search = resultParams.toString();
      return result.toString();
    }

    default: {
      return url;
    }
  }
}

export function getMimeTypeFromBase64(base64) {
  let result = null;

  if (typeof base64 !== 'string') {
    return result;
  }

  const mime = base64.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

  if (mime && mime.length) {
    result = mime[1];
  }

  return result;
}

export function photosFromImagesById({ imagesById, imageIds }) {
  function mapPhotoIds(id) {
    return Map(imagesById[id]);
  }
  return List(imageIds).map(mapPhotoIds);
}

export function shapePhotoForApp({ channel, file, validate = true }) {
  return new Promise(function executor(resolve, reject) {
    const url = URL.createObjectURL(file);

    function onValidated() {
      const reader = new FileReader();

      function onReaderLoad() {
        const data = reader.result;
        const hash = CryptoJS.SHA1(CryptoJS.lib.WordArray.create(data)).toString();
        const photo = Map({ data, fullsize_url: url, hash, mime: file.type, thumbnail_url: url });
        resolve(photo);
      }

      reader.addEventListener('error', reject);
      reader.addEventListener('load', onReaderLoad);
      reader.readAsArrayBuffer(file);
    }

    if (validate) {
      getUploadedPhotoError({ channel, file, url }).then(onValidated).catch(reject);
    } else {
      onValidated();
    }
  });
}

export function uploadPhoto({ photo, signal }) {
  return new Promise(function executor(resolve, reject) {
    const data = photo.get('data');
    const hash = photo.get('hash');
    const mime = photo.get('mime', MIME.JPG);

    function onUrl({ uploadUrl: url }) {
      function onUpload() {
        const s3Url = `${ENDPOINT.AWS_S3_IMAGES}/${hash}`;
        resolve({ photo: photo.set('fullsize_url', s3Url).set('thumbnail_url', s3Url), signal });
      }

      if (url) {
        api
          .put({ url, payload: data, headers: { [CONTENT_TYPE]: mime, [EXPIRES]: 0 }})
          .then(onUpload)
          .catch(reject);
      } else if (url === null) {
        onUpload();
      } else {
        reject(MESSAGE.FAIL.UPLOAD_PHOTO);
      }
    }

    api.get({ params: { hash, mime }, url: '/images/uploadURL' }).then(onUrl).catch(reject);
  });
}

export function generateAltText({ photo, signal }) {
  return new Promise(function executor(resolve, reject) {
    const url = photo.get('fullsize_url');

    if (!url) {
      reject(MESSAGE.FAIL.GENERATE);
      return;
    }

    if (getImageTypeByUrl(url) === IMAGE_TYPE.LOCAL) {
      reject(MESSAGE.FAIL.GENERATE);
      return;
    }

    function onGenerated({ altText }) {
      if (signal.aborted) return;

      resolve({ photo: photo.set('alt_text', altText.trim().replace(/\s+/g, SEPARATOR.SPACE)), signal });
    }

    api
      .post({ endpoint: ENDPOINT.WEB_V2, payload: { image: url }, signal, url: '/ai/generate-alt-text' })
      .then(onGenerated)
      .catch(reject);
  });
}
