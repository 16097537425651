import { List } from 'immutable';

import { getSize } from '../iterable/getSize';

import { TRACK_QUANTITY } from '../../constants/attributes';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { INDIVIDUAL } from '../../constants/profiles';

export function updateIndividual(profile) {
  const channel = profile.get('channel');

  function areBothIndividual(individual, variations) {
    return getSize(individual) === getSize(variations);
  }

  function getIndividualIndexes(result, variation, variationIndex) {
    if (variation.get(INDIVIDUAL.SKU)) {
      result.individualSKUVariations = result.individualSKUVariations.push(variationIndex);
    }

    if (variation.get(INDIVIDUAL.PRICE)) {
      result.individualPriceVariations = result.individualPriceVariations.push(variationIndex);
    }

    if (variation.get(INDIVIDUAL.QUANTITY)) {
      result.individualQuantityVariations = result.individualQuantityVariations.push(variationIndex);
    }

    return result;
  }

  function setIndividual(flag) {
    return function map(variation) {
      return variation.set(flag, true);
    };
  }

  function getTrackQuantity(result, offering) {
    return result && offering.get(TRACK_QUANTITY);
  }

  switch (channel) {
    case ETSY: {
      let variations = profile.get('variations');
      let {
        individualSKUVariations,
        individualPriceVariations,
        individualQuantityVariations,
      } = variations.reduce(getIndividualIndexes, {
        individualSKUVariations: List(),
        individualPriceVariations: List(),
        individualQuantityVariations: List(),
      });

      const hasBothIndividual = (
        areBothIndividual(individualSKUVariations, variations) ||
        areBothIndividual(individualPriceVariations, variations) ||
        areBothIndividual(individualQuantityVariations, variations)
      );

      if (hasBothIndividual) {
        if (
          getSize(individualSKUVariations) &&
          !areBothIndividual(individualSKUVariations, variations)
        ) {
          individualSKUVariations = variations.keySeq().toList();
          variations = variations.map(setIndividual(INDIVIDUAL.SKU));
        }

        if (
          getSize(individualPriceVariations) &&
          !areBothIndividual(individualPriceVariations, variations)
        ) {
          individualPriceVariations = variations.keySeq().toList();
          variations = variations.map(setIndividual(INDIVIDUAL.PRICE));
        }

        if (
          getSize(individualQuantityVariations) &&
          !areBothIndividual(individualQuantityVariations, variations)
        ) {
          individualQuantityVariations = variations.keySeq().toList();
          variations = variations.map(setIndividual(INDIVIDUAL.QUANTITY));
        }
      }

      return profile
        .set('variations', variations)
        .set(INDIVIDUAL.BOTH_VARIATIONS, hasBothIndividual)
        .set(INDIVIDUAL.SKU, areBothIndividual(individualSKUVariations, variations))
        .set(INDIVIDUAL.PRICE, areBothIndividual(individualPriceVariations, variations))
        .set(INDIVIDUAL.QUANTITY, areBothIndividual(individualQuantityVariations, variations));
    }

    case SHOPIFY: {
      return profile.set(TRACK_QUANTITY, profile.get('offerings').reduce(getTrackQuantity, true));
    }

    default: {
      return profile;
    }
  }
}
