/* eslint-disable no-console */
import { List, Map } from 'immutable';
import { sideEffect } from 'redux-side-effects';

import {
  getIntervalsFromConfig,
  getPollingInterval,
  isInvalid,
  shapeShopsForApp,
  updateState,
} from '../utils/shops';
import { createController, createPolling } from '../utils/reducer';
import { getPlanName } from '../utils/billing';
import { getSize } from '../utils/iterable/getSize';
// DEV-676 Amplitude events trimming
// import amplitude from '../utils/tracking/amplitude';
import mixpanel from '../utils/tracking/mixpanel';
import api from '../utils/api';

import { POLLING_INTERVAL, STATUSES } from '../constants/shops';
import { MESSAGE, NOTIFICATION } from '../constants/notifications';
import { CHANNEL_NAME } from '../constants/channels';
import { MODALS } from '../constants/modal';
import { EVENT } from '../constants/tracking';
import ACTIONS from '../constants/actions';

import Reducers, { Reducer } from '../classes/Reducer';
import initialState from '../initialState';
import Actions from '../actions';

const polling = createPolling();
const controller = createController();

function* getShops(reduction, nextShopId) {
  let state = reduction;
  let previousShopId;
  const { signal } = controller.start();
  polling.stop();

  if (
    nextShopId && (
      !state.hasIn(['shops', 'current']) ||
      state.hasIn(['shops', 'byId', nextShopId])
    )
  ) {
    previousShopId = state.setIn(['shops', 'current']);
    state = state.setIn(['shops', 'current'], nextShopId);
  }

  yield sideEffect((dispatch) => {
    api
      .get({ url: `/shops/status`, signal })
      .then(
        (response) => dispatch(Actions.Shops.getShopsSucceeded({ previousShopId, response })),
        (error) => dispatch(Actions.Shops.getShopsFailed({ error, signal }))
      );
  });

  return state;
}

function* getShopsFailed(reduction, { error, signal }) {
  if (signal.aborted) return reduction;

  controller.stop();
  console.error(error);

  yield sideEffect((dispatch) => {
    dispatch(Actions.Shops.rescheduleShopsPolling());
  });

  return reduction;
}

function* getShopsSucceeded(reduction, { previousShopId, response }) {
  controller.stop();

  let state = reduction;

  if (state.hasIn(['user', 'userId'])) {
    if (state.getIn(['user', 'userId']) !== response.userId) {
      // got shops of another user, need to reload app
      yield sideEffect((dispatch) => {
        dispatch(Actions.User.getUserData());
      });

      return initialState.setIn(['auth', 'loggedIn'], state.getIn(['auth', 'loggedIn']));
    }
  } else {
    state = state.setIn(['user', 'userId'], response.userId);
  }

  let current = state.getIn(['shops', 'current']);
  const intervals = getIntervalsFromConfig(state);
  const { byId, options } = shapeShopsForApp(response);

  if (!getSize(byId)) {
    return state
      .setIn(['shops', 'byId'], Map())
      .setIn(['shops', 'options'], List());
  }

  if (
    (current && !byId.has(current)) ||
    (state.hasIn(['shops', 'options']) && !state.getIn(['shops', 'options']).equals(options))
  ) {
    const interval = intervals.short;

    yield sideEffect((dispatch) => {
      dispatch(Actions.User.getSubscriptions());

      function getStatus() {
        dispatch(Actions.Shops.getShops());
      }

      if (getSize(state.get('billing'))) {
        const shopInManage = state.getIn(['billing', 'manage', 'shopId']);

        if (shopInManage && !byId.has(shopInManage)) {
          dispatch(Actions.Billing.manageShop());
        }
      }

      polling.start(setTimeout(getStatus, interval));
    });

    if (!byId.has(current)) {
      current = options.get(0);
    }

    return state
      .deleteIn(['user', 'subscriptions'])
      .setIn(['shops', 'byId'], byId)
      .setIn(['shops', 'current'], current)
      .setIn(['shops', 'options'], options)
      .setIn(['shops', 'pollingInterval'], interval);
  } else if (!current) {
    current = options.get(0);
  }

  const currentShop = byId.get(current);
  const currentSyncStatus = currentShop.get('syncStatus');

  if (previousShopId) {
    yield sideEffect(() => {
      // DEV-676 Amplitude events trimming
      // amplitude.logEvent('Switched stores');
      const url = `/shops/${current}/updateShopVisitCount`;
      const payload = { shopId: current, maxShopVisits: 0 };
      const params = { db: currentShop.get('db') };
      api.post({ url, payload, params });
    });

    state = state.deleteIn(['shops', 'waitForSync']);
  }

  // DEV-676 Amplitude events trimming
  // if (
  //   currentSyncStatus !== STATUSES.INITIAL_SYNC &&
  //   state.getIn(['shops', 'byId', current, 'syncStatus']) === STATUSES.INITIAL_SYNC
  // ) {
  //   yield sideEffect(() => {
  //     amplitude.logEvent('Added shop');
  //   });
  // }

  if (
    isInvalid(currentSyncStatus) &&
    !isInvalid(state.getIn(['shops', 'byId', current, 'syncStatus']))
  ) {
    polling.stop();

    state = state
      .setIn(['shops', 'byId'], byId)
      .setIn(['shops', 'current'], current)
      .setIn(['shops', 'options'], options)
      .deleteIn(['shops', 'waitForSync'])
      .deleteIn(['shops', 'syncData', current])
      .deleteIn(['shops', STATUSES.REFRESH_USER, current]);

    state = state
      .deleteIn(['listings', 'placeholders', current])
      .deleteIn(['listings', 'products', 'processing', current]);

    return state;
  }

  const { actions, state: newState } = options.reduce(
    updateState({ current, shopsById: byId }),
    { actions: [], state },
  );

  state = newState;

  if (getSize(actions)) {
    yield sideEffect((dispatch) => {
      actions.forEach(dispatch);
    });
  }

  if (state.getIn(['shops', 'current']) !== current) {
    state = state.setIn(['shops', 'current'], current);
  }

  if (!byId.equals(state.getIn(['shops', 'byId']))) {
    state = state.setIn(['shops', 'byId'], byId);
  }

  if (!options.equals(state.getIn(['shops', 'options']))) {
    state = state.setIn(['shops', 'options'], options);
  }

  const interval = getPollingInterval({ currentShopId: current, intervals, state });

  if (interval !== state.getIn(['shops', 'pollingInterval'])) {
    state = state.setIn(['shops', 'pollingInterval'], interval);
  }

  yield sideEffect((dispatch) => {
    function getStatus() {
      dispatch(Actions.Shops.getShops());
    }

    polling.start(setTimeout(getStatus, interval));
  });

  return state;
}

function* refreshUserInitiatedShop(reduction, shopId) {
  if (!reduction.hasIn(['shops', 'byId', shopId])) {
    console.error(`Error: Cannot refresh shop, no shop data for shopId "${shopId}"`);
    return reduction;
  }

  const shop = reduction.getIn(['shops', 'byId', shopId]);
  const channel = shop.get('channel');
  const db = shop.get('db');

  yield sideEffect((dispatch) => {
    dispatch(
      Actions.Shops.setData({
        path: ['syncData', shopId],
        value: Map({ modal: MODALS.SYNC.REFRESH }),
      })
    );

    // DEV-676 Amplitude events trimming
    // amplitude.logEvent('Refresh Shop');

    mixpanel.track(
      EVENT.SHOP.REFRESH,
      {
        channel: CHANNEL_NAME[channel],
        plan: getPlanName(reduction.getIn(['user', 'subscriptions', shopId])),
        shop_id: shopId,
      }
    );

    api.shops
      .refresh({ channel, db, shopId })
      .then(
        () => dispatch(Actions.Shops.getShops()),
        (error) => dispatch(Actions.Shops.refreshUserInitiatedShopFailed({ error, shopId })),
      );
  });

  return reduction;
}

function* refreshUserInitiatedShopFailed(reduction, { error, shopId }) {
  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(Actions.Shops.setData({ path: ['syncData', shopId] }));
    dispatch(
      Actions.Notifications.add({
        message: MESSAGE.FAIL.REFRESH,
        type: NOTIFICATION.ERROR,
      })
    );
  });

  return reduction;
}

function* refreshUserShop(reduction, shopId) {
  yield sideEffect(() => {
    api.get({
      url: `/shops/${shopId}/refresh`,
      params: {
        userAction: STATUSES.REFRESH_USER,
        db: reduction.getIn(['shops', 'byId', shopId, 'db']),
      },
    });
  });

  return reduction.setIn(['shops', 'refreshing', shopId], true);
}

function* rescheduleShopsPolling(reduction, interval) {
  polling.stop();

  let state = reduction;
  const newInterval = (
    interval ||
    state.getIn(['shops', 'pollingInterval']) ||
    state.getIn(['user', 'config', 'shopsPollingIntervalLong'], POLLING_INTERVAL.LONG)
  );

  if (newInterval !== state.getIn(['shops', 'pollingInterval'])) {
    state = state.setIn(['shops', 'pollingInterval'], newInterval);
  }

  yield sideEffect((dispatch) => {
    function getStatus() {
      dispatch(Actions.Shops.getShops());
    }

    polling.start(setTimeout(getStatus, newInterval));
  });

  return state;
}

function* setData(reduction, { path, value }) {
  const fullPath = Array.isArray(path)
    ? ['shops', ...path]
    : ['shops', path];

  if (value) return reduction.setIn(fullPath, value);

  let state = reduction.deleteIn(fullPath);

  fullPath.pop();

  while (fullPath.length > 1) {
    if (!getSize(state.getIn(fullPath))) {
      state = state.deleteIn(fullPath);
    }

    fullPath.pop();
  }

  return state;
}

function* validateOauthToken(reduction, token) {
  yield sideEffect((dispatch) => {
    api
      .get({ url: '/getOauthFailedToken', params: { token }})
      .then(() => dispatch(Actions.Shops.setData({ path: 'importFailed', value: true })));
  });

  return reduction;
}

Reducers.add(
  new Reducer('Shops')
    .add(ACTIONS.SHOPS.GET_SHOPS, getShops)
    .add(ACTIONS.SHOPS.GET_SHOPS_FAILED, getShopsFailed)
    .add(ACTIONS.SHOPS.GET_SHOPS_SUCCEEDED, getShopsSucceeded)
    .add(ACTIONS.SHOPS.REFRESH_USER_INITIATED_SHOP, refreshUserInitiatedShop)
    .add(ACTIONS.SHOPS.REFRESH_USER_INITIATED_SHOP_FAILED, refreshUserInitiatedShopFailed)
    .add(ACTIONS.SHOPS.REFRESH_USER_SHOP, refreshUserShop)
    .add(ACTIONS.SHOPS.RESCHEDULE_SHOPS_POLLING, rescheduleShopsPolling)
    .add(ACTIONS.SHOPS.SET_DATA, setData)
    .add(ACTIONS.SHOPS.VALIDATE_OAUTH_TOKEN, validateOauthToken)
);
