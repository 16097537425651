import ActionConstants, { Actions } from '../constants/actions';

const bulkPhotoEditorConstants = new Actions('Bulk_Photo_Editor')
  .addAsync('add_background')
  .add('close')
  .addAsync('get_photos')
  .addAsync('remove_background')
  .add('restore_originals')
  .addAsync('save')
  .add('select_all')
  .add('set_loading')
  .add('start_editing')
  .add('toggle_photo');

ActionConstants.add(bulkPhotoEditorConstants);

export default bulkPhotoEditorConstants.actionCreators();
