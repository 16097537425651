import { get } from './iterable/get';

export function getFromTheme(path) {
  return function mapTheme({ theme }) {
    return get(path)(theme);
  };
}

export function url(source) {
  return `url(${source})`;
}
