import { generatePath } from 'react-router-dom';

import { CHANNEL_STATUS_MAP, STATUS } from '../../constants/listings';
import { PARAMS, ROUTES } from '../../constants/routes';

const { ROOT, SHOP: { LISTINGS, ROOT: SHOP }} = ROUTES;

export function getListingsPath(state) {
  const shopId = state.getIn(['shops', 'current']);

  if (!shopId) return ROOT;

  const channel = state.getIn(['shops', 'byId', shopId, 'channel']);
  const status = state.getIn(['listings', 'status']);
  const path = `${SHOP}${LISTINGS.ROOT}${LISTINGS.STATUS}`;
  const params = {
    [PARAMS.SHOP_ID]: shopId,
    [PARAMS.STATUS]: CHANNEL_STATUS_MAP[channel][status] || status || STATUS.ACTIVE,
  };

  return generatePath(path, params);
}
