import XRegExp from 'xregexp';

import { tagsArrayFromString } from '../tags';
import { getSize } from '../iterable/getSize';

import { ERRORS, MAX_MATERIAL_LENGTH, MAX_NUMBER_OF_MATERIALS } from '../../constants/validations';
import { ETSY } from '../../constants/channels';

export function getMaterialError({ channel, material }) {
  function onlyAllowedCharacters() {
    switch (channel) {
      case ETSY: {
        return (
          XRegExp('[^\\p{L}\\p{Nd}\\p{Zs}]').test(material) &&
          ERRORS.MATERIALS.FORBIDDEN_CHARACTERS[channel]
        );
      }

      default: {
        return false;
      }
    }
  }

  return material.length > MAX_MATERIAL_LENGTH[channel]
    ? ERRORS.MATERIALS.LENGTH_EXCEEDED[channel]
    : onlyAllowedCharacters({ channel, material });
}

export function getMaterialsError({ channel, materials }) {
  function reduceMaterials(result, material) {
    return result || getMaterialError({ channel, material });
  }

  const size = getSize(materials);

  if (size > MAX_NUMBER_OF_MATERIALS[channel]) {
    return `${size - MAX_NUMBER_OF_MATERIALS[channel]} over limit`;
  }

  return materials.reduce(reduceMaterials, false);
}

export function getMaterialsInputError({ channel, value = '' }) {
  return getMaterialsError({ channel, materials: tagsArrayFromString(value) });
}
