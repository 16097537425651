import { DEFAULTS } from '../../constants';
import { reduceErrors } from '../errors';

export function areComplete(products) {
  function reduceProducts(isComplete, item) {
    return isComplete && item.get('isComplete', DEFAULTS.TRUE);
  }

  return products.reduce(reduceProducts, DEFAULTS.TRUE);
}

export function setIsComplete(product) {
  return product.has('errors')
    ? product.set('isComplete', !reduceErrors(product.get('errors')))
    : product;
}
