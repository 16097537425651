import { CROSS_ORIGIN } from '../../../constants/photoEditor';
import { MESSAGE } from '../../../constants/notifications';

export function waitForFontToLoad() {
  return new Promise(function executor(resolve) {
    function check() {
      if (!document.fonts || document.fonts.status === 'loaded') {
        resolve();
      } else {
        setTimeout(check, 500);
      }
    }

    check();
  });
}

export function createImageElement({ src, ...attributes }) {
  return new Promise(function executor(resolve, reject) {
    const image = new Image();

    image.crossOrigin = CROSS_ORIGIN.ANONYMOUS;

    for (const attribute in attributes) {
      if (attributes.hasOwnProperty(attribute)) {
        image[attribute] = attributes[attribute];
      }
    }

    image.addEventListener('error', function onError() {
      reject(MESSAGE.FAIL.LOAD_IMAGE);
    });

    image.addEventListener('load', function onLoad() {
      resolve(image);
    });

    image.src = src;
  });
}
