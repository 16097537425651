import ActionConstants, { Actions } from '../constants/actions';

const dataConstants = new Actions('Data')
  .add('add_product_type')
  .add('add_section')
  .add('add_vendor')
  .add('get_all_data')
  .addAsync('get_channel_data')
  .addAsync('get_profiles')
  .addAsync('get_shop_data')
  .add('set_data');

ActionConstants.add(dataConstants);

export default dataConstants.actionCreators();
