import { clearErrors, clearProfileOfferingsErrors, reduceErrors } from '../../errors';
import { getError, getNumberValue, getPriceValue } from '..';
import { escapeRegex } from '../../string';
import { getSize } from '../../iterable/getSize';
import { void0 } from '../../void0';

import { OPERATIONS, PRICE_TYPE } from '../../../constants/bulkEdit';
import { ERROR, SECTIONS, VALUE } from '../../../constants/product';
import { ETSY, SHOPIFY } from '../../../constants/channels';
import { DEFAULTS } from '../../../constants';
import {
  BARCODE,
  CAP,
  CHARGE_TAX,
  CONTINUE_SELLING,
  COUNTRY_CODE,
  CPI,
  HS_CODE,
  PHYSICAL,
  PRICE,
  QUANTITY,
  SKU,
  TRACK_QUANTITY,
  UNIT,
  VARIATIONS,
  WEIGHT,
} from '../../../constants/attributes';

const { EMPTY_STRING, FALSE, NULL, TRUE, ZERO, ZERO_CENT } = DEFAULTS;
const { INVENTORY } = SECTIONS;
const NO_CHANGES = 'no_changes';

function getAttributeByOperation(operationType) {
  if (/^barcode/.test(operationType)) {
    return BARCODE;
  }

  if (/^comapreAtPrice/.test(operationType)) {
    return CAP;
  }

  if (/^cost/.test(operationType)) {
    return CPI;
  }

  if (/^countryCode/.test(operationType)) {
    return COUNTRY_CODE;
  }

  if (/^hsCode/.test(operationType)) {
    return HS_CODE;
  }

  if (/^price/.test(operationType)) {
    return PRICE;
  }

  if (/^sku/.test(operationType)) {
    return SKU;
  }

  return undefined;
}

function inlineEdit({ channel, operation, product: source }) {
  const hasVariations = getSize(source.getIn([VARIATIONS, 'offerings'])) > 0;

  function updateInventory({ field, product, value }) {
    function updatePriceValue({ type }) {
      const initial = product.getIn([INVENTORY, type, VALUE]) || ZERO_CENT;
      const updated = getPriceValue({
        absolute: true,
        operationType: 'set',
        operationValue: value,
        productValue: initial,
      });

      if (getError({ channel, type, value: updated })) return product;

      return clearErrors({
        errors: [type],
        path: INVENTORY,
        product: product.setIn([INVENTORY, type, VALUE], updated),
      });
    }

    switch (field) {
      case 'shopify_barcode': {
        if (getError({ channel, type: BARCODE, value })) return product;

        return clearErrors({
          errors: [BARCODE],
          path: INVENTORY,
          product: product.setIn([INVENTORY, BARCODE, VALUE], value),
        });
      }

      case 'shopify_compare_at_price': {
        return updatePriceValue({ type: CAP });
      }

      case 'shopify_cost': {
        return updatePriceValue({ type: CPI });
      }

      case 'shopify_country_code_of_origin': {
        return product.setIn([INVENTORY, COUNTRY_CODE, VALUE], value || NULL);
      }

      case 'shopify_harmonized_system_code': {
        return product.setIn([INVENTORY, HS_CODE, VALUE], value || NULL);
      }

      case 'shopify_inventory_management': {
        if (value === SHOPIFY) {
          if (getError({ channel, type: QUANTITY, value: product.getIn([INVENTORY, QUANTITY, VALUE]) })) return product;

          return clearErrors({
            errors: [QUANTITY],
            path: INVENTORY,
            product: product.setIn([INVENTORY, TRACK_QUANTITY, VALUE], TRUE),
          });
        }

        return clearErrors({
          errors: [QUANTITY],
          path: INVENTORY,
          product: product.setIn([INVENTORY, TRACK_QUANTITY, VALUE], FALSE),
        });
      }

      case 'shopify_inventory_policy': {
        if (!product.getIn([INVENTORY, TRACK_QUANTITY, VALUE])) return product;

        return product.setIn([INVENTORY, CONTINUE_SELLING, VALUE], value === 'continue');
      }

      case 'shopify_inventory_quantity': {
        if (!product.getIn([INVENTORY, TRACK_QUANTITY, VALUE])) return product;

        if (getError({ channel, type: QUANTITY, value })) return product;

        return clearErrors({
          errors: [QUANTITY],
          path: INVENTORY,
          product: product.setIn([INVENTORY, QUANTITY, VALUE], value),
        });
      }

      case 'shopify_price': {
        return updatePriceValue({ type: PRICE });
      }

      case 'shopify_requires_shipping': {
        if (value) {
          if (getError({ channel, type: WEIGHT, value: product.getIn([INVENTORY, WEIGHT, VALUE]) })) return product;

          return clearErrors({
            errors: [WEIGHT],
            path: INVENTORY,
            product: product.setIn([INVENTORY, PHYSICAL, VALUE], TRUE),
          });
        }

        return clearErrors({
          errors: [WEIGHT],
          path: INVENTORY,
          product: product.setIn([INVENTORY, PHYSICAL, VALUE], FALSE),
        });
      }

      case 'shopify_sku': {
        if (getError({ channel, type: SKU, value })) return product;

        return clearErrors({
          errors: [SKU],
          path: INVENTORY,
          product: product.setIn([INVENTORY, SKU, VALUE], value),
        });
      }

      case 'shopify_taxable': {
        return product.setIn([INVENTORY, CHARGE_TAX, VALUE], value || FALSE);
      }

      case 'shopify_weight': {
        if (!product.getIn([INVENTORY, PHYSICAL, VALUE])) return product;

        if (getError({ channel, type: WEIGHT, value })) return product;

        return clearErrors({
          errors: [WEIGHT],
          path: INVENTORY,
          product: product.setIn([INVENTORY, WEIGHT, VALUE], value),
        });
      }

      case 'shopify_weight_unit': {
        if (!product.getIn([INVENTORY, PHYSICAL, VALUE])) return product;

        return product.setIn([INVENTORY, UNIT, VALUE], value);
      }

      default: {
        return product;
      }
    }
  }

  function updateOffering({ field, index, product, value }) {
    function updatePriceValue({ type }) {
      const initial = product.getIn([VARIATIONS, 'offerings', index, type]) || ZERO_CENT;
      const updated = getPriceValue({
        absolute: true,
        operationType: 'set',
        operationValue: value,
        productValue: initial,
      });

      if (getError({ channel, type, value: updated })) return product;

      return clearProfileOfferingsErrors({
        errors: [type],
        offeringIndex: index,
        product: product.setIn([VARIATIONS, 'offerings', index, type], updated),
      });
    }

    switch (field) {
      case 'shopify_barcode': {
        if (getError({ channel, type: BARCODE, value })) return product;

        return clearProfileOfferingsErrors({
          errors: [BARCODE],
          offeringIndex: index,
          product: product.setIn([VARIATIONS, 'offerings', index, BARCODE], value),
        });
      }

      case 'shopify_compare_at_price': {
        return updatePriceValue({ type: CAP });
      }

      case 'shopify_cost': {
        return updatePriceValue({ type: CPI });
      }

      case 'shopify_harmonized_system_code': {
        return product.setIn([VARIATIONS, 'offerings', index, HS_CODE], value || NULL);
      }

      case 'shopify_inventory_management': {
        if (value === SHOPIFY) {
          if (getError({ channel, type: QUANTITY, value: product.getIn([VARIATIONS, 'offerings', index, QUANTITY]) })) {
            return product;
          }

          return clearProfileOfferingsErrors({
            errors: [QUANTITY],
            offeringIndex: index,
            product: product.setIn([VARIATIONS, 'offerings', index, TRACK_QUANTITY], TRUE),
          });
        }

        return clearProfileOfferingsErrors({
          errors: [QUANTITY],
          offeringIndex: index,
          product: product
            .setIn([VARIATIONS, 'offerings', index, QUANTITY], ZERO)
            .setIn([VARIATIONS, 'offerings', index, TRACK_QUANTITY], FALSE),
        });
      }

      case 'shopify_inventory_policy': {
        if (!product.setIn([VARIATIONS, 'offerings', index, TRACK_QUANTITY])) return product;

        return product.setIn([VARIATIONS, 'offerings', index, CONTINUE_SELLING], value === 'continue');
      }

      case 'shopify_inventory_quantity': {
        if (!product.setIn([VARIATIONS, 'offerings', index, TRACK_QUANTITY])) return product;

        if (getError({ channel, type: QUANTITY, value })) return product;

        return clearProfileOfferingsErrors({
          errors: [QUANTITY],
          offeringIndex: index,
          product: product.setIn([VARIATIONS, 'offerings', index, QUANTITY], value),
        });
      }

      case 'shopify_price': {
        return updatePriceValue({ type: PRICE });
      }

      case 'shopify_requires_shipping': {
        if (value) {
          if (getError({ channel, type: WEIGHT, value: product.getIn([VARIATIONS, 'offerings', index, WEIGHT]) })) {
            return product;
          }

          return clearProfileOfferingsErrors({
            errors: [WEIGHT],
            offeringIndex: index,
            product: product.setIn([VARIATIONS, 'offerings', index, PHYSICAL], TRUE),
          });
        }

        return clearProfileOfferingsErrors({
          errors: [WEIGHT],
          offeringIndex: index,
          product: product.setIn([VARIATIONS, 'offerings', index, PHYSICAL], FALSE),
        });
      }

      case 'shopify_sku': {
        if (getError({ channel, type: SKU, value })) return product;

        return clearProfileOfferingsErrors({
          errors: [SKU],
          offeringIndex: index,
          product: product.setIn([VARIATIONS, 'offerings', index, SKU], value),
        });
      }

      case 'shopify_taxable': {
        return product.setIn([VARIATIONS, 'offerings', index, CHARGE_TAX], value || FALSE);
      }

      case 'shopify_weight': {
        if (!product.getIn([VARIATIONS, 'offerings', index, PHYSICAL])) return product;

        if (getError({ channel, type: WEIGHT, value })) return product;

        return clearProfileOfferingsErrors({
          errors: [WEIGHT],
          offeringIndex: index,
          product: product.setIn([VARIATIONS, 'offerings', index, WEIGHT], value),
        });
      }

      case 'shopify_weight_unit': {
        if (!product.getIn([VARIATIONS, 'offerings', index, PHYSICAL])) return product;

        return product.setIn([VARIATIONS, 'offerings', index, UNIT], value);
      }

      default: {
        return product;
      }
    }
  }

  function reduceValues(reducedByValues, change) {
    const fields = change.get('fields');
    const index = change.get('index');

    function reduceFields(reducedByFields, value, field) {
      return hasVariations
        ? updateOffering({ field, index, product: reducedByFields, value })
        : index === 0
          ? updateInventory({ field, product: reducedByFields, value })
          : reducedByFields;
    }

    return fields.reduce(reduceFields, reducedByValues);
  }

  return operation.get(VALUE).reduce(reduceValues, source);
}

export default function inventory({ actions, operation, product }) {
  const channel = product.get('channel');
  const operationType = operation.get('type');

  if (operationType === OPERATIONS[SHOPIFY].INVENTORY_INLINE_EDIT) {
    return { actions, product: inlineEdit({ channel, operation, product }) };
  }

  function bulkUpdateValues({ attributesToClearErrors, updateInventory = void0, updateOffering = void0 }) {
    let result = product;
    const shouldClearErrors = Array.isArray(attributesToClearErrors);
    const size = getSize(result.getIn([VARIATIONS, 'offerings']));

    function findVariationById(variationId) {
      return function find(variation) {
        return variation.get('id') === variationId;
      };
    }

    function findOfferingById(offeringId) {
      return function find(offering) {
        return offering.get('id') === offeringId;
      };
    }

    function getTotalQuantity(sum, item) {
      return item.get('visibility')
        ? sum + parseInt(item.get(QUANTITY) || 0, 10)
        : sum;
    }

    for (let valueIndex = 0; valueIndex < getSize(operation.get(VALUE)); valueIndex++) {
      const offeringId = operation.getIn([VALUE, valueIndex, 'offeringId'], null);
      const combination = operation.getIn([VALUE, valueIndex, 'combination'], null);

      if (combination === null && offeringId === null) {
        const productInventory = updateInventory(result.get(INVENTORY), valueIndex);

        if (productInventory === ERROR) return { actions, product };

        if (productInventory !== NO_CHANGES) {
          if (shouldClearErrors) {
            result = clearErrors({
              errors: attributesToClearErrors,
              path: INVENTORY,
              product: result.set(INVENTORY, productInventory),
            });
          } else {
            result = result.set(INVENTORY, productInventory);
          }
        }

        if (size) {
          for (let offeringIndex = 0; offeringIndex < size; offeringIndex++) {
            const offering = updateOffering(
              result.getIn([VARIATIONS, 'offerings', offeringIndex]),
              valueIndex
            );

            if (offering === ERROR) return { actions, product };

            if (offering === NO_CHANGES) continue;

            if (shouldClearErrors) {
              result = clearProfileOfferingsErrors({
                errors: attributesToClearErrors,
                offeringIndex,
                product: result.setIn([VARIATIONS, 'offerings', offeringIndex], offering),
              });
            } else {
              result = result.setIn([VARIATIONS, 'offerings', offeringIndex], offering);
            }
          }
        }
      } else if (combination !== null) {
        if (size) {
          const optionId = combination.get('optionId');
          const variationId = combination.get('variationId');
          const variationIndex = result
            .getIn([VARIATIONS, 'variations'])
            .findIndex(findVariationById(variationId));

          for (let offeringIndex = 0; offeringIndex < size; offeringIndex++) {
            let offering = result.getIn([VARIATIONS, 'offerings', offeringIndex]);

            if (offering.getIn(['options', variationIndex, 'optionId']) !== optionId) continue;

            offering = updateOffering(offering, valueIndex);

            if (offering === ERROR) return { actions, product };

            if (offering === NO_CHANGES) continue;

            if (shouldClearErrors) {
              result = clearProfileOfferingsErrors({
                errors: attributesToClearErrors,
                offeringIndex,
                product: result.setIn([VARIATIONS, 'offerings', offeringIndex], offering),
              });
            } else {
              result = result.setIn([VARIATIONS, 'offerings', offeringIndex], offering);
            }
          }
        }
      } else if (offeringId !== null) {
        if (size) {
          const offeringIndex = result
            .getIn([VARIATIONS, 'offerings'])
            .findIndex(findOfferingById(offeringId));

          if (offeringIndex === -1) return { actions, product };

          const offering = updateOffering(
            result.getIn([VARIATIONS, 'offerings', offeringIndex]),
            valueIndex
          );

          if (offering === ERROR) return { actions, product };

          if (offering === NO_CHANGES) continue;

          if (shouldClearErrors) {
            result = clearProfileOfferingsErrors({
              errors: attributesToClearErrors,
              offeringIndex,
              product: result.setIn([VARIATIONS, 'offerings', offeringIndex], offering),
            });
          } else {
            result = result.setIn([VARIATIONS, 'offerings', offeringIndex], offering);
          }
        }
      }
    }

    if (
      size &&
      channel === ETSY &&
      (
        operationType === OPERATIONS[ETSY].QUANTITY.CHANGE_TO ||
        operationType === OPERATIONS[ETSY].QUANTITY.DECREASE_BY ||
        operationType === OPERATIONS[ETSY].QUANTITY.INCREASE_BY
      ) &&
      !reduceErrors(result.getIn([VARIATIONS, 'errors', QUANTITY])) &&
      !result.getIn([VARIATIONS, 'offerings']).reduce(getTotalQuantity, 0)
    ) {
      return { actions, product };
    }

    return { actions, product: result };
  }

  switch (operationType) {
    case OPERATIONS[SHOPIFY].CAP.CHANGE_TO:
    case OPERATIONS[SHOPIFY].CAP.DECREASE_BY:
    case OPERATIONS[SHOPIFY].CAP.INCREASE_BY:
    case OPERATIONS[SHOPIFY].CPI.CHANGE_TO:
    case OPERATIONS[SHOPIFY].CPI.DECREASE_BY:
    case OPERATIONS[SHOPIFY].CPI.INCREASE_BY:
    case OPERATIONS[channel].PRICE.CHANGE_TO:
    case OPERATIONS[channel].PRICE.DECREASE_BY:
    case OPERATIONS[channel].PRICE.INCREASE_BY: {
      const type = getAttributeByOperation(operationType);
      return bulkUpdateValues({
        attributesToClearErrors: [type],
        updateInventory(productInventory, valueIndex) {
          const absolute = operation.getIn([VALUE, valueIndex, VALUE, 'type']) === PRICE_TYPE.ABSOLUTE;
          const cents = operation.getIn([VALUE, valueIndex, VALUE, 'rounding']);
          const operationValue = operation.getIn([VALUE, valueIndex, VALUE, VALUE]) || ZERO_CENT;
          const productValue = productInventory.getIn([type, VALUE]) || ZERO_CENT;
          const value = getPriceValue({ absolute, cents, operationType, operationValue, productValue });

          if (value === productValue) return NO_CHANGES;

          return getError({ channel, type, value })
            ? ERROR
            : productInventory.setIn([type, VALUE], value);
        },
        updateOffering(offering, valueIndex) {
          const absolute = operation.getIn([VALUE, valueIndex, VALUE, 'type']) === PRICE_TYPE.ABSOLUTE;
          const cents = operation.getIn([VALUE, valueIndex, VALUE, 'rounding']);
          const operationValue = operation.getIn([VALUE, valueIndex, VALUE, VALUE]) || ZERO_CENT;
          const productValue = offering.get(type) || ZERO_CENT;
          const value = getPriceValue({ absolute, cents, operationType, operationValue, productValue });

          if (value === productValue) return NO_CHANGES;

          return getError({ channel, type, value })
            ? ERROR
            : offering.set(type, value);
        },
      });
    }

    case OPERATIONS[SHOPIFY].CAP.COPY_FROM_PRICE:
    case OPERATIONS[SHOPIFY].PRICE.COPY_FROM_CAP: {
      const type = getAttributeByOperation(operationType);
      return bulkUpdateValues({
        updateInventory(productInventory) {
          const productValue = productInventory.getIn([type, VALUE]) || ZERO_CENT;
          const value = productInventory.getIn([type === CAP ? PRICE : CAP, VALUE]);
          if (value === productValue) return NO_CHANGES;

          return getError({ channel, type, value })
            ? ERROR
            : productInventory.setIn([type, VALUE], value);
        },
        updateOffering(offering) {
          const productValue = offering.get(type) || ZERO_CENT;
          const value = offering.get(type === CAP ? PRICE : CAP) || ZERO_CENT;

          if (value === productValue) return NO_CHANGES;

          return getError({ channel, type, value })
            ? ERROR
            : offering.set(type, value);
        },
      });
    }

    case OPERATIONS[channel].QUANTITY.CHANGE_TO:
    case OPERATIONS[channel].QUANTITY.DECREASE_BY:
    case OPERATIONS[channel].QUANTITY.INCREASE_BY: {
      return bulkUpdateValues({
        attributesToClearErrors: [QUANTITY],
        updateInventory(productInventory, valueIndex) {
          if (
            channel === SHOPIFY &&
            !productInventory.getIn([TRACK_QUANTITY, VALUE])
          ) {
            return NO_CHANGES;
          }

          const operationValue = operation.getIn([VALUE, valueIndex, VALUE]) || ZERO;
          const productValue = productInventory.getIn([QUANTITY, VALUE]) || ZERO;
          const value = getNumberValue({ decimals: 0, operationType, operationValue, productValue });

          if (value === productValue) return NO_CHANGES;

          return getError({ channel, type: QUANTITY, value })
            ? ERROR
            : productInventory.setIn([QUANTITY, VALUE], value);
        },
        updateOffering(offering, valueIndex) {
          if (channel === SHOPIFY && !offering.get(TRACK_QUANTITY)) return NO_CHANGES;

          const operationValue = operation.getIn([VALUE, valueIndex, VALUE]) || ZERO;
          const productValue = offering.get(QUANTITY) || ZERO;
          const value = getNumberValue({ decimals: 0, operationType, operationValue, productValue });

          if (value === productValue) return NO_CHANGES;

          const validatorProps = { channel, type: QUANTITY, value };

          if (channel === ETSY) {
            validatorProps.allowZero = true;
          }

          return getError(validatorProps)
            ? ERROR
            : offering.set(QUANTITY, value);
        },
      });
    }

    case OPERATIONS[channel].SKU.ADD_AFTER:
    case OPERATIONS[SHOPIFY].BARCODE.ADD_AFTER: {
      const type = getAttributeByOperation(operationType);
      return bulkUpdateValues({
        attributesToClearErrors: [type],
        updateInventory(productInventory, valueIndex) {
          const productValue = productInventory.getIn([type, VALUE]) || EMPTY_STRING;
          const operationValue = operation.getIn([VALUE, valueIndex, VALUE]);
          const value = `${productValue}${operationValue}`;

          if (value === productValue) return NO_CHANGES;

          return getError({ channel, type, value })
            ? ERROR
            : productInventory.setIn([type, VALUE], value);
        },
        updateOffering(offering, valueIndex) {
          const productValue = offering.get(type) || EMPTY_STRING;
          const operationValue = operation.getIn([VALUE, valueIndex, VALUE]);
          const value = `${productValue}${operationValue}`;

          if (value === productValue) return NO_CHANGES;

          return getError({ channel, type, value })
            ? ERROR
            : offering.set(type, value);
        },
      });
    }

    case OPERATIONS[channel].SKU.ADD_BEFORE:
    case OPERATIONS[SHOPIFY].BARCODE.ADD_BEFORE: {
      const type = getAttributeByOperation(operationType);
      return bulkUpdateValues({
        attributesToClearErrors: [type],
        updateInventory(productInventory, valueIndex) {
          const productValue = productInventory.getIn([type, VALUE]) || EMPTY_STRING;
          const operationValue = operation.getIn([VALUE, valueIndex, VALUE]);
          const value = `${operationValue}${productValue}`;

          if (value === productValue) return NO_CHANGES;

          return getError({ channel, type, value })
            ? ERROR
            : productInventory.setIn([type, VALUE], value);
        },
        updateOffering(offering, valueIndex) {
          const productValue = offering.get(type) || EMPTY_STRING;
          const operationValue = operation.getIn([VALUE, valueIndex, VALUE]);
          const value = `${operationValue}${productValue}`;

          if (value === productValue) return NO_CHANGES;

          return getError({ channel, type, value })
            ? ERROR
            : offering.set(type, value);
        },
      });
    }

    case OPERATIONS[channel].SKU.CHANGE_TO:
    case OPERATIONS[SHOPIFY].BARCODE.CHANGE_TO: {
      const type = getAttributeByOperation(operationType);
      return bulkUpdateValues({
        attributesToClearErrors: [type],
        updateInventory(productInventory, valueIndex) {
          const productValue = productInventory.getIn([type, VALUE]) || EMPTY_STRING;
          const value = operation.getIn([VALUE, valueIndex, VALUE]);

          if (value === productValue) return NO_CHANGES;

          return getError({ channel, type, value })
            ? ERROR
            : productInventory.setIn([type, VALUE], value);
        },
        updateOffering(offering, valueIndex) {
          const productValue = offering.get(type) || EMPTY_STRING;
          const value = operation.getIn([VALUE, valueIndex, VALUE]);

          if (value === productValue) return NO_CHANGES;

          return getError({ channel, type, value })
            ? ERROR
            : offering.set(type, value);
        },
      });
    }

    case OPERATIONS[channel].SKU.DELETE:
    case OPERATIONS[SHOPIFY].BARCODE.DELETE: {
      const type = getAttributeByOperation(operationType);
      return bulkUpdateValues({
        attributesToClearErrors: [type],
        updateInventory(productInventory, valueIndex) {
          const productValue = productInventory.getIn([type, VALUE]);

          if (!productValue) return NO_CHANGES;

          const operationValue = operation.getIn([VALUE, valueIndex, VALUE]);
          const pattern = new RegExp(escapeRegex(operationValue), 'ig');
          const value = productValue.replace(pattern, EMPTY_STRING);

          if (value === productValue) return NO_CHANGES;

          return getError({ channel, type, value })
            ? ERROR
            : productInventory.setIn([type, VALUE], value);
        },
        updateOffering(offering, valueIndex) {
          const productValue = offering.get(type);

          if (!productValue) return NO_CHANGES;

          const operationValue = operation.getIn([VALUE, valueIndex, VALUE]);
          const pattern = new RegExp(escapeRegex(operationValue), 'ig');
          const value = productValue.replace(pattern, EMPTY_STRING);

          if (value === productValue) return NO_CHANGES;

          return getError({ channel, type, value })
            ? ERROR
            : offering.set(type, value);
        },
      });
    }

    case OPERATIONS[channel].SKU.FIND_AND_REPLACE:
    case OPERATIONS[SHOPIFY].BARCODE.FIND_AND_REPLACE: {
      const type = getAttributeByOperation(operationType);
      return bulkUpdateValues({
        attributesToClearErrors: [type],
        updateInventory(productInventory, valueIndex) {
          const productValue = productInventory.getIn([type, VALUE]);

          if (!productValue) return NO_CHANGES;

          const find = operation.getIn([VALUE, valueIndex, VALUE, 'find']);
          const replace = operation.getIn([VALUE, valueIndex, VALUE, 'replace']);
          const pattern = new RegExp(escapeRegex(find), 'ig');
          const value = productValue.replace(pattern, replace);

          if (value === productValue) return NO_CHANGES;

          return getError({ channel, type, value })
            ? ERROR
            : productInventory.setIn([type, VALUE], value);
        },
        updateOffering(offering, valueIndex) {
          const productValue = offering.get(type);

          if (!productValue) return NO_CHANGES;

          const find = operation.getIn([VALUE, valueIndex, VALUE, 'find']);
          const replace = operation.getIn([VALUE, valueIndex, VALUE, 'replace']);
          const pattern = new RegExp(escapeRegex(find), 'ig');
          const value = productValue.replace(pattern, replace);

          if (value === productValue) return NO_CHANGES;

          return getError({ channel, type, value })
            ? ERROR
            : offering.set(type, value);
        },
      });
    }

    case OPERATIONS[SHOPIFY].WEIGHT.CHANGE_TO:
    case OPERATIONS[SHOPIFY].WEIGHT.INCREASE_BY:
    case OPERATIONS[SHOPIFY].WEIGHT.DECREASE_BY: {
      return bulkUpdateValues({
        attributesToClearErrors: [WEIGHT],
        updateInventory(productInventory, valueIndex) {
          if (!productInventory.getIn([PHYSICAL, VALUE])) return NO_CHANGES;

          const operationValue = operation.getIn([VALUE, valueIndex, VALUE, WEIGHT]) || ZERO;
          const productValue = productInventory.getIn([WEIGHT, VALUE]) || ZERO;
          const weight = getNumberValue({ operationType, operationValue, productValue });
          const unit = operation.getIn([VALUE, valueIndex, VALUE, UNIT]);

          if (
            weight === productValue &&
            unit === productInventory.getIn([UNIT, VALUE])
          ) {
            return NO_CHANGES;
          }

          return getError({ channel, type: WEIGHT, value: weight })
            ? ERROR
            : productInventory
              .setIn([UNIT, VALUE], unit)
              .setIn([WEIGHT, VALUE], weight);
        },
        updateOffering(offering, valueIndex) {
          if (!offering.get(PHYSICAL)) return NO_CHANGES;

          const operationValue = operation.getIn([VALUE, valueIndex, VALUE, WEIGHT]) || ZERO;
          const productValue = offering.get(WEIGHT) || ZERO;
          const weight = getNumberValue({ operationType, operationValue, productValue });
          const unit = operation.getIn([VALUE, valueIndex, VALUE, UNIT]);

          if (weight === productValue && unit === offering.get(UNIT)) return NO_CHANGES;

          return getError({ channel, type: WEIGHT, value: weight })
            ? ERROR
            : offering.set(WEIGHT, weight).set(UNIT, unit);
        },
      });
    }

    case OPERATIONS[SHOPIFY].CHARGE_TAX.SET: {
      return bulkUpdateValues({
        updateInventory(productInventory, valueIndex) {
          const productValue = productInventory.getIn([CHARGE_TAX, VALUE]);
          const value = operation.getIn([VALUE, valueIndex, VALUE, 'taxable']);

          if (value === productValue) return NO_CHANGES;

          return productInventory.setIn([CHARGE_TAX, VALUE], value);
        },
        updateOffering(offering, valueIndex) {
          const productValue = offering.get(CHARGE_TAX);
          const value = operation.getIn([VALUE, valueIndex, VALUE, 'taxable']);

          if (value === productValue) return NO_CHANGES;

          return offering.set(CHARGE_TAX, value);
        },
      });
    }

    case OPERATIONS[SHOPIFY].CONTINUE_SELLING.SET: {
      return bulkUpdateValues({
        updateInventory(productInventory, valueIndex) {
          const productValue = productInventory.getIn([CONTINUE_SELLING, VALUE]);
          const value = operation.getIn([VALUE, valueIndex, VALUE, 'inventoryPolicy']) === 'continue';

          if (value === productValue) return NO_CHANGES;

          return productInventory.setIn([CONTINUE_SELLING, VALUE], value);
        },
        updateOffering(offering, valueIndex) {
          const productValue = offering.get(CONTINUE_SELLING);
          const value = operation.getIn([VALUE, valueIndex, VALUE, 'inventoryPolicy']) === 'continue';

          if (value === productValue) return NO_CHANGES;

          return offering.set(CONTINUE_SELLING, value);
        },
      });
    }

    case OPERATIONS[SHOPIFY].COUNTRY_CODE.SET:
    case OPERATIONS[SHOPIFY].HS_CODE.SET: {
      const type = getAttributeByOperation(operationType);
      return bulkUpdateValues({
        updateInventory(productInventory, valueIndex) {
          const productValue = productInventory.getIn([type, VALUE]);
          const value = operation.getIn([VALUE, valueIndex, VALUE, type]);

          if (value === productValue) return NO_CHANGES;

          return productInventory.setIn([type, VALUE], value);
        },
        updateOffering(offering, valueIndex) {
          const productValue = offering.get(type);
          const value = operation.getIn([VALUE, valueIndex, VALUE, type]);

          if (value === productValue) return NO_CHANGES;

          return offering.set(type, value);
        },
      });
    }

    case OPERATIONS[SHOPIFY].TRACK_QUANTITY.SET: {
      return bulkUpdateValues({
        attributesToClearErrors: [QUANTITY],
        updateInventory(productInventory, valueIndex) {
          const productValue = productInventory.getIn([TRACK_QUANTITY, VALUE]);
          const value = operation.getIn([VALUE, valueIndex, VALUE, 'inventoryManagement']) === SHOPIFY;

          if (value === productValue) return NO_CHANGES;

          return value
            ? getError({ channel, type: QUANTITY, value: productInventory.getIn([QUANTITY, VALUE]) })
              ? ERROR
              : productInventory.setIn([TRACK_QUANTITY, VALUE], value)
            : productInventory.setIn([TRACK_QUANTITY, VALUE], value);
        },
        updateOffering(offering, valueIndex) {
          const productValue = offering.get(TRACK_QUANTITY);
          const value = operation.getIn([VALUE, valueIndex, VALUE, 'inventoryManagement']) === SHOPIFY;

          if (value === productValue) return NO_CHANGES;

          return value
            ? getError({ channel, type: QUANTITY, value: offering.get(QUANTITY) })
              ? ERROR
              : offering.set(TRACK_QUANTITY, value)
            : offering.set(TRACK_QUANTITY, value);
        },
      });
    }

    default: {
      return { actions, product };
    }
  }
}
