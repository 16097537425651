export function processedToOriginal(result, photo, processedId) {
  return photo.has('loaded')
    ? result.set(photo.get('originalId'), photo.set('processedId', processedId))
    : result;
}

export function originalToProcessed(result, photo) {
  const processedId = photo.get('processedId');
  return result.set(processedId, photo);
}
