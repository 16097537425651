import { Map } from 'immutable';

import { PAGE_SIZE } from '../../constants/listings';

export function getDefaultPagination() {
  return Map({ currentPage: 0 });
}

export function getPagination({ page, total }) {
  return Map({
    currentPage: page,
    from: page * PAGE_SIZE + 1,
    pageSize: PAGE_SIZE,
    to: Math.min(page * PAGE_SIZE + PAGE_SIZE, total),
    total,
  });
}

export function getPaginationForAPI(page) {
  return {
    limit: PAGE_SIZE,
    offset: page * PAGE_SIZE,
  };
}
