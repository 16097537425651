import { List, Map, OrderedMap } from 'immutable';

import { shapeTaxonomyAttributesForAPI } from './shapeTaxonomyAttributes';
import { getInventoryOperationValue } from './getOperationValue';
import { getOperationsFromProduct } from './getOperationsFromProduct';
import { getIndividualVariations } from '../variations/getIndividualVariations';
import { isFeatureEnabled } from '../featureFlags';
import { filterOperations } from './operations';
import { isValidNumber } from '../number';
import { getSize } from '../iterable/getSize';
import { keepOld } from '../iterable/keepOld';
import { get } from '../iterable/get';

import { NEW, OPERATIONS, SECTIONS, VALUE } from '../../constants/product';
import { INDIVIDUAL, INDIVIDUAL_FLAGS } from '../../constants/profiles';
import { DEFAULTS, SEPARATOR } from '../../constants';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { FEATURES } from '../../constants/billing';
import {
  CATEGORY,
  CHAR_COUNT_MAX,
  DESCRIPTION,
  DIGITAL,
  ENABLED,
  FILES,
  HEIGHT,
  INSTRUCTIONS,
  IS_SUPPLY,
  LENGTH,
  MATERIALS,
  NEW_MATERIALS,
  NEW_TAGS,
  PERSONALIZATION,
  PRICE,
  PRODUCTION_PARTNERS,
  PROFILE_ID,
  REQUIRED,
  RETURN_POLICY,
  QUANTITY,
  SECTION,
  SHIPPING,
  SHOULD_AUTO_RENEW,
  SKU,
  TAGS,
  TAXONOMY_ATTRIBUTES,
  TITLE,
  VARIATIONS,
  WEIGHT,
  WHEN_MADE,
  WHO_MADE,
  WIDTH,
  VISIBILITY,
} from '../../constants/attributes';

const { DETAILS, INVENTORY } = SECTIONS;
const { NULL, ZERO } = DEFAULTS;

export function shapeOperationsForAPI({ data = {}, operations, initialValue = [] }) {
  function reduceOperations(result, item) {
    const operation = item.toJS();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        operation[key] = data[key];
      }
    }

    result.push(operation);
    return result;
  }

  return operations.reduce(reduceOperations, initialValue);
}

export function shapeNewProductForAPI({ channel, product, shopId, status, templateId, userId }) {
  function reduceFiles(result, item) {
    const [name, size] = get('name', 'size')(item);
    const file = { name, size };

    if (isFeatureEnabled({ feature: FEATURES.FILES_TO_S3, userId })) {
      const url = get('file_url')(item);
      file.file_url = url;
    } else {
      const uuid = get('uuid')(item);
      file.uuid = uuid;
    }

    result.push(file);

    return result;
  }

  switch (channel) {
    case ETSY: {
      const value = {};
      const variations = product.get(VARIATIONS);
      const variationsProfile = variations.has('currentProfile');
      const taxonomyAttributes = product.getIn([DETAILS, TAXONOMY_ATTRIBUTES], Map());

      value.productState = status;
      value.productVariations = [];
      value.productSupply = product.getIn([DETAILS, IS_SUPPLY, VALUE]);
      value.productCreator = product.getIn([DETAILS, WHO_MADE, VALUE]);
      value.productCreationDate = product.getIn([DETAILS, WHEN_MADE, VALUE]);
      value.productShopSection = product.getIn([DETAILS, SECTION, VALUE], NULL);
      value.productAutoRenewal = product.getIn([DETAILS, SHOULD_AUTO_RENEW, VALUE]);
      value.productionPartnerIds = product.getIn([DETAILS, PRODUCTION_PARTNERS, VALUE], List()).toJS();
      value.isDigital = product.getIn([DETAILS, DIGITAL, VALUE]);
      value.productTitle = product.getIn([TITLE, VALUE]);
      value.productDescription = product.getIn([DESCRIPTION, VALUE]);
      value.productMaterials = product
        .getIn([DETAILS, MATERIALS, VALUE], OrderedMap())
        .merge(product.getIn([DETAILS, MATERIALS, NEW_MATERIALS], OrderedMap()))
        .join(SEPARATOR.COMMA);

      value.productAttributes = shapeTaxonomyAttributesForAPI({
        taxonomyAttributes,
        toImmutable: false,
      });

      if (value.isDigital) {
        value.files = product.getIn([FILES, VALUE]).reduce(reduceFiles, []);
      } else {
        value.returnPolicyId = product.getIn([SHIPPING, RETURN_POLICY, VALUE]) || DEFAULTS.ONE;
        value.productShippingId = product.getIn([SHIPPING, PROFILE_ID, VALUE]);
        const productItemHeight = product.getIn([SHIPPING, HEIGHT, VALUE], NULL);
        const productItemLength = product.getIn([SHIPPING, LENGTH, VALUE], NULL);
        const productItemWeight = product.getIn([SHIPPING, WEIGHT, VALUE], NULL);
        const productItemWidth = product.getIn([SHIPPING, WIDTH, VALUE], NULL);
        value.productItemWeight = isValidNumber(productItemWeight)
          ? parseFloat(productItemWeight)
          : NULL;
        value.productItemHeight = isValidNumber(productItemHeight)
          ? parseFloat(productItemHeight)
          : NULL;
        value.productItemWidth = isValidNumber(productItemWidth)
          ? parseFloat(productItemWidth)
          : NULL;
        value.productItemLength = isValidNumber(productItemLength)
          ? parseFloat(productItemLength)
          : NULL;
      }

      const personalization = product.get(PERSONALIZATION);
      const personalizationEnabled = personalization.get(ENABLED);
      value.isPersonalizable = personalizationEnabled;
      value.personalizationCharCountMax = personalizationEnabled
        ? personalization.getIn([CHAR_COUNT_MAX, VALUE])
        : NULL;
      value.personalizationInstructions = personalizationEnabled
        ? personalization.getIn([INSTRUCTIONS, VALUE])
        : NULL;
      value.personalizationIsRequired = personalizationEnabled
        ? personalization.get(REQUIRED)
        : false;

      value.productTaxonomy = variationsProfile
        ? parseInt(variations.get(CATEGORY), 10)
        : parseInt(product.getIn([DETAILS, CATEGORY, VALUE]), 10);

      if (product.hasIn([TAGS, 'currentProfile'])) {
        value.productTagsProfileId = product.getIn([TAGS, 'currentProfile', 'profileId']);
        value.productTags = product
          .getIn([TAGS, 'currentProfile', TAGS])
          .mergeWith(keepOld, product.getIn([TAGS, NEW_TAGS]))
          .join(SEPARATOR.COMMA);
      } else {
        value.productTags = product
          .getIn([TAGS, VALUE], OrderedMap())
          .mergeWith(keepOld, product.getIn([TAGS, NEW_TAGS]))
          .join(SEPARATOR.COMMA);
      }

      value.productImageFiles = getOperationsFromProduct({
        channel,
        product,
        operationTypes: { [channel]: [OPERATIONS[channel].PHOTOS.CHANGE_TO] },
        toImmutable: false,
      });

      value.productVideoFiles = getOperationsFromProduct({
        channel,
        product,
        operationTypes: { [channel]: [OPERATIONS[channel].VIDEOS.ADD] },
        toImmutable: false,
      });

      if (!value.isDigital && getSize(variations.get('offerings'))) {
        const individual = getIndividualVariations({
          properties: INDIVIDUAL_FLAGS[channel],
          variations: variations.get(VARIATIONS),
        });

        const individualPrice = !!getSize(individual[INDIVIDUAL.PRICE]);
        const individualQuantity = !!getSize(individual[INDIVIDUAL.QUANTITY]);
        const individualSKU = !!getSize(individual[INDIVIDUAL.SKU]);
        const firstVisibleOffering = (
          (individualPrice || individualQuantity || individualSKU) &&
          variations.get('offerings').find(get(VISIBILITY))
        );

        value.productPrice = individualPrice
          ? parseFloat(firstVisibleOffering.get(PRICE))
          : parseFloat(product.getIn([INVENTORY, PRICE, VALUE]));

        value.productQuantity = individualQuantity
          ? parseInt(firstVisibleOffering.get(QUANTITY), 10)
          : parseInt(product.getIn([INVENTORY, QUANTITY, VALUE]), 10);

        if (!individualSKU) {
          value.productSku = product.getIn([INVENTORY, SKU, VALUE]);
        }

        const variationsOperation = getOperationsFromProduct({
          channel,
          product,
          operationTypes: { [channel]: [OPERATIONS[channel].VARIATIONS] },
          toImmutable: false,
        })[0];

        if (!individualPrice || !individualQuantity || !individualSKU) {
          variationsOperation.value.offerings = variationsOperation.value.offerings.map(
            function mapOfferings(offering) {
              if (!individualPrice) {
                offering[PRICE] = variationsProfile
                  ? NULL
                  : product.getIn([INVENTORY, PRICE, VALUE]);
              }

              if (!individualQuantity) {
                offering[QUANTITY] = variationsProfile
                  ? NULL
                  : product.getIn([INVENTORY, QUANTITY, VALUE]);
              }

              if (!individualSKU) {
                offering[SKU] = variationsProfile
                  ? NULL
                  : product.getIn([INVENTORY, SKU, VALUE]);
              }

              return offering;
            }
          );
        }

        value.productVariations = [variationsOperation];
      } else {
        value.productPrice = parseFloat(product.getIn([INVENTORY, PRICE, VALUE]));
        value.productQuantity = parseInt(product.getIn([INVENTORY, QUANTITY, VALUE]), 10);
        value.productSku = product.getIn([INVENTORY, SKU, VALUE]);
        value.productVariations = [];
      }

      const operation = { shopId, type: 'product.create', value };

      if (templateId) {
        operation.createdFromTemplateId = templateId;
      }

      return [operation];
    }

    case SHOPIFY: {
      const products = [ZERO];
      let result = [{ products, type: OPERATIONS[channel].STATUS, value: status }];

      if (product.get('productId') === NEW) {
        const operations = product.get('operations', List());

        result = shapeOperationsForAPI({
          data: { products },
          operations,
          initialValue: result,
        });

        if (operations.findIndex(filterOperations(OPERATIONS[channel].VARIATIONS)) === -1) {
          result.push({
            products,
            type: OPERATIONS[channel].VARIATIONS,
            value: getInventoryOperationValue({ channel, inventory: product.get(INVENTORY) }).toJS(),
          });
        }
      } else {
        result = result.concat(
          getOperationsFromProduct({
            channel,
            operation: { products },
            product,
            toImmutable: false,
          })
        );
      }

      return result;
    }

    default: {
      return [];
    }
  }
}

export function shapeNewLinkedProductsForAPI({ edit, shopsById, statuses, userId }) {
  function reduceProductsData(result, product, shopId) {
    const shop = shopsById.get(shopId);
    const shopDb = shop.get('db');
    const channel = shop.get('channel');
    const status = statuses.get(shopId, statuses.get(edit.get('shopId')));
    const templateId = product.get('templateId');
    const value = shapeNewProductForAPI({ channel, product, shopId, status, templateId, userId });

    if (!Array.isArray(result[channel])) {
      result[channel] = [];
    }

    switch (channel) {
      case ETSY: {
        value[0].db = shopDb;
        result[channel].push(value[0]);
        break;
      }

      case SHOPIFY: {
        result[channel].push({ shopId, db: shopDb, operations: value });
        break;
      }

      default: {
        break;
      }
    }

    return result;
  }

  return {
    listingProfileId: edit.get('profile'),
    newProducts: edit.get('products').reduce(reduceProductsData, {}),
  };
}
