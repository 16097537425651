import { isImmutable, List } from 'immutable';

import { isTruthy } from './bool';
import { getSize } from './iterable/getSize';

import { VARIATIONS } from '../constants/attributes';
import { ERROR } from '../constants/product';

export function reduceErrors(errors, toBool = true) {
  function recursiveReduce(result, item) {
    return result || (
      isImmutable(item)
        ? item.reduce(recursiveReduce, result)
        : toBool
          ? !!item
          : item
    );
  }

  return isImmutable(errors)
    ? errors.reduce(recursiveReduce, false)
    : errors;
}

function getProductErrorPath({ key, path }) {
  if (typeof path === 'string') {
    return [path, key, ERROR];
  } else if (Array.isArray(path)) {
    return [...path, key, ERROR];
  } else {
    return [key, ERROR];
  }
}

export function clearErrors({ errors = [], path, product }) {
  function reduceErrorKeys(data, key) {
    let result = data;

    if (result.has('errors')) {
      result = result.deleteIn(['errors', key]);
    }

    return result.deleteIn(getProductErrorPath({ key, path }));
  }

  return errors.reduce(reduceErrorKeys, product);
}

export function setErrors({ errors = [], path, product }) {
  function reduceErrorKeys(data, value, key) {
    let result = data;

    if (result.has('errors')) {
      result = result.setIn(['errors', key], value);
    }

    return result.setIn(getProductErrorPath({ key, path }), value);
  }

  return errors.reduce(reduceErrorKeys, product);
}

export function clearProfileOfferingsErrors({ errors = [], offeringIndex, product }) {
  function reduceErrorKeys(data, key) {
    let result = data;
    let attributeErrors = result.getIn([VARIATIONS, 'errors', key]);

    if (!List.isList(attributeErrors)) {
      attributeErrors = List(Array(getSize(result.getIn([VARIATIONS, 'offerings']))).fill(false));
    }

    if (offeringIndex !== undefined && attributeErrors.get(offeringIndex)) {
      attributeErrors = attributeErrors.set(offeringIndex, false);
    }

    if (result.has('errors')) {
      if (!getSize(attributeErrors.filter(isTruthy))) {
        result = result.deleteIn(['errors', VARIATIONS, key]);
      }

      if (result.hasIn(['errors', VARIATIONS]) && !getSize(result.getIn(['errors', VARIATIONS]))) {
        result = result.deleteIn(['errors', VARIATIONS]);
      }
    }

    return result.setIn([VARIATIONS, 'errors', key], attributeErrors);
  }

  return errors.reduce(reduceErrorKeys, product);
}

export function removeErrors(errors) {
  function mapErrors(error) {
    return isImmutable(error)
      ? removeErrors(error)
      : false;
  }

  return errors.map(mapErrors);
}
