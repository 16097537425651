import { Set } from 'immutable';

import { getSize } from '../iterable/getSize';

import { SELECTION } from '../../constants/listings';
import { DEFAULTS } from '../../constants';

const { EMPTY_SET } = DEFAULTS;

export function setSelection({ exclude, products }) {
  const selected = products.get('selected', EMPTY_SET);

  if (!getSize(selected)) return products.delete('selection');

  const all = products.get('all', EMPTY_SET);
  const total = products.get('total');

  if (selected.equals(all) || getSize(selected) === total) {
    return products.set('selection', SELECTION.ALL);
  }

  const page = Set(products.get('ids'));
  const selectedAndExcluded = selected.union(page.intersect(exclude));

  if (getSize(selectedAndExcluded) >= getSize(page) && page.intersect(selectedAndExcluded).equals(page)) {
    return products.set('selection', SELECTION.PAGE);
  }

  return products.delete('selection');
}
