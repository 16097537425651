import he from 'he';
import { List, Map } from 'immutable';

import { newDetails, newInventory, newShipping } from '../new/product';
import { shapePersonalizationForApp } from './shapeForApp';
import { updateIndividual } from '../variations/updateIndividual';
import { setProfileErrors } from '../validations/profile';
import { setProductErrors } from './errors';
import { deleteVariation } from '../variations/deleteVariation';
import { convertProfile } from '../profiles/convert';
import { tagsFromArray } from '../tags';
import { getTagError } from '../validations/tags';
import { newProfile } from '../new/profile';
import { getSize } from '../iterable/getSize';

import { ATTRIBUTE_DEFAULTS, NEW, OVERSET, SECTIONS, VALUE } from '../../constants/product';
import { INDIVIDUAL, NUMBER_OF_VARIATIONS, PROFILE } from '../../constants/profiles';
import { DEFAULTS, SEPARATOR, VELA } from '../../constants';
import { SEO_URL_HANDLE_ENABLED } from '../../constants/featureFlags';
import { CUSTOM_PROPERTY_IDS } from '../../constants/taxonomy';
import { MAX_NUMBER_OF_TAGS } from '../../constants/validations';
import { ETSY, SHOPIFY } from '../../constants/channels';
import {
  BARCODE,
  CAP,
  CATEGORY,
  CHARGE_TAX,
  COLLECTIONS,
  CONTINUE_SELLING,
  COUNTRY_CODE,
  CPI,
  DESCRIPTION,
  DIGITAL,
  HS_CODE,
  META_DESCRIPTION,
  NEW_TAGS,
  PAGE_TITLE,
  PERSONALIZATION,
  PHYSICAL,
  PRICE,
  PRODUCTION_PARTNERS,
  PROFILE_ID,
  PROFILE_TYPE,
  QUANTITY,
  RETURN_POLICY,
  SECTION,
  SKU,
  TAGS,
  TITLE,
  TRACK_QUANTITY,
  UNIT,
  URL_HANDLE,
  VARIATIONS,
  VISIBILITY,
  WEIGHT,
} from '../../constants/attributes';

export function convertDescriptionToBodyHTML(description) {
  if (!description) return description;

  function mapParagraphs(paragraph) {
    return paragraph.trim()
      ? `<p>${he.encode(paragraph, { strict: true, useNamedReferences: true })}</p>`
      : '<br/>';
  }

  return description.split(/\r?\n/).map(mapParagraphs).join('');
}

export function convertBodyHTMLToDescription(bodyHTML) {
  if (!bodyHTML || typeof bodyHTML !== 'string') return DEFAULTS.EMPTY_STRING;

  const element = document.createElement('div');
  element.innerHTML = bodyHTML
    .replace(/(<\/(p|li|td|th|h\d)>)+/g, '<br><br>')
    .replace(/<br[^>]*>/gi, '\n')
    .replace(/(^\n+)|<[^>]*>|<\/[^>]*>|(\n+$)/gi, DEFAULTS.EMPTY_STRING);

  // decode HTML entities
  return he.decode(element.innerText || element.textContent || DEFAULTS.EMPTY_STRING);
}

export function convertBodyHTMLToMetaDescription(bodyHTML) {
  return convertBodyHTMLToDescription(bodyHTML).replace(/\s+/g, SEPARATOR.SPACE).trim();
}

function convertVelaVariations({ channel, product, value }) {
  function updateVariationsForEtsy(variations = List()) {
    function mapVariations(variation = Map(), variationIndex) {
      function updateOptions(options = List()) {
        function mapOptions(option = Map()) {
          return option
            .set('valueId', null)
            .set('imageHash', null);
        }

        return options.map(mapOptions);
      }

      return variation
        .set(INDIVIDUAL.PRICE, DEFAULTS.TRUE)
        .set(INDIVIDUAL.QUANTITY, DEFAULTS.TRUE)
        .set(INDIVIDUAL.SKU, DEFAULTS.TRUE)
        .set('property', CUSTOM_PROPERTY_IDS[variationIndex])
        .update('options', updateOptions);
    }

    return variations.map(mapVariations);
  }

  function updateOfferingsForEtsy(offerings = List()) {
    function mapOfferings(offering = Map()) {
      return offering
        .set(VISIBILITY, DEFAULTS.TRUE);
    }

    return offerings.map(mapOfferings);
  }

  function updateVariationsForShopify(variations = List()) {
    function mapVariations(variation = Map()) {
      function updateOptions(options = List()) {
        function mapOptions(option = Map()) {
          return option.delete('id');
        }

        return options.map(mapOptions);
      }

      return variation.update('options', updateOptions);
    }

    return variations.map(mapVariations);
  }

  function updateOfferingsForShopify(offerings = List()) {
    function mapOfferings(offering = Map()) {
      function updateOptions(options = List()) {
        function mapOptions(option = Map()) {
          return option.deleteAll(['optionId', 'variationId']);
        }

        return options.map(mapOptions);
      }

      return offering
        .set(BARCODE, DEFAULTS.EMPTY_STRING)
        .set(CAP, DEFAULTS.ZERO_CENT)
        .set(CHARGE_TAX, product.getIn([SECTIONS.INVENTORY, CHARGE_TAX, VALUE], ATTRIBUTE_DEFAULTS[SHOPIFY][CHARGE_TAX]))
        .set(CONTINUE_SELLING, product.getIn([SECTIONS.INVENTORY, CONTINUE_SELLING, VALUE], ATTRIBUTE_DEFAULTS[SHOPIFY][CONTINUE_SELLING]))
        .set(COUNTRY_CODE, DEFAULTS.NULL)
        .set(CPI, DEFAULTS.ZERO_CENT)
        .set(HS_CODE, DEFAULTS.NULL)
        .set(PHYSICAL, DEFAULTS.TRUE)
        .set(TRACK_QUANTITY, DEFAULTS.TRUE)
        .set(UNIT, DEFAULTS.UNIT)
        .set(WEIGHT, DEFAULTS.ZERO)
        .set('imageId', DEFAULTS.NULL)
        .set('imageHash', DEFAULTS.NULL)
        .update('options', updateOptions);
    }

    return offerings.map(mapOfferings);
  }

  switch (channel) {
    case ETSY: {
      let profile = value;

      while (getSize(profile.get(VARIATIONS)) > NUMBER_OF_VARIATIONS[channel]) {
        profile = deleteVariation({
          product,
          profile: value,
          variationIndex: getSize(value.get(VARIATIONS)) - 1,
        });
      }

      return setProfileErrors({
        profile: updateIndividual(
          profile
            .set('channel', channel)
            .set(CATEGORY, product.getIn([SECTIONS.DETAILS, CATEGORY, VALUE]), DEFAULTS.ZERO)
            .update('variations', updateVariationsForEtsy)
            .update('offerings', updateOfferingsForEtsy)
        ),
        type: PROFILE.VARIATIONS,
      });
    }

    case SHOPIFY: {
      return setProfileErrors({
        profile: value
          .set('channel', channel)
          .set(INDIVIDUAL.BARCODE, DEFAULTS.TRUE)
          .set(INDIVIDUAL.PRICE, DEFAULTS.TRUE)
          .set(INDIVIDUAL.QUANTITY, DEFAULTS.TRUE)
          .set(INDIVIDUAL.SHIPPING, DEFAULTS.TRUE)
          .set(INDIVIDUAL.SKU, DEFAULTS.TRUE)
          .set(TRACK_QUANTITY, DEFAULTS.TRUE)
          .update('variations', updateVariationsForShopify)
          .update('offerings', updateOfferingsForShopify),
        type: PROFILE.VARIATIONS,
      });
    }

    default: {
      return value;
    }
  }
}

function filterTags(channel) {
  return function filter(tag) {
    return !getTagError({ channel, tag });
  };
}

export function convert({ from, to, type, value, ...rest }) {
  switch (type) {
    case DESCRIPTION: {
      switch (from) {
        case ETSY: {
          switch (to) {
            case SHOPIFY: {
              return convertDescriptionToBodyHTML(value);
            }

            default: {
              return value;
            }
          }
        }

        case SHOPIFY: {
          switch (to) {
            case ETSY: {
              return convertBodyHTMLToDescription(value);
            }

            default: {
              return value;
            }
          }
        }

        default: {
          return value;
        }
      }
    }

    case TAGS: {
      return value.filter(filterTags(to)).slice(0, MAX_NUMBER_OF_TAGS[to]);
    }

    case VARIATIONS: {
      switch (from) {
        case VELA: {
          return convertVelaVariations({ channel: to, value, ...rest });
        }

        default: {
          return convertProfile({ profile: value, to, type, ...rest });
        }
      }
    }

    default: {
      return value;
    }
  }
}

export function convertProduct({ from, to, product: source, validate = true }) {
  let product = source.set('channel', to);

  function reduceOfferingsForPhysical(physical, offering) {
    return physical && offering.get(PHYSICAL);
  }

  switch (from) {
    case ETSY: {
      switch (to) {
        case ETSY: {
          if (product.getIn([SECTIONS.SHIPPING, PROFILE_ID, VALUE])) {
            product = product
              .setIn([SECTIONS.SHIPPING, PROFILE_ID, VALUE], DEFAULTS.NULL)
              .setIn([SECTIONS.SHIPPING, PROFILE_TYPE, VALUE], DEFAULTS.NULL);
          }

          if (getSize(product.getIn([SECTIONS.DETAILS, PRODUCTION_PARTNERS, VALUE]))) {
            product = product.setIn([SECTIONS.DETAILS, PRODUCTION_PARTNERS, VALUE],
              DEFAULTS.EMPTY_LIST
            );
          }

          if (product.hasIn([SECTIONS.VARIATIONS, 'currentProfile'])) {
            product = product
              .deleteIn([SECTIONS.VARIATIONS, 'operations'])
              .deleteIn([SECTIONS.VARIATIONS, 'oldProfile'])
              .deleteIn([SECTIONS.VARIATIONS, 'currentProfile']);
          }

          if (product.hasIn([SECTIONS.TAGS, 'currentProfile'])) {
            product = product.set(SECTIONS.TAGS,
              Map({
                [VALUE]: ATTRIBUTE_DEFAULTS[to][TAGS],
                [NEW_TAGS]: tagsFromArray(product.getIn([SECTIONS.TAGS, 'currentProfile', TAGS])),
              })
            );
          }

          if (product.getIn([SECTIONS.SHIPPING, RETURN_POLICY, VALUE])) {
            product = product.setIn([SECTIONS.SHIPPING, RETURN_POLICY, VALUE], DEFAULTS.NULL);
          }

          if (product.getIn([SECTIONS.DETAILS, SECTION, VALUE])) {
            product = product.setIn([SECTIONS.DETAILS, SECTION, VALUE], DEFAULTS.NULL);
          }

          break;
        }

        case SHOPIFY: {
          product = product
            .delete(SECTIONS.PERSONALIZATION)
            .delete(SECTIONS.SHIPPING)
            .delete(SECTIONS.FILES)
            .set(SECTIONS.DESCRIPTION,
              Map({
                [VALUE]: convert({
                  from,
                  to,
                  type: DESCRIPTION,
                  value: product.getIn([DESCRIPTION, VALUE]),
                }),
              }),
            )
            .set(SECTIONS.DETAILS, newDetails({ channel: to }))
            .set(SECTIONS.INVENTORY,
              newInventory({ channel: to })
                .setIn([PRICE, VALUE], product.getIn([SECTIONS.INVENTORY, PRICE, VALUE]))
                .setIn([QUANTITY, VALUE], product.getIn([SECTIONS.INVENTORY, QUANTITY, VALUE]))
                .setIn([SKU, VALUE], product.getIn([SECTIONS.INVENTORY, SKU, VALUE]))
            )
            .set(SECTIONS.VARIATIONS,
              convert({
                from,
                to,
                type: VARIATIONS,
                value: product.get(SECTIONS.VARIATIONS),
                product: product,
              })
            );

          if (product.hasIn([SECTIONS.TAGS, 'currentProfile'])) {
            product = product.set(SECTIONS.TAGS,
              Map({
                [VALUE]: ATTRIBUTE_DEFAULTS[to][TAGS],
                [NEW_TAGS]: convert({
                  from,
                  to,
                  type: TAGS,
                  value: tagsFromArray(product.getIn([SECTIONS.TAGS, 'currentProfile', TAGS])),
                }),
              })
            );
          } else {
            product = product.set(SECTIONS.TAGS,
              Map({
                [VALUE]: convert({
                  from,
                  to,
                  type: TAGS,
                  value: product.getIn([SECTIONS.TAGS, TAGS], ATTRIBUTE_DEFAULTS[to][TAGS]),
                }),
                [NEW_TAGS]: convert({
                  from,
                  to,
                  type: TAGS,
                  value: product.getIn([SECTIONS.TAGS, NEW_TAGS], ATTRIBUTE_DEFAULTS[to][TAGS]),
                }),
              })
            );
          }

          if (product.get('productId') === NEW) {
            product = product.setIn([SECTIONS.VARIATIONS, 'photosHidden'], DEFAULTS.TRUE);
          }

          product = product.set(SECTIONS.SEO,
            Map({
              [PAGE_TITLE]: Map({
                [VALUE]: product.getIn([TITLE, VALUE]),
                [OVERSET]: true,
              }),
              [META_DESCRIPTION]: Map({
                [OVERSET]: true,
                [VALUE]: convertBodyHTMLToMetaDescription(product.getIn([DESCRIPTION, VALUE])),
              }),
            })
          );

          if (SEO_URL_HANDLE_ENABLED) {
            product = product.setIn([SECTIONS.SEO, URL_HANDLE],
              Map({ [VALUE]: DEFAULTS.EMPTY_STRING })
            );
          }

          break;
        }

        default: {
          break;
        }
      }

      break;
    }

    case SHOPIFY: {
      switch (to) {
        case ETSY: {
          product = product
            .delete(SECTIONS.SEO)
            .set(DESCRIPTION,
              Map({
                [VALUE]: convert({
                  from,
                  to,
                  type: DESCRIPTION,
                  value: product.getIn([DESCRIPTION, VALUE]),
                }),
              }),
            )
            .set(SECTIONS.DETAILS, newDetails({ channel: to }))
            .set(SECTIONS.INVENTORY,
              newInventory({ channel: to })
                .setIn([PRICE, VALUE], product.getIn([SECTIONS.INVENTORY, PRICE, VALUE]))
                .setIn([QUANTITY, VALUE], product.getIn([SECTIONS.INVENTORY, QUANTITY, VALUE]))
                .setIn([SKU, VALUE], product.getIn([SECTIONS.INVENTORY, SKU, VALUE]))
            );

          if (product.hasIn([SECTIONS.TAGS, 'currentProfile'])) {
            product = product.set(SECTIONS.TAGS,
              Map({
                [VALUE]: ATTRIBUTE_DEFAULTS[to][TAGS],
                [NEW_TAGS]: convert({
                  from,
                  to,
                  type: TAGS,
                  value: tagsFromArray(product.getIn([SECTIONS.TAGS, 'currentProfile', TAGS])),
                }),
              })
            );
          } else {
            product = product.set(SECTIONS.TAGS,
              Map({
                [VALUE]: convert({
                  from,
                  to,
                  type: TAGS,
                  value: product.getIn([SECTIONS.TAGS, TAGS], ATTRIBUTE_DEFAULTS[to][TAGS]),
                }),
                [NEW_TAGS]: convert({
                  from,
                  to,
                  type: TAGS,
                  value: product.getIn([SECTIONS.TAGS, NEW_TAGS], ATTRIBUTE_DEFAULTS[to][TAGS]),
                }),
              })
            );
          }

          const physical = getSize(source.getIn([SECTIONS.VARIATIONS, 'offerings']))
            ? source
              .getIn([SECTIONS.VARIATIONS, 'offerings'])
              .reduce(reduceOfferingsForPhysical, DEFAULTS.TRUE)
            : source.getIn([SECTIONS.INVENTORY, PHYSICAL, VALUE]);

          if (physical) {
            product = product
              .setIn([SECTIONS.DETAILS, DIGITAL, VALUE], DEFAULTS.FALSE)
              .set(SECTIONS.SHIPPING, newShipping({ channel: to }))
              .set(SECTIONS.VARIATIONS,
                convert({
                  from,
                  to,
                  type: VARIATIONS,
                  value: product.get(SECTIONS.VARIATIONS),
                  product: product,
                })
              );
          } else {
            product = product
              .setIn([SECTIONS.DETAILS, DIGITAL, VALUE], DEFAULTS.TRUE)
              .set(SECTIONS.FILES, Map({ [VALUE]: DEFAULTS.EMPTY_LIST }))
              .set(SECTIONS.VARIATIONS,
                newProfile({ channel: to, forProduct: true, type: PROFILE.VARIATIONS })
              );
          }

          product = product.set(PERSONALIZATION, shapePersonalizationForApp({}));

          break;
        }

        case SHOPIFY: {
          if (product.get('productId') === NEW) {
            product = product.setIn([SECTIONS.VARIATIONS, 'photosHidden'], DEFAULTS.TRUE);
          }

          if (getSize(product.getIn([SECTIONS.DETAILS, COLLECTIONS, VALUE]))) {
            product = product.setIn([SECTIONS.DETAILS, COLLECTIONS, VALUE],
              ATTRIBUTE_DEFAULTS[to][COLLECTIONS]
            );
          }

          if (product.hasIn([SECTIONS.VARIATIONS, 'currentProfile'])) {
            product = product
              .deleteIn([SECTIONS.VARIATIONS, 'operations'])
              .deleteIn([SECTIONS.VARIATIONS, 'oldProfile'])
              .deleteIn([SECTIONS.VARIATIONS, 'currentProfile']);
          }

          if (product.hasIn([SECTIONS.TAGS, 'currentProfile'])) {
            product = product.set(SECTIONS.TAGS,
              Map({
                [VALUE]: ATTRIBUTE_DEFAULTS[to][TAGS],
                [NEW_TAGS]: tagsFromArray(product.getIn([SECTIONS.TAGS, 'currentProfile', TAGS])),
              })
            );
          }

          break;
        }

        default: {
          break;
        }
      }

      break;
    }

    default: {
      break;
    }
  }

  return validate
    ? setProductErrors({ product })
    : product;
}
