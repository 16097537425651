import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from '../styles';

import { useMediaQuery } from '../hooks';
import { getFromState } from '../utils/getFromState';
import { navigation } from '../utils/navigation';

import { THEME_NAME, THEMES, VELA_THEME } from '../constants/theme';
import { PAGES } from '../constants/routes';

export const ThemeContext = createContext();

ThemeContext.displayName = 'ThemeContext';

function ContextProvider({ children, velaTheme: saved }) {
  const { pathname } = useLocation();
  const autoDetect = saved === THEME_NAME.AUTO;
  const queryMatches = useMediaQuery({ enabled: autoDetect, query: '(prefers-color-scheme: dark)' });
  const name = useMemo(
    function factory() {
      const { PAGE } = navigation();

      if (PAGE === PAGES.ADMIN) {
        return THEME_NAME.LIGHT;
      }

      if (autoDetect) {
        return queryMatches
          ? THEME_NAME.DARK
          : THEME_NAME.LIGHT;
      }

      switch (saved) {
        case THEME_NAME.DARK:
        case THEME_NAME.LIGHT: {
          return saved;
        }

        default: {
          return THEME_NAME.LIGHT;
        }
      }
    },
    [autoDetect, pathname, queryMatches, saved],
  );

  const theme = THEMES[name];

  return (
    <ThemeContext.Provider value={{ autoDetect, name, theme }}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

ContextProvider.propTypes = {
  children: PropTypes.node,
  velaTheme: PropTypes.oneOf(Object.values(THEME_NAME)),
};

export default connect(getFromState(['user', VELA_THEME]))(ContextProvider);
