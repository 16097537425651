import { ETSY, SHOPIFY } from '../../constants/channels';
import { LISTING, STATUS, STATUS_ORDER } from '../../constants/listings';

export function getOrderForAPI({ channel, order = {}, status }) {
  if (order.by && STATUS_ORDER[channel][status].has(order.by)) {
    return { order_by: order.by, order_type: order.type };
  }

  switch (channel) {
    case ETSY: {
      switch (status) {
        case STATUS.ACTIVE: {
          return { order_by: LISTING[ETSY].EXPIRES_AT, order_type: false };
        }

        case STATUS.COPIED: {
          return { order_by: LISTING[ETSY].CREATED_AT, order_type: false };
        }

        case STATUS.DRAFT: {
          return { order_by: LISTING[ETSY].UPDATED_AT, order_type: false };
        }

        default: {
          return { order_by: LISTING[ETSY].PRODUCT_ID, order_type: false };
        }
      }
    }

    case SHOPIFY: {
      switch (status) {
        case STATUS.ACTIVE: {
          return { order_by: LISTING[SHOPIFY].PUBLISHED_AT, order_type: false };
        }

        case STATUS.DRAFT: {
          return { order_by: LISTING[SHOPIFY].UPDATED_AT, order_type: false };
        }

        default: {
          return { order_by: LISTING[SHOPIFY].PRODUCT_ID, order_type: false };
        }
      }
    }

    default: {
      return { order_by: 'id', order_type: false };
    }
  }
}
