import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useSearch() {
  const [search, setSearch] = useSearchParams();
  return useMemo(
    function factory() {
      const state = {};

      if (search) {
        for (const [key, value] of search) {
          state[key] = value;
        }
      }

      return [
        state,
        setSearch,
      ];
    },
    [search]
  );
}
