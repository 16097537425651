import { toLowerCase } from './string';

import { NONE } from '../constants';

export function sortCaseIgnore(x, y) {
  return toLowerCase(x).localeCompare(toLowerCase(y));
}

export function sortCaseIgnoreWithNone(x, y) {
  return y === NONE
    ? 1
    : sortCaseIgnore(x, y);
}

export function getIndexesArray({ length, start = 0 }) {
  function mapIndexes(index) {
    return index + start;
  }

  return Number.isFinite(length)
    ? start
      ? [...Array(length).keys()].map(mapIndexes)
      : [...Array(length).keys()]
    : [];
}
