import { List, Map, Set } from 'immutable';

import { materialsToString, tagsToList, tagsToString } from '../tags';
import { shapeVariationsProfileForAPI } from '../profiles/shapeForAPI';
import { isObject } from '../isObject';
import { getSize } from '../iterable/getSize';
import { keepOld } from '../iterable/keepOld';

import { FIND, MENU_ITEM, OPERATIONS, REPLACE, SIZE_OPERATIONS } from '../../constants/bulkEdit';
import { ABOUT_OPERATIONS, DIMENSIONS, ERROR, VALUE } from '../../constants/product';
import { MAX_NUMBER_OF_TAGS } from '../../constants/validations';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { WAIT_FOR_SYNC } from '../../constants/shops';
import { OPERATION } from '../../constants/ai';
import { DEFAULTS } from '../../constants';
import {
  BARCODE,
  CATEGORY,
  CHARGE_TAX,
  CHAR_COUNT_MAX,
  COLLECTIONS,
  CONTINUE_SELLING,
  COUNTRY_CODE,
  DESCRIPTION,
  ENABLED,
  FILES,
  HS_CODE,
  INSTRUCTIONS,
  MATERIALS,
  META_DESCRIPTION,
  PAGE_TITLE,
  PERSONALIZATION,
  PHOTOS,
  PRICE,
  PRODUCTION_PARTNERS,
  PRODUCT_TYPE,
  PROFILE_ID,
  QUANTITY,
  REQUIRED,
  RETURN_POLICY,
  SECTION,
  SEO,
  SHIPPING,
  SKU,
  TAGS,
  TITLE,
  TRACK_QUANTITY,
  UNIT,
  URL_HANDLE,
  VARIATIONS,
  VENDOR,
  VIDEOS,
  WEIGHT,
} from '../../constants/attributes';

const { EMPTY_LIST, EMPTY_MAP, EMPTY_ORDERED_MAP, EMPTY_STRING, NULL } = DEFAULTS;

import Actions from '../../actions';

function aiGenerated({ attribute, channel, operation, preview, state }) {
  const page = state.getIn(['page', 'currentPage']);
  const products = state.get('products');

  function getValue({ product, value }) {
    switch (attribute) {
      case TAGS: {
        switch (preview.getIn([attribute, 'operations', page])) {
          case OPERATION.ADD_MAXIMUM: {
            const productValue = (
              preview.getIn([attribute, 'profile', TAGS]) ||
              product.getIn([TAGS, VALUE]) ||
              EMPTY_ORDERED_MAP
            );

            return productValue.mergeWith(keepOld, value).slice(0, MAX_NUMBER_OF_TAGS[channel]).toList();
          }

          default: {
            return value.toList();
          }
        }
      }

      default: {
        return value;
      }
    }
  }

  function reduceProductIds(result, productId) {
    function reducePhotoIndexes(payload, value, index) {
      return payload.push(
        Map({
          type: operation,
          products: List([productId]),
          value: Map({
            attributes: Map({ alt_text: value }),
            index,
          }),
        })
      );
    }

    if (!products.hasIn(['selected', productId])) {
      if (result.preview.hasIn([attribute, 'values', productId])) {
        result.preview = result.preview.deleteIn([attribute, 'values', productId]);
      }
    } else if (result.preview.hasIn([attribute, 'values', productId])) {
      result.edited = result.edited.add(productId);

      switch (attribute) {
        case PHOTOS: {
          result.payload = result.preview
            .getIn([attribute, 'values', productId])
            .reduce(reducePhotoIndexes, result.payload);

          break;
        }

        default: {
          result.payload = result.payload.push(
            Map({
              type: operation,
              products: List([productId]),
              value: getValue({
                value: result.preview.getIn([attribute, 'values', productId]),
                product: products.getIn(['byId', productId]),
              }),
            })
          );

          break;
        }
      }

      result.preview = result.preview.deleteIn([attribute, 'values', productId]);
    }

    if (
      !getSize(result.preview.getIn([attribute, 'operations'])) &&
      !getSize(result.preview.getIn([attribute, 'processing'])) &&
      !getSize(result.preview.getIn([attribute, 'processed'])) &&
      !getSize(result.preview.getIn([attribute, 'values']))
    ) {
      result.preview = EMPTY_MAP;
    }

    return result;
  }

  return products
    .get('ids')
    .reduce(
      reduceProductIds,
      {
        edited: Set(),
        payload: EMPTY_LIST,
        preview: preview
          .deleteIn([attribute, 'operations', page])
          .deleteIn([attribute, 'processed', page])
          .deleteIn([attribute, 'processing', page]),
      },
    );
}

function about({ preview }) {
  function reduceAboutPreview(result, value, type) {
    result.push(Map({ type: ABOUT_OPERATIONS[type], value }));
    return result;
  }

  return {
    payload: preview.get('about').reduce(reduceAboutPreview, []),
  };
}

function barcode({ channel, operation, preview }) {
  return {
    payload: Map({
      type: operation,
      value: List([
        Map({
          combination: null,
          offeringId: null,
          value: operation === OPERATIONS[channel].BARCODE.FIND_AND_REPLACE
            ? Map({
              find: preview.getIn([BARCODE, FIND]),
              replace: preview.getIn([BARCODE, REPLACE]),
            })
            : preview.getIn([BARCODE, VALUE]),
        }),
      ]),
    }),
  };
}

function description({ channel, operation, preview, state }) {
  switch (operation) {
    case OPERATIONS[channel].DESCRIPTION.AI_GENERATED: {
      return aiGenerated({ attribute: DESCRIPTION, operation: OPERATIONS[channel].DESCRIPTION.SET, preview, state });
    }

    case OPERATIONS[channel].DESCRIPTION.FIND_AND_REPLACE: {
      return {
        payload: Map({
          type: operation,
          value: Map({
            find: preview.getIn([DESCRIPTION, FIND]),
            replace: preview.getIn([DESCRIPTION, REPLACE]),
          }),
        }),
      };
    }

    default: {
      return {
        payload: Map({
          type: operation,
          value: preview.getIn([DESCRIPTION, VALUE]),
        }),
      };
    }
  }
}

function category({ channel, preview }) {
  return {
    actions: [Actions.BulkEdit.updateMenuForCategory(preview.get(CATEGORY))],
    payload: Map({ type: OPERATIONS[channel].CATEGORY, value: preview.get(CATEGORY) }),
  };
}

function collections({ channel, preview }) {
  return {
    payload: Map({ type: OPERATIONS[channel].COLLECTIONS, value: preview.get(COLLECTIONS) }),
  };
}

function countryCode({ preview }) {
  return {
    payload: Map({
      type: preview.getIn([SHIPPING, 'operation']),
      value: List([
        Map({
          combination: null,
          offeringId: null,
          value: Map({
            [COUNTRY_CODE]: preview.getIn([SHIPPING, COUNTRY_CODE]),
          }),
        }),
      ]),
    }),
  };
}

function files({ channel, operation, preview }) {
  const actions = [];
  const payload = [];

  for (let index = 0; index < getSize(preview.getIn([FILES, VALUE])); index += 1) {
    switch (operation) {
      case OPERATIONS[channel].FILES.ADD: {
        payload.push(Map({ type: operation, value: preview.getIn([FILES, VALUE, index]) }));

        if (!getSize(actions)) {
          actions.push(Actions.BulkEdit.setData({ waitForSync: WAIT_FOR_SYNC.FINISH }));
        }

        break;
      }

      case OPERATIONS[channel].FILES.REPLACE: {
        const value = preview.getIn([FILES, VALUE, index]);

        if (!value) continue;

        payload.push(Map({ type: operation, value: Map({ targetIndex: index, replacement: value }) }));

        if (!getSize(actions)) {
          actions.push(Actions.BulkEdit.setData({ waitForSync: WAIT_FOR_SYNC.FINISH }));
        }

        break;
      }

      case OPERATIONS[channel].FILES.DELETE: {
        if (!preview.getIn([FILES, VALUE, index])) continue;

        payload.unshift(Map({ type: operation, value: Map({ targetIndex: index }) }));
        break;
      }

      default:
        break;
    }
  }

  return { actions, payload };
}

function hsCode({ preview }) {
  return {
    payload: Map({
      type: preview.getIn([SHIPPING, 'operation']),
      value: List([
        Map({
          combination: null,
          offeringId: null,
          value: Map({
            [HS_CODE]: preview.getIn([SHIPPING, HS_CODE]),
          }),
        }),
      ]),
    }),
  };
}

function materials({ channel, preview }) {
  return {
    payload: Map({
      type: preview.getIn([MATERIALS, 'operation']),
      value: materialsToString({ channel, materials: preview.getIn([MATERIALS, MATERIALS]) }),
    }),
  };
}

function personalization({ channel, operation, preview }) {
  const payload = [];

  if (preview.hasIn([PERSONALIZATION, ENABLED])) {
    payload.push(
      Map({
        type: OPERATIONS[channel].PERSONALIZATION.IS_PERSONALIZABLE_SET,
        value: preview.getIn([PERSONALIZATION, ENABLED]),
      })
    );

    if (!preview.getIn([PERSONALIZATION, ENABLED])) return { payload };
  }

  if (preview.hasIn([PERSONALIZATION, REQUIRED])) {
    payload.push(
      Map({
        type: OPERATIONS[channel].PERSONALIZATION.IS_REQUIRED_SET,
        value: preview.getIn([PERSONALIZATION, REQUIRED]),
      })
    );
  }

  if (
    preview.hasIn([PERSONALIZATION, INSTRUCTIONS]) &&
    !preview.getIn([PERSONALIZATION, INSTRUCTIONS, ERROR])
  ) {
    let value;

    switch (operation) {
      case OPERATIONS[channel].PERSONALIZATION.INSTRUCTIONS_FIND_AND_REPLACE: {
        value = Map({
          find: preview.getIn([PERSONALIZATION, INSTRUCTIONS, FIND]),
          replace: preview.getIn([PERSONALIZATION, INSTRUCTIONS, REPLACE]),
        });

        break;
      }

      default: {
        value = preview.getIn([PERSONALIZATION, INSTRUCTIONS, VALUE]);
        break;
      }
    }

    payload.push(Map({ type: operation, value }));
  }

  if (
    preview.hasIn([PERSONALIZATION, CHAR_COUNT_MAX]) &&
    !preview.getIn([PERSONALIZATION, CHAR_COUNT_MAX, ERROR])
  ) {
    payload.push(
      Map({
        type: OPERATIONS[channel].PERSONALIZATION.CHAR_COUNT_MAX_SET,
        value: preview.getIn([PERSONALIZATION, CHAR_COUNT_MAX, VALUE]),
      })
    );
  }

  return {
    payload,
  };
}

function photos({ channel, operation, preview, state }) {
  function reduceValue(result, value) {
    return result.push(
      isObject(value) && !Map.isMap(value)
        ? Map(value)
        : value
    );
  }

  switch (operation) {
    case OPERATIONS[channel].PHOTOS.ADD:
    case OPERATIONS[channel].PHOTOS.REPLACE: {
      return {
        actions: [Actions.BulkEdit.setData({ waitForSync: WAIT_FOR_SYNC.FINISH })],
        payload: Map({
          type: operation,
          value: preview.getIn([PHOTOS, PHOTOS]).reduce(reduceValue, EMPTY_LIST),
        }),
      };
    }

    case OPERATIONS[channel].PHOTOS.ALT_TEXT: {
      return {
        payload: Map({
          type: OPERATIONS[channel].PHOTOS.UPDATE,
          value: Map({
            attributes: Map({ alt_text: preview.getIn([PHOTOS, VALUE], EMPTY_STRING) }),
            index: preview.getIn([PHOTOS, 'photo']),
          }),
        }),
      };
    }

    case OPERATIONS[channel].PHOTOS.ALT_TEXT_AI_GENERATED: {
      return aiGenerated({ attribute: PHOTOS, operation: OPERATIONS[channel].PHOTOS.UPDATE, preview, state });
    }

    case OPERATIONS[channel].PHOTOS.EDIT: {
      return {
        payload: preview.getIn([PHOTOS, 'operations']),
      };
    }

    default: {
      return {
        payload: Map({
          type: operation,
          value: preview.getIn([PHOTOS, PHOTOS]).reduce(reduceValue, EMPTY_LIST),
        }),
      };
    }
  }
}

function videos({ channel, operation, preview }) {
  switch (operation) {
    case OPERATIONS[channel].VIDEOS.ADD: {
      return {
        actions: [Actions.BulkEdit.setData({ waitForSync: WAIT_FOR_SYNC.FINISH })],
        payload: Map({
          type: operation,
          value: preview.getIn([VIDEOS, VIDEOS])
            .reduce(
              function reduceValue(result, value) {
                return result.push(
                  isObject(value) && !Map.isMap(value)
                    ? Map(value)
                    : value
                );
              },
              EMPTY_LIST
            ),
        }),
      };
    }

    case OPERATIONS[channel].VIDEOS.DELETE: {
      return {
        payload: Map({
          type: operation,
          value: Map({
            index: preview.getIn([VIDEOS, VIDEOS]).keySeq().toList().sort(),
          }),
        }),
      };
    }

    case OPERATIONS[channel].VIDEOS.REPLACE: {
      return {
        actions: [Actions.BulkEdit.setData({ waitForSync: WAIT_FOR_SYNC.FINISH })],
        payload: preview.getIn([VIDEOS, VIDEOS]).reduce(
          function reducePreview(payload, video, index) {
            if (video) {
              payload.push(Map({ type: operation, value: Map({ index, video }) }));
            }

            return payload;
          },
          []
        ),
      };
    }

    default: {
      return {};
    }
  }
}

function price({ channel, preview }) {
  const payload = [];

  if (preview.hasIn([PRICE, 'operation'])) {
    payload.push(
      Map({
        type: preview.getIn([PRICE, 'operation']),
        value: preview.getIn([PRICE, 'conversion'])
          ? List([EMPTY_MAP])
          : List([
            Map({
              combination: null,
              offeringId: null,
              value: Map({
                rounding: preview.getIn([PRICE, VALUE, 'cents']),
                type: preview.getIn([PRICE, VALUE, 'price_type']),
                value: preview.getIn([PRICE, VALUE, VALUE]),
              }),
            }),
          ]),
      })
    );
  }

  if (preview.hasIn([PRICE, CHARGE_TAX])) {
    payload.push(
      Map({
        type: OPERATIONS[channel].CHARGE_TAX.SET,
        value: List([
          Map({
            combination: null,
            offeringId: null,
            value: Map({
              taxable: preview.getIn([PRICE, CHARGE_TAX]),
            }),
          }),
        ]),
      })
    );
  }

  return { payload };
}

function productType({ channel, preview, shopId }) {
  const value = preview.get(PRODUCT_TYPE);
  return {
    actions: [Actions.Data.addProductType({ shopId, productType: value })],
    payload: Map({ type: OPERATIONS[channel].PRODUCT_TYPE, value }),
  };
}

function productionPartners({ channel, preview }) {
  return {
    payload: Map({
      type: OPERATIONS[channel].PRODUCTION_PARTNERS,
      value: preview.get(PRODUCTION_PARTNERS),
    }),
  };
}

function quantity({ channel, preview }) {
  const payload = [];

  if (preview.hasIn([QUANTITY, CONTINUE_SELLING])) {
    payload.push(
      Map({
        type: OPERATIONS[channel].CONTINUE_SELLING.SET,
        value: List([
          Map({
            combination: null,
            offeringId: null,
            value: Map({
              inventoryPolicy: preview.getIn([QUANTITY, CONTINUE_SELLING])
                ? 'continue'
                : 'deny',
            }),
          }),
        ]),
      })
    );
  }

  if (preview.hasIn([QUANTITY, TRACK_QUANTITY])) {
    payload.push(
      Map({
        type: OPERATIONS[channel].TRACK_QUANTITY.SET,
        value: List([
          Map({
            combination: null,
            offeringId: null,
            value: Map({
              inventoryManagement: preview.getIn([QUANTITY, TRACK_QUANTITY])
                ? SHOPIFY
                : NULL,
            }),
          }),
        ]),
      })
    );
  }

  if (
    preview.hasIn([QUANTITY, 'operation']) && (
      !preview.hasIn([QUANTITY, TRACK_QUANTITY]) ||
      preview.getIn([QUANTITY, TRACK_QUANTITY])
    )
  ) {
    payload.push(
      Map({
        type: preview.getIn([QUANTITY, 'operation']),
        value: List([
          Map({
            combination: null,
            offeringId: null,
            value: preview.getIn([QUANTITY, VALUE]),
          }),
        ]),
      })
    );
  }

  return { payload };
}

function returnPolicy({ preview }) {
  return {
    payload: Map({
      type: preview.getIn([SHIPPING, 'operation']),
      value: preview.getIn([SHIPPING, RETURN_POLICY]),
    }),
  };
}

function section({ channel, preview, shopId }) {
  const actions = [];
  const value = preview.get(SECTION);

  if (preview.get('index') === -1) {
    actions.push(Actions.Data.addSection({ shopId, section: value }));
  }

  return {
    actions,
    payload: Map({ type: OPERATIONS[channel].SECTION, value }),
  };
}

function shippingDimensions({ channel, operation, preview }) {
  function makeOperation(result, attribute) {
    if (preview.getIn([SHIPPING, attribute])) {
      result.push(
        Map({
          type: SIZE_OPERATIONS[operation][attribute],
          value: preview.getIn([SHIPPING, attribute]),
        })
      );
    }

    return result;
  }

  return {
    payload: DIMENSIONS[channel].reduce(makeOperation, []),
  };
}

function shippingProfile({ preview }) {
  return {
    payload: Map({
      type: preview.getIn([SHIPPING, 'operation']),
      value: preview.getIn([SHIPPING, PROFILE_ID]),
    }),
  };
}

function shippingWeight({ operation, preview }) {
  return {
    payload: Map({
      type: operation,
      value: preview.getIn([SHIPPING, WEIGHT]),
    }),
  };
}

function sku({ channel, operation, preview }) {
  return {
    payload: Map({
      type: operation,
      value: List([
        Map({
          combination: null,
          offeringId: null,
          value: operation === OPERATIONS[channel].SKU.FIND_AND_REPLACE
            ? Map({
              find: preview.getIn([SKU, FIND]),
              replace: preview.getIn([SKU, REPLACE]),
            })
            : preview.getIn([SKU, VALUE]),
        }),
      ]),
    }),
  };
}

function tags({ channel, operation, preview, state }) {
  switch (operation) {
    case OPERATIONS[channel].TAGS.AI_GENERATED: {
      return aiGenerated({ attribute: TAGS, channel, operation: OPERATIONS[channel].TAGS.CHANGE_TO, preview, state });
    }

    default: {
      const payload = [];

      switch (operation) {
        case OPERATIONS[channel].TAGS.CHANGE_TO: {
          if (getSize(preview.getIn([TAGS, TAGS]))) {
            payload.push(
              Map({
                type: operation,
                value: tagsToList({ channel, tags: preview.getIn([TAGS, TAGS]) }),
              })
            );
          } else if (preview.hasIn([TAGS, 'profile'])) {
            payload.push(
              Map({
                tagsProfileId: preview.getIn([TAGS, 'profile', 'profileId']),
                type: OPERATIONS[channel].TAGS.CHANGE_TO,
                value: tagsToList({ channel, tags: preview.getIn([TAGS, 'profile', TAGS]) }),
              })
            );
          }

          break;
        }

        default: {
          if (preview.hasIn([TAGS, 'profile'])) {
            payload.push(
              Map({
                tagsProfileId: preview.getIn([TAGS, 'profile', 'profileId']),
                type: OPERATIONS[channel].TAGS.CHANGE_TO,
                value: tagsToList({ channel, tags: preview.getIn([TAGS, 'profile', TAGS]) }),
              })
            );
          }

          if (getSize(preview.getIn([TAGS, TAGS]))) {
            payload.push(
              Map({
                type: operation,
                value: tagsToString({ channel, tags: preview.getIn([TAGS, TAGS]) }),
              })
            );
          }

          break;
        }
      }

      return {
        payload,
      };
    }
  }
}

function taxonomyAttributes({ channel, preview, selectedMenuItem }) {
  return {
    payload: Map({
      type: OPERATIONS[channel].TAXONOMY_ATTRIBUTES,
      value: Map({
        [selectedMenuItem]: Map({
          values: preview.getIn([selectedMenuItem, 'values'], EMPTY_LIST),
          valueIds: preview.getIn([selectedMenuItem, 'valueIds'], EMPTY_LIST),
          scaleId: preview.getIn([selectedMenuItem, 'scaleId']) || null,
        }),
      }),
    }),
  };
}

function title({ channel, operation, preview, state }) {
  switch (operation) {
    case OPERATIONS[channel].TITLE.AI_GENERATED: {
      return aiGenerated({ attribute: TITLE, operation: OPERATIONS[channel].TITLE.SET, preview, state });
    }

    case OPERATIONS[channel].TITLE.FIND_AND_REPLACE: {
      return {
        payload: Map({
          type: operation,
          value: Map({
            find: preview.getIn([TITLE, FIND]),
            replace: preview.getIn([TITLE, REPLACE]),
          }),
        }),
      };
    }

    default: {
      return {
        payload: Map({
          type: operation,
          value: preview.getIn([TITLE, VALUE]),
        }),
      };
    }
  }
}

function variations({ channel, preview }) {
  let payload = shapeVariationsProfileForAPI({
    profile: preview.get(VARIATIONS),
    toImmutable: true,
  })
    .set('type', OPERATIONS[channel].VARIATIONS)
    .delete('editMode')
    .delete('isActive')
    .delete('isUniqueProfile')
    .delete('invalid')
    .delete('title');

  if (channel === ETSY) {
    payload = payload.delete('templateId');
  }

  return {
    payload,
  };
}

function vendor({ channel, preview, shopId }) {
  const value = preview.get(VENDOR);
  return {
    actions: [Actions.Data.addVendor({ shopId, vendor: value })],
    payload: Map({ type: OPERATIONS[channel].VENDOR, value }),
  };
}

function weight({ preview }) {
  return {
    payload: Map({
      type: preview.getIn([SHIPPING, 'operation']),
      value: List([
        Map({
          combination: null,
          offeringId: null,
          value: Map({
            [WEIGHT]: preview.getIn([SHIPPING, WEIGHT]),
            [UNIT]: preview.getIn([SHIPPING, UNIT]),
          }),
        }),
      ]),
    }),
  };
}

function seo({ attribute, findAndReplaceOperation, preview }) {
  const type = preview.getIn([SEO, attribute, 'operation']);
  return {
    payload: Map({
      type,
      value: type === findAndReplaceOperation
        ? Map({
          find: preview.getIn([SEO, attribute, FIND]),
          replace: preview.getIn([SEO, attribute, REPLACE]),
        })
        : preview.getIn([SEO, attribute, VALUE], EMPTY_STRING),
    }),
  };
}

export function applyPreviewOperations(bulkEdit) {
  const preview = bulkEdit.get('preview');

  if (!getSize(preview)) {
    return {};
  }

  const channel = bulkEdit.get('channel');
  const shopId = bulkEdit.get('shopId');
  const selectedMenuItem = bulkEdit.getIn(['menu', 'selected', 'item']);
  const operation = bulkEdit.getIn(['menu', 'operation']);

  switch (selectedMenuItem) {
    case MENU_ITEM.ABOUT: {
      return about({ preview });
    }

    case MENU_ITEM.BARCODE: {
      return barcode({ channel, operation, preview });
    }

    case MENU_ITEM.BODY_HTML:
    case MENU_ITEM.DESCRIPTION: {
      return description({ channel, operation, preview, state: bulkEdit });
    }

    case MENU_ITEM.CAP:
    case MENU_ITEM.CPI:
    case MENU_ITEM.PRICE: {
      return price({ channel, preview });
    }

    case MENU_ITEM.CATEGORY: {
      return category({ channel, preview });
    }

    case MENU_ITEM.COLLECTIONS: {
      return collections({ channel, preview });
    }

    case MENU_ITEM.COUNTRY_CODE: {
      return countryCode({ preview });
    }

    case MENU_ITEM.FILES: {
      return files({ channel, operation, preview });
    }

    case MENU_ITEM.HS_CODE: {
      return hsCode({ preview });
    }

    case MENU_ITEM.ITEM_SIZE: {
      return shippingDimensions({ channel, operation, preview });
    }

    case MENU_ITEM.ITEM_WEIGHT: {
      return shippingWeight({ operation, preview });
    }

    case MENU_ITEM.MATERIALS: {
      return materials({ channel, preview });
    }

    case MENU_ITEM.PHOTOS: {
      return photos({ channel, operation, preview, state: bulkEdit });
    }

    case MENU_ITEM.VIDEOS: {
      return videos({ channel, operation, preview });
    }

    case MENU_ITEM.PRODUCT_TYPE: {
      return productType({ channel, preview, shopId });
    }

    case MENU_ITEM.PRODUCTION_PARTNER: {
      return productionPartners({ channel, preview });
    }

    case MENU_ITEM.QUANTITY: {
      return quantity({ channel, preview });
    }

    case MENU_ITEM.RETURN_POLICY: {
      return returnPolicy({ preview });
    }

    case MENU_ITEM.SECTION: {
      return section({ channel, preview, shopId });
    }

    case MENU_ITEM.SHIPPING_PROFILE: {
      return shippingProfile({ preview });
    }

    case MENU_ITEM.SKU: {
      return sku({ channel, operation, preview });
    }

    case MENU_ITEM.TAGS: {
      return tags({ channel, operation, preview, state: bulkEdit });
    }

    case MENU_ITEM.TITLE: {
      return title({ channel, operation, preview, state: bulkEdit });
    }

    case MENU_ITEM.VARIATIONS: {
      return preview.has(CATEGORY)
        ? category({ channel, preview })
        : variations({ channel, preview });
    }

    case MENU_ITEM.VENDOR: {
      return vendor({ channel, preview, shopId });
    }

    case MENU_ITEM.WEIGHT: {
      return weight({ preview });
    }

    case MENU_ITEM.PERSONALIZATION: {
      return personalization({ channel, operation, preview });
    }

    case MENU_ITEM.PAGE_TITLE: {
      return seo({
        attribute: PAGE_TITLE,
        findAndReplaceOperation: OPERATIONS[SHOPIFY].PAGE_TITLE.FIND_AND_REPLACE,
        preview,
      });
    }

    case MENU_ITEM.META_DESCRIPTION: {
      return seo({
        attribute: META_DESCRIPTION,
        findAndReplaceOperation: OPERATIONS[SHOPIFY].META_DESCRIPTION.FIND_AND_REPLACE,
        preview,
      });
    }

    case MENU_ITEM.URL_HANDLE: {
      return seo({
        attribute: URL_HANDLE,
        findAndReplaceOperation: OPERATIONS[SHOPIFY].URL_HANDLE.FIND_AND_REPLACE,
        preview,
      });
    }

    default: {
      if (bulkEdit.getIn(['menu', 'items', selectedMenuItem, 'isTaxonomy'])) {
        return taxonomyAttributes({ channel, preview, selectedMenuItem });
      }

      return {};
    }
  }
}
