import { ATTRIBUTE_DEFAULTS, SECTIONS, VALUE } from '../../constants/product';
import { PRICE, QUANTITY, SKU } from '../../constants/attributes';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { DEFAULTS } from '../../constants';

export function setDefaultValues({ channel, profile, product }) {
  function setDefaultValue(attributes) {
    return function map(source) {
      let offering = source;

      for (const attribute in attributes) {
        if (attributes.hasOwnProperty(attribute)) {
          if (offering.get(attribute) === DEFAULTS.NULL) {
            offering = offering.set(attribute, attributes[attribute]);
          }
        }
      }

      return offering;
    };
  }

  switch (channel) {
    case ETSY: {
      const attributes = {
        [PRICE]: product.getIn([SECTIONS.INVENTORY, PRICE, VALUE]),
        [QUANTITY]: product.getIn([SECTIONS.INVENTORY, QUANTITY, VALUE]),
        [SKU]: product.getIn([SECTIONS.INVENTORY, SKU, VALUE]),
      };

      const offerings = profile.get('offerings').map(setDefaultValue(attributes));
      return profile.set('offerings', offerings);
    }

    case SHOPIFY: {
      const offerings = profile.get('offerings').map(setDefaultValue(ATTRIBUTE_DEFAULTS[channel]));
      return profile.set('offerings', offerings);
    }

    default: {
      return profile;
    }
  }
}
