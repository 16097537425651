import { isImmutable } from 'immutable';

import { isObject } from '../isObject';

export function forEach(instance, callback) {
  if (isImmutable(instance)) {
    let index = 0;
    instance.forEach((value, key) => callback(value, key, index++));
  } else if (Array.isArray(instance) || instance instanceof NodeList) {
    for (let index = 0; index < instance.length; index++) {
      callback(instance[index], index);
    }
  } else if (isObject(instance)) {
    let index = 0;

    for (const key in instance) {
      if (instance.hasOwnProperty(key)) {
        callback(instance[key], key, index);
        index += 1;
      }
    }
  }
}
