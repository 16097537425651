import { getOfferingsErrors } from './../validations/profile';
import { setDefaultValues } from './setDefaultValues';
import { updateIndividual } from './updateIndividual';
import { retainPhotos } from './retainPhotos';
import { isTruthy } from './../bool';
import { getSize } from '../iterable/getSize';

import { ATTRIBUTE_DEFAULTS, SECTIONS, VALUE } from '../../constants/product';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { DEFAULTS } from '../../constants';
import {
  INDIVIDUAL_FLAGS,
  INDIVIDUAL_FLAGS_MAP,
  INDIVIDUAL_TO_ATTRIBUTE_MAP,
  VALUES_TO_RETAIN,
} from '../../constants/profiles';
import {
  CHARGE_TAX,
  CONTINUE_SELLING,
  COUNTRY_CODE,
  HS_CODE,
  PHYSICAL,
  QUANTITY,
  TRACK_QUANTITY,
  UNIT,
  VARIATIONS,
  WEIGHT,
} from '../../constants/attributes';

const { EMPTY_LIST, EMPTY_MAP, NULL } = DEFAULTS;
const { INVENTORY } = SECTIONS;

function retainEtsyAttributes({ product, profile, validate }) {
  function reduceVariations(result, profileVariation, varationIndex) {
    if (!result.shouldRetainPhotos) return result;

    const productVariation = product.getIn([VARIATIONS, VARIATIONS, varationIndex], EMPTY_MAP);

    result.shouldRetainPhotos = (
      profileVariation.get('formattedName') === productVariation.get('formattedName')
    );

    if (!result.shouldRetainPhotos) return result;

    function reduceAttributes(attributes, individual) {
      const attribute = INDIVIDUAL_TO_ATTRIBUTE_MAP[individual];

      function updateFlags(flags = EMPTY_MAP) {
        return flags.set(attribute, productVariation.get(individual));
      }

      result.variationsFlags = result.variationsFlags.updateIn([varationIndex], updateFlags);

      return attributes.get(attribute) === false
        ? attributes
        : attributes.set(attribute, !profileVariation.get(individual));
    }

    result.attributes = INDIVIDUAL_FLAGS[ETSY].reduce(reduceAttributes, result.attributes);
    result.shouldRetainAttributes = !!getSize(result.attributes.filter(isTruthy));

    return result;
  }
  const {
    attributes,
    shouldRetainAttributes,
    shouldRetainPhotos,
    variationsFlags,
  } = profile.get(VARIATIONS).reduce(
    reduceVariations,
    {
      attributes: EMPTY_MAP,
      shouldRetainAttributes: false,
      shouldRetainPhotos: true,
      variationsFlags: EMPTY_LIST,
    }
  );

  if (!shouldRetainPhotos) return setDefaultValues({ channel: ETSY, profile, product });

  function mapVariations(source, variationIndex) {
    let variation = source;
    const productVariation = product.getIn([VARIATIONS, VARIATIONS, variationIndex]);

    function retainVariationFlags(result, value, attribute) {
      if (!value) return result;

      const flag = INDIVIDUAL_FLAGS_MAP[attribute];

      return result.set(flag, productVariation.get(flag));
    }

    if (productVariation) {
      if (shouldRetainAttributes) {
        variation = attributes.reduce(retainVariationFlags, variation);
      }

      const profileOptions = variation.get('options');
      const productOptions = productVariation.get('options');
      variation = variation.set('options', retainPhotos({ profileOptions, productOptions }));
    }

    return variation;
  }

  function mapOfferings(offering, offeringIndex) {
    function reduceAttributes(result, value, attribute) {
      if (!value) return result;

      function isMatching(productOffering) {
        function reduceOptions(match, option, optionIndex) {
          return match && (
            !variationsFlags.getIn([optionIndex, attribute]) ||
            option.get('name') === offering.getIn(['options', optionIndex, 'name'])
          );
        }

        return productOffering.get('options').reduce(reduceOptions, true);
      }

      const sameIndexOffering = product.getIn([VARIATIONS, 'offerings', offeringIndex]);
      const productOffering = sameIndexOffering && isMatching(sameIndexOffering, offeringIndex)
        ? sameIndexOffering
        : product.getIn([VARIATIONS, 'offerings']).find(isMatching);

      const offeringValue = productOffering
        ? productOffering.get(attribute)
        : product.getIn([INVENTORY, attribute, VALUE]);

      return result.set(attribute, offeringValue);
    }

    return attributes.reduce(reduceAttributes, offering);
  }

  const variations = profile.get(VARIATIONS).map(mapVariations);

  const offerings = shouldRetainAttributes
    ? profile.get('offerings').map(mapOfferings)
    : profile.get('offerings');

  const result = updateIndividual(
    profile
      .set('offerings', offerings)
      .set(VARIATIONS, variations)
  );

  return validate
    ? result.set('errors', getOfferingsErrors(result))
    : result;
}

function retainShopifyAttributes({ product, profile, validate }) {
  function mapOfferings(offering, offeringIndex) {
    function shouldRetainValue(attribute) {
      switch (attribute) {
        case 'imageId':
        case 'imageHash':
        case CHARGE_TAX:
        case CONTINUE_SELLING: {
          return true;
        }

        case PHYSICAL: {
          return (
            !offering.get(attribute) || (
              offering.get(COUNTRY_CODE) === NULL &&
              offering.get(HS_CODE) === NULL &&
              offering.get(WEIGHT) === NULL &&
              offering.get(UNIT) === ATTRIBUTE_DEFAULTS[SHOPIFY][UNIT]
            )
          );
        }

        case TRACK_QUANTITY: {
          return offering.get(QUANTITY) === NULL;
        }

        case UNIT: {
          return offering.get(UNIT) === ATTRIBUTE_DEFAULTS[SHOPIFY][UNIT];
        }

        default: {
          return offering.get(attribute) === NULL;
        }
      }
    }

    function reduceAttributes(result, attribute) {
      if (!shouldRetainValue(attribute)) return result;

      function isMatching(productOffering) {
        function reduceOptions(match, option, optionIndex) {
          return match && option.get('name') === offering.getIn(['options', optionIndex, 'name']);
        }

        return productOffering.get('options').reduce(reduceOptions, true);
      }

      const sameIndexOffering = product.getIn([VARIATIONS, 'offerings', offeringIndex]);
      const productOffering = sameIndexOffering && isMatching(sameIndexOffering, offeringIndex)
        ? sameIndexOffering
        : product.getIn([VARIATIONS, 'offerings']).find(isMatching);

      const offeringValue = productOffering
        ? productOffering.get(attribute)
        : attribute in ATTRIBUTE_DEFAULTS[SHOPIFY]
          ? ATTRIBUTE_DEFAULTS[SHOPIFY][attribute]
          : NULL;

      return result.set(attribute, offeringValue);
    }

    return VALUES_TO_RETAIN[SHOPIFY].reduce(reduceAttributes, offering);
  }

  const result = updateIndividual(profile.set('offerings', profile.get('offerings').map(mapOfferings)));

  return validate
    ? result.set('errors', getOfferingsErrors(result))
    : result;
}

export function retain({ channel, product, profile, validate = true }) {
  switch (channel) {
    case ETSY: {
      return retainEtsyAttributes({ product, profile, validate });
    }

    case SHOPIFY: {
      return retainShopifyAttributes({ product, profile, validate });
    }

    default: {
      return profile;
    }
  }
}
