import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import ReactModal from 'react-modal';

import { Admin, Root } from './pages';

import Router from './Router';

import ShopsContext from './contexts/shops';
import ThemeContext from './contexts/theme';
import UserContext from './contexts/user';

import { history } from './utils/navigation';
import { store } from './store';

import { ROUTES } from './constants/routes';

import './styles/fonts.css';

ReactModal.setAppElement('body');

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.VELA_ENVIRONMENT,
});

export default function Application() {
  return (
    <StoreProvider store={store}>
      <DndProvider backend={HTML5Backend}>
        <UserContext>
          <ShopsContext>
            <Router history={history}>
              <ThemeContext>
                <Routes>
                  <Route path={`${ROUTES.ADMIN.ROOT}${ROUTES.WILDCARD}`} Component={Admin} />
                  <Route path={ROUTES.WILDCARD} Component={Root} />
                </Routes>
              </ThemeContext>
            </Router>
          </ShopsContext>
        </UserContext>
      </DndProvider>
    </StoreProvider>
  );
}
