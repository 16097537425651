export const EVENT_EDIT_TYPE = {
  SINGLE: 'single',
  BULK: 'bulk',
};

export const EVENT_VIDEO_TYPE = {
  VIDEO: 'video',
  URL: 'url',
};

export const VIDEO_TYPE = {
  AWS_S3: 's3',
  ETSY: 'etsy',
  SHOPIFY: 'shopify',
  YOUTUBE: 'youtube',
  VIMEO: 'vimeo',
};

export const MIME = {
  MP4: 'video/mp4',
  QUICKTIME: 'video/quicktime',
};

export const EXT = {
  MP4: 'mp4',
  MOV: 'mov',
};

export const MIME_TO_EXT = {
  [MIME.MP4]: EXT.MP4,
  [MIME.QUICKTIME]: EXT.MOV,
};

export const CONTROLS_TITLES = {
  remove: 'Delete video',
};
