
import { getOfferingsErrors, getOptionsErrors, getVariationsErrors } from './../validations/profile';
import { updateIndividual } from './updateIndividual';
import { updateOfferings } from './updateOfferings';

export function updateVariations({
  profile: source,
  product,
  validateOfferings = false,
  validateOptions = false,
  variations,
}) {
  let profile = source;
  const offerings = updateOfferings({ profile, variations, product });
  profile = profile.set('offerings', offerings).set('variations', variations);
  profile = profile.set('errors', getVariationsErrors(profile));

  if (validateOptions) {
    profile = profile.set('errors', getOptionsErrors(profile));
  }

  if (validateOfferings) {
    profile = profile.set('errors', getOfferingsErrors(profile));
  }

  return updateIndividual(profile);
}
