import request from './request';

import { ENDPOINT } from '../../constants/api';

export default {
  getCredits() {
    return request.get({
      endpoint: ENDPOINT.AUTH,
      url: '/referrals/credits',
    });
  },
  getHistory() {
    return request.get({
      endpoint: ENDPOINT.AUTH,
      url: '/referrals/history',
    });
  },
  getLink() {
    return request.get({
      endpoint: ENDPOINT.AUTH,
      url: '/referrals/link',
    });
  },
  getPayout() {
    return request.get({
      endpoint: ENDPOINT.AUTH,
      url: '/referrals/payout',
    });
  },
  getPayouts() {
    return request.get({
      endpoint: ENDPOINT.AUTH,
      url: '/referrals/payouts',
    });
  },
  getPayoutsDetails(payoutId) {
    return request.get({
      endpoint: ENDPOINT.AUTH,
      url: `/referrals/payouts/${payoutId}`,
    });
  },
  requestPayout(payload) {
    return request.post({
      endpoint: ENDPOINT.AUTH,
      url: '/referrals/payout',
      payload,
    });
  },
  sendApply(payload) {
    return request.post({
      endpoint: ENDPOINT.AUTH,
      url: '/referrals/affiliates/survey',
      payload,
    });
  },
  getApplicants() {
    return request.get({
      endpoint: ENDPOINT.AUTH,
      url: '/referrals/applicants',
    });
  },
  activateApplicant(userId) {
    return request.post({
      endpoint: ENDPOINT.AUTH,
      url: `/referrals/applicants/activate/${userId}`,
    });
  },
  rejectApplicant(userId) {
    return request.post({
      endpoint: ENDPOINT.AUTH,
      url: `/referrals/applicants/reject/${userId}`,
    });
  },
  markPayoutAsPaid(payoutId) {
    return request.put({
      endpoint: ENDPOINT.AUTH,
      url: `/referrals/payouts/${payoutId}`,
    });
  },
  getReferrals() {
    return request.get({
      endpoint: ENDPOINT.AUTH,
      url: '/referrals/balance',
    });
  },
  getReferralsDetails(userId) {
    return request.get({
      endpoint: ENDPOINT.AUTH,
      url: `/referrals/${userId}/credits`,
    });
  },
  redeem(redeemId, userId) {
    return request.post({
      endpoint: ENDPOINT.AUTH,
      url: `/referrals/${userId}/redeem/${redeemId}`,
    });
  },
};
