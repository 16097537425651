import Big from 'big.js';

import { getBarcodeError, getCAPError, getCPIError, getPriceError, getQuantityError, getSKUError } from '../validations/inventory';
import { getHeightError, getLengthError, getWeightError, getWidthError } from '../validations/shipping';
import { getCharactersCountError, getInstructionsError } from '../validations/personalization';
import { getDescriptionError } from '../validations/description';
import { getTitleError } from '../validations/title';
import { getSize } from '../iterable/getSize';
import { toJS } from '../iterable/toJS';

import { FILTER, MENU_ITEM } from '../../constants/bulkEdit';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { SELECTION } from '../../constants/listings';
import { DEFAULTS } from '../../constants';
import {
  BARCODE,
  CAP,
  CHAR_COUNT_MAX,
  COUNTRY_CODE,
  CPI,
  DESCRIPTION,
  DIGITAL,
  HEIGHT,
  HS_CODE,
  INSTRUCTIONS,
  LENGTH,
  PHYSICAL,
  PRICE,
  PROFILE_ID,
  QUANTITY,
  RETURN_POLICY,
  SKU,
  TITLE,
  TRACK_QUANTITY,
  UNIT,
  WEIGHT,
  WIDTH,
} from '../../constants/attributes';

const { EMPTY_MAP } = DEFAULTS;

export function getMenuItemByAttribute({ attribute, channel }) {
  switch (attribute) {
    case BARCODE: {
      return MENU_ITEM.BARCODE;
    }

    case CAP: {
      return MENU_ITEM.CAP;
    }

    case COUNTRY_CODE: {
      return MENU_ITEM.COUNTRY_CODE;
    }

    case CPI: {
      return MENU_ITEM.CPI;
    }

    case HEIGHT:
    case LENGTH:
    case WIDTH: {
      return MENU_ITEM.ITEM_SIZE;
    }

    case HS_CODE: {
      return MENU_ITEM.HS_CODE;
    }

    case PRICE: {
      return MENU_ITEM.PRICE;
    }

    case PROFILE_ID: {
      return MENU_ITEM.SHIPPING_PROFILE;
    }

    case QUANTITY:
    case TRACK_QUANTITY: {
      return MENU_ITEM.QUANTITY;
    }

    case SKU: {
      return MENU_ITEM.SKU;
    }

    case UNIT: {
      return MENU_ITEM.WEIGHT;
    }

    case WEIGHT: {
      switch (channel) {
        case ETSY: {
          return MENU_ITEM.ITEM_WEIGHT;
        }

        case SHOPIFY: {
          return MENU_ITEM.WEIGHT;
        }

        default: {
          return undefined;
        }
      }
    }

    case RETURN_POLICY: {
      return MENU_ITEM.RETURN_POLICY;
    }

    default: {
      return undefined;
    }
  }
}

export function getError({ type, value, ...rest }) {
  switch (type) {
    case BARCODE: {
      return getBarcodeError({ barcode: value, ...rest });
    }

    case CAP: {
      return getCAPError({ cap: value, ...rest });
    }

    case CHAR_COUNT_MAX: {
      return getCharactersCountError({ value, ...rest });
    }

    case CPI: {
      return getCPIError({ cpi: value, ...rest });
    }

    case DESCRIPTION: {
      return getDescriptionError({ value, ...rest });
    }

    case HEIGHT: {
      return getHeightError({ height: value, ...rest });
    }

    case INSTRUCTIONS: {
      return getInstructionsError({ value, ...rest });
    }

    case LENGTH: {
      return getLengthError({ length: value, ...rest });
    }

    case PRICE: {
      return getPriceError({ price: value, ...rest });
    }

    case QUANTITY: {
      return getQuantityError({ quantity: value, ...rest });
    }

    case SKU: {
      return getSKUError({ sku: value, ...rest });
    }

    case TITLE: {
      return getTitleError({ value, ...rest });
    }

    case WEIGHT: {
      return getWeightError({ weight: value, ...rest });
    }

    case WIDTH: {
      return getWidthError({ width: value, ...rest });
    }

    default: {
      return false;
    }
  }
}

export function getNumberValue({ decimals, operationType, operationValue, productValue }) {
  const type = (/[^\.]+$/.exec(operationType) || [])[0];
  const number = new Big(operationValue || 0);
  let result = new Big(productValue || 0);

  switch (type) {
    case 'set':
    case 'changeTo': {
      if (number) {
        result = number;
      }

      break;
    }

    case 'increaseBy': {
      result = result.plus(number);
      break;
    }

    case 'decreaseBy': {
      result = result.minus(number);
      break;
    }

    default:
      break;
  }

  return Number.isFinite(decimals)
    ? result.toFixed(decimals)
    : result.toString();
}

export function getPriceValue({ absolute, cents, operationType, operationValue, productValue }) {
  const type = (/[^\.]+$/.exec(operationType) || [])[0];
  const number = new Big(operationValue || 0);
  const hundred = Big(100);
  let result = new Big(productValue || 0);

  switch (type) {
    case 'set':
    case 'changeTo': {
      if (number) {
        result = number;
      }

      break;
    }

    case 'increaseBy': {
      if (number.gt(0)) {
        if (absolute) {
          result = result.plus(number);
        } else {
          result = result.plus(result.times(number.div(hundred)));
        }
      }

      break;
    }

    case 'decreaseBy': {
      if (number.gt(0)) {
        if (absolute) {
          result = result.minus(number);
        } else {
          result = result.minus(result.times(number.div(hundred)));
        }
      }

      break;
    }

    default:
      break;
  }

  if (cents) {
    result = result.round(0, 0)[result.s > 0 ? 'plus' : 'minus'](new Big(cents).div(hundred));
  }

  return result.toFixed(2);
}

function shouldAddMenuItemFilters({ item, menu }) {
  switch (item) {
    case MENU_ITEM.FILES: {
      return menu.get('type') !== DIGITAL;
    }

    case MENU_ITEM.ITEM_SIZE:
    case MENU_ITEM.ITEM_WEIGHT:
    case MENU_ITEM.RETURN_POLICY:
    case MENU_ITEM.SHIPPING_PROFILE:
    case MENU_ITEM.VARIATIONS: {
      return menu.get('type') !== PHYSICAL;
    }

    default: {
      if (menu.getIn(['items', item, 'isTaxonomy'])) {
        return getSize(menu.getIn(['items', item, 'filters'])) > 0;
      }

      return false;
    }
  }
}

export function updateFiltersForMenuItem({ channel, filters, item: current, menu, products }) {
  const previous = menu.getIn(['selected', 'item']);

  if (!shouldAddMenuItemFilters({ item: current, menu }) && shouldAddMenuItemFilters({ item: previous, menu })) {
    if (!filters.get(FILTER[channel].TITLE)) {
      return filters.set('offset', 0).set('ids', products.get('all'));
    }

    return filters.set('offset', 0).delete('ids');
  }

  switch (current) {
    case MENU_ITEM.FILES: {
      return (
        shouldAddMenuItemFilters({ item: current, menu }) &&
        menu.getIn(['items', previous, 'filters', FILTER[channel].IS_DIGITAL]) !== true
      )
        ? filters.set('offset', 0).set('ids', products.get(DIGITAL))
        : undefined;
    }

    case MENU_ITEM.ITEM_SIZE:
    case MENU_ITEM.ITEM_WEIGHT:
    case MENU_ITEM.RETURN_POLICY:
    case MENU_ITEM.SHIPPING_PROFILE:
    case MENU_ITEM.VARIATIONS: {
      return (
        shouldAddMenuItemFilters({ item: current, menu }) &&
        menu.getIn(['items', previous, 'filters', FILTER[channel].IS_DIGITAL]) !== false
      )
        ? filters.set('offset', 0).set('ids', products.get(PHYSICAL))
        : undefined;
    }

    default: {
      if (menu.getIn(['items', current, 'isTaxonomy'])) {
        if (!shouldAddMenuItemFilters({ item: current, menu })) {
          if (shouldAddMenuItemFilters({ item: previous, menu })) {
            if (!filters.get(FILTER[channel].TITLE)) {
              return filters.set('offset', 0).set('ids', products.get('all'));
            } else {
              return filters.set('offset', 0).delete('ids');
            }
          }
        } else if (
          !menu.getIn(['items', previous, 'isTaxonomy']) ||
          !shouldAddMenuItemFilters({ item: previous, menu }) ||
          !menu.getIn(['items', current, 'filters', FILTER[channel].TAXONOMY_IDS]).equals(
            menu.getIn(['items', previous, 'filters', FILTER[channel].TAXONOMY_IDS])
          )
        ) {
          return filters.set('offset', 0).delete('ids');
        }
      }

      return undefined;
    }
  }
}

export function getFilterIdsForMenuItem({ menu, products }) {
  const selectedMenuItem = menu.getIn(['selected', 'item']);

  if (shouldAddMenuItemFilters({ item: selectedMenuItem, menu })) {
    switch (selectedMenuItem) {
      case MENU_ITEM.FILES: {
        return products.get(DIGITAL);
      }

      case MENU_ITEM.ITEM_SIZE:
      case MENU_ITEM.ITEM_WEIGHT:
      case MENU_ITEM.RETURN_POLICY:
      case MENU_ITEM.SHIPPING_PROFILE:
      case MENU_ITEM.VARIATIONS: {
        return products.get(PHYSICAL);
      }

      default: {
        if (
          menu.getIn(['items', selectedMenuItem, 'isTaxonomy']) &&
          shouldAddMenuItemFilters({ item: selectedMenuItem, menu })
        ) {
          return undefined;
        }

        return products.get('all');
      }
    }
  }

  return products.get('all');
}

export function shapeFiltersForAPI({ channel, errors, filters: source, menu, products, selection }) {
  const selectedMenuItem = menu.getIn(['selected', 'item']);
  const menuFilters = shouldAddMenuItemFilters({ item: selectedMenuItem, menu })
    ? menu.getIn(['items', selectedMenuItem, 'filters'])
    : EMPTY_MAP;

  const filters = {};

  if (selection === SELECTION.INCOMPLETE) {
    filters.ids = errors.get('products').toArray();
  } else if (menuFilters.has(FILTER[channel].IS_DIGITAL)) {
    if (menuFilters.get(FILTER[channel].IS_DIGITAL)) {
      filters.ids = products.get(DIGITAL).toArray();
    } else {
      filters.ids = products.get(PHYSICAL).toArray();
    }
  } else {
    filters.ids = products.get('all').toArray();
  }

  if (getSize(menuFilters)) {
    for (const [key, value] of menuFilters.entries()) {
      if (key !== FILTER[channel].IS_DIGITAL) {
        filters[key] = toJS(value);
      }
    }
  }

  if (source.get(FILTER[channel].TITLE)) {
    filters[FILTER[channel].TITLE] = source.get(FILTER[channel].TITLE);
  }

  return filters;
}
