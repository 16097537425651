/* eslint-disable no-console */
import { sideEffect } from 'redux-side-effects';
import { List, Map, Set } from 'immutable';

import { getDefaultStatuses, getFirstNonEmptyStatus, shapeCountsForApp } from '../utils/listings/statuses';
import { getDefaultFilters, shapeFiltersForApp, shapeFiltersForAPI, onlyComplete } from '../utils/listings/filters';
import { getDefaultPagination, getPagination, getPaginationForAPI } from '../utils/listings/pagination';
import { shapeId, shapeListingsForApp, shapeNumber } from '../utils/listings/listings';
import { addPlaceholders, filterPlaceholders } from '../utils/listings/placeholders';
import { isFeatureEnabled } from '../utils/featureFlags';
import { createController } from '../utils/reducer';
import { fromBoolString } from '../utils/bool';
import { getOrderForAPI } from '../utils/listings/order';
import { setSelection } from '../utils/listings/selection';
import { getPlanName } from '../utils/billing';
import { navigation } from '../utils/navigation';
import { getSize } from '../utils/iterable/getSize';
import mixpanel from '../utils/tracking/mixpanel';
import api from '../utils/api';

import { FILTER, FILTER_API_KEY, LISTING, PAGE_SIZE, PUBLISH_LIMIT, SELECTION, STATUS, STATUS_NAME, STATUS_ORDER } from '../constants/listings';
import { POLLING_INTERVAL, STATUSES, SYNC_INDICATOR } from '../constants/shops';
import { DEFAULTS, FALSE, TRUE, VELA } from '../constants';
import { MESSAGE, NOTIFICATION } from '../constants/notifications';
import { CHANNEL_NAME, ETSY } from '../constants/channels';
import { OPERATIONS } from '../constants/product';
import { FEATURES } from '../constants/billing';
import { MODALS } from '../constants/modal';
import { EVENT } from '../constants/tracking';
import { PAGES } from '../constants/routes';
import ACTIONS from '../constants/actions';

import Reducers, { Reducer } from '../classes/Reducer';
import Actions from '../actions';

const { EMPTY_LIST, EMPTY_MAP, EMPTY_SET } = DEFAULTS;

const filtersController = createController();
const listingsController = createController();
const listingIdsController = createController();
const statusesController = createController();

function abort(controller) {
  if (controller.isActive()) {
    controller.stop();
  }
}

function abortAll() {
  [
    filtersController,
    listingsController,
    listingIdsController,
    statusesController,
  ].forEach(abort);
}

function* addListingPlaceholder(reduction, { placeholder, shopId, status }) {
  function updatePlaceholders(placeholders = EMPTY_MAP) {
    const productId = placeholder.get(LISTING[VELA].PRODUCT_ID);

    function updateById(byId = EMPTY_MAP) {
      return byId.set(productId, placeholder);
    }

    function updateIds(ids = EMPTY_LIST) {
      return ids.push(productId);
    }

    return placeholders
      .update('byId', updateById)
      .update('ids', updateIds);
  }

  return reduction.updateIn(['listings', 'placeholders', shopId, status], updatePlaceholders);
}

function* bootstrap(reduction, { channel, db, shopId, status }) {
  abortAll();

  const listings = reduction.get('listings');
  const state = Map({
    channel,
    db,
    filters: getDefaultFilters({ channel, filters: listings.get('filters') }),
    loaded: listings.get('loaded', false),
    pagination: getDefaultPagination(),
    placeholders: listings.get('placeholders', EMPTY_MAP),
    products: Map({ processing: listings.getIn(['products', 'processing'], EMPTY_MAP) }),
    shopId,
    statuses: getDefaultStatuses({ channel, status, statuses: listings.get('statuses') }),
  });

  yield sideEffect((dispatch) => {
    if (state.getIn(['statuses', 'selected']) !== status) {
      navigation().goTo({ page: PAGES.LISTINGS, shopId, status: state.getIn(['statuses', 'selected']) });
    }

    const loaded = state.get('loaded');
    dispatch(Actions.Listings.getStatuses({ loaded }));

    if (loaded) {
      const filters = EMPTY_MAP;
      dispatch(Actions.Listings.getFilters({ filters }));
      dispatch(Actions.Listings.getListings({ clear: true, filters }));
    }
  });

  return reduction.set('listings', state);
}

function* cleanUp(reduction) {
  abortAll();

  if (!getSize(reduction.get('listings'))) {
    return reduction;
  }

  const state = Map({
    loaded: reduction.getIn(['listings', 'loaded']),
    placeholders: reduction.getIn(['listings', 'placeholders']),
    products: Map({ processing: reduction.getIn(['listings', 'products', 'processing']) }),
    status: reduction.getIn(['listings', 'status']),
  });

  return reduction.set('listings', state);
}

function* copyListings(reduction, { bulk, products, source, target }) {
  yield sideEffect((dispatch) => {
    dispatch(Actions.Listings.copyListingsStarted({ bulk, products, source, target }));

    const sourceShop = reduction.getIn(['shops', 'byId', source]);
    const targetShop = reduction.getIn(['shops', 'byId', target]);
    const shopId = source;
    const db = sourceShop.get('db');
    const payload = {
      product_ids: products,
      source_shop: source,
      source_channel: parseInt(sourceShop.get('channelId'), 10),
      source_db: db,
      target_shop: target,
      target_db: targetShop.get('db'),
      target_channel: parseInt(targetShop.get('channelId'), 10),
    };

    api.products
      .copyProducts({ shopId, db, payload })
      .then(
        (response) => dispatch(Actions.Listings.copyListingsSucceeded({ response, target })),
        (error) => dispatch(Actions.Listings.copyListingsFailed({ error, target })),
      );
  });

  return reduction;
}

function* copyListingsFailed(reduction, { error, target }) {
  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(Actions.Shops.setData({ path: ['syncData', target] }));

    dispatch(
      Actions.Notifications.add({
        message: MESSAGE.FAIL.COPY_LISTINGS,
        type: NOTIFICATION.ERROR,
      })
    );
  });

  return reduction;
}

function* copyListingsStarted(reduction, { bulk, products, source, target }) {
  yield sideEffect((dispatch) => {
    const total = getSize(products);
    const sourceShop = reduction.getIn(['shops', 'byId', source]);
    const targetShop = reduction.getIn(['shops', 'byId', target]);
    const eventName = bulk ? EVENT.LISTING.BULK_COPY : EVENT.LISTING.COPY;
    const eventProps = {
      plan: getPlanName(reduction.getIn(['user', 'subscriptions', source])),
    };

    if (bulk) {
      eventProps.from_channel = CHANNEL_NAME[sourceShop.get('channel')];
      eventProps.to_channel = CHANNEL_NAME[targetShop.get('channel')];
      eventProps.total_listings = total;
    } else {
      eventProps.channel = CHANNEL_NAME[sourceShop.get('channel')];
      eventProps.from_shop_id = source;
      eventProps.to_shop_id = target;
    }

    mixpanel.track(eventName, eventProps);

    dispatch(
      Actions.Shops.setData({
        path: ['syncData', target],
        value: Map({
          metaData: Map({ sourceShop: source }),
          modal: MODALS.SYNC.COPY,
        }),
      })
    );

    if (target === reduction.getIn(['shops', 'current'])) {
      const interval = (
        reduction.getIn(['user', 'config', 'shopsPollingIntervalShort']) ||
        POLLING_INTERVAL.SHORT
      );

      dispatch(Actions.Shops.rescheduleShopsPolling(interval));

      if (target === reduction.getIn(['listings', 'shopId'])) {
        navigation().goTo({ page: PAGES.LISTINGS, shopId: target, status: STATUS.COPIED });
      }
    } else {
      dispatch(
        Actions.Notifications.add({
          count: total,
          shopId: targetShop.get('id'),
          shopName: targetShop.get('name'),
          type: NOTIFICATION.LISTINGS_COPIED,
        })
      );
    }
  });

  return reduction;
}

function* copyListingsSucceeded(reduction, { response, target }) {
  yield sideEffect((dispatch) => {
    if (response?.result !== 'success') {
      dispatch(Actions.Listings.copyListingsFailed({ error: response, target }));
      return;
    }
  });

  return reduction;
}

function* deleteListings(reduction, listings) {
  yield sideEffect((dispatch) => {
    const state = reduction.get('listings');
    const channel = state.get('channel');
    const db = state.get('db');
    const shopId = state.get('shopId');
    dispatch(Actions.Listings.toggleState({ listings, state: ['processing', shopId] }));
    dispatch(
      Actions.Shops.setData({
        path: ['syncData', shopId],
        value: Map({ indicator: SYNC_INDICATOR.HIDDEN }),
      })
    );

    api.products
      .deleteProducts({ channel, db, products: listings.toArray(), shopId })
      .then(
        (response) => dispatch(Actions.Listings.deleteListingsSucceeded({ listings, response, shopId })),
        (error) => dispatch(Actions.Listings.deleteListingsFailed({ error, listings, shopId })),
      );
  });

  return reduction;
}

function* deleteListingsFailed(reduction, { error, listings, shopId }) {
  yield sideEffect((dispatch) => {
    if (error) console.error(error);

    if (reduction.getIn(['shops', 'syncData', shopId, 'indicator']) === SYNC_INDICATOR.HIDDEN) {
      dispatch(Actions.Shops.setData({ path: ['syncData', shopId] }));
    }

    if (getSize(listings.intersect(reduction.getIn(['listings', 'products', 'processing', shopId])))) {
      dispatch(Actions.Listings.toggleState({ listings, state: ['processing', shopId] }));
    }

    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.DELETE_LISTINGS,
      })
    );
  });

  return reduction;
}

function* deleteListingsSucceeded(reduction, { listings, response, shopId }) {
  if (response?.result !== 'succeeded') {
    yield sideEffect((dispatch) => {
      dispatch(Actions.Listings.deleteListingsFailed({ error: response?.error, listings, shopId }));
    });

    return reduction;
  }

  let state = reduction.get('listings');
  const sameShop = state.get('shopId') === shopId;

  function updateSelected(selected = EMPTY_SET) {
    return selected.subtract(listings);
  }

  if (sameShop && getSize(listings.intersect(state.getIn(['products', 'selected'])))) {
    state = state.set('products',
      setSelection({ products: state.get('products').update('selected', updateSelected) })
    );
  }

  yield sideEffect((dispatch) => {
    if (sameShop) {
      dispatch(Actions.Listings.getStatuses());

      if (getSize(listings.intersect(Set(state.getIn(['products', 'ids']))))) {
        dispatch(Actions.Listings.getFilters());
        dispatch(Actions.Listings.getListings());
      }
    }

    if (getSize(listings.intersect(state.getIn(['products', 'processing', shopId])))) {
      dispatch(Actions.Listings.toggleState({ listings, state: ['processing', shopId] }));
    }

    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.SUCCESS,
        message: MESSAGE.SUCCESS.DELETE_LISTINGS,
      })
    );
  });

  return reduction.set('listings', state);
}

function* getFilters(reduction, payload) {
  if (!reduction.hasIn(['listings', 'shopId'])) return reduction;

  abort(filtersController);

  yield sideEffect((dispatch) => {
    const { signal } = filtersController.start();
    const listings = reduction.get('listings');
    const channel = listings.get('channel');
    const db = listings.get('db');
    const shopId = listings.get('shopId');
    const status = listings.getIn(['statuses', 'selected']);
    let selected = payload?.filters || listings.getIn(['filters', 'selected']);

    if (
      status === STATUS.UNPUBLISHED &&
      !selected.has(FILTER.EXISTING) &&
      isFeatureEnabled({ feature: FEATURES.CSV_OVERRIDE, userId: reduction.getIn(['user', 'userId']) })
    ) {
      selected = selected.set(FILTER.EXISTING, FALSE);
    }

    const filters = shapeFiltersForAPI({ filters: selected, status });

    api.listings
      .getFilters({ channel, db, filters, shopId, signal })
      .then(
        (response) => dispatch(Actions.Listings.getFiltersSucceeded({ response, selected })),
        (error) => dispatch(Actions.Listings.getFiltersFailed({ error, signal })),
      );
  });

  return reduction.setIn(['listings', 'filters', 'loading'], true);
}

function* getFiltersFailed(reduction, { error, signal }) {
  if (signal.aborted) return reduction;

  abort(filtersController);

  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_DATA,
      })
    );
  });

  return reduction.deleteIn(['listings', 'filters', 'loading']);
}

function* getFiltersSucceeded(reduction, { response, selected }) {
  abort(filtersController);

  const listings = reduction.get('listings');
  const channel = listings.get('channel');

  if (!channel) return reduction;

  if (selected && selected.has(FILTER.EXISTING) && !listings.hasIn(['filters', 'values', FILTER.EXISTING])) {
    const current = selected.get(FILTER.EXISTING);
    const opposite = String(!fromBoolString(current));
    const counts = response[FILTER_API_KEY[FILTER.EXISTING]];

    if (shapeNumber(counts[current]) < shapeNumber(counts[opposite])) {
      yield sideEffect((dispatch) => {
        dispatch(Actions.Listings.setFilter({ filter: FILTER.EXISTING, value: opposite }));
      });

      return reduction;
    }
  }

  const filters = shapeFiltersForApp({
    channel,
    filters: listings.get('filters').delete('loading').set('selected', selected),
    response,
    shopData: reduction.getIn(['data', 'shopsData', listings.get('shopId')]),
    status: listings.getIn(['statuses', 'selected']),
  });

  return reduction.setIn(['listings', 'filters'], filters);
}

function* getListings(reduction, payload = {}) {
  if (!reduction.hasIn(['listings', 'shopId'])) return reduction;

  abort(listingsController);

  const { actions, clear, filters: selected } = payload;

  if (clear) {
    abort(listingIdsController);
  }

  yield sideEffect((dispatch) => {
    const { signal } = listingsController.start();
    const listings = reduction.get('listings');
    const channel = listings.get('channel');
    const db = listings.get('db');
    const shopId = listings.get('shopId');
    const status = listings.getIn(['statuses', 'selected']);
    const order = {
      by: listings.getIn(['order', 'by']),
      type: listings.getIn(['order', 'type']),
    };

    if (
      order.by &&
      status !== listings.get('status') &&
      !STATUS_ORDER[channel][status].has(order.by)
    ) {
      delete order.by;
      delete order.type;
    }

    const page = (
      clear ||
      order.by !== listings.getIn(['products', 'order', 'by']) ||
      order.type !== listings.getIn(['products', 'order', 'type'])
    )
      ? 0
      : listings.getIn(['pagination', 'currentPage']);

    const filters = {
      ...shapeFiltersForAPI({ filters: selected || listings.getIn(['filters', 'selected']), status }),
      ...getOrderForAPI({ channel, order, status }),
      ...getPaginationForAPI(page),
    };

    api.listings
      .getListings({ channel, db, filters, shopId, signal })
      .then(
        (response) => dispatch(Actions.Listings.getListingsSucceeded({ actions, clear, order, page, response, status })),
        (error) => dispatch(Actions.Listings.getListingsFailed({ error, signal })),
      );
  });

  return reduction.setIn(['listings', 'products', 'loading'], true);
}

function* getListingsFailed(reduction, { error, signal }) {
  if (signal.aborted) return reduction;

  abort(listingsController);

  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_LISTINGS,
      })
    );
  });

  return reduction.deleteIn(['listings', 'products', 'loading']);
}

function* getListingsSucceeded(reduction, { actions, clear, order, page, response, status }) {
  abort(listingsController);
  let state = reduction.get('listings');
  const channel = state.get('channel');

  if (!channel) return reduction;

  state = state.deleteIn(['products', 'loading']);

  if (clear) {
    state = state
      .deleteIn(['products', 'all'])
      .deleteIn(['products', 'hovered'])
      .deleteIn(['products', 'oldFilters'])
      .deleteIn(['products', 'selection'])
      .setIn(['products', 'selected'], EMPTY_SET);
  }

  if (status !== state.get('status')) {
    state = state.set('status', status);
  }

  if (!order.by) {
    state = state.delete('order').deleteIn(['products', 'order']);
  } else {
    if (
      order.by !== state.getIn(['order', 'by']) ||
      order.type !== state.getIn(['order', 'type'])
    ) {
      state = state.set('order', Map(order));
    }

    if (
      state.getIn(['order', 'by']) !== state.getIn(['products', 'order', 'by']) ||
      state.getIn(['order', 'type']) !== state.getIn(['products', 'order', 'type'])
    ) {
      state = state.setIn(['products', 'order'], state.get('order'));
    }
  }

  const total = shapeNumber(response.total);

  state = state.setIn(['products', 'total'], total);

  if (!total) {
    state = state
      .deleteIn(['products', 'all'])
      .deleteIn(['products', 'byId'])
      .deleteIn(['products', 'hovered'])
      .deleteIn(['products', 'ids'])
      .deleteIn(['products', 'oldFilters'])
      .deleteIn(['products', 'selection'])
      .setIn(['products', 'selected'], EMPTY_SET)
      .set('pagination', getDefaultPagination());

    return reduction.set('listings', state);
  } else if (!getSize(response.listings) && page > 0) {
    yield sideEffect((dispatch) => {
      dispatch(Actions.Listings.setPage(Math.max(0, Math.floor((total - 1) / PAGE_SIZE))));
    });

    return reduction.set('listings', state.setIn(['products', 'loading']));
  }

  const shopId = state.get('shopId');
  const userShops = reduction.get('shops');
  const shopData = reduction.getIn(['data', 'shopsData', shopId]);
  const { byId, ids } = shapeListingsForApp({ channel, response, shopData, userShops });

  state = state
    .set('pagination', getPagination({ page, total }))
    .set('products',
      setSelection({ products: state.get('products').set('byId', byId).set('ids', ids) })
    );

  if (state.hasIn(['placeholders', shopId, status]) || state.hasIn(['products', 'processing', shopId])) {
    if (
      !reduction.hasIn(['shops', 'syncData', shopId]) ||
      reduction.getIn(['shops', 'syncData', shopId, 'complete'])
    ) {
      state = state
        .deleteIn(['placeholders', shopId])
        .deleteIn(['products', 'processing', shopId]);
    } else if (state.hasIn(['placeholders', shopId, status])) {
      const placeholders = filterPlaceholders({ shopId, state, status });

      if (!getSize(placeholders.get('ids'))) {
        state = state.deleteIn(['placeholders', shopId, status]);

        if (!getSize(state.getIn(['placeholders', shopId]))) {
          state = state.deleteIn(['placeholders', shopId]);
        }
      } else {
        state = addPlaceholders({ placeholders, state, status });
      }
    }
  }

  if (getSize(actions)) {
    yield sideEffect((dispatch) => {
      actions.forEach(dispatch);
    });
  }

  return reduction.set('listings', state);
}

function* getStatuses(reduction, payload = {}) {
  if (!reduction.hasIn(['listings', 'shopId'])) return reduction;

  abort(statusesController);

  yield sideEffect((dispatch) => {
    const { signal } = statusesController.start();
    const listings = reduction.get('listings');
    const channel = listings.get('channel');
    const db = listings.get('db');
    const shopId = listings.get('shopId');
    const { loaded = true } = payload;

    api.listings
      .getStatuses({ channel, db, shopId, signal })
      .then(
        (response) => dispatch(Actions.Listings.getStatusesSucceeded({ loaded, response })),
        (error) => dispatch(Actions.Listings.getStatusesFailed({ error, signal })),
      );
  });

  return reduction;
}

function* getStatusesFailed(reduction, { error, signal }) {
  if (signal.aborted) return reduction;

  abort(statusesController);

  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_DATA,
      })
    );
  });

  return reduction;
}

function* getStatusesSucceeded(reduction, { loaded, response }) {
  abort(statusesController);

  let state = reduction.get('listings');
  const channel = state.get('channel');

  if (!channel) return reduction;

  const counts = shapeCountsForApp({ channel, response });
  let statuses = state.get('statuses').set('counts', counts);

  function getEmptyState() {
    return state
      .delete('order')
      .set('filters', getDefaultFilters({ channel }).set('options', EMPTY_LIST))
      .set('pagination', getDefaultPagination())
      .set('products', Map({ processing: state.getIn(['products', 'processing']), total: 0 }));
  }

  if (!loaded) {
    if (!statuses.getIn(['counts', statuses.get('selected')])) {
      const firstNonEmpty = getFirstNonEmptyStatus({ channel, counts });

      if (firstNonEmpty) {
        statuses = statuses.set('selected', firstNonEmpty);
      } else {
        state = getEmptyState().set('status', statuses.get('selected'));
      }
    }

    state = state.set('loaded', true);
  }

  yield sideEffect((dispatch) => {
    if (statuses.get('selected') !== state.getIn(['statuses', 'selected'])) {
      const shopId = state.get('shopId');
      navigation().goTo({ page: PAGES.LISTINGS, shopId, status: statuses.get('selected') });
    }

    if (!loaded) {
      const filters = EMPTY_MAP;
      dispatch(Actions.Listings.getFilters({ filters }));
      dispatch(Actions.Listings.getListings({ clear: true, filters }));
    }
  });

  return reduction.set('listings', state.set('statuses', statuses));
}

function* publishListings(reduction, { bulk, confirmed, override, placeholder, products, status }) {
  const listings = reduction.get('listings');
  const channel = listings.get('channel');
  const db = listings.get('db');
  const shopId = listings.get('shopId');
  const isCopy = listings.get('status') === STATUS.COPIED;

  if (!confirmed) {
    if (override) {
      yield sideEffect((dispatch) => {
        dispatch(
          Actions.Listings.setModal({
            bulk,
            placeholder,
            products,
            type: MODALS.CONFIRMATIONS.UPDATE_EXISTING,
          })
        );
      });

      return reduction;
    } else if (channel === ETSY && status === STATUS.ACTIVE) {
      yield sideEffect((dispatch) => {
        dispatch(
          Actions.Listings.setModal({
            action: Actions.Listings.publishListings({
              bulk,
              confirmed: true,
              placeholder,
              products,
              status,
            }),
            type: MODALS.CONFIRMATIONS.PUBLISH,
          })
        );
      });

      return reduction;
    }
  }

  yield sideEffect((dispatch) => {
    dispatch(Actions.Listings.publishListingsStarted({ bulk, channel, isCopy, placeholder, products, shopId, status }));

    if (override) {
      api.products
        .bulkOverride({ db, listings: products, shopId })
        .then(
          () => dispatch(Actions.Listings.publishListingsSucceeded({ shopId })),
          (error) => dispatch(Actions.Listings.publishListingsFailed({ error, shopId })),
        );
    } else {
      const operations = [{ type: OPERATIONS[channel].STATUS, products, value: status }];
      const params = {};

      if (bulk) {
        params.initial_sync_status = STATUSES.PUBLISHING_COPY;
      }

      api.products
        .editSingleProduct({ db, operations, params, shopId })
        .then(
          () => dispatch(Actions.Listings.publishListingsSucceeded({ shopId, status })),
          (error) => dispatch(Actions.Listings.publishListingsFailed({ error, shopId })),
        );
    }
  });

  return reduction;
}

function* publishListingsFailed(reduction, { error, shopId }) {
  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(Actions.Shops.setData({ path: ['syncData', shopId] }));
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.PUBLISH_LISTINGS,
      })
    );
  });

  return reduction;
}

function* publishListingsStarted(reduction, { bulk, channel, isCopy, placeholder, products, shopId, status }) {
  yield sideEffect((dispatch) => {
    dispatch(
      Actions.Shops.setData({
        path: ['syncData', shopId],
        value: bulk
          ? Map({ modal: MODALS.SYNC.PUBLISH })
          : Map({ indicator: SYNC_INDICATOR.PUBLISH }),
      })
    );

    if (status) {
      if (placeholder) {
        dispatch(Actions.Listings.addListingPlaceholder({ placeholder, shopId, status }));
      }

      if (shopId === reduction.getIn(['listings', 'shopId'])) {
        navigation().goTo({ page: PAGES.LISTINGS, shopId, status });
      }

      if (isCopy) {
        const eventName = bulk
          ? status === STATUS.ACTIVE
            ? EVENT.LISTING.BULK_COPY_PUBLISH
            : EVENT.LISTING.BULK_COPY_SAVE_AS_DRAFT
          : EVENT.LISTING.PUBLISH_COPY;

        const eventProps = {
          channel: CHANNEL_NAME[channel],
          plan: getPlanName(reduction.getIn(['user', 'subscriptions', shopId])),
          shop_id: shopId,
        };

        if (bulk) {
          eventProps.total_listings = getSize(products);
        } else {
          eventProps.to_status = STATUS_NAME[status];
        }

        mixpanel.track(eventName, eventProps);
      }
    }
  });

  return reduction;
}

function* publishListingsSucceeded(reduction, { shopId, status }) {
  yield sideEffect((dispatch) => {
    if (shopId === reduction.getIn(['listings', 'shopId'])) {
      dispatch(Actions.Listings.getStatuses());

      if (status && status === reduction.getIn(['listings', 'statuses', 'selected'])) {
        dispatch(Actions.Listings.getFilters());
        dispatch(Actions.Listings.getListings({ clear: true }));
      }
    }
  });

  return reduction;
}

function* setData(reduction, { path, value }) {
  const fullPath = Array.isArray(path)
    ? ['listings', ...path]
    : ['listings', path];

  if (value !== undefined) return reduction.setIn(fullPath, value);

  let state = reduction.deleteIn(fullPath);

  fullPath.pop();

  while (fullPath.length > 1) {
    if (!getSize(state.getIn(fullPath))) {
      state = state.deleteIn(fullPath);
    }

    fullPath.pop();
  }

  return state;
}

function* setFilter(reduction, { filter, value }) {
  let filters = reduction.getIn(['listings', 'filters', 'selected']);

  function updateFilter(values = EMPTY_SET) {
    if (Set.isSet(value)) {
      if (getSize(values.intersect(value))) {
        return values.subtract(value);
      } else {
        return values.union(value);
      }
    } else {
      if (values.has(value)) {
        return values.delete(value);
      } else {
        return values.add(value);
      }
    }
  }

  switch (filter) {
    case FILTER.EXISTING: {
      filters = filters.set(filter, value);

      yield sideEffect((dispatch) => {
        dispatch(Actions.Listings.getFilters({ filters }));
        dispatch(Actions.Listings.getListings({ clear: true, filters }));
      });

      return reduction.setIn(['listings', 'filters', 'selected'], filters);
    }

    case FILTER.PROFILE_TYPE: {
      filters = filters.set(filter, value);

      if (getSize(filters.get(FILTER.PROFILE))) {
        filters = filters.delete(FILTER.PROFILE);

        yield sideEffect((dispatch) => {
          dispatch(Actions.Listings.getFilters({ filters }));
          dispatch(Actions.Listings.getListings({ clear: true, filters }));
        });
      }

      return reduction.setIn(['listings', 'filters', 'selected'], filters);
    }

    case FILTER.TITLE: {
      filters = value
        ? filters.set(filter, value)
        : filters.delete(filter);

      yield sideEffect((dispatch) => {
        dispatch(Actions.Listings.getFilters({ filters }));
        dispatch(Actions.Listings.getListings({ clear: true, filters }));
      });

      return reduction.setIn(['listings', 'filters', 'selected'], filters);
    }

    default: {
      filters = filters.update(filter, updateFilter);

      if (!getSize(filters.get(filter))) {
        filters = filters.delete(filter);
      }

      yield sideEffect((dispatch) => {
        dispatch(Actions.Listings.getFilters({ filters }));
        dispatch(Actions.Listings.getListings({ clear: true, filters }));
      });

      return reduction.setIn(['listings', 'filters', 'selected'], filters);
    }
  }
}

function* setModal(reduction, payload) {
  return payload
    ? reduction.setIn(['listings', 'modal'], Map(payload))
    : reduction.deleteIn(['listings', 'modal']);
}

function* setOrder(reduction, { by, type }) {
  yield sideEffect((dispatch) => {
    dispatch(Actions.Listings.getListings({ clear: true }));
  });

  return reduction.setIn(['listings', 'order'], Map({ by, type }));
}

function* setPage(reduction, page) {
  yield sideEffect((dispatch) => {
    dispatch(Actions.Listings.getListings());
  });

  return reduction.setIn(['listings', 'pagination', 'currentPage'], page);
}

function* setStatus(reduction, status) {
  abort(filtersController);
  abort(listingsController);
  abort(listingIdsController);

  let listings = reduction.get('listings');
  const statuses = listings.get('statuses');

  if (status === statuses.get('selected')) return reduction;

  if (!statuses.getIn(['counts', status])) {
    const channel = listings.get('channel');

    listings = listings
      .set('filters', getDefaultFilters({ channel }).set('options', EMPTY_LIST))
      .set('pagination', getDefaultPagination())
      .set('products', Map({ processing: listings.getIn(['products', 'processing']), total: 0 }))
      .set('status', status)
      .set('statuses', statuses.set('selected', status));

    const order = listings.get('order');

    if (order && STATUS_ORDER[channel][status].has(order.get('by'))) {
      listings = listings.setIn(['products', 'order'], order);
    } else {
      listings = listings.delete('order');
    }

    return reduction.set('listings', listings);
  }

  let filters = EMPTY_MAP;
  const title = listings.getIn(['filters', 'selected', 'title']);

  if (title) {
    filters = filters.set(FILTER.TITLE, title);
  }

  yield sideEffect((dispatch) => {
    dispatch(Actions.Listings.getFilters({ filters }));
    dispatch(Actions.Listings.getListings({ clear: true, filters }));
  });

  return reduction.setIn(['listings', 'statuses'], statuses.set('selected', status));
}

function* toggleListings(reduction, { limit, listing, oldFilters, selection }) {
  abort(listingIdsController);

  let products = reduction.getIn(['listings', 'products']);

  if (listing) {
    products = products.update('selected', function updateSelected(selected = EMPTY_SET) {
      return selected.has(listing)
        ? selected.delete(listing)
        : selected.add(listing);
    });

    return reduction.setIn(['listings', 'products'], setSelection({ products }));
  }

  if (limit) {
    const { signal } = listingIdsController.start();
    const listings = reduction.get('listings');
    const channel = listings.get('channel');
    const db = listings.get('db');
    const shopId = listings.get('shopId');
    const filters = listings.getIn(['filters', 'selected']);
    const status = listings.getIn(['statuses', 'selected']);
    const order = {
      by: listings.getIn(['order', 'by']),
      type: listings.getIn(['order', 'type']),
    };

    const params = {
      ...shapeFiltersForAPI({ filters, status }),
      ...getOrderForAPI({ channel, order, status }),
      limit,
      offset: 0,
    };

    yield sideEffect((dispatch) => {
      api.listings
        .getListings({ channel, db, filters: params, shopId, signal })
        .then(
          (response) => dispatch(Actions.Listings.toggleListingsSucceeded({ channel, limit, response })),
          (error) => dispatch(Actions.Listings.toggleListingsFailed({ error, signal })),
        );
    });

    return reduction.setIn(['listings', 'products', 'loading'], true);
  }

  if (selection === SELECTION.COMPLETE) {
    let listings = reduction.get('listings');
    let filters = listings.getIn(['filters', 'selected']);

    if (!onlyComplete(filters)) {
      filters = filters.set(FILTER.IS_COMPLETE, Set([TRUE]));
      listings = listings
        .setIn(['filters', 'selected'], filters)
        .setIn(['filters', 'loading'], true)
        .setIn(['products', 'loading'], true);

      const actions = [
        Actions.Listings.toggleListings({
          oldFilters: reduction.getIn(['listings', 'filters', 'selected']),
          selection: SELECTION.COMPLETE,
        }),
      ];

      yield sideEffect((dispatch) => {
        dispatch(Actions.Listings.getFilters({ filters }));
        dispatch(Actions.Listings.getListings({ actions, clear: true, filters }));
      });

      return reduction.set('listings', listings);
    }

    const { signal } = listingIdsController.start();
    const channel = listings.get('channel');
    const db = listings.get('db');
    const shopId = listings.get('shopId');
    const status = listings.getIn(['statuses', 'selected']);
    const order = {
      by: listings.getIn(['order', 'by']),
      type: listings.getIn(['order', 'type']),
    };

    const params = {
      ...shapeFiltersForAPI({ filters, status }),
      ...getOrderForAPI({ channel, order, status }),
      limit: PUBLISH_LIMIT,
      offset: 0,
    };

    yield sideEffect((dispatch) => {
      api.listings
        .getListings({ channel, db, filters: params, shopId, signal })
        .then(
          (response) => dispatch(Actions.Listings.toggleListingsSucceeded({ channel, oldFilters, response, selection })),
          (error) => dispatch(Actions.Listings.toggleListingsFailed({ error, signal })),
        );
    });

    return reduction.setIn(['listings', 'products', 'loading'], true);
  }

  if (selection === SELECTION.NONE && reduction.hasIn(['listings', 'products', 'oldFilters'])) {
    const filters = reduction.getIn(['listings', 'products', 'oldFilters']);

    yield sideEffect((dispatch) => {
      dispatch(Actions.Listings.getFilters({ filters }));
      dispatch(Actions.Listings.getListings({ clear: true, filters }));
    });

    return reduction
      .deleteIn(['listings', 'products', 'oldFilters'])
      .setIn(['listings', 'filters', 'selected'], filters);
  }

  if (
    selection === SELECTION.NONE || (
      selection === SELECTION.PAGE &&
      products.get('selection') === SELECTION.PAGE &&
      !isFeatureEnabled({ feature: FEATURES.UI_UPDATES_AUGUST, userId: reduction.getIn(['user', 'userId']) })
    )
  ) {
    products = products.set('selected', EMPTY_SET);
    return reduction.setIn(['listings', 'products'], setSelection({ products }));
  }

  if (
    selection === SELECTION.PAGE || (
      selection === SELECTION.ALL &&
      getSize(products.get('ids')) === products.get('total')
    )
  ) {
    products = products.update('selected', function updateSelected(selected = EMPTY_SET) {
      return products.get('total') > PAGE_SIZE && products.get('selection') === SELECTION.ALL
        ? Set(products.get('ids'))
        : products.get('selection')
          ? selected.subtract(Set(products.get('ids')))
          : selected.union(Set(products.get('ids')));
    });

    return reduction.setIn(['listings', 'products'], setSelection({ products }));
  }

  if (selection === SELECTION.ALL) {
    if (products.has('all')) {
      products = products.set('selected', products.get('all').toSet());
      return reduction.setIn(['listings', 'products'], setSelection({ products }));
    }

    yield sideEffect((dispatch) => {
      const { signal } = listingIdsController.start();
      const listings = reduction.get('listings');
      const channel = listings.get('channel');
      const db = listings.get('db');
      const shopId = listings.get('shopId');
      const status = listings.getIn(['statuses', 'selected']);
      const payload = shapeFiltersForAPI({ filters: listings.getIn(['filters', 'selected']), status });

      api.listings
        .getListingIds({ channel, db, payload, shopId, signal })
        .then(
          (response) => dispatch(Actions.Listings.toggleListingsSucceeded({ response, selection })),
          (error) => dispatch(Actions.Listings.toggleListingsFailed({ error, signal })),
        );
    });

    return reduction.setIn(['listings', 'products', 'loading'], true);
  }

  return reduction;
}

function* toggleListingsFailed(reduction, { error, signal }) {
  if (signal.aborted) return reduction;

  abort(listingIdsController);

  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_DATA,
      })
    );
  });

  return reduction.deleteIn(['listings', 'products', 'loading']);
}

function* toggleListingsSucceeded(reduction, { channel, limit, oldFilters, response, selection }) {
  abort(listingIdsController);

  function mapListings(listing) {
    return shapeId(shapeId(listing[LISTING[channel].PRODUCT_ID]));
  }

  switch (selection) {
    case SELECTION.ALL: {
      const ids = response.map(shapeId);
      const products = reduction
        .getIn(['listings', 'products'])
        .delete('loading')
        .set('all', List(ids))
        .set('selected', Set(ids));

      return reduction.setIn(['listings', 'products'], setSelection({ products }));
    }

    case SELECTION.COMPLETE: {
      const ids = Set(response.listings.map(mapListings));
      let products = reduction
        .getIn(['listings', 'products'])
        .delete('loading')
        .set('selected', ids);

      if (oldFilters) {
        products = products.set('oldFilters', oldFilters);
      }

      return reduction.setIn(['listings', 'products'], setSelection({ products }));
    }

    default: {
      if (limit) {
        const products = reduction
          .getIn(['listings', 'products'])
          .delete('loading')
          .set('selected', Set(response.listings.map(mapListings)));

        return reduction.setIn(['listings', 'products'], setSelection({ products }));
      }

      return reduction;
    }
  }
}

function* toggleSidebarGroup(reduction, { key, value }) {
  function updateExpanded(expanded) {
    return expanded.has(value)
      ? expanded.delete(value)
      : expanded.add(value);
  }

  return reduction.updateIn(['listings', key, 'expanded'], updateExpanded);
}

function* toggleState(reduction, { listings, state: path }) {
  function reduceListingIds(result, id) {
    return result.has(id)
      ? result.delete(id)
      : result.add(id);
  }

  const fullPath = Array.isArray(path)
    ? ['listings', 'products', ...path]
    : ['listings', 'products', path];

  const state = listings.reduce(reduceListingIds, reduction.getIn(fullPath, EMPTY_SET));

  return getSize(state)
    ? reduction.setIn(fullPath, state)
    : reduction.deleteIn(fullPath);
}

Reducers.add(
  new Reducer('Listings')
    .add(ACTIONS.LISTINGS.ADD_LISTING_PLACEHOLDER, addListingPlaceholder)
    .add(ACTIONS.LISTINGS.BOOTSTRAP, bootstrap)
    .add(ACTIONS.LISTINGS.CLEAN_UP, cleanUp)
    .add(ACTIONS.LISTINGS.COPY_LISTINGS, copyListings)
    .add(ACTIONS.LISTINGS.COPY_LISTINGS_FAILED, copyListingsFailed)
    .add(ACTIONS.LISTINGS.COPY_LISTINGS_STARTED, copyListingsStarted)
    .add(ACTIONS.LISTINGS.COPY_LISTINGS_SUCCEEDED, copyListingsSucceeded)
    .add(ACTIONS.LISTINGS.DELETE_LISTINGS, deleteListings)
    .add(ACTIONS.LISTINGS.DELETE_LISTINGS_FAILED, deleteListingsFailed)
    .add(ACTIONS.LISTINGS.DELETE_LISTINGS_SUCCEEDED, deleteListingsSucceeded)
    .add(ACTIONS.LISTINGS.GET_FILTERS, getFilters)
    .add(ACTIONS.LISTINGS.GET_FILTERS_FAILED, getFiltersFailed)
    .add(ACTIONS.LISTINGS.GET_FILTERS_SUCCEEDED, getFiltersSucceeded)
    .add(ACTIONS.LISTINGS.GET_LISTINGS, getListings)
    .add(ACTIONS.LISTINGS.GET_LISTINGS_FAILED, getListingsFailed)
    .add(ACTIONS.LISTINGS.GET_LISTINGS_SUCCEEDED, getListingsSucceeded)
    .add(ACTIONS.LISTINGS.GET_STATUSES, getStatuses)
    .add(ACTIONS.LISTINGS.GET_STATUSES_FAILED, getStatusesFailed)
    .add(ACTIONS.LISTINGS.GET_STATUSES_SUCCEEDED, getStatusesSucceeded)
    .add(ACTIONS.LISTINGS.PUBLISH_LISTINGS, publishListings)
    .add(ACTIONS.LISTINGS.PUBLISH_LISTINGS_FAILED, publishListingsFailed)
    .add(ACTIONS.LISTINGS.PUBLISH_LISTINGS_STARTED, publishListingsStarted)
    .add(ACTIONS.LISTINGS.PUBLISH_LISTINGS_SUCCEEDED, publishListingsSucceeded)
    .add(ACTIONS.LISTINGS.SET_DATA, setData)
    .add(ACTIONS.LISTINGS.SET_FILTER, setFilter)
    .add(ACTIONS.LISTINGS.SET_MODAL, setModal)
    .add(ACTIONS.LISTINGS.SET_ORDER, setOrder)
    .add(ACTIONS.LISTINGS.SET_PAGE, setPage)
    .add(ACTIONS.LISTINGS.SET_STATUS, setStatus)
    .add(ACTIONS.LISTINGS.TOGGLE_LISTINGS, toggleListings)
    .add(ACTIONS.LISTINGS.TOGGLE_LISTINGS_FAILED, toggleListingsFailed)
    .add(ACTIONS.LISTINGS.TOGGLE_LISTINGS_SUCCEEDED, toggleListingsSucceeded)
    .add(ACTIONS.LISTINGS.TOGGLE_SIDEBAR_GROUP, toggleSidebarGroup)
    .add(ACTIONS.LISTINGS.TOGGLE_STATE, toggleState)
);
