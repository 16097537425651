import { List } from 'immutable';

export function replaceOperation(result = List(), operation) {
  let operations = result;

  function findSameOperation(item) {
    return item.get('type') === operation.get('type');
  }

  const index = operations.findIndex(findSameOperation);

  if (index !== -1) {
    operations = operations.delete(index);
  }

  return operations.push(operation.delete('products'));
}

export function updateOrReplaceTagsOperation({ operation, operations = List(), operationTypes }) {
  function findOperationByType(type) {
    return function find(item) {
      return item.get('type') === type;
    };
  }

  function updateValue(value) {
    return `${value}, ${operation.get('value')}`;
  }

  switch (operation.get('type')) {
    case operationTypes.delete: {
      const deleteOperationIndex = operations.findIndex(findOperationByType(operationTypes.delete));

      if (deleteOperationIndex !== -1) {
        return operations.updateIn([deleteOperationIndex, 'value'], updateValue);
      }

      const addOperationIndex = operations.findIndex(findOperationByType(operationTypes.add));

      if (addOperationIndex !== -1) {
        return operations.insert(addOperationIndex, operation);
      }

      return operations.push(operation);
    }

    default: {
      return replaceOperation(operations, operation);
    }
  }
}

export function filterOperations(parameter, equals = true) {
  return function filterOperation(operation) {
    let match = !equals;

    if (typeof parameter === 'function') {
      match = parameter(operation);
    } else if (typeof parameter === 'string') {
      match = operation.get('type') === parameter;
    } else if (parameter instanceof RegExp) {
      match = parameter.test(operation.get('type'));
    }

    return match === equals;
  };
}
