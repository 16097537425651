import { ERRORS, MAX_DESCRIPTION_LENGTH } from '../../constants/validations';
import { ETSY } from '../../constants/channels';

export function getDescriptionError({ channel, value }) {
  switch (channel) {
    case ETSY: {
      if (!value.length) {
        return ERRORS.DESCRIPTION.REQUIRED;
      } else if (value.length > MAX_DESCRIPTION_LENGTH[channel]) {
        return ERRORS.DESCRIPTION.LENGTH_EXCEEDED[channel];
      } else {
        return false;
      }
    }

    default: {
      return false;
    }
  }
}
