import he from 'he';
import XRegExp from 'xregexp';
import { parseFragment, serialize } from 'parse5';

import { convertBodyHTMLToMetaDescription } from '../../product/convert';
import { areEqual } from '../../iterable/areEqual';
import { getSize } from '../../iterable/getSize';

import { DESCRIPTION, META_DESCRIPTION, SEO } from '../../../constants/attributes';
import { FIND, OPERATIONS, REPLACE } from '../../../constants/bulkEdit';
import { DEFAULTS, SEPARATOR } from '../../../constants';
import { OVERSET, VALUE } from '../../../constants/product';
import { SHOPIFY } from '../../../constants/channels';

function traverse(object, callback) {
  const childNodes = object.childNodes;

  if (!childNodes) {
    if (object.nodeName === '#text') {
      return callback(object);
    }
  } else {
    object.childNodes = childNodes.map(function mapNodes(node) {
      return traverse(node, callback);
    });
  }

  return object;
}

export function decodeHTML(html) {
  return he.decode(html)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/\u00a0/g, SEPARATOR.SPACE);
}

function removeEmptyHTMLTagsTraverse({ parentNode, childNodes, ...rest }) {
  function reduceChildNodes(result, node) {
    if (
      node.nodeName === 'br' ||
      (
        node.nodeName === '#text'
          ? !!node.value && node.value !== SEPARATOR.SPACE
          : !!getSize(node.childNodes)
      )
    ) {
      result.push(removeEmptyHTMLTagsTraverse(node));
    }

    return result;
  }

  return {
    ...rest,
    childNodes: getSize(childNodes)
      ? childNodes.reduce(reduceChildNodes, [])
      : childNodes,
  };
}

export function removeEmptyHTMLTags(html) {
  let oldFragments;
  let newFragments = parseFragment(html);

  do {
    oldFragments = newFragments;
    newFragments = removeEmptyHTMLTagsTraverse(oldFragments);
  } while (!areEqual(oldFragments, newFragments));

  return serialize(newFragments);
}

export function stripTopParagraph(html) {
  if (typeof html !== 'string' || !html) return html;

  const fragments = parseFragment(html);
  const childNodes = fragments.childNodes || [];

  if (!childNodes.length) {
    return html;
  }
  if (childNodes.length === 1) {
    if (childNodes[0].nodeName !== '#text') {
      childNodes[0].nodeName = 'span';
      childNodes[0].tagName = 'span';
    }
  } else {
    fragments.childNodes = childNodes.map(function mapNodes(node) {
      let style = node.attrs.find(function findAttribute({ name }) {
        return name === 'style';
      });

      if (!style) {
        style = { name: 'style' };
        node.attrs.push(style);
      }

      style.value = `display: block;margin: 0 0 1em 0;line-height: 1.4;${style.value || DEFAULTS.EMPTY_STRING}`;

      node.tagName = node.nodeName === 'p' ? 'span' : node.tagName;
      node.nodeName = node.nodeName === 'p' ? 'span' : node.nodeName;
      return node;
    });
  }

  return serialize(fragments);
}

export function removeWhiteSpaces(string = DEFAULTS.EMPTY_STRING) {
  const stringWithoutNewLines = XRegExp.replace(string, new XRegExp('[\n\r\u000a]', 'g'), DEFAULTS.EMPTY_STRING);
  return XRegExp.replace(stringWithoutNewLines, new XRegExp('\\s', 'g'), ' ');
}

function normalizeHtml(html) {
  const normalizedFragments = traverse(
    parseFragment(html, { sourceCodeLocationInfo: true }),
    function callback(node) {
      node.value = decodeHTML(node.value);
      return node;
    }
  );

  return serialize(normalizedFragments).replace(/&amp;/g, '&');
}

function cleanupString(string = DEFAULTS.EMPTY_STRING) {
  return normalizeHtml(removeWhiteSpaces(string));
}

function createHtmlToCharacterMap(html) {
  const cleanHtml = cleanupString(html);
  const htmlWithNormalizedText = normalizeHtml(cleanHtml);
  const fragments = parseFragment(htmlWithNormalizedText, { sourceCodeLocationInfo: true });

  let innerText = DEFAULTS.EMPTY_STRING;
  const characterMap = [];

  traverse(
    fragments,
    function callback(node) {
      const offset = innerText.length;
      const value = decodeHTML(node.value);
      innerText += value;

      for (let i = 0; i < value.length; ++i) {
        characterMap[offset + i] = node.sourceCodeLocation.startOffset + i;
      }

      node.value = value;
      return node;
    }
  );

  return {
    html: htmlWithNormalizedText,
    innerText: innerText,
    characterMap: characterMap,
  };
}

function getIndexesInInnerHtml(htmlInfo, find) {
  const findRegexp = new XRegExp(XRegExp.escape(find), 'gi');
  const innerText = htmlInfo.innerText;
  const matches = innerText.match(findRegexp) || [];

  let offset = 0;

  return matches.map(function mapMatches(match) {
    const index = innerText.indexOf(match, offset);
    offset = index + match.length;
    return index;
  });
}

function getContinuosRangeIndex(characterMap, startIndex, findLength) {
  let rangeLength = 1;

  while ((characterMap[startIndex + rangeLength] === characterMap[startIndex + rangeLength - 1] + 1) && rangeLength < findLength) {
    rangeLength++;
  }

  return {
    startIndex: startIndex,
    endIndex: startIndex + rangeLength,
    length: rangeLength,
  };
}

function catString(string, start, end) {
  return string.substring(0, start) + string.substring(end);
}

function insertString(string, start, stringToInsert) {
  return string.substring(0, start) + stringToInsert + string.substring(start);
}

function replaceHtmlPart(html, findLength, replaceFn, characterMap, startIndex, offset) {
  const continuousRange = getContinuosRangeIndex(characterMap, startIndex, findLength);
  const startIndexInHtml = characterMap[continuousRange.startIndex];
  const originalText = html.slice(startIndexInHtml + offset, startIndexInHtml + offset + continuousRange.length);
  const htmlWithoutRange = catString(html, startIndexInHtml + offset, startIndexInHtml + offset + continuousRange.length);
  const replaceText = replaceFn(originalText);
  const newHtml = insertString(htmlWithoutRange, startIndexInHtml + offset, replaceText);
  return {
    html: newHtml,
    length: continuousRange.length,
    offset: offset + replaceText.length - continuousRange.length,
  };
}

export function findAndReplace(html, find, replaceFn) {
  const htmlInfo = createHtmlToCharacterMap(html);
  const indexes = getIndexesInInnerHtml(htmlInfo, find);

  function reduceIndexes(result, innerTextMatchIndex) {
    let replaceInfo = replaceHtmlPart(result.html, find.length, replaceFn.bind(this, false), htmlInfo.characterMap, innerTextMatchIndex, result.offset);

    if (replaceInfo.length === find.length) {
      return replaceInfo;
    }

    let findLength = find.length - replaceInfo.length;
    let innerTextMatchIndexOffset = replaceInfo.length;

    while (findLength) {
      replaceInfo = replaceHtmlPart(replaceInfo.html, findLength, replaceFn.bind(this, true), htmlInfo.characterMap, innerTextMatchIndex + innerTextMatchIndexOffset, replaceInfo.offset);
      innerTextMatchIndexOffset = innerTextMatchIndexOffset + replaceInfo.length;
      findLength = findLength - replaceInfo.length;
    }
    return replaceInfo;
  }

  const info = indexes.reduce(reduceIndexes, { html: htmlInfo.html, offset: 0 });

  return removeEmptyHTMLTags(info.html);
}

export default function bodyHTML({ actions, operation, product: source }) {
  let product = source;

  switch (operation.get('type')) {
    case OPERATIONS[SHOPIFY].DESCRIPTION.ADD_AFTER: {
      product = product.setIn([DESCRIPTION, VALUE],
        `${product.getIn([DESCRIPTION, VALUE])}${operation.get(VALUE)}`
      );

      break;
    }

    case OPERATIONS[SHOPIFY].DESCRIPTION.ADD_BEFORE: {
      product = product.setIn([DESCRIPTION, VALUE],
        `${operation.get(VALUE)}${product.getIn([DESCRIPTION, VALUE])}`
      );

      break;
    }

    case OPERATIONS[SHOPIFY].DESCRIPTION.FIND_AND_REPLACE: {
      const productValue = removeEmptyHTMLTags(product.getIn([DESCRIPTION, VALUE]));
      const find = removeWhiteSpaces(decodeHTML(operation.getIn([VALUE, FIND])));
      const replace = stripTopParagraph(operation.getIn([VALUE, REPLACE]));

      if (!productValue || !find || !replace) break;

      product = product.setIn([DESCRIPTION, VALUE],
        findAndReplace(
          productValue,
          find,
          function callback(remove) {
            return remove ? DEFAULTS.EMPTY_STRING : replace;
          }
        )
      );

      break;
    }

    case OPERATIONS[SHOPIFY].DESCRIPTION.DELETE: {
      const productValue = removeEmptyHTMLTags(product.getIn([DESCRIPTION, VALUE]));
      const operationValue = removeWhiteSpaces(decodeHTML(operation.get(VALUE)));

      if (!productValue || !operationValue) break;

      product = product.setIn([DESCRIPTION, VALUE],
        findAndReplace(
          productValue,
          operationValue,
          function callback() {
            return DEFAULTS.EMPTY_STRING;
          }
        )
      );

      break;
    }

    case OPERATIONS[SHOPIFY].DESCRIPTION.SET: {
      product = product.setIn([DESCRIPTION, VALUE], operation.get(VALUE));
      break;
    }

    default: {
      break;
    }
  }

  if (product.getIn([SEO, META_DESCRIPTION, OVERSET])) {
    product = product.setIn([SEO, META_DESCRIPTION, VALUE],
      convertBodyHTMLToMetaDescription(product.getIn([DESCRIPTION, VALUE]))
    );
  }

  return { actions, product };
}
