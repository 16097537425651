import { Map, OrderedMap } from 'immutable';

import { clearErrors, setErrors } from '../errors';
import { getTagsError } from '../validations/tags';
import { keepOld } from '../iterable/keepOld';
import { getSize } from '../iterable/getSize';

import { MAX_NUMBER_OF_TAGS } from '../../constants/validations';
import { OPERATIONS, VALUE } from '../../constants/product';
import { NEW_TAGS, TAGS } from '../../constants/attributes';
import { SEPARATOR } from '../../constants';

export function getTagsAndError({ channel, newTags, tags }) {
  function reduceNewTags(result, value, key) {
    if (getSize(result.allTags) >= MAX_NUMBER_OF_TAGS[channel]) return result;

    if (!result.allTags.has(key)) {
      result.allTags = result.allTags.set(key, value);
      result.updatedNewTags = result.updatedNewTags.set(key, value);
    }

    return result;
  }

  const { allTags, updatedNewTags } = newTags.reduce(
    reduceNewTags,
    { allTags: tags, updatedNewTags: OrderedMap() }
  );

  const error = getTagsError({ channel, tags: allTags });

  return { error, newTags: updatedNewTags, tags };
}

export function editTags({ channel, newTags, operation: operationType, product: source, tagsToRemove }) {
  const operations = [];
  let product = clearErrors({ errors: [TAGS], product: source });
  const currentProfile = product.getIn([TAGS, 'currentProfile']);
  const profileSelected = !!currentProfile;
  const productTags = profileSelected
    ? currentProfile.get(TAGS)
    : product.getIn([TAGS, VALUE]);

  switch (operationType) {
    case OPERATIONS[channel].TAGS.ADD: {
      const { error, newTags: updatedNewTags } = getTagsAndError({
        channel,
        newTags,
        tags: productTags,
      });

      product = product.setIn([TAGS, NEW_TAGS], updatedNewTags);

      if (error) {
        product = setErrors({ errors: Map({ [TAGS]: error }), product });
      } else if (getSize(updatedNewTags)) {
        operations.push(
          Map({
            type: OPERATIONS[channel].TAGS.ADD,
            value: updatedNewTags.toList().join(SEPARATOR.COMMA_SPACE),
          })
        );
      }

      break;
    }

    case OPERATIONS[channel].TAGS.DELETE: {
      if (tagsToRemove) {
        product = product.setIn([TAGS, VALUE], productTags.deleteAll(tagsToRemove.keySeq()));
        operations.push(
          Map({
            type: OPERATIONS[channel].TAGS.DELETE,
            value: tagsToRemove.toList().join(SEPARATOR.COMMA_SPACE),
          })
        );
      }

      if (getSize(newTags)) {
        const { error, newTags: updatedNewTags } = getTagsAndError({
          channel,
          newTags,
          tags: product.getIn([TAGS, VALUE]),
        });

        product = product.setIn([TAGS, NEW_TAGS], updatedNewTags);

        if (error) {
          product = setErrors({ errors: Map({ [TAGS]: error }), product });
        } else {
          operations.push(
            Map({
              type: OPERATIONS[channel].TAGS.ADD,
              value: updatedNewTags.toList().join(SEPARATOR.COMMA_SPACE),
            })
          );
        }
      }

      break;
    }

    case OPERATIONS[channel].TAGS.CHANGE_TO: {
      if (newTags) {
        product = product.setIn([TAGS, NEW_TAGS], newTags);
      }

      if (tagsToRemove) {
        const filteredTags = productTags.deleteAll(tagsToRemove.keySeq());
        product = profileSelected
          ? product.setIn([TAGS, 'currentProfile', TAGS], filteredTags)
          : product.setIn([TAGS, VALUE], filteredTags);
      }

      let allTags = profileSelected
        ? product.getIn([TAGS, 'currentProfile', TAGS])
        : product.getIn([TAGS, VALUE]);

      allTags = allTags.mergeWith(keepOld, product.getIn([TAGS, NEW_TAGS]));
      const error = getTagsError({ channel, tags: allTags });

      if (error) {
        product = setErrors({ errors: Map({ [TAGS]: error }), product });
      } else {
        let operation = Map({ type: OPERATIONS[channel].TAGS.CHANGE_TO, value: allTags.toList() });

        if (profileSelected && currentProfile.has('profileId')) {
          operation = operation.set('tagsProfileId', currentProfile.get('profileId'));
        }

        operations.push(operation);
      }

      break;
    }

    default: {
      break;
    }
  }

  return { product, operations };
}
