
import { fromJS, List, Map, OrderedMap } from 'immutable';

import { filterOperations, replaceOperation } from './operations';
import { shapeVariationsProfileForApp } from '../profiles/shapeForApp';
import { filterNewTags, tagsFromArray } from '../tags';
import { areComplete, setIsComplete } from './isComplete';
import { getAttributesForCategory } from './taxonomy';
import { editVariations } from '../edit/variations';
import { newProfile } from '../new/profile';
import { editTags } from '../edit/tags';
import { shapeId } from '../listings/listings';
import { getSize } from '../iterable/getSize';
import { convert } from './convert';
import { retain } from '../variations/retain';
import {
  setCategoryErrors,
  setTaxonomyAttributesErrors,
  updateProductVariationsErrors,
} from './errors';

import { CATEGORY, NEW_TAGS, TAGS, TAXONOMY_ATTRIBUTES, VARIATIONS } from '../../constants/attributes';
import { NEW, OPERATIONS, SECTIONS, VALUE } from '../../constants/product';
import { MAX_NUMBER_OF_TAGS } from '../../constants/validations';
import { DEFAULTS, VELA } from '../../constants';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { PROFILE } from '../../constants/profiles';
import { MODALS } from '../../constants/modal';

const { DETAILS } = SECTIONS;

export function applyProfile({ channel, data, fromVelaForm, profileId, shopId, state, type }) {
  function reduceChannelsForTags(velaTags) {
    return function reduceChannels(productsByShopId, shopIds, shopsChannel) {
      const tags = convert({
        from: channel,
        to: shopsChannel,
        type: TAGS,
        value: velaTags.getIn(['currentProfile', TAGS]),
      });

      const currentProfile = Map({ [TAGS]: tags });

      function reduceShopIds(result, productShopId) {
        function updateProduct(source) {
          const product = source
            .deleteIn([TAGS, NEW_TAGS])
            .setIn([TAGS, VALUE], OrderedMap())
            .setIn([TAGS, 'currentProfile'],
              productShopId === shopId
                ? currentProfile.set('profileId', velaTags.getIn(['currentProfile', 'profileId']))
                : currentProfile
            );

          const freeSpace = MAX_NUMBER_OF_TAGS[shopsChannel] - getSize(tags);
          const newTags = freeSpace > 0
            ? velaTags
              .get(NEW_TAGS)
              .filter(filterNewTags(currentProfile.get(TAGS)))
              .slice(0, freeSpace)
            : OrderedMap();

          const updates = editTags({
            channel: shopsChannel,
            newTags,
            operation: OPERATIONS[channel].TAGS.CHANGE_TO,
            product,
          });

          return setIsComplete(
            updates.product.set('operations',
              updates.operations.reduce(
                replaceOperation,
                updates.product.get('operations', DEFAULTS.EMPTY_LIST)
              )
            )
          );
        }

        return result.update(productShopId, updateProduct);
      }

      return shopIds.reduce(reduceShopIds, productsByShopId);
    };
  }

  function reduceChannelsForVariations(profile) {
    return function reduceChannels(productsByShopId, shopIds, shopsChannel) {
      function reduceShopIds(result, productShopId) {
        function updateProduct(product) {
          const updates = editVariations({
            channel: shopsChannel,
            product: product.set(VARIATIONS,
              newProfile({ channel: shopsChannel, forProduct: true, type: PROFILE.VARIATIONS })
            ),
            updateCategory: channel === ETSY,
            value: convert({
              from: channel,
              product,
              to: shopsChannel,
              type: VARIATIONS,
              value: profile
                .delete('oldProfile')
                .delete('currentProfile')
                .set('photosHidden',
                  shopsChannel === SHOPIFY && product.get('productId') === NEW
                ),
            }),
          });

          return setIsComplete(
            updates.product.set('operations',
              updates.operations.reduce(
                replaceOperation,
                updates.product.get('operations', DEFAULTS.EMPTY_LIST)
              )
            )
          );
        }

        return result.update(productShopId, updateProduct);
      }

      return shopIds.reduce(reduceShopIds, productsByShopId);
    };
  }

  if (fromVelaForm) {
    switch (type) {
      case PROFILE.TAGS: {
        const currentProfile = Map({ profileId, shopId, [TAGS]: tagsFromArray(data.profile.tags) });
        const tags = Map({
          currentProfile,
          [NEW_TAGS]: state
            .getIn([VELA, TAGS, NEW_TAGS], OrderedMap())
            .filter(filterNewTags(currentProfile.get(TAGS))),
        });

        const products = state
          .get('channels')
          .reduce(reduceChannelsForTags(tags), state.get('products'));

        return {
          state: state
            .setIn([VELA, TAGS], tags)
            .set('products', products)
            .set('isComplete', areComplete(products)),
        };
      }

      case PROFILE.VARIATIONS: {
        const { profile } = data;
        const product = state.get(VELA, Map());
        const oldProfile = product.getIn([VARIATIONS, 'oldProfile'], product.get(VARIATIONS));
        const variations = shapeVariationsProfileForApp({ channel, profile, product })
          .delete('id')
          .delete('title')
          .delete('invalid')
          .delete('listingsCount')
          .set('photosHidden', true)
          .set('oldProfile', oldProfile)
          .set('currentProfile', Map({ profileId, shopId }));

        const products = state
          .get('channels')
          .reduce(reduceChannelsForVariations(variations), state.get('products'));

        return {
          state: state
            .set(VELA, product.set(VARIATIONS, variations))
            .set('products', products)
            .set('isComplete', areComplete(products)),
        };
      }

      default: {
        return { state };
      }
    }
  }

  let confirmation;
  let product = state.getIn(['products', shopId]);
  const operations = product.get('operations', List());

  function splitOperationsByType(pattern) {
    return function reduceOperations(result, operation) {
      if (pattern.test(operation.get('type'))) {
        result.archivedOperations = result.archivedOperations.push(operation);
      } else {
        result.filteredOperations = result.filteredOperations.push(operation);
      }

      return result;
    };
  }

  switch (type) {
    case PROFILE.TAGS: {
      const profile = Map({
        profileId: shapeId(data.profile.id),
        [TAGS]: tagsFromArray(data.profile.tags),
      });

      product = product.setIn([TAGS, 'currentProfile'], profile);

      const freeSpace = MAX_NUMBER_OF_TAGS[channel] - getSize(profile.get(TAGS));
      const newTags = freeSpace > 0
        ? product
          .getIn([TAGS, NEW_TAGS], OrderedMap())
          .filter(filterNewTags(profile.get(TAGS)))
          .slice(0, freeSpace)
        : OrderedMap();

      const updates = editTags({
        channel,
        newTags,
        operation: OPERATIONS[channel].TAGS.CHANGE_TO,
        product,
      });

      product = updates.product.set('operations',
        operations
          .filter(filterOperations(OPERATIONS[channel].TAGS.CHANGE_TO, false))
          .concat(List(updates.operations))
      );

      break;
    }

    case PROFILE.VARIATIONS: {
      const splittedOperations = operations.reduce(
        splitOperationsByType(/(^taxonomy\.|Inventory\.)/),
        { archivedOperations: List(), filteredOperations: List() }
      );
      let { archivedOperations } = splittedOperations;
      const { filteredOperations } = splittedOperations;
      const { profile } = data;
      let operation = Map({ type: OPERATIONS[channel].VARIATIONS });
      const profileVariations = shapeVariationsProfileForApp({ channel, profile });
      const title = profileVariations.get('title');
      let immutableProfile = profileVariations
        .delete('id')
        .delete('title')
        .delete('invalid')
        .delete('listingsCount');

      let oldProfile = product.getIn([VARIATIONS, 'oldProfile']);

      if (!oldProfile) {
        oldProfile = product.get(VARIATIONS);
      } else {
        archivedOperations = product.getIn([VARIATIONS, 'operations']);
      }

      switch (channel) {
        case ETSY: {
          immutableProfile = immutableProfile.set('offerings',
            immutableProfile.get('offerings').map(function mapOfferings(offering, offeringIndex) {
              const negativeId = -1 - offeringIndex;
              profile.value.offerings[offeringIndex].id = negativeId;
              return offering.set('id', negativeId);
            })
          );

          if (product.getIn([DETAILS, CATEGORY, VALUE]) !== immutableProfile.get(CATEGORY)) {
            product = product = setCategoryErrors({
              channel: ETSY,
              product: product.setIn([DETAILS, CATEGORY, VALUE], immutableProfile.get(CATEGORY)),
            });

            const { changed, taxonomyAttributes } = getAttributesForCategory({
              category: immutableProfile.get(CATEGORY),
              taxonomyAttributes: product.getIn([DETAILS, TAXONOMY_ATTRIBUTES], Map()),
            });

            product = setTaxonomyAttributesErrors({
              channel,
              product: product.setIn([DETAILS, TAXONOMY_ATTRIBUTES], taxonomyAttributes),
            });

            if (changed) {
              confirmation = MODALS.CONFIRMATIONS.CHANGE_CATEGORY;
            }
          }

          break;
        }

        case SHOPIFY: {
          immutableProfile = immutableProfile.set('photosHidden', state.get('productId') === NEW);
          operation = operation
            .set('templateId', profile.templateId)
            .set('shopifyMeta', Map(profile.shopifyMeta));

          break;
        }

        default: {
          break;
        }
      }

      operation = operation.set(VALUE, fromJS(profile.value));

      immutableProfile = retain({
        channel,
        profile: immutableProfile,
        product: product.set(VARIATIONS, oldProfile),
      })
        .set('oldProfile', oldProfile)
        .set('currentProfile', Map({ profileId, title }));

      product = product
        .set(VARIATIONS, immutableProfile.set('operations', archivedOperations))
        .set('operations', filteredOperations.push(operation));

      product = updateProductVariationsErrors({ channel, product });
      break;
    }

    default: {
      break;
    }
  }
  product = setIsComplete(product);

  const products = state.get('products').set(shopId, product);

  return {
    confirmation,
    state: state
      .set('products', products)
      .set('isComplete', areComplete(products)),
  };
}
