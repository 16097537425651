import { useRef } from 'react';

export function useDebounce() {
  const timeout = useRef(undefined);

  function debounce({ callback, delay }) {
    timeout.current && clearTimeout(timeout.current);
    timeout.current = setTimeout(callback, delay);
  }

  function stopTimeout() {
    timeout.current = clearTimeout(timeout.current);
  }

  return [debounce, stopTimeout, timeout];
}
