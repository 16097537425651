import Big from 'big.js';

import { SEPARATOR } from '../constants';

export function addThousandsSeparator(value, decimals = 0, fillDecimalsWithZero = false) {
  try {
    const number = new Big(value);
    let whole = number.toFixed(0, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (!decimals) return whole;

    let decimal = number.c.slice(number.e + 1);

    if (fillDecimalsWithZero && decimal.length < decimals) {
      decimal.splice(decimal.length, 0, ...Array(decimals - decimal.length).fill(0));
    }

    decimal = decimal.slice(0, decimals);

    if (decimal.length) {
      whole += `.${decimal.join('')}`;
    }

    return whole;
  } catch (error) {
    return value;
  }
}

export function addLeadingZero(number) {
  return Number.isFinite(number)
    ? number < 10
      ? `0${number}`
      : String(number)
    : number;
}

export function cutLeadingZeros(value) {
  return value.replace(/^(-)?[0]*(?=\d)/, /^-/.test(value) ? '-' : '');
}

export function cutDecimals(value, decimals = 0) {
  if (typeof value !== 'string' || !/\./.test(value) || /^\.$/.test(value)) return value;

  const index = value.indexOf(SEPARATOR.DOT);

  return index
    ? value.slice(0, index + decimals + 1)
    : `0${value.slice(0, decimals + 1)}`;
}

export function isValidNumber(value) {
  try {
    return !!(new Big(value));
  } catch (error) {
    return false;
  }
}

export function isEven(number) {
  return number % 2 === 0;
}

export function toFixed(value, decimals = 0) {
  try {
    return new Big(value).toFixed(decimals, 0).toString();
  } catch (error) {
    return value === 'null' ? null : value;
  }
}

export function toString(value) {
  return typeof value === 'string' || !Number.isFinite(value)
    ? value
    : String(value);
}

export function formatNumber(value, digits = 1) {
  if (!value || !isValidNumber(value)) return value;

  const number = parseFloat(value);
  const lookup = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' },
    { value: 1, symbol: '' },
  ];

  function findSymbol(item) {
    return number >= item.value;
  }

  const item = lookup.find(findSymbol);

  return item
    ? `${(number / item.value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1')}${item.symbol}`
    : number;
}

export function formatFileSize(size) {
  if (!size || !isValidNumber(size)) return '0 B';

  const exponent = new Big(Math.log(size) / Math.log(1024)).round(0, 0).toNumber();
  const value = new Big(size).div(new Big(1024).pow(exponent)).round(2);
  const measure = ['B', 'KB', 'MB', 'GB', 'TB'][exponent];

  return `${value} ${measure}`;
}
