import { Map } from 'immutable';
import Big from 'big.js';

import { areShippingDimensionsOptional } from '../product/checkAttribute';
import { isValidNumber } from '../number';

import { HEIGHT, LENGTH, PROFILE_ID, WEIGHT, WIDTH } from '../../constants/attributes';
import { VALUE } from '../../constants/product';
import { ETSY } from '../../constants/channels';
import {
  ERRORS,
  MAX_HEIGHT,
  MAX_LENGTH,
  MAX_WEIGHT,
  MAX_WIDTH,
  MIN_HEIGHT,
  MIN_LENGTH,
  MIN_WEIGHT,
  MIN_WIDTH,
} from '../../constants/validations';

export function getHeightError({ channel, height, length, allowEmpty = true, allowZero = true }) {
  if (!isValidNumber(height)) {
    return !allowEmpty && ERRORS.SHIPPING[channel].HEIGHT.REQUIRED;
  }

  if (!allowZero && parseFloat(height) === 0) {
    return ERRORS.SHIPPING[channel].HEIGHT.REQUIRED;
  }

  const heightNumber = new Big(height);
  const minimum = channel in MIN_HEIGHT && new Big(String(MIN_HEIGHT[channel]));
  const maximum = channel in MAX_HEIGHT && new Big(String(MAX_HEIGHT[channel]));

  if (minimum && minimum.gt(heightNumber)) {
    return ERRORS.SHIPPING[channel].HEIGHT.TOO_LOW;
  } else if (maximum && !maximum.gt(heightNumber)) {
    return ERRORS.SHIPPING[channel].HEIGHT.TOO_HIGH;
  } else if (isValidNumber(length) && heightNumber.gt(new Big(length))) {
    return ERRORS.SHIPPING[channel].LENGTH.NOT_LARGEST;
  } else {
    return false;
  }
}

export function getLengthError({ channel, height, length, width, allowEmpty = true, allowZero = true }) {
  if (!isValidNumber(length)) {
    return !allowEmpty && ERRORS.SHIPPING[channel].LENGTH.REQUIRED;
  }

  if (!allowZero && parseFloat(length) === 0) {
    return ERRORS.SHIPPING[channel].LENGTH.REQUIRED;
  }

  const lengthNumber = new Big(length);
  const minimum = channel in MIN_LENGTH && new Big(String(MIN_LENGTH[channel]));
  const maximum = channel in MAX_LENGTH && new Big(String(MAX_LENGTH[channel]));

  if (minimum && minimum.gt(lengthNumber)) {
    return ERRORS.SHIPPING[channel].LENGTH.TOO_LOW;
  } else if (maximum && !maximum.gt(lengthNumber)) {
    return ERRORS.SHIPPING[channel].LENGTH.TOO_HIGH;
  } else if (isValidNumber(height) && !lengthNumber.gte(new Big(height))) {
    return ERRORS.SHIPPING[channel].LENGTH.NOT_LARGEST;
  } else if (isValidNumber(width) && !lengthNumber.gte(new Big(width))) {
    return ERRORS.SHIPPING[channel].LENGTH.NOT_LARGEST;
  } else {
    return false;
  }
}

export function getWeightError({ channel, weight, allowEmpty = true, allowZero = true }) {
  if (!isValidNumber(weight)) {
    return !allowEmpty && ERRORS.SHIPPING[channel].WEIGHT.REQUIRED;
  }

  if (!allowZero && parseFloat(weight) === 0) {
    return ERRORS.SHIPPING[channel].WEIGHT.REQUIRED;
  }

  const weightNumber = new Big(weight);
  const minimum = channel in MIN_WEIGHT && new Big(String(MIN_WEIGHT[channel]));
  const maximum = channel in MAX_WEIGHT && new Big(String(MAX_WEIGHT[channel]));

  if (minimum && minimum.gt(weightNumber)) {
    return ERRORS.SHIPPING[channel].WEIGHT.TOO_LOW;
  } else if (maximum && !maximum.gt(weightNumber)) {
    return ERRORS.SHIPPING[channel].WEIGHT.TOO_HIGH;
  } else {
    return false;
  }
}

export function getWidthError({ channel, length, width, allowEmpty = true, allowZero = true }) {
  if (!isValidNumber(width)) {
    return !allowEmpty && ERRORS.SHIPPING[channel].WIDTH.REQUIRED;
  }

  if (!allowZero && parseFloat(width) === 0) {
    return ERRORS.SHIPPING[channel].WIDTH.REQUIRED;
  }

  const widthNumber = new Big(width);
  const minimum = channel in MIN_WIDTH && new Big(String(MIN_WIDTH[channel]));
  const maximum = channel in MAX_WIDTH && new Big(String(MAX_WIDTH[channel]));

  if (minimum && minimum.gt(widthNumber)) {
    return ERRORS.SHIPPING[channel].WIDTH.TOO_LOW;
  } else if (maximum && !maximum.gt(widthNumber)) {
    return ERRORS.SHIPPING[channel].WIDTH.TOO_HIGH;
  } else if (isValidNumber(length) && widthNumber.gt(new Big(length))) {
    return ERRORS.SHIPPING[channel].LENGTH.NOT_LARGEST;
  } else {
    return false;
  }
}

export function validateShipping({ allowEmpty: skipForEmpty, channel, shipping }) {
  let errors = Map();

  switch (channel) {
    case ETSY: {
      if (!shipping.getIn([PROFILE_ID, VALUE]) && !skipForEmpty) {
        errors = errors.set(PROFILE_ID, ERRORS.SHIPPING[channel].TEMPLATE);
      }

      const allowEmpty = skipForEmpty ?? areShippingDimensionsOptional(shipping);
      const allowZero = skipForEmpty ? areShippingDimensionsOptional(shipping) : allowEmpty;
      const height = shipping.getIn([HEIGHT, VALUE]);
      const length = shipping.getIn([LENGTH, VALUE]);
      const weight = shipping.getIn([WEIGHT, VALUE]);
      const width = shipping.getIn([WIDTH, VALUE]);
      const heightError = getHeightError({ channel, height, length, allowEmpty, allowZero });
      const lengthError = getLengthError({ channel, length, allowEmpty, allowZero });
      const weightError = getWeightError({ channel, weight, allowEmpty, allowZero });
      const widthError = getWidthError({ channel, length, width, allowEmpty, allowZero });

      if (heightError) {
        errors = errors.set(HEIGHT, heightError);
      }

      if (lengthError) {
        errors = errors.set(LENGTH, lengthError);
      }

      if (weightError) {
        errors = errors.set(WEIGHT, weightError);
      }

      if (widthError) {
        errors = errors.set(WIDTH, widthError);
      }

      break;
    }

    default:
      break;
  }

  return errors;
}
