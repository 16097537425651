import { Map } from 'immutable';

import { DEFAULTS, VELA } from '.';
import { ETSY, SHOPIFY } from './channels';
import ATTRIBUTES from './attributes';

export const THUMBNAIL_SIZE = 42;

export const MAX_EDIT_HEIGHT_SIZE = '675px';

export const PROFILE = {
  LISTINGS: 'listings',
  TAGS: 'tags',
  SALES: 'sales',
  VARIATIONS: 'variations',
};

export const PROFILE_TYPE_NAME = {
  [PROFILE.LISTINGS]: 'Listings',
  [PROFILE.TAGS]: 'Tags',
  [PROFILE.SALES]: 'Sales',
  [PROFILE.VARIATIONS]: 'Variations',
};

export const PROFILES_TYPES = [
  PROFILE.LISTINGS,
  PROFILE.VARIATIONS,
  PROFILE.TAGS,
  PROFILE.SALES,
];

export const PROFILES_PER_PAGE = {
  [PROFILE.LISTINGS]: 10,
  [PROFILE.SALES]: 100,
  [PROFILE.TAGS]: 10,
  [PROFILE.VARIATIONS]: 10,
};

export const TABS = {
  PROFILE: {
    [ETSY]: ['Variations', 'Price', 'Quantity', 'SKU', 'Visibility'],
    [SHOPIFY]: ['Variations', 'Price', 'Quantity', 'SKU', 'Barcode', 'Shipping'],
    [VELA]: ['Variations', 'Price', 'Quantity', 'SKU'],
  },
  PRODUCT: {
    [ETSY]: ['Variations', 'Price', 'Quantity', 'SKU', 'Visibility', 'Photos'],
    [SHOPIFY]: ['Variations', 'Price', 'Quantity', 'SKU', 'Barcode', 'Photos', 'Shipping'],
  },
};

export const INDIVIDUAL = {
  BARCODE: 'individualBarcode',
  BOTH_VARIATIONS: 'hasBothIndividual',
  PRICE: 'individualPrice',
  QUANTITY: 'individualQuantity',
  SKU: 'individualSKU',
  SHIPPING: 'individualShipping',
};

export const INDIVIDUAL_FLAGS = {
  [ETSY]: [
    INDIVIDUAL.PRICE,
    INDIVIDUAL.QUANTITY,
    INDIVIDUAL.SKU,
  ],
  [SHOPIFY]: [
    INDIVIDUAL.BARCODE,
    INDIVIDUAL.PRICE,
    INDIVIDUAL.QUANTITY,
    INDIVIDUAL.SKU,
    INDIVIDUAL.SHIPPING,
  ],
};

export const INDIVIDUAL_FLAGS_MAP = {
  [ATTRIBUTES.BARCODE]: INDIVIDUAL.BARCODE,
  [ATTRIBUTES.PRICE]: INDIVIDUAL.PRICE,
  [ATTRIBUTES.QUANTITY]: INDIVIDUAL.QUANTITY,
  [ATTRIBUTES.SKU]: INDIVIDUAL.SKU,
  [ATTRIBUTES.SHIPPING]: INDIVIDUAL.SHIPPING,
  [ATTRIBUTES.VISIBILITY]: INDIVIDUAL.BOTH_VARIATIONS,
};

export const INDIVIDUAL_TO_ATTRIBUTE_MAP = {
  [INDIVIDUAL.BARCODE]: ATTRIBUTES.BARCODE,
  [INDIVIDUAL.PRICE]: ATTRIBUTES.PRICE,
  [INDIVIDUAL.QUANTITY]: ATTRIBUTES.QUANTITY,
  [INDIVIDUAL.SKU]: ATTRIBUTES.SKU,
  [INDIVIDUAL.SHIPPING]: ATTRIBUTES.SHIPPING,
};

export const INDIVIDUAL_CHECKBOX_LABEL = {
  [ATTRIBUTES.PRICE]: 'Individual price',
  [ATTRIBUTES.QUANTITY]: 'Individual quantity',
  [ATTRIBUTES.SKU]: 'Individual SKU',
};

export const OFFERINGS = {
  [ETSY]: {
    [ATTRIBUTES.PRICE]: {
      individualProperty: INDIVIDUAL.PRICE,
    },
    [ATTRIBUTES.QUANTITY]: {
      individualProperty: INDIVIDUAL.QUANTITY,
    },
    [ATTRIBUTES.SKU]: {
      individualProperty: INDIVIDUAL.SKU,
    },
    [ATTRIBUTES.VISIBILITY]: {
      individualProperty: INDIVIDUAL.BOTH_VARIATIONS,
    },
    [ATTRIBUTES.PHOTOS]: {},
  },
  [SHOPIFY]: {
    [ATTRIBUTES.PRICE]: {
      individualProperty: INDIVIDUAL.PRICE,
    },
    [ATTRIBUTES.CAP]: {
      individualProperty: INDIVIDUAL.PRICE,
    },
    [ATTRIBUTES.CPI]: {
      individualProperty: INDIVIDUAL.PRICE,
    },
    [ATTRIBUTES.QUANTITY]: {
      individualProperty: ATTRIBUTES.TRACK_QUANTITY,
    },
    [ATTRIBUTES.SKU]: {
      individualProperty: INDIVIDUAL.SKU,
    },
    [ATTRIBUTES.BARCODE]: {
      individualProperty: INDIVIDUAL.BARCODE,
    },
    [ATTRIBUTES.PHOTOS]: {},
    [ATTRIBUTES.WEIGHT]: {
      individualProperty: INDIVIDUAL.SHIPPING,
    },
    [ATTRIBUTES.CHARGE_TAX]: {},
    [ATTRIBUTES.CONTINUE_SELLING]: {},
    [ATTRIBUTES.COUNTRY_CODE]: {},
    [ATTRIBUTES.HS_CODE]: {},
    [ATTRIBUTES.UNIT]: {},
    [ATTRIBUTES.PHYSICAL]: {},
  },
};

export const MAXIMUM_OPTIONS_TO_SHOW = 4;

export const NUMBER_OF_VARIATIONS = {
  [ETSY]: 2,
  [SHOPIFY]: 3,
  [VELA]: 3,
};

export const NUMERAL_ADJECTIVES = [
  'first',
  'second',
  'third',
];

export const MAXIMUM_NUMBER_OF_OPTIONS = {
  [ETSY]: 70,
  [SHOPIFY]: 100,
};

export const MAXIMUM_NUMBER_OF_OFFERINGS = {
  [ETSY]: 400,
  [SHOPIFY]: 100,
};

export const MAXIMUM_VARIATION_NAME_LENGTH = {
  [ETSY]: 45,
  [SHOPIFY]: 255,
};

export const MAXIMUM_OPTION_NAME_LENGTH = {
  [ETSY]: 255,
  [SHOPIFY]: 255,
};

export const MAXIMUM_PROFILE_NAME_LENGTH = {
  [ETSY]: 140,
  [SHOPIFY]: 255,
  [VELA]: 255,
};

export const ERRORS = {
  NAME: {
    EMPTY: 'Fill in name',
    NON_UNIQUE: 'Name must be unique',
    LENGTH_EXCEEDED: {
      [ETSY]: `Profile name should not exceed ${MAXIMUM_PROFILE_NAME_LENGTH[ETSY]} characters`,
      [SHOPIFY]: `Profile name should not exceed ${MAXIMUM_PROFILE_NAME_LENGTH[SHOPIFY]} characters`,
      [VELA]: `Profile name should not exceed ${MAXIMUM_PROFILE_NAME_LENGTH[VELA]} characters`,
    },
    ALPHANUMERIC_BEGIN: 'Profile name must begin with alphanumerical character',
    FORBIDDEN_CHARACTERS: 'Profile name can contain only alphanumeric and -+=/*%:& characters',
    CAPITALIZED_WORDS_NUMBER_EXCEEDED: 'Profile name can contain at most three words with all capitalized letters',
    SPECIAL_CHARACTERS_NUMBER_EXCEEDED: 'Profile name can contain characters % : & at most once',
  },
  NO_OPTIONS: 'Add at least one option',
  VARIATION_NAME_REQUIRED: 'Fill in variation name',
  SAME_NAME_VARIATION: 'You may not have two variations with same name',
  SAME_NAME_OPTION: 'You may not have two options with same name',
  FORBIDDEN_CHARACTERS: {
    [ETSY]: 'You may not include any of these characters: ^ $ `',
  },
  FORBIDDEN_CHARACTERS_SEQUENCE: {
    [SHOPIFY]: 'You may not include the character sequence " / "',
  },
  OPTIONS_NUMBER_EXCEEDED: {
    [ETSY]: `Should not exceed ${MAXIMUM_NUMBER_OF_OPTIONS[ETSY]} options`,
    [SHOPIFY]: `Should not exceed ${MAXIMUM_NUMBER_OF_OPTIONS[SHOPIFY]} options`,
  },
  OFFERINGS_NUMBER_EXCEEDED: {
    [ETSY]: `Should not exceed ${MAXIMUM_NUMBER_OF_OFFERINGS[ETSY]} options combinations`,
    [SHOPIFY]: `Should not exceed ${MAXIMUM_NUMBER_OF_OFFERINGS[SHOPIFY]} options combinations`,
  },
  VARIATION_NAME_LENGTH_EXCEEDED: {
    [ETSY]: `Variation name should not exceed ${MAXIMUM_VARIATION_NAME_LENGTH[ETSY]} characters`,
    [SHOPIFY]: `Variation name should not exceed ${MAXIMUM_VARIATION_NAME_LENGTH[SHOPIFY]} characters`,
  },
  OPTION_NAME_LENGTH_EXCEEDED: {
    [ETSY]: `Option name should not exceed ${MAXIMUM_OPTION_NAME_LENGTH[ETSY]} characters`,
    [SHOPIFY]: `Option name should not exceed ${MAXIMUM_OPTION_NAME_LENGTH[SHOPIFY]} characters`,
  },
  NO_VISIBLE: 'At least one offering must be visible',
  NO_OPTION: 'Fill in option',
  SCALE: 'Choose scale',
};

export const SAVE_TYPE = {
  UPDATE_PROFILE: 'Update current profile',
  SAVE_AS_NEW: 'Save as new profile',
};

export const SAVE_OPTIONS = [
  SAVE_TYPE.UPDATE_PROFILE,
  SAVE_TYPE.SAVE_AS_NEW,
];

export const SALE_PROFILE_TITLE_OPERATION = {
  ADD_BEFORE: 'title.addBefore',
  ADD_AFTER: 'title.addAfter',
};

export const SALE_PROFILE_DESCRIPTION_OPERATION = {
  [ETSY]: {
    ADD_BEFORE: 'description.addBefore',
    ADD_AFTER: 'description.addAfter',
  },
  [SHOPIFY]: {
    ADD_BEFORE: 'body_html.addBefore',
    ADD_AFTER: 'body_html.addAfter',
  },
};

export const SALE_PROFILE_TAGS_OPERATION = {
  ADD: 'tags.add',
  REPLACE: 'tags.changeTo',
};

export const SALE_PROFILE_FILTER = {
  PENDING: 'pending',
  ACTIVE: 'active',
  COMPLETED: 'completed',
};

export const PLACEHOLDERS = {
  [ATTRIBUTES.BARCODE]: 'Barcode',
  [ATTRIBUTES.CAP]: 'CAP',
  [ATTRIBUTES.CHARGE_TAX]: 'Charge tax',
  [ATTRIBUTES.CONTINUE_SELLING]: 'Cont selling',
  [ATTRIBUTES.COUNTRY_CODE]: 'Country of origin',
  [ATTRIBUTES.CPI]: 'CPI',
  [ATTRIBUTES.HS_CODE]: 'HS Code',
  [ATTRIBUTES.PHYSICAL]: 'Physical',
  [ATTRIBUTES.PRICE]: 'Price',
  [ATTRIBUTES.QUANTITY]: 'Quantity',
  [ATTRIBUTES.SKU]: 'SKU',
  [ATTRIBUTES.TRACK_QUANTITY]: 'Track inv',
  [ATTRIBUTES.WEIGHT]: 'Weight',
  OPTION: 'Add option',
  SCALE: 'Choose Scale',
  SHOPS: 'Select shops',
  PROFILE_NAME: 'Enter Profile name',
  VARIATION: {
    [ETSY]: 'Choose Variation type',
    [SHOPIFY]: 'Add Variation',
  },
};

export const VALUES_TO_RETAIN = {
  [SHOPIFY]: [
    ATTRIBUTES.BARCODE,
    ATTRIBUTES.CAP,
    ATTRIBUTES.CHARGE_TAX,
    ATTRIBUTES.CONTINUE_SELLING,
    ATTRIBUTES.COUNTRY_CODE,
    ATTRIBUTES.CPI,
    'imageHash',
    'imageId',
    ATTRIBUTES.HS_CODE,
    ATTRIBUTES.PHYSICAL,
    ATTRIBUTES.PRICE,
    ATTRIBUTES.QUANTITY,
    ATTRIBUTES.TRACK_QUANTITY,
    ATTRIBUTES.SKU,
    ATTRIBUTES.UNIT,
    ATTRIBUTES.WEIGHT,
  ],
};

export const NEW_PROFILE_ERRORS = {
  [PROFILE.SALES]: Map({ [ATTRIBUTES.TAGS]: DEFAULTS.FALSE, [ATTRIBUTES.PRICE]: DEFAULTS.TRUE }),
  [PROFILE.TAGS]: Map({ [ATTRIBUTES.TAGS]: DEFAULTS.FALSE }),
  [PROFILE.VARIATIONS]: Map({ [ATTRIBUTES.VARIATIONS]: DEFAULTS.EMPTY_LIST }),
};

export const TAB_VALUES = {
  [SHOPIFY]: {
    [ATTRIBUTES.BARCODE]: [ATTRIBUTES.BARCODE],
    [ATTRIBUTES.PRICE]: [ATTRIBUTES.PRICE, ATTRIBUTES.CAP, ATTRIBUTES.CPI],
    [ATTRIBUTES.QUANTITY]: [ATTRIBUTES.TRACK_QUANTITY, ATTRIBUTES.QUANTITY],
    [ATTRIBUTES.SKU]: [ATTRIBUTES.SKU],
    [ATTRIBUTES.SHIPPING]: [ATTRIBUTES.PHYSICAL, ATTRIBUTES.WEIGHT, ATTRIBUTES.COUNTRY_CODE, ATTRIBUTES.HS_CODE],
  },
  [VELA]: {
    [ATTRIBUTES.PRICE]: [ATTRIBUTES.PRICE],
    [ATTRIBUTES.QUANTITY]: [ATTRIBUTES.QUANTITY],
    [ATTRIBUTES.SKU]: [ATTRIBUTES.SKU],
  },
};

export const BULK_OPERATION = {
  ADD_AFTER: 'addAfter',
  ADD_BEFORE: 'addBefore',
  CHANGE_TO: 'changeTo',
  DECREASE_BY: 'decreaseBy',
  DELETE: 'delete',
  INCREASE_BY: 'increaseBy',
  SET: 'set',
};

export const BULK_OPERATION_NAME = {
  [BULK_OPERATION.ADD_AFTER]: 'Add after',
  [BULK_OPERATION.ADD_BEFORE]: 'Add before',
  [BULK_OPERATION.CHANGE_TO]: 'Change to',
  [BULK_OPERATION.DECREASE_BY]: 'Decrease by',
  [BULK_OPERATION.DELETE]: 'Delete',
  [BULK_OPERATION.INCREASE_BY]: 'Increase by',
  [BULK_OPERATION.SET]: 'Change to',
};

export const BULK_OPERATIONS = {
  [ETSY]: {
    [ATTRIBUTES.PRICE]: [
      BULK_OPERATION.INCREASE_BY,
      BULK_OPERATION.DECREASE_BY,
      BULK_OPERATION.CHANGE_TO,
    ],
    [ATTRIBUTES.QUANTITY]: [
      BULK_OPERATION.INCREASE_BY,
      BULK_OPERATION.DECREASE_BY,
      BULK_OPERATION.CHANGE_TO,
    ],
    [ATTRIBUTES.SKU]: [
      BULK_OPERATION.ADD_BEFORE,
      BULK_OPERATION.ADD_AFTER,
      BULK_OPERATION.DELETE,
      BULK_OPERATION.SET,
    ],
  },
  [SHOPIFY]: {
    [ATTRIBUTES.BARCODE]: [
      BULK_OPERATION.ADD_BEFORE,
      BULK_OPERATION.ADD_AFTER,
      BULK_OPERATION.DELETE,
      BULK_OPERATION.SET,
    ],
    [ATTRIBUTES.PRICE]: [
      BULK_OPERATION.INCREASE_BY,
      BULK_OPERATION.DECREASE_BY,
      BULK_OPERATION.CHANGE_TO,
    ],
    [ATTRIBUTES.QUANTITY]: [
      BULK_OPERATION.INCREASE_BY,
      BULK_OPERATION.DECREASE_BY,
      BULK_OPERATION.CHANGE_TO,
    ],
    [ATTRIBUTES.SKU]: [
      BULK_OPERATION.ADD_BEFORE,
      BULK_OPERATION.ADD_AFTER,
      BULK_OPERATION.DELETE,
      BULK_OPERATION.SET,
    ],
  },
};

