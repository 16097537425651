import amplitude from 'amplitude-js';

const API_KEY = process.env.AMPLITUDE_API_KEY;
const instance = amplitude.getInstance();

export default {
  init() {
    if (!API_KEY) return;

    this.initialized = true;
    instance.init(API_KEY, null, { batchEvents: true });
  },
  setUserId(userId) {
    if (!this.initialized) return;

    instance.setUserId(userId);
  },
  logEvent(event) {
    if (!this.initialized) return;

    if (typeof event === 'string') {
      instance.logEvent(event);
    } else if (event?.type) {
      instance.logEvent(event.type, event.options);
    }
  },
};
