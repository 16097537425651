import { List, Map } from 'immutable';

import { makeOperation, makeOperationValue } from '../inventory';
import { shapeVariationsProfileForAPI } from '../profiles/shapeForAPI';
import { getAttributesForCategory } from '../product/taxonomy';
import { getIndividualVariations } from '../variations/getIndividualVariations';
import { reduceErrors } from '../errors';
import { getSize } from '../iterable/getSize';
import {
  setCategoryErrors,
  setTaxonomyAttributesErrors,
  updateProductVariationsErrors,
} from '../product/errors';

import { CATEGORY, PRICE, QUANTITY, SKU, TAXONOMY_ATTRIBUTES, VARIATIONS } from '../../constants/attributes';
import { EMPTY_VARIATIONS, ERROR, OPERATIONS, SECTIONS, VALUE } from '../../constants/product';
import { INDIVIDUAL, INDIVIDUAL_FLAGS } from '../../constants/profiles';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { MAX_QUANTITY } from '../../constants/validations';
import { DEFAULTS } from '../../constants';
import { MODALS } from '../../constants/modal';

const { DETAILS, INVENTORY } = SECTIONS;

export function editVariations({ channel, commonChange, product: source, value }) {
  let product = source;
  const operations = [];
  const productId = product.get('productId');
  let operation = Map({ type: OPERATIONS[channel].VARIATIONS });
  let confirmation;

  function removeVariationsErrors(errors = Map()) {
    function filterVariationsErrors(_, key) {
      return !/^variations/.test(key);
    }

    return errors.filter(filterVariationsErrors);
  }

  function findVisible(offering) {
    return offering.get('visibility');
  }

  function sumQuantity(result, offering, offeringIndex, offerings) {
    const maximum = MAX_QUANTITY[channel] - 1;

    if (result >= maximum) return maximum;

    if (!offering.get('visibility')) {
      return result
        ? result
        : offeringIndex === getSize(offerings) - 1
          ? Math.min(parseInt(offerings.getIn([0, QUANTITY]), 10) || 0, maximum) || 1
          : result;
    }

    return Math.min((parseInt(offering.get(QUANTITY), 10) || 0) + result, maximum);
  }

  if (!getSize(value.get(VARIATIONS)) && getSize(product.getIn([VARIATIONS, VARIATIONS]))) {
    // last variation was deleted
    if (product.has('errors')) {
      product = product.update('errors', removeVariationsErrors);
    }

    // make inventory operations
    switch (channel) {
      case ETSY: {
        const variations = product.getIn([VARIATIONS, VARIATIONS]);
        const offerings = product.getIn([VARIATIONS, 'offerings']);
        const individual = getIndividualVariations({
          properties: INDIVIDUAL_FLAGS[channel],
          variations,
        });

        const price = getSize(individual[INDIVIDUAL.PRICE])
          ? getSize(offerings)
            ? (offerings.find(findVisible) || offerings.last()).get(PRICE)
            : product.getIn([INVENTORY, PRICE, VALUE])
          : product.getIn([INVENTORY, PRICE, VALUE]);

        const quantity = getSize(individual[INDIVIDUAL.QUANTITY])
          ? getSize(offerings)
            ? String(offerings.reduce(sumQuantity, 0))
            : product.getIn([INVENTORY, QUANTITY, VALUE])
          : product.getIn([INVENTORY, QUANTITY, VALUE]);

        const sku = product.getIn([INVENTORY, SKU, VALUE]);

        product = updateProductVariationsErrors({
          channel,
          product: product
            .setIn([INVENTORY, PRICE, VALUE], price || DEFAULTS.ZERO_CENT)
            .setIn([INVENTORY, QUANTITY, VALUE], quantity || DEFAULTS.ZERO)
            .setIn([INVENTORY, SKU, VALUE], sku || DEFAULTS.EMPTY_STRING)
            .set(VARIATIONS, value),
        });

        if (!product.getIn([INVENTORY, PRICE, ERROR])) {
          const priceOperationValue = makeOperationValue({
            channel,
            overset: false,
            product,
            type: PRICE,
          });

          if (priceOperationValue) {
            const priceOperation = makeOperation({
              channel,
              type: PRICE,
              value: List([priceOperationValue]),
            });

            if (priceOperation) {
              operations.push(priceOperation);
            }
          }
        }

        if (!product.getIn([INVENTORY, QUANTITY, ERROR])) {
          const quantityOperationValue = makeOperationValue({
            channel,
            overset: false,
            product,
            type: QUANTITY,
          });

          if (quantityOperationValue) {
            const quantityOperation = makeOperation({
              channel,
              type: QUANTITY,
              value: List([quantityOperationValue]),
            });

            if (quantityOperation) {
              operations.push(quantityOperation);
            }
          }
        }

        if (!product.getIn([INVENTORY, SKU, ERROR])) {
          const SKUoperationValue = makeOperationValue({
            channel,
            overset: false,
            product,
            type: SKU,
          });

          if (SKUoperationValue) {
            const SKUoperation = makeOperation({
              channel,
              type: SKU,
              value: List([SKUoperationValue]),
            });

            if (SKUoperation) {
              operations.push(SKUoperation);
            }
          }
        }

        break;
      }

      default: {
        product = updateProductVariationsErrors({
          channel,
          product: product.set(VARIATIONS, value),
        });
        break;
      }
    }

    // make variations operation
    switch (channel) {
      case SHOPIFY: {
        operation = operation.set(VALUE, EMPTY_VARIATIONS[channel]);
        break;
      }

      default: {
        const operationData = shapeVariationsProfileForAPI({
          profile: value,
          productId,
          toImmutable: true,
        });

        operation = operation.set(VALUE, operationData.get(VALUE));
        break;
      }
    }

    operations.unshift(operation);
  } else {
    switch (channel) {
      case ETSY: {
        if (value.get(CATEGORY) !== product.getIn([DETAILS, CATEGORY, VALUE])) {
          product = setCategoryErrors({
            channel: ETSY,
            product: product.setIn([DETAILS, CATEGORY, VALUE], value.get(CATEGORY)),
          });

          const { changed, taxonomyAttributes } = getAttributesForCategory({
            category: value.get(CATEGORY),
            taxonomyAttributes: product.getIn([DETAILS, TAXONOMY_ATTRIBUTES], Map()),
          });

          product = setTaxonomyAttributesErrors({
            channel,
            product: product.setIn([DETAILS, TAXONOMY_ATTRIBUTES], taxonomyAttributes),
          });

          if (changed) {
            confirmation = MODALS.CONFIRMATIONS.CHANGE_CATEGORY;
          }
        }

        if (getSize(product.getIn([VARIATIONS, VARIATIONS]))) {
          const properties = INDIVIDUAL_FLAGS[channel];
          const nextIndividual = getIndividualVariations({
            properties,
            variations: value.get(VARIATIONS),
          });

          const currentIndividual = getIndividualVariations({
            properties,
            variations: product.getIn([VARIATIONS, VARIATIONS]),
          });

          if (!getSize(nextIndividual[INDIVIDUAL.PRICE]) && getSize(currentIndividual[INDIVIDUAL.PRICE])) {
            const price = value.getIn(['offerings', 0, PRICE]);
            product = product.setIn([INVENTORY, PRICE, VALUE], price);
          }

          if (!getSize(nextIndividual[INDIVIDUAL.QUANTITY]) && getSize(currentIndividual[INDIVIDUAL.QUANTITY])) {
            const quantity = value.getIn(['offerings', 0, QUANTITY]);
            product = product.setIn([INVENTORY, QUANTITY, VALUE], quantity);
          }

          if (!getSize(nextIndividual[INDIVIDUAL.SKU]) && getSize(currentIndividual[INDIVIDUAL.SKU])) {
            const sku = value.getIn(['offerings', 0, SKU]);
            product = product.setIn([INVENTORY, SKU, VALUE], sku);
          }
        }

        if (commonChange) {
          const commonValue = value.getIn(['offerings', 0, commonChange]);
          product = product.setIn([INVENTORY, commonChange, VALUE], commonValue);
        }

        break;
      }

      default: {
        break;
      }
    }

    product = updateProductVariationsErrors({ channel, product: product.set(VARIATIONS, value) });

    if (!reduceErrors(product.getIn([VARIATIONS, 'errors']))) {
      const operationData = shapeVariationsProfileForAPI({ profile: value, productId, toImmutable: true });
      operation = operation.set(VALUE, operationData.get(VALUE));

      if (operationData.has('templateId')) {
        operation = operation.set('templateId', operationData.get('templateId'));
      }

      if (operationData.has('shopifyMeta')) {
        operation = operation.set('shopifyMeta', operationData.get('shopifyMeta'));
      }

      operations.unshift(operation);
    }
  }

  return { confirmation, product, operations };
}
