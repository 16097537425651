import request from './request';

import { ENDPOINT } from '../../constants/api';

export default {
  getFilters({ channel, db, filters, shopId, signal }) {
    return request.get({
      endpoint: ENDPOINT.WEB_V2,
      params: { ...filters, db },
      signal,
      url: `/${channel}/shops/${shopId}/listings/filters`,
    });
  },
  getListings({ channel, db, filters, shopId, signal }) {
    return request.get({
      endpoint: ENDPOINT.WEB_V2,
      params: { ...filters, db },
      signal,
      url: `/${channel}/shops/${shopId}/listings`,
    });
  },
  getListingIds({ channel, db, payload, shopId, signal }) {
    return request.post({
      endpoint: ENDPOINT.WEB_V2,
      params: { db },
      payload,
      signal,
      url: `/${channel}/shops/${shopId}/listings/ids`,
    });
  },
  getStatuses({ channel, db, shopId, signal }) {
    return request.get({
      endpoint: ENDPOINT.WEB_V2,
      params: { db },
      signal,
      url: `/${channel}/shops/${shopId}/listings/status/count`,
    });
  },
  getTaxonomyIds({ channel, db, payload, shopId, signal }) {
    return request.post({
      endpoint: ENDPOINT.WEB_V2,
      params: { db },
      payload,
      signal,
      url: `/${channel}/shops/${shopId}/listings/taxonomy_ids`,
    });
  },
};
