import { createStore, applyMiddleware, compose } from 'redux';
import { createEffectCapableStore } from 'redux-side-effects';

import masterReducer from './reducers/masterReducer';
import initialState from './initialState';
import { libraryMiddleware } from './utils/studio/library/libraryMiddleware';
import { photoEditorMiddleware } from './utils/studio/editor/editorMiddleware';

function createStoreWithDevtools(reducer, preloadedState) {
  const composeWithDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  return createStore(reducer, preloadedState, composeWithDevTools(applyMiddleware(libraryMiddleware, photoEditorMiddleware)));
}

function createStoreWithMiddleware(reducer, preloadedState) {
  return createStore(reducer, preloadedState, applyMiddleware(libraryMiddleware, photoEditorMiddleware));
}

const createStoreFunc = process.env.NODE_ENV === 'development'
  ? createStoreWithDevtools
  : createStoreWithMiddleware;

const storeFactory = createEffectCapableStore(createStoreFunc);

export const store = storeFactory(masterReducer, initialState);
