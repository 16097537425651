import { Map } from 'immutable';

import { CHANNEL_BY_ID } from '../../constants/channels';

export function shapeLinkedProductForAPI(product, shopsById) {
  if (!Map.isMap(product) || !Map.isMap(shopsById)) return product;

  const shopId = product.get('shopId');
  const db = shopsById.getIn([shopId, 'db']);
  const channelId = product.get('channelId');
  const productId = product.get('productId');
  const channelProductId = product.get('channelProductId');

  return {
    db,
    shop_id: parseInt(shopId, 10),
    channel_id: parseInt(channelId, 10),
    product_id: parseInt(productId, 10),
    channel_product_id: channelProductId,
  };
}

export function mapLinkedProducts(linkId) {
  return function mapProducts(product) {
    return Map({
      channel: CHANNEL_BY_ID[product.channel_id],
      channelId: String(product.channel_id),
      channelProductId: String(product.listing_id || product.product_id),
      linkId,
      productId: String(product.id),
      shopId: String(product.shop_id),
      thumbnailUrl: product.thumbnail_url,
      title: product.title,
    });
  };
}
