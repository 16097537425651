/* eslint-disable no-console */
import { sideEffect } from 'redux-side-effects';

import amplitude from '../utils/tracking/amplitude';
import mixpanel from '../utils/tracking/mixpanel';
import api from '../utils/api';

import { RESET_PASSWORD_STATUS } from '../constants/auth';
import { ENDPOINT } from '../constants/api';
import { MESSAGE } from '../constants/notifications';
import ACTIONS from '../constants/actions';

import Reducers, { Reducer } from '../classes/Reducer';
import Actions from '../actions';

function* commitPasswordReset(reduction, formData) {
  const state = reduction.setIn(['auth', 'loading'], true);

  yield sideEffect((dispatch) => {
    function onSuccess(response) {
      response.result === 'success'
        ? dispatch(Actions.Auth.commitPasswordResetSucceeded())
        : dispatch(Actions.Auth.commitPasswordResetFailed(response.error));
    }

    function onFail(error) {
      dispatch(Actions.Auth.commitPasswordResetFailed(error));
    }

    api
      .post({ endpoint: ENDPOINT.AUTH, url: '/resetPassword/setPassword', payload: formData })
      .then(onSuccess, onFail);
  });

  return state.hasIn(['auth', 'resetPasswordStatus'])
    ? state
    : state.setIn(['auth', 'resetPasswordStatus'], RESET_PASSWORD_STATUS.COMMIT_REQUESTED);
}

function* commitPasswordResetFailed(reduction, error) {
  console.error(error);

  return reduction
    .setIn(['auth', 'resetPasswordStatus'], RESET_PASSWORD_STATUS.COMMIT_REJECTED)
    .deleteIn(['auth', 'loading']);
}

function* commitPasswordResetSucceeded(reduction) {
  return reduction
    .setIn(['auth', 'resetPasswordStatus'], RESET_PASSWORD_STATUS.COMMIT_ACCEPTED)
    .deleteIn(['auth', 'loading']);
}

function* createAccount(reduction, request) {
  yield sideEffect((dispatch) => {
    function onSuccess(response) {
      response.result === 'success'
        ? dispatch(Actions.Auth.createAccountSucceeded(request.payload))
        : dispatch(Actions.Auth.signInFailed(response.data));
    }

    function onFail(error) {
      dispatch(Actions.Auth.signInFailed(error));
    }

    api
      .post({ endpoint: ENDPOINT.AUTH, url: '/createAccount', ...request })
      .then(onSuccess, onFail);
  });

  return reduction.setIn(['auth', 'loading'], true);
}

function* createAccountSucceeded(reduction, payload) {
  yield sideEffect((dispatch) => {
    amplitude.setUserId(payload.email);
    mixpanel.setDistinctId(payload.email);
    amplitude.logEvent('Created an account');
    dispatch(Actions.User.getUserData());
    dispatch(Actions.Auth.setAffiliate());
  });

  return reduction;
}

function* removeError(reduction) {
  return reduction.deleteIn(['auth', 'error']);
}

function* requestPasswordReset(reduction, formData) {
  const state = reduction.setIn(['auth', 'loading'], true);

  yield sideEffect((dispatch) => {
    function onSuccess(response) {
      response.result === 'success'
        ? dispatch(Actions.Auth.requestPasswordResetSucceeded())
        : dispatch(Actions.Auth.requestPasswordResetFailed(response.error));
    }

    function onFail(error) {
      dispatch(Actions.Auth.requestPasswordResetFailed(error));
    }

    api
      .post({ endpoint: ENDPOINT.AUTH, url: '/resetPassword/request', payload: formData })
      .then(onSuccess, onFail);
  });

  return state.hasIn(['auth', 'resetPasswordStatus'])
    ? state
    : state.setIn(['auth', 'resetPasswordStatus'], RESET_PASSWORD_STATUS.QUERY_REQUESTED);
}

function* requestPasswordResetFailed(reduction, error) {
  console.error(error);

  return reduction
    .setIn(['auth', 'resetPasswordStatus'], RESET_PASSWORD_STATUS.QUERY_REJECTED)
    .deleteIn(['auth', 'loading']);
}

function* requestPasswordResetSucceeded(reduction) {
  return reduction
    .setIn(['auth', 'resetPasswordStatus'], RESET_PASSWORD_STATUS.QUERY_ACCEPTED)
    .deleteIn(['auth', 'loading']);
}

function* resetPasswordStatus(reduction, status) {
  return status
    ? reduction.setIn(['auth', 'resetPasswordStatus'], status)
    : reduction.deleteIn(['auth', 'resetPasswordStatus']);
}

function* setAffiliate(reduction, value) {
  return value
    ? reduction.setIn(['auth', 'affiliate'], value)
    : reduction.deleteIn(['auth', 'affiliate']);
}

function* setLoading(reduction, value) {
  const state = reduction.deleteIn(['auth', 'error']);

  return value
    ? state.setIn(['auth', 'loading'], value)
    : state.deleteIn(['auth', 'loading']);
}

function* setLoggedIn(reduction, value) {
  return reduction.setIn(['auth', 'loggedIn'], value);
}

function* signIn(reduction, formData) {
  yield sideEffect((dispatch) => {
    const { email: username, password } = formData;

    function onSuccess(response) {
      if (response.result !== 'success') {
        dispatch(Actions.Auth.signInFailed(response.data));
        return;
      }

      dispatch(Actions.User.getUserData());
      dispatch(Actions.Auth.setAffiliate());
    }

    function onFail(error) {
      dispatch(Actions.Auth.signInFailed(error));
    }

    api
      .post({ endpoint: ENDPOINT.AUTH, url: '/login', payload: { username, password }})
      .then(onSuccess, onFail);
  });

  return reduction.setIn(['auth', 'loading'], true);
}

function* signInFailed(reduction, error) {
  if (typeof error !== 'string') {
    console.error(error);
  }

  return reduction
    .deleteIn(['auth', 'loading'])
    .setIn(['auth', 'error'],
      typeof error === 'string'
        ? error || MESSAGE.FAIL.UNKNOWN
        : MESSAGE.FAIL.UNKNOWN
    );
}

Reducers.add(
  new Reducer('Auth')
    .add(ACTIONS.AUTH.COMMIT_PASSWORD_RESET, commitPasswordReset)
    .add(ACTIONS.AUTH.COMMIT_PASSWORD_RESET_FAILED, commitPasswordResetFailed)
    .add(ACTIONS.AUTH.COMMIT_PASSWORD_RESET_SUCCEEDED, commitPasswordResetSucceeded)
    .add(ACTIONS.AUTH.CREATE_ACCOUNT, createAccount)
    .add(ACTIONS.AUTH.CREATE_ACCOUNT_SUCCEEDED, createAccountSucceeded)
    .add(ACTIONS.AUTH.REMOVE_ERROR, removeError)
    .add(ACTIONS.AUTH.REQUEST_PASSWORD_RESET, requestPasswordReset)
    .add(ACTIONS.AUTH.REQUEST_PASSWORD_RESET_FAILED, requestPasswordResetFailed)
    .add(ACTIONS.AUTH.REQUEST_PASSWORD_RESET_SUCCEEDED, requestPasswordResetSucceeded)
    .add(ACTIONS.AUTH.RESET_PASSWORD_STATUS, resetPasswordStatus)
    .add(ACTIONS.AUTH.SET_AFFILIATE, setAffiliate)
    .add(ACTIONS.AUTH.SET_LOADING, setLoading)
    .add(ACTIONS.AUTH.SET_LOGGED_IN, setLoggedIn)
    .add(ACTIONS.AUTH.SIGN_IN, signIn)
    .add(ACTIONS.AUTH.SIGN_IN_FAILED, signInFailed)
);
