import { Map, Set } from 'immutable';

import { filterOperations } from './operations';
import { setProductErrors } from './errors';
import { isPublished } from '../listings/statuses';
import { getPlanName } from '../billing';
import { getSize } from '../iterable/getSize';

import { CHANNEL_NAME, ETSY, SHOPIFY } from '../../constants/channels';
import { STATUS, STATUS_NAME } from '../../constants/listings';
import { NEW, OPERATIONS } from '../../constants/product';
import { SYNC_INDICATOR } from '../../constants/shops';
import { DEFAULTS } from '../../constants';
import { MODALS } from '../../constants/modal';
import { EVENT } from '../../constants/tracking';

const { EMPTY_LIST, EMPTY_SET } = DEFAULTS;

const STATUSES = {
  ARCHIVED: Set([STATUS.ARCHIVED, STATUS.DRAFT, STATUS.ACTIVE]),
  COPIED: Set([STATUS.COPIED, STATUS.DRAFT, STATUS.ACTIVE]),
  INACTIVE: Set([STATUS.INACTIVE, STATUS.ACTIVE]),
  NEW: Set([STATUS.DRAFT, STATUS.ACTIVE]),
  UNPUBLISHED: Set([STATUS.UNPUBLISHED, STATUS.DRAFT, STATUS.ACTIVE]),
};

export function shouldAddStatusOperation({ product, status }) {
  const isNew = product.get('productId') === NEW;
  const oldStatus = product.get('status');

  switch (status) {
    case STATUS.ACTIVE: {
      return status !== oldStatus;
    }

    case STATUS.DRAFT: {
      switch (product.get('channel')) {
        case ETSY: {
          return isNew || oldStatus === STATUS.COPIED || oldStatus === STATUS.UNPUBLISHED;
        }

        case SHOPIFY: {
          return status !== oldStatus;
        }

        default: {
          return false;
        }
      }
    }

    case STATUS.COPIED: {
      return status === oldStatus;
    }

    case STATUS.TEMPLATE:
    case STATUS.UNPUBLISHED: {
      return isNew;
    }

    default: {
      return false;
    }
  }
}

export function getIndicator(status) {
  return isPublished(status)
    ? SYNC_INDICATOR.PUBLISH
    : SYNC_INDICATOR.SAVE;
}

export function getStatuses(product) {
  let all = EMPTY_SET;
  let enabled = EMPTY_SET;

  function isComplete() {
    return product.get('isComplete');
  }

  if (product.get('status') === STATUS.COPIED) {
    all = STATUSES.COPIED;

    if (isComplete()) {
      enabled = all;
    }

    return { all, enabled };
  } else if (product.get('productId') === NEW) {
    all = STATUSES.NEW;
    enabled = all;
    return { all, enabled };
  }

  switch (product.get('channel')) {
    case ETSY: {
      switch (product.get('status')) {
        case STATUS.ACTIVE: {
          all = STATUSES.NEW;
          break;
        }

        case STATUS.DRAFT: {
          all = STATUSES.NEW;
          enabled = all;
          break;
        }

        case STATUS.INACTIVE: {
          all = STATUSES.INACTIVE;
          enabled = all;
          break;
        }

        case STATUS.UNPUBLISHED: {
          all = STATUSES.UNPUBLISHED;

          if (isComplete()) {
            enabled = all;
          }

          break;
        }

        default: {
          break;
        }
      }

      break;
    }

    case SHOPIFY: {
      switch (product.get('status')) {
        case STATUS.ACTIVE: {
          all = STATUSES.NEW;
          enabled = all;
          break;
        }

        case STATUS.ARCHIVED: {
          all = STATUSES.ARCHIVED;
          enabled = all;
          break;
        }

        case STATUS.DRAFT: {
          all = STATUSES.NEW;
          enabled = all;
          break;
        }

        case STATUS.UNPUBLISHED: {
          all = STATUSES.UNPUBLISHED;

          if (isComplete()) {
            enabled = all;
          }

          break;
        }

        default: {
          break;
        }
      }

      break;
    }

    default: {
      break;
    }
  }

  return { all, enabled };
}

export function canChangeStatuses({ data, state, statuses }) {
  function validateProduct(validated, product, shopId) {
    if (!getSize(product.get('errors'))) {
      const validatedProduct = setProductErrors({ product });

      if (!validatedProduct.get('isComplete')) {
        validated.hasErrors = true;
        validated.data = validated.data.setIn(['products', shopId], validatedProduct);
      }
    } else {
      validated.hasErrors = true;
    }

    return validated;
  }

  function canChangeProductStatus({ product, shopId, status, updatedData }) {
    const result = { canChange: true };
    const channel = product.get('channel');
    const oldStatus = product.get('status');
    const productId = product.get('productId');

    if (isPublished(status)) {
      const validated = validateProduct({ data: updatedData }, product, shopId);

      if (validated.hasErrors) {
        result.canChange = false;
        result.updatedData = validated.data.set('scrollToErrors', true);
        return result;
      }
    }

    if (
      status === STATUS.ACTIVE &&
      updatedData.getIn(['modal', 'type']) !== MODALS.CONFIRMATIONS.PUBLISH &&
      channel === ETSY && (
        productId === NEW ||
        oldStatus !== STATUS.ACTIVE
      )
    ) {
      result.canChange = false;
      result.modal = { type: MODALS.CONFIRMATIONS.PUBLISH };

      return result;
    }

    if (oldStatus === STATUS.COPIED && status !== STATUS.COPIED) {
      result.event = {
        channel: CHANNEL_NAME[channel],
        event: EVENT.LISTING.PUBLISH_COPY,
        plan: getPlanName(state.getIn(['user', 'subscriptions', shopId])),
        shop_id: shopId,
        to_status: STATUS_NAME[status],
      };
    }

    function addStatusOperation(operations = EMPTY_LIST) {
      return operations.push(Map({ type: OPERATIONS[channel].STATUS, value: status }));
    }

    if (shouldAddStatusOperation({ product, status })) {
      result.indicator = getIndicator(status);
      result.updatedData = updatedData.updateIn(['products', shopId, 'operations'], addStatusOperation);
    } else if (getSize(product.get('operations'))) {
      result.indicator = getIndicator(status);
    }

    return result;
  }

  function reduceProducts(result, product, shopId) {
    const { canChange, event, indicator, modal, updatedData } = canChangeProductStatus({
      product,
      shopId,
      status: statuses.get(shopId) || product.get('status'),
      updatedData: result.data || data,
    });

    if (!canChange) {
      result.canChange = false;

      if (modal && !result.modal) {
        result.modal = modal;
      }
    }

    if (updatedData) {
      result.data = updatedData;
    }

    if (indicator) {
      result.indicators.push({ indicator, shopId });
    }

    if (event) {
      result.events.push(event);
    }

    return result;
  }

  return data.get('products').reduce(reduceProducts, { events: [], canChange: true, indicators: [] });
}

export function setStatusByShopId(status) {
  return function reduceShopIds(statuses, shopId) {
    return statuses.set(shopId, status);
  };
}

export function filterStatusOperations(product) {
  function updateOperations(operations = EMPTY_LIST) {
    return operations.filter(filterOperations(OPERATIONS[product.get('channel')].STATUS, false));
  }

  return product.update('operations', updateOperations);
}

export function getVelaStatus({ shops, statuses }) {
  function reduceShopIds(velaStatus, shopId) {
    if (velaStatus === STATUS.TEMPLATE || statuses.get(shopId) === STATUS.TEMPLATE) {
      return STATUS.TEMPLATE;
    }

    if (statuses.get(shopId) === STATUS.UNPUBLISHED) {
      return STATUS.UNPUBLISHED;
    }

    return velaStatus;
  }

  return shops.reduce(reduceShopIds, STATUS.PUBLISHED);
}
