import './reducers';
import Reducer from '../classes/Reducer';

export default function* masterReducer(reduction, action) {
  const { type, payload } = action;
  const path = type.toUpperCase().split('.');

  // reducers are nested by their "namespaces"
  // split type by "." (dot) and get last reducer (function)
  let reducer = Reducer;
  do {
    // get next namespace
    const key = path.shift();
    // is there a nested reducer/namespace
    // if not, there is nothing to do
    if (!reducer[key]) { break; }
    // continue with remaining path
    reducer = reducer[key];
  } while (path.length);

  // we reached the end, is it reducing function?
  if (typeof reducer === 'function') {
    // reduce action
    return yield* reducer(reduction, payload);
  } else {
    return reduction;
  }
}
