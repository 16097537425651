import { getSize } from '../iterable/getSize';

import { DEFAULTS, SEPARATOR } from '../../constants';
import { SHOPIFY } from '../../constants/channels';
import {
  ERRORS,
  MAX_NUMBER_OF_VIDEOS,
  MAX_VIDEO_SIZE,
  VIDEO_DIMENSIONS_MAXIMUM,
  VIDEO_DIMENSIONS_MINIMUM,
  VIDEO_DURATION_MAX,
  VIDEO_DURATION_MINIMUM,
  VIDEO_FILE_FORMATS,
} from '../../constants/validations';

export async function getUploadedVideoError({ channel, file, url }) {
  return new Promise(function executor(resolve, reject) {
    const videoType = (file?.type || DEFAULTS.EMPTY_STRING).split(SEPARATOR.SLASH)[1];

    if (
      !videoType || (
        VIDEO_FILE_FORMATS.hasOwnProperty(channel) &&
        !VIDEO_FILE_FORMATS[channel].has(videoType.toLocaleLowerCase())
      )
    ) {
      reject(ERRORS.VIDEOS.UNSUPPORTED_FORMAT[channel]);
    }

    if (MAX_VIDEO_SIZE.hasOwnProperty(channel) && file.size > MAX_VIDEO_SIZE[channel]) {
      reject(ERRORS.VIDEOS.FILES_TO_BIG[channel]);
    }

    const video = document.createElement('video');

    video.onerror = function videoError() {
      reject(ERRORS.VIDEOS.UNKNOWN);
    };

    video.onloadeddata = function videoLoadedData() {
      const { videoWidth, videoHeight, duration } = video;

      if (VIDEO_DIMENSIONS_MINIMUM.hasOwnProperty(channel)) {
        const minimumVideo = VIDEO_DIMENSIONS_MINIMUM[channel];

        if (videoWidth < minimumVideo || videoHeight < minimumVideo) {
          reject(ERRORS.VIDEOS.TOO_SMALL[channel]);
        }
      }

      if (VIDEO_DIMENSIONS_MAXIMUM.hasOwnProperty(channel)) {
        const maximumVideo = VIDEO_DIMENSIONS_MAXIMUM[channel];

        if (videoWidth > maximumVideo || videoHeight > maximumVideo) {
          reject(ERRORS.VIDEOS.TOO_BIG[channel]);
        }
      }

      if (VIDEO_DURATION_MINIMUM.hasOwnProperty(channel)) {
        const minimumDuration = VIDEO_DURATION_MINIMUM[channel];

        if (duration < minimumDuration) {
          reject(ERRORS.VIDEOS.SMALL_DURATION[channel]);
        }
      }

      if (VIDEO_DURATION_MAX.hasOwnProperty(channel)) {
        const maximumDuration = VIDEO_DURATION_MAX[channel];
        const isValidDuration = channel === SHOPIFY ? duration > maximumDuration
          : duration >= maximumDuration;
        if (isValidDuration) {
          reject(ERRORS.VIDEOS.MAX_DURATION[channel]);
        }
      }

      resolve();
    };

    video.src = url || URL.createObjectURL(file);
  });
}

export function getVideosError({ channel, videos }) {
  switch (channel) {
    default: {
      if (!getSize(videos) > MAX_NUMBER_OF_VIDEOS[channel]) return ERRORS.VIDEOS.MAX_ALLOWED[channel];

      break;
    }
  }

  function reduceVideos(error, video) {
    return error || video.get('error', false);
  }

  return videos.reduce(reduceVideos, false);
}

export function getExternalVideoUrlError(url) {
  try {
    const { host } = new URL(url);

    switch (host) {
      case 'youtu.be':
      case 'youtube.com':
      case 'vimeo.com':
      case 'www.youtube.com': {
        return false;
      }

      default: {
        return ERRORS.VIDEOS.UNSUPPORTED_URL;
      }
    }
  } catch (e) {
    return ERRORS.VIDEOS.INVALID_URL;
  }
}
