import Big from 'big.js';
import XRegExp from 'xregexp';

import { isValidNumber } from '../number';

import { ETSY, SHOPIFY } from '../../constants/channels';
import {
  ERRORS,
  MAX_BARCODE_LENGTH,
  MAX_PRICE,
  MIN_PRICE,
  MAX_QUANTITY,
  MIN_QUANTITY,
  MAX_SKU_LENGTH,
} from '../../constants/validations';

export function getBarcodeError({ channel, barcode }) {
  if (!barcode) return false;

  if (MAX_BARCODE_LENGTH.hasOwnProperty(channel) && barcode.length > MAX_BARCODE_LENGTH[channel]) {
    return ERRORS.BARCODE.LENGTH_EXCEEDED[channel];
  }

  return false;
}

export function getCAPError({ channel, cap }) {
  if (!isValidNumber(cap)) return false;

  const CAPNumber = new Big(cap);

  if (CAPNumber.eq(0)) return false;

  if (MIN_PRICE.hasOwnProperty(channel) && new Big(MIN_PRICE[channel]).gt(CAPNumber)) {
    return ERRORS.CAP.TOO_LOW[channel];
  }

  if (MAX_PRICE.hasOwnProperty(channel) && new Big(MAX_PRICE[channel]).lte(CAPNumber)) {
    return ERRORS.CAP.TOO_HIGH[channel];
  }

  return false;
}

export function getCPIError({ channel, cpi }) {
  if (!isValidNumber(cpi)) return false;

  const CPINumber = new Big(cpi);

  if (MIN_PRICE.hasOwnProperty(channel) && new Big(MIN_PRICE[channel]).gt(CPINumber)) {
    return ERRORS.CPI.TOO_LOW[channel];
  }

  if (MAX_PRICE.hasOwnProperty(channel) && new Big(MAX_PRICE[channel]).lte(CPINumber)) {
    return ERRORS.CPI.TOO_HIGH[channel];
  }

  return false;
}

export function getPriceError({ channel, price }) {
  if (!isValidNumber(price)) {
    return channel !== SHOPIFY && ERRORS.PRICE.REQUIRED;
  }

  const priceNumber = new Big(price);

  if (MIN_PRICE.hasOwnProperty(channel) && new Big(MIN_PRICE[channel]).gt(priceNumber)) {
    return ERRORS.PRICE.TOO_LOW[channel];
  }

  if (MAX_PRICE.hasOwnProperty(channel) && new Big(MAX_PRICE[channel]).lte(priceNumber)) {
    return ERRORS.PRICE.TOO_HIGH[channel];
  }

  return false;
}

export function getQuantityError({ channel, quantity, allowZero = false, allowEmpty = true }) {
  switch (channel) {
    case ETSY: {
      if (!isValidNumber(quantity)) {
        return ERRORS.QUANTITY.REQUIRED;
      }

      if (parseFloat(quantity) === 0) {
        return !allowZero && ERRORS.QUANTITY.IS_ZERO;
      }

      break;
    }

    default: {
      if (!isValidNumber(quantity)) {
        return !allowEmpty && ERRORS.QUANTITY.REQUIRED;
      }

      break;
    }
  }

  const quantityNumber = new Big(quantity);

  if (!quantityNumber.eq(quantityNumber.round(0, 0))) {
    return ERRORS.QUANTITY.INVALID;
  }

  if (MIN_QUANTITY.hasOwnProperty(channel) && new Big(MIN_QUANTITY[channel]).gt(quantityNumber)) {
    return ERRORS.QUANTITY.TOO_LOW[channel];
  }

  if (MAX_QUANTITY.hasOwnProperty(channel) && new Big(MAX_QUANTITY[channel]).lte(quantityNumber)) {
    return ERRORS.QUANTITY.TOO_HIGH[channel];
  }

  return false;
}

export function getSKUError({ channel, sku }) {
  if (!sku) return false;

  if (MAX_SKU_LENGTH.hasOwnProperty(channel) && sku.length > MAX_SKU_LENGTH[channel]) {
    return ERRORS.SKU.LENGTH_EXCEEDED[channel];
  }

  switch (channel) {
    case ETSY: {
      if (/[\^$`]/.test(sku)) {
        return ERRORS.SKU.FORBIDDEN_CHARACTERS[channel];
      } else if (!new XRegExp('^[\\p{L}\\p{Nd}]').test(sku)) {
        return ERRORS.SKU.ALPHANUMERIC_BEGIN;
      } else {
        return false;
      }
    }

    default: {
      return false;
    }
  }
}
