export function find(instance, callback) {
  if (!instance) return undefined;

  if ('find' in instance && typeof instance.find === 'function') {
    return instance.find(callback);
  }

  for (const key in instance) {
    if (instance.hasOwnProperty(key)) {
      if (callback(instance[key], key)) return instance[key];
    }
  }

  return undefined;
}
