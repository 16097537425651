import { Map } from 'immutable';

import { convertBodyHTMLToMetaDescription } from '../../product/convert';
import { escapeRegex } from '../../string';

import { DESCRIPTION, META_DESCRIPTION, SEO } from '../../../constants/attributes';
import { FIND, OPERATIONS, REPLACE } from '../../../constants/bulkEdit';
import { OVERSET, VALUE } from '../../../constants/product';
import { SHOPIFY } from '../../../constants/channels';

export default function seoDescription({ actions, operation, product }) {
  function setValueToProduct(value) {
    const result = {
      actions,
      product: product.setIn([SEO, META_DESCRIPTION, VALUE], value),
    };

    const description = result.product.getIn([DESCRIPTION, VALUE]);
    const plainDescription = description && convertBodyHTMLToMetaDescription(description);

    if (!value) {
      result.product = result.product.setIn([SEO, META_DESCRIPTION],
        Map({ [OVERSET]: true, [VALUE]: plainDescription })
      );
    } else if (value === plainDescription) {
      result.product = result.product.setIn([SEO, META_DESCRIPTION, OVERSET], true);
    } else if (result.product.getIn([SEO, META_DESCRIPTION, OVERSET])) {
      result.product = result.product.deleteIn([SEO, META_DESCRIPTION, OVERSET]);
    }

    return result;
  }

  switch (operation.get('type')) {
    case OPERATIONS[SHOPIFY].META_DESCRIPTION.SET: {
      const value = operation.get(VALUE);
      return setValueToProduct(value);
    }

    case OPERATIONS[SHOPIFY].META_DESCRIPTION.DELETE: {
      const pattern = new RegExp(escapeRegex(operation.get(VALUE)), 'ig');
      const value = product.getIn([SEO, META_DESCRIPTION, VALUE]).replace(pattern, '');
      return setValueToProduct(value);
    }

    case OPERATIONS[SHOPIFY].META_DESCRIPTION.ADD_AFTER: {
      const value = `${product.getIn([SEO, META_DESCRIPTION, VALUE])}${operation.get(VALUE)}`;
      return setValueToProduct(value);
    }

    case OPERATIONS[SHOPIFY].META_DESCRIPTION.ADD_BEFORE: {
      const value = `${operation.get(VALUE)}${product.getIn([SEO, META_DESCRIPTION, VALUE])}`;
      return setValueToProduct(value);
    }

    case OPERATIONS[SHOPIFY].META_DESCRIPTION.FIND_AND_REPLACE: {
      const pattern = new RegExp(escapeRegex(operation.getIn([VALUE, FIND])), 'ig');
      const value = product
        .getIn([SEO, META_DESCRIPTION, VALUE])
        .replace(pattern, operation.getIn([VALUE, REPLACE]));

      return setValueToProduct(value);
    }

    default: {
      return { actions, product };
    }
  }
}
