import { Set } from 'immutable';

export function getIndividualVariations({ properties, variations }) {
  function reduceProperties(result, property) {
    if (!(property in result)) {
      result[property] = Set();
    }

    function reduceVariations(output, variation) {
      if (variation.get(property)) {
        output[property] = output[property].add(variation.get('id'));
      }

      return output;
    }

    return variations.reduce(reduceVariations, result);
  }

  return properties.reduce(reduceProperties, {});
}
