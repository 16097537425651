import { fabric } from 'fabric';
import Big from 'big.js';

import OffscreenCanvas from '../../../classes/Studio/Editor/OffscreenCanvas';

import { createImageElement } from '.';
import { getFromTheme } from '../../theme';
import { discretise } from '../../math';

import { PHOTO_DIMENSIONS_MINIMUM } from '../../../constants/validations';
import { DIMENSION, DIMENSION_SCALE, MIME, NAMES, PAN_OFFSET, ZOOM_STEPS } from '../../../constants/photoEditor';
import { CURSOR, THEME } from '../../../constants/theme';
import { DEFAULTS } from '../../../constants';
import { TWO_PI } from '../../../constants/math';

export function drawImageOnCanvas(image, sx = 0, sy = 0, sw, sh) {
  const height = sh || image.height;
  const width = sw || image.width;
  const canvas = new OffscreenCanvas(width, height);
  const ctx = canvas.getContext('2d');
  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = 'high';
  ctx.drawImage(image, sx, sy, width, height, 0, 0, width, height);
  return canvas;
}

export async function renderChecker({ callback, canvas, theme }) {
  const src = getFromTheme(THEME.ICONS.TRANSPARENCY)(theme).replace(/(^url\(|\)$)/g, DEFAULTS.EMPTY_STRING);
  const image = await createImageElement({ src });
  const { naturalHeight: sh, naturalWidth: sw } = image;
  const { height: dh, width: dw } = canvas.checker;
  const ctx = canvas.checker.getContext('2d');
  ctx.clearRect(0, 0, dw, dh);
  ctx.imageSmoothingEnabled = false;
  ctx.drawImage(image, 0, 0, sw, sh, 0, 0, dw, dh);

  if (typeof callback === 'function') {
    callback();
  }
}

export function renderFreeDrawingCursor({ canvas, clear = true, pointer }) {
  const { contextTop: ctx, freeDrawingBrush, viewportTransform } = canvas;
  const { color, width } = freeDrawingBrush;
  const [a, b, c, d, e, f] = viewportTransform;
  const { x, y } = pointer;
  const r = width / 2;

  if (clear) canvas.clearContext(ctx);

  ctx.save();
  ctx.fillStyle = color;
  ctx.transform(a, b, c, d, e, f);
  ctx.beginPath();
  ctx.ellipse(x, y, r, r, 0, 0, TWO_PI);
  ctx.fill();
  ctx.closePath();
  ctx.restore();
}

export function getZoom({ canvas, target, zoom: { current: previousZoom = 0, initialSteps }}) {
  function getMinZoomByDimension(dimension) {
    const canvasSide = new Big(canvas[dimension]).minus(PAN_OFFSET * 2);
    const targetSide = new Big(target[dimension]).times(target[DIMENSION_SCALE[dimension]]);
    return canvasSide.div(targetSide).toNumber();
  }

  function getZoomSteps({ max, min }) {
    function filterSteps(step) {
      return step > min && step < max;
    }

    const middleSteps = min === 1
      ? ZOOM_STEPS.UP.filter(filterSteps)
      : ZOOM_STEPS.DOWN.filter(filterSteps);

    return [min, ...middleSteps, max];
  }

  const heightMinZoom = getMinZoomByDimension(DIMENSION.HEIGHT);
  const widthMinZoom = getMinZoomByDimension(DIMENSION.WIDTH);
  const nextZoom = discretise(Math.min(heightMinZoom, widthMinZoom), 2);
  const max = Math.max(nextZoom, 1);
  const min = Math.min(nextZoom, 1);
  const steps = getZoomSteps({ max, min });
  const current = previousZoom <= max && previousZoom >= min ? previousZoom : nextZoom;

  return { current, initialSteps, max, min, steps };
}

export function zoomAndPan({ canvas, target, zoom }) {
  const panX = new Big(target.width).times(target.scaleX).times(zoom).minus(canvas.width).minus(PAN_OFFSET).toNumber();
  const panY = new Big(target.height).times(target.scaleY).times(zoom).minus(canvas.height).minus(PAN_OFFSET).toNumber();
  const panStartX = new Big(target.left).neg().times(zoom).minus(PAN_OFFSET).toNumber();
  const panStartY = new Big(target.top).neg().times(zoom).minus(PAN_OFFSET).toNumber();

  if (
    canvas.panX === panX &&
    canvas.panY === panY &&
    canvas.panStartX === panStartX &&
    canvas.panStartY === panStartY &&
    zoom === canvas.getZoom()
  ) {
    return;
  }

  canvas.panX = panX;
  canvas.panY = panY;
  canvas.panStartX = panStartX;
  canvas.panStartY = panStartY;
  canvas.image.hoverCursor = panX > 0 || panY > 0
    ? CURSOR.GRAB
    : CURSOR.DEFAULT;

  const { left: x, top: y } = canvas.getCenter();
  canvas.zoomToPoint({ x, y }, zoom);
  canvas.calcViewportBoundaries();

  function getCoordForPan({ coord, pan, start }) {
    if (pan < 0) {
      const shift = -pan / 2 + start;

      if (coord !== shift) {
        return shift;
      }
    } else if (pan === 0) {
      if (coord !== pan) {
        return pan;
      }
    } else {
      if (coord < start - pan) {
        return start - pan;
      } else if (coord > start) {
        return start;
      }
    }

    return coord;
  }

  canvas.viewportTransform[4] = getCoordForPan({
    coord: canvas.viewportTransform[4],
    pan: panX,
    start: panStartX,
  });

  canvas.viewportTransform[5] = getCoordForPan({
    coord: canvas.viewportTransform[5],
    pan: panY,
    start: panStartY,
  });

  canvas.calcViewportBoundaries(canvas.viewportTransform);
}

function addEventListeners({ actions, dispatch, state: { canvas, crop }}) {
  canvas.image.on('mousedown', function onMouseDown(options) {
    if ((!canvas.isDrawingMode || options.button === 3) && (canvas.panX > 0 || canvas.panY > 0)) {
      if (canvas.isDrawingMode && !canvas._isCurrentlyDrawing) {
        canvas.clearContext(canvas.contextTop);
      }

      canvas.panEventX = options.e.clientX;
      canvas.panEventY = options.e.clientY;
      canvas.panning = true;
      canvas.defaultCursor = CURSOR.GRABBING;
      canvas.image.hoverCursor = CURSOR.GRABBING;
      canvas.setCursor(CURSOR.GRABBING);
    }
  });

  canvas.on('mouse:move', function onMouseMove(options) {
    if (!canvas.panning) {
      if (canvas.isDrawingMode && !canvas._isCurrentlyDrawing) {
        renderFreeDrawingCursor({ canvas, pointer: options.absolutePointer });
      }

      return;
    }

    canvas.setCursor(CURSOR.GRABBING);

    let changed;
    const zoom = canvas.getZoom();
    const { clientX, clientY } = options.e;
    const { panEventX, panEventY, vptCoords: viewportCoords, viewportTransform } = canvas;
    const { height, left, scaleX, scaleY, top, width } = canvas.cropping
      ? canvas.image
      : crop.area;

    function getShift({ delta, max, min }) {
      if (delta > 0) {
        const shift = max * zoom;
        return shift > -PAN_OFFSET
          ? Math.min(delta, shift + PAN_OFFSET)
          : 0;
      } else if (delta < 0) {
        const shift = min * zoom;
        return shift < PAN_OFFSET
          ? Math.max(delta, shift - PAN_OFFSET)
          : 0;
      } else {
        return 0;
      }
    }

    if (canvas.panX > 0) {
      const delta = clientX - panEventX;
      const max = viewportCoords.tl.x - left;
      const min = viewportCoords.tr.x - left - width * scaleX;
      const shift = getShift({ delta, max, min, zoom });

      if (shift !== 0) {
        viewportTransform[4] += shift;
        changed = true;
      }
    }

    if (canvas.panY > 0) {
      const delta = clientY - panEventY;
      const max = viewportCoords.tl.y - top;
      const min = viewportCoords.bl.y - top - height * scaleY;
      const shift = getShift({ delta, max, min, zoom });

      if (shift !== 0) {
        viewportTransform[5] += shift;
        changed = true;
      }
    }

    if (changed) {
      canvas.setViewportTransform(canvas.viewportTransform);
      canvas.requestRenderAll();
    }

    canvas.panEventX = clientX;
    canvas.panEventY = clientY;
  });

  canvas.on('mouse:out', function onMouseOut() {
    if (canvas.isDrawingMode && !canvas._isCurrentlyDrawing) {
      canvas.clearContext(canvas.contextTop);
    }
  });

  canvas.on('mouse:up', function onMouseUp(options) {
    if (canvas.isDrawingMode && !canvas._isCurrentlyDrawing) {
      renderFreeDrawingCursor({ canvas, pointer: options.absolutePointer });
    }

    if (!canvas.panning) return;

    delete canvas.panEventX;
    delete canvas.panEventY;
    delete canvas.panning;
    canvas.defaultCursor = CURSOR.DEFAULT;
    canvas.image.hoverCursor = CURSOR.GRAB;

    const cursor = canvas.isDrawingMode
      ? canvas.freeDrawingCursor
      : canvas.image.containsPoint(options.absolutePointer, null, true)
        ? CURSOR.GRAB
        : canvas.defaultCursor;

    canvas.setCursor(cursor);
  });

  canvas.on('stack:updated', function onStackUpdated() {
    function reduceObjects(result, object) {
      switch (object.name) {
        case NAMES.BANNER:
        case NAMES.SCENERY:
        case NAMES.SHAPE:
        case NAMES.TEXT: {
          result.push(object.id);
          break;
        }

        default: {
          break;
        }
      }

      return result;
    }

    const ids = canvas.getObjects().reduce(reduceObjects, []);
    dispatch(actions.setValue({ path: ['objects', 'ids'], value: ids }));
  });
}

export function initialize({ options, store, theme }) {
  const { actions, dispatch } = store;
  const state = { loading: undefined };

  if (store.state.canvas) {
    store.state.canvas.initialize({ ...options, objects: store.state.canvas.getObjects() });
    addEventListeners({ actions, dispatch, state: store.state });
    renderChecker({ canvas: store.state.canvas, theme });
    state.canvas = store.state.canvas.requestRenderAll();
  } else {
    const { state: { channel, original, zoom }} = store;
    const { height, width } = original;
    const canvas = new fabric.MainCanvas(options);
    canvas.renderOnAddRemove = false;
    state.canvas = canvas;

    const image = new fabric.MainImage(drawImageOnCanvas(original), { height, width });
    const { left, scaleX, scaleY, top } = image;
    image.original = image._originalElement;
    image.aspectRatio = width / height;
    image.element = original;
    canvas.image = image;

    const area = new fabric.CropArea({ height, left, scaleX, scaleY, top, width });
    area.minSize = PHOTO_DIMENSIONS_MINIMUM[channel];

    canvas.clipPath = new fabric.Group([
      new fabric.Rect({ height, left, opacity: 0, top, width }),
      new fabric.Rect({ height, left, opacity: 1, top, width }),
    ]);

    canvas.add(area, image);
    canvas.renderOnAddRemove = true;
    state.crop = { area, height, left, scaleX, scaleY, top, width };
    state.zoom = getZoom({ canvas, target: area, zoom });
    state.zoom.initialSteps = state.zoom.steps;
    addEventListeners({ actions, dispatch, state });
    renderChecker({ canvas: state.canvas, theme });
  }

  return state;
}

export function changeMainImage({ canvas, image }) {
  canvas.image.setElement(drawImageOnCanvas(image));
  canvas.image.setCoords();
  canvas.requestRenderAll();
}

export function canvasToBlob({ canvas, mime = MIME.PNG }) {
  return new Promise(function executor(resolve) {
    canvas.toBlob(resolve, mime);
  });
}

export function getPreview({ filters, image, size }) {
  const { _backgroundRaw: back, _originalElement: front } = image;
  let { height: sh, width: sw } = image;
  let sy = 0;
  let sx = 0;

  if (sw !== sh) {
    if (sw > sh) {
      sx = (sw - sh) / 2;
      sw = sh;
    } else {
      sy = (sh - sw) / 2;
      sh = sw;
    }
  }

  const filterBackend = new fabric.Canvas2dFilterBackend();
  const canvas = fabric.util.createCanvasElement();
  canvas.width = size;
  canvas.height = size;
  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, size, size);
  ctx.drawImage(back, sx, sy, sw, sh, 0, 0, size, size);
  ctx.drawImage(front, sx, sy, sw, sh, 0, 0, size, size);
  const imageData = ctx.getImageData(0, 0, size, size);
  const pipelineState = {
    canvasEl: canvas,
    ctx,
    filterBackend,
    imageData,
    originalEl: canvas,
    originalImageData: imageData,
    sourceHeight: size,
    sourceWidth: size,
  };

  filters.forEach(function applyFilter(filter) {
    if (filter && !filter.isNeutralState()) filter.applyTo(pipelineState);
  });

  ctx.putImageData(pipelineState.imageData, 0, 0);

  return canvas.toDataURL(MIME.PNG);
}

