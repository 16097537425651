import { getTitleError } from '../../validations/title';
import { clearErrors } from '../../errors';
import { escapeRegex } from '../../string';

import { FIND, OPERATIONS, REPLACE } from '../../../constants/bulkEdit';
import { PAGE_TITLE, SEO, TITLE } from '../../../constants/attributes';
import { OVERSET, VALUE } from '../../../constants/product';
import { DEFAULTS } from '../../../constants';
import { SHOPIFY } from '../../../constants/channels';

export default function title({ actions, operation, product }) {
  const channel = product.get('channel');

  function setValueToProduct(value) {
    let result = product.setIn([TITLE, VALUE], value);

    switch (channel) {
      case SHOPIFY: {
        if (product.getIn([SEO, PAGE_TITLE, OVERSET])) {
          result = result.setIn([SEO, PAGE_TITLE, VALUE], value);
        }

        break;
      }

      default: {
        break;
      }
    }

    return result;
  }

  function validateAndApply(value) {
    return {
      actions,
      product: getTitleError({ channel, value })
        ? product
        : clearErrors({ errors: [TITLE], product: setValueToProduct(value) }),
    };
  }

  switch (operation.get('type')) {
    case OPERATIONS[channel].TITLE.ADD_AFTER: {
      const value = `${product.getIn([TITLE, VALUE])}${operation.get(VALUE)}`;
      return validateAndApply(value);
    }

    case OPERATIONS[channel].TITLE.ADD_BEFORE: {
      const value = `${operation.get(VALUE)}${product.getIn([TITLE, VALUE])}`;
      return validateAndApply(value);
    }

    case OPERATIONS[channel].TITLE.DELETE: {
      const pattern = new RegExp(escapeRegex(operation.get(VALUE)), 'ig');
      const value = product.getIn([TITLE, VALUE]).replace(pattern, DEFAULTS.EMPTY_STRING);
      return validateAndApply(value);
    }

    case OPERATIONS[channel].TITLE.FIND_AND_REPLACE: {
      const pattern = new RegExp(escapeRegex(operation.getIn([VALUE, FIND])), 'ig');
      const value = product
        .getIn([TITLE, VALUE])
        .replace(pattern, operation.getIn([VALUE, REPLACE]));

      return validateAndApply(value);
    }

    case OPERATIONS[channel].TITLE.SET: {
      const value = operation.get(VALUE);
      return validateAndApply(value);
    }

    default: {
      return { actions, product };
    }
  }
}
