import { useState } from 'react';
import { produce } from 'immer';

export function useImmerState(initialState) {
  const [state, setState] = useState(initialState);

  function updateState(callback) {
    return setState(produce(callback));
  }

  return [state, updateState];
}
