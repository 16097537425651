export function getFirstNonZeroOption(menu) {
  function reduceOptions(result, status) {
    if (result) return result;

    return menu.getIn(['byId', status, 'counts', 'raw']) > 0
      ? status
      : undefined;
  }

  return menu.get('options').reduce(reduceOptions, undefined);
}
