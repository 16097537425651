import { Map } from 'immutable';
import { v4 } from 'uuid';

import { shapeCollections, shapeLinkedShops, shapeSection } from '../listings/listings';

import { COLLECTIONS, PHOTOS, PRICE, QUANTITY, SECTION, TITLE } from '../../constants/attributes';
import { NEW, SECTIONS, VALUE } from '../../constants/product';
import { ETSY, SHOPIFY } from '../../constants/channels';
import { LISTING } from '../../constants/listings';
import { VELA } from '../../constants';

const { DETAILS, INVENTORY } = SECTIONS;

export function shapeForTable({ product: source, shopData, shopIds, userShops }) {
  const id = source.get('productId');
  const channel = source.get('channel');
  const isPlaceholderForNewProduct = id === NEW;
  let product = Map({
    isPlaceholderForNewProduct,
    [LISTING[VELA].PHOTO]: source.getIn([PHOTOS, VALUE, 0, 'fullsize_url']),
    [LISTING[VELA].PRICE]: source.getIn([INVENTORY, PRICE, VALUE]),
    [LISTING[VELA].PRODUCT_ID]: isPlaceholderForNewProduct ? v4() : id,
    [LISTING[VELA].QUANTITY]: source.getIn([INVENTORY, QUANTITY, VALUE]),
    [LISTING[VELA].TITLE]: source.getIn([TITLE, VALUE]),
  });

  const {
    linkedProductsShopIds,
    linkedProductsShopChannels,
  } = shapeLinkedShops({ shopIds, userShops });

  product = product
    .set(LISTING[VELA].LINKED_PRODUCTS_SHOP_IDS, linkedProductsShopIds)
    .set(LISTING[VELA].LINKED_PRODUCTS_SHOP_CHANNELS, linkedProductsShopChannels);

  switch (channel) {
    case ETSY: {
      const section = source.getIn([DETAILS, SECTION, VALUE]);
      product = product.set(LISTING[VELA].SECTION, shapeSection({ section, shopData }));
      break;
    }

    case SHOPIFY: {
      const collections = source.getIn([DETAILS, COLLECTIONS, VALUE]);
      product = product.set(LISTING[VELA].COLLECTIONS, shapeCollections({ collections, shopData }));
      break;
    }

    default: {
      break;
    }
  }

  return product;
}
