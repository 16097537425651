import { Map } from 'immutable';
import pluralize from 'pluralize';
import { encode } from 'base64-arraybuffer';

import { getActualDate, getDateDifference, getFormattedDate } from './time';
import { formatFileSize } from './number';
import { getFileError } from './validations/files';
import { getSize } from './iterable/getSize';
import { isTruthy } from './bool';
import api from './api/request';

import { MAX_NUMBER_OF_FILES } from '../constants/validations';
import { DEFAULTS, SEPARATOR } from '../constants';
import { ENDPOINT, HEADERS } from '../constants/api';
import { OPERATIONS } from '../constants/product';

const { CONTENT_TYPE, EXPIRES } = HEADERS;
const { EMPTY_LIST } = DEFAULTS;

export function arrangeUploadedFiles(files) {
  function reduceFiles(result, file, index) {
    return result.set(index, file);
  }

  return files.reduce(reduceFiles, EMPTY_LIST).filter(isTruthy);
}

export function fileToArrayBuffer(file) {
  return new Promise(function read(resolve, reject) {
    const reader = new FileReader();

    reader.addEventListener('error', function onError(event) {
      reject(event.target.error);
    });

    reader.addEventListener('loadend', function onloadEnd(event) {
      resolve(event.target.result);
    });

    reader.readAsArrayBuffer(file);
  });
}

export async function fileToBase64(file) {
  const { type } = file;
  const data = await fileToArrayBuffer(file);
  return `data:${type};base64,${encode(data)}`;
}

export function formatTimestamp(timestamp) {
  if (!timestamp) return '';

  try {
    const { days, hours, minutes, months, seconds, years } = getDateDifference(getActualDate(), new Date(timestamp));
    let message = 'Updated ';

    if (years || months || days) {
      message += `${getFormattedDate(timestamp)}`;
    } else if (hours) {
      message += `${pluralize('hour', hours, true)} ago`;
    } else if (minutes) {
      message += `${pluralize('minute', minutes, true)} ago`;
    } else if (seconds) {
      message += 'just now';
    } else {
      message += 'while ago';
    }

    return message;
  } catch (error) {
    return '';
  }
}

export function filesNumberLimitReached({ channel, files, operationType, productFiles, startIndex }) {
  switch (operationType) {
    case OPERATIONS[channel].FILES.ADD: {
      return getSize(productFiles) + getSize(files) === MAX_NUMBER_OF_FILES[channel];
    }

    case OPERATIONS[channel].FILES.REPLACE: {
      return startIndex + getSize(files) === MAX_NUMBER_OF_FILES[channel];
    }

    default: {
      return false;
    }
  }
}

export function formatFileName(file) {
  return file instanceof File
    ? new File([file], file.name.replace(/\s+/g, ''), file)
    : file;
}

export function downloadFile({ data, name }) {
  const a = document.createElement('a');
  a.style = 'display: none';
  a.href = data;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export function shapeFileForApp({ channel, file: source }) {
  if (!(source instanceof File)) {
    throw new Error('Invalid file');
  }

  const name = source.name.replace(/\s+/g, function replacer(substring, index, input) {
    return !index || /[\W_]/.test(`${input.charAt(index - 1)}${input.charAt(index + 1)}`)
      ? ''
      : SEPARATOR.UNDERSCORE;
  });

  const file = new File([source], name, source);
  const error = getFileError({ channel, file });

  if (error) {
    throw new Error(error);
  }

  return file;
}

export function uploadFile({ db, file, s3, shopId, signal, userId }) {
  return new Promise(async function executor(resolve, reject) {
    if (!(file instanceof File)) {
      reject('Invalid file');
      return;
    }

    const { name, size: bytes, type } = file;
    const size = formatFileSize(bytes);

    function onUrl({ uploadUrl }) {
      if (signal.aborted) return;

      function onUpload() {
        if (signal.aborted) return;

        const url = `${ENDPOINT.AWS_S3_FILES}/${userId}/${db}/${shopId}/${name}`;

        resolve(Map({ file_url: url, name, size }));
      }

      if (!uploadUrl) {
        reject('Failed to get signed upload URL');
        return;
      }

      api
        .put({ headers: { [CONTENT_TYPE]: type, [EXPIRES]: 0 }, payload: file, signal, url: uploadUrl })
        .then(onUpload)
        .catch(reject);
    }

    function onLegacyUpload({ uuids }) {
      if (signal.aborted) return;

      resolve(Map({ name, size, uuid: uuids[0].uuid }));
    }

    if (s3) {
      const params = { contentType: type, db, filename: name };

      api
        .get({ endpoint: ENDPOINT.WEB_V2, params, signal, url: `/files/${shopId}/uploadUrl` })
        .then(onUrl)
        .catch(reject);
    } else {
      const data = await fileToBase64(file);
      const payload = { files: [{ file: data, name, size }] };
      api
        .post({ payload, signal, url: '/digital_files/upload' })
        .then(onLegacyUpload)
        .catch(reject);
    }
  });
}
