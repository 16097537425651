import Admin from './adminActions';
import Affiliate from './affiliateActions';
import Auth from './authActions';
import Billing from './billingActions';
import BulkEdit from './bulkEditActions';
import BulkPhotoEditor from './bulkPhotoEditorActions';
import ConnectProfile from './connectProfileActions';
import Data from './dataActions';
import Edit from './editActions';
import Listings from './listingsActions';
import MediaLibrary from './mediaLibraryActions';
import Notifications from './notificationsActions';
import PhotoEditor from './photoEditorActions';
import Profiles from './profilesActions';
import ScheduledUpdates from './scheduledUpdatesActions';
import Shops from './shopsActions';
import User from './userActions';

export default {
  Admin,
  Affiliate,
  Auth,
  Billing,
  BulkEdit,
  BulkPhotoEditor,
  ConnectProfile,
  Data,
  Edit,
  Listings,
  MediaLibrary,
  Notifications,
  PhotoEditor,
  Profiles,
  ScheduledUpdates,
  Shops,
  User,
};
