import { List } from 'immutable';

import { shapeNewProductForAPI, shapeOperationsForAPI } from './shapeForAPI';
import { getSize } from '../iterable/getSize';

import { ETSY, SHOPIFY } from '../../constants/channels';
import { DEFAULTS } from '../../constants';
import { NEW } from '../../constants/product';

const OPERATIONS_KEY = {
  [DEFAULTS.TRUE]: 'newProducts',
  [DEFAULTS.FALSE]: 'productsToEdit',
};

export function addProductIdToOperations({ product }) {
  return shapeOperationsForAPI({
    data: { products: [product.get('productId')] },
    operations: product.get('operations', List()),
  });
}

export function shapeLinkedProductsOperations({ edit, linkId, shopId: currentShopId, shopsById, statuses, userId }) {
  function reduceProducts(result, product, shopId) {
    const shop = shopsById.get(shopId);
    const db = shop.get('db');
    const channel = shop.get('channel');
    const isNew = product.get('productId') === NEW;
    const key = OPERATIONS_KEY[isNew];
    const operations = isNew
      ? shapeNewProductForAPI({ channel, product, shopId, status: statuses.get(shopId), userId })
      : addProductIdToOperations({ product });

    if (!result[key]) {
      result[key] = {};
    }

    if (!result[key][channel]) {
      result[key][channel] = [];
    }

    function mapEtsyOperations(operation) {
      operation.shopId = shopId;
      operation.linkId = linkId;
      operation.db = db;

      return operation;
    }

    switch (channel) {
      case ETSY: {
        if (!isNew && shopId === currentShopId && !getSize(operations)) {
          operations.push({});
        }

        result[key][channel].push(...operations.map(mapEtsyOperations));
        break;
      }

      case SHOPIFY: {
        result[key][channel].push({ db, linkId, operations, shopId });
        break;
      }

      default: {
        break;
      }
    }

    return result;
  }

  return edit.get('products').reduce(reduceProducts, {});
}
