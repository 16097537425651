/* eslint-disable no-console */
import { sideEffect } from 'redux-side-effects';
import { Map, Set } from 'immutable';

import { shapeTemplatesForPropagateAPI, updateNewListingsProfile } from '../utils/profiles/listingsProfiles';
import { shapeListingsForApp, shapeProfilesForApp } from '../utils/profiles/shapeForApp';
import { isFeatureEnabled } from '../utils/featureFlags';
import { createController } from '../utils/reducer';
import { shapeForAPI } from '../utils/profiles/shapeForAPI';
import { copyProfile } from '../utils/profiles/copy';
import { getFilters } from '../utils/profiles/filters';
import { isEditing } from '../utils/profiles/isEditing';
import { decrease } from '../utils/math';
import { shapeId } from '../utils/listings/listings';
import { getSize } from '../utils/iterable/getSize';
import { reduce } from '../utils/iterable/reduce';
import { get } from '../utils/iterable/get';
import api from '../utils/api';

import { PROFILE, PROFILES_PER_PAGE, SALE_PROFILE_FILTER, SAVE_TYPE } from '../constants/profiles';
import { POLLING_INTERVAL, SYNC_INDICATOR } from '../constants/shops';
import { MESSAGE, NOTIFICATION } from '../constants/notifications';
import { FEATURES } from '../constants/billing';
import { DEFAULTS } from '../constants';
import { MODALS } from '../constants/modal';
import { NEW } from '../constants/product';
import ACTIONS from '../constants/actions';

import Reducers, { Reducer } from '../classes/Reducer';
import Actions from '../actions';

const listingsController = createController();
const profilesController = createController();
const templatesController = createController();

function abort(controller) {
  if (controller.isActive()) {
    controller.stop();
  }
}

function abortAll() {
  [
    listingsController,
    profilesController,
    templatesController,
  ].forEach(abort);
}

function* apply(reduction, { channel, db, payload, products, profile, profileId, shopId, type }) {
  yield sideEffect((dispatch) => {
    function onFail(error) {
      dispatch(Actions.Profiles.applyFailed(error));
    }

    function onSuccess() {
      dispatch(Actions.Profiles.applySucceeded({ shopId }));
    }

    switch (type) {
      case PROFILE.LISTINGS: {
        const templates = shapeTemplatesForPropagateAPI({
          excludedSections: payload.excluded_sections,
          products: profile.getIn(['data', 'products']),
          userShops: reduction.get('shops'),
        });

        if (getSize(templates)) {
          api.profiles
            .apply({ payload: templates, profileId, type })
            .then(
              () => {
                templates.forEach(function showSyncIndicator(template) {
                  dispatch(Actions.Profiles.applySucceeded({ shopId: template.shopId }));
                });
              },
              onFail
            );
        }

        return;
      }

      default: {
        break;
      }
    }

    if (!products) {
      api.profiles
        .getListings({ db, profileId, shopId, type })
        .then(
          (response) => {
            const listings = shapeListingsForApp({ response, type });

            if (getSize(listings)) {
              dispatch(
                Actions.Profiles.apply({
                  channel,
                  db,
                  payload,
                  products: listings.map(get('productId')).toArray(),
                  profile,
                  profileId,
                  shopId,
                  type,
                })
              );
            }
          },
          onFail
        );
    } else if (getSize(products)) {
      switch (type) {
        case PROFILE.SALES: {
          api.profiles
            .apply({ db, products, profileId, shopId, type })
            .then(undefined, onFail);

          return;
        }

        case PROFILE.TAGS: {
          api.profiles
            .apply({ db, payload, products, profileId, shopId, type })
            .then(onSuccess, onFail);

          return;
        }

        case PROFILE.VARIATIONS: {
          api.profiles
            .getProfile({ db, profileId, shopId, type })
            .then(
              (data) => api.profiles
                .apply({ channel, db, payload: data.profile, products, profileId, shopId, type })
                .then(onSuccess, onFail),
              onFail
            );

          return;
        }

        default: {
          return;
        }
      }
    }
  });

  return reduction;
}

function* applyFailed(reduction, error) {
  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        message: MESSAGE.FAIL.SYNC,
        type: NOTIFICATION.ERROR,
      })
    );
  });

  return reduction;
}

function* applySucceeded(reduction, { shopId }) {
  yield sideEffect((dispatch) => {
    dispatch(
      Actions.Shops.setData({
        path: ['syncData', shopId],
        value: Map({ indicator: SYNC_INDICATOR.SYNC }),
      })
    );

    dispatch(
      Actions.Shops.rescheduleShopsPolling(
        reduction.getIn(['user', 'config', 'shopsPollingIntervalShort']) ||
        POLLING_INTERVAL.SHORT
      )
    );
  });

  return reduction;
}

function* bootstrap(reduction, { channel, db, shopId, type }) {
  abortAll();

  let state = Map({
    channel,
    db,
    filters: getFilters({ type }),
    pagination: Map({ page: 0, pageSize: PROFILES_PER_PAGE[type] }),
    query: DEFAULTS.EMPTY_STRING,
    shopId,
    type,
  });

  if (
    getSize(reduction.getIn(['profiles', 'editing'])) &&
    !getSize(reduction.get('profiles').delete('editing'))
  ) {
    state = state.set('editing', reduction.getIn(['profiles', 'editing']));
  } else {
    state = state.set('editing', Map());
  }

  if (
    db === reduction.getIn(['profiles', 'db']) &&
    shopId === reduction.getIn(['profiles', 'shopId'])
  ) {
    state = state.set('counts', reduction.getIn(['profiles', 'counts']));
  }

  yield sideEffect((dispatch) => {
    dispatch(Actions.Profiles.getProfiles());
  });

  return reduction.set('profiles', state);
}

function* cleanUp(reduction) {
  abortAll();
  return reduction.set('profiles', Map());
}

function* copy(reduction, { profile, targetShopId }) {
  const profiles = reduction.get('profiles');
  const type = profiles.get('type');
  const shopId = targetShopId || profiles.get('shopId');

  yield sideEffect((dispatch) => {
    const userId = reduction.getIn(['user', 'userId']);
    const profileId = profile.get('id');
    const shop = reduction.getIn(['shops', 'byId', shopId]);
    const channel = shop.get('channel');
    const db = shop.get('db');
    const payload = shapeForAPI({ type, profile: copyProfile({ channel, profile, type }), userId });

    api.profiles
      .create({ db, payload, profileId, shopId, type })
      .then(
        (response) => dispatch(Actions.Profiles.copySucceeded({ response, shopId, type })),
        (error) => dispatch(Actions.Profiles.copyFailed({ error, profileId, shopId, type }))
      );
  });

  return shopId === profiles.get('shopId')
    ? reduction.setIn(['profiles', 'loading'], true)
    : reduction;
}

function* copyFailed(reduction, { error, profileId, shopId, type }) {
  const profiles = reduction.get('profiles');
  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.FAIL,
        message: MESSAGE.FAIL.COPY_PROFILE,
      })
    );
  });

  return (
    profiles.get('type') === type &&
    profiles.get('shopId') === shopId &&
    profiles.hasIn(['byId', profileId]) &&
    profiles.get('loading')
  )
    ? reduction.deleteIn(['profiles', 'loading'])
    : reduction;
}

function* copySucceeded(reduction, { response, shopId, type }) {
  const profiles = reduction.get('profiles');

  yield sideEffect((dispatch) => {
    function getProfileId() {
      switch (type) {
        case PROFILE.LISTINGS: {
          return shapeId(response.profileId);
        }

        default: {
          return shapeId(response.id);
        }
      }
    }

    const profileId = getProfileId();

    if (profiles.get('type') === type && profiles.get('shopId') === shopId) {
      dispatch(Actions.Profiles.setPage({ editing: Map({ [profileId]: null }), page: 0 }));
      dispatch(
        Actions.Notifications.add({
          type: NOTIFICATION.SUCCESS,
          message: MESSAGE.SUCCESS.COPY_PROFILE,
        })
      );
    } else {
      dispatch(
        Actions.Notifications.add({
          type: NOTIFICATION.PROFILE_COPIED,
          profileId,
          shopId,
          shopName: reduction.getIn(['shops', 'byId', shopId, 'name']),
          profileType: type,
        })
      );
    }
  });

  return reduction;
}

function* disconnectListing(reduction, { index, productId, profileId }) {
  const state = reduction
    .get('profiles')
    .setIn(['listings', profileId, index, 'disconnecting'], true);

  yield sideEffect((dispatch) => {
    const db = state.get('db');
    const shopId = state.get('shopId');
    const type = state.get('type');
    const params = { db, productId, profileId, shopId, type };

    api.profiles
      .disconnectListing(params)
      .then(
        () => dispatch(Actions.Profiles.disconnectListingSucceeded({ index, ...params })),
        (error) => dispatch(Actions.Profiles.disconnectListingFailed({ error, index, ...params })),
      );
  });

  return reduction.set('profiles', state);
}

function* disconnectListingFailed(reduction, { db, error, index, productId, profileId, shopId, type }) {
  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        message: MESSAGE.FAIL.DISCONNECT_LISTINGS,
        type: NOTIFICATION.ERROR,
      })
    );
  });

  let state = reduction.get('profiles');

  function updateListings(listings) {
    return listings.map(function mapListings(listing) {
      return listing.get('productId') === productId
        ? listings.delete('disconnecting')
        : listing;
    });
  }

  if (
    state.get('db') === db &&
    state.get('shopId') === shopId &&
    state.get('type') === type &&
    state.hasIn(['listings', profileId])
  ) {
    if (state.getIn(['listings', profileId, index, 'productId']) === productId) {
      state = state.deleteIn(['listings', profileId, index, 'disconnecting']);
    } else {
      state = state.updateIn(['listings', profileId], updateListings);
    }

    return reduction.set('profiles', state);
  }

  return reduction;
}

function* disconnectListingSucceeded(reduction, { db, index, productId, profileId, shopId, type }) {
  yield sideEffect((dispatch) => {
    dispatch(
      Actions.Notifications.add({
        message: MESSAGE.SUCCESS.DISCONNECT_LISTINGS,
        type: NOTIFICATION.SUCCESS,
      })
    );
  });

  let state = reduction.get('profiles');

  function updateListings(listings) {
    return listings.filter(function filterListings(listing) {
      return listing.get('productId') !== productId;
    });
  }

  if (
    state.get('db') === db &&
    state.get('shopId') === shopId &&
    state.get('type') === type &&
    state.hasIn(['byId', profileId])
  ) {
    state = state.updateIn(['byId', profileId, 'listingsCount'], decrease);

    if (!state.getIn(['byId', profileId, 'listingsCount'])) {
      state = state.deleteIn(['listings', profileId]);
    } else if (state.getIn(['listings', profileId, index, 'productId']) === productId) {
      state = state.deleteIn(['listings', profileId, index]);
    } else if (state.hasIn(['listings', profileId])) {
      state = state.updateIn(['listings', profileId], updateListings);
    }

    return reduction.set('profiles', state);
  }

  return reduction;
}

function* edit(reduction, { profile, profileId }) {
  const profiles = reduction.get('profiles');
  const editing = profiles.get('editing', Map());
  const isCancel = !profile && profile !== null;

  if (editing.get(profileId) && isCancel) {
    if (
      !profiles.get('modal') ||
      (
        profiles.getIn(['modal', 'type']) !== MODALS.PROFILES.CANCEL &&
        profiles.getIn(['modal', 'type']) !== MODALS.PROFILES.LEAVE
      ) ||
      profiles.getIn(['modal', 'profileId']) !== profileId
    ) {
      yield sideEffect((dispatch) => {
        dispatch(
          Actions.Profiles.setModal({
            action: Actions.Profiles.edit({ profile, profileId }),
            profileId,
            type: MODALS.PROFILES.CANCEL,
          })
        );
      });

      return reduction;
    }
  } else if (profiles.hasIn(['byId', profileId]) && profile === null) {
    return reduction.setIn(['profiles', 'editing'],
      editing.set(profileId, profiles.getIn(['byId', profileId])));
  }

  return reduction.setIn(['profiles', 'editing'],
    isCancel
      ? editing.delete(profileId)
      : editing.set(profileId, profile)
  );
}

function* editTemplate(reduction, { data, profileId }) {
  if (!reduction.getIn(['profiles', 'editing', profileId])) return reduction;

  return reduction.setIn(['profiles', 'editing', profileId, 'data'], data);
}

function* filter(reduction, filters) {
  function reduceFilters(state, item, group) {
    function updateSelected(selected) {
      return selected.has(item)
        ? selected.delete(item)
        : selected.add(item);
    }

    return state.updateIn(['profiles', 'filters', group, 'selected'], updateSelected);
  }

  return reduce(filters, reduction, reduceFilters);
}

function* getListings(reduction, profileId) {
  if (!reduction.hasIn(['profiles', 'byId', profileId])) return reduction;

  abort(listingsController);

  let state = reduction.get('profiles');

  if (!state.hasIn(['listings', profileId])) {
    state = state.setIn(['listings', profileId], DEFAULTS.EMPTY_LIST);
  }

  yield sideEffect((dispatch) => {
    const { signal } = listingsController.start();
    const db = state.get('db');
    const shopId = state.get('shopId');
    const type = state.get('type');

    api.profiles
      .getListings({ db, profileId, shopId, signal, type })
      .then(
        (response) => dispatch(Actions.Profiles.getListingsSucceeded({ profileId, response, type })),
        (error) => dispatch(Actions.Profiles.getListingsFailed({ error, profileId, signal })),
      );
  });

  return reduction.set('profiles', state);
}

function* getListingsFailed(reduction, { error, profileId, signal }) {
  if (!signal.aborted) {
    abort(listingsController);

    yield sideEffect((dispatch) => {
      if (error) console.error(error);
      dispatch(
        Actions.Notifications.add({
          message: MESSAGE.FAIL.LOAD_LISTINGS,
          type: NOTIFICATION.ERROR,
        })
      );
    });
  }

  return reduction.getIn(['profiles', 'listings', profileId]) === DEFAULTS.EMPTY_LIST
    ? reduction.deleteIn(['profiles', 'listings', profileId])
    : reduction;
}

function* getListingsSucceeded(reduction, { profileId, response, type }) {
  abort(listingsController);

  const listings = shapeListingsForApp({ response, type });

  if (!listings) {
    yield sideEffect((dispatch) => {
      dispatch(Actions.Profiles.getListingsFailed({ profileId, signal: {}}));
    });

    return reduction;
  }

  const listingsCount = getSize(listings);
  let profiles = reduction.get('profiles').setIn(['listings', profileId], listings);

  if (profiles.getIn(['byId', profileId, 'listingsCount']) !== listingsCount) {
    profiles = profiles.setIn(['byId', profileId, 'listingsCount'], listingsCount);
  }

  return reduction.set('profiles', profiles);
}

function* getProfiles(reduction, { keepListings } = {}) {
  abort(profilesController);

  if (!keepListings) {
    abort(listingsController);
  }

  const state = reduction.get('profiles').set('loading', true);

  yield sideEffect((dispatch) => {
    const { signal } = profilesController.start();
    const db = state.get('db');
    const pagination = state.get('pagination');
    const shopId = state.get('shopId');
    const type = state.get('type');
    const query = state.get('query');
    const page = pagination.get('page', 0);
    const pageSize = pagination.get('pageSize');
    const offset = page * pageSize;
    const requests = [
      api.profiles.get({ db, limit: pageSize, offset, query, shopId, signal, type }),
    ];

    requests.push(
      api.profiles.totals({ db, shopId, signal, type: PROFILE.LISTINGS })
    );

    if (type === PROFILE.LISTINGS) {
      requests.push(
        api.profiles.get({
          db,
          limit: 0,
          offset: 0,
          shopId,
          signal,
          type: PROFILE.TAGS,
        })
      );
    }

    Promise
      .all(requests)
      .then(
        (responses) => {
          dispatch(
            Actions.Profiles.getProfilesSucceeded({
              keepListings,
              query,
              responses,
            })
          );
        },
        (error) => dispatch(Actions.Profiles.getProfilesFailed({ error, signal })),
      );
  });

  return reduction.set('profiles', state);
}

function* getProfilesFailed(reduction, { error, signal }) {
  if (signal.aborted) return reduction;

  abort(profilesController);

  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_PROFILES,
      })
    );
  });

  return reduction.deleteIn(['profiles', 'loading']);
}

function* getProfilesSucceeded(reduction, { keepListings, query, responses }) {
  abort(profilesController);

  let state = reduction.get('profiles').delete('loading');
  let pagination = state.get('pagination');
  const channel = state.get('channel');
  const db = state.get('db');
  const shopId = state.get('shopId');
  const type = state.get('type');

  const { totalProfiles } = responses[2] || responses[0];
  let counts = Map({
    [PROFILE.TAGS]: totalProfiles[PROFILE.TAGS].count,
    [PROFILE.VARIATIONS]: totalProfiles[PROFILE.VARIATIONS].count,
  });

  if (isFeatureEnabled({ feature: FEATURES.SALES_PROFILES, shopId, db })) {
    counts = counts.set(PROFILE.SALES, totalProfiles[PROFILE.SALES].count);
  }

  if (responses[1]) {
    counts = counts.set(PROFILE.LISTINGS, responses[1].count);
  }

  const total = query
    ? parseInt(responses[0].count, 10) || 0
    : parseInt(counts.get(type), 10) || 0;

  const page = pagination.get('page', 0);
  const pageSize = pagination.get('pageSize');
  const offset = page * pageSize;

  pagination = pagination
    .set('end', Math.min(total, offset + pageSize))
    .set('start', offset)
    .set('total', total);

  const shaped = shapeProfilesForApp({
    channel,
    profiles: responses[0],
    state: Map({
      counts,
      editing: state.get('editing'),
      listings: keepListings ? state.get('listings', Map()) : Map(),
      pagination,
      titles: Set(),
    }),
    type,
    userShops: reduction.get('shops'),
  });

  function filterEditing(item) {
    return item !== null;
  }

  for (const [key, value] of shaped.entries()) {
    switch (key) {
      case 'editing': {
        state = state.set(key, value.filter(filterEditing));
        break;
      }

      default: {
        state = state.set(key, value);
        break;
      }
    }
  }

  return reduction.set('profiles', state);
}

function* remove(reduction, profileId) {
  if (
    reduction.getIn(['profiles', 'modal', 'type']) !== MODALS.PROFILES.DELETE ||
    reduction.getIn(['profiles', 'modal', 'profileId']) !== profileId
  ) {
    yield sideEffect((dispatch) => {
      dispatch(Actions.Profiles.setModal({ profileId, type: MODALS.PROFILES.DELETE }));
    });

    return reduction;
  }

  const profiles = reduction.get('profiles');
  const db = profiles.get('db');
  const shopId = profiles.get('shopId');
  const type = profiles.get('type');

  yield sideEffect((dispatch) => {
    api.profiles
      .remove({ db, profileId, shopId, type })
      .then(
        () => dispatch(Actions.Profiles.removeSucceeded({ shopId, type })),
        error => dispatch(Actions.Profiles.removeFailed({ error, shopId, type }))
      );
  });

  return reduction
    .deleteIn(['profiles', 'modal'])
    .setIn(['profiles', 'loading'], true);
}

function* removeFailed(reduction, { error, shopId, type }) {
  yield sideEffect((dispatch) => {
    console.error('Error encountered while deleting profile: ', error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.DELETE_PROFILE,
      })
    );
  });

  return (
    reduction.getIn(['profiles', 'loading']) &&
    type === reduction.getIn(['profiles', 'type']) &&
    shopId === reduction.getIn(['profiles', 'shopId'])
  )
    ? reduction.deleteIn(['profiles', 'loading'])
    : reduction;
}

function* removeSucceeded(reduction, { shopId, type }) {
  yield sideEffect((dispatch) => {
    if (reduction.getIn(['data', 'loaded', 'profiles', shopId, type])) {
      dispatch(Actions.Data.getProfiles({ force: true, shopId, type }));
    }

    if (
      type === reduction.getIn(['profiles', 'type']) &&
      shopId === reduction.getIn(['profiles', 'shopId'])
    ) {
      dispatch(Actions.Profiles.getProfiles({ keepListings: true }));
    }

    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.SUCCESS,
        message: MESSAGE.SUCCESS.DELETE_PROFILE,
      })
    );
  });

  return reduction;
}

function* save(reduction, { profile, saveType, type, ...rest }) {
  yield sideEffect((dispatch) => {
    const userShops = reduction.get('shops');
    const userId = reduction.getIn(['user', 'userId']);
    const payload = shapeForAPI({ profile, type, userId, userShops });
    const request = saveType === SAVE_TYPE.SAVE_AS_NEW
      ? api.profiles.create
      : api.profiles.update;

    request({ payload, type, ...rest })
      .then(
        () => dispatch(Actions.Profiles.saveSucceeded({ payload, profile, saveType, type, ...rest })),
        (error) => dispatch(Actions.Profiles.saveFailed({ error, profile, saveType, type, ...rest }))
      );
  });

  return reduction;
}

function* saveFailed(reduction, { error, profile, profileId, saveType, shopId, type }) {
  let profiles = reduction.get('profiles');

  if (
    type === PROFILE.LISTINGS &&
    type === profiles.get('type') &&
    shopId === profiles.get('shopId') &&
    profiles.hasIn(['editing', profileId])
  ) {
    profiles = profiles.setIn(['editing', profileId, 'data'], profile.get('data'));
  }

  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: saveType === SAVE_TYPE.SAVE_AS_NEW
          ? MESSAGE.FAIL.CREATE_PROFILE
          : MESSAGE.FAIL.SAVE,
      })
    );
  });

  return reduction.set('profiles', profiles);
}

function* saveSucceeded(reduction, {
  channel,
  db,
  listings,
  payload,
  profile,
  profileId,
  saveType,
  shopId,
  type,
}) {
  let profiles = reduction.get('profiles');
  const actions = [
    Actions.Notifications.add({
      type: NOTIFICATION.SUCCESS,
      message: saveType === SAVE_TYPE.SAVE_AS_NEW
        ? MESSAGE.SUCCESS.CREATE_PROFILE
        : MESSAGE.SUCCESS.SAVE_PROFILE,
    }),
  ];

  if (
    profile.get('listingsCount') &&
    saveType === SAVE_TYPE.UPDATE_PROFILE && (
      type !== PROFILE.SALES ||
      profile.get('status') === SALE_PROFILE_FILTER.PENDING
    )
  ) {
    actions.push(
      Actions.Profiles.apply({
        channel,
        db,
        payload,
        products: listings && listings.map(get('productId')),
        profile,
        profileId,
        shopId,
        type,
      })
    );
  }

  if (reduction.getIn(['data', 'loaded', 'profiles', shopId, type])) {
    actions.push(Actions.Data.getProfiles({ force: true, shopId, type }));
  }

  if (
    type === profiles.get('type') &&
    shopId === profiles.get('shopId') &&
    profiles.hasIn(['editing', profileId])
  ) {
    profiles = profiles.deleteIn(['editing', profileId]);

    if (saveType === SAVE_TYPE.SAVE_AS_NEW) {
      actions.push(Actions.Profiles.setPage({ page: 0 }));
    } else {
      actions.push(Actions.Profiles.getProfiles({ keepListings: true }));
    }
  }

  yield sideEffect((dispatch) => {
    actions.forEach(dispatch);
  });

  return reduction.set('profiles', profiles);
}

function* search(reduction, query) {
  const profiles = reduction.get('profiles');

  if (isEditing({ profiles })) {
    yield sideEffect((dispatch) => {
      dispatch(
        Actions.Profiles.setModal({
          action: Actions.Profiles.search(query),
          type: MODALS.PROFILES.CANCEL,
        })
      );
    });

    return reduction;
  }

  yield sideEffect((dispatch) => {
    dispatch(Actions.Profiles.getProfiles());
  });

  return reduction.set('profiles',
    profiles
      .set('editing', Map())
      .setIn(['pagination', 'page'], 0)
      .set('query', query)
  );
}

function* setModal(reduction, payload) {
  return payload
    ? reduction.setIn(['profiles', 'modal'], Map(payload))
    : reduction.deleteIn(['profiles', 'modal']);
}

function* setPage(reduction, { editing = Map(), page }) {
  const profiles = reduction.get('profiles');

  if (isEditing({ profiles })) {
    yield sideEffect((dispatch) => {
      dispatch(
        Actions.Profiles.setModal({
          action: Actions.Profiles.setPage({ editing, page }),
          type: MODALS.PROFILES.CANCEL,
        })
      );
    });

    return reduction;
  }

  yield sideEffect((dispatch) => {
    dispatch(
      Actions.Profiles.getProfiles({
        keepListings: page === profiles.getIn(['pagination', 'page']),
      })
    );
  });

  return reduction.set('profiles',
    profiles
      .set('editing', editing)
      .setIn(['pagination', 'page'], page)
  );
}

function* stopWaitingForListings(reduction, profileId) {
  return reduction.deleteIn(['profiles', 'byId', profileId, 'waitingForListings']);
}

function* toggleTemplate(reduction, shopId) {
  let profile = reduction.getIn(['profiles', 'editing', NEW]);

  if (!profile) return reduction;

  const userShops = reduction.get('shops');

  function updateShopIds(shopIds = Set()) {
    return shopIds.has(shopId)
      ? shopIds.delete(shopId)
      : shopIds.add(shopId);
  }

  profile = profile.update('shopIds', updateShopIds);

  function reduceShopIds(newChannels, item) {
    return newChannels.add(userShops.getIn(['byId', item, 'channel']));
  }

  profile = profile.set('channels', profile.get('shopIds').reduce(reduceShopIds, Set()));
  profile = updateNewListingsProfile({ profile, userShops });

  if (profile.hasIn(['shopIds', shopId])) {
    yield sideEffect((dispatch) => {
      dispatch(Actions.Data.getAllData(shopId));
    });
  }

  return reduction.setIn(['profiles', 'editing', NEW], profile);
}

function* waitForUploadAndSave(reduction, payload) {
  if (payload.type !== PROFILE.LISTINGS) return reduction;

  if (!reduction.getIn(['profiles', 'editing', payload.profileId, 'syncStarted'])) {
    yield sideEffect((dispatch) => {
      dispatch(Actions.Profiles.waitForUploadAndSave(payload));
    });

    return reduction
      .deleteIn(['profiles', 'editing', payload.profileId, 'data'])
      .setIn(['profiles', 'editing', payload.profileId, 'syncStarted'], true);
  }

  yield sideEffect((dispatch) => {
    dispatch(Actions.Profiles.save(payload));
  });

  return reduction.deleteIn(['profiles', 'editing', payload.profileId, 'syncStarted']);
}

Reducers.add(
  new Reducer('Profiles')
    .add(ACTIONS.PROFILES.APPLY, apply)
    .add(ACTIONS.PROFILES.APPLY_FAILED, applyFailed)
    .add(ACTIONS.PROFILES.APPLY_SUCCEEDED, applySucceeded)
    .add(ACTIONS.PROFILES.BOOTSTRAP, bootstrap)
    .add(ACTIONS.PROFILES.CLEAN_UP, cleanUp)
    .add(ACTIONS.PROFILES.COPY, copy)
    .add(ACTIONS.PROFILES.COPY_FAILED, copyFailed)
    .add(ACTIONS.PROFILES.COPY_SUCCEEDED, copySucceeded)
    .add(ACTIONS.PROFILES.EDIT, edit)
    .add(ACTIONS.PROFILES.EDIT_TEMPLATE, editTemplate)
    .add(ACTIONS.PROFILES.FILTER, filter)
    .add(ACTIONS.PROFILES.DISCONNECT_LISTING, disconnectListing)
    .add(ACTIONS.PROFILES.DISCONNECT_LISTING_FAILED, disconnectListingFailed)
    .add(ACTIONS.PROFILES.DISCONNECT_LISTING_SUCCEEDED, disconnectListingSucceeded)
    .add(ACTIONS.PROFILES.GET_LISTINGS, getListings)
    .add(ACTIONS.PROFILES.GET_LISTINGS_FAILED, getListingsFailed)
    .add(ACTIONS.PROFILES.GET_LISTINGS_SUCCEEDED, getListingsSucceeded)
    .add(ACTIONS.PROFILES.GET_PROFILES, getProfiles)
    .add(ACTIONS.PROFILES.GET_PROFILES_FAILED, getProfilesFailed)
    .add(ACTIONS.PROFILES.GET_PROFILES_SUCCEEDED, getProfilesSucceeded)
    .add(ACTIONS.PROFILES.REMOVE, remove)
    .add(ACTIONS.PROFILES.REMOVE_FAILED, removeFailed)
    .add(ACTIONS.PROFILES.REMOVE_SUCCEEDED, removeSucceeded)
    .add(ACTIONS.PROFILES.SAVE, save)
    .add(ACTIONS.PROFILES.SAVE_FAILED, saveFailed)
    .add(ACTIONS.PROFILES.SAVE_SUCCEEDED, saveSucceeded)
    .add(ACTIONS.PROFILES.SEARCH, search)
    .add(ACTIONS.PROFILES.SET_MODAL, setModal)
    .add(ACTIONS.PROFILES.SET_PAGE, setPage)
    .add(ACTIONS.PROFILES.STOP_WAITING_FOR_LISTINGS, stopWaitingForListings)
    .add(ACTIONS.PROFILES.TOGGLE_TEMPLATE, toggleTemplate)
    .add(ACTIONS.PROFILES.WAIT_FOR_UPLOAD_AND_SAVE, waitForUploadAndSave)
);
