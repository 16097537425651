import { getOfferingsErrors, setProfileErrors } from '../../validations/profile';
import { shapeVariationsProfileForApp } from '../../profiles/shapeForApp';
import { getInventoryFromVariations } from '../../inventory';
import { reduceErrors } from '../../errors';
import { retain } from '../../variations/retain';
import {
  setBarcodeErrors,
  setCAPErrors,
  setCPIErrors,
  setPriceErrors,
  setQuantityErrors,
  setSKUErrors,
  setWeightErrors,
  updateProductErrorsFromVariations,
  updateProductVariationsErrors,
} from '../../product/errors';

import { ETSY, SHOPIFY } from '../../../constants/channels';
import { OPERATIONS } from '../../../constants/bulkEdit';
import { SECTIONS } from '../../../constants/product';
import { PROFILE } from '../../../constants/profiles';
import {
  BARCODE,
  CAP,
  CPI,
  DIGITAL,
  PHYSICAL,
  PRICE,
  QUANTITY,
  SKU,
  VARIATIONS,
  WEIGHT,
} from '../../../constants/attributes';

const { DETAILS, INVENTORY } = SECTIONS;

function isProfileOperation({ channel, operation }) {
  switch (channel) {
    case ETSY: {
      return operation.hasIn(['value', 'templateid']);
    }

    case SHOPIFY: {
      return operation.has('templateId');
    }

    default: {
      return false;
    }
  }
}

export default function variations({ actions, operation, product: source }) {
  let product = source;
  const channel = product.get('channel');

  switch (operation.get('type')) {
    case OPERATIONS[channel].VARIATIONS: {
      if (channel === ETSY && product.getIn([DETAILS, DIGITAL, 'value'])) break;

      const isProfile = isProfileOperation({ channel, operation });
      let profile = shapeVariationsProfileForApp({ profile: operation, channel })
        .delete('id')
        .delete('title')
        .delete('invalid')
        .delete('listingsCount');

      if (isProfile) {
        profile = retain({ channel, profile, product, validate: false });
      }

      profile = setProfileErrors({ type: PROFILE.VARIATIONS, profile });

      if (!reduceErrors(profile.get('errors'))) {
        product = updateProductVariationsErrors({
          channel,
          product: product.set(VARIATIONS, profile),
        });
      }

      break;
    }

    case OPERATIONS[SHOPIFY].BARCODE.INLINE_EDIT: {
      let updatedProduct = getInventoryFromVariations({
        channel,
        product,
        variations: operation.get('value'),
      });

      const overset = updatedProduct.getIn([INVENTORY, BARCODE, 'overset']);

      if (overset) {
        updatedProduct = updatedProduct.setIn([VARIATIONS, 'errors'],
          getOfferingsErrors(updatedProduct.get(VARIATIONS))
        );

        updatedProduct = updatedProduct
          .getIn([VARIATIONS, 'errors'])
          .reduce(updateProductErrorsFromVariations, updatedProduct);
      } else {
        updatedProduct = setBarcodeErrors({ channel, product: updatedProduct });
      }

      const error = overset
        ? updatedProduct.getIn(['errors', 'variations', BARCODE])
        : updatedProduct.getIn(['errors', BARCODE]);

      if (!error) {
        product = updatedProduct;
      }

      break;
    }

    case OPERATIONS[SHOPIFY].CAP.INLINE_EDIT: {
      let updatedProduct = getInventoryFromVariations({
        channel,
        product,
        variations: operation.get('value'),
      });

      const overset = updatedProduct.getIn([INVENTORY, CAP, 'overset']);

      if (overset) {
        updatedProduct = updatedProduct.setIn([VARIATIONS, 'errors'],
          getOfferingsErrors(updatedProduct.get(VARIATIONS))
        );

        updatedProduct = updatedProduct
          .getIn([VARIATIONS, 'errors'])
          .reduce(updateProductErrorsFromVariations, updatedProduct);
      } else {
        updatedProduct = setCAPErrors({ channel, product: updatedProduct });
      }

      const error = overset
        ? updatedProduct.getIn(['errors', 'variations', CAP])
        : updatedProduct.getIn(['errors', CAP]);

      if (!error) {
        product = updatedProduct;
      }

      break;
    }

    case OPERATIONS[SHOPIFY].CPI.INLINE_EDIT: {
      let updatedProduct = getInventoryFromVariations({
        channel,
        product,
        variations: operation.get('value'),
      });

      const overset = updatedProduct.getIn([INVENTORY, CPI, 'overset']);

      if (overset) {
        updatedProduct = updatedProduct.setIn([VARIATIONS, 'errors'],
          getOfferingsErrors(updatedProduct.get(VARIATIONS))
        );

        updatedProduct = updatedProduct
          .getIn([VARIATIONS, 'errors'])
          .reduce(updateProductErrorsFromVariations, updatedProduct);
      } else {
        updatedProduct = setCPIErrors({ channel, product: updatedProduct });
      }

      const error = overset
        ? updatedProduct.getIn(['errors', 'variations', CPI])
        : updatedProduct.getIn(['errors', CPI]);

      if (!error) {
        product = updatedProduct;
      }

      break;
    }

    case OPERATIONS[SHOPIFY].CHARGE_TAX.INLINE_EDIT:
    case OPERATIONS[SHOPIFY].CONTINUE_SELLING.INLINE_EDIT:
    case OPERATIONS[SHOPIFY].COUNTRY_CODE.INLINE_EDIT:
    case OPERATIONS[SHOPIFY].HS_CODE.INLINE_EDIT: {
      return getInventoryFromVariations({
        channel,
        product,
        variations: operation.get('value'),
      });
    }

    case OPERATIONS[SHOPIFY].PHYSICAL.INLINE_EDIT:
    case OPERATIONS[SHOPIFY].WEIGHT.INLINE_EDIT: {
      let updatedProduct = getInventoryFromVariations({
        channel,
        product,
        variations: operation.get('value'),
      });

      const overset = updatedProduct.getIn([INVENTORY, PHYSICAL, 'overset']);

      if (overset) {
        updatedProduct = updatedProduct.setIn([VARIATIONS, 'errors'],
          getOfferingsErrors(updatedProduct.get(VARIATIONS))
        );

        updatedProduct = updatedProduct
          .getIn([VARIATIONS, 'errors'])
          .reduce(updateProductErrorsFromVariations, updatedProduct);
      } else {
        updatedProduct = setWeightErrors({ channel, product: updatedProduct });
      }

      const error = overset
        ? updatedProduct.getIn(['errors', 'variations', WEIGHT])
        : updatedProduct.getIn(['errors', WEIGHT]);

      if (!error) {
        product = updatedProduct;
      }

      break;
    }

    case OPERATIONS[SHOPIFY].PRICE.INLINE_EDIT: {
      let updatedProduct = getInventoryFromVariations({
        channel,
        product,
        variations: operation.get('value'),
      });

      const overset = updatedProduct.getIn([INVENTORY, PRICE, 'overset']);

      if (overset) {
        updatedProduct = updatedProduct.setIn([VARIATIONS, 'errors'],
          getOfferingsErrors(updatedProduct.get(VARIATIONS))
        );

        updatedProduct = updatedProduct
          .getIn([VARIATIONS, 'errors'])
          .reduce(updateProductErrorsFromVariations, updatedProduct);
      } else {
        updatedProduct = setPriceErrors({ channel, product });
      }

      const error = overset
        ? updatedProduct.getIn(['errors', 'variations', PRICE])
        : updatedProduct.getIn(['errors', PRICE]);

      if (!error) {
        product = updatedProduct;
      }

      break;
    }

    case OPERATIONS[SHOPIFY].QUANTITY.INLINE_EDIT: {
      let updatedProduct = getInventoryFromVariations({
        channel,
        product,
        variations: operation.get('value'),
      });

      const overset = updatedProduct.getIn([INVENTORY, QUANTITY, 'overset']);

      if (overset) {
        updatedProduct = updatedProduct.setIn([VARIATIONS, 'errors'],
          getOfferingsErrors(updatedProduct.get(VARIATIONS))
        );

        updatedProduct = updatedProduct
          .getIn([VARIATIONS, 'errors'])
          .reduce(updateProductErrorsFromVariations, updatedProduct);
      } else {
        updatedProduct = setQuantityErrors({ channel, product: updatedProduct });
      }

      const error = overset
        ? updatedProduct.getIn(['errors', 'variations', QUANTITY])
        : updatedProduct.getIn(['errors', QUANTITY]);

      if (!error) {
        product = updatedProduct;
      }

      break;
    }

    case OPERATIONS[SHOPIFY].SKU.INLINE_EDIT: {
      let updatedProduct = getInventoryFromVariations({
        channel,
        product,
        variations: operation.get('value'),
      });

      const overset = updatedProduct.getIn([INVENTORY, SKU, 'overset']);

      if (overset) {
        updatedProduct = updatedProduct.setIn([VARIATIONS, 'errors'],
          getOfferingsErrors(updatedProduct.get(VARIATIONS))
        );

        updatedProduct = updatedProduct
          .getIn([VARIATIONS, 'errors'])
          .reduce(updateProductErrorsFromVariations, updatedProduct);
      } else {
        updatedProduct = setSKUErrors({ channel, product: updatedProduct });
      }

      const error = overset
        ? updatedProduct.getIn(['errors', 'variations', SKU])
        : updatedProduct.getIn(['errors', SKU]);

      if (!error) {
        product = updatedProduct;
      }

      break;
    }

    default: {
      break;
    }
  }

  return { actions, product };
}
