import { Set, Map } from 'immutable';

import { getAttribute } from '../taxonomy';
import { shapeId } from '../listings/listings';
import { getSize } from '../iterable/getSize';

import { FILTER, MENU_ITEM, MENU_ITEMS, MENU_SECTION } from '../../constants/bulkEdit';
import { TAXONOMY_ATTRIBUTE } from '../../constants/product';
import { DEFAULTS } from '../../constants';

const { EMPTY_LIST, EMPTY_MAP, EMPTY_SET } = DEFAULTS;

export function getAttributeType(attribute = {}) {
  if (getSize(attribute.availableScales)) {
    return TAXONOMY_ATTRIBUTE.SCALED;
  }

  if (getSize(attribute.availableOptions)) {
    if (attribute.isMultivalued) {
      return TAXONOMY_ATTRIBUTE.MULTI;
    }

    if (attribute.totalOptions === 2 && getSize(attribute.availableOptions) === 2) {
      return TAXONOMY_ATTRIBUTE.BOOL;
    }

    return TAXONOMY_ATTRIBUTE.SINGLE;
  }

  return undefined;
}

export function getTaxonomyAttribute({ attribute = EMPTY_MAP, category, property, scale }) {
  const data = getAttribute({ category, property, scale });

  function addOptions(options, item) {
    const { id, name } = item;

    if (options.hasIn(['byId', id])) return options;

    function updateById(byId = EMPTY_MAP) {
      return byId.set(id, Map({ id, name }));
    }

    function updateIds(ids = EMPTY_LIST) {
      return ids.push(id);
    }

    return options.update('byId', updateById).update('ids', updateIds);
  }

  function updateOptions(oldOptions = EMPTY_MAP) {
    return (data.availableOptions || []).reduce(addOptions, oldOptions);
  }

  function updateScales(oldScales = EMPTY_MAP) {
    function addScales(source, item) {
      let scales = source;
      const { id, name, suggestedOptionIds: suggested } = item;

      if (getSize(suggested)) {
        scales = scales.set('hasSuggested', true);
      }

      if (scales.hasIn(['byId', id])) {
        if (getSize(suggested)) {
          const { availableOptions: scaleOptions } = getAttribute({ category, property, scale: id });

          if (getSize(scaleOptions)) {
            scales = scales.setIn(['byId', id, 'options'],
              scaleOptions.reduce(addOptions, scales.getIn(['byId', id, 'options']))
            );
          }
        }

        return scales;
      }

      function updateById(byId = EMPTY_MAP) {
        let result = Map({ id, name });

        if (getSize(suggested)) {
          result = result.set('suggested', Set(suggested));

          const { availableOptions: scaleOptions } = getAttribute({ category, property, scale: id });

          if (getSize(scaleOptions)) {
            result = result.set('options', scaleOptions.reduce(addOptions, EMPTY_MAP));
          }
        }

        return byId.set(id, result);
      }

      function updateIds(ids = EMPTY_LIST) {
        return ids.push(id);
      }

      return scales.update('byId', updateById).update('ids', updateIds);
    }

    return (data.availableScales || []).reduce(addScales, oldScales);
  }

  function updateSelected(oldSelected = EMPTY_MAP) {
    function addSelected(selected, id) {
      if (selected.hasIn(['set', id])) return selected;

      function updateSet(set = EMPTY_SET) {
        return set.add(id);
      }

      function updateList(list = EMPTY_LIST) {
        return list.push(id);
      }

      return selected.update('set', updateSet).update('list', updateList);
    }

    return (data.selectedOptions || []).reduce(addSelected, oldSelected);
  }

  return attribute
    .set('name', data.displayName)
    .set('property', property)
    .set('required', data.required)
    .set('type', getAttributeType(data))
    .update('options', updateOptions)
    .update('scales', updateScales)
    .update('selected', updateSelected);
}

export function updateMenuTaxonomyAttrubutes({ categories, channel, edited, menu }) {
  function reduceCategories(result, category) {
    const { availableAttributes = [] } = getAttribute({ category });

    function updateAttribute({ property, scale }) {
      function updateCategories(attributeCategories = EMPTY_SET) {
        return attributeCategories.has(category) ? attributeCategories : attributeCategories.add(category);
      }

      return function update(menuItem) {
        const attribute = getTaxonomyAttribute({ attribute: menuItem, category, property, scale });
        const attributeCategories = updateCategories(attribute.get('categories'));
        let filters = EMPTY_MAP;

        if (!edited) {
          filters = filters.set(FILTER[channel].TAXONOMY_IDS, attributeCategories.toList().map(shapeId));
        }

        return attribute
          .delete('required')
          .set('categories', attributeCategories)
          .set('filters', filters)
          .set('selected', EMPTY_MAP);
      };
    }

    for (const item of availableAttributes) {
      const { id: property, scaleId: scale } = item;
      const menuItem = shapeId(property);

      if (result.byId.has(menuItem)) {
        result.byId = result.byId.update(menuItem, updateAttribute({ property, scale }));
      } else {
        const attribute = MENU_ITEMS[channel].get(MENU_ITEM.TAXONOMY_ATTRIBUTES);
        result.byId = result.byId.set(menuItem, updateAttribute({ property, scale })(attribute));
        result.ids = result.ids.push(menuItem);
      }
    }

    return result;
  }

  const initial = {
    byId: menu.get('items'),
    ids: menu.getIn(['sections', 'items', MENU_ITEM.TAXONOMY_ATTRIBUTES], EMPTY_LIST),
  };

  const { byId, ids } = categories.reduce(reduceCategories, initial);

  return menu.set('items', byId).setIn(['sections', 'items', MENU_ITEM.TAXONOMY_ATTRIBUTES], ids);
}

export function clearMenuTaxonomyAttrubutes(menu) {
  function reduceTaxonomyItems(items, item) {
    return items.delete(item);
  }

  return menu
    .setIn(['sections', 'items', MENU_SECTION.TAXONOMY_ATTRIBUTES], EMPTY_LIST)
    .set('items',
      menu
        .getIn(['sections', 'items', MENU_SECTION.TAXONOMY_ATTRIBUTES], EMPTY_LIST)
        .reduce(reduceTaxonomyItems, menu.get('items'))
    );
}
