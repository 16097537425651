import { Map, Set } from 'immutable';

import { getAttribute, getName, getUiState } from '../taxonomy';

import { CATEGORY, VARIATIONS } from '../../constants/attributes';
import { DEFAULTS, NONE } from '../../constants';
import { PLACEHOLDERS } from '../../constants/product';

export function getOversetAttributes(product) {
  function reduceVariations(result, variation) {
    return result.add(variation.get('property'));
  }

  return product
    .getIn([VARIATIONS, VARIATIONS], DEFAULTS.EMPTY_LIST)
    .reduce(reduceVariations, Set());
}

export function getAttributesForCategory({ category, taxonomyAttributes }) {
  if (!category) return { taxonomyAttributes };

  const { availableAttributes: attributes = [] } = getUiState({ taxonomyId: category }, false);

  if (!attributes.length) {
    return taxonomyAttributes.size
      ? { changed: true, taxonomyAttributes: Map() }
      : { taxonomyAttributes };
  }

  function filterValueIds(options) {
    return function filter(valueId) {
      function findOption({ id }) {
        return id === valueId;
      }

      return options.findIndex(findOption) !== -1;
    };
  }

  function findScale(scaleId) {
    return function find({ id }) {
      return id === scaleId;
    };
  }

  function filterEmpty(values) {
    return (
      values.get('scaleId', DEFAULTS.NULL) ||
      values.get('values', DEFAULTS.EMPTY_LIST).size ||
      values.get('valueIds', DEFAULTS.EMPTY_LIST).size
    );
  }

  function reduceAttributes(result, attribute) {
    const { id: propertyId } = attribute;

    if (result.previous.has(propertyId)) {
      const valueIds = result.previous.getIn([propertyId, 'valueIds'], DEFAULTS.EMPTY_LIST);
      const scaleId = result.previous.getIn([propertyId, 'scaleId'], DEFAULTS.NULL);
      const { availableOptions, availableScales } = getAttribute({
        category,
        property: propertyId,
        scale: scaleId,
      });

      const newScaleId = !scaleId && !availableScales.length
        ? scaleId
        : availableScales.find(findScale(scaleId))?.id || DEFAULTS.NULL;

      if (newScaleId !== scaleId) {
        result.changed = true;
      } else {
        const newValueIds = valueIds.filter(filterValueIds(availableOptions));

        if (newValueIds.size !== valueIds.size) {
          result.changed = true;
        } else {
          result.current = result.current.set(propertyId, result.previous.get(propertyId));
          result.previous = result.previous.delete(propertyId);
        }
      }
    }

    return result;
  }

  const { changed, current, previous } = attributes.reduce(reduceAttributes, {
    previous: taxonomyAttributes,
    current: Map(),
  });

  return { changed: changed || !!previous.filter(filterEmpty).size, taxonomyAttributes: current };
}

export const messages = {
  definedByCategory(attribute) {
    return `${attribute} defined by category`;
  },
  definedByVariations(attribute) {
    return `${attribute} defined by variations`;
  },
  notAvailable({ attribute, category: taxonomyId }) {
    const category = getName(taxonomyId);
    return category === NONE || category === PLACEHOLDERS[CATEGORY]
      ? `${attribute} is not available`
      : `${attribute} is not available for ${category}`;
  },
};
