import { Map, Set } from 'immutable';

import { getName, getTopTaxonomyId } from '../taxonomy';
import { sortCaseIgnore } from '../array';
import { isPublished } from '../listings/statuses';
import { shapeNumber } from '../listings/listings';
import { getSize } from '../iterable/getSize';
import { reduce } from '../iterable/reduce';

import { CHANNEL_FILTERS, FILTER, FILTER_API_KEY, FILTER_NAME, PROFILE_TYPES, STATUS } from '../../constants/listings';
import { DEFAULTS, FALSE, TRUE } from '../../constants';
import { THEME } from '../../constants/theme';

const { EMPTY_LIST, EMPTY_MAP, MINUS_TWO } = DEFAULTS;

export function getDefaultFilters() {
  return Map({ selected: EMPTY_MAP });
}

export function getDefaultStatuses() {
  return Map({ selected: STATUS.ACTIVE });
}

export function shapeFiltersForApp({ channel, filters, response, shopData, status }) {
  function shapeValues({ filter, type, values = {}}) {
    function addValues(result, item) {
      const { id, ...rest } = item;

      return result
        .update('byId', function updateById(byId) {
          return byId.set(id, Map(rest));
        })
        .update('ids', function updateIds(ids) {
          return ids.push(id);
        });
    }

    function getValues(result, count, value) {
      result.push({ count: shapeNumber(count), id: value, name: value, value });

      return result;
    }

    function sortAlphabetically(x, y) {
      return sortCaseIgnore(x.name, y.name);
    }

    function sortByCount(x, y) {
      return x.count === y.count
        ? sortCaseIgnore(x.name, y.name)
        : y.count - x.count;
    }

    function reduceCategories(result, count, category) {
      const id = getTopTaxonomyId(category);

      if (id === 0) return result;

      const index = result.findIndex(function findSame(item) {
        return item.id === id;
      });

      if (index === -1) {
        result.push({ count: shapeNumber(count), id, name: getName(id), value: Set([category]) });
      } else {
        result[index].count += shapeNumber(count);
        result[index].value = result[index].value.add(category);
      }

      return result;
    }

    function reduceCollections(result, count, id) {
      const name = shopData.getIn(['collections', 'byId', id, 'label']);
      result.push({ count: shapeNumber(count), id, name, value: id });

      return result;
    }

    function reduceProfiles(result, count, id) {
      if (!shopData.hasIn(['profiles', type, 'byId', id])) return result;

      const name = shopData.getIn(['profiles', type, 'byId', id, 'name']);

      result.push({ count: shapeNumber(count), id, name, value: id });

      return result;
    }

    function reduceSections(result, count, id) {
      const name = shopData.getIn(['sections', 'byId', id]);

      if (name) {
        result.push({ count: shapeNumber(count), id, name, value: id });
      }

      return result;
    }

    const result = Map({ byId: EMPTY_MAP, ids: EMPTY_LIST });

    switch (filter) {
      case FILTER.CATEGORY: {
        if (!getSize(values)) return result;

        return reduce(values, [], reduceCategories).sort(sortByCount).reduce(addValues, result);
      }

      case FILTER.COLLECTIONS: {
        if (!getSize(values)) return result;

        return reduce(values, [], reduceCollections).sort(sortByCount).reduce(addValues, result);
      }

      case FILTER.EXISTING: {
        return result;
      }

      case FILTER.IS_COMPLETE: {
        const options = [
          {
            count: shapeNumber(values[TRUE]),
            id: TRUE,
            indicator: THEME.COLORS.CLOVER,
            name: 'Complete',
            value: TRUE,
          },
          {
            count: shapeNumber(values[FALSE]),
            id: FALSE,
            indicator: THEME.COLORS.ROUGE,
            name: 'Incomplete',
            value: FALSE,
          },
        ];

        return options.reduce(addValues, Map({ byId: EMPTY_MAP, ids: EMPTY_LIST }));
      }

      case FILTER.PROFILE: {
        if (!getSize(values)) return result;

        const { [MINUS_TWO]: count, ...rest } = values;

        if (!getSize(rest)) return result;

        const options = reduce(rest, [], reduceProfiles).sort(sortByCount);

        if (!getSize(options)) return result;

        if (shapeNumber(count)) {
          options.push(
            {
              count: shapeNumber(count),
              id: MINUS_TWO,
              name: `No ${FILTER_NAME[FILTER.PROFILE]}`,
              value: MINUS_TWO,
            },
          );
        }

        return options.reduce(addValues, result);
      }

      case FILTER.SECTION: {
        if (!getSize(values)) return result;

        return reduce(values, [], reduceSections).sort(sortByCount).reduce(addValues, result);
      }

      case FILTER.TAGS: {
        if (!getSize(values)) return result;

        return reduce(values, [], getValues).sort(sortAlphabetically).reduce(addValues, result);
      }

      default: {
        if (!getSize(values)) return result;

        return reduce(values, [], getValues).sort(sortByCount).reduce(addValues, result);
      }
    }
  }

  function reduceChannelFilters(source, filter) {
    if (filter === FILTER.IS_COMPLETE && isPublished(status)) return source;

    let result = source;

    switch (filter) {
      case FILTER.PROFILE: {
        let values = EMPTY_MAP;

        for (const type of PROFILE_TYPES) {
          const shaped = shapeValues({
            filter: FILTER.PROFILE,
            type,
            values: response[FILTER_API_KEY[FILTER.PROFILE][type]],
          });

          if (!getSize(shaped.get('ids'))) {
            result = result.deleteIn(['selected', FILTER.PROFILE, type]);
            continue;
          }

          values = values.set(type, shaped);
        }

        if (!getSize(values)) {
          return result.deleteIn(['selected', FILTER.PROFILE]);
        }

        result = result
          .setIn(['values', filter], values)
          .update('options', function updateOptions(options) {
            return options.push(filter);
          });

        break;
      }

      default: {
        const values = shapeValues({ filter, values: response[FILTER_API_KEY[filter]] });
        const size = getSize(values.get('ids'));

        if (!size) return result;

        result = result
          .setIn(['values', filter], values)
          .update('options', function updateOptions(options) {
            return options.push(filter);
          });

        break;
      }
    }

    return result;
  }

  return CHANNEL_FILTERS[channel].reduce(
    reduceChannelFilters,
    filters.set('options', EMPTY_LIST).set('values', EMPTY_MAP),
  );
}

export function shapeFiltersForAPI({ filters, status }) {
  function reduceFilters(result, value, key) {
    switch (key) {
      case FILTER.EXISTING: {
        break;
      }

      case FILTER.IS_COMPLETE: {
        if (!isPublished(status) && getSize(value) === 1) {
          result[FILTER_API_KEY[key]] = value.toList().get(0);
        }

        break;
      }

      case FILTER.PROFILE: {
        value.forEach(function forEachType(ids, type) {
          result[FILTER_API_KEY[key][type]] = ids.toArray();
        });

        break;
      }

      case FILTER.TITLE: {
        if (getSize(value)) {
          result[FILTER_API_KEY[key]] = value;
        }

        break;
      }

      default: {
        if (getSize(value)) {
          result[FILTER_API_KEY[key]] = value.toArray();
        }

        break;
      }
    }

    return result;
  }

  const initial = {
    [FILTER_API_KEY[FILTER.STATUS]]: status,
  };

  if (status === STATUS.UNPUBLISHED) {
    initial[FILTER_API_KEY[FILTER.EXISTING]] = FALSE;
  }

  return filters.reduce(reduceFilters, initial);
}
