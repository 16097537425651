import { ETSY, SHOPIFY } from './channels';
import { MENU_ITEM } from './bulkEdit';

export const UPDATES_PER_PAGE = 20;

export const TIMER = {
  COMPLETE: 'complete',
  SOON: 'soon',
  NOT_SO_SOON: 'not-so-soon',
};

export const UPDATE = {
  COMPLETE: 'complete',
  PENDING: 'pending',
};

export const FILTER_NAME = {
  [UPDATE.PENDING]: 'Scheduled',
  [UPDATE.COMPLETE]: 'Complete',
};

export const COLUMN = {
  CREATED: 'created',
  PRODUCTS: 'products',
  STARTS: 'starts',
  SCHEDULED: 'scheduled',
  UPDATES: 'updates',
};

export const COLUMNS = {
  [COLUMN.UPDATES]: {
    name: 'Updates',
    className: COLUMN.UPDATES,
  },
  [COLUMN.CREATED]: {
    name: 'Created',
    className: COLUMN.CREATED,
  },
  [COLUMN.PRODUCTS]: {
    name: 'Products',
    className: COLUMN.PRODUCTS,
  },
  [COLUMN.STARTS]: {
    name: 'Starts in',
    className: COLUMN.STARTS,
  },
  [COLUMN.SCHEDULED]: {
    name: 'Scheduled',
    className: COLUMN.SCHEDULED,
  },
};

export const COLUMN_ORDER = [
  COLUMN.UPDATES,
  COLUMN.CREATED,
  COLUMN.PRODUCTS,
  COLUMN.STARTS,
  COLUMN.SCHEDULED,
];

export const FIELD_TO_MENU_ITEM_MAP = {
  [ETSY]: {
    attributes: MENU_ITEM.TAXONOMY_ATTRIBUTES,
    description: MENU_ITEM.DESCRIPTION,
    files: MENU_ITEM.FILES,
    isPersonalizable: MENU_ITEM.PERSONALIZATION,
    is_supply: MENU_ITEM.ABOUT,
    itemHeight: MENU_ITEM.ITEM_SIZE,
    itemLength: MENU_ITEM.ITEM_SIZE,
    itemWeight: MENU_ITEM.ITEM_WEIGHT,
    itemWidth: MENU_ITEM.ITEM_SIZE,
    materials: MENU_ITEM.MATERIALS,
    personalizationCharCountMax: MENU_ITEM.PERSONALIZATION,
    personalizationInstructions: MENU_ITEM.PERSONALIZATION,
    personalizationIsRequired: MENU_ITEM.PERSONALIZATION,
    photos: MENU_ITEM.PHOTOS,
    priceInventory: MENU_ITEM.PRICE,
    productionPartners: MENU_ITEM.PRODUCTION_PARTNER,
    quantityInventory: MENU_ITEM.QUANTITY,
    section: MENU_ITEM.SECTION,
    shipping_template_id: MENU_ITEM.SHIPPING_PROFILE,
    skuInventory: MENU_ITEM.SKU,
    tags: MENU_ITEM.TAGS,
    taxonomy: MENU_ITEM.CATEGORY,
    title: MENU_ITEM.TITLE,
    variationsInventory: MENU_ITEM.VARIATIONS,
    when_made: MENU_ITEM.ABOUT,
    who_made: MENU_ITEM.ABOUT,
  },
  [SHOPIFY]: {
    barcodeInventory: MENU_ITEM.BARCODE,
    body_html: MENU_ITEM.DESCRIPTION,
    collection_ids: MENU_ITEM.COLLECTIONS,
    comapreAtPriceInventory: MENU_ITEM.CAP,
    countryCode: MENU_ITEM.COUNTRY_CODE,
    costInventory: MENU_ITEM.CPI,
    description: MENU_ITEM.DESCRIPTION, // for paywall placeholders
    hsCode: MENU_ITEM.HS_CODE,
    photos: MENU_ITEM.PHOTOS,
    physical: 'Physical',
    priceInventory: MENU_ITEM.PRICE,
    product_type: MENU_ITEM.PRODUCT_TYPE,
    quantityInventory: MENU_ITEM.QUANTITY,
    skuInventory: MENU_ITEM.SKU,
    tags: MENU_ITEM.TAGS,
    title: MENU_ITEM.TITLE,
    vendor: MENU_ITEM.VENDOR,
    variationsInventory: MENU_ITEM.VARIATIONS,
    weight: MENU_ITEM.WEIGHT,
  },
};

export const INFO = 'Once implemented, schedule updates will remain in place until overridden by another update.';

export const ERRORS = {
  SCHEDULED_OVER_MONTH: 'Scheduled date should be less than in a month from now',
  SCHEDULED_IN_PAST: 'Scheduled time is in the past',
};
