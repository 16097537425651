/* eslint-disable no-console */
import { sideEffect } from 'redux-side-effects';
import { fromJS, List, Map } from 'immutable';
import { captureException } from '@sentry/react';

import { getDiscountedPrice, isSubscribed } from '../utils/billing';
import { createController } from '../utils/reducer';
import { navigateTo } from '../utils/navigation';
import { getSize } from '../utils/iterable/getSize';
import api from '../utils/api';

import { MESSAGE, NOTIFICATION } from '../constants/notifications';
import { PLAN, TIER } from '../constants/billing';
import { STATUSES } from '../constants/shops';
import { ENDPOINT } from '../constants/api';
import { MODALS } from '../constants/modal';
import { ETSY } from '../constants/channels';
import ACTIONS from '../constants/actions';

import Reducers, { Reducer } from '../classes/Reducer';
import Actions from '../actions';

const customerController = createController();
const invoicesController = createController();
const shopsController = createController();
const manageController = createController();

function abort(controller) {
  if (controller.isActive()) {
    controller.stop();
  }
}

function abortAll() {
  [
    customerController,
    invoicesController,
    shopsController,
    manageController,
  ].forEach(abort);
}

function* cleanUp(reduction) {
  abortAll();
  return reduction.set('billing', Map());
}

function* getCustomer(reduction) {
  abort(customerController);

  yield sideEffect((dispatch) => {
    const { signal } = customerController.start();
    api.billing
      .getCustomer({ signal })
      .then(
        (response) => dispatch(Actions.Billing.getCustomerSucceeded(response)),
        (error) => dispatch(Actions.Billing.getCustomerFailed({ error, signal })),
      );
  });

  return reduction;
}

function* getCustomerFailed(reduction, { error, signal }) {
  if (signal.aborted) return reduction;

  abort(customerController);

  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_DATA,
      })
    );
  });

  return reduction;
}

function* getCustomerSucceeded(reduction, response) {
  abort(customerController);

  let billing = reduction.get('billing');

  if (billing.has('paymentError')) {
    billing = billing.delete('paymentError');
  }

  billing = billing.set('customer', fromJS(response));

  return reduction.set('billing', billing);
}

function* getInvoices(reduction) {
  function reduceShopIds(result, shopId) {
    const subscriptionId = reduction.getIn(['user', 'subscriptions', shopId, 'subscriptionId']);

    if (!subscriptionId) return result;

    const shop = reduction.getIn(['shops', 'byId', shopId]);

    return result.push(
      Map({
        channel: shop.get('channel'),
        domain: shop.get('domain'),
        db: shop.get('db'),
        shopId,
        shopName: shop.get('name'),
        subscriptionId,
      })
    );
  }

  const tabs = reduction.getIn(['shops', 'options']).reduce(reduceShopIds, List());

  if (getSize(tabs)) {
    yield sideEffect((dispatch) => {
      dispatch(Actions.Billing.setInvoicesTab(0));
    });
  }

  return reduction.setIn(['billing', 'invoices'], Map({ tabs }));
}

function* getShops(reduction) {
  abort(shopsController);

  const empty = Map({ amount: 0, byId: Map(), connected: 0, ids: List(), upgraded: 0 });
  const subscriptions = reduction.getIn(['user', 'subscriptions']);

  if (!getSize(subscriptions)) return reduction.setIn(['billing', 'shops'], empty);

  const userShops = reduction.get('shops');
  const { signal } = shopsController.start();

  function reduceShopIds(result, shopId) {
    const subscription = reduction.getIn(['user', 'subscriptions', shopId]);

    if (!subscription) return result;

    const plan = subscription.get('plan');
    const shop = userShops.getIn(['byId', shopId]);

    switch (plan) {
      case PLAN.TRIAL: {
        const channel = shop.get('channel');
        const db = shop.get('db');
        const shopName = shop.get('name');
        const discount = subscription.get('discountPercent');
        const trialRemaining = subscription.get('trialRemaining');
        const disabled = trialRemaining < 1;

        result.actions.push(
          Actions.Billing.getShop({
            channel,
            db,
            disabled,
            discount,
            dueDate: null,
            plan,
            shopId,
            shopName,
            signal,
            subscribed: false,
            trialRemaining,
          })
        );

        result.shops = result.shops.set('ids', result.shops.get('ids').push(shopId));
        break;
      }

      case 'lite':
      case 'plus':
      case 'Vela Lite':
      case 'Vela Plus': {
        const channel = shop.get('channel');
        const db = shop.get('db');
        const shopName = shop.get('name');
        const dueDate = subscription.get('dueDate');
        const price = subscription.get('price');

        result.actions.push(
          Actions.Billing.getShop({
            channel,
            db,
            dueDate,
            shopId,
            shopName,
            signal,
            subscribed: true,
          })
        );

        result.shops = result.shops
          .set('ids', result.shops.get('ids').push(shopId))
          .set('amount', result.shops.get('amount') + price);

        break;
      }

      case TIER.T_0:
      case TIER.T_251:
      case TIER.T_501:
      case TIER.T_1001:
      case TIER.T_2501:
      case TIER.T_5001:
      case TIER.T_7501:
      case TIER.T_10001:
      case TIER.T_20001:
      case TIER.T_30001:
      case TIER.T_40001:
      case TIER.T_50001:
      case TIER.T_60001:
      case TIER.T_70001: {
        const channel = shop.get('channel');
        const db = shop.get('db');
        const shopName = shop.get('name');
        const cancelAtPeriodEnd = subscription.get('cancelAtPeriodEnd');
        const tierChangeDetected = subscription.get('tierChangeDetected');
        const subscribed = !cancelAtPeriodEnd && !tierChangeDetected;
        const dueDate = subscribed ? subscription.get('dueDate') : undefined;

        result.actions.push(
          Actions.Billing.getShop({
            canceled: !!cancelAtPeriodEnd,
            channel,
            db,
            dueDate,
            plan,
            shopId,
            shopName,
            signal,
            subscribed,
          })
        );

        if (subscribed) {
          const discount = subscription.get('discountPercent');
          const price = subscription.get('price');

          result.shops = result.shops.set('amount',
            result.shops.get('amount') + getDiscountedPrice({ discount, price })
          );
        }

        result.shops = result.shops.set('ids', result.shops.get('ids').push(shopId));
        break;
      }

      default: {
        const db = shop.get('db');

        captureException('Unexpected shop plan', {
          extra: {
            subscription: subscription.toJS(),
          },
          tags: {
            userId: reduction.getIn(['user', 'userId']),
            shopId,
            db,
          },
        });

        break;
      }
    }

    return result;
  }

  const { actions, shops } = userShops.get('options').reduce(reduceShopIds, { actions: [], shops: empty });

  if (getSize(actions)) {
    yield sideEffect((dispatch) => {
      actions.forEach(dispatch);
    });
  }

  return reduction.setIn(['billing', 'shops'], shops);
}

function* getShop(reduction, { signal, ...shop }) {
  yield sideEffect((dispatch) => {
    api.billing
      .getSubscription({ db: shop.db, shopId: shop.shopId, signal })
      .then(
        (response) => {
          const {
            discount_percent: discount,
            price_without_discount_in_units: price,
            product_count: listings,
            tier_name: plan,
          } = response;

          dispatch(Actions.Billing.getShopSucceeded({ discount, plan, price, listings, ...shop }));
        },
        (error) => dispatch(Actions.Billing.getShopFailed({ error, shop, signal }))
      );
  });

  return reduction;
}

function* getShopFailed(reduction, { error, shop, signal }) {
  if (signal.aborted) return reduction;

  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_DATA,
      })
    );
  });

  return reduction.setIn(['billing', 'shops', 'byId', shop.shopId], Map(shop));
}

function* getShopSucceeded(reduction, shop) {
  let state = reduction.getIn(['billing', 'shops']);

  function addCount(count = 0) {
    return count + 1;
  }

  state = state
    .update('connected', addCount)
    .setIn(['byId', shop.shopId], Map(shop));

  if (shop.subscribed) {
    state = state.update('upgraded', addCount);
  }

  return reduction.setIn(['billing', 'shops'], state);
}

function* manageShop(reduction, { shopId, type, ...rest } = {}) {
  abort(manageController);

  switch (type) {
    case MODALS.BILLING.MANAGE: {
      if (!reduction.hasIn(['shops', 'byId', shopId])) return reduction;

      const shop = reduction.getIn(['shops', 'byId', shopId]);
      const channel = shop.get('channel');
      const db = shop.get('db');

      if (shop.get('syncStatus') === STATUSES.TOKEN_REJECTED) {
        if (isSubscribed(reduction.getIn(['user', 'susbscriptions', shopId]))) {
          rest.reconnect = ENDPOINT.CONNECT_CHANNEL[channel];
        } else {
          navigateTo(`/${shopId}`);
          return reduction;
        }
      }

      yield sideEffect((dispatch) => {
        const { signal } = manageController.start();
        api.billing
          .getSubscription({ db, shopId, signal })
          .then(
            (response) => dispatch(Actions.Billing.manageShopSucceeded({ response, shopId })),
            (error) => dispatch(Actions.Billing.manageShopFailed({ error, signal }))
          );
      });

      return reduction.setIn(['billing', 'manage'], Map({ channel, shopId, type, ...rest }));
    }

    case MODALS.BILLING.SUCCESS: {
      const shop = reduction.getIn(['shops', 'byId', shopId]);
      const channel = shop.get('channel');
      const shopName = shop.get('name');

      return reduction.setIn(['billing', 'manage'], Map({ channel, shopId, shopName, type, ...rest }));
    }

    default: {
      return reduction.deleteIn(['billing', 'manage']);
    }
  }
}

function* manageShopFailed(reduction, { error, signal }) {
  if (signal.aborted) return reduction;

  abort(manageController);

  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_DATA,
      })
    );
  });

  return reduction.deleteIn(['billing', 'manage']);
}

function* manageShopSucceeded(reduction, { response, shopId }) {
  abort(manageController);

  const {
    discount_percent: discount,
    product_count: listings,
    price_without_discount_in_units: price,
    tier_name: plan,
    sync_status: syncStatus,
  } = response;

  const shop = reduction.getIn(['shops', 'byId', shopId]);

  if (syncStatus === STATUSES.TOKEN_REJECTED && shop.get('syncStatus') !== syncStatus) {
    yield sideEffect((dispatch) => {
      dispatch(Actions.Shops.getShops());
      navigateTo(`/${shopId}`);
    });

    return reduction.setIn(['shops', 'byId', shopId, 'syncStatus'], syncStatus);
  }

  function updateData(data) {
    const channel = shop.get('channel');
    const db = shop.get('db');
    const name = shop.get('name');

    return data.set('shop',
      Map({
        channel,
        db,
        discount: parseInt(discount, 10) || 0,
        id: shopId,
        listings: listings || 0,
        name,
        price: price || 0,
        plan,
        syncStatus,
      })
    );
  }

  return reduction.updateIn(['billing', 'manage'], updateData);
}

function* setInvoicesTab(reduction, index) {
  abort(invoicesController);

  const tab = reduction.getIn(['billing', 'invoices', 'tabs', index]);

  switch (tab.get('channel')) {
    case ETSY: {
      const shopId = tab.get('shopId');

      if (reduction.hasIn(['billing', 'invoices', 'byShopId', shopId])) break;

      const db = tab.get('db');
      const subscriptionId = tab.get('subscriptionId');

      yield sideEffect((dispatch) => {
        const { signal } = invoicesController.start();
        api.billing
          .getInvoices({ db, shopId, signal, subscriptionId })
          .then(
            (response) => dispatch(Actions.Billing.setInvoicesTabSucceeded({ index, response, shopId })),
            (error) => dispatch(Actions.Billing.setInvoicesTabFailed({ error, signal }))
          );
      });

      break;
    }

    default: {
      break;
    }
  }

  return reduction.setIn(['billing', 'invoices', 'tab'], index);
}

function* setInvoicesTabFailed(reduction, { error, signal }) {
  if (signal.aborted) return reduction;

  abort(invoicesController);

  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_INVOICES,
      })
    );
  });

  return reduction;
}

function* setInvoicesTabSucceeded(reduction, { index, response, shopId }) {
  abort(invoicesController);

  if (getSize(response.invoices)) {
    return reduction.setIn(['billing', 'invoices', 'byShopId', shopId], fromJS(response.invoices));
  }

  const tabs = reduction.getIn(['billing', 'invoices', 'tabs']).delete(index);
  const tab = Math.min(index, getSize(tabs) - 1);

  if (getSize(tabs)) {
    yield sideEffect((dispatch) => {
      dispatch(Actions.Billing.setInvoicesTab(tab));
    });
  }

  return reduction
    .setIn(['billing', 'invoices', 'tab'], tab)
    .setIn(['billing', 'invoices', 'tabs'], tabs);
}

function* togglePaymentMethodModal(reduction, paymentError ) {
  let billing = reduction.get('billing');
  if (paymentError) {
    billing = billing.setIn(['paymentError'], paymentError);
  }

  billing = billing.getIn(['showPaymentMethodModal'])
    ? billing.deleteIn(['showPaymentMethodModal'])
    : billing.setIn(['showPaymentMethodModal'], true);

  return reduction.set('billing', billing);
}

Reducers.add(
  new Reducer('Billing')
    .add(ACTIONS.BILLING.GET_CUSTOMER, getCustomer)
    .add(ACTIONS.BILLING.GET_CUSTOMER_FAILED, getCustomerFailed)
    .add(ACTIONS.BILLING.GET_CUSTOMER_SUCCEEDED, getCustomerSucceeded)
    .add(ACTIONS.BILLING.CLEAN_UP, cleanUp)
    .add(ACTIONS.BILLING.GET_INVOICES, getInvoices)
    .add(ACTIONS.BILLING.GET_SHOPS, getShops)
    .add(ACTIONS.BILLING.GET_SHOP, getShop)
    .add(ACTIONS.BILLING.GET_SHOP_FAILED, getShopFailed)
    .add(ACTIONS.BILLING.GET_SHOP_SUCCEEDED, getShopSucceeded)
    .add(ACTIONS.BILLING.SET_INVOICES_TAB, setInvoicesTab)
    .add(ACTIONS.BILLING.SET_INVOICES_TAB_SUCCEEDED, setInvoicesTabSucceeded)
    .add(ACTIONS.BILLING.SET_INVOICES_TAB_FAILED, setInvoicesTabFailed)
    .add(ACTIONS.BILLING.MANAGE_SHOP, manageShop)
    .add(ACTIONS.BILLING.MANAGE_SHOP_FAILED, manageShopFailed)
    .add(ACTIONS.BILLING.MANAGE_SHOP_SUCCEEDED, manageShopSucceeded)
    .add(ACTIONS.BILLING.TOGGLE_PAYMENT_METHOD_MODAL, togglePaymentMethodModal)
);
