import ActionConstants, { Actions } from '../constants/actions';

const bulkEditConstants = new Actions('BulkEdit')
  .add('add_operation')
  .add('add_pending_updates')
  .add('add_preview')
  .add('ai_discard')
  .addAsync('ai_generate')
  .add('ai_generate_cancel')
  .add('apply_preview')
  .addAsync('bootstrap')
  .add('close_bulk_edit')
  .addAsync('get_categories')
  .addAsync('get_error_counts')
  .addAsync('get_filter_ids')
  .addAsync('get_invalid_products')
  .addAsync('get_photos_by_index')
  .addAsync('get_products')
  .addAsync('get_profile_by_id')
  .add('hide_countdown')
  .addAsync('remove_background')
  .add('remove_preview')
  .add('save_as')
  .addAsync('schedule_updates')
  .add('search')
  .add('select_operation')
  .add('set_banner')
  .add('set_filter_by_errors')
  .add('set_data')
  .add('set_modal')
  .add('set_page')
  .add('set_product')
  .addAsync('set_selection')
  .add('select_menu_item')
  .addAsync('sync_updates')
  .add('toggle_all_products')
  .add('toggle_product')
  .add('update_menu_for_category')
  .add('wait_for_operations');

ActionConstants.add(bulkEditConstants);

export default bulkEditConstants.actionCreators();
