import { Map } from 'immutable';

import { OPERATIONS } from '../../constants/photoEditor';

export function applyOperations(photo, operation) {
  switch (operation.get('type')) {
    case OPERATIONS.REMOVE_BACKGROUND: {
      return photo
        .set('backgroundRemoved', true)
        .set('background',
          Map({
            color: operation.getIn(['properties', 'bg_color']),
            image: operation.getIn(['properties', 'bg_image_url']),
          })
        );
    }

    default: {
      return photo;
    }
  }
}
