import { Map } from 'immutable';

export function shapePhotoForApp(result, image) {
  const { id: originalId, fullsize_url: url } = image;
  result.byId = result.byId.set(originalId, Map(image));

  if (result.originalsToRemoveBG.has(originalId)) {
    result.toRemoveBG = result.toRemoveBG.set(originalId,
      result.originalsToRemoveBG.get(originalId).set('url', url)
    );
  }

  return result;
}
