import { Map } from 'immutable';

import { PAGE_SIZE } from '../constants/listings';

export function getPageInfo(filters, total) {
  const offset = filters.get('offset') || 0;
  const limit = filters.get('limit') || PAGE_SIZE;

  return Map({
    total,
    pageSize: limit,
    from: offset + 1,
    currentPage: offset / limit,
    to: Math.min(offset + limit, total),
  });
}

export function getPagination({ page, pagination }) {
  return pagination.set('offset', page * pagination.get('limit'));
}
