import { isTruthy } from '../bool';
import { isObject } from '../isObject';
import { forEach } from '../iterable/forEach';
import { getSize } from '../iterable/getSize';
import { reduce } from '../iterable/reduce';

import { CONTENT_TYPE, ENDPOINT, ERROR_KEYS, HEADERS, STATUS } from '../../constants/api';
import { DEFAULTS, SEPARATOR } from '../../constants';
import { MESSAGE } from '../../constants/notifications';

export function processParams(params) {
  if (!isObject(params) || !getSize(params)) return DEFAULTS.EMPTY_STRING;

  function reduceParams(search, value, key) {
    function addParam(param) {
      if (key in search) {
        if (Array.isArray(search[key])) {
          search[key].push(encodeURIComponent(param));
        } else {
          search[key] = [search[key], encodeURIComponent(param)];
        }
      } else {
        search[key] = encodeURIComponent(param);
      }
    }

    function filterParam(param) {
      return (
        isTruthy(param) ||
        typeof param === 'boolean' ||
        Number.isFinite(param)
      );
    }

    function forEachParam(paramValue, paramKey) {
      if (isTruthy(paramValue)) {
        if (!Array.isArray(search[key])) {
          search[key] = [];
        }

        addParam(paramKey);
      }
    }

    if (typeof value === 'boolean' || Number.isFinite(value)) {
      addParam(value);
    } else if (typeof value === 'string') {
      const trimedValue = value.replace(/\s+/g, SEPARATOR.SPACE).trim();

      if (trimedValue) {
        addParam(trimedValue);
      }
    } else if (Array.isArray(value)) {
      const filtered = value.filter(filterParam);

      if (getSize(filtered)) {
        if (!Array.isArray(search[key])) {
          search[key] = [];
        }

        filtered.forEach(addParam);
      }
    } else if (isObject(value)) {
      if (getSize(value)) {
        forEach(value, forEachParam);
      }
    }

    return search;
  }

  function reduceSearch(query, value, key) {
    function mapParams(param) {
      return `${key}[]=${param}`;
    }

    if (Array.isArray(value)) {
      if (getSize(value)) {
        query.push(...value.map(mapParams));
      }
    } else {
      query.push(`${key}=${value}`);
    }

    return query;
  }

  const search = reduce(params, {}, reduceParams);

  return getSize(search)
    ? `?${reduce(search, [], reduceSearch).join('&')}`
    : DEFAULTS.EMPTY_STRING;
}

function getErrorMessage(response) {
  let message = MESSAGE.FAIL.FETCH;

  if (typeof response === 'string') {
    if (getSize(response)) {
      message = response;
    }
  } else if (isObject(response)) {
    if (
      response.success === false &&
      response.payload === null &&
      Array.isArray(response.errors)
    ) {
      message = response.errors[0];
    } else {
      for (const key of ERROR_KEYS) {
        if (response.hasOwnProperty(key)) {
          message = response[key];
          break;
        }
      }
    }
  }

  return message;
}

function call({
  credentials = true,
  doNotLogout = false,
  endpoint,
  headers = {},
  method,
  params,
  payload,
  signal,
  url,
}) {
  async function handleResponse(response) {
    if (!doNotLogout && response.status === STATUS.UNAUTHORIZED) {
      window.location = ENDPOINT.LOGOUT;
      return Promise.reject(MESSAGE.FAIL.UNAUTHORIZED);
    }

    let result;
    const contentType = response.headers.get(HEADERS.CONTENT_TYPE);

    if (/^application\/json/.test(contentType)) {
      try {
        result = await response.json();
      } catch (error) {
        result = {};
      }
    } else if (/^text/.test(contentType)) {
      result = await response.text();
    } else {
      result = await response.blob();
    }

    return response.ok
      ? Promise.resolve(result)
      : Promise.reject(getErrorMessage(result));
  }

  function catchError(error) {
    return Promise.reject(error);
  }

  try {
    let source = endpoint || /^\/[^\/]/.test(url)
      ? `${endpoint || ENDPOINT.WEB}${url}`
      : url;

    if (payload && !(payload instanceof FormData) && !(HEADERS.CONTENT_TYPE in headers)) {
      headers[HEADERS.CONTENT_TYPE] = CONTENT_TYPE.JSON;
    }

    const options = {
      cache: 'no-cache',
      headers: new Headers(headers),
      method,
      mode: 'cors',
      signal,
    };

    if (credentials) {
      options.credentials = 'include';
    }

    if (params) {
      source += `${processParams(params)}`;
    }

    if (payload) {
      options.body = options.headers.get(HEADERS.CONTENT_TYPE) === CONTENT_TYPE.JSON
        ? JSON.stringify(payload)
        : payload;
    }

    const request = new Request(source, options);

    return fetch(request).then(handleResponse).catch(catchError);
  } catch (error) {
    return Promise.reject(error);
  }
}

export default {
  get(props) {
    return call({ method: 'GET', ...props });
  },
  patch(props) {
    return call({ method: 'PATCH', ...props });
  },
  put(props) {
    return call({ method: 'PUT', ...props });
  },
  post(props) {
    return call({ method: 'POST', ...props });
  },
  push(props) {
    return call({ method: 'PUSH', ...props });
  },
  delete(props) {
    return call({ method: 'DELETE', ...props });
  },
};
