import { List, Map } from 'immutable';

import { ETSY, SHOPIFY } from './channels';
import { DEFAULTS } from '.';
import { NEW } from './product';
import ATTRIBUTES from './attributes';

export const THUMBNAIL_SIZE = 50;

export const ADD = 'add';

export const FIND = 'find';

export const REPLACE = 'replace';

export const DELETE = 'delete';

export const FILTER = {
  [ETSY]: {
    IS_DIGITAL: 'is_digital',
    TAXONOMY_IDS: 'taxonomy_ids',
    TITLE: 'title',
  },
  [SHOPIFY]: {
    TITLE: 'title',
  },
};

export const MENU_SECTION = {
  INVENTORY: 'Inventory',
  LISTINGS: 'Listings',
  MEDIA: 'Media',
  SEO: 'SEO listing',
  SHIPPING: 'Shipping',
  TAXONOMY_ATTRIBUTES: 'Optional',
};

export const MENU_ITEM = {
  ABOUT: 'About',
  BARCODE: 'Barcode',
  BODY_HTML: 'Description',
  CATEGORY: 'Category',
  CAP: 'Compare at price',
  COLLECTIONS: 'Collections',
  COUNTRY_CODE: 'Country or Region',
  CPI: 'Cost',
  DESCRIPTION: 'Description',
  FILES: 'Digital files',
  HS_CODE: 'HS Code',
  MATERIALS: 'Materials',
  META_DESCRIPTION: 'Meta description',
  PAGE_TITLE: 'Page title',
  PERSONALIZATION: 'Personalization',
  PHOTOS: 'Photos',
  VIDEOS: 'Videos',
  PRICE: 'Price',
  PRODUCTION_PARTNER: 'Production partner',
  PRODUCT_TYPE: 'Product type',
  QUANTITY: 'Quantity',
  RETURN_POLICY: 'Return policy',
  SECTION: 'Section',
  SHIPPING_PROFILE: 'Shipping profile',
  SKU: 'SKU',
  TAGS: 'Tags',
  TAXONOMY_ATTRIBUTES: 'Optional',
  TITLE: 'Title',
  URL_HANDLE: 'URL handle',
  VARIATIONS: 'Variations',
  VENDOR: 'Vendor',
  WEIGHT: 'Weight',
  ITEM_SIZE: 'Item size',
  ITEM_WEIGHT: 'Item weight',
};

export const ATTRIBUTE_TO_MENU_ITEM_MAP = {
  [ATTRIBUTES.BARCODE]: MENU_ITEM.BARCODE,
  [ATTRIBUTES.CAP]: MENU_ITEM.CAP,
  [ATTRIBUTES.CATEGORY]: MENU_ITEM.CATEGORY,
  [ATTRIBUTES.CHAR_COUNT_MAX]: MENU_ITEM.PERSONALIZATION,
  [ATTRIBUTES.COLLECTIONS]: MENU_ITEM.COLLECTIONS,
  [ATTRIBUTES.COUNTRY_CODE]: MENU_ITEM.COUNTRY_CODE,
  [ATTRIBUTES.CPI]: MENU_ITEM.CPI,
  [ATTRIBUTES.FILES]: MENU_ITEM.FILES,
  [ATTRIBUTES.HEIGHT]: MENU_ITEM.ITEM_SIZE,
  [ATTRIBUTES.HS_CODE]: MENU_ITEM.HS_CODE,
  [ATTRIBUTES.INSTRUCTIONS]: MENU_ITEM.PERSONALIZATION,
  [ATTRIBUTES.IS_SUPPLY]: MENU_ITEM.ABOUT,
  [ATTRIBUTES.LENGTH]: MENU_ITEM.ITEM_SIZE,
  [ATTRIBUTES.MATERIALS]: MENU_ITEM.MATERIALS,
  [ATTRIBUTES.META_DESCRIPTION]: MENU_ITEM.META_DESCRIPTION,
  [ATTRIBUTES.PAGE_TITLE]: MENU_ITEM.PAGE_TITLE,
  [ATTRIBUTES.PERSONALIZATION]: MENU_ITEM.PERSONALIZATION,
  [ATTRIBUTES.PHOTOS]: MENU_ITEM.PHOTOS,
  [ATTRIBUTES.PRICE]: MENU_ITEM.PRICE,
  [ATTRIBUTES.PRODUCTION_PARTNERS]: MENU_ITEM.PRODUCTION_PARTNER,
  [ATTRIBUTES.PRODUCT_TYPE]: MENU_ITEM.PRODUCT_TYPE,
  [ATTRIBUTES.PROFILE_ID]: MENU_ITEM.SHIPPING_PROFILE,
  [ATTRIBUTES.QUANTITY]: MENU_ITEM.QUANTITY,
  [ATTRIBUTES.RETURN_POLICY]: MENU_ITEM.RETURN_POLICY,
  [ATTRIBUTES.SECTION]: MENU_ITEM.SECTION,
  [ATTRIBUTES.SKU]: MENU_ITEM.SKU,
  [ATTRIBUTES.TAGS]: MENU_ITEM.TAGS,
  [ATTRIBUTES.TAXONOMY_ATTRIBUTES]: MENU_ITEM.TAXONOMY_ATTRIBUTES,
  [ATTRIBUTES.TITLE]: MENU_ITEM.TITLE,
  [ATTRIBUTES.URL_HANDLE]: MENU_ITEM.URL_HANDLE,
  [ATTRIBUTES.VARIATIONS]: MENU_ITEM.VARIATIONS,
  [ATTRIBUTES.VENDOR]: MENU_ITEM.VENDOR,
  [ATTRIBUTES.WEIGHT]: MENU_ITEM.WEIGHT,
  [ATTRIBUTES.WHEN_MADE]: MENU_ITEM.ABOUT,
  [ATTRIBUTES.WHO_MADE]: MENU_ITEM.ABOUT,
  [ATTRIBUTES.WIDTH]: MENU_ITEM.ITEM_SIZE,
};

export const MENU_SECTIONS = {
  [ETSY]: List([
    MENU_SECTION.MEDIA,
    MENU_SECTION.LISTINGS,
    MENU_SECTION.TAXONOMY_ATTRIBUTES,
    MENU_SECTION.INVENTORY,
    MENU_SECTION.SHIPPING,
  ]),
  [SHOPIFY]: List([
    MENU_SECTION.MEDIA,
    MENU_SECTION.LISTINGS,
    MENU_SECTION.INVENTORY,
    MENU_SECTION.SHIPPING,
    MENU_SECTION.SEO,
  ]),
};

export const MENU_SECTION_ITEMS = {
  [ETSY]: Map({
    [MENU_SECTION.INVENTORY]: List([
      MENU_ITEM.VARIATIONS,
      MENU_ITEM.PRICE,
      MENU_ITEM.QUANTITY,
      MENU_ITEM.SKU,
    ]),
    [MENU_SECTION.LISTINGS]: List([
      MENU_ITEM.TITLE,
      MENU_ITEM.DESCRIPTION,
      MENU_ITEM.TAGS,
      MENU_ITEM.MATERIALS,
      MENU_ITEM.ABOUT,
      MENU_ITEM.PRODUCTION_PARTNER,
      MENU_ITEM.CATEGORY,
      MENU_ITEM.SECTION,
      MENU_ITEM.PERSONALIZATION,
    ]),
    [MENU_SECTION.MEDIA]: List([
      MENU_ITEM.PHOTOS,
      MENU_ITEM.VIDEOS,
    ]),
    [MENU_SECTION.SHIPPING]: List([
      MENU_ITEM.SHIPPING_PROFILE,
      MENU_ITEM.ITEM_WEIGHT,
      MENU_ITEM.ITEM_SIZE,
      MENU_ITEM.RETURN_POLICY,
    ]),
    [MENU_SECTION.TAXONOMY_ATTRIBUTES]: DEFAULTS.EMPTY_LIST,
  }),
  [SHOPIFY]: Map({
    [MENU_SECTION.INVENTORY]: List([
      MENU_ITEM.VARIATIONS,
      MENU_ITEM.PRICE,
      MENU_ITEM.CAP,
      MENU_ITEM.CPI,
      MENU_ITEM.QUANTITY,
      MENU_ITEM.SKU,
      MENU_ITEM.BARCODE,
    ]),
    [MENU_SECTION.LISTINGS]: List([
      MENU_ITEM.TITLE,
      MENU_ITEM.BODY_HTML,
      MENU_ITEM.TAGS,
      MENU_ITEM.PRODUCT_TYPE,
      MENU_ITEM.VENDOR,
      MENU_ITEM.COLLECTIONS,
    ]),
    [MENU_SECTION.MEDIA]: List([
      MENU_ITEM.PHOTOS,
      MENU_ITEM.VIDEOS,
    ]),
    [MENU_SECTION.SEO]: List([
      MENU_ITEM.PAGE_TITLE,
      MENU_ITEM.META_DESCRIPTION,
    ]),
    [MENU_SECTION.SHIPPING]: List([
      MENU_ITEM.WEIGHT,
      MENU_ITEM.COUNTRY_CODE,
      MENU_ITEM.HS_CODE,
    ]),
  }),
};

export const MENU_ITEM_BADGE = {
};

export const MENU_SECTION_BADGE = {
};

const COMMON_OPERATIONS = {
  PHOTOS: {
    ALT_TEXT: 'photos.altText', // "Alt text" option in operations dropdown, will not be sent to API
    ALT_TEXT_AI_GENERATED: 'photos.altText.aiGenerated',
    ADD: 'photos.add',
    DELETE: 'photos.delete',
    EDIT: 'photos.edit',
    REPLACE: 'photos.replace',
    SWAP: 'photos.swap',
    UPDATE: 'photos.update',
  },
  VIDEOS: {
    ADD: 'videos.add',
    REPLACE: 'videos.replace',
    DELETE: 'videos.delete',
  },
  STATUS: 'status.set',
  TAGS: {
    ADD: 'tags.add',
    AI_GENERATED: 'tags.aiGenerated',
    CHANGE_TO: 'tags.changeTo',
    DELETE: 'tags.delete',
  },
  TITLE: {
    ADD_AFTER: 'title.addAfter',
    ADD_BEFORE: 'title.addBefore',
    AI_GENERATED: 'title.aiGenerated',
    DELETE: 'title.delete',
    FIND_AND_REPLACE: 'title.findAndReplace',
    SET: 'title.set',
  },
  VARIATIONS: 'variationsInventory.changeTo',
};

export const OPERATIONS = {
  [ETSY]: {
    ...COMMON_OPERATIONS,
    CATEGORY: 'taxonomy.set',
    DESCRIPTION: {
      ADD_AFTER: 'description.addAfter',
      ADD_BEFORE: 'description.addBefore',
      AI_GENERATED: 'description.aiGenerated',
      DELETE: 'description.delete',
      FIND_AND_REPLACE: 'description.findAndReplace',
      SET: 'description.set',
    },
    FILES: {
      ADD: 'files.add',
      DELETE: 'files.delete',
      REPLACE: 'files.replace',
      SWAP: 'files.swap',
    },
    HEIGHT: {
      SET: 'itemHeight.set',
      INCREASE_BY: 'itemHeight.increaseBy',
      DECREASE_BY: 'itemHeight.decreaseBy',
    },
    IS_SUPPLY: 'is_supply.set',
    LENGTH: {
      SET: 'itemLength.set',
      DECREASE_BY: 'itemLength.decreaseBy',
      INCREASE_BY: 'itemLength.increaseBy',
    },
    MATERIALS: {
      ADD: 'materials.add',
      DELETE: 'materials.delete',
    },
    PERSONALIZATION: {
      IS_PERSONALIZABLE_SET: 'isPersonalizable.set',
      IS_REQUIRED_SET: 'personalizationIsRequired.set',
      CHAR_COUNT_MAX_SET: 'personalizationCharCountMax.set',
      INSTRUCTIONS_ADD_BEFORE: 'personalizationInstructions.addBefore',
      INSTRUCTIONS_ADD_AFTER: 'personalizationInstructions.addAfter',
      INSTRUCTIONS_FIND_AND_REPLACE: 'personalizationInstructions.findAndReplace',
      INSTRUCTIONS_DELETE: 'personalizationInstructions.delete',
      INSTRUCTIONS_SET: 'personalizationInstructions.set',
    },
    PRICE: {
      INCREASE_BY: 'priceInventory.increaseBy',
      DECREASE_BY: 'priceInventory.decreaseBy',
      CHANGE_TO: 'priceInventory.changeTo',
    },
    PRODUCTION_PARTNERS: 'productionPartners.set',
    QUANTITY: {
      CHANGE_TO: 'quantityInventory.changeTo',
      DECREASE_BY: 'quantityInventory.decreaseBy',
      INCREASE_BY: 'quantityInventory.increaseBy',
    },
    RETURN_POLICY: 'return_policy_id.set',
    SECTION: 'section.set',
    SHIPPING_PROFILE: 'shipping_template_id.set',
    SIZE: {
      SET: 'itemSize.set',
      INCREASE_BY: 'itemSize.increaseBy',
      DECREASE_BY: 'itemSize.decreaseBy',
    },
    SKU: {
      DELETE: 'skuInventory.delete',
      CHANGE_TO: 'skuInventory.changeTo',
      ADD_AFTER: 'skuInventory.addAfter',
      ADD_BEFORE: 'skuInventory.addBefore',
      FIND_AND_REPLACE: 'skuInventory.findAndReplace',
    },
    TAXONOMY_ATTRIBUTES: 'attributes.set',
    WEIGHT: {
      SET: 'itemWeight.set',
      DECREASE_BY: 'itemWeight.decreaseBy',
      INCREASE_BY: 'itemWeight.increaseBy',
    },
    WHEN_MADE: 'when_made.set',
    WHO_MADE: 'who_made.set',
    WIDTH: {
      SET: 'itemWidth.set',
      DECREASE_BY: 'itemWidth.decreaseBy',
      INCREASE_BY: 'itemWidth.increaseBy',
    },
  },
  [SHOPIFY]: {
    ...COMMON_OPERATIONS,
    BARCODE: {
      DELETE: 'barcodeInventory.delete',
      CHANGE_TO: 'barcodeInventory.changeTo',
      ADD_AFTER: 'barcodeInventory.addAfter',
      ADD_BEFORE: 'barcodeInventory.addBefore',
      INLINE_EDIT: 'barcodeInventory.inlineedit',
      FIND_AND_REPLACE: 'barcodeInventory.findAndReplace',
    },
    CAP: {
      CHANGE_TO: 'comapreAtPriceInventory.changeTo',
      INLINE_EDIT: 'comapreAtPriceInventory.inlineedit',
      INCREASE_BY: 'comapreAtPriceInventory.increaseBy',
      DECREASE_BY: 'comapreAtPriceInventory.decreaseBy',
      COPY_FROM_PRICE: 'comapreAtPriceInventory.copyFromPrice',
    },
    CHARGE_TAX: {
      SET: 'taxable.set',
      INLINE_EDIT: 'taxable.inlineedit',
    },
    COLLECTIONS: 'collection_ids.set',
    CONTINUE_SELLING: {
      SET: 'inventoryPolicy.set',
      INLINE_EDIT: 'inventoryPolicy.inlineedit',
    },
    COUNTRY_CODE: {
      SET: 'countryCode.set',
      INLINE_EDIT: 'countryCode.inlineedit',
    },
    CPI: {
      CHANGE_TO: 'costInventory.changeTo',
      INLINE_EDIT: 'costInventory.inlineedit',
      INCREASE_BY: 'costInventory.increaseBy',
      DECREASE_BY: 'costInventory.decreaseBy',
    },
    DESCRIPTION: {
      ADD_AFTER: 'body_html.addAfter',
      ADD_BEFORE: 'body_html.addBefore',
      AI_GENERATED: 'body_html.aiGenerated',
      DELETE: 'body_html.delete',
      FIND_AND_REPLACE: 'body_html.findAndReplace',
      SET: 'body_html.set',
    },
    HS_CODE: {
      SET: 'hsCode.set',
      INLINE_EDIT: 'hsCode.inlineedit',
    },
    INVENTORY_INLINE_EDIT: 'variations.inlineEdit',
    META_DESCRIPTION: {
      SET: 'seoDescription.set',
      DELETE: 'seoDescription.delete',
      ADD_AFTER: 'seoDescription.addAfter',
      ADD_BEFORE: 'seoDescription.addBefore',
      FIND_AND_REPLACE: 'seoDescription.findAndReplace',
    },
    PAGE_TITLE: {
      SET: 'seoTitle.set',
      DELETE: 'seoTitle.delete',
      ADD_AFTER: 'seoTitle.addAfter',
      ADD_BEFORE: 'seoTitle.addBefore',
      FIND_AND_REPLACE: 'seoTitle.findAndReplace',
    },
    PHYSICAL: {
      SET: 'physical.set',
      INLINE_EDIT: 'physical.inlineedit',
    },
    PRICE: {
      CHANGE_TO: 'priceInventory.changeTo',
      INLINE_EDIT: 'priceInventory.inlineedit',
      INCREASE_BY: 'priceInventory.increaseBy',
      DECREASE_BY: 'priceInventory.decreaseBy',
      COPY_FROM_CAP: 'priceInventory.copyFromCap',
    },
    PRODUCT_TYPE: 'product_type.set',
    QUANTITY: {
      CHANGE_TO: 'quantityInventory.changeTo',
      INLINE_EDIT: 'quantityInventory.inlineedit',
      INCREASE_BY: 'quantityInventory.increaseBy',
      DECREASE_BY: 'quantityInventory.decreaseBy',
    },
    SKU: {
      DELETE: 'skuInventory.delete',
      CHANGE_TO: 'skuInventory.changeTo',
      ADD_AFTER: 'skuInventory.addAfter',
      ADD_BEFORE: 'skuInventory.addBefore',
      INLINE_EDIT: 'skuInventory.inlineedit',
      FIND_AND_REPLACE: 'skuInventory.findAndReplace',
    },
    TRACK_QUANTITY: {
      SET: 'inventoryManagement.set',
    },
    URL_HANDLE: {
      SET: 'seoUrl.set',
      DELETE: 'seoUrl.delete',
      ADD_AFTER: 'seoUrl.addAfter',
      ADD_BEFORE: 'seoUrl.addBefore',
      FIND_AND_REPLACE: 'seoUrl.findAndReplace',
    },
    URL_NUMERATION: {
      SET: 'seoNumeration.set',
    },
    VENDOR: 'vendor.set',
    WEIGHT: {
      CHANGE_TO: 'weight.changeTo',
      INLINE_EDIT: 'weight.inlineedit',
      INCREASE_BY: 'weight.increaseBy',
      DECREASE_BY: 'weight.decreaseBy',
    },
  },
};

const COMMON_MENU_ITEMS = {
  [MENU_ITEM.TITLE]: Map({
    controls: 'Title',
    productRow: 'Title',
    operations: List([
      COMMON_OPERATIONS.TITLE.ADD_BEFORE,
      COMMON_OPERATIONS.TITLE.ADD_AFTER,
      COMMON_OPERATIONS.TITLE.FIND_AND_REPLACE,
      COMMON_OPERATIONS.TITLE.DELETE,
      COMMON_OPERATIONS.TITLE.SET,
      COMMON_OPERATIONS.TITLE.AI_GENERATED,
    ]),
  }),
  [MENU_ITEM.TAGS]: Map({
    controls: 'Tags',
    productRow: 'Tags',
    operations: List([
      COMMON_OPERATIONS.TAGS.ADD,
      COMMON_OPERATIONS.TAGS.DELETE,
      COMMON_OPERATIONS.TAGS.CHANGE_TO,
      COMMON_OPERATIONS.TAGS.AI_GENERATED,
    ]),
  }),
  [MENU_ITEM.PHOTOS]: Map({
    controls: 'Photos',
    productRow: 'Photos',
    operations: List([
      COMMON_OPERATIONS.PHOTOS.ADD,
      COMMON_OPERATIONS.PHOTOS.REPLACE,
      COMMON_OPERATIONS.PHOTOS.DELETE,
      COMMON_OPERATIONS.PHOTOS.ALT_TEXT_AI_GENERATED,
    ]),
  }),
  [MENU_ITEM.VIDEOS]: Map({
    controls: 'Videos',
    productRow: 'Videos',
    operations: List([
      COMMON_OPERATIONS.VIDEOS.ADD,
      COMMON_OPERATIONS.VIDEOS.REPLACE,
      COMMON_OPERATIONS.VIDEOS.DELETE,
    ]),
  }),
};

export const MENU_ITEMS = {
  [ETSY]: Map({
    ...COMMON_MENU_ITEMS,
    [MENU_ITEM.ABOUT]: Map({
      controls: 'About',
      productRow: 'About',
    }),
    [MENU_ITEM.CATEGORY]: Map({
      controls: 'Category',
      productRow: 'Category',
    }),
    [MENU_ITEM.DESCRIPTION]: Map({
      controls: 'Description',
      productRow: 'Description',
      operations: List([
        OPERATIONS[ETSY].DESCRIPTION.ADD_BEFORE,
        OPERATIONS[ETSY].DESCRIPTION.ADD_AFTER,
        OPERATIONS[ETSY].DESCRIPTION.FIND_AND_REPLACE,
        OPERATIONS[ETSY].DESCRIPTION.DELETE,
        OPERATIONS[ETSY].DESCRIPTION.SET,
        OPERATIONS[ETSY].DESCRIPTION.AI_GENERATED,
      ]),
    }),
    [MENU_ITEM.FILES]: Map({
      controls: 'Files',
      productRow: 'Files',
      filters: Map({ [FILTER[ETSY].IS_DIGITAL]: true }),
      operations: List([
        OPERATIONS[ETSY].FILES.ADD,
        OPERATIONS[ETSY].FILES.REPLACE,
        OPERATIONS[ETSY].FILES.DELETE,
      ]),
    }),
    [MENU_ITEM.ITEM_WEIGHT]: Map({
      controls: 'ProductShipping',
      productRow: 'ProductShipping',
      filters: Map({ [FILTER[ETSY].IS_DIGITAL]: false }),
      operations: List([
        OPERATIONS[ETSY].WEIGHT.INCREASE_BY,
        OPERATIONS[ETSY].WEIGHT.DECREASE_BY,
        OPERATIONS[ETSY].WEIGHT.SET,
      ]),
    }),
    [MENU_ITEM.ITEM_SIZE]: Map({
      controls: 'ProductShipping',
      productRow: 'ProductShipping',
      filters: Map({ [FILTER[ETSY].IS_DIGITAL]: false }),
      operations: List([
        OPERATIONS[ETSY].SIZE.INCREASE_BY,
        OPERATIONS[ETSY].SIZE.DECREASE_BY,
        OPERATIONS[ETSY].SIZE.SET,
      ]),
    }),
    [MENU_ITEM.RETURN_POLICY]: Map({
      controls: 'ProductShipping',
      productRow: 'ProductShipping',
      filters: Map({ [FILTER[ETSY].IS_DIGITAL]: false }),
    }),
    [MENU_ITEM.MATERIALS]: Map({
      controls: 'Materials',
      productRow: 'Materials',
      operations: List([
        OPERATIONS[ETSY].MATERIALS.ADD,
        OPERATIONS[ETSY].MATERIALS.DELETE,
      ]),
    }),
    [MENU_ITEM.TAXONOMY_ATTRIBUTES]: Map({
      controls: 'TaxonomyAttributes',
      productRow: 'TaxonomyAttributes',
      isTaxonomy: true,
    }),
    [MENU_ITEM.PERSONALIZATION]: Map({
      controls: 'Personalization',
      productRow: 'Personalization',
      operations: List([
        OPERATIONS[ETSY].PERSONALIZATION.INSTRUCTIONS_ADD_BEFORE,
        OPERATIONS[ETSY].PERSONALIZATION.INSTRUCTIONS_ADD_AFTER,
        OPERATIONS[ETSY].PERSONALIZATION.INSTRUCTIONS_FIND_AND_REPLACE,
        OPERATIONS[ETSY].PERSONALIZATION.INSTRUCTIONS_DELETE,
      ]),
    }),
    [MENU_ITEM.PRICE]: Map({
      controls: 'Price',
      productRow: 'Price',
      operations: List([
        OPERATIONS[ETSY].PRICE.INCREASE_BY,
        OPERATIONS[ETSY].PRICE.DECREASE_BY,
        OPERATIONS[ETSY].PRICE.CHANGE_TO,
      ]),
    }),
    [MENU_ITEM.PRODUCTION_PARTNER]: Map({
      controls: 'ProductionPartner',
      productRow: 'ProductionPartner',
    }),
    [MENU_ITEM.QUANTITY]: Map({
      controls: 'Quantity',
      productRow: 'Quantity',
      operations: List([
        OPERATIONS[ETSY].QUANTITY.INCREASE_BY,
        OPERATIONS[ETSY].QUANTITY.DECREASE_BY,
        OPERATIONS[ETSY].QUANTITY.CHANGE_TO,
      ]),
    }),
    [MENU_ITEM.SECTION]: Map({
      controls: 'Section',
      productRow: 'Section',
    }),
    [MENU_ITEM.SHIPPING_PROFILE]: Map({
      controls: 'ProductShipping',
      productRow: 'ProductShipping',
      filters: Map({ [FILTER[ETSY].IS_DIGITAL]: false }),
    }),
    [MENU_ITEM.SKU]: Map({
      controls: 'Sku',
      productRow: 'Sku',
      operations: List([
        OPERATIONS[ETSY].SKU.ADD_BEFORE,
        OPERATIONS[ETSY].SKU.ADD_AFTER,
        OPERATIONS[ETSY].SKU.FIND_AND_REPLACE,
        OPERATIONS[ETSY].SKU.DELETE,
        OPERATIONS[ETSY].SKU.CHANGE_TO,
      ]),
    }),
    [MENU_ITEM.VARIATIONS]: Map({
      controls: 'Variations',
      productRow: 'Variations',
      filters: Map({ [FILTER[ETSY].IS_DIGITAL]: false }),
    }),
  }),
  [SHOPIFY]: Map({
    ...COMMON_MENU_ITEMS,
    [MENU_ITEM.BARCODE]: Map({
      controls: 'Barcode',
      productRow: 'Barcode',
      operations: List([
        OPERATIONS[SHOPIFY].BARCODE.ADD_BEFORE,
        OPERATIONS[SHOPIFY].BARCODE.ADD_AFTER,
        OPERATIONS[SHOPIFY].BARCODE.FIND_AND_REPLACE,
        OPERATIONS[SHOPIFY].BARCODE.DELETE,
        OPERATIONS[SHOPIFY].BARCODE.CHANGE_TO,
      ]),
    }),
    [MENU_ITEM.BODY_HTML]: Map({
      controls: 'Description',
      productRow: 'Description',
      operations: List([
        OPERATIONS[SHOPIFY].DESCRIPTION.ADD_BEFORE,
        OPERATIONS[SHOPIFY].DESCRIPTION.ADD_AFTER,
        OPERATIONS[SHOPIFY].DESCRIPTION.FIND_AND_REPLACE,
        OPERATIONS[SHOPIFY].DESCRIPTION.DELETE,
        OPERATIONS[SHOPIFY].DESCRIPTION.SET,
        OPERATIONS[SHOPIFY].DESCRIPTION.AI_GENERATED,
      ]),
    }),
    [MENU_ITEM.COLLECTIONS]: Map({
      controls: 'Collections',
      productRow: 'Collections',
    }),
    [MENU_ITEM.CAP]: Map({
      controls: 'Price',
      productRow: 'Price',
      operations: List([
        OPERATIONS[SHOPIFY].CAP.INCREASE_BY,
        OPERATIONS[SHOPIFY].CAP.DECREASE_BY,
        OPERATIONS[SHOPIFY].CAP.CHANGE_TO,
      ]),
    }),
    [MENU_ITEM.CPI]: Map({
      controls: 'Price',
      productRow: 'Price',
      operations: List([
        OPERATIONS[SHOPIFY].CPI.INCREASE_BY,
        OPERATIONS[SHOPIFY].CPI.DECREASE_BY,
        OPERATIONS[SHOPIFY].CPI.CHANGE_TO,
      ]),
    }),
    [MENU_ITEM.COUNTRY_CODE]: Map({
      controls: 'InventoryShipping',
      productRow: 'InventoryShipping',
    }),
    [MENU_ITEM.HS_CODE]: Map({
      controls: 'InventoryShipping',
      productRow: 'InventoryShipping',
    }),
    [MENU_ITEM.META_DESCRIPTION]: Map({
      controls: 'SEOListing',
      productRow: 'SEOListing',
      operations: List([
        OPERATIONS[SHOPIFY].META_DESCRIPTION.ADD_BEFORE,
        OPERATIONS[SHOPIFY].META_DESCRIPTION.ADD_AFTER,
        OPERATIONS[SHOPIFY].META_DESCRIPTION.FIND_AND_REPLACE,
        OPERATIONS[SHOPIFY].META_DESCRIPTION.DELETE,
        OPERATIONS[SHOPIFY].META_DESCRIPTION.SET,
      ]),
    }),
    [MENU_ITEM.PAGE_TITLE]: Map({
      controls: 'SEOListing',
      productRow: 'SEOListing',
      operations: List([
        OPERATIONS[SHOPIFY].PAGE_TITLE.ADD_BEFORE,
        OPERATIONS[SHOPIFY].PAGE_TITLE.ADD_AFTER,
        OPERATIONS[SHOPIFY].PAGE_TITLE.FIND_AND_REPLACE,
        OPERATIONS[SHOPIFY].PAGE_TITLE.DELETE,
        OPERATIONS[SHOPIFY].PAGE_TITLE.SET,
      ]),
    }),
    [MENU_ITEM.PRICE]: Map({
      controls: 'Price',
      productRow: 'Price',
      operations: List([
        OPERATIONS[SHOPIFY].PRICE.INCREASE_BY,
        OPERATIONS[SHOPIFY].PRICE.DECREASE_BY,
        OPERATIONS[SHOPIFY].PRICE.CHANGE_TO,
      ]),
    }),
    [MENU_ITEM.PRODUCT_TYPE]: Map({
      controls: 'ProductType',
      productRow: 'ProductType',
    }),
    [MENU_ITEM.QUANTITY]: Map({
      controls: 'Quantity',
      productRow: 'Quantity',
      operations: List([
        OPERATIONS[SHOPIFY].QUANTITY.INCREASE_BY,
        OPERATIONS[SHOPIFY].QUANTITY.DECREASE_BY,
        OPERATIONS[SHOPIFY].QUANTITY.CHANGE_TO,
      ]),
    }),
    [MENU_ITEM.SKU]: Map({
      controls: 'Sku',
      productRow: 'Sku',
      operations: List([
        OPERATIONS[SHOPIFY].SKU.ADD_BEFORE,
        OPERATIONS[SHOPIFY].SKU.ADD_AFTER,
        OPERATIONS[SHOPIFY].SKU.FIND_AND_REPLACE,
        OPERATIONS[SHOPIFY].SKU.DELETE,
        OPERATIONS[SHOPIFY].SKU.CHANGE_TO,
      ]),
    }),
    [MENU_ITEM.URL_HANDLE]: Map({
      controls: 'SEOListing',
      productRow: 'SEOListing',
      operations: List([
        OPERATIONS[SHOPIFY].URL_HANDLE.ADD_BEFORE,
        OPERATIONS[SHOPIFY].URL_HANDLE.ADD_AFTER,
        OPERATIONS[SHOPIFY].URL_HANDLE.FIND_AND_REPLACE,
        OPERATIONS[SHOPIFY].URL_HANDLE.DELETE,
        OPERATIONS[SHOPIFY].URL_HANDLE.SET,
      ]),
    }),
    [MENU_ITEM.VARIATIONS]: Map({
      controls: 'Variations',
      productRow: 'Variations',
    }),
    [MENU_ITEM.VENDOR]: Map({
      controls: 'Vendor',
      productRow: 'Vendor',
    }),
    [MENU_ITEM.WEIGHT]: Map({
      controls: 'InventoryShipping',
      productRow: 'InventoryShipping',
      operations: List([
        OPERATIONS[SHOPIFY].WEIGHT.INCREASE_BY,
        OPERATIONS[SHOPIFY].WEIGHT.DECREASE_BY,
        OPERATIONS[SHOPIFY].WEIGHT.CHANGE_TO,
      ]),
    }),
  }),
};

const OPERATION_NAME = {
  ADD: 'Add',
  ADD_AFTER: 'Add after',
  ADD_BEFORE: 'Add before',
  AI_GENERATED: 'AI Generated',
  ALT_TEXT: 'Alt text',
  CHANGE_TO: 'Change to',
  DECREASE_BY: 'Decrease by',
  DELETE: 'Delete',
  EDIT: 'Edit',
  FIND_AND_REPLACE: 'Find & Replace',
  INCREASE_BY: 'Increase by',
  REPLACE: 'Replace',
};

const COMMON_MENU_OPERATION_NAMES = {
  [MENU_ITEM.TITLE]: {
    [COMMON_OPERATIONS.TITLE.ADD_AFTER]: OPERATION_NAME.ADD_AFTER,
    [COMMON_OPERATIONS.TITLE.ADD_BEFORE]: OPERATION_NAME.ADD_BEFORE,
    [COMMON_OPERATIONS.TITLE.AI_GENERATED]: OPERATION_NAME.AI_GENERATED,
    [COMMON_OPERATIONS.TITLE.DELETE]: OPERATION_NAME.DELETE,
    [COMMON_OPERATIONS.TITLE.FIND_AND_REPLACE]: OPERATION_NAME.FIND_AND_REPLACE,
    [COMMON_OPERATIONS.TITLE.SET]: OPERATION_NAME.CHANGE_TO,
  },
  [MENU_ITEM.TAGS]: {
    [COMMON_OPERATIONS.TAGS.ADD]: OPERATION_NAME.ADD,
    [COMMON_OPERATIONS.TAGS.AI_GENERATED]: OPERATION_NAME.AI_GENERATED,
    [COMMON_OPERATIONS.TAGS.DELETE]: OPERATION_NAME.DELETE,
    [COMMON_OPERATIONS.TAGS.CHANGE_TO]: OPERATION_NAME.CHANGE_TO,
  },
  [MENU_ITEM.PHOTOS]: {
    [COMMON_OPERATIONS.PHOTOS.ADD]: OPERATION_NAME.ADD,
    [COMMON_OPERATIONS.PHOTOS.ALT_TEXT]: OPERATION_NAME.ALT_TEXT,
    [COMMON_OPERATIONS.PHOTOS.ALT_TEXT_AI_GENERATED]: OPERATION_NAME.ALT_TEXT,
    [COMMON_OPERATIONS.PHOTOS.DELETE]: OPERATION_NAME.DELETE,
    [COMMON_OPERATIONS.PHOTOS.EDIT]: OPERATION_NAME.EDIT,
    [COMMON_OPERATIONS.PHOTOS.REPLACE]: OPERATION_NAME.REPLACE,
  },
  [MENU_ITEM.VIDEOS]: {
    [COMMON_OPERATIONS.VIDEOS.ADD]: OPERATION_NAME.ADD,
    [COMMON_OPERATIONS.VIDEOS.REPLACE]: OPERATION_NAME.REPLACE,
    [COMMON_OPERATIONS.VIDEOS.DELETE]: OPERATION_NAME.DELETE,
  },
};

export const MENU_OPERATION_NAMES = {
  [ETSY]: {
    ...COMMON_MENU_OPERATION_NAMES,
    [MENU_ITEM.DESCRIPTION]: {
      [OPERATIONS[ETSY].DESCRIPTION.ADD_AFTER]: OPERATION_NAME.ADD_AFTER,
      [OPERATIONS[ETSY].DESCRIPTION.ADD_BEFORE]: OPERATION_NAME.ADD_BEFORE,
      [OPERATIONS[ETSY].DESCRIPTION.AI_GENERATED]: OPERATION_NAME.AI_GENERATED,
      [OPERATIONS[ETSY].DESCRIPTION.DELETE]: OPERATION_NAME.DELETE,
      [OPERATIONS[ETSY].DESCRIPTION.FIND_AND_REPLACE]: OPERATION_NAME.FIND_AND_REPLACE,
      [OPERATIONS[ETSY].DESCRIPTION.SET]: OPERATION_NAME.CHANGE_TO,
    },
    [MENU_ITEM.FILES]: {
      [OPERATIONS[ETSY].FILES.ADD]: OPERATION_NAME.ADD,
      [OPERATIONS[ETSY].FILES.DELETE]: OPERATION_NAME.DELETE,
      [OPERATIONS[ETSY].FILES.REPLACE]: OPERATION_NAME.REPLACE,
    },
    [MENU_ITEM.ITEM_SIZE]: {
      [OPERATIONS[ETSY].SIZE.DECREASE_BY]: OPERATION_NAME.DECREASE_BY,
      [OPERATIONS[ETSY].SIZE.INCREASE_BY]: OPERATION_NAME.INCREASE_BY,
      [OPERATIONS[ETSY].SIZE.SET]: OPERATION_NAME.CHANGE_TO,
    },
    [MENU_ITEM.ITEM_WEIGHT]: {
      [OPERATIONS[ETSY].WEIGHT.DECREASE_BY]: OPERATION_NAME.DECREASE_BY,
      [OPERATIONS[ETSY].WEIGHT.INCREASE_BY]: OPERATION_NAME.INCREASE_BY,
      [OPERATIONS[ETSY].WEIGHT.SET]: OPERATION_NAME.CHANGE_TO,
    },
    [MENU_ITEM.MATERIALS]: {
      [OPERATIONS[ETSY].MATERIALS.ADD]: OPERATION_NAME.ADD,
      [OPERATIONS[ETSY].MATERIALS.DELETE]: OPERATION_NAME.DELETE,
    },
    [MENU_ITEM.PERSONALIZATION]: {
      [OPERATIONS[ETSY].PERSONALIZATION.INSTRUCTIONS_ADD_AFTER]: OPERATION_NAME.ADD_AFTER,
      [OPERATIONS[ETSY].PERSONALIZATION.INSTRUCTIONS_ADD_BEFORE]: OPERATION_NAME.ADD_BEFORE,
      [OPERATIONS[ETSY].PERSONALIZATION.INSTRUCTIONS_DELETE]: OPERATION_NAME.DELETE,
      [OPERATIONS[ETSY].PERSONALIZATION.INSTRUCTIONS_FIND_AND_REPLACE]: OPERATION_NAME.FIND_AND_REPLACE,
    },
    [MENU_ITEM.PRICE]: {
      [OPERATIONS[ETSY].PRICE.CHANGE_TO]: OPERATION_NAME.CHANGE_TO,
      [OPERATIONS[ETSY].PRICE.DECREASE_BY]: OPERATION_NAME.DECREASE_BY,
      [OPERATIONS[ETSY].PRICE.INCREASE_BY]: OPERATION_NAME.INCREASE_BY,
    },
    [MENU_ITEM.QUANTITY]: {
      [OPERATIONS[ETSY].QUANTITY.CHANGE_TO]: OPERATION_NAME.CHANGE_TO,
      [OPERATIONS[ETSY].QUANTITY.DECREASE_BY]: OPERATION_NAME.DECREASE_BY,
      [OPERATIONS[ETSY].QUANTITY.INCREASE_BY]: OPERATION_NAME.INCREASE_BY,
    },
    [MENU_ITEM.SKU]: {
      [OPERATIONS[ETSY].SKU.ADD_AFTER]: OPERATION_NAME.ADD_AFTER,
      [OPERATIONS[ETSY].SKU.ADD_BEFORE]: OPERATION_NAME.ADD_BEFORE,
      [OPERATIONS[ETSY].SKU.CHANGE_TO]: OPERATION_NAME.CHANGE_TO,
      [OPERATIONS[ETSY].SKU.DELETE]: OPERATION_NAME.DELETE,
      [OPERATIONS[ETSY].SKU.FIND_AND_REPLACE]: OPERATION_NAME.FIND_AND_REPLACE,
    },
  },
  [SHOPIFY]: {
    ...COMMON_MENU_OPERATION_NAMES,
    [MENU_ITEM.BARCODE]: {
      [OPERATIONS[SHOPIFY].BARCODE.ADD_AFTER]: OPERATION_NAME.ADD_AFTER,
      [OPERATIONS[SHOPIFY].BARCODE.ADD_BEFORE]: OPERATION_NAME.ADD_BEFORE,
      [OPERATIONS[SHOPIFY].BARCODE.CHANGE_TO]: OPERATION_NAME.CHANGE_TO,
      [OPERATIONS[SHOPIFY].BARCODE.DELETE]: OPERATION_NAME.DELETE,
      [OPERATIONS[SHOPIFY].BARCODE.FIND_AND_REPLACE]: OPERATION_NAME.FIND_AND_REPLACE,
    },
    [MENU_ITEM.BODY_HTML]: {
      [OPERATIONS[SHOPIFY].DESCRIPTION.ADD_AFTER]: OPERATION_NAME.ADD_AFTER,
      [OPERATIONS[SHOPIFY].DESCRIPTION.ADD_BEFORE]: OPERATION_NAME.ADD_BEFORE,
      [OPERATIONS[SHOPIFY].DESCRIPTION.AI_GENERATED]: OPERATION_NAME.AI_GENERATED,
      [OPERATIONS[SHOPIFY].DESCRIPTION.DELETE]: OPERATION_NAME.DELETE,
      [OPERATIONS[SHOPIFY].DESCRIPTION.FIND_AND_REPLACE]: OPERATION_NAME.FIND_AND_REPLACE,
      [OPERATIONS[SHOPIFY].DESCRIPTION.SET]: OPERATION_NAME.CHANGE_TO,
    },
    [MENU_ITEM.CAP]: {
      [OPERATIONS[SHOPIFY].CAP.CHANGE_TO]: OPERATION_NAME.CHANGE_TO,
      [OPERATIONS[SHOPIFY].CAP.DECREASE_BY]: OPERATION_NAME.DECREASE_BY,
      [OPERATIONS[SHOPIFY].CAP.INCREASE_BY]: OPERATION_NAME.INCREASE_BY,
    },
    [MENU_ITEM.CPI]: {
      [OPERATIONS[SHOPIFY].CPI.CHANGE_TO]: OPERATION_NAME.CHANGE_TO,
      [OPERATIONS[SHOPIFY].CPI.DECREASE_BY]: OPERATION_NAME.DECREASE_BY,
      [OPERATIONS[SHOPIFY].CPI.INCREASE_BY]: OPERATION_NAME.INCREASE_BY,
    },
    [MENU_ITEM.META_DESCRIPTION]: {
      [OPERATIONS[SHOPIFY].META_DESCRIPTION.ADD_AFTER]: OPERATION_NAME.ADD_AFTER,
      [OPERATIONS[SHOPIFY].META_DESCRIPTION.ADD_BEFORE]: OPERATION_NAME.ADD_BEFORE,
      [OPERATIONS[SHOPIFY].META_DESCRIPTION.DELETE]: OPERATION_NAME.DELETE,
      [OPERATIONS[SHOPIFY].META_DESCRIPTION.FIND_AND_REPLACE]: OPERATION_NAME.FIND_AND_REPLACE,
      [OPERATIONS[SHOPIFY].META_DESCRIPTION.SET]: OPERATION_NAME.CHANGE_TO,
    },
    [MENU_ITEM.PAGE_TITLE]: {
      [OPERATIONS[SHOPIFY].PAGE_TITLE.ADD_AFTER]: OPERATION_NAME.ADD_AFTER,
      [OPERATIONS[SHOPIFY].PAGE_TITLE.ADD_BEFORE]: OPERATION_NAME.ADD_BEFORE,
      [OPERATIONS[SHOPIFY].PAGE_TITLE.DELETE]: OPERATION_NAME.DELETE,
      [OPERATIONS[SHOPIFY].PAGE_TITLE.FIND_AND_REPLACE]: OPERATION_NAME.FIND_AND_REPLACE,
      [OPERATIONS[SHOPIFY].PAGE_TITLE.SET]: OPERATION_NAME.CHANGE_TO,
    },
    [MENU_ITEM.PRICE]: {
      [OPERATIONS[SHOPIFY].PRICE.CHANGE_TO]: OPERATION_NAME.CHANGE_TO,
      [OPERATIONS[SHOPIFY].PRICE.DECREASE_BY]: OPERATION_NAME.DECREASE_BY,
      [OPERATIONS[SHOPIFY].PRICE.INCREASE_BY]: OPERATION_NAME.INCREASE_BY,
    },
    [MENU_ITEM.QUANTITY]: {
      [OPERATIONS[SHOPIFY].QUANTITY.CHANGE_TO]: OPERATION_NAME.CHANGE_TO,
      [OPERATIONS[SHOPIFY].QUANTITY.DECREASE_BY]: OPERATION_NAME.DECREASE_BY,
      [OPERATIONS[SHOPIFY].QUANTITY.INCREASE_BY]: OPERATION_NAME.INCREASE_BY,
    },
    [MENU_ITEM.SKU]: {
      [OPERATIONS[SHOPIFY].SKU.ADD_AFTER]: OPERATION_NAME.ADD_AFTER,
      [OPERATIONS[SHOPIFY].SKU.ADD_BEFORE]: OPERATION_NAME.ADD_BEFORE,
      [OPERATIONS[SHOPIFY].SKU.CHANGE_TO]: OPERATION_NAME.CHANGE_TO,
      [OPERATIONS[SHOPIFY].SKU.DELETE]: OPERATION_NAME.DELETE,
      [OPERATIONS[SHOPIFY].SKU.FIND_AND_REPLACE]: OPERATION_NAME.FIND_AND_REPLACE,
    },
    [MENU_ITEM.URL_HANDLE]: {
      [OPERATIONS[SHOPIFY].URL_HANDLE.ADD_AFTER]: OPERATION_NAME.ADD_AFTER,
      [OPERATIONS[SHOPIFY].URL_HANDLE.ADD_BEFORE]: OPERATION_NAME.ADD_BEFORE,
      [OPERATIONS[SHOPIFY].URL_HANDLE.DELETE]: OPERATION_NAME.DELETE,
      [OPERATIONS[SHOPIFY].URL_HANDLE.FIND_AND_REPLACE]: OPERATION_NAME.FIND_AND_REPLACE,
      [OPERATIONS[SHOPIFY].URL_HANDLE.SET]: OPERATION_NAME.CHANGE_TO,
    },
    [MENU_ITEM.WEIGHT]: {
      [OPERATIONS[SHOPIFY].WEIGHT.CHANGE_TO]: OPERATION_NAME.CHANGE_TO,
      [OPERATIONS[SHOPIFY].WEIGHT.DECREASE_BY]: OPERATION_NAME.DECREASE_BY,
      [OPERATIONS[SHOPIFY].WEIGHT.INCREASE_BY]: OPERATION_NAME.INCREASE_BY,
    },
  },
};

export const MENU_OPERATION_BADGE = {
  [MENU_ITEM.PHOTOS]: {
    [OPERATIONS[ETSY].PHOTOS.ALT_TEXT]: NEW,
    [OPERATIONS[ETSY].PHOTOS.ALT_TEXT_AI_GENERATED]: NEW,
    [OPERATIONS[SHOPIFY].PHOTOS.ALT_TEXT]: NEW,
    [OPERATIONS[SHOPIFY].PHOTOS.ALT_TEXT_AI_GENERATED]: NEW,
  },
  [MENU_ITEM.DESCRIPTION]: {
    [OPERATIONS[ETSY].DESCRIPTION.AI_GENERATED]: NEW,
    [OPERATIONS[SHOPIFY].DESCRIPTION.AI_GENERATED]: NEW,
  },
  [MENU_ITEM.TAGS]: {
    [OPERATIONS[ETSY].TAGS.AI_GENERATED]: NEW,
    [OPERATIONS[SHOPIFY].TAGS.AI_GENERATED]: NEW,
  },
  [MENU_ITEM.TITLE]: {
    [OPERATIONS[ETSY].TITLE.AI_GENERATED]: NEW,
    [OPERATIONS[SHOPIFY].TITLE.AI_GENERATED]: NEW,
  },
};

export const ATTRIBUTES_TO_MENU_ITEM_MAP = {
  [ETSY]: {
    about: MENU_ITEM.ABOUT,
    category: MENU_ITEM.CATEGORY,
    description: MENU_ITEM.DESCRIPTION,
    files: MENU_ITEM.FILES,
    item_dimensions: MENU_ITEM.ITEM_SIZE,
    item_weight: MENU_ITEM.ITEM_WEIGHT,
    materials: MENU_ITEM.MATERIALS,
    optional: MENU_ITEM.TAXONOMY_ATTRIBUTES,
    personalization: MENU_ITEM.PERSONALIZATION,
    photos: MENU_ITEM.PHOTOS,
    price: MENU_ITEM.PRICE,
    production_partners: MENU_ITEM.PRODUCTION_PARTNER,
    quantity: MENU_ITEM.QUANTITY,
    section: MENU_ITEM.SECTION,
    shipping_profile: MENU_ITEM.SHIPPING_PROFILE,
    sku: MENU_ITEM.SKU,
    tags: MENU_ITEM.TAGS,
    title: MENU_ITEM.TITLE,
    variations: MENU_ITEM.VARIATIONS,
  },
  [SHOPIFY]: {
    barcode: MENU_ITEM.BARCODE,
    description: MENU_ITEM.BODY_HTML,
    compare_at_price: MENU_ITEM.CAP,
    collections: MENU_ITEM.COLLECTIONS,
    country_code_of_origin: MENU_ITEM.COUNTRY_CODE,
    cost: MENU_ITEM.CPI,
    harmonized_system_code: MENU_ITEM.HS_CODE,
    photos: MENU_ITEM.PHOTOS,
    price: MENU_ITEM.PRICE,
    product_type: MENU_ITEM.PRODUCT_TYPE,
    quantity: MENU_ITEM.QUANTITY,
    sku: MENU_ITEM.SKU,
    tags: MENU_ITEM.TAGS,
    title: MENU_ITEM.TITLE,
    variations: MENU_ITEM.VARIATIONS,
    vendor: MENU_ITEM.VENDOR,
    weight: MENU_ITEM.WEIGHT,
  },
};

export const MENU_ITEMS_TO_ATTRIBUTE_MAP = {
  [ETSY]: {
    [MENU_ITEM.ABOUT]: 'about',
    [MENU_ITEM.CATEGORY]: 'category',
    [MENU_ITEM.DESCRIPTION]: 'description',
    [MENU_ITEM.FILES]: 'files',
    [MENU_ITEM.ITEM_SIZE]: 'item_dimensions',
    [MENU_ITEM.ITEM_WEIGHT]: 'item_weight',
    [MENU_ITEM.TAXONOMY_ATTRIBUTES]: 'optional',
    [MENU_ITEM.MATERIALS]: 'materials',
    [MENU_ITEM.PERSONALIZATION]: 'personalization',
    [MENU_ITEM.PHOTOS]: 'photos',
    [MENU_ITEM.PRICE]: 'price',
    [MENU_ITEM.PRODUCTION_PARTNER]: 'production_partners',
    [MENU_ITEM.QUANTITY]: 'quantity',
    [MENU_ITEM.SECTION]: 'section',
    [MENU_ITEM.SHIPPING_PROFILE]: 'shipping_profile',
    [MENU_ITEM.SKU]: 'sku',
    [MENU_ITEM.TAGS]: 'tags',
    [MENU_ITEM.TITLE]: 'title',
    [MENU_ITEM.VARIATIONS]: 'variations',
  },
  [SHOPIFY]: {
    [MENU_ITEM.BARCODE]: 'barcode',
    [MENU_ITEM.BODY_HTML]: 'description',
    [MENU_ITEM.CAP]: 'compare_at_price',
    [MENU_ITEM.COLLECTIONS]: 'collections',
    [MENU_ITEM.COUNTRY_CODE]: 'country_code_of_origin',
    [MENU_ITEM.CPI]: 'cost',
    [MENU_ITEM.HS_CODE]: 'harmonized_system_code',
    [MENU_ITEM.PHOTOS]: 'photos',
    [MENU_ITEM.PRICE]: 'price',
    [MENU_ITEM.PRODUCT_TYPE]: 'product_type',
    [MENU_ITEM.QUANTITY]: 'quantity',
    [MENU_ITEM.SKU]: 'sku',
    [MENU_ITEM.TAGS]: 'tags',
    [MENU_ITEM.TITLE]: 'title',
    [MENU_ITEM.VARIATIONS]: 'variations',
    [MENU_ITEM.VENDOR]: 'vendor',
    [MENU_ITEM.WEIGHT]: 'weight',
  },
};

export const MENU_ITEMS_WITH_AI = {
  [ETSY]: {
    [MENU_ITEM.DESCRIPTION]: true,
    [MENU_ITEM.PHOTOS]: true,
    [MENU_ITEM.TAGS]: true,
    [MENU_ITEM.TITLE]: true,
  },
  [SHOPIFY]: {
    [MENU_ITEM.DESCRIPTION]: true,
    [MENU_ITEM.PHOTOS]: true,
    [MENU_ITEM.TAGS]: true,
    [MENU_ITEM.TITLE]: true,
  },
};

export const SAVE_TYPE = {
  SAVE: 'save',
  SYNC: 'sync',
};

export const PRICE_TYPE = {
  ABSOLUTE: 'absolute',
  PERCENTAGE: 'percentage',
};

export const PRICE_TYPE_SYMBOL = {
  [PRICE_TYPE.ABSOLUTE]: '$',
  [PRICE_TYPE.PERCENTAGE]: '%',
};

export const PRICE_TYPES = [
  PRICE_TYPE.ABSOLUTE,
  PRICE_TYPE.PERCENTAGE,
];

export const INVENTORY_OPERATIONS = {
  [ETSY]: {
    [ATTRIBUTES.PRICE]: OPERATIONS[ETSY].PRICE.CHANGE_TO,
    [ATTRIBUTES.QUANTITY]: OPERATIONS[ETSY].QUANTITY.CHANGE_TO,
    [ATTRIBUTES.SKU]: OPERATIONS[ETSY].SKU.CHANGE_TO,
  },
  [SHOPIFY]: {
    [ATTRIBUTES.BARCODE]: OPERATIONS[SHOPIFY].BARCODE.INLINE_EDIT,
    [ATTRIBUTES.CAP]: OPERATIONS[SHOPIFY].CAP.INLINE_EDIT,
    [ATTRIBUTES.CPI]: OPERATIONS[SHOPIFY].CPI.INLINE_EDIT,
    [ATTRIBUTES.CHARGE_TAX]: OPERATIONS[SHOPIFY].CHARGE_TAX.INLINE_EDIT,
    [ATTRIBUTES.CONTINUE_SELLING]: OPERATIONS[SHOPIFY].CONTINUE_SELLING.INLINE_EDIT,
    [ATTRIBUTES.COUNTRY_CODE]: OPERATIONS[SHOPIFY].COUNTRY_CODE.INLINE_EDIT,
    [ATTRIBUTES.HS_CODE]: OPERATIONS[SHOPIFY].HS_CODE.INLINE_EDIT,
    [ATTRIBUTES.PHYSICAL]: OPERATIONS[SHOPIFY].PHYSICAL.INLINE_EDIT,
    [ATTRIBUTES.PRICE]: OPERATIONS[SHOPIFY].PRICE.INLINE_EDIT,
    [ATTRIBUTES.QUANTITY]: OPERATIONS[SHOPIFY].QUANTITY.INLINE_EDIT,
    [ATTRIBUTES.SKU]: OPERATIONS[SHOPIFY].SKU.INLINE_EDIT,
    [ATTRIBUTES.TRACK_QUANTITY]: OPERATIONS[SHOPIFY].QUANTITY.INLINE_EDIT,
    [ATTRIBUTES.UNIT]: OPERATIONS[SHOPIFY].WEIGHT.INLINE_EDIT,
    [ATTRIBUTES.WEIGHT]: OPERATIONS[SHOPIFY].WEIGHT.INLINE_EDIT,
    [ATTRIBUTES.SHIPPING]: OPERATIONS[SHOPIFY].WEIGHT.INLINE_EDIT,
  },
};

export const SIZE_OPERATIONS = {
  [OPERATIONS[ETSY].SIZE.SET]: {
    [ATTRIBUTES.HEIGHT]: OPERATIONS[ETSY].HEIGHT.SET,
    [ATTRIBUTES.LENGTH]: OPERATIONS[ETSY].LENGTH.SET,
    [ATTRIBUTES.WIDTH]: OPERATIONS[ETSY].WIDTH.SET,
  },
  [OPERATIONS[ETSY].SIZE.INCREASE_BY]: {
    [ATTRIBUTES.HEIGHT]: OPERATIONS[ETSY].HEIGHT.INCREASE_BY,
    [ATTRIBUTES.LENGTH]: OPERATIONS[ETSY].LENGTH.INCREASE_BY,
    [ATTRIBUTES.WIDTH]: OPERATIONS[ETSY].WIDTH.INCREASE_BY,
  },
  [OPERATIONS[ETSY].SIZE.DECREASE_BY]: {
    [ATTRIBUTES.HEIGHT]: OPERATIONS[ETSY].HEIGHT.DECREASE_BY,
    [ATTRIBUTES.LENGTH]: OPERATIONS[ETSY].LENGTH.DECREASE_BY,
    [ATTRIBUTES.WIDTH]: OPERATIONS[ETSY].WIDTH.DECREASE_BY,
  },
};

export const PLACEHOLDERS = {
  [ATTRIBUTES.BARCODE]: 'Barcode',
  [ATTRIBUTES.CAP]: 'CAP',
  [ATTRIBUTES.COLLECTIONS]: 'Choose Collections',
  [ATTRIBUTES.COUNTRY_CODE]: 'Choose country/region',
  [ATTRIBUTES.CPI]: 'CPI',
  [ATTRIBUTES.DESCRIPTION]: 'Description',
  [ATTRIBUTES.META_DESCRIPTION]: 'Description',
  [ATTRIBUTES.PAGE_TITLE]: 'Title',
  [ATTRIBUTES.URL_HANDLE]: 'URL',
  [ATTRIBUTES.PRICE]: 'Price',
  [ATTRIBUTES.PRODUCT_TYPE]: 'Choose Product type',
  [ATTRIBUTES.QUANTITY]: 'Quantity',
  [ATTRIBUTES.TITLE]: 'Title',
  [ATTRIBUTES.SKU]: 'SKU',
  [ATTRIBUTES.TITLE]: 'Title',
  [ATTRIBUTES.URL_HANDLE]: 'Enter',
  [ATTRIBUTES.VENDOR]: 'Choose Vendor',
  [ATTRIBUTES.WEIGHT]: 'Weight',
  FIND: 'Find',
  REPLACE: 'Replace',
};

export const BANNER = {
  AI_GENERATED: 'ai-generated',
  CONNECT_CANVA: 'connect-canva',
};
