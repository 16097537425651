import { Set } from 'immutable';
import Big from 'big.js';

import { addThousandsSeparator, formatFileSize } from '../utils/number';

import { EBAY, ETSY, SHOPIFY } from './channels';
import { VELA } from '.';
import ATTRIBUTES from './attributes';

export const MAX_NUMBER_OF_PHOTOS = {
  [ETSY]: 10,
  [SHOPIFY]: 255,
};

export const MAX_NUMBER_OF_VIDEOS = {
  [ETSY]: 1,
  [SHOPIFY]: 10,
};

export const MAX_PRICE = { // greater than valid value
  [ETSY]: 50000,
  [SHOPIFY]: 1000000000000000000,
};

export const MIN_PRICE = { // less than or equals valid value
  [ETSY]: 0.2,
  [SHOPIFY]: 0,
};

export const MAX_QUANTITY = { // greater than valid value
  [ETSY]: 1000,
  [SHOPIFY]: 1000000001,
};

export const MIN_QUANTITY = { // less than or equals valid value
  [ETSY]: 0,
  [SHOPIFY]: -1000000000,
};

export const MAX_SKU_LENGTH = {
  [ETSY]: 32,
  [SHOPIFY]: 255,
};

export const MAX_BARCODE_LENGTH = {
  [SHOPIFY]: 255,
};

export const MAX_TAG_LENGTH = {
  [ETSY]: 20,
  [SHOPIFY]: 255,
};

export const MAX_NUMBER_OF_TAGS = {
  [ETSY]: 13,
  [SHOPIFY]: 250,
};

export const MAX_NUMBER_OF_AI_TAGS = {
  [ETSY]: 13,
  [SHOPIFY]: 25,
};

export const MAX_NUMBER_OF_MATERIALS = {
  [ETSY]: 13,
};

export const MAX_MATERIAL_LENGTH = {
  [ETSY]: 45,
};

export const MAX_NUMBER_OF_SECTIONS = {
  [ETSY]: 20,
};

export const MAX_NUMBER_OF_FILES = {
  [ETSY]: 5,
};

export const MAX_PRODUCT_TYPE_LENGTH = 255;

export const MAX_VENDOR_LENGTH = 255;

export const MAX_SECTION_LENGTH = 24;

export const MAX_WEIGHT = {
  [ETSY]: 1000000000000,
  [SHOPIFY]: 2000000000,
};

export const MIN_WEIGHT = {
  [ETSY]: 0,
  [SHOPIFY]: 0,
};

export const MAX_HEIGHT = {
  [ETSY]: 1000000000000,
};

export const MIN_HEIGHT = {
  [ETSY]: 0,
};

export const MAX_LENGTH = {
  [ETSY]: 1000000000000,
};

export const MIN_LENGTH = {
  [ETSY]: 0,
};

export const MAX_WIDTH = {
  [ETSY]: 1000000000000,
};

export const MIN_WIDTH = {
  [ETSY]: 0,
};

export const MAX_TITLE_LENGTH = {
  [ETSY]: 140,
  [SHOPIFY]: 255,
};

export const MAX_SEO_TITLE_LENGTH = {
  [SHOPIFY]: 70,
};

export const MAX_SEO_DESCRIPTION_LENGTH = {
  [SHOPIFY]: 320,
};

export const MAX_FILE_NAME_LENGTH = {
  [ETSY]: 70,
};

export const MAX_DESCRIPTION_LENGTH = {
  [ETSY]: 102400,
};

export const PHOTO_DIMENSIONS_MINIMUM = {
  [ETSY]: 50,
  [SHOPIFY]: 50,
  [EBAY]: 500,
};

export const PHOTO_DIMENSIONS_MAXIMUM = {
  [ETSY]: 10000,
  [SHOPIFY]: 4472,
  [EBAY]: 9000,
};

export const MAX_PHOTO_SIZE = {
  [ETSY]: 10485760,
  [SHOPIFY]: 20971520,
  [EBAY]: 7340032,
};

export const MAX_ALT_TEXT_LENGTH = {
  [ETSY]: 250,
  [SHOPIFY]: 512,
};

export const IMAGE_FILE_FORMATS = {
  [ETSY]: Set(['gif', 'jpeg', 'jpg', 'png']),
  [SHOPIFY]: Set(['gif', 'jpeg', 'jpg', 'png', 'heic', 'webp', 'tiff']),
  [VELA]: Set(['gif', 'jpeg', 'jpg', 'png', 'heic', 'webp', 'tiff']),
  [EBAY]: Set(['gif', 'jpeg', 'jpg', 'png', 'heic', 'webp', 'tiff']),
};

export const VIDEO_FILE_FORMATS = {
  [ETSY]: Set(['mp4', 'quicktime']),
  [SHOPIFY]: Set(['mp4', 'quicktime']),
};

export const VIDEO_DURATION_MINIMUM = {
  [ETSY]: 5.0,
  [SHOPIFY]: 0.25,
};

export const VIDEO_DURATION_MAX = {
  [ETSY]: 60.0,
  [SHOPIFY]: 600.0,
};

export const MAX_VIDEO_SIZE = {
  [ETSY]: 104857600,
  [SHOPIFY]: 1073741824,
};

export const MAX_VIDEO_SIZE_FORMATTED = {
  [ETSY]: '100MB',
  [SHOPIFY]: '1GB',
};

export const VIDEO_DIMENSIONS_MINIMUM = {
  [ETSY]: 720,
  [SHOPIFY]: 240,
};

export const VIDEO_DIMENSIONS_MAXIMUM = {
  [ETSY]: 3840,
  [SHOPIFY]: 4096,
};

export const MAX_FILE_SIZE = {
  [ETSY]: 20971520,
};

export const PERSONALIZATION_CHARACTERS_COUNT_MIN = {
  [ETSY]: 1,
};

export const PERSONALIZATION_CHARACTERS_COUNT_MAX = {
  [ETSY]: 1024,
};

export const PERSONALIZATION_INSTRUCTION_LENGTH_MAX = {
  [ETSY]: 256,
};

export const TAXONOMY_LIMITS = {
  47626759760: 5,
  570246213556: 3,
};

function reduceFileFormats(result, value, index, array) {
  const format = `.${value}`;
  return result
    ? index === array.length - 1
      ? `${result} or ${format}`
      : `${result}, ${format}`
    : format;
}

export const ERRORS = {
  ALPHANUMERIC_BEGIN: 'Must begin with alphanumeric character',
  ALPHANUMERIC_CONTAIN: 'Must contain alphanumeric character',
  BARCODE: {
    LENGTH_EXCEEDED: {
      [SHOPIFY]: `Barcode should not exceed ${MAX_BARCODE_LENGTH[SHOPIFY]} characters`,
    },
  },
  CAP: {
    REQUIRED: 'Add CAP',
    TOO_LOW: {
      [SHOPIFY]: 'Compare at price must be positive number',
    },
    TOO_HIGH: {
      [SHOPIFY]: `Compare at price must be lower than $${addThousandsSeparator(MAX_PRICE[SHOPIFY])}`,
    },
  },
  CPI: {
    TOO_LOW: {
      [SHOPIFY]: `Cost per item must be positive number`,
    },
    TOO_HIGH: {
      [SHOPIFY]: `Cost per item must be lower than $${addThousandsSeparator(MAX_PRICE[SHOPIFY])}`,
    },
  },
  DESCRIPTION: {
    REQUIRED: 'Add description',
    LENGTH_EXCEEDED: {
      [ETSY]: `Description should not exceed ${MAX_DESCRIPTION_LENGTH[ETSY]} characters`,
    },
  },
  DETAILS: {
    ABOUT: {
      [ATTRIBUTES.IS_SUPPLY]: 'Choose what is it',
      [ATTRIBUTES.WHEN_MADE]: 'Choose when it was made',
      [ATTRIBUTES.WHO_MADE]: 'Choose who made it',
    },
    CATEGORY: 'Choose category',
    DIGITAL_MADE_TO_ORDER: 'Digital files cannot be included for "Made to Order" listings. Delete the files or update "When was it made?" to sync.',
    PRODUCT_TYPE: `Product type should not exceed ${MAX_PRODUCT_TYPE_LENGTH} characters`,
    PRODUCTION_PARTNERS: {
      REQUIRED: 'Production partner is required for this listing',
    },
    SECTION: {
      CAPITALIZED_WORDS_NUMBER_EXCEEDED: 'Section can contain at most three words with all capitalized letters',
      FORBIDDEN_CHARACTERS: 'Section may not contain any of these characters: ^ `',
      LENGTH_EXCEEDED: `Section should not exceed ${MAX_SECTION_LENGTH} characters`,
      SPECIAL_CHARACTERS_NUMBER_EXCEEDED: 'Section can contain characters % : & at most once',
    },
    VENDOR: `Vendor should not exceed ${MAX_VENDOR_LENGTH} characters`,
  },
  FILES: {
    REQUIRED: 'At least one file is required',
    TOO_BIG: {
      [ETSY]: `File must be less than ${formatFileSize(MAX_FILE_SIZE[ETSY])}`,
    },
    NAME_FORBIDDEN_CHARACTERS: {
      [ETSY]: 'File name must contain only letters, numbers, hyphens, underscores or periods',
    },
    NAME_LENGTH_EXCEEDED: {
      [ETSY]: `File name should not exceed ${MAX_FILE_NAME_LENGTH[ETSY]} characters`,
    },
  },
  MATERIALS: {
    FORBIDDEN_CHARACTERS: {
      [ETSY]: 'Material can only include spaces, letters and numbers',
    },
    LENGTH_EXCEEDED: {
      [ETSY]: `Material should not exceed ${MAX_MATERIAL_LENGTH[ETSY]} characters`,
    },
  },
  META_DESCRIPTION: {
    LENGTH_EXCEEDED: {
      [SHOPIFY]: `Description should not exceed ${MAX_SEO_DESCRIPTION_LENGTH[SHOPIFY]} characters`,
    },
  },
  NOT_A_NUMBER: 'Must be a number',
  PERSONALIZATION: {
    ALPHANUMERIC_BEGIN: 'Personalization instructions must begin with alphanumeric character',
    CAPITALIZED_WORDS_NUMBER_EXCEEDED: 'Personalization instructions can contain at most one word with all capitalized letters',
    LENGTH_EXCEEDED: {
      [ETSY]: `Personalization instructions should not exceed ${PERSONALIZATION_INSTRUCTION_LENGTH_MAX[ETSY]} characters`,
    },
    LIMIT_OUT_OF_RANGE: {
      [ETSY]: `Character limit must be a whole number between ${PERSONALIZATION_CHARACTERS_COUNT_MIN[ETSY]} and ${PERSONALIZATION_CHARACTERS_COUNT_MAX[ETSY]}`,
    },
  },
  PAGE_TITLE: {
    LENGTH_EXCEEDED: {
      [SHOPIFY]: `Title should not exceed ${MAX_SEO_TITLE_LENGTH[SHOPIFY]} characters`,
    },
  },
  PHOTOS: {
    ALT_TEXT_TOO_LONG: {
      [ETSY]: `Alt text should not exceed ${MAX_ALT_TEXT_LENGTH[ETSY]} characters`,
      [SHOPIFY]: `Alt text should not exceed ${MAX_ALT_TEXT_LENGTH[SHOPIFY]} characters`,
    },
    FILE_TOO_BIG: {
      [ETSY]: `Image file must be less than ${formatFileSize(MAX_PHOTO_SIZE[ETSY])}`,
      [SHOPIFY]: `Image file must be less than ${formatFileSize(MAX_PHOTO_SIZE[SHOPIFY])}`,
      [EBAY]: `Image file must be less than ${formatFileSize(MAX_PHOTO_SIZE[EBAY])}`,
    },
    LENGTH_EXCEEDED: 'Maximum number of photos reached',
    REQUIRED: 'Add photo',
    TOO_BIG: {
      [ETSY]: `Images must be at most ${addThousandsSeparator(PHOTO_DIMENSIONS_MAXIMUM[ETSY])} pixels in each dimension`,
      [SHOPIFY]: `Images must be at most ${addThousandsSeparator(PHOTO_DIMENSIONS_MAXIMUM[SHOPIFY])} pixels in each dimension`,
      [EBAY]: `Images must be at most ${addThousandsSeparator(PHOTO_DIMENSIONS_MAXIMUM[EBAY])} pixels in each dimension`,
    },
    TOO_SMALL: {
      [ETSY]: `Images must be at least ${PHOTO_DIMENSIONS_MINIMUM[ETSY]} pixels in each dimension`,
      [SHOPIFY]: `Images must be at least ${PHOTO_DIMENSIONS_MINIMUM[SHOPIFY]} pixels in each dimension`,
      [EBAY]: `Images must be at least ${PHOTO_DIMENSIONS_MINIMUM[EBAY]} pixels in each dimension`,
    },
    UNKNOWN: 'Image file is corrupted or unsupported',
    UNSUPPORTED_FORMAT: {
      [ETSY]: `File must be one of the following types: ${IMAGE_FILE_FORMATS[ETSY].reduce(reduceFileFormats, '')}`,
      [SHOPIFY]: `File must be one of the following types: ${IMAGE_FILE_FORMATS[SHOPIFY].reduce(reduceFileFormats, '')}`,
      [VELA]: `File must be one of the following types: ${IMAGE_FILE_FORMATS[VELA].reduce(reduceFileFormats, '')}`,
      [EBAY]: `File must be one of the following types: ${IMAGE_FILE_FORMATS[EBAY].reduce(reduceFileFormats, '')}`,
    },
  },
  VIDEOS: {
    FILES_TO_BIG: {
      [ETSY]: `Video file must be less than ${formatFileSize(MAX_VIDEO_SIZE[ETSY])}`,
      [SHOPIFY]: `Video file must be less than ${formatFileSize(MAX_VIDEO_SIZE[SHOPIFY])}`,
    },
    INVALID_URL: 'Invalid URL',
    MAX_ALLOWED: {
      [ETSY]: `Videos must be at most ${MAX_NUMBER_OF_VIDEOS[ETSY]} per listing`,
      [SHOPIFY]: `Videos must be at most ${MAX_NUMBER_OF_VIDEOS[ETSY]} per listing`,
    },
    MAX_DURATION: {
      [ETSY]: `Video must be less than ${VIDEO_DURATION_MAX[ETSY]} seconds long`,
      [SHOPIFY]: `Video must be at most ${VIDEO_DURATION_MAX[SHOPIFY]} seconds long`,
    },
    SMALL_DURATION: {
      [ETSY]: `Video must be at least ${VIDEO_DURATION_MINIMUM[ETSY]} seconds long`,
      [SHOPIFY]: `Video must be at least ${VIDEO_DURATION_MINIMUM[SHOPIFY]} seconds long`,
    },
    TOO_BIG: {
      [ETSY]: `Video must be at most ${addThousandsSeparator(VIDEO_DIMENSIONS_MAXIMUM[ETSY])}p resolution`,
      [SHOPIFY]: `Video must be at most ${addThousandsSeparator(VIDEO_DIMENSIONS_MAXIMUM[SHOPIFY])}p resolution`,
    },
    TOO_SMALL: {
      [ETSY]: `Video must be at least ${addThousandsSeparator(VIDEO_DIMENSIONS_MINIMUM[ETSY])}p resolution`,
      [SHOPIFY]: `Video must be at least ${addThousandsSeparator(VIDEO_DIMENSIONS_MINIMUM[SHOPIFY])}p resolution`,
    },
    UNKNOWN: 'Video file is corrupted or unsupported',
    UNSUPPORTED_FORMAT: {
      [ETSY]: `File must be one of the following types: ${VIDEO_FILE_FORMATS[ETSY].reduce(reduceFileFormats, '')}`,
      [SHOPIFY]: `File must be one of the following types: ${VIDEO_FILE_FORMATS[SHOPIFY].reduce(reduceFileFormats, '')}`,
    },
    UNSUPPORTED_URL: 'Only Youtube and Vimeo videos supported',
  },
  PRICE: {
    REQUIRED: 'Add price',
    TOO_LOW: {
      [ETSY]: `Price must be greater than $${addThousandsSeparator(MIN_PRICE[ETSY], 2, true)}`,
      [SHOPIFY]: `Price must be positive number`,
    },
    TOO_HIGH: {
      [ETSY]: `Price must be lower than $${addThousandsSeparator(MAX_PRICE[ETSY])}`,
      [SHOPIFY]: `Price must be lower than $${addThousandsSeparator(MAX_PRICE[SHOPIFY])}`,
    },
  },
  QUANTITY: {
    REQUIRED: 'Add quantity',
    INVALID: 'Quantity must be a whole number',
    IS_ZERO: 'At least one must be in stock',
    IS_ZERO_OFFERING: 'At least one offering must be in stock',
    TOO_LOW: {
      [ETSY]: `Quantity must be positive number`,
      [SHOPIFY]: `Quantity must be greater than ${addThousandsSeparator(MIN_QUANTITY[SHOPIFY])}`,
    },
    TOO_HIGH: {
      [ETSY]: `Quantity must be lower than ${addThousandsSeparator(MAX_QUANTITY[ETSY])}`,
      [SHOPIFY]: `Quantity must be lower than ${addThousandsSeparator(MAX_QUANTITY[SHOPIFY])}`,
    },
  },
  REQUIRED: 'Required',
  SHIPPING: {
    [ETSY]: {
      TEMPLATE: 'Choose shipping profile',
      DIGITAL: 'Shipping not required',
      HEIGHT: {
        REQUIRED: 'Calculated shipping requires item length, width, height',
        TOO_HIGH: `Height must be lower than ${addThousandsSeparator(MAX_HEIGHT[ETSY])}`,
        TOO_LOW: 'Height must be positive number',
      },
      LENGTH: {
        NOT_LARGEST: 'Length should be the largest dimension',
        REQUIRED: 'Calculated shipping requires item length, width, height',
        TOO_HIGH: `Length must be lower than ${addThousandsSeparator(MAX_LENGTH[ETSY])}`,
        TOO_LOW: 'Length must be positive number',
      },
      WIDTH: {
        REQUIRED: 'Calculated shipping requires item length, width, height',
        TOO_HIGH: `Width must be lower than ${addThousandsSeparator(MAX_WIDTH[ETSY])}`,
        TOO_LOW: 'Width must be positive number',
      },
      WEIGHT: {
        REQUIRED: 'Calculated shipping requires item weight',
        TOO_HIGH: `Weight must be lower than ${addThousandsSeparator(new Big(MAX_WEIGHT[ETSY]).div(16))}`,
        TOO_LOW: 'Weight must be positive number',
      },
      RETURN_POLICY: 'Choose return policy',
    },
    [SHOPIFY]: {
      WEIGHT: {
        REQUIRED: 'Add weight',
        TOO_HIGH: `Weight must be lower than ${addThousandsSeparator(MAX_WEIGHT[SHOPIFY])}`,
        TOO_LOW: 'Weight must be positive number',
      },
    },
  },
  SKU: {
    LENGTH_EXCEEDED: {
      [ETSY]: `SKU should not exceed ${MAX_SKU_LENGTH[ETSY]} characters`,
      [SHOPIFY]: `SKU should not exceed ${MAX_SKU_LENGTH[SHOPIFY]} characters`,
    },
    FORBIDDEN_CHARACTERS: {
      [ETSY]: 'SKU may not contain any of these characters: ^ $ `',
    },
    ALPHANUMERIC_BEGIN: 'SKU must begin with alphanumeric character',
  },
  TAGS: {
    LENGTH_EXCEEDED: {
      [ETSY]: `Tag should not exceed ${MAX_TAG_LENGTH[ETSY]} characters`,
      [SHOPIFY]: `Tag should not exceed ${MAX_TAG_LENGTH[SHOPIFY]} characters`,
    },
    FORBIDDEN_CHARACTERS: {
      [ETSY]: 'Tag can only include spaces, letters, hyphens, numbers and apostrophe',
    },
    ALPHANUMERIC_BEGIN: 'Tag must begin with alphanumeric character',
    ALPHANUMERIC_CONTAIN: 'Tag must contain alphanumeric character',
  },
  TITLE: {
    REQUIRED: 'Add title',
    ALPHANUMERIC_BEGIN: 'Title must begin with alphanumeric character',
    FORBIDDEN_CHARACTERS: {
      [ETSY]: 'Title can contain only alphanumeric, punctuation, ™©® characters and mathematical symbols',
    },
    LENGTH_EXCEEDED: {
      [ETSY]: `Title should not exceed ${MAX_TITLE_LENGTH[ETSY]} characters`,
      [SHOPIFY]: `Title should not exceed ${MAX_TITLE_LENGTH[SHOPIFY]} characters`,
    },
    CAPITALIZED_WORDS_NUMBER_EXCEEDED: 'Title can contain at most three words with all capitalized letters',
    SPECIAL_CHARACTERS_NUMBER_EXCEEDED: 'Title can contain characters % : & at most once',
  },
  URL_HANDLE: {
  },
};
