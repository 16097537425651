import { isImmutable } from 'immutable';

import { isObject } from '../isObject';

export function get(...fields) {
  function getValueByPath(object, path) {
    if (!path || !isObject(object) || !Array.isArray(path)) return undefined;

    return path.length
      ? path.length === 1
        ? object[path[0]]
        : getValueByPath(object[path[0]], path.slice(1))
      : object;
  }

  return function getFromObject(instance) {
    const result = [];

    if (isImmutable(instance)) {
      for (const key of fields) {
        if (typeof key === 'string') {
          result.push(instance.get(key));
        } else if (Number.isFinite(key)) {
          result.push(instance.get(String(key)));
        } else if (Array.isArray(key)) {
          result.push(instance.getIn(key));
        }
      }
    } else if (isObject(instance)) {
      for (const key of fields) {
        if (typeof key === 'string') {
          result.push(instance[key]);
        } else if (Number.isFinite(key)) {
          result.push(instance[String(key)]);
        } else if (Array.isArray(key)) {
          result.push(getValueByPath(instance, key));
        }
      }
    }

    return result.length < 2
      ? result[0]
      : result;
  };
}
