import ActionConstants, { Actions } from '../constants/actions';

const editConstants = new Actions('Edit')
  .add('add_placeholders_for_new_listings')
  .add('cancel_edit')
  .add('create_new')
  .add('get_data')
  .addAsync('get_linked_products')
  .add('get_product')
  .addAsync('get_product_data')
  .add('remove_new')
  .add('save_as')
  .addAsync('save_as_profile')
  .addAsync('save_new')
  .addAsync('save_to_channels')
  .add('set_data')
  .addAsync('set_listings_profile')
  .add('set_modal')
  .add('set_product')
  .addAsync('set_profile')
  .add('toggle_shop')
  .add('wait_for_operations');

ActionConstants.add(editConstants);

export default editConstants.actionCreators();
