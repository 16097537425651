/* eslint-disable no-console */
import { sideEffect } from 'redux-side-effects';
import { List, Map, Set } from 'immutable';

import { addProductIdToOperations, shapeLinkedProductsOperations } from '../utils/product/shapeOperations';
import { reduceShopIdsToChannels, shapeProductForApp, sortShops } from '../utils/product/shapeForApp';
import { shapeNewLinkedProductsForAPI, shapeNewProductForAPI } from '../utils/product/shapeForAPI';
import { mapLinkedProducts, shapeLinkedProductForAPI } from '../utils/product/linkedProducts';
import { getExcludedSections, shapeTemplatesForAPI } from '../utils/profiles/listingsProfiles';
import { canChangeStatuses, filterStatusOperations } from '../utils/product/statuses';
import { getOperationsFromProduct } from '../utils/product/getOperationsFromProduct';
import { shouldAddPlaceholder } from '../utils/product/shouldAddPlaceholder';
import { getConnectedShopIds } from '../utils/product/getConnectedShopIds';
import { getListingsPath } from '../utils/listings/getListingsPath';
import { canConnectShop } from '../utils/product/canConnectShop';
import { editNewProduct } from '../utils/product/editNewProduct';
import { getPlaceholder } from '../utils/listings/placeholders';
import { shapeForTable } from '../utils/product/shapeForTable';
import { applyProfile } from '../utils/product/applyProfile';
import { clearProfile } from '../utils/product/clearProfile';
import { areComplete } from '../utils/product/isComplete';
import { navigateTo } from '../utils/navigation';
import { shapeId } from '../utils/listings/listings';
import { getSize } from '../utils/iterable/getSize';
import amplitude from '../utils/tracking/amplitude';
import mixpanel from '../utils/tracking/mixpanel';
import api from '../utils/api';

import { POLLING_INTERVAL, WAIT_FOR_SYNC } from '../constants/shops';
import { MESSAGE, NOTIFICATION } from '../constants/notifications';
import { ENDPOINTS, NEW, VALUE } from '../constants/product';
import { DEFAULTS, VELA } from '../constants';
import { PHOTOS, TITLE } from '../constants/attributes';
import { CHANNEL_ID } from '../constants/channels';
import { STATUS } from '../constants/listings';
import { PROFILE } from '../constants/profiles';
import { MODALS } from '../constants/modal';
import ACTIONS from '../constants/actions';

import Reducers, { Reducer } from '../classes/Reducer';
import Actions from '../actions';

const { EMPTY_LIST, EMPTY_MAP, MINUS_ONE } = DEFAULTS;

function* addPlaceholdersForNewListings(reduction, { edit, shopIds, status }) {
  yield sideEffect((dispatch) => {
    const shops = Set(edit.get('shops'));

    function addPlaceholder(shopId) {
      const product = edit.getIn(['products', shopId]);

      if (!product) return;

      dispatch(
        Actions.Listings.addListingPlaceholder({
          placeholder: getPlaceholder({
            channel: product.get('channel'),
            product: shapeForTable({
              product,
              shopData: reduction.getIn(['data', 'shopsData', shopId]),
              shopIds: shops.delete(shopId).toList(),
              userShops: reduction.get('shops'),
            }),
          }),
          shopId,
          status,
        })
      );
    }

    shopIds.forEach(addPlaceholder);
  });

  return reduction;
}

function* cancelEdit(reduction) {
  if (!getSize(reduction.get('edit'))) {
    return reduction;
  }

  let edit = EMPTY_MAP;
  const processing = reduction.getIn(['edit', 'processing']);
  const syncStarted = reduction.getIn(['edit', 'syncStarted']);

  if (processing) {
    edit = edit.set('processing', processing);
  }

  if (syncStarted) {
    edit = edit.set('syncStarted', syncStarted);
  }

  return reduction.set('edit', edit);
}

function* createNew(reduction, payload = {}) {
  const { currentShopId, event, products, shopIds, switchTo } = payload;
  const userShops = reduction.get('shops');
  let edit = reduction.get('edit');
  const session = edit.get('session');
  edit = edit.delete('profile').delete('session');

  function reduceShopIds(data, shopId) {
    return userShops.hasIn(['byId', shopId])
      ? editNewProduct({
        currentShopId,
        edit: data,
        products,
        shopId,
        userShops,
      })
      : data;
  }

  yield sideEffect((dispatch) => {
    dispatch(Actions.Edit.getData());

    if (event) {
      mixpanel.track(event, mixpanel.getChannelsAndPlans({ shopIds, state: reduction }));
    }
  });

  edit = shopIds.reduce(reduceShopIds, edit);

  if (switchTo) {
    edit = edit.set('switchTo', switchTo);
  }

  return reduction.set('edit', edit.set('session', session));
}

function* getData(reduction) {
  yield sideEffect((dispatch) => {
    reduction.getIn(['edit', 'shops']).forEach(function getAllData(shopId) {
      dispatch(Actions.Data.getAllData(shopId));
    });
  });

  return reduction;
}

function* getLinkedProducts(reduction, { db, linkId, productId, shopId }) {
  yield sideEffect((dispatch) => {
    api.products.getLinkedProducts({ productId, db, linkId })
      .then(
        (products) => dispatch(Actions.Edit.getLinkedProductsSucceeded({ linkId, products, shopId })),
        (error) => dispatch(Actions.Edit.getLinkedProductsFailed(error))
      );
  });

  return reduction;
}

function* getLinkedProductsFailed(reduction, error) {
  yield sideEffect(() => {
    console.error('Error encountered while getting linked product: ', error);
    navigateTo(getListingsPath(reduction));
  });

  return reduction.set('edit', EMPTY_MAP);
}

function* getLinkedProductsSucceeded(reduction, { linkId, products, shopId }) {
  const linkedProducts = List(products.map(mapLinkedProducts(linkId)));
  const userShops = reduction.get('shops');
  let edit = reduction.get('edit');

  if (!getSize(linkedProducts) && getSize(edit.get('shops')) === 1) return reduction;

  function reduceProducts(result, linkedProduct) {
    result.shops = result.shops.push(linkedProduct.get('shopId'));
    result.actions.push(
      Actions.Edit.getProductData({
        productId: linkedProduct.get('productId'),
        shopId: linkedProduct.get('shopId'),
        channel: linkedProduct.get('channel'),
        db: userShops.getIn(['byId', linkedProduct.get('shopId'), 'db']),
      })
    );

    return result;
  }

  const { actions, shops } = linkedProducts.reduce(
    reduceProducts,
    { actions: [], shops: List([shopId]) }
  );

  if (getSize(actions)) {
    yield sideEffect((dispatch) => {
      actions.forEach(dispatch);
    });
  }

  edit = edit
    .set('linkedProducts', linkedProducts)
    .set('shops',
      shops.sort(
        sortShops({
          first: edit.get('shopId'),
          prioritized: getConnectedShopIds(edit.get('products')),
          sorted: userShops.get('options'),
        })
      )
    );

  if (edit.has('channels')) {
    edit = edit.set('channels',
      edit.get('shops').reduce(reduceShopIdsToChannels(userShops.get('byId')), EMPTY_MAP)
    );
  }

  return reduction.set('edit', edit);
}

function* getProduct(reduction, action = {}) {
  const {
    productId = reduction.getIn(['edit', 'productId']),
    shopId = reduction.getIn(['edit', 'shopId']),
  } = action;

  const shop = reduction.getIn(['shops', 'byId', shopId]);

  if (!productId || !shop) {
    yield sideEffect((dispatch) => {
      dispatch(Actions.Edit.cancelEdit());
    });

    return reduction;
  }

  const db = shop.get('db');
  const channel = shop.get('channel');
  const linkId = reduction.getIn(['edit', 'linkId']);

  yield sideEffect((dispatch) => {
    dispatch(Actions.Edit.getProductData({ channel, db, productId, shopId }));

    if (linkId) {
      dispatch(Actions.Edit.getLinkedProducts({ db, linkId, productId, shopId }));
    }

    if (getSize(reduction.getIn(['edit', 'shops']))) {
      dispatch(Actions.Edit.getData());
    }
  });

  return reduction
    .deleteIn(['edit', 'linkedProducts'])
    .deleteIn(['edit', 'waitingForOperationsToBeApplied']);
}

function* getProductData(reduction, { asProfile, channel, db, productId, shopId } ) {
  yield sideEffect((dispatch) => {
    dispatch(Actions.Data.getAllData(shopId));

    api.products
      .getById({ channel, db, payload: { id: [productId] }, shopId })
      .then(
        (data) => dispatch(
          Actions.Edit.getProductDataSucceeded({ asProfile, channel, data, db, productId, shopId })
        ),
        (error) => dispatch(Actions.Edit.getProductDataFailed({ asProfile, error, shopId }))
      );
  });

  return reduction;
}

function* getProductDataFailed(reduction, { asProfile, error, shopId }) {
  if (!reduction.getIn(['edit', 'productId'])) return reduction;

  console.error('Error encountered while getting product: ', error);

  function filterShopIds(item) {
    return item !== shopId;
  }

  if (asProfile) {
    const shops = reduction
      .getIn(['edit', 'shops'], EMPTY_LIST)
      .filter(filterShopIds);

    if (getSize(shops)) {
      return reduction
        .setIn(['edit', 'shops'], shops)
        .setIn(['edit', 'channels'],
          shops.reduce(reduceShopIdsToChannels(reduction.getIn(['shops', 'byId'])), EMPTY_MAP)
        );
    }

    yield sideEffect((dispatch) => {
      dispatch(
        Actions.Notifications.add({
          type: NOTIFICATION.ERROR,
          message: MESSAGE.FAIL.LOAD_DATA,
        })
      );
    });

    return reduction
      .deleteIn(['edit', 'profile'])
      .setIn(['edit', 'channels'], EMPTY_MAP)
      .setIn(['edit', 'shops'], shops);
  }

  if (!reduction.getIn(['edit', 'processing'])) {
    yield sideEffect(() => {
      navigateTo(getListingsPath(reduction));
    });

    return reduction.set('edit', EMPTY_MAP);
  }

  yield sideEffect((dispatch) => {
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_DATA,
      })
    );
  });

  return reduction;
}

function* getProductDataSucceeded(reduction, { asProfile, channel, data, db, productId, shopId }) {
  let edit = reduction.get('edit');

  if (!edit.get('productId')) return reduction;

  if (!getSize(data?.products)) {
    yield sideEffect((dispatch) => {
      dispatch(
        Actions.Edit.getProductDataFailed({
          asProfile,
          error: `Product "${productId}" was not found in the shop "${shopId}" at DB "${db}"`,
          shopId,
        })
      );
    });

    return reduction;
  }

  let product;

  try {
    product = shapeProductForApp({ channel, data, shopId });
  } catch (error) {
    yield sideEffect((dispatch) => {
      dispatch(Actions.Edit.getProductDataFailed({ asProfile, error, shopId }));
    });

    return reduction;
  }

  function updateStatuses(statuses = EMPTY_MAP) {
    return statuses.set(shopId, product.get('status'));
  }

  const userShops = reduction.get('shops');

  if (asProfile) {
    product = product
      .delete('status')
      .delete('errors')
      .delete('isComplete')
      .set('status', STATUS.ACTIVE)
      .set('productId', NEW)
      .set('templateId', productId)
      .set('operations',
        getOperationsFromProduct({
          archived: edit.getIn(['archive', 'products', shopId], EMPTY_MAP),
          channel,
          product,
        })
      );

    edit = edit.set('channels',
      edit.get('shops').reduce(reduceShopIdsToChannels(userShops.get('byId')), EMPTY_MAP)
    );

    if (!getSize(edit.get('products'))) {
      edit = edit
        .set('title', product.getIn([TITLE, VALUE]))
        .set('isComplete', !getSize(product.get('errors')))
        .set('thumbnailUrl', product.getIn([PHOTOS, VALUE, 0, 'fullsize_url']));
    }
  } else {
    const currentShopId = edit.get('shopId');

    if (canConnectShop({ connectedShops: edit.get('shops', EMPTY_LIST), userShops })(shopId)) {
      edit = edit.set('shops',
        edit
          .get('shops', EMPTY_LIST)
          .push(shopId)
          .sort(
            sortShops({
              fitst: currentShopId,
              prioritized: getConnectedShopIds(edit.get('products')).add(shopId),
              sorted: userShops.get('options'),
            })
          )
      );
    }

    if (
      shopId === currentShopId && (
        !edit.has('title') || (
          edit.has('processing') &&
          !getSize(edit.get('products')) &&
          edit.getIn(['processing', 'session']) === edit.get('session')
        )
      )
    ) {
      edit = edit
        .set('title', product.getIn([TITLE, VALUE]))
        .set('status', product.get('status'))
        .set('channelProductId', product.get('channelProductId'))
        .set('linkId', product.get('linkId'))
        .set('channel', product.get('channel'))
        .set('isComplete', !getSize(product.get('errors')))
        .set('thumbnailUrl', product.getIn([PHOTOS, VALUE, 0, 'fullsize_url']));

      if (edit.get('linkId') && !edit.has('processing')) {
        edit = edit.set('shops',
          edit
            .get('shops', EMPTY_LIST)
            .concat(
              product
                .get('linkedProductsShopIds')
                .filter(canConnectShop({ connectedShops: edit.get('shops', EMPTY_LIST), userShops }))
            )
            .sort(
              sortShops({
                first: currentShopId,
                prioritized: getConnectedShopIds(edit.get('products')).add(shopId),
                sorted: userShops.get('options'),
              })
            )
        );

        if (getSize(edit.get('shops')) > 1) {
          yield sideEffect((dispatch) => {
            dispatch(
              Actions.Edit.getLinkedProducts({
                db,
                linkId: edit.get('linkId'),
                productId: edit.get('productId'),
                shopId,
              })
            );
          });
        }
      }
    }

    edit = edit.set('channels',
      edit.get('shops').reduce(reduceShopIdsToChannels(userShops.get('byId')), EMPTY_MAP)
    );

    if (edit.has('processing') && edit.getIn(['processing', 'session']) === edit.get('session')) {
      edit = edit.deleteIn(['processing', 'products', shopId]);

      if (!getSize(edit.getIn(['processing', 'products']))) {
        edit = edit.delete('processing');
      }
    }
  }

  edit = edit
    .setIn(['products', shopId], product)
    .update('statuses', updateStatuses);

  return reduction.set('edit', edit.set('isComplete', areComplete(edit.get('products'))));
}

function* removeNew(reduction, shopId) {
  let edit = reduction.get('edit');

  function filterShopIds(item) {
    return item !== shopId;
  }

  function removeFromShops(shops) {
    return shops.filter(filterShopIds);
  }

  function updateChannels(channels) {
    const channel = reduction.getIn(['shops', 'byId', shopId, 'channel']);
    const shopIds = channels.get(channel, EMPTY_LIST).filter(filterShopIds);

    return getSize(shopIds)
      ? channels.set(channel, shopIds)
      : channels.delete(channel);
  }

  function removeByShopId(items = EMPTY_MAP) {
    return items.delete(shopId);
  }

  edit = edit
    .delete('profile')
    .update('shops', removeFromShops)
    .update('channels', updateChannels)
    .update('products', removeByShopId)
    .update('statuses', removeByShopId);

  if (!getSize(edit.get('shops'))) {
    edit = edit.delete(VELA);
  } else {
    edit = edit.set('shops',
      edit
        .get('shops')
        .sort(
          sortShops({
            first: edit.get('shopId'),
            prioritized: getConnectedShopIds(edit.get('products')),
            sorted: reduction.getIn(['shops', 'options']),
          })
        )
    );
  }

  return reduction.set('edit', edit.set('isComplete', areComplete(edit.get('products'))));
}

function* saveAs(reduction, { statuses, velaStatus, ...rest }) {
  let edit = reduction.get('edit');

  function track({ event, ...properties }) {
    mixpanel.track(event, properties);
  }

  if (!edit.get('syncStarted')) {
    const { canChange, data, events, indicators, modal } = canChangeStatuses({
      data: edit,
      state: reduction,
      statuses,
    });

    if (data) {
      edit = data;
    }

    if (events) {
      events.forEach(track);
    }

    if (!canChange) {
      if (modal) {
        yield sideEffect((dispatch) => {
          dispatch(Actions.Edit.setModal(modal));
        });
      }

      return reduction.set('edit', edit);
    }

    yield sideEffect((dispatch) => {
      function setSync({ shopId, ...syncData }) {
        dispatch(
          Actions.Shops.setData({
            path: ['syncData', shopId],
            value: Map(syncData),
          })
        );
      }

      if (velaStatus !== STATUS.TEMPLATE) {
        indicators.forEach(setSync);
      }

      dispatch(Actions.Edit.saveAs({ statuses, velaStatus, ...rest }));
    });

    if (!getSize(edit.get('processing'))) {
      edit = edit
        .set('products', EMPTY_MAP)
        .set('processing', edit);
    }

    return reduction.set('edit', edit.set('syncStarted', true));
  } else if (edit.get('session') === edit.getIn(['processing', 'session']) && edit.has('modal')) {
    edit = edit.delete('modal');
  }

  yield sideEffect((dispatch) => {
    if (edit.get('productId') === NEW) {
      dispatch(Actions.Edit.saveNew({ statuses }));
    } else {
      dispatch(Actions.Edit.saveToChannels({ statuses }));
    }
  });

  return reduction.set('edit', edit.delete('waitForSync').delete('syncStarted'));
}

function* saveAsProfile(reduction, { name }) {
  yield sideEffect((dispatch) => {
    const edit = reduction.get('edit');
    const products = edit.get('products');
    const channels = edit.get('channels').keySeq().toSet();
    const excludedSections = getExcludedSections({ channels, products });
    const templates = shapeTemplatesForAPI({
      excludedSections,
      products,
      userShops: reduction.get('shops'),
      userId: reduction.getIn(['user', 'userId']),
    });

    const payload = { excluded_sections: excludedSections, name, templates };

    api.profiles
      .create({ payload, type: PROFILE.LISTINGS })
      .then(
        (response) => {
          if (response.status === 201) {
            dispatch(
              Actions.Edit.saveAsProfileSucceeded({
                shopId: edit.get('shopId'),
                name,
                response,
              })
            );
          } else {
            dispatch(Actions.Edit.saveAsProfileFailed(response));
          }
        },
        (error) => dispatch(Actions.Edit.saveAsProfileFailed(error)),
      );
  });

  return reduction.setIn(['edit', 'modal', 'processing'], true);
}

function* saveAsProfileFailed(reduction, error) {
  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.CREATE_PROFILE,
      })
    );
  });

  return reduction.deleteIn(['edit', 'modal']);
}

function* saveAsProfileSucceeded(reduction, { name, response, shopId }) {
  yield sideEffect((dispatch) => {
    const getProfilesPayload = { shopId: shopId, type: PROFILE.LISTINGS, force: true };

    dispatch(
      Actions.Data.getProfiles(getProfilesPayload)
    );

    dispatch(
      Actions.Notifications.add({
        message: MESSAGE.SUCCESS.CREATE_PROFILE,
        name,
        profileId: shapeId(response.profileId),
        profileType: PROFILE.LISTINGS,
        shopId,
        type: NOTIFICATION.SAVED_AS_PROFILE,
      })
    );
  });

  return reduction.deleteIn(['edit', 'modal']);
}

function* saveNew(reduction, { statuses }) {
  yield sideEffect((dispatch) => {
    // DEV-676 Amplitude events trimming
    // const EVENTS = {
    //   [STATUS.ACTIVE]: 'Clicked "Publish" in create new listing page',
    //   [STATUS.DRAFT]: 'Clicked "Save as draft" in create new listing page',
    // };

    // amplitude.logEvent(EVENTS[status]);

    dispatch(
      Actions.Shops.rescheduleShopsPolling(
        reduction.getIn(['user', 'config', 'shopsPollingIntervalShort']) ||
        POLLING_INTERVAL.SHORT
      )
    );

    const edit = reduction.getIn(['edit', 'processing']);
    const isLinkedProduct = getSize(edit.get('shops')) > 1;
    const shopId = edit.get('shopId');
    const db = reduction.getIn(['shops', 'byId', shopId, 'db']);
    const channel = reduction.getIn(['shops', 'byId', shopId, 'channel']);
    const userId = reduction.getIn(['user', 'userId']);
    const request = { params: { db }};

    if (isLinkedProduct) {
      const shopsById = reduction.getIn(['shops', 'byId']);
      request.payload = shapeNewLinkedProductsForAPI({ edit, shopsById, statuses, userId });
      request.url = `/shop/${shopId}/${ENDPOINTS.CREATE.LINKED}`;
    } else {
      const product = edit.getIn(['products', shopId]);
      request.payload = {
        listingProfileId: edit.get('profile'),
        operations: shapeNewProductForAPI({ channel, product, shopId, status: statuses.get(shopId), userId }),
      };
      request.url = `/shops/${shopId}/${ENDPOINTS.CREATE[channel]}`;
    }

    api
      .post(request)
      .then(
        (response) => dispatch(Actions.Edit.saveNewSucceeded({ channel, response, shopId, statuses })),
        (error) => dispatch(Actions.Edit.saveNewFailed({ error }))
      );
  });

  return reduction;
}

function* saveNewFailed(reduction, { error }) {
  const edit = reduction.getIn(['edit', 'processing']);

  yield sideEffect((dispatch) => {
    function removeSyncIndicator(shopId) {
      dispatch(Actions.Shops.setData({ path: ['syncData', shopId] }));
    }

    console.error(error || 'Unknown error encountered when posting new listing');
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.CREATE_LISTINGS,
      })
    );

    if (getSize(edit.get('shops')) > 1) {
      edit.get('shops').forEach(removeSyncIndicator);
    } else {
      removeSyncIndicator(edit.getIn(['shops', 0]));
    }
  });

  return reduction.getIn(['edit', 'session']) === edit.get('session')
    ? reduction.set('edit', edit.set('products', edit.get('products').map(filterStatusOperations)))
    : reduction.deleteIn(['edit', 'processing']);
}

function* saveNewSucceeded(reduction, { response, shopId, statuses }) {
  switch (response?.result) {
    case 'ok':
    case 'succeeded': {
      yield sideEffect((dispatch) => {
        const edit = reduction.getIn(['edit', 'processing']);
        const status = statuses.get(shopId);

        dispatch(Actions.Edit.addPlaceholdersForNewListings({ edit, shopIds: edit.get('shops'), status }));

        if (reduction.getIn(['edit', 'session']) === edit.get('session')) {
          navigateTo(getListingsPath(reduction.setIn(['listings', 'status'], status)));
        }
      });

      return reduction.deleteIn(['edit', 'processing']);
    }

    default: {
      yield sideEffect((dispatch) => {
        dispatch(Actions.Edit.saveNewFailed({ error: response?.error }));
      });

      return reduction;
    }
  }
}

function* saveToChannels(reduction, { statuses }) {
  yield sideEffect((dispatch) => {
    const shopsById = reduction.getIn(['shops', 'byId']);

    function reduceLinkedProducts(result, product) {
      result.push(shapeLinkedProductForAPI(product, shopsById));
      return result;
    }

    function removeSyncIndicator(shopId) {
      dispatch(Actions.Shops.setData({ path: ['syncData', shopId] }));
    }

    const edit = reduction.getIn(['edit', 'processing']);
    const shopId = edit.get('shopId');
    const shop = shopsById.get(shopId);
    const channel = shop.get('channel');
    const db = shop.get('db');
    const shops = edit.get('shops');
    const waitForSync = edit.get('waitForSync', WAIT_FOR_SYNC.START_OR_FINISH);
    const isLinkedProduct = getSize(shops) > 1;

    amplitude.logEvent({
      type: 'Started sync',
      options: {
        channel,
        type: 'single edit',
        number: getSize(shops),
      },
    });

    dispatch(
      Actions.Shops.rescheduleShopsPolling(
        reduction.getIn(['user', 'config', 'shopsPollingIntervalShort']) ||
        POLLING_INTERVAL.SHORT
      )
    );

    if (isLinkedProduct) {
      const linkId = edit.get('linkId');
      const payload = {
        db,
        linkId,
        shopId,
        ...shapeLinkedProductsOperations({
          edit,
          linkId,
          shopsById,
          shopId,
          statuses,
          userId: reduction.getIn(['user', 'userId']),
        }),
      };

      if (!getSize(payload.newProducts) && !getSize(payload.productsToEdit)) {
        edit.get('shops').forEach(removeSyncIndicator);
        dispatch(Actions.Edit.getProduct());
        return;
      }

      if (getSize(payload.newProducts)) {
        payload.existingProducts = [
          {
            shop_id: parseInt(shopId, 10),
            channel_id: CHANNEL_ID[channel],
            product_id: parseInt(edit.getIn(['products', shopId, 'productId']), 10),
            channel_product_id: edit.getIn(['products', shopId, 'channelProductId']),
            db,
          },
          ...edit.get('linkedProducts', EMPTY_LIST).reduce(reduceLinkedProducts, []),
        ];
      }

      edit.get('products').forEach(function forEachProduct(product, key) {
        const productId = product.get('productId');
        const status = statuses.get(key);

        if (getSize(product.get('operations'))) {
          dispatch(Actions.Listings.toggleState({ listings: [productId], state: ['processing', key] }));

          if (shouldAddPlaceholder({ product, status })) {
            dispatch(Actions.Edit.addPlaceholdersForNewListings({ edit, shopIds: [key], status }));
          }
        }
      });

      api.products
        .editLinkedProducts(payload)
        .then(
          (response) => dispatch(Actions.Edit.saveToChannelsSucceeded({ response, waitForSync })),
          (error) => dispatch(Actions.Edit.saveToChannelsFailed({ error }))
        );
    } else {
      const product = edit.getIn(['products', shopId]);
      const operations = addProductIdToOperations({ product });

      if (!getSize(operations)) {
        dispatch(Actions.Edit.getProduct());
        removeSyncIndicator(shopId);
        return;
      }

      const productId = product.get('productId');
      const status = statuses.get(shopId);

      dispatch(Actions.Listings.toggleState({ listings: [productId], state: ['processing', shopId] }));

      if (shouldAddPlaceholder({ product, status })) {
        dispatch(Actions.Edit.addPlaceholdersForNewListings({ edit, shopIds: [shopId], status }));
      }

      api.products.editSingleProduct({ db, operations, shopId })
        .then(
          () => dispatch(Actions.Edit.saveToChannelsSucceeded({ waitForSync })),
          (error) => dispatch(Actions.Edit.saveToChannelsFailed({ error }))
        );
    }
  });

  return reduction;
}

function* saveToChannelsFailed(reduction, { error }) {
  const edit = reduction.getIn(['edit', 'processing']);

  yield sideEffect((dispatch) => {
    function removeSyncIndicator(shopId) {
      dispatch(Actions.Shops.setData({ path: ['syncData', shopId] }));
    }

    console.error('Error encountered while syncing updates: ', error);

    dispatch(Actions.Notifications.add({ message: MESSAGE.FAIL.SYNC, type: NOTIFICATION.ERROR }));

    edit.get('shops').forEach(removeSyncIndicator);
  });

  return reduction.getIn(['edit', 'session']) === edit.get('session')
    ? reduction.set('edit', edit.set('products', edit.get('products').map(filterStatusOperations)))
    : reduction.deleteIn(['edit', 'processing']);
}

function* saveToChannelsSucceeded(reduction, { response, waitForSync }) {
  yield sideEffect((dispatch) => {
    const edit = reduction.getIn(['edit', 'processing']);

    if (reduction.getIn(['edit', 'session']) === edit.get('session')) {
      if (response?.linkId) {
        dispatch(Actions.Edit.setData({ linkId: shapeId(response.linkId.id) }));
      }

      dispatch(Actions.Shops.setData({ path: 'waitForSync', value: waitForSync }));
    }
  });

  return reduction.deleteIn(['edit', 'processing']);
}

function* setData(reduction, data) {
  let state = reduction;

  for (const field in data) {
    if (data.hasOwnProperty(field)) {
      state = state.setIn(['edit', field], data[field]);
    }
  }

  return state;
}

function* setListingsProfile(reduction, { event, profileId }) {
  let edit = reduction.get('edit').delete(VELA);

  if (profileId === MINUS_ONE) {
    const archive = edit.get('archive');

    return getSize(archive)
      ? reduction.set('edit', archive)
      : reduction
        .deleteIn(['edit', 'profile'])
        .setIn(['edit', 'channels'], EMPTY_MAP)
        .setIn(['edit', 'products'], EMPTY_MAP)
        .setIn(['edit', 'shops'], EMPTY_LIST);
  }

  if (!edit.has('archive')) {
    edit = edit.set('archive', edit);
  }

  function reduceTemplates(result, template) {
    const channel = template.get('channel');
    const db = template.get('db');
    const productId = template.get('productId');
    const shopId = template.get('shopId');
    result.shops = result.shops.push(shopId);
    result.actions.push(
      Actions.Edit.getProductData({ asProfile: true, channel, db, productId, shopId })
    );

    return result;
  }

  const userShops = reduction.get('shops');
  const shopId = reduction.getIn(['shops', 'current']);

  const { actions, shops } = reduction
    .getIn(['data', 'shopsData', shopId, 'profiles', PROFILE.LISTINGS, 'byId', profileId, 'templates'], EMPTY_LIST)
    .reduce(reduceTemplates, { actions: [], shops: EMPTY_LIST });

  yield sideEffect((dispatch) => {
    if (getSize(actions)) {
      actions.forEach(dispatch);
    }

    if (event && getSize(shops)) {
      mixpanel.track(event, mixpanel.getChannelsAndPlans({ shopIds: shops, state: reduction }));
    }
  });

  return reduction.set('edit',
    edit
      .set('channels', shops.reduce(reduceShopIdsToChannels(userShops.get('byId')), EMPTY_MAP))
      .set('products', EMPTY_MAP)
      .set('profile', profileId)
      .set('shops', shops.sort(sortShops({ sorted: userShops.get('options') })))
  );
}

function* setModal(reduction, payload) {
  return payload
    ? reduction.setIn(['edit', 'modal'], Map(payload))
    : reduction.deleteIn(['edit', 'modal']);
}

function* setProduct(reduction, { shopId, product }) {
  const edit = reduction.get('edit').setIn(['products', shopId], product);

  return reduction.set('edit', edit.set('isComplete', areComplete(edit.get('products'))));
}

function* setProfile(reduction, payload) {
  const { profileId } = payload;

  if (!profileId) {
    const { state: edit } = clearProfile({ ...payload, state: reduction.get('edit') });
    return reduction.set('edit', edit);
  }

  yield sideEffect((dispatch) => {
    const { shopId, type } = payload;
    const db = reduction.getIn(['shops', 'byId', shopId, 'db']);

    api.profiles
      .getProfile({ db, profileId, shopId, type })
      .then(
        (data) => dispatch(Actions.Edit.setProfileSucceeded({ ...payload, data })),
        (error) => dispatch(Actions.Edit.setProfileFailed(error))
      );
  });

  return reduction;
}

function* setProfileFailed(reduction, error) {
  yield sideEffect((dispatch) => {
    console.error('Error encountered while getting profile: ', error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_PROFILE,
      })
    );
  });

  return reduction;
}

function* setProfileSucceeded(reduction, payload) {
  const { shopId } = payload;
  const { confirmation, state: edit } = applyProfile({ ...payload, state: reduction.get('edit') });

  if (confirmation) {
    yield sideEffect((dispatch) => {
      dispatch(
        Actions.Edit.setModal({
          type: confirmation,
          shopId,
          product: edit.getIn(['products', shopId]),
        })
      );
    });

    return reduction;
  }

  return reduction.set('edit', edit);
}

function* toggleShop(reduction, shopId) {
  const edit = reduction.get('edit');
  const isNew = edit.get('productId') === NEW;
  const connected = edit.hasIn(['products', shopId]);
  const shopsById = reduction.getIn(['shops', 'byId']);
  const shop = shopsById.get(shopId);

  yield sideEffect((dispatch) => {
    function openModal() {
      dispatch(Actions.Edit.setModal({ type: MODALS.COPY_CONTENT, targetShop: shop }));
    }

    if (isNew) {
      if (connected) {
        dispatch(Actions.Edit.removeNew(shopId));
      } else if (getSize(edit.get('products'))) {
        openModal();
      } else {
        dispatch(Actions.Edit.createNew({ shopIds: [shopId] }));
      }

      return;
    }

    if (connected) {
      if (edit.getIn(['products', shopId, 'productId']) === NEW) {
        dispatch(Actions.Edit.removeNew(shopId));
      }
    } else if (getSize(edit.get('products'))) {
      openModal();
    } else {
      dispatch(Actions.Edit.createNew({ shopIds: [shopId] }));
    }
  });

  return reduction;
}

function* waitForOperations(reduction) {
  return getSize(reduction.get('edit'))
    ? reduction.setIn(['edit', 'waitingForOperationsToBeApplied'], true)
    : reduction;
}

Reducers.add(
  new Reducer('Edit')
    .add(ACTIONS.EDIT.ADD_PLACEHOLDERS_FOR_NEW_LISTINGS, addPlaceholdersForNewListings)
    .add(ACTIONS.EDIT.CANCEL_EDIT, cancelEdit)
    .add(ACTIONS.EDIT.CREATE_NEW, createNew)
    .add(ACTIONS.EDIT.GET_DATA, getData)
    .add(ACTIONS.EDIT.GET_LINKED_PRODUCTS, getLinkedProducts)
    .add(ACTIONS.EDIT.GET_LINKED_PRODUCTS_FAILED, getLinkedProductsFailed)
    .add(ACTIONS.EDIT.GET_LINKED_PRODUCTS_SUCCEEDED, getLinkedProductsSucceeded)
    .add(ACTIONS.EDIT.GET_PRODUCT, getProduct)
    .add(ACTIONS.EDIT.GET_PRODUCT_DATA, getProductData)
    .add(ACTIONS.EDIT.GET_PRODUCT_DATA_FAILED, getProductDataFailed)
    .add(ACTIONS.EDIT.GET_PRODUCT_DATA_SUCCEEDED, getProductDataSucceeded)
    .add(ACTIONS.EDIT.REMOVE_NEW, removeNew)
    .add(ACTIONS.EDIT.SAVE_AS, saveAs)
    .add(ACTIONS.EDIT.SAVE_AS_PROFILE, saveAsProfile)
    .add(ACTIONS.EDIT.SAVE_AS_PROFILE_FAILED, saveAsProfileFailed)
    .add(ACTIONS.EDIT.SAVE_AS_PROFILE_SUCCEEDED, saveAsProfileSucceeded)
    .add(ACTIONS.EDIT.SAVE_NEW, saveNew)
    .add(ACTIONS.EDIT.SAVE_NEW_FAILED, saveNewFailed)
    .add(ACTIONS.EDIT.SAVE_NEW_SUCCEEDED, saveNewSucceeded)
    .add(ACTIONS.EDIT.SAVE_TO_CHANNELS, saveToChannels)
    .add(ACTIONS.EDIT.SAVE_TO_CHANNELS_FAILED, saveToChannelsFailed)
    .add(ACTIONS.EDIT.SAVE_TO_CHANNELS_SUCCEEDED, saveToChannelsSucceeded)
    .add(ACTIONS.EDIT.SET_DATA, setData)
    .add(ACTIONS.EDIT.SET_LISTINGS_PROFILE, setListingsProfile)
    .add(ACTIONS.EDIT.SET_MODAL, setModal)
    .add(ACTIONS.EDIT.SET_PRODUCT, setProduct)
    .add(ACTIONS.EDIT.SET_PROFILE, setProfile)
    .add(ACTIONS.EDIT.SET_PROFILE_FAILED, setProfileFailed)
    .add(ACTIONS.EDIT.SET_PROFILE_SUCCEEDED, setProfileSucceeded)
    .add(ACTIONS.EDIT.TOGGLE_SHOP, toggleShop)
    .add(ACTIONS.EDIT.WAIT_FOR_OPERATIONS, waitForOperations)
);
