import { List, Map } from 'immutable';

import { EXT_TO_MIME } from '../../../constants/photoEditor';

export function shapeFoldersForApp(result, folder) {
  const { folder_name: name, image_count: count, uuid } = folder;

  function updateById(byId = Map()) {
    return byId.set(uuid, Map({ count: parseInt(count, 10), name, uuid }));
  }

  function updateIds(ids = List()) {
    return ids.push(uuid);
  }

  return result
    .update('byId', updateById)
    .update('ids', updateIds);
}

export function shapeImagesForApp(result, image) {
  const { alt_text: altText, fileName, format, url, uuid } = image;

  function updateById(byId = Map()) {
    return byId.set(uuid,
      Map({
        altText,
        fileName,
        format,
        mime: EXT_TO_MIME[format],
        url,
        uuid,
      })
    );
  }

  function updateIds(ids = List()) {
    return ids.push(uuid);
  }

  return result
    .update('byId', updateById)
    .update('ids', updateIds);
}
