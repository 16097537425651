/* eslint-disable no-console */
import { List, Map, Set } from 'immutable';
import { sideEffect } from 'redux-side-effects';

import { getDefaultFilters, getDefaultStatuses, shapeFiltersForAPI, shapeFiltersForApp } from '../utils/profiles/connectProfile';
import { shapeConnectedProduct, shapeId, shapeListingsForApp, shapeNumber } from '../utils/listings/listings';
import { getDefaultPagination, getPagination, getPaginationForAPI } from '../utils/listings/pagination';
import { getFirstNonEmptyStatus, shapeCountsForApp } from '../utils/listings/statuses';
import { createController } from '../utils/reducer';
import { getOrderForAPI } from '../utils/listings/order';
import { setSelection } from '../utils/listings/selection';
import { getPlanName } from '../utils/billing';
import { increase } from '../utils/math';
import { getSize } from '../utils/iterable/getSize';
import mixpanel from '../utils/tracking/mixpanel';
import api from '../utils/api';

import { ALPHABETICAL_ORDER, FILTER, PAGE_SIZE, SELECTION } from '../constants/listings';
import { POLLING_INTERVAL, SYNC_INDICATOR } from '../constants/shops';
import { MESSAGE, NOTIFICATION } from '../constants/notifications';
import { CHANNEL_NAME } from '../constants/channels';
import { DEFAULTS } from '../constants';
import { PROFILE } from '../constants/profiles';
import { EVENT } from '../constants/tracking';
import ACTIONS from '../constants/actions';

import Reducers, { Reducer } from '../classes/Reducer';
import Actions from '../actions';

const filtersController = createController();
const listingsController = createController();
const listingIdsController = createController();
const statusesController = createController();
const connectedController = createController();

function abort(controller) {
  if (controller.isActive()) {
    controller.stop();
  }
}

function abortAll() {
  [
    filtersController,
    listingsController,
    listingIdsController,
    statusesController,
    connectedController,
  ].forEach(abort);
}

function* bootstrap(reduction, profileId) {
  abortAll();

  const profiles = reduction.get('profiles');
  const channel = profiles.get('channel');
  const db = profiles.get('db');
  const shopId = profiles.get('shopId');
  const type = profiles.get('type');
  const state = Map({
    channel,
    db,
    filters: getDefaultFilters(),
    pagination: getDefaultPagination(),
    products: DEFAULTS.EMPTY_MAP,
    profileId,
    shopId,
    statuses: getDefaultStatuses(),
    type,
  });

  yield sideEffect((dispatch) => {
    dispatch(Actions.ConnectProfile.getStatuses());

    if (profiles.getIn(['byId', profileId, 'listingsCount']) > 0) {
      const connectedListings = profiles.getIn(['listings', profileId]);

      if (getSize(connectedListings)) {
        dispatch(Actions.ConnectProfile.setConnectedProducts(connectedListings));
      } else {
        dispatch(Actions.ConnectProfile.getConnectedProducts(profileId));
      }
    } else {
      dispatch(Actions.ConnectProfile.setConnectedProducts(List()));
    }
  });

  return reduction.set('connectProfile', state);
}

function* cleanUp(reduction) {
  abortAll();
  return reduction.delete('connectProfile');
}

function* getConnectedProducts(reduction, profileId) {
  abort(connectedController);

  yield sideEffect((dispatch) => {
    const { signal } = connectedController.start();
    const state = reduction.get('connectProfile');
    const type = state.get('type');
    const db = state.get('db');
    const shopId = state.get('shopId');

    api.profiles
      .getListings({ db, profileId, shopId, signal, type })
      .then(
        (response) => dispatch(Actions.ConnectProfile.getConnectedProductsSucceeded({ response, type })),
        (error) => dispatch(Actions.ConnectProfile.getConnectedProductsFailed({ error, signal })),
      );
  });

  return reduction;
}

function* connectProducts(reduction) {
  const state = reduction.get('connectProfile').set('loading', true);
  const db = state.get('db');
  const shopId = state.get('shopId');
  const channel = state.get('channel');
  const profileId = state.get('profileId');
  const type = state.get('type');
  const products = state.getIn(['products', 'selected']).toArray();
  const total = getSize(products);

  if (EVENT.PROFILE.hasOwnProperty(type)) {
    mixpanel.track(
      EVENT.PROFILE[type].CONNECT,
      {
        channel: CHANNEL_NAME[channel],
        plan: getPlanName(reduction.getIn(['user', 'subscriptions', shopId])),
        shop_id: shopId,
        total_listings: total,
      }
    );
  }

  yield sideEffect((dispatch) => {
    function onFail(error) {
      dispatch(Actions.ConnectProfile.connectProductsFailed(error));
    }

    function onSuccess() {
      dispatch(Actions.ConnectProfile.connectProductsSucceeded({ profileId, shopId, total, type }));
    }

    switch (type) {
      case PROFILE.SALES: {
        api.profiles
          .apply({ db, products, profileId, shopId, type })
          .then(onSuccess, onFail);

        break;
      }

      default: {
        api.profiles
          .getProfile({ db, profileId, shopId, type })
          .then(
            (data) => {
              api.profiles
                .apply({ channel, db, payload: data.profile, products, profileId, shopId, type })
                .then(
                  () => {
                    dispatch(
                      Actions.Shops.setData({
                        path: ['syncData', shopId],
                        value: Map({ indicator: SYNC_INDICATOR.SYNC }),
                      })
                    );

                    dispatch(
                      Actions.Shops.rescheduleShopsPolling(
                        reduction.getIn(['user', 'config', 'shopsPollingIntervalShort']) ||
                        POLLING_INTERVAL.SHORT
                      )
                    );

                    onSuccess();
                  },
                  onFail
                );
            },
            onFail
          );

        break;
      }
    }
  });

  return reduction.set('connectProfile', state);
}

function* connectProductsFailed(reduction, error) {
  if (!reduction.has('connectProfile')) return reduction;

  yield sideEffect((dispatch) => {
    console.error('Error encountered while connecting products to profile: ', error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.APPLY_PROFILE,
      })
    );
  });

  return reduction.deleteIn(['connectProfile', 'loading']);
}

function* connectProductsSucceeded(reduction, { profileId, shopId, total, type }) {
  if (!reduction.has('connectProfile')) return reduction;

  const profiles = reduction.get('profiles');

  if (
    profiles.get('shopId') !== shopId ||
    profiles.get('type') !== type ||
    !profiles.hasIn(['byId', profileId])
  ) {
    return reduction;
  }

  yield sideEffect((dispatch) => {
    dispatch(Actions.Profiles.setModal());
  });

  function updateProfile(profile) {
    return profile
      .set('listingsCount', profile.get('listingsCount') + total)
      .set('waitingForListings', true);
  }

  return reduction.set('profiles', profiles.updateIn(['byId', profileId], updateProfile));
}

function* getConnectedProductsFailed(reduction, { error, signal }) {
  if (signal.aborted || !reduction.has('connectProfile')) return reduction;

  abort(connectedController);

  yield sideEffect((dispatch) => {
    if (error) console.error(error);
    dispatch(Actions.ConnectProfile.setConnectedProducts(List()));
  });

  return reduction;
}

function* getConnectedProductsSucceeded(reduction, { response, type }) {
  if (!reduction.has('connectProfile')) return reduction;

  abort(connectedController);

  yield sideEffect((dispatch) => {
    const listings = shapeConnectedProduct({ response, type });
    dispatch(Actions.ConnectProfile.setConnectedProducts(listings || List()));
  });

  return reduction;
}

function* getFilters(reduction, { filters: selected } = {}) {
  abort(filtersController);

  yield sideEffect((dispatch) => {
    const { signal } = filtersController.start();
    const state = reduction.get('connectProfile');
    const channel = state.get('channel');
    const db = state.get('db');
    const shopId = state.get('shopId');
    const status = state.getIn(['statuses', 'selected']);
    const filters = shapeFiltersForAPI({ filters: selected || state.getIn(['filters', 'selected']), status });

    api.listings
      .getFilters({ channel, db, filters, shopId, signal })
      .then(
        (response) => dispatch(Actions.ConnectProfile.getFiltersSucceeded({ response, selected })),
        (error) => dispatch(Actions.ConnectProfile.getFiltersFailed({ error, signal })),
      );
  });

  return reduction.setIn(['connectProfile', 'filters', 'loading'], true);
}

function* getFiltersFailed(reduction, { error, signal }) {
  if (signal.aborted) return reduction;

  abort(filtersController);

  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_DATA,
      })
    );
  });

  return reduction.deleteIn(['connectProfile', 'filters', 'loading']);
}

function* getFiltersSucceeded(reduction, { response, selected }) {
  abort(filtersController);

  const state = reduction.get('connectProfile');
  const channel = state.get('channel');

  if (!channel) return reduction;

  let filters = state.get('filters').delete('loading');

  if (selected) {
    filters = filters.set('selected', selected);
  }

  filters = shapeFiltersForApp({
    channel,
    filters,
    response,
    shopData: reduction.getIn(['data', 'shopsData', state.get('shopId')]),
    status: state.getIn(['statuses', 'selected']),
  });

  return reduction.setIn(['connectProfile', 'filters'], filters);
}

function* getListings(reduction, payload = {}) {
  abort(listingsController);

  const { clear, filters: selected } = payload;

  if (clear) {
    abort(listingIdsController);
  }

  yield sideEffect((dispatch) => {
    const { signal } = listingsController.start();
    const state = reduction.get('connectProfile');
    const channel = state.get('channel');
    const db = state.get('db');
    const shopId = state.get('shopId');
    const status = state.getIn(['statuses', 'selected']);
    const page = clear
      ? 0
      : state.getIn(['pagination', 'currentPage']);

    const filters = {
      ...shapeFiltersForAPI({ filters: selected || state.getIn(['filters', 'selected']), status }),
      ...getOrderForAPI({ channel, order: ALPHABETICAL_ORDER[channel], status }),
      ...getPaginationForAPI(page),
    };

    api.listings
      .getListings({ channel, db, filters, shopId, signal })
      .then(
        (response) => dispatch(
          Actions.ConnectProfile.getListingsSucceeded({ clear, page, response, status })
        ),
        (error) => dispatch(Actions.ConnectProfile.getListingsFailed({ error, signal })),
      );
  });

  return reduction.setIn(['connectProfile', 'products', 'loading'], true);
}

function* getListingsFailed(reduction, { error, signal }) {
  if (signal.aborted) return reduction;

  abort(listingsController);

  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_LISTINGS,
      })
    );
  });

  return reduction.deleteIn(['connectProfile', 'products', 'loading']);
}

function* getListingsSucceeded(reduction, { clear, page, response, status }) {
  abort(listingsController);
  let state = reduction.get('connectProfile');
  const channel = state.get('channel');

  if (!channel) return reduction;

  state = state.deleteIn(['products', 'loading']);

  if (clear) {
    state = state
      .deleteIn(['products', 'all'])
      .deleteIn(['products', 'selection'])
      .setIn(['products', 'selected'], Set());
  }

  if (status !== state.get('status')) {
    state = state.set('status', status);
  }

  const total = shapeNumber(response.total);

  state = state.setIn(['products', 'total'], total);

  if (!total) {
    state = state
      .deleteIn(['products', 'all'])
      .deleteIn(['products', 'byId'])
      .deleteIn(['products', 'ids'])
      .deleteIn(['products', 'selection'])
      .setIn(['products', 'selected'], Set())
      .set('pagination', getDefaultPagination());

    return reduction.set('connectProfile', state);
  } else if (!getSize(response.listings) && page > 0) {
    const lastPage = Math.floor((total - 1) / PAGE_SIZE);

    if (state.getIn(['pagination', 'currentPage']) > lastPage) {
      state = state.set('pagination', getPagination({ page: lastPage, total }));
    }

    return reduction.set('connectProfile', state);
  }

  const shopId = state.get('shopId');
  const userShops = reduction.get('shops');
  const shopData = reduction.getIn(['data', 'shopsData', shopId]);
  const { byId, ids } = shapeListingsForApp({ channel, response, shopData, userShops });
  let products = page > 0
    ? state.get('products').mergeIn(['byId'], byId).mergeIn(['ids'], ids)
    : state.get('products').set('byId', byId).set('ids', ids);

  const connected = products.get('connected', Set());

  if (!products.has('all') && getSize(products.get('ids')) === products.get('total')) {
    products = products.set('all', Set(products.get('ids')).subtract(connected));
  }

  state = state
    .set('pagination', getPagination({ page, total }))
    .set('products', setSelection({ exclude: connected, products }));

  return reduction.set('connectProfile', state);
}

function* getStatuses(reduction) {
  abort(statusesController);

  yield sideEffect((dispatch) => {
    const { signal } = statusesController.start();
    const state = reduction.get('connectProfile');
    const channel = state.get('channel');
    const db = state.get('db');
    const shopId = state.get('shopId');

    api.listings
      .getStatuses({ channel, db, shopId, signal })
      .then(
        (response) => dispatch(Actions.ConnectProfile.getStatusesSucceeded(response)),
        (error) => dispatch(Actions.ConnectProfile.getStatusesFailed({ error, signal })),
      );
  });

  return reduction;
}

function* getStatusesFailed(reduction, { error, signal }) {
  if (signal.aborted) return reduction;

  abort(statusesController);

  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_DATA,
      })
    );
  });

  return reduction;
}

function* getStatusesSucceeded(reduction, response) {
  abort(statusesController);

  let state = reduction.get('connectProfile');
  const channel = state.get('channel');

  if (!channel) return reduction;

  const counts = shapeCountsForApp({ channel, response });
  let statuses = state.get('statuses').set('counts', counts);

  function getEmptyState() {
    return state
      .set('filters', getDefaultFilters().set('options', DEFAULTS.EMPTY_LIST))
      .set('pagination', getDefaultPagination())
      .set('products', Map({ total: 0 }));
  }

  if (!statuses.getIn(['counts', statuses.get('selected')])) {
    const firstNonEmpty = getFirstNonEmptyStatus({ channel, counts });

    if (firstNonEmpty) {
      statuses = statuses.set('selected', firstNonEmpty);
    } else {
      state = getEmptyState().set('status', statuses.get('selected'));
    }
  }

  yield sideEffect((dispatch) => {
    const filters = DEFAULTS.EMPTY_MAP;
    dispatch(Actions.ConnectProfile.getFilters({ filters }));
    dispatch(Actions.ConnectProfile.getListings({ clear: true, filters }));
  });

  return reduction.set('connectProfile', state.set('statuses', statuses));
}

function* setConnectedProducts(reduction, products) {
  if (!reduction.has('connectProfile')) return reduction;

  function reduceProducts(result, product) {
    return result.add(product.get('productId'));
  }

  return reduction.setIn(['connectProfile', 'products', 'connected'], products.reduce(reduceProducts, Set()));
}

function* setFilter(reduction, { filter, value, ...rest }) {
  let filters = reduction.getIn(['connectProfile', 'filters', 'selected']);

  switch (filter) {
    case FILTER.PROFILE: {
      filters = filters.update(filter, function updateFilter(types = Map()) {
        const { type } = rest;

        let ids = types.get(type, Set());

        ids = ids.has(value)
          ? ids.delete(value)
          : ids.add(value);

        return getSize(ids)
          ? types.set(type, ids)
          : types.delete(type);
      });

      break;
    }

    case FILTER.TITLE: {
      filters = filters.set(filter, value);
      break;
    }

    default: {
      filters = filters.update(filter, function updateFilter(values = Set()) {
        return Set.isSet(value)
          ? getSize(values.intersect(value))
            ? values.subtract(value)
            : values.union(value)
          : values.has(value)
            ? values.delete(value)
            : values.add(value);
      });

      break;
    }
  }

  if (!getSize(filters.get(filter))) {
    filters = filters.delete(filter);
  }

  yield sideEffect((dispatch) => {
    dispatch(Actions.ConnectProfile.getFilters({ filters }));
    dispatch(Actions.ConnectProfile.getListings({ clear: true, filters }));
  });

  return reduction.setIn(['listings', 'filters', 'selected'], filters);
}

function* loadMore(reduction) {
  const pagination = reduction.getIn(['connectProfile', 'pagination']);

  if (pagination.get('to') >= pagination.get('total')) return reduction;

  yield sideEffect((dispatch) => {
    dispatch(Actions.ConnectProfile.getListings());
  });

  return reduction.updateIn(['connectProfile', 'pagination', 'currentPage'], increase);
}

function* setStatus(reduction, status) {
  abort(filtersController);
  abort(listingsController);
  abort(listingIdsController);

  let state = reduction.get('connectProfile');
  const statuses = state.get('statuses');

  if (status === statuses.get('selected')) return reduction;

  if (!statuses.getIn(['counts', status])) {
    state = state
      .set('filters', getDefaultFilters().set('options', DEFAULTS.EMPTY_LIST))
      .set('pagination', getDefaultPagination())
      .set('products', Map({ total: 0 }))
      .set('status', status)
      .set('statuses', statuses.set('selected', status));

    return reduction.set('connectProfile', state);
  }

  let filters = DEFAULTS.EMPTY_MAP;
  const title = state.getIn(['filters', 'selected', 'title']);

  if (title) {
    filters = filters.set('title', title);
  }

  yield sideEffect((dispatch) => {
    dispatch(Actions.ConnectProfile.getFilters({ filters }));
    dispatch(Actions.ConnectProfile.getListings({ clear: true, filters }));
  });

  return reduction.setIn(['connectProfile', 'statuses'], statuses.set('selected', status));
}

function* toggleProduct(reduction, productId) {
  abort(listingIdsController);
  let products = reduction.getIn(['connectProfile', 'products']);
  const connected = products.get('connected', Set());

  if (productId) {
    products = products.update('selected', function update(selected = Set()) {
      return selected.has(productId)
        ? selected.delete(productId)
        : selected.add(productId);
    });
  }

  return reduction.setIn(['connectProfile', 'products'], setSelection({ exclude: connected, products }));
}

function* toggleAllProducts(reduction) {
  const state = reduction.get('connectProfile');
  const products = state.get('products');

  if (products.get('selection') === SELECTION.ALL) {
    return reduction.setIn(['connectProfile', 'products'],
      setSelection({ products: products.set('selected', Set()) })
    );
  }

  if (products.has('all')) {
    return reduction.setIn(['connectProfile', 'products'],
      setSelection({ products: products.set('selected', products.get('all')) })
    );
  }

  yield sideEffect((dispatch) => {
    const { signal } = listingIdsController.start();
    const channel = state.get('channel');
    const db = state.get('db');
    const shopId = state.get('shopId');
    const status = state.getIn(['statuses', 'selected']);
    const payload = shapeFiltersForAPI({ filters: state.getIn(['filters', 'selected']), status });

    api.listings
      .getListingIds({ channel, db, payload, shopId, signal })
      .then(
        (response) => dispatch(Actions.ConnectProfile.toggleAllProductsSucceeded(response)),
        (error) => dispatch(Actions.ConnectProfile.toggleAllProductsFailed({ error, signal })),
      );
  });

  return reduction.setIn(['connectProfile', 'products', 'loading'], true);
}

function* toggleAllProductsFailed(reduction, { error, signal }) {
  if (signal.aborted) return reduction;

  abort(listingIdsController);

  yield sideEffect((dispatch) => {
    console.error(error);
    dispatch(
      Actions.Notifications.add({
        type: NOTIFICATION.ERROR,
        message: MESSAGE.FAIL.LOAD_DATA,
      })
    );
  });

  return reduction.deleteIn(['connectProfile', 'products', 'loading']);
}

function* toggleAllProductsSucceeded(reduction, response) {
  abort(listingIdsController);

  const ids = response.map(shapeId);
  const products = reduction.getIn(['connectProfile', 'products']).delete('loading');
  const connected = products.get('connected', Set());
  const selected = Set(ids).subtract(connected);

  return reduction.setIn(['connectProfile', 'products'],
    setSelection({
      exclude: connected,
      products: products.set('selected', selected).set('all', selected),
    })
  );
}

Reducers.add(
  new Reducer('ConnectProfile')
    .add(ACTIONS.CONNECTPROFILE.BOOTSTRAP, bootstrap)
    .add(ACTIONS.CONNECTPROFILE.CLEAN_UP, cleanUp)
    .add(ACTIONS.CONNECTPROFILE.CONNECT_PRODUCTS, connectProducts)
    .add(ACTIONS.CONNECTPROFILE.CONNECT_PRODUCTS_SUCCEEDED, connectProductsSucceeded)
    .add(ACTIONS.CONNECTPROFILE.CONNECT_PRODUCTS_FAILED, connectProductsFailed)
    .add(ACTIONS.CONNECTPROFILE.GET_CONNECTED_PRODUCTS, getConnectedProducts)
    .add(ACTIONS.CONNECTPROFILE.GET_CONNECTED_PRODUCTS_SUCCEEDED, getConnectedProductsSucceeded)
    .add(ACTIONS.CONNECTPROFILE.GET_CONNECTED_PRODUCTS_FAILED, getConnectedProductsFailed)
    .add(ACTIONS.CONNECTPROFILE.GET_FILTERS, getFilters)
    .add(ACTIONS.CONNECTPROFILE.GET_FILTERS_FAILED, getFiltersFailed)
    .add(ACTIONS.CONNECTPROFILE.GET_FILTERS_SUCCEEDED, getFiltersSucceeded)
    .add(ACTIONS.CONNECTPROFILE.GET_LISTINGS, getListings)
    .add(ACTIONS.CONNECTPROFILE.GET_LISTINGS_FAILED, getListingsFailed)
    .add(ACTIONS.CONNECTPROFILE.GET_LISTINGS_SUCCEEDED, getListingsSucceeded)
    .add(ACTIONS.CONNECTPROFILE.GET_STATUSES, getStatuses)
    .add(ACTIONS.CONNECTPROFILE.GET_STATUSES_FAILED, getStatusesFailed)
    .add(ACTIONS.CONNECTPROFILE.GET_STATUSES_SUCCEEDED, getStatusesSucceeded)
    .add(ACTIONS.CONNECTPROFILE.LOAD_MORE, loadMore)
    .add(ACTIONS.CONNECTPROFILE.SET_CONNECTED_PRODUCTS, setConnectedProducts)
    .add(ACTIONS.CONNECTPROFILE.SET_FILTER, setFilter)
    .add(ACTIONS.CONNECTPROFILE.SET_STATUS, setStatus)
    .add(ACTIONS.CONNECTPROFILE.TOGGLE_PRODUCT, toggleProduct)
    .add(ACTIONS.CONNECTPROFILE.TOGGLE_ALL_PRODUCTS, toggleAllProducts)
    .add(ACTIONS.CONNECTPROFILE.TOGGLE_ALL_PRODUCTS_SUCCEEDED, toggleAllProductsSucceeded)
    .add(ACTIONS.CONNECTPROFILE.TOGGLE_ALL_PRODUCTS_FAILED, toggleAllProductsFailed)
);
