import request from './request';

import { ENDPOINT } from '../../constants/api';

export default {
  createSetupIntent({ paymentMethod, signal }) {
    return request.post({
      endpoint: ENDPOINT.AUTH_V2,
      payload: { paymentMethod },
      signal,
      url: '/billing/subscriptions/setup_intent',
    });
  },
  createSubscription({ db, shopId, signal }) {
    return request.post({
      endpoint: ENDPOINT.AUTH_V2,
      params: { db },
      signal,
      url: `/billing/${shopId}/subscriptions`,
    });
  },
  cancelSubscription({ db, shopId, signal }) {
    return request.delete({
      endpoint: ENDPOINT.AUTH_V2,
      params: { db },
      signal,
      url: `/billing/${shopId}/subscriptions`,
    });
  },
  downloadInvoice({ db, shopId, signal, invoiceId }) {
    return request.get({
      endpoint: ENDPOINT.AUTH_V2,
      params: { db, stripeInvoiceId: invoiceId },
      signal,
      url: `/billing/${shopId}/subscriptions/download_invoice`,
    });
  },
  getCustomer({ signal }) {
    return request.get({
      endpoint: ENDPOINT.AUTH_V2,
      signal,
      url: '/billing/customer',
    });
  },
  getSubscription({ db, shopId, signal }) {
    return request.get({
      endpoint: ENDPOINT.AUTH_V2,
      params: { db },
      signal,
      url: `/billing/${shopId}/subscriptions/quote`,
    });
  },
  getSubscriptions() {
    return request.get({
      endpoint: ENDPOINT.AUTH_V2,
      url: '/billing/subscriptions',
    });
  },
  getInvoices({ db, shopId, signal, subscriptionId }) {
    return request.get({
      endpoint: ENDPOINT.AUTH_V2,
      params: { db },
      signal,
      url: `/billing/${shopId}/subscriptions/${subscriptionId}/invoices`,
    });
  },
  setPaymentMethod({ paymentMethod, signal }) {
    return request.post({
      endpoint: ENDPOINT.AUTH_V2,
      payload: { paymentMethod },
      signal,
      url: '/billing/subscriptions/set_default_payment_method',
    });
  },
  confirmSubscription({ params, shopId, signal }) {
    return request.get({
      endpoint: ENDPOINT.AUTH_V2,
      params,
      signal,
      url: `/billing/${shopId}/subscriptions/confirm`,
    });
  },
};
