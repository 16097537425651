import { STATUSES } from '../constants/shops';
import { DEFAULTS } from '../constants';
import { PLAN } from '../constants/billing';

const { EMPTY_MAP } = DEFAULTS;

export function getPlanName(subscription = EMPTY_MAP) {
  switch (subscription.get('plan')) {
    case PLAN.TRIAL: {
      return (subscription.get('trialRemaining') || 0) < 1
        ? `${PLAN.TRIAL} expired`
        : PLAN.TRIAL;
    }

    default: {
      return subscription.get('plan');
    }
  }
}

export function isBillingError({ plan, syncStatus }) {
  switch (syncStatus) {
    case STATUSES.REIMPORT_SHOP:
    case STATUSES.TOKEN_REJECTED: {
      return false;
    }

    default: {
      break;
    }
  }

  return plan === PLAN.ERROR;
}

export function isUnpaid(subscription = EMPTY_MAP) {
  switch (subscription.get('plan')) {
    case PLAN.TRIAL: {
      return (subscription.get('trialRemaining') || 0) < 1;
    }

    default: {
      return (subscription.get('tierChangeDetected') || !subscription.get('subscriptionId'));
    }
  }
}

export function isSubscribed(subscription = EMPTY_MAP) {
  return !!subscription.get('subscriptionId') && !subscription.get('cancelAtPeriodEnd');
}

export function getDiscountedPrice({ discount, price }) {
  return discount
    ? price - price * (discount / 100)
    : price;
}

